import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, list_type, class_type, bool_type, option_type, anonRecord_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName, Receiver_$reflection } from "../../Shared/User.js";
import { SetId as SetId_1, DataTransfer_CreateReservationFormDto, RequestedValue$1, DataTransfer_CreateReservationFormDto_$reflection, TokenDataDto_$reflection, RequestedValue$1_$reflection, CustomerConfiguration_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { DataTransfer_DetailSet_$reflection } from "../../Shared/Set.js";
import { toString, compare, utcNow } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { sortBy, toArray, map as map_1, find, ofArray, singleton, filter, tryFind, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { unwrap, defaultArg, map } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Cmd_ofEffect, Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { getCustomerConfiguration } from "../../Requests/Configuration.js";
import { postReservationCmd } from "../../Requests/Set.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { safeHash, comparePrimitives, equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton as singleton_1, empty as empty_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { InformationAlert, WarningAlert } from "../../Components/Alert.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { SetInfo } from "../../Widgets/SetInfoWidget.js";
import { GroupedSelect } from "../../Components/Select.js";
import { List_groupBy } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { AutoSuggestInput } from "../../Components/Input.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { TextArea } from "../../Components/TextArea.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSelectableReceivers", "SetSelectedReceiver", "SetName", "SetIsReceiverNotificationSet", "SetDescription", "SetStartDate", "SetEndDate", "SaveReservation", "ReservationCreated", "ReservationNameSuggestionsFetched", "ReceiversFetched", "CustomerConfigurationFetched", "UpdateAppointmentInDateRange", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Sets.Dialog.CreateReservationDialog.Msg", [], Msg, () => [[], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", list_type(string_type)]], [["Item", list_type(Receiver_$reflection())]], [["Item", CustomerConfiguration_$reflection()]], [], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Set$, Loading, SuccessCallback, SelectedUser, OnClose, ReservationNameSuggestions, Receivers, CustomerConfiguration, UserData, Dto, AppointmentInDaterange) {
        super();
        this.Set = Set$;
        this.Loading = Loading;
        this.SuccessCallback = SuccessCallback;
        this.SelectedUser = SelectedUser;
        this.OnClose = OnClose;
        this.ReservationNameSuggestions = ReservationNameSuggestions;
        this.Receivers = Receivers;
        this.CustomerConfiguration = CustomerConfiguration;
        this.UserData = UserData;
        this.Dto = Dto;
        this.AppointmentInDaterange = AppointmentInDaterange;
    }
}

function State_$reflection() {
    return record_type("Sets.Dialog.CreateReservationDialog.State", [], State, () => [["Set", DataTransfer_DetailSet_$reflection()], ["Loading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["SelectedUser", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))], ["OnClose", lambda_type(unit_type, unit_type)], ["ReservationNameSuggestions", list_type(string_type)], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["UserData", TokenDataDto_$reflection()], ["Dto", DataTransfer_CreateReservationFormDto_$reflection()], ["AppointmentInDaterange", option_type(anonRecord_type(["Date", class_type("System.DateTime")], ["Title", string_type]))]]);
}

function init(props) {
    const now = utcNow();
    return [new State(props.Set, false, props.SuccessCallback, undefined, props.OnClose, empty(), new RequestedValue$1(0, []), new RequestedValue$1(0, []), props.UserData, new DataTransfer_CreateReservationFormDto(now, now, "", "", props.UserData.UserId, false, ""), map((reminder) => ({
        Date: reminder.Date,
        Title: reminder.Title,
    }), tryFind((r_1) => {
        if (compare(r_1.Date, now) >= 0) {
            return compare(r_1.Date, now) <= 0;
        }
        else {
            return false;
        }
    }, filter((r) => !r.IsConfirmed, props.Set.Planning.Reminders)))), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(string_type)));
    })))), "/api/tools/reservations/name-suggestions", (Item) => (new Msg(9, [Item])), (Item_1) => (new Msg(13, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item_2) => (new Msg(10, [Item_2])), (Item_3) => (new Msg(13, [Item_3]))), getCustomerConfiguration((Item_4) => (new Msg(11, [Item_4])), (Item_5) => (new Msg(13, [Item_5])))]))];
}

function update(msg, state) {
    let bind$0040, bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4, bind$0040_5, matchValue_5, user_1;
    switch (msg.tag) {
        case 0: {
            const matchValue = state.Receivers;
            const matchValue_1 = state.CustomerConfiguration;
            let matchResult, customerConfiguration, receivers;
            if (matchValue.tag === 1) {
                if (matchValue_1.tag === 1) {
                    matchResult = 1;
                    customerConfiguration = matchValue_1.fields[0];
                    receivers = matchValue.fields[0];
                }
                else {
                    matchResult = 0;
                }
            }
            else {
                matchResult = 0;
            }
            switch (matchResult) {
                case 0:
                    return [state, Cmd_none()];
                default: {
                    const matchValue_3 = state.UserData.Role;
                    switch (matchValue_3) {
                        case "user":
                            return [new State(state.Set, state.Loading, state.SuccessCallback, state.SelectedUser, state.OnClose, state.ReservationNameSuggestions, new RequestedValue$1(1, [filter((receiver) => {
                                const matchValue_4 = receiver.ReceiverType;
                                switch (matchValue_4.tag) {
                                    case 9:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Vehicles;
                                    case 8:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.SchoolClasses;
                                    case 7:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Students;
                                    case 2:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.ExternalPersons;
                                    case 4:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Rooms;
                                    case 5:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Projects;
                                    case 3:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Subcontractos;
                                    case 6:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.ConstructionSites;
                                    case 0:
                                        if (!customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Users) {
                                            return receiver.Id === state.UserData.UserId;
                                        }
                                        else {
                                            return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Users;
                                        }
                                    default:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Employees;
                                }
                            }, receivers)]), state.CustomerConfiguration, state.UserData, state.Dto, state.AppointmentInDaterange), Cmd_none()];
                        default:
                            return [state, Cmd_none()];
                    }
                }
            }
        }
        case 1: {
            const receiver_1 = msg.fields[0];
            return [new State(state.Set, state.Loading, state.SuccessCallback, receiver_1, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData, (bind$0040 = state.Dto, new DataTransfer_CreateReservationFormDto(bind$0040.StartDate, bind$0040.EndDate, bind$0040.Name, defaultArg(map((receiver_2) => receiver_2.value.Id, receiver_1), ""), bind$0040.SenderId, bind$0040.NotifyUser, bind$0040.Description)), state.AppointmentInDaterange), Cmd_none()];
        }
        case 3:
            return [new State(state.Set, state.Loading, state.SuccessCallback, state.SelectedUser, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData, (bind$0040_1 = state.Dto, new DataTransfer_CreateReservationFormDto(bind$0040_1.StartDate, bind$0040_1.EndDate, bind$0040_1.Name, bind$0040_1.ReceiverId, bind$0040_1.SenderId, msg.fields[0], bind$0040_1.Description)), state.AppointmentInDaterange), Cmd_none()];
        case 2:
            return [new State(state.Set, state.Loading, state.SuccessCallback, state.SelectedUser, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData, (bind$0040_2 = state.Dto, new DataTransfer_CreateReservationFormDto(bind$0040_2.StartDate, bind$0040_2.EndDate, msg.fields[0], bind$0040_2.ReceiverId, bind$0040_2.SenderId, bind$0040_2.NotifyUser, bind$0040_2.Description)), state.AppointmentInDaterange), Cmd_none()];
        case 4:
            return [new State(state.Set, state.Loading, state.SuccessCallback, state.SelectedUser, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData, (bind$0040_3 = state.Dto, new DataTransfer_CreateReservationFormDto(bind$0040_3.StartDate, bind$0040_3.EndDate, bind$0040_3.Name, bind$0040_3.ReceiverId, bind$0040_3.SenderId, bind$0040_3.NotifyUser, msg.fields[0])), state.AppointmentInDaterange), Cmd_none()];
        case 5:
            return [new State(state.Set, state.Loading, state.SuccessCallback, state.SelectedUser, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData, (bind$0040_4 = state.Dto, new DataTransfer_CreateReservationFormDto(msg.fields[0], bind$0040_4.EndDate, bind$0040_4.Name, bind$0040_4.ReceiverId, bind$0040_4.SenderId, bind$0040_4.NotifyUser, bind$0040_4.Description)), state.AppointmentInDaterange), singleton((dispatch) => {
                dispatch(new Msg(12, []));
            })];
        case 6:
            return [new State(state.Set, state.Loading, state.SuccessCallback, state.SelectedUser, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData, (bind$0040_5 = state.Dto, new DataTransfer_CreateReservationFormDto(bind$0040_5.StartDate, msg.fields[0], bind$0040_5.Name, bind$0040_5.ReceiverId, bind$0040_5.SenderId, bind$0040_5.NotifyUser, bind$0040_5.Description)), state.AppointmentInDaterange), singleton((dispatch_1) => {
                dispatch_1(new Msg(12, []));
            })];
        case 7:
            return [new State(state.Set, true, state.SuccessCallback, state.SelectedUser, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData, state.Dto, state.AppointmentInDaterange), postReservationCmd(new SetId_1(parse(state.Set.Id)), state.Dto, (Item_1) => (new Msg(8, [Item_1])), (Item_2) => (new Msg(13, [Item_2])))];
        case 8:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Set, false, state.SuccessCallback, state.SelectedUser, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData, state.Dto, state.AppointmentInDaterange), Cmd_errorToast("dialog.reservation.toast_messages.already_exists")];
            }
            else {
                return [new State(state.Set, false, state.SuccessCallback, state.SelectedUser, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData, state.Dto, state.AppointmentInDaterange), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast("dialog.reservation.toast_messages.created")]))];
            }
        case 9:
            return [new State(state.Set, state.Loading, state.SuccessCallback, state.SelectedUser, state.OnClose, msg.fields[0], state.Receivers, state.CustomerConfiguration, state.UserData, state.Dto, state.AppointmentInDaterange), Cmd_none()];
        case 10: {
            const response_2 = msg.fields[0];
            return [new State(state.Set, state.Loading, state.SuccessCallback, (matchValue_5 = state.UserData.Role, (matchValue_5 === "administrator") ? undefined : ((matchValue_5 === "system") ? undefined : ((matchValue_5 === "toolManager") ? undefined : ((user_1 = find((user) => (user.Id === state.UserData.UserId), response_2), {
                label: user_1.Name,
                value: user_1,
            }))))), state.OnClose, state.ReservationNameSuggestions, new RequestedValue$1(1, [response_2]), state.CustomerConfiguration, state.UserData, state.Dto, state.AppointmentInDaterange), singleton((dispatch_2) => {
                dispatch_2(new Msg(0, []));
            })];
        }
        case 11:
            return [new State(state.Set, state.Loading, state.SuccessCallback, state.SelectedUser, state.OnClose, state.ReservationNameSuggestions, state.Receivers, new RequestedValue$1(1, [msg.fields[0]]), state.UserData, state.Dto, state.AppointmentInDaterange), singleton((dispatch_3) => {
                dispatch_3(new Msg(0, []));
            })];
        case 13:
            return [new State(state.Set, false, state.SuccessCallback, state.SelectedUser, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData, state.Dto, state.AppointmentInDaterange), Cmd_none()];
        default:
            return [new State(state.Set, state.Loading, state.SuccessCallback, state.SelectedUser, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData, state.Dto, map((reminder) => ({
                Date: reminder.Date,
                Title: reminder.Title,
            }), tryFind((r_1) => {
                if (compare(r_1.Date, state.Dto.StartDate) >= 0) {
                    return compare(r_1.Date, state.Dto.EndDate) <= 0;
                }
                else {
                    return false;
                }
            }, filter((r) => !r.IsConfirmed, state.Set.Planning.Reminders)))), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems_4;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const formState = props.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["id", "tool-reservation-dialog"], ["className", join(" ", ["grid", "gap-5"])], (elems_4 = toList(delay(() => {
        let matchValue, reminder, elems, arg_1;
        return append((matchValue = props.AppointmentInDaterange, (matchValue == null) ? (empty_1()) : ((reminder = matchValue, singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["w-96"])], (elems = [createElement(WarningAlert, {
            Label: (arg_1 = {
                dateString: toString(reminder.Date, "dd.MM.yyyy"),
                reminderName: reminder.Title,
            }, patternInput[1].t("tool.reservation_dialog.overlapping_date_warning", arg_1)),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))))))), delay(() => {
            let elems_1;
            return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(SetInfo, {
                DisplayReservationAlert: false,
                SetId: new SetId_1(parse(props.Set.Id)),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => append(singleton_1(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
                ComponentState: formState,
                IsClearable: false,
                Key: "assign-tool-select",
                Label: t("general.user"),
                NoOptionsMessage: "select.employee_no_options",
                OnChange: props.SetSelectedReceiver,
                Options: map_1((tupledArg) => {
                    const receiverType = tupledArg[0];
                    const receivers_1 = toArray(sortBy((receiver_2) => receiver_2.label.toLowerCase(), map_1((receiver_1) => {
                        const matchValue_2 = receiver_1.LocationName;
                        if (matchValue_2 != null) {
                            const locationName = matchValue_2;
                            if (equals(receiverType, new ReceiverTypeName(4, []))) {
                                return {
                                    label: `${locationName} - ${receiver_1.Name}`,
                                    value: receiver_1,
                                };
                            }
                            else {
                                return {
                                    label: `${receiver_1.Name} - ${locationName}`,
                                    value: receiver_1,
                                };
                            }
                        }
                        else {
                            return {
                                label: `${receiver_1.Name}`,
                                value: receiver_1,
                            };
                        }
                    }, tupledArg[1]), {
                        Compare: comparePrimitives,
                    }));
                    return {
                        label: Helper_receiverTypeNameTranslation(t, receiverType),
                        options: receivers_1,
                    };
                }, List_groupBy((receiver) => receiver.ReceiverType, filter((u) => {
                    if (props.IsReceiverNotificationSet) {
                        const matchValue_1 = u.ReceiverType;
                        switch (matchValue_1.tag) {
                            case 1:
                            case 5:
                            case 8:
                            case 6:
                            case 2:
                            case 7:
                            case 3:
                            case 9:
                            case 4:
                                return false;
                            default:
                                return true;
                        }
                    }
                    else {
                        return true;
                    }
                }, props.Users), {
                    Equals: equals,
                    GetHashCode: safeHash,
                })),
                PlaceholderKey: "select.employee_placeholder",
                Value: unwrap(props.SelectedReceiver),
            }))), delay(() => {
                let dependencies_1;
                return append(singleton_1((dependencies_1 = [props.ReservervationNameSuggestions, props.ReservationName, formState], reactApi.useMemo(() => createElement(AutoSuggestInput, {
                    ComponentState: formState,
                    Label: t("general.name"),
                    OnChange: props.SetReservationName,
                    Suggestions: props.ReservervationNameSuggestions,
                    TestId: "tool-reservation-dialog-name-test-id",
                    Value: props.ReservationName,
                }), dependencies_1))), delay(() => append(singleton_1(createElement(InformationAlert, {
                    Label: t("tool.reservation_dialog.reservation_name_tooltip"),
                })), delay(() => {
                    let elems_2;
                    return append(singleton_1(createElement("div", createObj(singleton((elems_2 = [createElement(DatePicker, {
                        ComponentState: formState,
                        IsClearable: false,
                        Label: t("general.start_date"),
                        OnChange: (date) => {
                            if (date == null) {
                            }
                            else {
                                const date_1 = date;
                                if (compare(date_1, props.EndDate) > 0) {
                                    props.SetEndDate(date_1);
                                }
                                props.SetStartDate(date_1);
                            }
                        },
                        TestId: "tool-reservation-dialog-dialog-start-date-test-id",
                        Value: props.StartDate,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))), delay(() => {
                        let elems_3;
                        return append(singleton_1(createElement("div", createObj(singleton((elems_3 = [createElement(DatePicker, {
                            ComponentState: formState,
                            IsClearable: false,
                            Label: t("general.end_date"),
                            MinDate: props.StartDate,
                            OnChange: (date_2) => {
                                if (date_2 == null) {
                                }
                                else {
                                    props.SetEndDate(date_2);
                                }
                            },
                            TestId: "tool-reservation-dialog-end-date-test-id",
                            Value: props.EndDate,
                        })], ["children", reactApi.Children.toArray(Array.from(elems_3))]))))), delay(() => append(singleton_1(createElement(TextArea, {
                            ComponentState: formState,
                            Label: t("general.description"),
                            OnChange: props.SetDescription,
                            TestId: "tool-reservation-dialog-description-test-id",
                            Value: props.Description,
                        })), delay(() => ((props.Set.LocationInformation.AssignedReceiverId == null) ? singleton_1(defaultOf()) : append(singleton_1(Checkbox({
                            ComponentState: formState,
                            IsChecked: props.IsReceiverNotificationSet,
                            Label: t("tool.user_reservation_notification"),
                            OnCheck: props.SetIsReceiverNotificationSet,
                            TestId: "tool-reservation-dialog-notification-test-id",
                        })), delay(() => singleton_1(createElement(InformationAlert, {
                            Label: t("tool.user_reservation_notification_info"),
                        })))))))));
                    }));
                }))));
            }))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function OpenCreateReservationDialog(props) {
    let Title, Label_1;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        Set: props.Set,
        SuccessCallback: props.SuccessCallback,
        UserData: props.UserData,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("dialog.reservation.new_title"), {
        Body: toList(delay(() => {
            const matchValue = state_1.Receivers;
            let matchResult, receivers;
            if (matchValue.tag === 1) {
                if (state_1.CustomerConfiguration.tag === 1) {
                    matchResult = 1;
                    receivers = matchValue.fields[0];
                }
                else {
                    matchResult = 0;
                }
            }
            else {
                matchResult = 0;
            }
            switch (matchResult) {
                case 0:
                    return singleton_1(createElement(Skeleton, {
                        Variant: "normal",
                    }));
                default:
                    return singleton_1(createElement(DialogBody, {
                        AppointmentInDaterange: unwrap(state_1.AppointmentInDaterange),
                        Description: state_1.Dto.Description,
                        EndDate: state_1.Dto.EndDate,
                        IsLoading: state_1.Loading,
                        IsReceiverNotificationSet: state_1.Dto.NotifyUser,
                        ReservationName: state_1.Dto.Name,
                        ReservervationNameSuggestions: state_1.ReservationNameSuggestions,
                        SelectedReceiver: unwrap(state_1.SelectedUser),
                        Set: props.Set,
                        SetDescription: (value_2) => {
                            dispatch(new Msg(4, [value_2]));
                        },
                        SetEndDate: (value_5) => {
                            dispatch(new Msg(6, [value_5]));
                        },
                        SetIsReceiverNotificationSet: (value_3) => {
                            dispatch(new Msg(3, [value_3]));
                        },
                        SetReservationName: (value_1) => {
                            dispatch(new Msg(2, [value_1]));
                        },
                        SetSelectedReceiver: (value) => {
                            dispatch(new Msg(1, [value]));
                        },
                        SetStartDate: (value_4) => {
                            dispatch(new Msg(5, [value_4]));
                        },
                        StartDate: state_1.Dto.StartDate,
                        SuccessCallback: props.SuccessCallback,
                        UserData: props.UserData,
                        Users: receivers,
                    }));
            }
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-reservation-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, (Label_1 = t("general.save"), {
            ComponentState: (state_1.SelectedUser == null) ? "disabled" : "enabled",
            Label: Label_1,
            OnClick: () => {
                dispatch(new Msg(7, []));
            },
            TestId: "save-reservation-dialog-test-id",
            Variant: "blueButton",
        }))]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

