import { createElement } from "react";
import React from "react";
import { parse } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import { useTranslation } from "react-i18next";
import { equals, createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { isNullOrWhiteSpace, join, printf, toText } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { ofArray, singleton } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { customBadgePillWithTranslation, customBadgePill } from "../SharedComponents/Badges.js";
import { append, empty, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { InformationAlert, ErrorAlert } from "../Components/Alert.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { Msg } from "./Types.js";
import { Button } from "../Components/Button.js";
import { ColorPicker_colorPicker_BD3CF72 } from "../SharedComponents/ReactColorPicker.js";
import { AppVariant, Variant as Variant_3 } from "../Variant.js";
import { TooltipProps, Tooltip } from "../SharedComponents/ReactTooltip.js";
import { PageHeader } from "../Components/Text.js";
import { ReceiverVisibilityConfiguration, CustomerConfiguration } from "../Shared/Shared.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { OverviewDispolistView } from "./Dispolist/View.js";
import { View as View_1 } from "./CustomProperty/View.js";

export function DetailBtn(props) {
    let elems_1, elems;
    const labelId = parse(props.Label.Id);
    const patternInput = useTranslation();
    return createElement("a", createObj(ofArray([["href", RouterModule_encodeParts(singleton(RouterModule_encodeParts(singleton(toText(printf("/settings/view/label/%O"))(labelId)), 1)), 1)], (elems_1 = [createElement("i", {
        className: "fas fa-eye",
    }), createElement("span", createObj(ofArray([["className", "url-text"], (elems = [patternInput[0]("general.display")], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function rowBadge(label) {
    return customBadgePill(label.Name, label.ColorHex);
}

export function LabelForm(props) {
    let elems_8;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "settings-form"], ["className", join(" ", toList(delay(() => (props.IsDisabled ? singleton_1("disabled-form") : empty()))))], (elems_8 = toList(delay(() => {
        let matchValue;
        return append((matchValue = props.State.Message, (matchValue != null) ? singleton_1(createElement(ErrorAlert, {
            Label: matchValue,
        })) : singleton_1(defaultOf())), delay(() => {
            let elems_2;
            return append(singleton_1(createElement("div", createObj(ofArray([["className", "form-group"], (elems_2 = toList(delay(() => {
                let elems;
                return append(singleton_1(createElement("label", createObj(ofArray([["htmlFor", "input-labelname"], ["className", "form-label"], (elems = [t("settings.label_name")], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                    let value_17;
                    return append(singleton_1(createElement("input", createObj(ofArray([["id", "input-labelname"], ["className", join(" ", toList(delay(() => append(singleton_1("form-control"), delay(() => ((props.State.IsValidated && isNullOrWhiteSpace(props.Label.Name)) ? singleton_1("is-invalid") : empty()))))))], ["data-cy", "login-labelname-input"], (value_17 = props.Label.Name, ["ref", (e) => {
                        if (!(e == null) && !equals(e.value, value_17)) {
                            e.value = value_17;
                        }
                    }]), ["onChange", (ev) => {
                        props.Dispatch(new Msg(1, [ev.target.value]));
                    }], ["placeholder", "Akkubohrer"], ["disabled", props.IsDisabled]])))), delay(() => {
                        let elems_1;
                        return (props.State.IsValidated && isNullOrWhiteSpace(props.Label.Name)) ? singleton_1(createElement("div", createObj(ofArray([["className", "invalid-feedback"], (elems_1 = [t("settings.label_name_invalid")], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : empty();
                    }));
                }));
            })), ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                let elems_4, elems_3, children;
                return append(singleton_1(createElement("div", createObj(ofArray([["className", "form-group"], (elems_4 = [createElement("label", createObj(ofArray([["htmlFor", "input-labelname"], ["className", "form-label"], (elems_3 = [t("settings.label_preview")], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), (children = toList(delay(() => (isNullOrWhiteSpace(props.Label.Name) ? singleton_1(customBadgePillWithTranslation("settings.your_label_preview", props.Label.ColorHex)) : singleton_1(customBadgePill(props.Label.Name, props.Label.ColorHex))))), createElement("div", {
                    children: reactApi.Children.toArray(Array.from(children)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                    let elems_6;
                    return append(!props.IsDisabled ? singleton_1(createElement("div", createObj(ofArray([["className", "form-group"], (elems_6 = toList(delay(() => {
                        let elems_5;
                        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mb-3"])], (elems_5 = [createElement(Button, {
                            ComponentState: "enabled",
                            Label: t("settings.label_choose_color"),
                            OnClick: () => {
                                props.Dispatch(new Msg(7, []));
                            },
                            TestId: "settings-choosecolor-button",
                            Variant: "secondary",
                        })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => (props.State.IsLabelColorSelectorOpen ? singleton_1(ColorPicker_colorPicker_BD3CF72((color) => {
                            props.Dispatch(new Msg(2, [color.hex]));
                        }, props.Label.ColorHex)) : empty())));
                    })), ["children", reactApi.Children.toArray(Array.from(elems_6))])])))) : empty(), delay(() => {
                        let elems_7;
                        return equals(Variant_3, new AppVariant(0, [])) ? singleton_1(createElement("div", createObj(ofArray([["className", "form-check"], (elems_7 = [createElement("input", {
                            type: "checkbox",
                            className: "form-check-input",
                            id: "checkbox-is-visible-for-user-role-id",
                            checked: props.Label.VisibleForUserRole,
                            disabled: props.IsDisabled,
                            onChange: (ev_1) => {
                                props.Dispatch(new Msg(3, [ev_1.target.checked]));
                            },
                        }), createElement("label", {
                            className: "form-check-label check-label mr-1",
                            for: "checkbox-is-visible-for-user-role-id",
                            children: t("settings.label_visible_for_user"),
                        }), createElement(Tooltip, {
                            props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["settings.label_visible_for_user_tooltip"])]),
                        })], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))) : empty();
                    }));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_8))])])));
}

export function LabelEditForm(props) {
    let elems_8;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "settings-form"], ["className", join(" ", toList(delay(() => (props.IsDisabled ? singleton_1("disabled-form") : empty()))))], (elems_8 = toList(delay(() => {
        let matchValue;
        return append((matchValue = props.State.Message, (matchValue != null) ? singleton_1(createElement(ErrorAlert, {
            Label: matchValue,
        })) : singleton_1(defaultOf())), delay(() => {
            let elems_2;
            return append(singleton_1(createElement("div", createObj(ofArray([["className", "form-group"], (elems_2 = toList(delay(() => {
                let elems;
                return append(singleton_1(createElement("label", createObj(ofArray([["htmlFor", "input-labelname"], ["className", "form-label"], (elems = [t("settings.label_name")], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                    let value_17;
                    return append(singleton_1(createElement("input", createObj(ofArray([["id", "input-labelname"], ["className", join(" ", toList(delay(() => append(singleton_1("form-control"), delay(() => ((props.State.IsValidated && isNullOrWhiteSpace(props.Label.Name)) ? singleton_1("is-invalid") : empty()))))))], ["data-cy", "login-labelname-input"], (value_17 = props.Label.Name, ["ref", (e) => {
                        if (!(e == null) && !equals(e.value, value_17)) {
                            e.value = value_17;
                        }
                    }]), ["onChange", (ev) => {
                        props.Dispatch(new Msg(1, [ev.target.value]));
                    }], ["placeholder", "Akkubohrer"], ["disabled", props.IsDisabled]])))), delay(() => {
                        let elems_1;
                        return (props.State.IsValidated && isNullOrWhiteSpace(props.Label.Name)) ? singleton_1(createElement("div", createObj(ofArray([["className", "invalid-feedback"], (elems_1 = [t("settings.label_name_invalid")], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : empty();
                    }));
                }));
            })), ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                let elems_4, elems_3, children;
                return append(singleton_1(createElement("div", createObj(ofArray([["className", "form-group"], (elems_4 = [createElement("label", createObj(ofArray([["htmlFor", "input-labelname"], ["className", "form-label"], (elems_3 = [t("settings.label_preview")], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), (children = toList(delay(() => (isNullOrWhiteSpace(props.Label.Name) ? singleton_1(customBadgePillWithTranslation("settings.your_label_preview", props.Label.ColorHex)) : singleton_1(customBadgePill(props.Label.Name, props.Label.ColorHex))))), createElement("div", {
                    children: reactApi.Children.toArray(Array.from(children)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                    let elems_6;
                    return append(!props.IsDisabled ? singleton_1(createElement("div", createObj(ofArray([["className", "form-group"], (elems_6 = toList(delay(() => {
                        let elems_5;
                        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mb-3"])], (elems_5 = [createElement(Button, {
                            ComponentState: "enabled",
                            Label: t("settings.label_choose_color"),
                            OnClick: () => {
                                props.Dispatch(new Msg(7, []));
                            },
                            TestId: "settings-choosecolor-button",
                            Variant: "secondary",
                        })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => (props.State.IsLabelColorSelectorOpen ? singleton_1(ColorPicker_colorPicker_BD3CF72((color) => {
                            props.Dispatch(new Msg(2, [color.hex]));
                        }, props.Label.ColorHex)) : empty())));
                    })), ["children", reactApi.Children.toArray(Array.from(elems_6))])])))) : empty(), delay(() => {
                        let elems_7;
                        return equals(Variant_3, new AppVariant(0, [])) ? singleton_1(createElement("div", createObj(ofArray([["className", "form-check"], (elems_7 = [createElement("input", {
                            type: "checkbox",
                            className: "form-check-input",
                            id: "checkbox-is-visible-for-user-role-id",
                            checked: props.Label.VisibleForUserRole,
                            disabled: props.IsDisabled,
                            onChange: (ev_1) => {
                                props.Dispatch(new Msg(3, [ev_1.target.checked]));
                            },
                        }), createElement("label", {
                            className: "form-check-label check-label mr-1",
                            for: "checkbox-is-visible-for-user-role-id",
                            children: t("settings.label_visible_for_user"),
                        }), createElement(Tooltip, {
                            props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["settings.label_visible_for_user_tooltip"])]),
                        })], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))) : empty();
                    }));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_8))])])));
}

export function NewLabelView(props) {
    let elems_1, elems;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["id", "settings-form-container"], ["className", "flex-grow-1 d-flex flex-column"], (elems_1 = [createElement(PageHeader, {
        Text: "settings.create_new_label",
    }), createElement(LabelForm, {
        Dispatch: props.Dispatch,
        IsDisabled: false,
        Label: props.State.LabelForm.fields[0],
        State: props.State,
    }), createElement("div", createObj(ofArray([["className", "controls d-flex"], (elems = [createElement("button", {
        className: "btn btn-primary ml-auto save-submit",
        onClick: (arg) => {
            props.Dispatch(new Msg(5, [arg]));
        },
        children: patternInput[0]("general.save"),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function EditLabelView(props) {
    let elems_1, elems;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["id", "settings-form-container"], ["className", "flex-grow-1 d-flex flex-column"], (elems_1 = [createElement(PageHeader, {
        Text: "settings.label_edit",
    }), createElement(LabelEditForm, {
        Dispatch: props.Dispatch,
        IsDisabled: false,
        Label: props.State.LabelForm.fields[0],
        State: props.State,
    }), createElement("div", createObj(ofArray([["className", "controls d-flex"], (elems = [createElement("button", {
        className: "btn btn-primary ml-auto save-submit",
        onClick: (arg) => {
            props.Dispatch(new Msg(6, [arg]));
        },
        children: patternInput[0]("general.save"),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function CheckboxElement(props) {
    let elems;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["className", "form-check"], (elems = [createElement("input", {
        disabled: props.IsDisabled,
        type: "checkbox",
        className: "form-check-input",
        id: props.TranslationKey,
        checked: props.IsChecked,
        onChange: (ev) => {
            props.OnChecked(ev.target.checked);
        },
    }), createElement("label", {
        className: "form-check-labe mr-3",
        htmlFor: props.TranslationKey,
        children: patternInput[0](props.TranslationKey),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

function Permissions(props) {
    let elems_11, elems_1, children_4, elems_2, elems_3, elems_4, elems_5, elems_6, elems_10, elems_7, elems_9, elems_8, children_2, children;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(true);
    const setIsDisabled = patternInput_1[1];
    const isDisabled = patternInput_1[0];
    const patternInput_2 = reactApi.useState(props.CustomerConfiguration);
    const setCustomerConfiguration = patternInput_2[1];
    const customerConfiguration = patternInput_2[0];
    return createElement("div", createObj(ofArray([["className", " d-flex flex-column"], (elems_11 = [createElement("div", createObj(ofArray([["className", "ml-auto controls"], (elems_1 = toList(delay(() => {
        let elems;
        return isDisabled ? singleton_1(createElement(Button, {
            ComponentState: "enabled",
            Label: t("general.edit"),
            OnClick: () => {
                setIsDisabled(!isDisabled);
            },
            TestId: "settings-permissions-edit-button",
            Variant: "primary",
        })) : append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mr-2"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: () => {
                setIsDisabled(!isDisabled);
            },
            TestId: "settings-permissions-cancel-button",
            Variant: "secondary",
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => singleton_1(createElement(Button, {
            ComponentState: "enabled",
            Label: t("general.save"),
            OnClick: () => {
                setIsDisabled(!isDisabled);
                props.Dispatch(new Msg(8, [customerConfiguration]));
            },
            TestId: "settings-permissions-save-button",
            Variant: "primary",
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), (children_4 = ofArray([createElement("div", createObj(ofArray([["className", "form-check"], (elems_2 = [createElement("input", {
        type: "checkbox",
        className: "form-check-input",
        id: "global-search-app",
        disabled: isDisabled,
        checked: customerConfiguration.IsGlobalAppSearchActiveForUserRole,
        onChange: (ev) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, ev.target.checked, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
    }), createElement("label", {
        className: "form-check-labe mr-3",
        htmlFor: "global-search-app",
        children: t("settings.permissions_activate_global_search_for_user"),
    }), createElement(Tooltip, {
        props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["settings.permissions_activate_global_search_for_user_info"])]),
    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", "form-check"], (elems_3 = [createElement("input", {
        type: "checkbox",
        className: "form-check-input",
        disabled: isDisabled,
        id: "selfservice-user-return-authrised",
        checked: customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification,
        onChange: (ev_1) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, ev_1.target.checked, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
    }), createElement("label", {
        className: "form-check-labe mr-3",
        htmlFor: "selfservice-user-return-authrised",
        children: t("settings.permissions_self_service_user_return_tool"),
    }), createElement(Tooltip, {
        props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["settings.permissions_self_service_user_return_tool_info"])]),
    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", "form-check"], (elems_4 = [createElement("input", {
        type: "checkbox",
        className: "form-check-input",
        disabled: isDisabled,
        id: "selfservice-user-reservation",
        checked: customerConfiguration.IsSelfServiceReservationForUserActive,
        onChange: (ev_2) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, ev_2.target.checked, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
    }), createElement("label", {
        className: "form-check-labe mr-3",
        htmlFor: "selfservice-user-reservation",
        children: t("settings.permissions_self_service_user_reservation"),
    }), createElement(Tooltip, {
        props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["settings.permissions_self_service_user_reservation_info"])]),
    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", "form-check"], (elems_5 = [createElement("input", {
        type: "checkbox",
        className: "form-check-input",
        disabled: isDisabled,
        id: "notification-email-only-admin",
        checked: customerConfiguration.NotificationEmailsOnlyToAdministrators,
        onChange: (ev_3) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, ev_3.target.checked, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
    }), createElement("label", {
        className: "form-check-labe mr-3",
        htmlFor: "notification-email-only-admin",
        children: t("settings.permissions_notification_emails_only_to_administrators"),
    }), createElement(Tooltip, {
        props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["settings.permissions_notification_emails_only_to_administrators_info"])]),
    })], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", "form-check"], (elems_6 = [createElement("input", {
        type: "checkbox",
        className: "form-check-input",
        disabled: isDisabled,
        id: "global-storage-selfservice",
        checked: customerConfiguration.GlobalSelfServiceStorageActive,
        onChange: (ev_4) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, ev_4.target.checked, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
    }), createElement("label", {
        className: "form-check-labe mr-3",
        htmlFor: "global-storage-selfservice",
        children: t("settings.global_self_service_storage"),
    }), createElement(Tooltip, {
        props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["settings.global_self_service_storage_info"])]),
    })], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["border", "border-sky-500", "pt-2"])], (elems_10 = [createElement("div", createObj(ofArray([["className", "form-check"], (elems_7 = [createElement("label", {
        className: "form-check-label mr-3",
        children: t("settings.permissions_app_receiver_visibility"),
    }), createElement(Tooltip, {
        props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["settings.permissions_app_receiver_visibility_info"])]),
    })], ["children", reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", "form-check"], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_8 = [(children_2 = singleton((children = toList(delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Users ? singleton_1(createElement(CheckboxElement, {
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Users,
        IsDisabled: isDisabled,
        OnChecked: (value_129) => {
            let bind$0040;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040.ConstructionSites, bind$0040.Employees, bind$0040.ExternalPersons, bind$0040.Projects, bind$0040.Rooms, bind$0040.SchoolClasses, bind$0040.Students, bind$0040.Subcontractos, value_129, bind$0040.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TranslationKey: "receivers.users",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Employees ? singleton_1(createElement(CheckboxElement, {
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Employees,
        IsDisabled: isDisabled,
        OnChecked: (value_130) => {
            let bind$0040_1;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_1 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_1.ConstructionSites, value_130, bind$0040_1.ExternalPersons, bind$0040_1.Projects, bind$0040_1.Rooms, bind$0040_1.SchoolClasses, bind$0040_1.Students, bind$0040_1.Subcontractos, bind$0040_1.Users, bind$0040_1.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TranslationKey: "receivers.employees",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.ConstructionSites ? singleton_1(createElement(CheckboxElement, {
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.ConstructionSites,
        IsDisabled: isDisabled,
        OnChecked: (value_131) => {
            let bind$0040_2;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_2 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(value_131, bind$0040_2.Employees, bind$0040_2.ExternalPersons, bind$0040_2.Projects, bind$0040_2.Rooms, bind$0040_2.SchoolClasses, bind$0040_2.Students, bind$0040_2.Subcontractos, bind$0040_2.Users, bind$0040_2.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TranslationKey: "receivers.constructionsites",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Projects ? singleton_1(createElement(CheckboxElement, {
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Projects,
        IsDisabled: isDisabled,
        OnChecked: (value_132) => {
            let bind$0040_3;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_3 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_3.ConstructionSites, bind$0040_3.Employees, bind$0040_3.ExternalPersons, value_132, bind$0040_3.Rooms, bind$0040_3.SchoolClasses, bind$0040_3.Students, bind$0040_3.Subcontractos, bind$0040_3.Users, bind$0040_3.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TranslationKey: "receivers.projects",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Rooms ? singleton_1(createElement(CheckboxElement, {
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Rooms,
        IsDisabled: isDisabled,
        OnChecked: (value_133) => {
            let bind$0040_4;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_4 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_4.ConstructionSites, bind$0040_4.Employees, bind$0040_4.ExternalPersons, bind$0040_4.Projects, value_133, bind$0040_4.SchoolClasses, bind$0040_4.Students, bind$0040_4.Subcontractos, bind$0040_4.Users, bind$0040_4.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TranslationKey: "receivers.rooms",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.ExternalPersons ? singleton_1(createElement(CheckboxElement, {
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.ExternalPersons,
        IsDisabled: isDisabled,
        OnChecked: (value_134) => {
            let bind$0040_5;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_5 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_5.ConstructionSites, bind$0040_5.Employees, value_134, bind$0040_5.Projects, bind$0040_5.Rooms, bind$0040_5.SchoolClasses, bind$0040_5.Students, bind$0040_5.Subcontractos, bind$0040_5.Users, bind$0040_5.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TranslationKey: "receivers.externalpersons",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Subcontractos ? singleton_1(createElement(CheckboxElement, {
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Subcontractos,
        IsDisabled: isDisabled,
        OnChecked: (value_135) => {
            let bind$0040_6;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_6 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_6.ConstructionSites, bind$0040_6.Employees, bind$0040_6.ExternalPersons, bind$0040_6.Projects, bind$0040_6.Rooms, bind$0040_6.SchoolClasses, bind$0040_6.Students, value_135, bind$0040_6.Users, bind$0040_6.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TranslationKey: "receivers.subcontractors",
    })) : empty(), delay(() => (equals(Variant_3, new AppVariant(1, [])) ? append(customerConfiguration.ReceiverVisibilityConfiguration.SchoolClasses ? singleton_1(createElement(CheckboxElement, {
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.SchoolClasses,
        IsDisabled: isDisabled,
        OnChecked: (value_136) => {
            let bind$0040_7;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_7 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_7.ConstructionSites, bind$0040_7.Employees, bind$0040_7.ExternalPersons, bind$0040_7.Projects, bind$0040_7.Rooms, value_136, bind$0040_7.Students, bind$0040_7.Subcontractos, bind$0040_7.Users, bind$0040_7.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TranslationKey: "receivers.school_classes",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Students ? singleton_1(createElement(CheckboxElement, {
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Students,
        IsDisabled: isDisabled,
        OnChecked: (value_137) => {
            let bind$0040_8;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_8 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_8.ConstructionSites, bind$0040_8.Employees, bind$0040_8.ExternalPersons, bind$0040_8.Projects, bind$0040_8.Rooms, bind$0040_8.SchoolClasses, value_137, bind$0040_8.Subcontractos, bind$0040_8.Users, bind$0040_8.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TranslationKey: "receivers.students",
    })) : empty(), delay(() => (customerConfiguration.ReceiverVisibilityConfiguration.Vehicles ? singleton_1(createElement(CheckboxElement, {
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Vehicles,
        IsDisabled: isDisabled,
        OnChecked: (value_138) => {
            let bind$0040_9;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_9 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_9.ConstructionSites, bind$0040_9.Employees, bind$0040_9.ExternalPersons, bind$0040_9.Projects, bind$0040_9.Rooms, bind$0040_9.SchoolClasses, bind$0040_9.Students, bind$0040_9.Subcontractos, bind$0040_9.Users, value_138)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TranslationKey: "receivers.vehicles",
    })) : empty()))))) : empty()))))))))))))))))), createElement("li", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("ol", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", reactApi.Children.toArray(Array.from(elems_10))])])))]), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_11))])])));
}

function Receivers(props) {
    let elems_2, elems_1, children_4, children_2, children;
    const isDisabled = props.State.ReceiverTypeForm.IsFormDisabled;
    const t = useTranslation()[0];
    const matchValue = props.CustomerConfiguration;
    if (matchValue != null) {
        const config = matchValue.ReceiverVisibilityConfiguration;
        return createElement("div", createObj(ofArray([["className", " d-flex flex-column"], (elems_2 = [createElement("div", createObj(ofArray([["className", "ml-auto controls"], (elems_1 = toList(delay(() => {
            let elems;
            return isDisabled ? singleton_1(createElement(Button, {
                ComponentState: "enabled",
                Label: t("general.edit"),
                OnClick: () => {
                    props.Dispatch(new Msg(20, []));
                },
                TestId: "settings-receivers-edit-button",
                Variant: "primary",
            })) : append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mr-2"])], (elems = [createElement(Button, {
                ComponentState: "enabled",
                Label: t("general.cancel"),
                OnClick: () => {
                    props.Dispatch(new Msg(20, []));
                },
                TestId: "settings-receivers-cancel-button",
                Variant: "secondary",
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => singleton_1(createElement(Button, {
                ComponentState: "enabled",
                Label: t("general.save"),
                OnClick: () => {
                    props.Dispatch(new Msg(22, []));
                },
                TestId: "settings-receivers-save-button",
                Variant: "primary",
            }))));
        })), ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), (children_4 = ofArray([createElement(InformationAlert, {
            Label: "settings.receivers_description",
        }), (children_2 = singleton((children = toList(delay(() => append(singleton_1(createElement(CheckboxElement, {
            IsChecked: config.Users,
            IsDisabled: isDisabled,
            OnChecked: (value_7) => {
                props.Dispatch(new Msg(21, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, value_7, config.Vehicles)]));
            },
            TranslationKey: "receivers.users",
        })), delay(() => append(singleton_1(createElement(CheckboxElement, {
            IsChecked: config.Employees,
            IsDisabled: isDisabled,
            OnChecked: (value_8) => {
                props.Dispatch(new Msg(21, [new ReceiverVisibilityConfiguration(config.ConstructionSites, value_8, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TranslationKey: "receivers.employees",
        })), delay(() => append(singleton_1(createElement(CheckboxElement, {
            IsChecked: config.ConstructionSites,
            IsDisabled: isDisabled,
            OnChecked: (value_9) => {
                props.Dispatch(new Msg(21, [new ReceiverVisibilityConfiguration(value_9, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TranslationKey: "receivers.constructionsites",
        })), delay(() => append(singleton_1(createElement(CheckboxElement, {
            IsChecked: config.Projects,
            IsDisabled: isDisabled,
            OnChecked: (value_10) => {
                props.Dispatch(new Msg(21, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, value_10, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TranslationKey: "receivers.projects",
        })), delay(() => append(singleton_1(createElement(CheckboxElement, {
            IsChecked: config.Rooms,
            IsDisabled: isDisabled,
            OnChecked: (value_11) => {
                props.Dispatch(new Msg(21, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, value_11, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TranslationKey: "receivers.rooms",
        })), delay(() => append(singleton_1(createElement(CheckboxElement, {
            IsChecked: config.ExternalPersons,
            IsDisabled: isDisabled,
            OnChecked: (value_12) => {
                props.Dispatch(new Msg(21, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, value_12, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TranslationKey: "receivers.externalpersons",
        })), delay(() => append(singleton_1(createElement(CheckboxElement, {
            IsChecked: config.Subcontractos,
            IsDisabled: isDisabled,
            OnChecked: (value_13) => {
                props.Dispatch(new Msg(21, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, value_13, config.Users, config.Vehicles)]));
            },
            TranslationKey: "receivers.subcontractors",
        })), delay(() => append(equals(Variant_3, new AppVariant(1, [])) ? append(singleton_1(createElement(CheckboxElement, {
            IsChecked: config.SchoolClasses,
            IsDisabled: isDisabled,
            OnChecked: (value_14) => {
                props.Dispatch(new Msg(21, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, value_14, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TranslationKey: "receivers.school_classes",
        })), delay(() => singleton_1(createElement(CheckboxElement, {
            IsChecked: config.Students,
            IsDisabled: isDisabled,
            OnChecked: (value_15) => {
                props.Dispatch(new Msg(21, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, value_15, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TranslationKey: "receivers.students",
        })))) : empty(), delay(() => singleton_1(createElement(CheckboxElement, {
            IsChecked: config.Vehicles,
            IsDisabled: isDisabled,
            OnChecked: (value_16) => {
                props.Dispatch(new Msg(21, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, value_16)]));
            },
            TranslationKey: "receivers.vehicles",
        })))))))))))))))))))), createElement("li", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))), createElement("ol", {
            children: reactApi.Children.toArray(Array.from(children_2)),
        }))]), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children_4)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }
    else {
        return defaultOf();
    }
}

export function navElement(text, active, tabName, _arg) {
    return createElement("a", {
        id: toText(printf("%s-tab"))(tabName),
        className: join(" ", toList(delay(() => append(singleton_1("nav-link"), delay(() => (active ? singleton_1("active") : empty())))))),
        "data-toggle": "tab",
        children: reactApi.Children.toArray([text]),
        href: toText(printf("#%s"))(tabName),
    });
}

export function View() {
    let elems_10, elems, elems_9, elems_2, elems_1, elems_4, elems_3, elems_6, elems_5, elems_8, elems_7;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init_1, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "settings-container"], ["className", "flex-grow-1 d-flex"], (elems_10 = [createElement("div", createObj(ofArray([["id", "sidebar"], ["className", "d-flex flex-grow-1 flex-column nav"], (elems = [navElement(t("settings.label"), true, "label", (value_9) => {
    }), navElement(t("settings.permissions"), false, "permissions", (value_11) => {
    }), navElement(t("settings.receivers"), false, "receivers", (value_13) => {
    }), navElement(t("settings.dispolist"), false, "dispolist", (value_15) => {
    }), navElement(t("settings.custom_property.nav_header"), false, "customproperties", (value_17) => {
    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["id", "content"], ["className", "tab-content d-flex flex-grow-1"], (elems_9 = [createElement("div", createObj(ofArray([["id", "permissions"], ["className", "tab-pane flex-grow-1"], (elems_2 = [createElement("div", createObj(ofArray([["className", " d-flex flex-column"], (elems_1 = toList(delay(() => {
        const matchValue = state_1.CustomerConfiguration;
        if (matchValue == null) {
            return empty();
        }
        else {
            return singleton_1(createElement(Permissions, {
                CustomerConfiguration: matchValue,
                Dispatch: dispatch,
            }));
        }
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["id", "receivers"], ["className", "tab-pane flex-grow-1"], (elems_4 = [createElement("div", createObj(ofArray([["className", " d-flex flex-column"], (elems_3 = [createElement(Receivers, {
        CustomerConfiguration: unwrap(state_1.CustomerConfiguration),
        Dispatch: dispatch,
        State: state_1,
    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["id", "dispolist"], ["className", "tab-pane flex-grow-1"], (elems_6 = [createElement("div", createObj(ofArray([["className", " d-flex flex-column"], (elems_5 = [createElement(OverviewDispolistView, {
        Dispatch: dispatch,
        State: state_1,
    })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["id", "customproperties"], ["className", "tab-pane flex-grow-1"], (elems_8 = [createElement("div", createObj(ofArray([["className", " d-flex flex-column"], (elems_7 = [createElement(View_1, null)], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", {
        id: "crew",
        className: "tab-pane",
        children: "Coming soon...",
    })], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", reactApi.Children.toArray(Array.from(elems_10))])])));
}

