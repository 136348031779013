import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { class_type, record_type, option_type, bool_type, list_type, union_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { PropertyConfigurationEnabledResponse_$reflection, PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { Label_$reflection, RequestedValue$1_$reflection } from "../../Shared/Shared.js";

export class InvalidMessage extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NameExists", "EnumValueInUsed", "FeatureNotEnabled"];
    }
}

export function InvalidMessage_$reflection() {
    return union_type("Settings.CustomProperty.Types.InvalidMessage", [], InvalidMessage, () => [[], [], []]);
}

export class State extends Record {
    constructor(CustomProperties, Labels, IsEnabled, InvalidMessage) {
        super();
        this.CustomProperties = CustomProperties;
        this.Labels = Labels;
        this.IsEnabled = IsEnabled;
        this.InvalidMessage = InvalidMessage;
    }
}

export function State_$reflection() {
    return record_type("Settings.CustomProperty.Types.State", [], State, () => [["CustomProperties", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["Labels", RequestedValue$1_$reflection(list_type(Label_$reflection()))], ["IsEnabled", RequestedValue$1_$reflection(bool_type)], ["InvalidMessage", option_type(InvalidMessage_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FetchCustomProperties", "CustomPropertiesFetched", "CustomPropertiesEnabledFetched", "LabelsFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Settings.CustomProperty.Types.Msg", [], Msg, () => [[], [["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", PropertyConfigurationEnabledResponse_$reflection()]], [["Item", list_type(Label_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

