import { createElement } from "react";
import React from "react";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";

export function ColoredDot(props) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("flex"), delay(() => append(singleton("items-center"), delay(() => append(singleton("justify-center"), delay(() => append(singleton("w-3.5"), delay(() => append(singleton("h-3.5"), delay(() => append(singleton("rounded-full"), delay(() => {
        const matchValue = props.Color;
        return (matchValue === "red") ? singleton("bg-[#F99D9D]") : ((matchValue === "orange") ? singleton("bg-[#f9d49d]") : singleton("bg-[#D4FAAF]"));
    })))))))))))))))], (elems = [createElement("div", {
        className: join(" ", toList(delay(() => append(singleton("w-1.5"), delay(() => append(singleton("h-1.5"), delay(() => append(singleton("rounded-full"), delay(() => {
            const matchValue_1 = props.Color;
            return (matchValue_1 === "red") ? singleton("bg-red") : ((matchValue_1 === "orange") ? singleton("bg-[#eba44d]") : singleton("bg-[#50BF00]"));
        }))))))))),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

