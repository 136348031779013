import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, class_type, string_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__setValidationResponse_5219762A, FormState__startLoading, FormState_get_empty, StorageId_$reflection, FormState_$reflection, ToolId_$reflection, ClientApiResponse$1_$reflection } from "../../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { deleteToolWithQuantityStorage } from "../../../Requests/ToolWithQuantity.js";
import { Cmd_successToast, Cmd_errorToast } from "../../../Components/Toast.js";
import { ofArray } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ConfirmationDialog } from "../../../Components/Dialog.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DeleteStockPerStorage", "DeleteStockPerStorageResponse", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Tools.ToolWithQuantityDeleteStockPerStorageDialog.Msg", [], Msg, () => [[], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(ToolId, FormState, SuccessCallback, OnClose, StorageId) {
        super();
        this.ToolId = ToolId;
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.StorageId = StorageId;
    }
}

function State_$reflection() {
    return record_type("Tools.ToolWithQuantityDeleteStockPerStorageDialog.State", [], State, () => [["ToolId", ToolId_$reflection()], ["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["StorageId", StorageId_$reflection()]]);
}

function init(props) {
    return [new State(props.ToolId, FormState_get_empty(), props.SuccessCallback, props.OnClose, props.StorageId), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 0:
            return [new State(state.ToolId, FormState__startLoading(state.FormState), state.SuccessCallback, state.OnClose, state.StorageId), deleteToolWithQuantityStorage(state.ToolId, state.StorageId, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])))];
        case 2:
            return [state, Cmd_none()];
        default: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.ToolId, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0]), state.SuccessCallback, state.OnClose, state.StorageId), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.ToolId, FormState_get_empty(), state.SuccessCallback, state.OnClose, state.StorageId), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
    }
}

export function ToolWithQuantityStockPerStorageDeleteDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        StorageId: props.StorageId,
        SuccessCallback: props.SuccessCallback,
        ToolId: props.ToolId,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    return createElement(ConfirmationDialog, (Title = t("tool.delete_tool_with_quantity_stock_per_storage"), {
        Description: t("tool.tool_with_quantity_stock_per_storage_delete_msg"),
        FormState: state_1.FormState,
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        OnConfirm: state_1.FormState.IsLoading ? (() => {
        }) : (() => {
            patternInput_1[1](new Msg(0, []));
        }),
        Title: Title,
    }));
}

