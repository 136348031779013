import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, list_type, class_type, bool_type, option_type, anonRecord_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { SetId as SetId_1, SetId__get_unwrap, DataTransfer_UpdateReservationFormDto, RequestedValue$1, DataTransfer_UpdateReservationFormDto_$reflection, RequestedValue$1_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName, Receiver_$reflection } from "../../Shared/User.js";
import { DataTransfer_DetailSet_$reflection } from "../../Shared/Set.js";
import { ReservationDto_$reflection } from "../../Shared/Planning.js";
import { toString, compare, utcNow } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { sortBy, toArray, map as map_1, filter, tryFind, ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Cmd_ofEffect, Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { unwrap, map } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { putReservationCmd, deleteReservationCmd } from "../../Requests/Set.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { safeHash, comparePrimitives, equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton as singleton_1, empty as empty_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { InformationAlert, WarningAlert } from "../../Components/Alert.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { GroupedSelect } from "../../Components/Select.js";
import { List_groupBy } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { AutoSuggestInput } from "../../Components/Input.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { TextArea } from "../../Components/TextArea.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ReservationDeleted", "DeleteReservation", "SetSelectedReceiver", "SetName", "SetIsReceiverNotificationSet", "SetDescription", "SetStartDate", "SetEndDate", "SaveReservation", "UpdateAppointmentInDateRange", "ReservationSaved", "ReservationNameSuggestionsFetched", "ReceiverFetched", "SetFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Sets.Dialog.EditReservationDialog.Msg", [], Msg, () => [[["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", option_type(anonRecord_type(["label", string_type], ["value", string_type]))]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", list_type(string_type)]], [["Item", list_type(Receiver_$reflection())]], [["Item", DataTransfer_DetailSet_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export class State extends Record {
    constructor(Set$, Loading, SuccessCallback, Dto, SelectedUser, OnClose, Reservation, ReservationNameSuggestions, Receivers, AppointmentInDaterange) {
        super();
        this.Set = Set$;
        this.Loading = Loading;
        this.SuccessCallback = SuccessCallback;
        this.Dto = Dto;
        this.SelectedUser = SelectedUser;
        this.OnClose = OnClose;
        this.Reservation = Reservation;
        this.ReservationNameSuggestions = ReservationNameSuggestions;
        this.Receivers = Receivers;
        this.AppointmentInDaterange = AppointmentInDaterange;
    }
}

export function State_$reflection() {
    return record_type("Sets.Dialog.EditReservationDialog.State", [], State, () => [["Set", RequestedValue$1_$reflection(DataTransfer_DetailSet_$reflection())], ["Loading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["Dto", DataTransfer_UpdateReservationFormDto_$reflection()], ["SelectedUser", option_type(anonRecord_type(["label", string_type], ["value", string_type]))], ["OnClose", lambda_type(unit_type, unit_type)], ["Reservation", ReservationDto_$reflection()], ["ReservationNameSuggestions", list_type(string_type)], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["AppointmentInDaterange", option_type(anonRecord_type(["Date", class_type("System.DateTime")], ["Title", string_type]))]]);
}

function init(props) {
    const now = utcNow();
    return [new State(new RequestedValue$1(0, []), false, props.SuccessCallback, new DataTransfer_UpdateReservationFormDto(props.Reservation.Id, props.Reservation.StartDate, props.Reservation.EndDate, props.Reservation.Name, props.Reservation.ReceiverInformation.ReceiverId, props.UserData.UserId, false, props.Reservation.Description), {
        label: props.Reservation.ReceiverInformation.ReceiverName,
        value: props.Reservation.ReceiverInformation.ReceiverId,
    }, props.OnClose, props.Reservation, empty(), new RequestedValue$1(0, []), undefined), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(string_type)));
    })))), "/api/tools/reservations/name-suggestions", (Item) => (new Msg(11, [Item])), (Item_1) => (new Msg(14, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item_2) => (new Msg(12, [Item_2])), (Item_3) => (new Msg(14, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), DataTransfer_DetailSet_$reflection()));
    })))), `/api/sets/${SetId__get_unwrap(props.SetId)}/details`, (Item_4) => (new Msg(13, [Item_4])), (Item_5) => (new Msg(14, [Item_5])))]))];
}

function update(msg, state) {
    let bind$0040, bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4;
    switch (msg.tag) {
        case 9: {
            const matchValue = state.Set;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.Set, state.Loading, state.SuccessCallback, state.Dto, state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, map((reminder) => ({
                    Date: reminder.Date,
                    Title: reminder.Title,
                }), tryFind((r_1) => {
                    if (compare(r_1.Date, state.Dto.StartDate) >= 0) {
                        return compare(r_1.Date, state.Dto.EndDate) <= 0;
                    }
                    else {
                        return false;
                    }
                }, filter((r) => !r.IsConfirmed, matchValue.fields[0].Planning.Reminders)))), Cmd_none()];
            }
        }
        case 2:
            return [new State(state.Set, state.Loading, state.SuccessCallback, state.Dto, msg.fields[0], state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, state.AppointmentInDaterange), Cmd_none()];
        case 4:
            return [new State(state.Set, state.Loading, state.SuccessCallback, (bind$0040 = state.Dto, new DataTransfer_UpdateReservationFormDto(bind$0040.ReservationId, bind$0040.StartDate, bind$0040.EndDate, bind$0040.Name, bind$0040.ReceiverId, bind$0040.SenderId, msg.fields[0], bind$0040.Description)), state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, state.AppointmentInDaterange), Cmd_none()];
        case 3:
            return [new State(state.Set, state.Loading, state.SuccessCallback, (bind$0040_1 = state.Dto, new DataTransfer_UpdateReservationFormDto(bind$0040_1.ReservationId, bind$0040_1.StartDate, bind$0040_1.EndDate, msg.fields[0], bind$0040_1.ReceiverId, bind$0040_1.SenderId, bind$0040_1.NotifyUser, bind$0040_1.Description)), state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, state.AppointmentInDaterange), Cmd_none()];
        case 5:
            return [new State(state.Set, state.Loading, state.SuccessCallback, (bind$0040_2 = state.Dto, new DataTransfer_UpdateReservationFormDto(bind$0040_2.ReservationId, bind$0040_2.StartDate, bind$0040_2.EndDate, bind$0040_2.Name, bind$0040_2.ReceiverId, bind$0040_2.SenderId, bind$0040_2.NotifyUser, msg.fields[0])), state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, state.AppointmentInDaterange), Cmd_none()];
        case 6:
            return [new State(state.Set, state.Loading, state.SuccessCallback, (bind$0040_3 = state.Dto, new DataTransfer_UpdateReservationFormDto(bind$0040_3.ReservationId, msg.fields[0], bind$0040_3.EndDate, bind$0040_3.Name, bind$0040_3.ReceiverId, bind$0040_3.SenderId, bind$0040_3.NotifyUser, bind$0040_3.Description)), state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, state.AppointmentInDaterange), singleton((dispatch_1) => {
                dispatch_1(new Msg(9, []));
            })];
        case 7:
            return [new State(state.Set, state.Loading, state.SuccessCallback, (bind$0040_4 = state.Dto, new DataTransfer_UpdateReservationFormDto(bind$0040_4.ReservationId, bind$0040_4.StartDate, msg.fields[0], bind$0040_4.Name, bind$0040_4.ReceiverId, bind$0040_4.SenderId, bind$0040_4.NotifyUser, bind$0040_4.Description)), state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, state.AppointmentInDaterange), singleton((dispatch_2) => {
                dispatch_2(new Msg(9, []));
            })];
        case 1: {
            const matchValue_1 = state.Set;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.Set, true, state.SuccessCallback, state.Dto, state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, state.AppointmentInDaterange), deleteReservationCmd(new SetId_1(parse(matchValue_1.fields[0].Id)), state.Reservation.Id, (Item_1) => (new Msg(0, [Item_1])), (Item_2) => (new Msg(14, [Item_2])))];
            }
        }
        case 8: {
            const matchValue_2 = state.Set;
            if (matchValue_2.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.Set, true, state.SuccessCallback, state.Dto, state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, state.AppointmentInDaterange), putReservationCmd(new SetId_1(parse(matchValue_2.fields[0].Id)), state.Dto, (Item_4) => (new Msg(10, [Item_4])), (Item_5) => (new Msg(14, [Item_5])))];
            }
        }
        case 10:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Set, false, state.SuccessCallback, state.Dto, state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, state.AppointmentInDaterange), Cmd_errorToast("dialog.reservation.toast_messages.already_exists")];
            }
            else {
                return [new State(state.Set, false, state.SuccessCallback, state.Dto, state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, state.AppointmentInDaterange), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast("dialog.reservation.toast_messages.created")]))];
            }
        case 0:
            return [new State(state.Set, false, state.SuccessCallback, state.Dto, state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, state.AppointmentInDaterange), Cmd_batch(ofArray([Cmd_ofEffect((_arg_2) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_3) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_remove_reservation_success")]))];
        case 11:
            return [new State(state.Set, state.Loading, state.SuccessCallback, state.Dto, state.SelectedUser, state.OnClose, state.Reservation, msg.fields[0], state.Receivers, state.AppointmentInDaterange), Cmd_none()];
        case 12:
            return [new State(state.Set, state.Loading, state.SuccessCallback, state.Dto, state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, new RequestedValue$1(1, [msg.fields[0]]), state.AppointmentInDaterange), Cmd_none()];
        case 14:
            return [new State(state.Set, false, state.SuccessCallback, state.Dto, state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, state.AppointmentInDaterange), Cmd_none()];
        default:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.Loading, state.SuccessCallback, state.Dto, state.SelectedUser, state.OnClose, state.Reservation, state.ReservationNameSuggestions, state.Receivers, state.AppointmentInDaterange), singleton((dispatch) => {
                dispatch(new Msg(9, []));
            })];
    }
}

function DialogBody(props) {
    let elems_3;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const formState = props.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["id", "tool-reservation-dialog"], ["className", join(" ", ["grid", "gap-5"])], (elems_3 = toList(delay(() => {
        let matchValue, reminder, elems, arg_1;
        return append((matchValue = props.AppointmentInDaterange, (matchValue == null) ? (empty_1()) : ((reminder = matchValue, singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["w-96"])], (elems = [createElement(WarningAlert, {
            Label: (arg_1 = {
                dateString: toString(reminder.Date, "dd.MM.yyyy"),
                reminderName: reminder.Title,
            }, patternInput[1].t("tool.reservation_dialog.overlapping_date_warning", arg_1)),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))))))), delay(() => append(singleton_1(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
            ComponentState: (props.UserData.Role === "user") ? "disabled" : formState,
            IsClearable: false,
            Key: "assign-tool-select",
            Label: t("general.user"),
            NoOptionsMessage: "select.employee_no_options",
            OnChange: props.SetSelectedReceiver,
            Options: map_1((tupledArg) => {
                const receiverType = tupledArg[0];
                const receivers_1 = toArray(sortBy((receiver_2) => receiver_2.label.toLowerCase(), map_1((receiver_1) => {
                    const matchValue_2 = receiver_1.LocationName;
                    if (matchValue_2 != null) {
                        const locationName = matchValue_2;
                        if (equals(receiverType, new ReceiverTypeName(4, []))) {
                            return {
                                label: `${locationName} - ${receiver_1.Name}`,
                                value: receiver_1.Id,
                            };
                        }
                        else {
                            return {
                                label: `${receiver_1.Name} - ${locationName}`,
                                value: receiver_1.Id,
                            };
                        }
                    }
                    else {
                        return {
                            label: `${receiver_1.Name}`,
                            value: receiver_1.Id,
                        };
                    }
                }, tupledArg[1]), {
                    Compare: comparePrimitives,
                }));
                return {
                    label: Helper_receiverTypeNameTranslation(t, receiverType),
                    options: receivers_1,
                };
            }, List_groupBy((receiver) => receiver.ReceiverType, filter((u) => {
                if (props.IsReceiverNotificationSet) {
                    const matchValue_1 = u.ReceiverType;
                    switch (matchValue_1.tag) {
                        case 1:
                        case 5:
                        case 8:
                        case 6:
                        case 2:
                        case 7:
                        case 3:
                        case 9:
                        case 4:
                            return false;
                        default:
                            return true;
                    }
                }
                else {
                    return true;
                }
            }, props.Receivers), {
                Equals: equals,
                GetHashCode: safeHash,
            })),
            PlaceholderKey: "select.employee_placeholder",
            Value: unwrap(props.SelectedReceiver),
        }))), delay(() => {
            let dependencies_1;
            return append(singleton_1((dependencies_1 = [props.ReservationName, props.ReservervationNameSuggestions, formState], reactApi.useMemo(() => createElement(AutoSuggestInput, {
                ComponentState: formState,
                Label: t("general.name"),
                OnChange: props.SetReservationName,
                Suggestions: props.ReservervationNameSuggestions,
                TestId: "tool-reservation-dialog-name-test-id",
                Value: props.ReservationName,
            }), dependencies_1))), delay(() => append(singleton_1(createElement(InformationAlert, {
                Label: t("tool.reservation_dialog.reservation_name_tooltip"),
            })), delay(() => {
                let elems_1;
                return append(singleton_1(createElement("div", createObj(singleton((elems_1 = [createElement(DatePicker, {
                    ComponentState: "enabled",
                    IsClearable: false,
                    Label: t("general.start_date"),
                    OnChange: (date) => {
                        if (date == null) {
                        }
                        else {
                            const date_1 = date;
                            if (compare(date_1, props.EndDate) > 0) {
                                props.SetEndDate(date_1);
                            }
                            props.SetStartDate(date_1);
                        }
                    },
                    TestId: "tool-reservation-dialog-start-date-test-id",
                    Value: props.StartDate,
                })], ["children", reactApi.Children.toArray(Array.from(elems_1))]))))), delay(() => {
                    let elems_2;
                    return append(singleton_1(createElement("div", createObj(singleton((elems_2 = [createElement(DatePicker, {
                        ComponentState: "enabled",
                        IsClearable: false,
                        Label: t("general.end_date"),
                        MinDate: props.StartDate,
                        OnChange: (date_2) => {
                            if (date_2 == null) {
                            }
                            else {
                                props.SetEndDate(date_2);
                            }
                        },
                        TestId: "tool-reservation-dialog-end-date-test-id",
                        Value: props.EndDate,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))), delay(() => append(singleton_1(createElement(TextArea, {
                        ComponentState: formState,
                        Label: t("general.description"),
                        OnChange: props.SetDescription,
                        TestId: "tool-reservation-dialog-description-test-id",
                        Value: props.Description,
                    })), delay(() => ((props.Set.LocationInformation.AssignedReceiverId == null) ? singleton_1(defaultOf()) : append(singleton_1(Checkbox({
                        ComponentState: formState,
                        IsChecked: props.IsReceiverNotificationSet,
                        Label: t("tool.user_reservation_notification"),
                        OnCheck: props.SetIsReceiverNotificationSet,
                        TestId: "tool-reservation-dialog-notification-test-id",
                    })), delay(() => singleton_1(createElement(InformationAlert, {
                        Label: t("tool.user_reservation_notification_info"),
                    })))))))));
                }));
            }))));
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function OpenEditReservationDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        Reservation: props.Reservation,
        SetId: props.SetId,
        SuccessCallback: props.SuccessCallback,
        UserData: props.UserData,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("dialog.reservation.edit_title"), {
        Body: toList(delay(() => {
            const matchValue = state_1.Receivers;
            const matchValue_1 = state_1.Set;
            let matchResult, receivers, set$;
            if (matchValue.tag === 0) {
                if (matchValue_1.tag === 0) {
                    matchResult = 1;
                }
                else {
                    matchResult = 1;
                }
            }
            else if (matchValue_1.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                receivers = matchValue.fields[0];
                set$ = matchValue_1.fields[0];
            }
            switch (matchResult) {
                case 0:
                    return singleton_1(createElement(DialogBody, {
                        AppointmentInDaterange: unwrap(state_1.AppointmentInDaterange),
                        Description: state_1.Dto.Description,
                        EndDate: state_1.Dto.EndDate,
                        IsLoading: state_1.Loading,
                        IsReceiverNotificationSet: state_1.Dto.NotifyUser,
                        Receivers: receivers,
                        ReservationName: state_1.Dto.Name,
                        ReservervationNameSuggestions: state_1.ReservationNameSuggestions,
                        SelectedReceiver: unwrap(state_1.SelectedUser),
                        Set: set$,
                        SetDescription: (value_2) => {
                            dispatch(new Msg(5, [value_2]));
                        },
                        SetEndDate: (value_5) => {
                            dispatch(new Msg(7, [value_5]));
                        },
                        SetIsReceiverNotificationSet: (value_3) => {
                            dispatch(new Msg(4, [value_3]));
                        },
                        SetReservationName: (value_1) => {
                            dispatch(new Msg(3, [value_1]));
                        },
                        SetSelectedReceiver: (value) => {
                            dispatch(new Msg(2, [value]));
                        },
                        SetStartDate: (value_4) => {
                            dispatch(new Msg(6, [value_4]));
                        },
                        StartDate: state_1.Dto.StartDate,
                        SuccessCallback: props.SuccessCallback,
                        UserData: props.UserData,
                    }));
                default:
                    return singleton_1(createElement(Skeleton, {
                        Variant: "normal",
                    }));
            }
        })),
        Controls: toList(delay(() => {
            const matchValue_3 = state_1.Receivers;
            const matchValue_4 = state_1.Set;
            let matchResult_1, receivers_1, set$_1;
            if (matchValue_3.tag === 1) {
                if (matchValue_4.tag === 1) {
                    matchResult_1 = 0;
                    receivers_1 = matchValue_3.fields[0];
                    set$_1 = matchValue_4.fields[0];
                }
                else {
                    matchResult_1 = 1;
                }
            }
            else {
                matchResult_1 = 1;
            }
            switch (matchResult_1) {
                case 0:
                    return append(singleton_1(createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: t("general.delete"),
                        OnClick: () => {
                            dispatch(new Msg(1, []));
                        },
                        TestId: "delete-reservation-dialog-test-id",
                        Variant: "redButton",
                    })), delay(() => append(singleton_1(createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: t("general.cancel"),
                        OnClick: props.OnClose,
                        TestId: "close-reservation-dialog-test-id",
                        Variant: "blueButton",
                    })), delay(() => {
                        let Label_2;
                        return singleton_1(createElement(TextButton, (Label_2 = t("general.save"), {
                            ComponentState: (state_1.SelectedUser == null) ? "disabled" : "enabled",
                            Label: Label_2,
                            OnClick: () => {
                                dispatch(new Msg(8, []));
                            },
                            TestId: "save-reservation-dialog-test-id",
                            Variant: "blueButton",
                        })));
                    }))));
                default: {
                    return empty_1();
                }
            }
        })),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

