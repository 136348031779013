import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, FormState__startLoading, ToolId, UnassignToolWithoutNotificationDto, RequestedValue$1, FormState_get_empty, RequestedValue$1_$reflection, FormState_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { ToolDetailDto_$reflection } from "../../Shared/Tool.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_unassignCmd } from "../../Requests/Tool.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextArea } from "../../Components/TextArea.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Dialog } from "../../Components/Dialog.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UnassignedToolResponse", "OnUnassignTool", "SetComment", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Tools.UnassignToolDialog.Msg", [], Msg, () => [[["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", string_type]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Tool, Comment$, UserId, SuccessCallback, OnClose, FormState, ReceiverName) {
        super();
        this.Tool = Tool;
        this.Comment = Comment$;
        this.UserId = UserId;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.FormState = FormState;
        this.ReceiverName = ReceiverName;
    }
}

function State_$reflection() {
    return record_type("Tools.UnassignToolDialog.State", [], State, () => [["Tool", ToolDetailDto_$reflection()], ["Comment", string_type], ["UserId", string_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["FormState", FormState_$reflection()], ["ReceiverName", RequestedValue$1_$reflection(string_type)]]);
}

function init(props) {
    let matchValue;
    return [new State(props.Tool, props.Comment, props.UserId, props.SuccessCallback, props.OnClose, FormState_get_empty(), (matchValue = props.Tool.AssignedReceiverInformation, (matchValue == null) ? (new RequestedValue$1(0, [])) : (new RequestedValue$1(1, [matchValue.ReceiverName])))), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const matchValue = state.Tool.AssignedReceiverInformation;
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                const assignedReceiver = matchValue;
                const dto = new UnassignToolWithoutNotificationDto(state.Comment);
                const toolId = new ToolId(parse(state.Tool.Id));
                return [new State(state.Tool, state.Comment, state.UserId, state.SuccessCallback, state.OnClose, FormState__startLoading(state.FormState), state.ReceiverName), Cmd_unassignCmd(toolId, dto, (Item_1) => (new Msg(0, [Item_1])), (Item_2) => (new Msg(3, [Item_2])))];
            }
        }
        case 2:
            return [new State(state.Tool, msg.fields[0], state.UserId, state.SuccessCallback, state.OnClose, state.FormState, state.ReceiverName), Cmd_none()];
        case 3:
            return [state, Cmd_none()];
        default: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.Tool, state.Comment, state.UserId, state.SuccessCallback, state.OnClose, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0]), state.ReceiverName), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.Tool, state.Comment, state.UserId, state.SuccessCallback, state.OnClose, FormState_get_empty(), state.ReceiverName), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
    }
}

function DialogBody(props) {
    let elems_1;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-5"])], (elems_1 = toList(delay(() => {
        let arg_1;
        return append(singleton(createElement("p", {
            children: (arg_1 = {
                user: props.ReceiverName,
            }, patternInput[1].t("tool.dialog_unassign_tool_query", arg_1)),
        })), delay(() => {
            let elems;
            return append(singleton(createElement("div", createObj(singleton_1((elems = [createElement(TextArea, {
                ComponentState: componentState,
                Label: t("general.comment"),
                OnChange: props.SetComment,
                TestId: "unassign-tool-dialog-comment-test-id",
                Value: props.Comment,
            })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), delay(() => {
                const matchValue = FormState__getValidation(props.FormState, t, "global");
                if (matchValue == null) {
                    return empty();
                }
                else {
                    return singleton(createElement(ErrorAlert, {
                        Label: matchValue,
                    }));
                }
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function UnassignDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        Comment: "",
        OnClose: props.OnClose,
        SuccessCallback: props.SuccessCallback,
        Tool: props.Tool,
        UserId: props.UserId,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    if (props.Tool.AssignedReceiverInformation == null) {
        return defaultOf();
    }
    else {
        return createElement(Dialog, (Title = t("tool.back_to_storage"), {
            Body: toList(delay(() => {
                const matchValue_1 = state_1.ReceiverName;
                return (matchValue_1.tag === 0) ? singleton(createElement(Skeleton, {
                    Variant: "normal",
                })) : singleton(createElement(DialogBody, {
                    Comment: state_1.Comment,
                    FormState: state_1.FormState,
                    ReceiverName: matchValue_1.fields[0],
                    SetComment: (value) => {
                        dispatch(new Msg(2, [value]));
                    },
                }));
            })),
            Controls: ofArray([createElement(TextButton, {
                ComponentState: "enabled",
                Label: t("general.cancel"),
                OnClick: props.OnClose,
                TestId: "close-reservation-dialog-test-id",
                Variant: "blueButton",
            }), createElement(TextButton, {
                ComponentState: state_1.FormState.IsLoading ? "disabled" : "enabled",
                Label: t("general.save"),
                OnClick: () => {
                    dispatch(new Msg(1, []));
                },
                TestId: "save-unassign-dialog-test-id",
                Variant: "blueButton",
            })]),
            IsOpen: props.IsOpen,
            OnClose: props.OnClose,
            Title: Title,
        }));
    }
}

