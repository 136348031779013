import { RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getStocktakings } from "../../Requests/Stocktaking.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";

export const initOverviewStocktaking = [new State(new RequestedValue$1(0, [])), Cmd_batch(singleton(getStocktakings((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(1, [Item_1])))))];

export function update(msg, state) {
    if (msg.tag === 1) {
        return [state, Cmd_none()];
    }
    else {
        return [new State(new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
    }
}

