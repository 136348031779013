import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { PostResponse$1_$reflection, LabelResponse_$reflection, RequestedValue$1_$reflection, Label_$reflection } from "../../Shared/Shared.js";
import { class_type, bool_type, option_type, list_type, record_type, string_type, union_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class LabelForm extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["LabelForm"];
    }
}

export function LabelForm_$reflection() {
    return union_type("Settings.Categories.Types.LabelForm", [], LabelForm, () => [[["Item", Label_$reflection()]]]);
}

export class LabelRow extends Record {
    constructor(Id, Name, ColorHex) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.ColorHex = ColorHex;
    }
}

export function LabelRow_$reflection() {
    return record_type("Settings.Categories.Types.LabelRow", [], LabelRow, () => [["Id", string_type], ["Name", string_type], ["ColorHex", string_type]]);
}

export class State extends Record {
    constructor(Categories, LabelTable, LabelForm, Message, IsValidated, IsLabelColorSelectorOpen) {
        super();
        this.Categories = Categories;
        this.LabelTable = LabelTable;
        this.LabelForm = LabelForm;
        this.Message = Message;
        this.IsValidated = IsValidated;
        this.IsLabelColorSelectorOpen = IsLabelColorSelectorOpen;
    }
}

export function State_$reflection() {
    return record_type("Settings.Categories.Types.State", [], State, () => [["Categories", RequestedValue$1_$reflection(list_type(Label_$reflection()))], ["LabelTable", list_type(LabelRow_$reflection())], ["LabelForm", LabelForm_$reflection()], ["Message", option_type(string_type)], ["IsValidated", bool_type], ["IsLabelColorSelectorOpen", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OpenNewLabelForm", "SetName", "SetColorHex", "SetVisibleForUser", "SaveLabel", "UpdateLabel", "ToggleColorSelector", "FetchCategories", "LabelFetched", "LabelsFetched", "LabelSaved", "LabelDeleted", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Settings.Categories.Types.Msg", [], Msg, () => [[], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", class_type("Browser.Types.MouseEvent", undefined)]], [["Item", class_type("Browser.Types.MouseEvent", undefined)]], [], [], [["Item", Label_$reflection()]], [["Item", list_type(Label_$reflection())]], [["Item", PostResponse$1_$reflection(LabelResponse_$reflection())]], [], [["Item", class_type("System.Exception")]]]);
}

