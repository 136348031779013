import { Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../CommunicationV2.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Testphase_$reflection, AccountStatistic_$reflection, NewCustomerDto_$reflection, PostResponse$1_$reflection, CreateNewCustomerResponse_$reflection, AccountSettings_$reflection } from "../Shared/Shared.js";
import { ValidationOnGetRequest } from "../ErrorHandling.js";
import { addPrefix as addPrefix_1, fetchWithDecoder as fetchWithDecoder_1 } from "../Communication.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { CreateStripeInvoicePaymentDto_$reflection, CreateStripeCustomerAndSubscriptionDto_$reflection, CreateCustomerAndSubscriptionResponse_$reflection, StripeCustomer_$reflection } from "../Shared/Customer.js";
import { string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export function getAccountSettings(successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), AccountSettings_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), "/api/customer", successMsg, errorMsg);
}

export function createCustomerBooking(data, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(CreateNewCustomerResponse_$reflection())));
    })))), ["/api/customer/bookings/newcustomer", toString(0, Auto_generateBoxedEncoder_437914C6(NewCustomerDto_$reflection(), undefined, undefined, undefined)(data))], successMsg, errorMsg);
}

export function getStripeCustomer(stripeCustomerId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), StripeCustomer_$reflection()));
    })))), `/api/customer/stripe/customer/${stripeCustomerId}`, successMsg, errorMsg);
}

export function createStripeCustomer(data, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), CreateCustomerAndSubscriptionResponse_$reflection()));
    })))), ["/api/customer/stripe/customer", toString(0, Auto_generateBoxedEncoder_437914C6(CreateStripeCustomerAndSubscriptionDto_$reflection(), undefined, undefined, undefined)(data))], successMsg, errorMsg);
}

export function createCustomerStripeSubscription(data, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
    })))), ["/api/customer/bookings/customer/stripe", toString(0, Auto_generateBoxedEncoder_437914C6(NewCustomerDto_$reflection(), undefined, undefined, undefined)(data))], successMsg, errorMsg);
}

export function createStripeInvoicePayment(data, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
    })))), ["/api/customer/stripe/payment/invoice", toString(0, Auto_generateBoxedEncoder_437914C6(CreateStripeInvoicePaymentDto_$reflection(), undefined, undefined, undefined)(data))], successMsg, errorMsg);
}

export function getCustomerPortalUrl(successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), "/api/customer/stripe/portal", successMsg, errorMsg);
}

export function getAccountStatistic(successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), AccountStatistic_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), "/api/customer/statistic", successMsg, errorMsg);
}

export function getTestphase(successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Testphase_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), "/api/customer/testphase", successMsg, errorMsg);
}

