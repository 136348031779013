import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { AreaAnchorMenu } from "../../Widgets/AnchorMenu.js";
import { singleton, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { SectionContainer } from "../../Widgets/Container.js";
import { PermissionsView } from "../Permissions/View.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Receivers } from "../Receivers/View.js";

export function SystemSettingsView() {
    let elems_4, elems, elems_3, elems_1, Title, elems_2, Title_1;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState("permissions-section");
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[10rem,min(calc(100vw-16rem-2px-28px-11rem),80rem)]"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(AreaAnchorMenu, {
        Anchors: ofArray([{
            Id: "permissions-section",
            Label: t("settings.permissions"),
        }, {
            Id: "receivers-section",
            Label: t("settings.receivers"),
        }]),
        OnSelectAnchor: patternInput_1[1],
        SelectedAnchor: patternInput_1[0],
    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(singleton((elems_3 = [createElement("section", createObj(ofArray([["id", "permissions-section"], (elems_1 = [createElement(SectionContainer, (Title = t("settings.permissions"), {
        Content: createElement(PermissionsView, null),
        Title: unwrap(Title),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("section", createObj(ofArray([["id", "receivers-section"], (elems_2 = [createElement(SectionContainer, (Title_1 = t("settings.receivers"), {
        Content: createElement(Receivers, null),
        Title: unwrap(Title_1),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))]))))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

