import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { singleton, empty, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { NewCategoryDialog, EditCategoryDialog } from "../../Widgets/Dialogs/NewCategoryDialog.js";
import { Msg } from "./Types.js";
import { CategoryDeleteDialog } from "./Dialogs/CategoryDeleteDialog.js";
import { TableControl$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { ofArray, tryHead, singleton as singleton_1, toArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Label as Label_1 } from "../../Shared/Shared.js";
import { Checkbox } from "../../Components/Checkbox.js";
import Pencil from "../../../public/assets/icons/Pencil.svg";
import Recycle_Bin_1 from "../../../public/assets/icons/Recycle_Bin_1.svg";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Button } from "../../Components/Button.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, initOverview } from "./State.js";
import { Skeleton } from "../../Components/Skeleton.js";

export function CategoriesTable(props) {
    let elems;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(undefined);
    const setSelectedLabel = patternInput_1[1];
    const patternInput_2 = reactApi.useState(undefined);
    const setSelectedDeleteLabel = patternInput_2[1];
    return createElement("div", createObj(singleton_1((elems = toList(delay(() => {
        let matchValue;
        return append((matchValue = patternInput_1[0], (matchValue == null) ? (empty()) : singleton(createElement(EditCategoryDialog, {
            IsOpen: true,
            Label: matchValue,
            OnClose: () => {
                setSelectedLabel(undefined);
                props.Dispatch(new Msg(7, []));
            },
        }))), delay(() => {
            let matchValue_1;
            return append((matchValue_1 = patternInput_2[0], (matchValue_1 == null) ? (empty()) : singleton(createElement(CategoryDeleteDialog, {
                IsOpen: true,
                LabelId: matchValue_1.Id,
                OnClose: () => {
                    setSelectedDeleteLabel(undefined);
                },
                SuccessCallback: () => {
                    props.Dispatch(new Msg(7, []));
                },
            }))), delay(() => {
                let TableRows, Options;
                return singleton(createElement(Table, (TableRows = toArray(props.Categories), (Options = {
                    BorderStyle: "full",
                    CSVDownload: false,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["name", "asc"],
                    GlobalSearch: false,
                    RowSelection: "singleRow",
                }, {
                    ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.category"), (category) => (new CellContent(0, [category.Name])), "none", "text"), new ColumnDefinition$1("label_preview", "", t("settings.label_preview"), (category_1) => (new CellContent(3, [singleton_1(new Label_1(category_1.Id, category_1.Name, category_1.ColorHex, category_1.VisibleForUserRole))])), "none", "text"), new ColumnDefinition$1("label_visible_for_user", "", t("settings.label_visible_for_user"), (category_2) => (new CellContent(1, [Checkbox({
                        ComponentState: "enabled",
                        IsChecked: category_2.VisibleForUserRole,
                        Label: "",
                        OnCheck: (_arg_3) => {
                        },
                        TestId: "",
                    })])), "none", "text")],
                    Controls: ofArray([new TableControl$1(t("general.edit"), Pencil(), (rows) => {
                        setSelectedLabel(tryHead(rows));
                    }, true, false, "table-category-edit"), new TableControl$1(t("general.delete"), Recycle_Bin_1(), (rows_1) => {
                        setSelectedDeleteLabel(tryHead(rows_1));
                    }, true, false, "table-category-delete")]),
                    Options: Options,
                    TableRows: TableRows,
                }))));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems))]))));
}

export function CategoriesOverview(props) {
    let elems_2, elems_1, elems;
    const patternInput = useTranslation();
    const patternInput_1 = reactApi.useState(false);
    const setNewCategoryDialogIsOpen = patternInput_1[1];
    return createElement("div", createObj(ofArray([["className", "tab-pane flex-grow-1 active"], (elems_2 = [createElement(NewCategoryDialog, {
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setNewCategoryDialogIsOpen(false);
            props.Dispatch(new Msg(7, []));
        },
    }), createElement("div", createObj(ofArray([["className", join(" ", ["d-flex", "flex-column"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "justify-end", "mb-3", "controls"])], (elems = [createElement(Button, {
        ComponentState: "enabled",
        Label: patternInput[0]("settings.create_new_label"),
        OnClick: () => {
            setNewCategoryDialogIsOpen(true);
        },
        TestId: "settings-create-new-label-button",
        Variant: "primary",
    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(CategoriesTable, {
        Categories: props.Categories,
        Dispatch: props.Dispatch,
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function CategoriesOverviewView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(initOverview, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const matchValue = patternInput[0].Categories;
    if (matchValue.tag === 0) {
        return createElement(Skeleton, {
            Variant: "table",
        });
    }
    else {
        return createElement(CategoriesOverview, {
            Categories: matchValue.fields[0],
            Dispatch: patternInput[1],
        });
    }
}

