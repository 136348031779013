import { toString, Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, lambda_type, unit_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { createElement } from "react";
import React from "react";
import { defaultOf, equals, createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { map as map_1, empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextValue } from "../Components/Text.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { cons, singleton as singleton_1, choose, map, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { InformationAlert, WarningAlert, ErrorAlert } from "../Components/Alert.js";
import { Button, TextButton } from "../Components/Button.js";
import { useTranslation } from "react-i18next";
import { nativeType, useDrop } from "../Bindings/Fable.ReactDnD.js";
import { item, equalsWith } from "../fable_modules/fable-library-js.4.19.2/Array.js";
import { FSharpMap__get_Item } from "../fable_modules/fable-library-js.4.19.2/Map.js";
import { rangeDouble } from "../fable_modules/fable-library-js.4.19.2/Range.js";
import { SectionContainer } from "./Container.js";
import { defaultOf as defaultOf_1 } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { Skeleton } from "../Components/Skeleton.js";
import { unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";

export class TemplateType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LocalCsv", "DownloadApi"];
    }
}

export function TemplateType_$reflection() {
    return union_type("Widgets.Importer.TemplateType", [], TemplateType, () => [[["Item", string_type]], [["Item", lambda_type(unit_type, unit_type)]]]);
}

export function FailedTd(failedTdInputProps) {
    let elems;
    const toolTipMsg = failedTdInputProps.toolTipMsg;
    const value = failedTdInputProps.value;
    const colClasses = failedTdInputProps.colClasses;
    const t = failedTdInputProps.t;
    return createElement("td", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("bg-light-red"), delay(() => colClasses)))))], (elems = [value, createElement(TextValue, {
        FontWeight: "bold",
        TestId: "",
        Text: t(toolTipMsg),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function tdString(t, colClasses, validatedCell) {
    const matchValue = validatedCell.ValidationResult;
    if (matchValue.tag === 1) {
        return createElement(FailedTd, {
            t: t,
            colClasses: colClasses,
            value: validatedCell.Value,
            toolTipMsg: matchValue.fields[0],
        });
    }
    else {
        return createElement("td", {
            className: join(" ", toList(delay(() => colClasses))),
            children: validatedCell.Value,
        });
    }
}

function optionalAlert(i, t, header, validatedCell) {
    let elems;
    const matchValue = validatedCell.ValidationResult;
    if (matchValue.tag === 1) {
        const msg_1 = t(matchValue.fields[0]);
        return createElement("div", createObj(ofArray([["className", join(" ", ["w-[35rem]", "p-2"])], (elems = [createElement(ErrorAlert, {
            Label: `${toString(i)} | ${header} | ${msg_1}`,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
    }
    else {
        return undefined;
    }
}

export function downloadFileArea(t, csvFileName, currentUserLanguage) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["mt-4", "grid", "gap-4"])], (elems = toList(delay(() => append(singleton(createElement(TextValue, {
        FontWeight: "bold",
        TestId: "",
        Text: t("importer.file_template"),
    })), delay(() => append(singleton(createElement(TextValue, {
        FontWeight: "normal",
        TestId: "",
        Text: t("importer.file_download_description"),
    })), delay(() => append(singleton(createElement(TextButton, {
        ComponentState: "enabled",
        Label: t("importer.tool_help_text"),
        OnClick: () => {
            window.open((currentUserLanguage === "en") ? "https://help.inventory-one.com/help/en-gb/22-importer-exporter" : ((currentUserLanguage === "fr") ? "https://help.inventory-one.com/help/en-gb/22-importer-exporter" : ((currentUserLanguage === "it") ? "https://help.inventory-one.com/help/en-gb/22-importer-exporter" : ((currentUserLanguage === "uk") ? "https://help.inventory-one.com/help/en-gb/22-importer-exporter" : ((currentUserLanguage === "pl") ? "https://help.inventory-one.com/help/en-gb/22-importer-exporter" : "https://help.inventory-one.com/help/de-de/22-importer-exporter")))), "_blank", "noreferrer");
        },
        TestId: "widgets-importer-toolhelp-button",
        Variant: "default",
    })), delay(() => {
        const matchValue = csvFileName;
        return (matchValue.tag === 1) ? singleton(createElement(TextButton, {
            ComponentState: "enabled",
            Label: "Download",
            OnClick: matchValue.fields[0],
            TestId: "download-template",
            Variant: "blueButton",
        })) : singleton(createElement("a", {
            className: join(" ", ["text-primary-link", "flex", "content-center", "cursor-pointer", "text-sm", "font-medium", "flex", "items-center", "text-primary-link"]),
            href: `/assets/templates/${matchValue.fields[0]}`,
            download: true,
            children: "Download",
        }));
    })))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function UploadArea(props) {
    let elems_3, elems, elems_2, elems_1;
    const patternInput = useTranslation();
    const validateFile = (file) => {
        const formData = new FormData();
        formData.append(file.name, file);
        props.ValidateFormData(formData);
    };
    const patternInput_1 = useDrop({
        accept: nativeType.FILE,
        collect: (monitor) => {
            const isOver = monitor.isOver();
            return {
                canDrop: monitor.canDrop(),
                isOver: isOver,
            };
        },
        drop: (draggedFile) => {
            let testExpr, testExpr_1;
            const matchValue = draggedFile.files;
            if (matchValue == null) {
            }
            else if ((testExpr = matchValue, !equalsWith(equals, testExpr, defaultOf()) && (testExpr.length === 0))) {
            }
            else if ((testExpr_1 = matchValue, !equalsWith(equals, testExpr_1, defaultOf()) && (testExpr_1.length === 1))) {
                const file_1 = item(0, matchValue);
                if ("text/csv" === file_1.type) {
                    validateFile(file_1);
                }
            }
        },
    });
    const dropData = patternInput_1[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems = [createElement(TextValue, {
        FontWeight: "bold",
        TestId: "",
        Text: "Upload",
    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("border"), delay(() => append(singleton("rounded"), delay(() => append(singleton("border-dashed"), delay(() => append(singleton("border-primary"), delay(() => append(singleton("p-4"), delay(() => ((dropData.isOver && dropData.canDrop) ? singleton("bg-background") : empty()))))))))))))))], ["ref", patternInput_1[1]], (elems_2 = [createElement(TextButton, {
        ComponentState: "enabled",
        Label: patternInput[0]("general.select_file"),
        OnClick: () => {
            const element = document.getElementById("file-upload");
            element.click();
        },
        TestId: "open-file-explorer-test-id",
        Variant: "blueButton",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "justify-center"])], (elems_1 = [createElement("input", {
        id: "file-upload",
        className: join(" ", ["hidden"]),
        type: "file",
        accept: ".csv, .CSV",
        onChange: (ev) => {
            const files = ev.target.files;
            if (!(files == null) && (files.length > 0)) {
                validateFile(files.item(0));
            }
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

function failResultArea(t, data) {
    const rowList = map((i) => {
        const children = choose((header) => optionalAlert(i, t, header, FSharpMap__get_Item(data.Rows, [i, header])), data.Headers);
        return createElement("li", {
            children: reactApi.Children.toArray(Array.from(children)),
        });
    }, toList(rangeDouble(2, 1, data.RowsLength)));
    const children_5 = singleton_1(createElement("ul", {
        children: reactApi.Children.toArray(Array.from(rowList)),
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_5)),
    });
}

export function resultArea(t, data) {
    let elems_1, elems, children_5;
    const headerClasses = ofArray(["p-4", "border-b", "border-border"]);
    const colClasses = ofArray(["p-4", "border-b", "border-border"]);
    const header = cons(createElement("th", {
        className: join(" ", toList(delay(() => headerClasses))),
        children: "Zeile",
    }), toList(delay(() => map_1((value_3) => createElement("th", {
        className: join(" ", toList(delay(() => headerClasses))),
        children: value_3,
    }), data.Headers))));
    const rowList = map((number) => {
        const children = cons(createElement("td", {
            className: join(" ", toList(delay(() => colClasses))),
            children: `${number}`,
        }), map((header_1) => tdString(t, colClasses, FSharpMap__get_Item(data.Rows, [number, header_1])), data.Headers));
        return createElement("tr", {
            children: reactApi.Children.toArray(Array.from(children)),
        });
    }, toList(rangeDouble(2, 1, data.RowsLength)));
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement("table", createObj(ofArray([["className", join(" ", [])], (elems = [(children_5 = singleton_1(createElement("tr", {
        children: reactApi.Children.toArray(Array.from(header)),
    })), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(rowList)),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function csvErrorAlert(t, csvError) {
    let elems;
    return createElement("div", createObj(ofArray([["className", "flex-grow-1"], (elems = [createElement(ErrorAlert, {
        Label: (csvError.tag === 1) ? "importer.fail_no_headers" : ((csvError.tag === 2) ? "importer.fail_headers_not_equal" : "importer.fail_no_rows"),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function Importer(props) {
    let elems_8, elems_1, elems, Title_1, elems_7;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4", "max-w-[75rem]"])], (elems_8 = [createElement(SectionContainer, {
        Content: createElement("div", createObj(singleton_1((elems_1 = [createElement(WarningAlert, {
            Label: "importer.info_text",
        }), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2", "gap-8"])], (elems = [downloadFileArea(t, props.CSVFileName, props.CurrentUserLanguage), createElement(UploadArea, {
            ValidateFormData: props.ValidateFormData,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])))),
        Title: props.HeaderTitle,
    }), createElement(SectionContainer, (Title_1 = t("importer.uploaded_data"), {
        Content: createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_7 = toList(delay(() => {
            let elems_3;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_3 = toList(delay(() => {
                let elems_2;
                const matchValue = props.ImportData;
                return (matchValue == null) ? singleton(defaultOf_1()) : (matchValue.IsImported ? singleton(defaultOf_1()) : (!!props.IsImportBtnEnabled ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["ml-auto"])], (elems_2 = [createElement(Button, {
                    ComponentState: "enabled",
                    Label: t("importer.save"),
                    OnClick: props.OnImportData,
                    TestId: "passwordreset-login-reset-button",
                    Variant: "primary",
                })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))) : empty()));
            })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                let elems_6, elems_5, elems_4, children;
                if (props.IsImportActive) {
                    return singleton(createElement(Skeleton, {
                        Variant: "table",
                    }));
                }
                else {
                    const matchValue_1 = props.ValidateErrorMessage;
                    if (matchValue_1 != null) {
                        const msg = matchValue_1;
                        return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_6 = [createElement(ErrorAlert, {
                            Label: "importer.validation_failed",
                        }), createElement(WarningAlert, {
                            Label: msg,
                        })], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))));
                    }
                    else {
                        const matchValue_2 = props.ImportData;
                        if (matchValue_2 == null) {
                            return singleton(createElement("div", createObj(ofArray([["className", "flex-grow-1"], (elems_5 = [createElement(InformationAlert, {
                                Label: "importer.no_data_imported_msg",
                            })], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))));
                        }
                        else {
                            const importData_1 = matchValue_2;
                            const matchValue_3 = importData_1.CsvErrorImportType;
                            return (matchValue_3 == null) ? (importData_1.IsImported ? singleton(createElement("div", createObj(ofArray([["className", "flex-grow-1"], (elems_4 = [createElement(InformationAlert, {
                                Label: "importer.successful_imported",
                            })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))) : singleton((children = ofArray([failResultArea(t, importData_1), resultArea(t, importData_1)]), createElement("div", {
                                children: reactApi.Children.toArray(Array.from(children)),
                            })))) : singleton(csvErrorAlert(t, matchValue_3));
                        }
                    }
                }
            }));
        })), ["children", reactApi.Children.toArray(Array.from(elems_7))])]))),
        Title: unwrap(Title_1),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])));
}

