import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, ToolId as ToolId_1, FormState_get_empty, FormState_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../../CommunicationV2.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { ToolStateChangedDto, ToolDetailDto_$reflection, ToolStateChangedDto_$reflection } from "../../Shared/Tool.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextArea } from "../../Components/TextArea.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateState", "SetComment", "StateUpdated", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Tools.ToolStateChangeDialog.Msg", [], Msg, () => [[], [["Item", string_type]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

export function putToolStateChangedCmd(dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), ["/api/tools/state", toString(0, Auto_generateBoxedEncoder_437914C6(ToolStateChangedDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])));
}

export class State extends Record {
    constructor(Tool, ToolState, Comment$, OnClose, SuccessCallback, FormState) {
        super();
        this.Tool = Tool;
        this.ToolState = ToolState;
        this.Comment = Comment$;
        this.OnClose = OnClose;
        this.SuccessCallback = SuccessCallback;
        this.FormState = FormState;
    }
}

export function State_$reflection() {
    return record_type("Tools.ToolStateChangeDialog.State", [], State, () => [["Tool", ToolDetailDto_$reflection()], ["ToolState", string_type], ["Comment", string_type], ["OnClose", lambda_type(unit_type, unit_type)], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["FormState", FormState_$reflection()]]);
}

export function labelByState(t, newToolState) {
    switch (newToolState) {
        case "inMaintenance":
            return t("tool.in_maintenance_tool");
        case "retired":
            return t("tool.retire_tool");
        case "lost":
            return t("tool.lost_tool");
        default:
            return t("tool.activate_tool");
    }
}

export function init(props) {
    return [new State(props.Tool, props.ToolState, "", props.OnClose, props.SuccessCallback, FormState_get_empty()), Cmd_none()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 0:
            return [state, putToolStateChangedCmd(new ToolStateChangedDto(new ToolId_1(parse(state.Tool.Id)), state.ToolState, state.Comment))];
        case 2: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.Tool, state.ToolState, state.Comment, state.OnClose, state.SuccessCallback, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.Tool, state.ToolState, state.Comment, state.OnClose, state.SuccessCallback, FormState_get_empty()), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 3:
            return [state, Cmd_none()];
        default:
            return [new State(state.Tool, state.ToolState, msg.fields[0], state.OnClose, state.SuccessCallback, state.FormState), Cmd_none()];
    }
}

export function DialogBody(props) {
    let elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-5"])], (elems = toList(delay(() => append(singleton(createElement(TextArea, {
        ComponentState: props.FormState.IsLoading ? "disabled" : "enabled",
        Label: t("general.comment"),
        OnChange: props.SetComment,
        TestId: "tool-state-change-dialog-comment-test-id",
        Value: props.Comment,
    })), delay(() => {
        const matchValue = FormState__getValidation(props.FormState, t, "global");
        if (matchValue == null) {
            return empty();
        }
        else {
            return singleton(createElement(ErrorAlert, {
                Label: matchValue,
            }));
        }
    })))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function ToolStateChangeDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.OnClose]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t(labelByState(t, props.ToolState)), {
        Body: singleton_1(createElement(DialogBody, {
            Comment: state_1.Comment,
            FormState: state_1.FormState,
            SetComment: (value) => {
                dispatch(new Msg(1, [value]));
            },
            SuccessCallback: props.SuccessCallback,
            Tool: props.Tool,
            ToolState: props.ToolState,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-state-change-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(0, []));
            },
            TestId: "save-state-change-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

