import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { lambda_type, unit_type, class_type, anonRecord_type, record_type, list_type, option_type, float64_type, string_type, union_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName, Receiver_$reflection } from "../../Shared/User.js";
import { FormState__getValidation, FormState__startLoading, StorageId, FormState__setValidationResponse_5219762A, ToolId__get_unwrap, RequestedValue$1, FormState_get_empty, RequestedValue$1_$reflection, FormState_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { DataTransfer_DetailSet_$reflection } from "../../Shared/Set.js";
import { Helper_unitToTranslationKey, Helper_currentReceiverStock, Consumable_$reflection } from "../../Shared/Consumable.js";
import { Helper_quantityAsStringWithComma, Helper_toolId, ToolTypes_$reflection } from "../../Shared/Tool.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { MultiUnassignToolWithQuantityDto, MultiUnassignConsumableDto, MultiUnassignSetDto, MultiUnassignToolDto, MultiUnassignDto, MultiUnassignDto_$reflection } from "../../Shared/MultiOperation.js";
import { length, sortBy, toArray as toArray_1, exists, append as append_1, tryHead, choose, contains, collect, tryFind, filter, cons, find, ofArray, singleton as singleton_1, map, empty, isEmpty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Cmd_none, Cmd_ofEffect, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { iterate, toArray, empty as empty_1, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { getSelectedSets } from "../../Requests/Set.js";
import { getSelectedConsumables } from "../../Requests/Consumable.js";
import { Cmd_getSelectedTools } from "../../Requests/Tool.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { toArray as toArray_2, unwrap, defaultArg, map as map_1 } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { unwrapConsumableId, unwrapStorageId } from "../../Shared/Helper.js";
import { createObj, comparePrimitives, int32ToString, stringHash, safeHash, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { parse, newGuid } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../CommunicationV2.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FSharpMap__get_IsEmpty } from "../../fable_modules/fable-library-js.4.19.2/Map.js";
import { SectionHeader, InvalidTextValue } from "../../Components/Text.js";
import { TableControl$1, Table as Table_1, ColumnDefinition$1, CellContent } from "../Table/Table.js";
import { NumberInput } from "../../Components/Input.js";
import { numberInputIntMaxValue } from "../../Common.js";
import { List_groupBy } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { Select, GroupedSelect } from "../../Components/Select.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { TabsMenu } from "../TabsMenu.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import Recycle_Bin_1 from "../../../public/assets/icons/Recycle_Bin_1.svg";
import { TextArea } from "../../Components/TextArea.js";
import { Button } from "../../Components/Button.js";
import { WarningAlert } from "../../Components/Alert.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ModalSheet } from "../../Components/ModalSheet.js";
import { Skeleton } from "../../Components/Skeleton.js";

export class TableElementType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Tool", "ToolWithQuantity", "Consumable", "Set"];
    }
}

export function TableElementType_$reflection() {
    return union_type("Widgets.Modals.MultiUnassignModal.TableElementType", [], TableElementType, () => [[], [], [], []]);
}

export class TableElement extends Record {
    constructor(Id, ElementId, Manufacturer, Model, Name, AdditionalName, InventoryNumber, Quanity, Type, Unit, SelectedStorageId, AvailableStorageIds, CurrentSelectedSenderId, AvailableSenderIds) {
        super();
        this.Id = Id;
        this.ElementId = ElementId;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.Name = Name;
        this.AdditionalName = AdditionalName;
        this.InventoryNumber = InventoryNumber;
        this.Quanity = Quanity;
        this.Type = Type;
        this.Unit = Unit;
        this.SelectedStorageId = SelectedStorageId;
        this.AvailableStorageIds = AvailableStorageIds;
        this.CurrentSelectedSenderId = CurrentSelectedSenderId;
        this.AvailableSenderIds = AvailableSenderIds;
    }
}

export function TableElement_$reflection() {
    return record_type("Widgets.Modals.MultiUnassignModal.TableElement", [], TableElement, () => [["Id", string_type], ["ElementId", string_type], ["Manufacturer", string_type], ["Model", string_type], ["Name", string_type], ["AdditionalName", string_type], ["InventoryNumber", string_type], ["Quanity", option_type(float64_type)], ["Type", TableElementType_$reflection()], ["Unit", string_type], ["SelectedStorageId", option_type(string_type)], ["AvailableStorageIds", list_type(string_type)], ["CurrentSelectedSenderId", option_type(string_type)], ["AvailableSenderIds", list_type(string_type)]]);
}

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Unassign", "SetComment", "SetQuantity", "SetStorage", "SetSelectedSender", "AddElement", "RemoveElement", "InitTableElements", "UnassignedResponse", "SetsFetched", "ConsumableFetched", "ToolsFetched", "ReceiversFetched", "StoragesFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Widgets.Modals.MultiUnassignModal.Msg", [], Msg, () => [[], [["Item", string_type]], [["id", string_type], ["quantity", option_type(float64_type)]], [["id", string_type], ["storageId", option_type(string_type)]], [["id", string_type], ["Item2", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))]], [["id", string_type]], [["id", string_type]], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", list_type(DataTransfer_DetailSet_$reflection())]], [["Item", list_type(Consumable_$reflection())]], [["Item", list_type(ToolTypes_$reflection())]], [["Item", list_type(Receiver_$reflection())]], [["Item", list_type(Storage_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(FormState, SuccessCallback, OnClose, Receivers, Sets, Consumables, Storages, Tools, TableElements, RemovedElements, ToolWithQuantityReceiverStocks, ConsumableReceiverStocks, Dto, SenderId) {
        super();
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Receivers = Receivers;
        this.Sets = Sets;
        this.Consumables = Consumables;
        this.Storages = Storages;
        this.Tools = Tools;
        this.TableElements = TableElements;
        this.RemovedElements = RemovedElements;
        this.ToolWithQuantityReceiverStocks = ToolWithQuantityReceiverStocks;
        this.ConsumableReceiverStocks = ConsumableReceiverStocks;
        this.Dto = Dto;
        this.SenderId = SenderId;
    }
}

function State_$reflection() {
    return record_type("Widgets.Modals.MultiUnassignModal.State", [], State, () => [["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["Sets", RequestedValue$1_$reflection(list_type(DataTransfer_DetailSet_$reflection()))], ["Consumables", RequestedValue$1_$reflection(list_type(Consumable_$reflection()))], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["Tools", RequestedValue$1_$reflection(list_type(ToolTypes_$reflection()))], ["TableElements", list_type(TableElement_$reflection())], ["RemovedElements", list_type(TableElement_$reflection())], ["ToolWithQuantityReceiverStocks", list_type(anonRecord_type(["ReceiverId", string_type], ["Stock", string_type]))], ["ConsumableReceiverStocks", list_type(anonRecord_type(["ReceiverId", string_type], ["Stock", string_type]))], ["Dto", MultiUnassignDto_$reflection()], ["SenderId", option_type(string_type)]]);
}

function init(props) {
    return [new State(FormState_get_empty(), props.SuccessCallback, props.OnClose, new RequestedValue$1(0, []), isEmpty(props.UnassignProps.SetIds) ? (new RequestedValue$1(1, [empty()])) : (new RequestedValue$1(0, [])), isEmpty(props.UnassignProps.ConsumableIds) ? (new RequestedValue$1(1, [empty()])) : (new RequestedValue$1(0, [])), new RequestedValue$1(0, []), isEmpty(props.UnassignProps.ToolIds) ? (new RequestedValue$1(1, [empty()])) : (new RequestedValue$1(0, [])), empty(), empty(), empty(), empty(), new MultiUnassignDto(empty(), empty(), empty(), empty(), ""), props.SenderId), Cmd_batch(toList(delay(() => append(!isEmpty(props.UnassignProps.SetIds) ? singleton(getSelectedSets(props.UnassignProps.SetIds, (Item) => (new Msg(9, [Item])), (Item_1) => (new Msg(14, [Item_1])))) : empty_1(), delay(() => append(!isEmpty(props.UnassignProps.ConsumableIds) ? singleton(getSelectedConsumables(props.UnassignProps.ConsumableIds, (Item_2) => (new Msg(10, [Item_2])), (Item_3) => (new Msg(14, [Item_3])))) : empty_1(), delay(() => append(!isEmpty(props.UnassignProps.ToolIds) ? singleton(Cmd_getSelectedTools(map(ToolId__get_unwrap, props.UnassignProps.ToolIds), (Item_4) => (new Msg(11, [Item_4])), (Item_5) => (new Msg(14, [Item_5])))) : empty_1(), delay(() => append(singleton(Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton_1(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item_6) => (new Msg(12, [Item_6])), (Item_7) => (new Msg(14, [Item_7])))), delay(() => singleton(Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton_1(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
    })))), "/api/storages/access", (Item_8) => (new Msg(13, [Item_8])), (Item_9) => (new Msg(14, [Item_9])))))))))))))))];
}

function update(msg, state) {
    let bind$0040_1, matchValue, senderId;
    switch (msg.tag) {
        case 8: {
            const response_1 = msg.fields[0];
            if (response_1.tag === 1) {
                return [new State(FormState__setValidationResponse_5219762A(state.FormState, response_1.fields[0]), state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, state.Consumables, state.Storages, state.Tools, state.TableElements, state.RemovedElements, state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(FormState_get_empty(), state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, state.Consumables, state.Storages, state.Tools, state.TableElements, state.RemovedElements, state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
        case 3:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, state.Consumables, state.Storages, state.Tools, map((tableElement) => {
                if (tableElement.Id === msg.fields[0]) {
                    return new TableElement(tableElement.Id, tableElement.ElementId, tableElement.Manufacturer, tableElement.Model, tableElement.Name, tableElement.AdditionalName, tableElement.InventoryNumber, tableElement.Quanity, tableElement.Type, tableElement.Unit, msg.fields[1], tableElement.AvailableStorageIds, tableElement.CurrentSelectedSenderId, tableElement.AvailableSenderIds);
                }
                else {
                    return tableElement;
                }
            }, state.TableElements), state.RemovedElements, state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), Cmd_none()];
        case 2:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, state.Consumables, state.Storages, state.Tools, map((tableElement_1) => {
                if (tableElement_1.Id === msg.fields[0]) {
                    return new TableElement(tableElement_1.Id, tableElement_1.ElementId, tableElement_1.Manufacturer, tableElement_1.Model, tableElement_1.Name, tableElement_1.AdditionalName, tableElement_1.InventoryNumber, msg.fields[1], tableElement_1.Type, tableElement_1.Unit, tableElement_1.SelectedStorageId, tableElement_1.AvailableStorageIds, tableElement_1.CurrentSelectedSenderId, tableElement_1.AvailableSenderIds);
                }
                else {
                    return tableElement_1;
                }
            }, state.TableElements), state.RemovedElements, state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), Cmd_none()];
        case 1:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, state.Consumables, state.Storages, state.Tools, state.TableElements, state.RemovedElements, state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, (bind$0040_1 = state.Dto, new MultiUnassignDto(bind$0040_1.Tools, bind$0040_1.Sets, bind$0040_1.Consumables, bind$0040_1.ToolsWithQuantity, msg.fields[0])), state.SenderId), Cmd_none()];
        case 4:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, state.Consumables, state.Storages, state.Tools, map((tableElement_2) => {
                if (tableElement_2.Id === msg.fields[0]) {
                    return new TableElement(tableElement_2.Id, tableElement_2.ElementId, tableElement_2.Manufacturer, tableElement_2.Model, tableElement_2.Name, tableElement_2.AdditionalName, tableElement_2.InventoryNumber, tableElement_2.Quanity, tableElement_2.Type, tableElement_2.Unit, tableElement_2.SelectedStorageId, tableElement_2.AvailableStorageIds, map_1((value_12) => value_12.value.Id, msg.fields[1]), tableElement_2.AvailableSenderIds);
                }
                else {
                    return tableElement_2;
                }
            }, state.TableElements), state.RemovedElements, state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), Cmd_none()];
        case 5: {
            const id_3 = msg.fields[0];
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, state.Consumables, state.Storages, state.Tools, cons(find((tableElement_3) => (tableElement_3.Id === id_3), state.RemovedElements), state.TableElements), filter((tableElement_4) => (tableElement_4.Id !== id_3), state.RemovedElements), state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), Cmd_none()];
        }
        case 6: {
            const id_4 = msg.fields[0];
            const selectedElement_1 = find((tableElement_5) => (tableElement_5.Id === id_4), state.TableElements);
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, state.Consumables, state.Storages, state.Tools, filter((tableElement_6) => (tableElement_6.Id !== id_4), state.TableElements), cons(selectedElement_1, state.RemovedElements), state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), Cmd_none()];
        }
        case 12: {
            const response_2 = msg.fields[0];
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, new RequestedValue$1(1, [(matchValue = state.SenderId, (matchValue == null) ? response_2 : ((senderId = matchValue, filter((receiver) => (receiver.Id === senderId), response_2))))]), state.Sets, state.Consumables, state.Storages, state.Tools, state.TableElements, state.RemovedElements, state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), singleton_1((dispatch) => {
                dispatch(new Msg(7, []));
            })];
        }
        case 13:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, state.Consumables, new RequestedValue$1(1, [msg.fields[0]]), state.Tools, state.TableElements, state.RemovedElements, state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), singleton_1((dispatch_1) => {
                dispatch_1(new Msg(7, []));
            })];
        case 9:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, new RequestedValue$1(1, [msg.fields[0]]), state.Consumables, state.Storages, state.Tools, state.TableElements, state.RemovedElements, state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), singleton_1((dispatch_2) => {
                dispatch_2(new Msg(7, []));
            })];
        case 10:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, new RequestedValue$1(1, [msg.fields[0]]), state.Storages, state.Tools, state.TableElements, state.RemovedElements, state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), singleton_1((dispatch_3) => {
                dispatch_3(new Msg(7, []));
            })];
        case 11:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, state.Consumables, state.Storages, new RequestedValue$1(1, [msg.fields[0]]), state.TableElements, state.RemovedElements, state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), singleton_1((dispatch_4) => {
                dispatch_4(new Msg(7, []));
            })];
        case 7: {
            const matchValue_1 = state.Consumables;
            const matchValue_2 = state.Tools;
            const matchValue_3 = state.Sets;
            const matchValue_4 = state.Storages;
            const matchValue_5 = state.Receivers;
            let matchResult, consumables, receivers, sets, storages, tools;
            if (matchValue_1.tag === 0) {
                matchResult = 1;
            }
            else if (matchValue_2.tag === 0) {
                matchResult = 1;
            }
            else if (matchValue_3.tag === 0) {
                matchResult = 1;
            }
            else if (matchValue_4.tag === 0) {
                matchResult = 1;
            }
            else if (matchValue_5.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                consumables = matchValue_1.fields[0];
                receivers = matchValue_5.fields[0];
                sets = matchValue_3.fields[0];
                storages = matchValue_4.fields[0];
                tools = matchValue_2.fields[0];
            }
            switch (matchResult) {
                case 0: {
                    const storageIds = map((storage) => storage.Id, storages);
                    const getStorageId = (storageId) => map_1((storage_2) => unwrapStorageId(storage_2.Id), tryFind((storage_1) => equals(storage_1.Id, storageId), storages));
                    const setElements = map((set$) => {
                        let matchValue_7, receiverId;
                        return new TableElement(newGuid(), set$.Id, set$.MasterData.Manufacturer, set$.MasterData.Model, set$.MasterData.Name, "", set$.MasterData.InventoryNumber, undefined, new TableElementType(3, []), "piece", getStorageId(new StorageId(parse(set$.LocationInformation.StorageId))), empty(), (matchValue_7 = set$.LocationInformation.AssignedReceiverId, (matchValue_7 == null) ? undefined : ((receiverId = matchValue_7, map_1((receiver_2) => receiver_2.Id, tryFind((receiver_1) => (receiver_1.Id === receiverId), receivers))))), empty());
                    }, sets);
                    const setToolIds = map((tool) => tool.ToolId, collect((set$_1) => set$_1.AssignedTools, sets));
                    const toolElements = map((tool_2) => {
                        let matchValue_11, senderId_2, matchValue_8, receiverId_1;
                        if (tool_2.tag === 1) {
                            const tool_4 = tool_2.fields[0];
                            const availableStorageIds = map((sps_1) => unwrapStorageId(sps_1.StorageId), filter((sps) => contains(sps.StorageId, storageIds, {
                                Equals: equals,
                                GetHashCode: safeHash,
                            }), tool_4.StockPerStorage));
                            const availableUserStocks = choose((ua_1) => {
                                const matchValue_9 = tryFind((receiver_5) => (receiver_5.Id === ua_1.UserId), receivers);
                                if (matchValue_9 == null) {
                                    return undefined;
                                }
                                else {
                                    const receiver_7 = matchValue_9;
                                    return ua_1.UserId;
                                }
                            }, filter((ua) => (ua.Quantity > 0), tool_4.UserAssignments));
                            let quantity;
                            const matchValue_10 = state.SenderId;
                            if (matchValue_10 != null) {
                                const senderId_1 = matchValue_10;
                                quantity = find((ua_2) => (ua_2.UserId === senderId_1), tool_4.UserAssignments).Quantity;
                            }
                            else {
                                quantity = 0;
                            }
                            return new TableElement(newGuid(), ToolId__get_unwrap(tool_4.Id), tool_4.Manufacturer, tool_4.Model, tool_4.DisplayName, "", tool_4.InventoryNumber, quantity, new TableElementType(1, []), "piece", tryHead(availableStorageIds), availableStorageIds, (matchValue_11 = state.SenderId, (matchValue_11 == null) ? tryHead(availableUserStocks) : ((senderId_2 = matchValue_11, map_1((receiver_9) => receiver_9.Id, tryFind((receiver_8) => (receiver_8.Id === senderId_2), receivers))))), map((x_3) => x_3, availableUserStocks));
                        }
                        else {
                            const tool_3 = tool_2.fields[0];
                            return new TableElement(newGuid(), ToolId__get_unwrap(tool_3.Id), tool_3.Manufacturer, tool_3.Model, tool_3.DisplayName, "", tool_3.InventoryNumber, undefined, new TableElementType(0, []), "piece", getStorageId(tool_3.StorageId), empty(), (matchValue_8 = tool_3.AssignedUserId, (matchValue_8 == null) ? undefined : ((receiverId_1 = matchValue_8, map_1((receiver_4) => receiver_4.Id, tryFind((receiver_3) => (receiver_3.Id === receiverId_1), receivers))))), empty());
                        }
                    }, filter((tool_1) => !contains(ToolId__get_unwrap(Helper_toolId(tool_1)), setToolIds, {
                        Equals: (x_1, y) => (x_1 === y),
                        GetHashCode: stringHash,
                    }), tools));
                    const consumableElements = map((consumable) => {
                        let matchValue_12, senderId_3;
                        const availableStorageIds_1 = map((sps_3) => unwrapStorageId(sps_3.StorageId), filter((sps_2) => contains(sps_2.StorageId, storageIds, {
                            Equals: equals,
                            GetHashCode: safeHash,
                        }), consumable.StockPerStorage));
                        return new TableElement(newGuid(), unwrapConsumableId(consumable.Id), "", "", consumable.Name, consumable.AdditionalName, consumable.InventoryNumber, 0, new TableElementType(2, []), consumable.Unit, tryHead(availableStorageIds_1), availableStorageIds_1, (matchValue_12 = state.SenderId, (matchValue_12 == null) ? undefined : ((senderId_3 = matchValue_12, map_1((receiver_11) => receiver_11.Id, tryFind((receiver_10) => (receiver_10.Id === senderId_3), receivers))))), map((receiver_12) => receiver_12.Id, receivers));
                    }, consumables);
                    const toolWithQuantityReceiverStocks = collect((x_5) => x_5, choose((tool_5) => {
                        if (tool_5.tag === 1) {
                            return map((ua_5) => ({
                                ReceiverId: ua_5.UserId,
                                Stock: int32ToString(ua_5.Quantity),
                            }), filter((ua_4) => (ua_4.Quantity > 0), tool_5.fields[0].UserAssignments));
                        }
                        else {
                            return undefined;
                        }
                    }, tools));
                    const consumableReceiverStocks = collect((x_6) => x_6, map((receiver_13) => map((consumable_1) => ({
                        ReceiverId: receiver_13.Id,
                        Stock: Helper_quantityAsStringWithComma(Helper_currentReceiverStock(receiver_13.Id, consumable_1.Events)),
                    }), consumables), receivers));
                    return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, state.Consumables, state.Storages, state.Tools, append_1(setElements, append_1(toolElements, consumableElements)), state.RemovedElements, toolWithQuantityReceiverStocks, consumableReceiverStocks, state.Dto, state.SenderId), Cmd_none()];
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 14:
            return [new State(FormState_get_empty(), state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, state.Consumables, state.Storages, state.Tools, state.TableElements, state.RemovedElements, state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), Cmd_none()];
        default: {
            const updatedDto = new MultiUnassignDto(map((element_1) => (new MultiUnassignToolDto(element_1.Id, element_1.ElementId)), filter((element) => equals(element.Type, new TableElementType(0, [])), state.TableElements)), map((element_3) => (new MultiUnassignSetDto(element_3.Id, element_3.ElementId)), filter((element_2) => equals(element_2.Type, new TableElementType(3, [])), state.TableElements)), map((element_5) => (new MultiUnassignConsumableDto(element_5.Id, element_5.ElementId, defaultArg(element_5.Quanity, 0), defaultArg(element_5.SelectedStorageId, ""), defaultArg(element_5.CurrentSelectedSenderId, ""))), filter((element_4) => equals(element_4.Type, new TableElementType(2, [])), state.TableElements)), map((element_7) => (new MultiUnassignToolWithQuantityDto(element_7.Id, element_7.ElementId, defaultArg(map_1((value_3) => ~~value_3, element_7.Quanity), 0), defaultArg(element_7.SelectedStorageId, ""), defaultArg(element_7.CurrentSelectedSenderId, ""))), filter((element_6) => equals(element_6.Type, new TableElementType(1, [])), state.TableElements)), state.Dto.Comment);
            return [new State(FormState__startLoading(state.FormState), state.SuccessCallback, state.OnClose, state.Receivers, state.Sets, state.Consumables, state.Storages, state.Tools, state.TableElements, state.RemovedElements, state.ToolWithQuantityReceiverStocks, state.ConsumableReceiverStocks, state.Dto, state.SenderId), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_2;
                return ((url_2 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_2, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                    const pr = response.arrayBuffer();
                    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                }, refreshOn)))(true);
            })), ["/api/multi-operations/unassign", toString(0, Auto_generateBoxedEncoder_437914C6(MultiUnassignDto_$reflection(), undefined, undefined, undefined)(updatedDto))], (Item) => (new Msg(8, [Item])), (Item_1) => (new Msg(14, [Item_1])))];
        }
    }
}

function Table(props) {
    let elems_1;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const i18n = patternInput[1];
    const containsConsumables = exists((tableElement) => equals(tableElement.Type, new TableElementType(2, [])), props.TableElements);
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    const columnDefinition = toArray(delay(() => append(!FSharpMap__get_IsEmpty(props.FormState.Validations) ? singleton(new ColumnDefinition$1("hint", "", t("assign_multiple_tools_dialog.hint"), (row) => {
        const matchValue = FormState__getValidation(props.FormState, t, `${row.Id}-tool-assigned-to-set`);
        const matchValue_1 = FormState__getValidation(props.FormState, t, `${row.Id}-receiver-stock`);
        const matchValue_2 = FormState__getValidation(props.FormState, t, `${row.Id}-not-assigned-to-receiver`);
        let matchResult, value;
        if (matchValue != null) {
            matchResult = 0;
            value = matchValue;
        }
        else if (matchValue_1 != null) {
            matchResult = 0;
            value = matchValue_1;
        }
        else if (matchValue_2 != null) {
            matchResult = 0;
            value = matchValue_2;
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0:
                return new CellContent(1, [createElement(InvalidTextValue, {
                    TestId: `${row.Id}-validation-message`,
                    Text: value,
                })]);
            default:
                return new CellContent(0, [""]);
        }
    }, "none", "text")) : empty_1(), delay(() => append(singleton(new ColumnDefinition$1("manufacturer", "", t("assign_multiple_tools_dialog.manufacturer"), (row_1) => (new CellContent(0, [row_1.Manufacturer])), "none", "text")), delay(() => append(singleton(new ColumnDefinition$1("model", "", t("assign_multiple_tools_dialog.model"), (row_2) => (new CellContent(0, [row_2.Model])), "none", "text")), delay(() => append(containsConsumables ? singleton(new ColumnDefinition$1("additionalName", "", t("consumable.additional_name"), (row_3) => (new CellContent(0, [row_3.AdditionalName])), "none", "text")) : empty_1(), delay(() => append(singleton(new ColumnDefinition$1("name", "", t("tool.displayname"), (row_4) => (new CellContent(0, [row_4.Name])), "none", "text")), delay(() => append(singleton(new ColumnDefinition$1("inventoryNumber", "", t("assign_multiple_tools_dialog.inventory_number"), (row_5) => (new CellContent(0, [row_5.InventoryNumber])), "none", "text")), delay(() => append(singleton(new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_6) => {
        let Value_1, Value;
        const matchValue_4 = row_6.Type;
        switch (matchValue_4.tag) {
            case 1:
                return new CellContent(1, [createElement(NumberInput, (Value_1 = map_1((value_3) => value_3, row_6.Quanity), {
                    ComponentState: componentState,
                    MaxValue: numberInputIntMaxValue,
                    OnChange: (value_4) => {
                        props.Dispatch(new Msg(2, [row_6.Id, value_4]));
                    },
                    TestId: "",
                    ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, `${row_6.Id}-receiver-stock`)),
                    Value: unwrap(Value_1),
                }))]);
            case 0:
            case 3:
                return new CellContent(0, ["1"]);
            default:
                return new CellContent(1, [createElement(NumberInput, (Value = map_1((value_1) => value_1, row_6.Quanity), {
                    ComponentState: componentState,
                    OnChange: (value_2) => {
                        props.Dispatch(new Msg(2, [row_6.Id, value_2]));
                    },
                    TestId: "",
                    ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, `${row_6.Id}-receiver-stock`)),
                    Value: unwrap(Value),
                }))]);
        }
    }, "none", "text")), delay(() => append(singleton(new ColumnDefinition$1("unit", "", t("general.unit"), (row_7) => (new CellContent(0, [t(Helper_unitToTranslationKey(row_7.Unit))])), "none", "text")), delay(() => append(singleton(new ColumnDefinition$1("sender", "", t("general.sender"), (row_8) => {
        let matchValue_7, selectedSenderId;
        const matchValue_5 = row_8.Type;
        switch (matchValue_5.tag) {
            case 0:
            case 3:
                return new CellContent(0, [defaultArg(map_1((senderId_1) => defaultArg(map_1((receiver_7) => receiver_7.Name, tryFind((receiver_6) => (receiver_6.Id === senderId_1), props.Receivers)), ""), row_8.CurrentSelectedSenderId), "")]);
            default: {
                const selectableReceiver = map((tupledArg) => {
                    const receiverType = tupledArg[0];
                    const receivers_1 = toArray_1(sortBy((receiver_3) => receiver_3.label.toLowerCase(), map((receiver_2) => {
                        const matchValue_6 = receiver_2.LocationName;
                        if (matchValue_6 != null) {
                            const locationName = matchValue_6;
                            if (equals(receiverType, new ReceiverTypeName(4, []))) {
                                return {
                                    label: `${locationName} - ${receiver_2.Name}`,
                                    value: receiver_2,
                                };
                            }
                            else {
                                return {
                                    label: `${receiver_2.Name} - ${locationName}`,
                                    value: receiver_2,
                                };
                            }
                        }
                        else {
                            return {
                                label: `${receiver_2.Name}`,
                                value: receiver_2,
                            };
                        }
                    }, tupledArg[1]), {
                        Compare: comparePrimitives,
                    }));
                    return {
                        label: Helper_receiverTypeNameTranslation(t, receiverType),
                        options: receivers_1,
                    };
                }, List_groupBy((receiver_1) => receiver_1.ReceiverType, choose((senderId) => tryFind((receiver) => (receiver.Id === senderId), props.Receivers), row_8.AvailableSenderIds), {
                    Equals: equals,
                    GetHashCode: safeHash,
                }));
                return new CellContent(1, [createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
                    ComponentState: componentState,
                    IsClearable: false,
                    Key: "",
                    NoOptionsMessage: "select.employee_no_options",
                    OnChange: (value_5) => {
                        props.Dispatch(new Msg(4, [row_8.Id, value_5]));
                    },
                    Options: selectableReceiver,
                    PlaceholderKey: "select.employee_placeholder",
                    Value: unwrap((matchValue_7 = row_8.CurrentSelectedSenderId, (matchValue_7 == null) ? undefined : ((selectedSenderId = matchValue_7, map_1((receiver_5) => ({
                        label: receiver_5.Name,
                        value: receiver_5,
                    }), tryFind((receiver_4) => (receiver_4.Id === selectedSenderId), props.Receivers)))))),
                }))]);
            }
        }
    }, "none", "text")), delay(() => append(singleton(new ColumnDefinition$1("receiverStock", "", t("unassign_multiple_tools_dialog.user_stock"), (row_9) => {
        let matchValue_8, matchValue_9, selectedSenderId_1;
        return new CellContent(0, [(matchValue_8 = row_9.Type, (matchValue_8.tag === 1) ? defaultArg(map_1((senderId_2) => {
            const senderValue = tryFind((receiverStock_2) => (receiverStock_2.ReceiverId === senderId_2), props.ToolWithQuantityReceiverStocks);
            if (senderValue == null) {
                return "0";
            }
            else {
                return senderValue.Stock;
            }
        }, row_9.CurrentSelectedSenderId), "0") : ((matchValue_8.tag === 0) ? defaultArg(map_1((senderId_2) => {
            const senderValue = tryFind((receiverStock_2) => (receiverStock_2.ReceiverId === senderId_2), props.ToolWithQuantityReceiverStocks);
            if (senderValue == null) {
                return "0";
            }
            else {
                return senderValue.Stock;
            }
        }, row_9.CurrentSelectedSenderId), "0") : ((matchValue_8.tag === 3) ? defaultArg(map_1((senderId_2) => {
            const senderValue = tryFind((receiverStock_2) => (receiverStock_2.ReceiverId === senderId_2), props.ToolWithQuantityReceiverStocks);
            if (senderValue == null) {
                return "0";
            }
            else {
                return senderValue.Stock;
            }
        }, row_9.CurrentSelectedSenderId), "0") : ((matchValue_9 = row_9.CurrentSelectedSenderId, (matchValue_9 == null) ? "0" : ((selectedSenderId_1 = matchValue_9, defaultArg(map_1((receiverStock_1) => receiverStock_1.Stock, tryFind((receiverStock) => (receiverStock.ReceiverId === selectedSenderId_1), props.ConsumableReceiverStocks)), "0"))))))))]);
    }, "none", "text")), delay(() => singleton(new ColumnDefinition$1("storage", "", t("general.storage"), (row_10) => {
        let selectedStorage;
        const matchValue_10 = row_10.SelectedStorageId;
        if (matchValue_10 == null) {
            selectedStorage = undefined;
        }
        else {
            const storageId = matchValue_10;
            selectedStorage = map_1((storage_1) => ({
                label: storage_1.Name,
                value: storage_1,
            }), tryFind((storage) => equals(storage.Id, new StorageId(parse(storageId))), props.Storages));
        }
        return new CellContent(1, [createElement(Select, {
            ComponentState: componentState,
            IsClearable: false,
            NoOptionsMessage: "select.storage_no_options",
            OnChange: (value_11) => {
                props.Dispatch(new Msg(3, [row_10.Id, map_1((value_12) => unwrapStorageId(value_12.value.Id), value_11)]));
            },
            Options: map((storage_3) => ({
                label: storage_3.Name,
                value: storage_3,
            }), filter((storage_2) => contains(unwrapStorageId(storage_2.Id), row_10.AvailableStorageIds, {
                Equals: (x_2, y_2) => (x_2 === y_2),
                GetHashCode: stringHash,
            }), props.Storages)),
            PlaceholderKey: "select.storage_placeholder",
            TestId: "",
            Value: unwrap(selectedStorage),
        })]);
    }, "none", "text"))))))))))))))))))))))));
    return createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems_1 = toList(delay(() => {
        let elems, arg_1, arg_3;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "gap-4", "mb-4"])], (elems = [createElement(TabsMenu, {
            Tabs: ofArray([{
                IsSelected: props.IsAddedElementTabVisible,
                Label: containsConsumables ? t("assign_multiple_tools_dialog.consumables") : t("assign_multiple_tools_dialog.tools"),
                OnClick: () => {
                    props.SetIsAddedElementTabVisible(true);
                },
                TestId: "",
            }, {
                IsSelected: !props.IsAddedElementTabVisible,
                Label: containsConsumables ? ((arg_1 = {
                    numberOfRemovedTools: length(props.RemovedElements),
                }, i18n.t("assign_multiple_tools_dialog.removed_consumables_elements", arg_1))) : ((arg_3 = {
                    numberOfRemovedTools: length(props.RemovedElements),
                }, i18n.t("assign_multiple_tools_dialog.removed_elements", arg_3))),
                OnClick: () => {
                    props.SetIsAddedElementTabVisible(false);
                },
                TestId: "",
            }]),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let TableRows, Options, TableRows_1, Options_1;
            return props.IsAddedElementTabVisible ? singleton(createElement(Table_1, (TableRows = toArray_1(props.TableElements), (Options = {
                BorderStyle: "row",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["manufacturer", "asc"],
                GlobalSearch: false,
                RowSelection: "singleRow",
            }, {
                ColumnDefinitions: columnDefinition,
                Controls: singleton_1(new TableControl$1(t("general.remove"), Recycle_Bin_1(), (rows) => {
                    iterate((row_12) => {
                        props.Dispatch(new Msg(6, [row_12]));
                    }, toArray_2(map_1((row_11) => row_11.Id, tryHead(rows))));
                }, true, false, "tools-dialog-toolreservation-dialog-remove-button")),
                Options: Options,
                TableRows: TableRows,
            })))) : singleton(createElement(Table_1, (TableRows_1 = toArray_1(props.RemovedElements), (Options_1 = {
                BorderStyle: "row",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["manufacturer", "asc"],
                GlobalSearch: false,
                RowSelection: "singleRow",
            }, {
                ColumnDefinitions: columnDefinition,
                Controls: singleton_1(new TableControl$1(t("general.remove"), Recycle_Bin_1(), (rows_1) => {
                    iterate((row_14) => {
                        props.Dispatch(new Msg(5, [row_14]));
                    }, toArray_2(map_1((row_13) => row_13.Id, tryHead(rows_1))));
                }, true, false, "tools-dialog-toolreservation-dialog-remove-button")),
                Options: Options_1,
                TableRows: TableRows_1,
            }))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function Form(props) {
    let elems_1, elems, Label_1;
    const t = useTranslation()[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4", "mt-4", "w-96"])], (elems_1 = [createElement(TextArea, {
        ComponentState: componentState,
        Label: t("general.comment"),
        OnChange: (value_1) => {
            props.Dispatch(new Msg(1, [value_1]));
        },
        TestId: "",
        Value: props.Dto.Comment,
    }), createElement("div", createObj(singleton_1((elems = [createElement(Button, (Label_1 = t("general.save"), {
        ComponentState: (componentState === "disabled") ? "disabled" : ((props.IsTableElementsEmpty ? true : props.HasTableElementEmptyStorageId) ? "disabled" : "enabled"),
        Label: Label_1,
        OnClick: () => {
            props.Dispatch(new Msg(0, []));
        },
        TestId: "tools-dialog-toolreservation-save-button",
        Variant: "primary",
    }))], ["children", reactApi.Children.toArray(Array.from(elems))]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function Content(props) {
    let elems_3, elems_2;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(true);
    const isAddedElementsTabSelected = patternInput_1[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["min-h-48"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["p-4"])], (elems_2 = toList(delay(() => append(singleton(createElement(SectionHeader, {
        Text: t("general.take_back"),
    })), delay(() => {
        let elems_1;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = toList(delay(() => {
            let elems;
            return append(isEmpty(props.Storages) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-5"])], (elems = [createElement(WarningAlert, {
                Label: t("multi_operation.unassign.no_storage_access"),
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty_1(), delay(() => singleton(createElement(Table, {
                ConsumableReceiverStocks: props.ConsumableReceiverStocks,
                Dispatch: props.Dispatch,
                FormState: props.FormState,
                IsAddedElementTabVisible: isAddedElementsTabSelected,
                Receivers: props.Receivers,
                RemovedElements: props.RemovedElements,
                SetIsAddedElementTabVisible: patternInput_1[1],
                Storages: props.Storages,
                TableElements: props.TableElements,
                ToolWithQuantityReceiverStocks: props.ToolWithQuantityReceiverStocks,
            }))));
        })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
            let IsTableElementsEmpty;
            return isAddedElementsTabSelected ? singleton(createElement(Form, (IsTableElementsEmpty = isEmpty(props.TableElements), {
                Dispatch: props.Dispatch,
                Dto: props.Dto,
                FormState: props.FormState,
                HasTableElementEmptyStorageId: exists((tableElement) => (tableElement.SelectedStorageId == null), props.TableElements),
                IsTableElementsEmpty: IsTableElementsEmpty,
                Receivers: props.Receivers,
            }))) : empty_1();
        }));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function MultiUnassignModalSheet(props) {
    let elems, matchValue, matchValue_1, matchValue_2, matchValue_3, matchValue_4;
    let patternInput;
    const init_1 = init({
        OnClose: props.OnClose,
        SenderId: unwrap(props.SenderId),
        SuccessCallback: props.SuccessCallback,
        UnassignProps: props.UnassignProps,
        UserData: props.UserData,
    });
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput[0];
    return createElement("div", createObj(singleton_1((elems = [createElement(ModalSheet, {
        CloseButtonTestId: "",
        Content: (matchValue = state_1.Receivers, (matchValue_1 = state_1.Consumables, (matchValue_2 = state_1.Sets, (matchValue_3 = state_1.Tools, (matchValue_4 = state_1.Storages, (matchValue.tag === 0) ? createElement(Skeleton, {
            Variant: "table",
        }) : ((matchValue_1.tag === 0) ? createElement(Skeleton, {
            Variant: "table",
        }) : ((matchValue_2.tag === 0) ? createElement(Skeleton, {
            Variant: "table",
        }) : ((matchValue_3.tag === 0) ? createElement(Skeleton, {
            Variant: "table",
        }) : ((matchValue_4.tag === 0) ? createElement(Skeleton, {
            Variant: "table",
        }) : createElement(Content, {
            ConsumableReceiverStocks: state_1.ConsumableReceiverStocks,
            Dispatch: patternInput[1],
            Dto: state_1.Dto,
            FormState: state_1.FormState,
            Receivers: matchValue.fields[0],
            RemovedElements: state_1.RemovedElements,
            Storages: matchValue_4.fields[0],
            TableElements: state_1.TableElements,
            ToolWithQuantityReceiverStocks: state_1.ToolWithQuantityReceiverStocks,
        })))))))))),
        Controls: empty(),
        IsModalOpen: props.IsOpen,
        OnClose: props.OnClose,
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))));
}

