import { RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { User_$reflection } from "../../Shared/User.js";
import { list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { getCustomerConfiguration } from "../../Requests/Configuration.js";

export const init = [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, [])), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
    const pr = response.arrayBuffer();
    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(User_$reflection())));
})))), "/api/receivers/users", (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1]))), getCustomerConfiguration((Item_2) => (new Msg(0, [Item_2])), (Item_3) => (new Msg(2, [Item_3])))]))];

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.CustomerConfiguration), Cmd_none()];
        case 2:
            return [state, Cmd_none()];
        default:
            return [new State(state.Users, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
    }
}

