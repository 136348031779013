import { Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../Communication.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { FileUploadResponse_$reflection } from "../Shared/File.js";

export function uploadImage(successMsg, errorMsg, formData) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), undefined, (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), FileUploadResponse_$reflection()));
    })))), ["/api/files/images", formData], successMsg, errorMsg);
}

export function uploadSignature(formData, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), undefined, (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), FileUploadResponse_$reflection()));
    })))), ["/api/files/signatures", formData], successMsg, errorMsg);
}

