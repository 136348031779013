import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, record_type, option_type, anonRecord_type, float64_type, list_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ToolDto_$reflection } from "../../Shared/Tool.js";
import { ConsumableDto_$reflection } from "../../Shared/Consumable.js";
import { ReservationDetailDto_$reflection } from "../../Shared/Planning.js";
import { ClientApiResponse$1_$reflection, FormState_$reflection, TokenDataDto_$reflection, CustomerConfiguration_$reflection, RequestedValue$1_$reflection } from "../../Shared/Shared.js";
import { Receiver_$reflection } from "../../Shared/User.js";
import { PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";

export class FormData extends Record {
    constructor(Name, Description, StartDate, EndDate, ReceiverId, ToolIds, ToolsWithQuantity, SetIds, Consumables, ReservationDetailDto) {
        super();
        this.Name = Name;
        this.Description = Description;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.ReceiverId = ReceiverId;
        this.ToolIds = ToolIds;
        this.ToolsWithQuantity = ToolsWithQuantity;
        this.SetIds = SetIds;
        this.Consumables = Consumables;
        this.ReservationDetailDto = ReservationDetailDto;
    }
}

export function FormData_$reflection() {
    return record_type("Reservation.Form.Types.FormData", [], FormData, () => [["Name", string_type], ["Description", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["ReceiverId", string_type], ["ToolIds", list_type(string_type)], ["ToolsWithQuantity", list_type(anonRecord_type(["Quantity", float64_type], ["Tool", ToolDto_$reflection()]))], ["SetIds", list_type(string_type)], ["Consumables", list_type(anonRecord_type(["Consumable", ConsumableDto_$reflection()], ["Quantity", float64_type]))], ["ReservationDetailDto", option_type(ReservationDetailDto_$reflection())]]);
}

export class State extends Record {
    constructor(Consumables, Tools, CustomerConfiguration, UserData, FormState, ReservationNameSuggestions, Receivers, PropertyConfigurations, FormData) {
        super();
        this.Consumables = Consumables;
        this.Tools = Tools;
        this.CustomerConfiguration = CustomerConfiguration;
        this.UserData = UserData;
        this.FormState = FormState;
        this.ReservationNameSuggestions = ReservationNameSuggestions;
        this.Receivers = Receivers;
        this.PropertyConfigurations = PropertyConfigurations;
        this.FormData = FormData;
    }
}

export function State_$reflection() {
    return record_type("Reservation.Form.Types.State", [], State, () => [["Consumables", RequestedValue$1_$reflection(list_type(ConsumableDto_$reflection()))], ["Tools", RequestedValue$1_$reflection(list_type(ToolDto_$reflection()))], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["UserData", TokenDataDto_$reflection()], ["FormState", FormState_$reflection()], ["ReservationNameSuggestions", RequestedValue$1_$reflection(list_type(string_type))], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["PropertyConfigurations", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["FormData", FormData_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SaveReservation", "AssignToolId", "RemoveToolId", "AssignSetId", "RemoveSetId", "AssignToolWithQuantityId", "RemoveToolWithQuantityId", "ChangeToolWithQuantityQuantity", "AssignConsumableId", "RemoveConsumableId", "ChangeConsumableQuantity", "SetReceiver", "SetName", "SetDescription", "SetStartDate", "SetEndDate", "ToolsFetched", "ConsumablesFetched", "ReservationNameSuggestionsFetched", "ReceiversFetched", "CustomerConfigurationFetched", "PropertyConfigurationsFetched", "ReservationResponse", "ReservationFetched", "InitEditFormData", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Reservation.Form.Types.Msg", [], Msg, () => [[], [["Item", list_type(string_type)]], [["Item", list_type(string_type)]], [["Item", list_type(string_type)]], [["Item", list_type(string_type)]], [["Item", list_type(ToolDto_$reflection())]], [["Item", list_type(ToolDto_$reflection())]], [["Item1", ToolDto_$reflection()], ["Item2", option_type(float64_type)]], [["Item", list_type(ConsumableDto_$reflection())]], [["Item", list_type(ConsumableDto_$reflection())]], [["Item1", ConsumableDto_$reflection()], ["Item2", option_type(float64_type)]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [["Item", list_type(ToolDto_$reflection())]], [["Item", list_type(ConsumableDto_$reflection())]], [["Item", list_type(string_type)]], [["Item", list_type(Receiver_$reflection())]], [["Item", CustomerConfiguration_$reflection()]], [["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", ReservationDetailDto_$reflection()]], [], [["Item", class_type("System.Exception")]]]);
}

