import { Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { createElement } from "react";
import React from "react";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { singleton, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { ofArray, singleton as singleton_1, mapIndexed, concat } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { TextButton } from "../Components/Button.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";

export class CustomField extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Link", "Route"];
    }
}

export function CustomField_$reflection() {
    return union_type("Widgets.PropertyListWidget.CustomField", [], CustomField, () => [[], [["Item", string_type]]]);
}

export function PropertyList(props) {
    let elems_1;
    return createElement("div", createObj(singleton_1((elems_1 = toList(delay(() => {
        let matchValue, title;
        return append((matchValue = props.Title, (matchValue == null) ? (empty()) : ((title = matchValue, singleton(createElement("div", {
            className: join(" ", ["text-sm", "font-semibold", "mb-4"]),
            children: title,
        }))))), delay(() => {
            let elems;
            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2"])], (elems = toList(delay(() => concat(mapIndexed((index, property) => toList(delay(() => append((index !== 0) ? singleton(createElement("div", {
                className: join(" ", ["col-span-2", "border-t", "border-divider"]),
            })) : empty(), delay(() => append(singleton(createElement("div", {
                className: join(" ", ["divide-y", "font-semibold", "text-base", "mr-5", "py-4"]),
                children: property.Label,
            })), delay(() => {
                const propertyField = createElement("div", createObj(toList(delay(() => append(singleton(["className", join(" ", toList(delay(() => append(singleton("text-base"), delay(() => append(singleton("py-4"), delay(() => append(singleton("break-words"), delay(() => append(singleton("whitespace-pre-wrap"), delay(() => {
                    const matchValue_1 = property.CustomField;
                    if (matchValue_1 == null) {
                        return empty();
                    }
                    else {
                        const customField = matchValue_1;
                        if (customField.tag === 1) {
                            return empty();
                        }
                        else {
                            return singleton("hover:cursor-pointer");
                        }
                    }
                })))))))))))]), delay(() => append(singleton(["data-test-id", `${property.Label}-property-list-label`]), delay(() => append(singleton(["children", property.Value]), delay(() => append(singleton(["data-test-id", `${property.TestId}`]), delay(() => {
                    const matchValue_3 = property.CustomField;
                    if (matchValue_3 == null) {
                        return empty();
                    }
                    else {
                        const customField_1 = matchValue_3;
                        if (customField_1.tag === 0) {
                            return singleton(["onClick", (_arg) => {
                                window.open(property.Value, "_blank", "noreferrer");
                            }]);
                        }
                        else {
                            return empty();
                        }
                    }
                }))))))))))));
                const matchValue_5 = property.CustomField;
                if (matchValue_5 != null) {
                    const matchValue_6 = matchValue_5;
                    return (matchValue_6.tag === 1) ? singleton(createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: property.Value,
                        OnClick: () => {
                            RouterModule_nav(singleton_1(matchValue_6.fields[0]), 1, 1);
                        },
                        TestId: `${property.Label}-property-list-label`,
                        Variant: "blueButton",
                    })) : singleton(propertyField);
                }
                else {
                    return singleton(propertyField);
                }
            })))))), props.Properties)))), ["children", reactApi.Children.toArray(Array.from(elems))])]))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))]))));
}

