import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "./Select.js";
import { writeLanguage } from "../LocalStorageHelper.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";

export function LanguageSelector(props) {
    const i18next = useTranslation()[1];
    const currentLanguage = i18next.language;
    return createElement(Select, {
        ComponentState: "enabled",
        IsClearable: false,
        NoOptionsMessage: "",
        OnChange: (value) => {
            if (value == null) {
            }
            else {
                const lng = value.value;
                props.ChangeCallback(lng);
                const pr = i18next.changeLanguage(lng);
                void pr;
                writeLanguage(lng);
            }
        },
        Options: ofArray([{
            label: "English",
            value: "en",
        }, {
            label: "Deutsch",
            value: "de",
        }, {
            label: "French",
            value: "fr",
        }, {
            label: "Italian",
            value: "it",
        }, {
            label: "Polish",
            value: "pl",
        }, {
            label: "Ukrainian",
            value: "uk",
        }]),
        PlaceholderKey: "",
        TestId: "",
        Value: (currentLanguage === "en") ? {
            label: "English",
            value: currentLanguage,
        } : ((currentLanguage === "de") ? {
            label: "Deutsch",
            value: currentLanguage,
        } : ((currentLanguage === "fr") ? {
            label: "French",
            value: currentLanguage,
        } : ((currentLanguage === "it") ? {
            label: "Italian",
            value: currentLanguage,
        } : ((currentLanguage === "uk") ? {
            label: "Ukrainian",
            value: currentLanguage,
        } : ((currentLanguage === "pl") ? {
            label: "Polish",
            value: currentLanguage,
        } : {
            label: "",
            value: currentLanguage,
        }))))),
    });
}

