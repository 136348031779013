import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { singleton } from "../fable_modules/fable-library-js.4.19.2/List.js";

export function RadioButton(props) {
    let elems;
    return createElement("div", createObj(singleton((elems = [createElement("input", {
        className: join(" ", ["cursor-pointer", "accent-primary"]),
        type: "radio",
        id: `radio-button-${props.Label}`,
        checked: props.Selected,
        onChange: (ev) => {
            props.OnSelect(ev.target.checked);
        },
        disabled: props.ComponentState === "disabled",
    }), createElement("label", {
        className: join(" ", ["ml-2", "text-sm"]),
        children: props.Label,
        htmlFor: `radio-button-${props.Label}`,
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))));
}

