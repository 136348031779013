import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { PropertyConfigurationVisibility, CreatePropertyConfigurationDto_$reflection, CreatePropertyConfigurationDto, UpdatePropertyConfigurationDto_$reflection, UpdatePropertyConfigurationDto, PropertyConfigurationEnumValue, PropertyConfigurationType, PropertyConfiguration, PropertyConfigurationEnumValue_$reflection, PropertyConfigurationType_$reflection, PropertyConfigurationVisibility_$reflection, PropertyConfiguration_$reflection } from "../../../Shared/PropertyConfiguration.js";
import { record_type, lambda_type, unit_type, class_type, array_type, option_type, anonRecord_type, bool_type, string_type, union_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, FormState__startLoading, FormState__setValidation, PropertyConfigurationEnumValueId_get_Empty, FormState_get_empty, FormState_$reflection, ClientApiResponse$1_$reflection, Label_$reflection } from "../../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { tryFind, empty, contains, filter, singleton, append, ofArray, choose, map } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { join, isNullOrWhiteSpace } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../../../CommunicationV2.js";
import { Types_RequestProperties } from "../../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { PropertyConfigurationId__get_unwrap } from "../../../Shared/Shared.js";
import { Cmd_successToast } from "../../../Components/Toast.js";
import { ofNullable, unwrap, map as map_1, defaultArg } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { createElement } from "react";
import React from "react";
import { Select, MultiCreatable, MultiSelect } from "../../../Components/Select.js";
import { createObj, safeHash, equals } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { useTranslation } from "react-i18next";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { InformationAlert } from "../../../Components/Alert.js";
import { Input } from "../../../Components/Input.js";
import { Checkbox } from "../../../Components/Checkbox.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../../Components/Dialog.js";
import { TextButton } from "../../../Components/Button.js";

export class FormType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["New", "Edit"];
    }
}

export function FormType_$reflection() {
    return union_type("Settings.CustomProperty.Dialogs.CustomPropertyDialog.FormType", [], FormType, () => [[["Item", PropertyConfiguration_$reflection()]], [["Item", PropertyConfiguration_$reflection()]]]);
}

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetName", "SetRequired", "SetVisibility", "SetType", "SetEnumValues", "CreateEnumValue", "SetCategory", "SaveCustomProperty", "PropertyConfigurationSaved", "PropertyConfigurationUpdated", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Settings.CustomProperty.Dialogs.CustomPropertyDialog.Msg", [], Msg, () => [[["Item", string_type]], [["Item", bool_type]], [["Item", PropertyConfigurationVisibility_$reflection()]], [["Item", PropertyConfigurationType_$reflection()]], [["Item", array_type(option_type(anonRecord_type(["label", string_type], ["value", PropertyConfigurationEnumValue_$reflection()])))]], [["Item", string_type]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Label_$reflection()]))]], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(FormType, FormState, SuccessCallback, OnClose) {
        super();
        this.FormType = FormType;
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
    }
}

function State_$reflection() {
    return record_type("Settings.CustomProperty.Dialogs.CustomPropertyDialog.State", [], State, () => [["FormType", FormType_$reflection()], ["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)]]);
}

function init(successCallback, onClose, formType) {
    return [new State(formType, FormState_get_empty(), successCallback, onClose), Cmd_none()];
}

function update(msg, state) {
    let matchValue_1, property_2, property_1, matchValue_2, property_4, property_3, matchValue_4, property_7, property_6, mappedValues, mapPropertyType, matchValue_5, property_9, property_8, mapPropertyType_1, matchValue_6, property_11, property_10, propertyConfiguration_1, body_3, propertyConfiguration;
    switch (msg.tag) {
        case 0: {
            const value_1 = msg.fields[0];
            return [new State((matchValue_1 = state.FormType, (matchValue_1.tag === 1) ? ((property_2 = matchValue_1.fields[0], new FormType(1, [new PropertyConfiguration(property_2.Id, value_1, property_2.Required, property_2.IsDeleted, property_2.Type, property_2.Visibility, property_2.CreatedAt, property_2.UpdatedAt)]))) : ((property_1 = matchValue_1.fields[0], new FormType(0, [new PropertyConfiguration(property_1.Id, value_1, property_1.Required, property_1.IsDeleted, property_1.Type, property_1.Visibility, property_1.CreatedAt, property_1.UpdatedAt)])))), state.FormState, state.SuccessCallback, state.OnClose), Cmd_none()];
        }
        case 1: {
            const value_2 = msg.fields[0];
            return [new State((matchValue_2 = state.FormType, (matchValue_2.tag === 1) ? ((property_4 = matchValue_2.fields[0], new FormType(1, [new PropertyConfiguration(property_4.Id, property_4.Name, value_2, property_4.IsDeleted, property_4.Type, property_4.Visibility, property_4.CreatedAt, property_4.UpdatedAt)]))) : ((property_3 = matchValue_2.fields[0], new FormType(0, [new PropertyConfiguration(property_3.Id, property_3.Name, value_2, property_3.IsDeleted, property_3.Type, property_3.Visibility, property_3.CreatedAt, property_3.UpdatedAt)])))), state.FormState, state.SuccessCallback, state.OnClose), Cmd_none()];
        }
        case 2: {
            const matchValue_3 = state.FormType;
            if (matchValue_3.tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                const property_5 = matchValue_3.fields[0];
                return [new State(new FormType(0, [new PropertyConfiguration(property_5.Id, property_5.Name, property_5.Required, property_5.IsDeleted, property_5.Type, msg.fields[0], property_5.CreatedAt, property_5.UpdatedAt)]), state.FormState, state.SuccessCallback, state.OnClose), Cmd_none()];
            }
        }
        case 3: {
            const value_4 = msg.fields[0];
            return [new State((matchValue_4 = state.FormType, (matchValue_4.tag === 1) ? ((property_7 = matchValue_4.fields[0], new FormType(1, [new PropertyConfiguration(property_7.Id, property_7.Name, property_7.Required, property_7.IsDeleted, value_4, property_7.Visibility, property_7.CreatedAt, property_7.UpdatedAt)]))) : ((property_6 = matchValue_4.fields[0], new FormType(0, [new PropertyConfiguration(property_6.Id, property_6.Name, property_6.Required, property_6.IsDeleted, value_4, property_6.Visibility, property_6.CreatedAt, property_6.UpdatedAt)])))), state.FormState, state.SuccessCallback, state.OnClose), Cmd_none()];
        }
        case 4:
            return [new State((mappedValues = map((value_5) => value_5.value, choose((x) => x, ofArray(msg.fields[0]))), (mapPropertyType = ((propertyType) => {
                switch (propertyType.tag) {
                    case 1:
                        return new PropertyConfigurationType(1, [mappedValues]);
                    case 0:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        return propertyType;
                    default:
                        return new PropertyConfigurationType(2, [mappedValues]);
                }
            }), (matchValue_5 = state.FormType, (matchValue_5.tag === 1) ? ((property_9 = matchValue_5.fields[0], new FormType(1, [new PropertyConfiguration(property_9.Id, property_9.Name, property_9.Required, property_9.IsDeleted, mapPropertyType(property_9.Type), property_9.Visibility, property_9.CreatedAt, property_9.UpdatedAt)]))) : ((property_8 = matchValue_5.fields[0], new FormType(0, [new PropertyConfiguration(property_8.Id, property_8.Name, property_8.Required, property_8.IsDeleted, mapPropertyType(property_8.Type), property_8.Visibility, property_8.CreatedAt, property_8.UpdatedAt)])))))), state.FormState, state.SuccessCallback, state.OnClose), Cmd_none()];
        case 5: {
            const newValue = new PropertyConfigurationEnumValue(PropertyConfigurationEnumValueId_get_Empty(), msg.fields[0], "");
            return [new State((mapPropertyType_1 = ((propertyType_1) => {
                switch (propertyType_1.tag) {
                    case 1:
                        return new PropertyConfigurationType(1, [append(propertyType_1.fields[0], singleton(newValue))]);
                    case 0:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        return propertyType_1;
                    default:
                        return new PropertyConfigurationType(2, [append(propertyType_1.fields[0], singleton(newValue))]);
                }
            }), (matchValue_6 = state.FormType, (matchValue_6.tag === 1) ? ((property_11 = matchValue_6.fields[0], new FormType(1, [new PropertyConfiguration(property_11.Id, property_11.Name, property_11.Required, property_11.IsDeleted, mapPropertyType_1(property_11.Type), property_11.Visibility, property_11.CreatedAt, property_11.UpdatedAt)]))) : ((property_10 = matchValue_6.fields[0], new FormType(0, [new PropertyConfiguration(property_10.Id, property_10.Name, property_10.Required, property_10.IsDeleted, mapPropertyType_1(property_10.Type), property_10.Visibility, property_10.CreatedAt, property_10.UpdatedAt)]))))), state.FormState, state.SuccessCallback, state.OnClose), Cmd_none()];
        }
        case 7: {
            const matchValue_7 = state.FormType;
            if (matchValue_7.tag === 1) {
                const property_13 = matchValue_7.fields[0];
                if (isNullOrWhiteSpace(property_13.Name)) {
                    return [new State(state.FormType, FormState__setValidation(state.FormState, "name", "validation.input.name_not_empty"), state.SuccessCallback, state.OnClose), Cmd_none()];
                }
                else {
                    return [new State(state.FormType, FormState__startLoading(state.FormState), state.SuccessCallback, state.OnClose), (propertyConfiguration_1 = (new UpdatePropertyConfigurationDto(property_13.Name, property_13.Required, property_13.Type)), (body_3 = toString(0, Auto_generateBoxedEncoder_437914C6(UpdatePropertyConfigurationDto_$reflection(), undefined, undefined, undefined)(propertyConfiguration_1)), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_5;
                        return ((url_5 = addPrefix(tupledArg_1[0]), (refreshOn_1) => fetchWithDecoder(url_5, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                            const pr_1 = response_1.arrayBuffer();
                            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                        }, refreshOn_1)))(true);
                    })), [`/api/property-configurations/${PropertyConfigurationId__get_unwrap(property_13.Id)}`, body_3], (Item_18) => (new Msg(9, [Item_18])), (Item_19) => (new Msg(10, [Item_19])))))];
                }
            }
            else {
                const property_12 = matchValue_7.fields[0];
                if (isNullOrWhiteSpace(property_12.Name)) {
                    return [new State(state.FormType, FormState__setValidation(state.FormState, "name", "validation.input.name_not_empty"), state.SuccessCallback, state.OnClose), Cmd_none()];
                }
                else {
                    return [new State(state.FormType, FormState__startLoading(state.FormState), state.SuccessCallback, state.OnClose), (propertyConfiguration = (new CreatePropertyConfigurationDto(property_12.Name, property_12.Required, property_12.Type, property_12.Visibility)), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_2;
                        return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                            const pr = response.arrayBuffer();
                            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                        }, refreshOn)))(true);
                    })), ["/api/property-configurations", toString(0, Auto_generateBoxedEncoder_437914C6(CreatePropertyConfigurationDto_$reflection(), undefined, undefined, undefined)(propertyConfiguration))], (Item_16) => (new Msg(8, [Item_16])), (Item_17) => (new Msg(10, [Item_17]))))];
                }
            }
        }
        case 8: {
            const response_2 = msg.fields[0];
            if (response_2.tag === 1) {
                return [new State(state.FormType, FormState__setValidationResponse_5219762A(state.FormState, response_2.fields[0]), state.SuccessCallback, state.OnClose), Cmd_none()];
            }
            else {
                return [new State(state.FormType, FormState_get_empty(), state.SuccessCallback, state.OnClose), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 9: {
            const response_3 = msg.fields[0];
            if (response_3.tag === 1) {
                return [new State(state.FormType, FormState__setValidationResponse_5219762A(state.FormState, response_3.fields[0]), state.SuccessCallback, state.OnClose), Cmd_none()];
            }
            else {
                return [new State(state.FormType, FormState_get_empty(), state.SuccessCallback, state.OnClose), Cmd_batch(ofArray([Cmd_ofEffect((_arg_2) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_3) => {
                    state.OnClose();
                }), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 10:
            return [state, Cmd_none()];
        default: {
            const updatedVisibility = defaultArg(map_1((category_1) => (new PropertyConfigurationVisibility(1, [category_1.value.Id])), msg.fields[0]), new PropertyConfigurationVisibility(0, []));
            const matchValue = state.FormType;
            if (matchValue.tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                const property = matchValue.fields[0];
                return [new State(new FormType(0, [new PropertyConfiguration(property.Id, property.Name, property.Required, property.IsDeleted, property.Type, updatedVisibility, property.CreatedAt, property.UpdatedAt)]), state.FormState, state.SuccessCallback, state.OnClose), Cmd_none()];
            }
        }
    }
}

function CategorySelect(props) {
    return createElement(MultiSelect, {
        ComponentState: "enabled",
        IsClearable: true,
        NoOptionsMessage: "",
        OnChange: (option) => {
            props.OnSelectLabel(defaultArg(option, []));
        },
        Options: map((value_1) => ({
            label: value_1.Name,
            value: value_1.Id,
        }), filter((l) => !contains(l.Id, props.SelectedLabelIds, {
            Equals: equals,
            GetHashCode: safeHash,
        }), props.Labels)),
        PlaceholderKey: "",
        TestId: "",
        Value: map((l_2) => ({
            label: l_2.Name,
            value: l_2.Id,
        }), filter((l_1) => contains(l_1.Id, props.SelectedLabelIds, {
            Equals: equals,
            GetHashCode: safeHash,
        }), props.Labels)),
    });
}

function EnumCreatable(props) {
    let Label, Options, Value;
    const t = useTranslation()[0];
    return createElement(MultiCreatable, (Label = t("settings.custom_property.header.value"), (Options = map((value) => ({
        label: value.Name,
        value: value,
    }), props.Values), (Value = map((value_3) => ({
        label: value_3.Name,
        value: value_3,
    }), props.Values), {
        IsClearable: true,
        Label: unwrap(Label),
        NoOptionsMessage: "",
        OnChange: (option) => {
            props.OnSelectValue(defaultArg(ofNullable(option), []));
        },
        OnCreateOption: props.OnCreateValue,
        Options: Options,
        PlaceholderKey: "",
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "value")),
        Value: Value,
    }))));
}

function DialogBody(props) {
    let elems;
    const t = useTranslation()[0];
    const typeOptions = ofArray([{
        label: t("settings.custom_property.types.text"),
        value: new PropertyConfigurationType(0, []),
    }, {
        label: t("settings.custom_property.types.date"),
        value: new PropertyConfigurationType(3, []),
    }, {
        label: t("settings.custom_property.types.bool"),
        value: new PropertyConfigurationType(4, []),
    }, {
        label: t("settings.custom_property.types.enum"),
        value: new PropertyConfigurationType(1, [empty()]),
    }, {
        label: t("settings.custom_property.types.multi_enum"),
        value: new PropertyConfigurationType(2, [empty()]),
    }, {
        label: t("settings.custom_property.types.url"),
        value: new PropertyConfigurationType(5, []),
    }, {
        label: t("settings.custom_property.types.currency"),
        value: new PropertyConfigurationType(6, []),
    }]);
    let property_2;
    const matchValue = props.FormType;
    property_2 = ((matchValue.tag === 1) ? matchValue.fields[0] : matchValue.fields[0]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4", "w-[35rem]"])], (elems = toList(delay(() => append_1(singleton_1(createElement(InformationAlert, {
        Label: "settings.custom_property.info",
    })), delay(() => {
        let Label, Options, Value, matchValue_2;
        return append_1(singleton_1(createElement(Select, (Label = t("general.category"), (Options = map((label_1) => ({
            label: label_1.Name,
            value: label_1,
        }), filter((label) => {
            const matchValue_1 = property_2.Visibility;
            switch (matchValue_1.tag) {
                case 1:
                    return !equals(matchValue_1.fields[0], label.Id);
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    return false;
                default:
                    return true;
            }
        }, props.Labels)), (Value = ((matchValue_2 = property_2.Visibility, (matchValue_2.tag === 1) ? map_1((label_3) => ({
            label: label_3.Name,
            value: label_3,
        }), tryFind((label_2) => equals(label_2.Id, matchValue_2.fields[0]), props.Labels)) : ((matchValue_2.tag === 2) ? undefined : ((matchValue_2.tag === 3) ? undefined : ((matchValue_2.tag === 4) ? undefined : ((matchValue_2.tag === 5) ? undefined : ((matchValue_2.tag === 6) ? undefined : ((matchValue_2.tag === 7) ? undefined : ((matchValue_2.tag === 8) ? undefined : ((matchValue_2.tag === 9) ? undefined : ((matchValue_2.tag === 10) ? undefined : ((matchValue_2.tag === 11) ? undefined : undefined)))))))))))), {
            ComponentState: (props.FormType.tag === 1) ? "disabled" : props.ComponentState,
            IsClearable: true,
            Label: unwrap(Label),
            NoOptionsMessage: "",
            OnChange: (value_1) => {
                props.Dispatch(new Msg(6, [value_1]));
            },
            Options: Options,
            PlaceholderKey: "",
            TestId: "",
            Value: unwrap(Value),
        }))))), delay(() => append_1(singleton_1(createElement(Input, {
            ComponentState: props.ComponentState,
            Label: t("settings.custom_property.header.name"),
            OnChange: (arg) => {
                props.Dispatch(new Msg(0, [arg]));
            },
            TestId: "",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "name")),
            Value: property_2.Name,
        })), delay(() => {
            let Label_2, Value_2;
            return append_1(singleton_1(createElement(Select, (Label_2 = t("settings.custom_property.header.type"), (Value_2 = tryFind((option_3) => equals(option_3.value, property_2.Type), typeOptions), {
                ComponentState: (props.FormType.tag === 0) ? props.ComponentState : "disabled",
                IsClearable: false,
                Label: unwrap(Label_2),
                NoOptionsMessage: "",
                OnChange: (option_1) => {
                    if (option_1 == null) {
                    }
                    else {
                        props.Dispatch(new Msg(3, [option_1.value]));
                    }
                },
                Options: typeOptions,
                PlaceholderKey: "",
                TestId: "",
                Value: unwrap(Value_2),
            })))), delay(() => {
                let matchValue_5;
                return append_1((matchValue_5 = property_2.Type, (matchValue_5.tag === 5) ? singleton_1(Checkbox({
                    ComponentState: props.ComponentState,
                    IsChecked: property_2.Required,
                    Label: t("settings.custom_property.header.required"),
                    OnCheck: (arg_1) => {
                        props.Dispatch(new Msg(1, [arg_1]));
                    },
                    TestId: "",
                })) : ((matchValue_5.tag === 0) ? singleton_1(Checkbox({
                    ComponentState: props.ComponentState,
                    IsChecked: property_2.Required,
                    Label: t("settings.custom_property.header.required"),
                    OnCheck: (arg_1) => {
                        props.Dispatch(new Msg(1, [arg_1]));
                    },
                    TestId: "",
                })) : ((matchValue_5.tag === 1) ? singleton_1(Checkbox({
                    ComponentState: props.ComponentState,
                    IsChecked: property_2.Required,
                    Label: t("settings.custom_property.header.required"),
                    OnCheck: (arg_1) => {
                        props.Dispatch(new Msg(1, [arg_1]));
                    },
                    TestId: "",
                })) : ((matchValue_5.tag === 2) ? singleton_1(Checkbox({
                    ComponentState: props.ComponentState,
                    IsChecked: property_2.Required,
                    Label: t("settings.custom_property.header.required"),
                    OnCheck: (arg_1) => {
                        props.Dispatch(new Msg(1, [arg_1]));
                    },
                    TestId: "",
                })) : ((matchValue_5.tag === 3) ? singleton_1(Checkbox({
                    ComponentState: props.ComponentState,
                    IsChecked: property_2.Required,
                    Label: t("settings.custom_property.header.required"),
                    OnCheck: (arg_1) => {
                        props.Dispatch(new Msg(1, [arg_1]));
                    },
                    TestId: "",
                })) : ((matchValue_5.tag === 6) ? singleton_1(Checkbox({
                    ComponentState: props.ComponentState,
                    IsChecked: property_2.Required,
                    Label: t("settings.custom_property.header.required"),
                    OnCheck: (arg_1) => {
                        props.Dispatch(new Msg(1, [arg_1]));
                    },
                    TestId: "",
                })) : (empty_1()))))))), delay(() => {
                    const matchValue_6 = property_2.Type;
                    let matchResult, enums;
                    switch (matchValue_6.tag) {
                        case 1: {
                            matchResult = 1;
                            enums = matchValue_6.fields[0];
                            break;
                        }
                        case 2: {
                            matchResult = 1;
                            enums = matchValue_6.fields[0];
                            break;
                        }
                        default:
                            matchResult = 0;
                    }
                    switch (matchResult) {
                        case 0: {
                            return empty_1();
                        }
                        default:
                            return singleton_1(createElement(EnumCreatable, {
                                FormState: props.FormState,
                                OnCreateValue: (arg_3) => {
                                    props.Dispatch(new Msg(5, [arg_3]));
                                },
                                OnSelectValue: (arg_2) => {
                                    props.Dispatch(new Msg(4, [arg_2]));
                                },
                                Values: enums,
                            }));
                    }
                }));
            }));
        }))));
    })))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function CustomPropertyDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.SuccessCallback, props.OnClose, props.FormType);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const componentState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = ((props.FormType.tag === 1) ? t("settings.custom_property.edit") : t("settings.custom_property.create")), {
        Body: singleton(createElement(DialogBody, {
            ComponentState: componentState,
            Dispatch: dispatch,
            FormState: state_1.FormState,
            FormType: state_1.FormType,
            Labels: props.Labels,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-custom-property-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(7, []));
            },
            TestId: "save-custom-property-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

