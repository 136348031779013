import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { toList, empty, singleton, append, delay, toArray } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { OnTableRowClick$1, ColumnDefinition$1, CellContent } from "../Widgets/Table/Table.js";
import { Helper_receiverTypeNameTranslation } from "../Shared/User.js";
import { Feature, isFeatureEnabled } from "../FeatureFlip.js";
import { InfiniteTable } from "../Widgets/Table/InfiniteTable.js";
import { ofArray, singleton as singleton_1, empty as empty_1, toArray as toArray_1 } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { FSharpMap__TryFind } from "../fable_modules/fable-library-js.4.19.2/Map.js";
import { Msg } from "./Types.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { SelectedTab, ToolboxControl } from "../Widgets/ToolboxControlWidget.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { SWR_getToolBoxes } from "../Requests/Toolbox.js";
import { Skeleton } from "../Components/Skeleton.js";

export function OverviewTable(props) {
    let TableRows, matchValue_1;
    const t = useTranslation()[0];
    const columnDefinitions = toArray(delay(() => append(singleton(new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.DisplayName])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("toolQuantity", "", t("toolbox.quantity_tool"), (dto_1) => (new CellContent(0, [dto_1.ToolQuantity])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("toolWithQuantity", "", t("toolbox.quantity_simple_tool"), (dto_2) => (new CellContent(0, [dto_2.ToolWithQuantityQuantity])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("receiverType", "", t("general.receiver"), (dto_3) => (new CellContent(0, [Helper_receiverTypeNameTranslation(t, dto_3.ReceiverType)])), "select", "text")), delay(() => append(singleton(new ColumnDefinition$1("location", "", t("general.location"), (dto_4) => {
        let matchValue;
        return new CellContent(0, [(matchValue = dto_4.LocationName, (matchValue == null) ? "" : matchValue)]);
    }, "select", "text")), delay(() => (isFeatureEnabled(new Feature(6, [])) ? singleton(new ColumnDefinition$1("archived", "", t("user.is_archived"), (dto_5) => (new CellContent(0, [dto_5.IsArchived ? t("general.yes") : t("general.no")])), "select", "text")) : empty())))))))))))));
    return createElement(InfiniteTable, (TableRows = toArray_1(props.Receivers), {
        ColumnDefinitions: columnDefinitions,
        Controls: empty_1(),
        Options: {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: true,
            DefaultColumnFilter: unwrap(isFeatureEnabled(new Feature(6, [])) ? singleton_1({
                id: "archived",
                value: [t("general.no")],
            }) : undefined),
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(dto_6) => RouterModule_encodeParts(ofArray(["receivers", dto_6.UserId]), 1)]),
            RowSelection: "disabled",
            WithQueryParameters: true,
        },
        TableConfiguration: unwrap((matchValue_1 = FSharpMap__TryFind(props.UserConfiguration.TableConfiguration, "receiver-overview-table"), (matchValue_1 == null) ? {
            OnSaveTableConfiguration: (arg_1) => {
                props.Dispatch(new Msg(22, [arg_1]));
            },
        } : {
            OnSaveTableConfiguration: (arg) => {
                props.Dispatch(new Msg(22, [arg]));
            },
            TableConfiguration: matchValue_1,
        })),
        TableRows: TableRows,
    }));
}

function ReceiversOverview(props) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-full"])], (elems = toList(delay(() => {
        let matchValue;
        return append((matchValue = props.UserData.Role, (matchValue === "toolManager") ? singleton(createElement(ToolboxControl, {
            CustomerConfiguration: props.CustomerConfiguration,
            SelectedTab: new SelectedTab(0, []),
            UserData: props.UserData,
        })) : ((matchValue === "system") ? singleton(createElement(ToolboxControl, {
            CustomerConfiguration: props.CustomerConfiguration,
            SelectedTab: new SelectedTab(0, []),
            UserData: props.UserData,
        })) : ((matchValue === "user") ? (empty()) : singleton(createElement(ToolboxControl, {
            CustomerConfiguration: props.CustomerConfiguration,
            SelectedTab: new SelectedTab(0, []),
            UserData: props.UserData,
        }))))), delay(() => singleton(createElement(OverviewTable, {
            Dispatch: props.Dispatch,
            Receivers: props.Receivers,
            UserConfiguration: props.UserConfiguration,
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function ReceiversOverviewView(props) {
    let patternInput;
    const init = init_1(props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const toolBoxes = SWR_getToolBoxes();
    const matchValue_1 = toolBoxes.data;
    const matchValue_2 = state_1.CustomerConfiguration;
    const matchValue_3 = state_1.UserConfiguration;
    let matchResult, customerConfiguration, toolBoxes_1, userConfiguration;
    if (toolBoxes.error != null) {
        if (matchValue_1 == null) {
            matchResult = 1;
        }
        else if (matchValue_2.tag === 0) {
            matchResult = 1;
        }
        else if (matchValue_3.tag === 0) {
            matchResult = 1;
        }
        else {
            matchResult = 2;
        }
    }
    else if (matchValue_1 == null) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        customerConfiguration = matchValue_2.fields[0];
        toolBoxes_1 = matchValue_1;
        userConfiguration = matchValue_3.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(ReceiversOverview, {
                CustomerConfiguration: customerConfiguration,
                Dispatch: patternInput[1],
                Receivers: toolBoxes_1,
                UserConfiguration: userConfiguration,
                UserData: state_1.UserData,
            });
        case 1:
            return createElement(Skeleton, {
                Variant: "table",
            });
        default:
            return createElement("div", {
                children: ["Error"],
            });
    }
}

