import { createElement } from "react";
import React from "react";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ofArray, fold } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { Switch } from "@headlessui/react";
import { reactApi as reactApi_1 } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";

export function Toggle(props) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex"])], (elems = toList(delay(() => {
        let matchValue, leftLabel;
        return append((matchValue = props.LeftLabel, (matchValue == null) ? (empty()) : ((leftLabel = matchValue, singleton(createElement("div", {
            className: join(" ", ["flex", "items-center", "mr-2", "font-bold"]),
            children: leftLabel,
        }))))), delay(() => {
            let props_4, children;
            return append(singleton((props_4 = ofArray([["checked", props.Checked], ["onChange", props.OnChange], ["className", fold((a, b) => (`${a} ${b}`), "", toList(delay(() => append(props.Checked ? append(singleton("bg-primary"), delay(() => singleton("border-0"))) : append(singleton("bg-white"), delay(() => singleton("border-2"))), delay(() => append(singleton("border-darkgrey"), delay(() => append(singleton("relative"), delay(() => append(singleton("inline-flex"), delay(() => append(singleton("h-[26px]"), delay(() => append(singleton("w-[43px]"), delay(() => append(singleton("shrink-0"), delay(() => append(singleton("cursor-pointer"), delay(() => append(singleton("rounded-full"), delay(() => append(singleton("border-transparent"), delay(() => append(singleton("transition-colors"), delay(() => append(singleton("duration-200"), delay(() => append(singleton("ease-in-out"), delay(() => append(singleton("focus:outline-none"), delay(() => append(singleton("focus-visible:ring-2"), delay(() => append(singleton("focus-visible:ring-white"), delay(() => singleton("focus-visible:ring-opacity-75"))))))))))))))))))))))))))))))))))))], (children = ofArray([createElement("span", {
                className: join(" ", ["sr-only"]),
                children: "blabla",
            }), createElement("span", {
                className: join(" ", toList(delay(() => append(props.Checked ? append(singleton("translate-x-5"), delay(() => append(singleton("bg-white"), delay(() => singleton("mt-[5px]"))))) : append(singleton("translate-x-1"), delay(() => append(singleton("bg-darkgrey"), delay(() => singleton("mt-[3.5px]"))))), delay(() => append(singleton("pointer-events-none"), delay(() => append(singleton("inline-block"), delay(() => append(singleton("h-[16px]"), delay(() => append(singleton("w-[16px]"), delay(() => append(singleton("transform"), delay(() => append(singleton("rounded-full"), delay(() => append(singleton("shadow-lg"), delay(() => append(singleton("ring-0"), delay(() => append(singleton("transition"), delay(() => append(singleton("duration-200"), delay(() => singleton("ease-in-out")))))))))))))))))))))))))),
            })]), ["children", reactApi.Children.toArray(children)])]), reactApi.createElement(Switch, createObj(props_4)))), delay(() => {
                const matchValue_1 = props.RightLabel;
                if (matchValue_1 == null) {
                    return empty();
                }
                else {
                    const rightLabel = matchValue_1;
                    return singleton(createElement("div", {
                        className: join(" ", ["flex", "items-center", "ml-2", "font-bold"]),
                        children: rightLabel,
                    }));
                }
            }));
        }));
    })), ["children", reactApi_1.Children.toArray(Array.from(elems))])])));
}

