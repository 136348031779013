import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { ToolReservationId, ToolId, PostResponse$1_$reflection, EmptyResponse_$reflection } from "../../../Shared/Shared.js";
import { record_type, lambda_type, unit_type, bool_type, union_type, class_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ToolWithQuantityDetailReservationDto_$reflection, ToolWithQuantityDetailDto_$reflection } from "../../../Shared/Tool.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_successToast } from "../../../Components/Toast.js";
import { ofArray } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { deleteToolWithQuantityReservation } from "../../../Requests/ToolWithQuantity.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ConfirmationDialog } from "../../../Components/Dialog.js";
import { defaultOf } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ToolReservationDeleted", "DeleteReservation", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("ToolWithQuantity.Dialogs.ToolWithQuantityDeleteReservation.Msg", [], Msg, () => [[["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Loading, SuccessCallback, OnClose, ToolWithQuantity, ToolReservation) {
        super();
        this.Loading = Loading;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.ToolWithQuantity = ToolWithQuantity;
        this.ToolReservation = ToolReservation;
    }
}

function State_$reflection() {
    return record_type("ToolWithQuantity.Dialogs.ToolWithQuantityDeleteReservation.State", [], State, () => [["Loading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["ToolWithQuantity", ToolWithQuantityDetailDto_$reflection()], ["ToolReservation", ToolWithQuantityDetailReservationDto_$reflection()]]);
}

function init(props) {
    return [new State(false, props.SuccessCallback, props.OnClose, props.ToolWithQuantity, props.ToolReservation), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 0:
            return [new State(false, state.SuccessCallback, state.OnClose, state.ToolWithQuantity, state.ToolReservation), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_remove_reservation_success")]))];
        case 2:
            return [new State(false, state.SuccessCallback, state.OnClose, state.ToolWithQuantity, state.ToolReservation), Cmd_none()];
        default:
            return [new State(true, state.SuccessCallback, state.OnClose, state.ToolWithQuantity, state.ToolReservation), deleteToolWithQuantityReservation(new ToolId(parse(state.ToolWithQuantity.Id)), new ToolReservationId(parse(state.ToolReservation.Id)), (Item_1) => (new Msg(0, [Item_1])), (Item_2) => (new Msg(2, [Item_2])))];
    }
}

export function DeleteToolWithQuantityReservationDialog(props) {
    let Title;
    const matchValue = props.Reservation;
    if (matchValue != null) {
        const reservation = matchValue;
        const t = useTranslation()[0];
        let patternInput_1;
        const init_1 = init({
            OnClose: props.OnClose,
            SuccessCallback: props.SuccessCallback,
            ToolReservation: reservation,
            ToolWithQuantity: props.ToolWithQuantity,
        });
        patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
        }), undefined, [props.IsOpen]);
        return createElement(ConfirmationDialog, (Title = t("general.delete"), {
            Description: t("tool.dialog_remove_reservation_query"),
            IsOpen: props.IsOpen,
            OnClose: props.OnClose,
            OnConfirm: patternInput_1[0].Loading ? (() => {
            }) : (() => {
                patternInput_1[1](new Msg(1, []));
            }),
            Title: Title,
        }));
    }
    else {
        return defaultOf();
    }
}

