import { Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { NoticeId, NoticeId_$reflection } from "../Shared/Shared.js";
import { record_type, bool_type, class_type, option_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { compare, now, toLocalTime, toString } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import Pencil from "../../public/assets/icons/Pencil.svg";
import { singleton as singleton_1, isEmpty, filter, sortBy, map, length, tryFind, append as append_1, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import Delete_1 from "../../public/assets/icons/Delete_1.svg";
import { ImageDecorator, CommonProps } from "../SharedComponents/ReactImageViewer.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import react_viewer from "react-viewer";
import Padlock_Square_1 from "../../public/assets/icons/Padlock_Square_1.svg";
import { useTranslation } from "react-i18next";
import { unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { Toggle } from "../Components/Toggle.js";
import { TextArea } from "../Components/TextArea.js";
import { Button } from "../Components/Button.js";
import Send_Email from "../../public/assets/icons/Send_Email.svg";
import Check from "../../public/assets/icons/Check.svg";

export class Message extends Record {
    constructor(Id, Message, ImageUrl, Timestamp, SenderName, SenderId, IsVisibleForUser) {
        super();
        this.Id = Id;
        this.Message = Message;
        this.ImageUrl = ImageUrl;
        this.Timestamp = Timestamp;
        this.SenderName = SenderName;
        this.SenderId = SenderId;
        this.IsVisibleForUser = IsVisibleForUser;
    }
}

export function Message_$reflection() {
    return record_type("Widgets.ChatWidget.Message", [], Message, () => [["Id", NoticeId_$reflection()], ["Message", string_type], ["ImageUrl", option_type(string_type)], ["Timestamp", class_type("System.DateTime")], ["SenderName", string_type], ["SenderId", string_type], ["IsVisibleForUser", bool_type]]);
}

function MessageComponent(props) {
    let elems_9, elems_8, elems_7, elems_3, elems_2, elems_4, elems_6;
    const patternInput = reactApi.useState(false);
    const setIsImageViewerOpen = patternInput[1];
    const timestampString = toString(toLocalTime(props.Message.Timestamp), "dd.MM.yyyy, HH:mm");
    return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("flex"), delay(() => append(singleton("items-end"), delay(() => ((props.UserId === props.Message.SenderId) ? singleton("justify-end") : empty()))))))))], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("flex"), delay(() => append(singleton("flex-col-reverse"), delay(() => append(singleton("rounded"), delay(() => append(singleton("w-3/5"), delay(() => append(singleton("mb-4"), delay(() => ((props.UserId === props.Message.SenderId) ? singleton("bg-primary") : singleton("bg-background")))))))))))))))], (elems_8 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("flex"), delay(() => append(singleton("flex-col"), delay(() => append(singleton("p-4"), delay(() => append(singleton("font-normal"), delay(() => ((props.UserId === props.Message.SenderId) ? singleton("text-white") : empty()))))))))))))], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "justify-between"])], (elems_3 = [createElement("span", {
        className: join(" ", ["text-base"]),
        children: props.Message.Message,
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "justify-between"])], (elems_2 = toList(delay(() => {
        let elems;
        return append(props.CanEdit ? singleton(createElement("div", createObj(ofArray([["onClick", (_arg) => {
            props.OnEditClicked(props.Message);
            props.SetIsEdit(true);
        }], ["className", join(" ", ["h-4", "w-4", "text-white", "cursor-pointer"])], (elems = [Pencil()], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty(), delay(() => {
            let elems_1;
            const matchValue = props.OnDeleteClicked;
            if (matchValue == null) {
                return empty();
            }
            else {
                const onDeleteClicked = matchValue;
                return singleton(createElement("div", createObj(ofArray([["onClick", (_arg_1) => {
                    onDeleteClicked(props.Message);
                }], ["className", join(" ", toList(delay(() => append(singleton("ml-4"), delay(() => append(singleton("h-4"), delay(() => append(singleton("w-4"), delay(() => append(singleton("cursor-pointer"), delay(() => ((props.UserId === props.Message.SenderId) ? singleton("text-white") : singleton("text-black")))))))))))))], (elems_1 = [Delete_1()], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
            }
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "items-center", "w-96"])], (elems_4 = toList(delay(() => {
        const matchValue_1 = props.Message.ImageUrl;
        if (matchValue_1 != null) {
            const imageUrl = matchValue_1;
            return append(singleton(createElement("img", {
                className: join(" ", ["cursor-pointer", "h-64", "object-cover"]),
                src: imageUrl,
                onClick: (_arg_2) => {
                    setIsImageViewerOpen(true);
                },
            })), delay(() => {
                let props_7, props_8;
                return singleton((props_7 = ofArray([new CommonProps(3, ["img-viewer"]), new CommonProps(2, [[new ImageDecorator(imageUrl)]]), new CommonProps(0, [patternInput[0]]), new CommonProps(4, [() => {
                    setIsImageViewerOpen(false);
                }])]), (props_8 = keyValueList(append_1(props_7, toList(delay(() => append(singleton(new CommonProps(6, [false])), delay(() => {
                    const matchValue_2 = tryFind((prop) => {
                        if (prop.tag === 4) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }, props_7);
                    let matchResult, onClose;
                    if (matchValue_2 != null) {
                        if (matchValue_2.tag === 4) {
                            matchResult = 0;
                            onClose = matchValue_2.fields[0];
                        }
                        else {
                            matchResult = 1;
                        }
                    }
                    else {
                        matchResult = 1;
                    }
                    switch (matchResult) {
                        case 0:
                            return singleton(new CommonProps(5, [onClose]));
                        default: {
                            return empty();
                        }
                    }
                }))))), 1), react.createElement(react_viewer, props_8))));
            }));
        }
        else {
            return empty();
        }
    })), ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mt-4"])], (elems_6 = toList(delay(() => {
        let elems_5;
        return append((!props.Message.IsVisibleForUser && props.CanEdit) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["h-4", "w-4", "mr-3"])], (elems_5 = [Padlock_Square_1()], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))) : empty(), delay(() => singleton(createElement("span", {
            className: join(" ", ["font-medium", "text-sm"]),
            children: `${timestampString} Uhr - ${props.Message.SenderName}`,
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])));
}

export function ChatWidget(props) {
    let elems_1, elems, elems_21, elems_3, elems_20;
    const t = useTranslation()[0];
    const emptyMessage = new Message(new NoticeId("00000000-0000-0000-0000-000000000000"), "", undefined, now(), "", "", false);
    const patternInput_1 = reactApi.useState(emptyMessage);
    const setEditMessage = patternInput_1[1];
    const editMessage = patternInput_1[0];
    const patternInput_2 = reactApi.useState("");
    const setNewMessage = patternInput_2[1];
    const newMessage = patternInput_2[0];
    const dependencies = [length(props.Messages)];
    reactApi.useEffect(() => {
        setNewMessage("");
        const element = document.getElementById("chatbox");
        element.scrollTop = element.scrollHeight;
    }, dependencies);
    const patternInput_3 = reactApi.useState(false);
    const setIsEdit = patternInput_3[1];
    let canUserWriteAMessage;
    const matchValue = props.UserData.Role;
    switch (matchValue) {
        case "user": {
            const matchValue_1 = props.CustomerConfiguration;
            canUserWriteAMessage = ((matchValue_1 == null) ? false : matchValue_1.CanUserWriteNotices);
            break;
        }
        default:
            canUserWriteAMessage = true;
    }
    const messages = map((m_1) => createElement(MessageComponent, {
        CanDelete: (m_1.SenderId === props.UserData.UserId) ? true : (props.UserData.Role === "administrator"),
        CanEdit: (props.OnEditClicked != null) && (m_1.SenderId === props.UserData.UserId),
        Message: m_1,
        OnDeleteClicked: unwrap(props.OnDeleteClicked),
        OnEditClicked: setEditMessage,
        SetIsEdit: setIsEdit,
        UserId: props.UserId,
    }), sortBy((m) => m.Timestamp, filter((message) => {
        const matchValue_2 = props.UserData.Role;
        switch (matchValue_2) {
            case "user":
                return message.IsVisibleForUser;
            default:
                return true;
        }
    }, props.Messages), {
        Compare: compare,
    }));
    const elements = isEmpty(messages) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "justify-center"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["bg-background", "p-2", "w-1/4", "rounded", "text-center"])], (elems = [t("general.no_content")], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : messages;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "justify-between", "grow", "h-full"])], (elems_21 = [createElement("div", createObj(ofArray([["id", "chatbox"], ["className", join(" ", ["flex", "flex-col", "space-y-4", "overflow-y-auto", "scrollbar-thumb-blue", "scrollbar-thumb-rounded", "scrollbar-track-blue-lighter", "scrollbar-w-2", "scrolling-touch", props.MaxContentHeigth])], (elems_3 = [createElement("div", {
        id: "messages",
        className: join(" ", []),
        children: reactApi.Children.toArray(Array.from(elements)),
    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "mt-8"])], (elems_20 = toList(delay(() => {
        let matchValue_3, userVisibility, elems_4, matchValue_4, elems_19, elems_11, matchValue_7, elems_10, elems_8, elems_9, elems_18, elems_12, elems_17, elems_14, elems_13, elems_16, elems_15;
        if (!patternInput_3[0]) {
            if (canUserWriteAMessage) {
                return append((matchValue_3 = props.UserVisibility, (matchValue_3 == null) ? (empty()) : ((userVisibility = matchValue_3, singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "mb-2"])], (elems_4 = [createElement(Toggle, {
                    Checked: !userVisibility.IsVisibleForUserChecked,
                    OnChange: (matchValue_4 = props.UserData.Role, (matchValue_4 === "toolManager") ? ((arg) => {
                        userVisibility.OnVisibleForUserChecked(!arg);
                    }) : ((matchValue_4 === "system") ? ((arg) => {
                        userVisibility.OnVisibleForUserChecked(!arg);
                    }) : ((matchValue_4 === "user") ? ((value_17) => {
                    }) : ((arg) => {
                        userVisibility.OnVisibleForUserChecked(!arg);
                    })))),
                    RightLabel: t("notice.hide_notice_from_user"),
                })], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))))))), delay(() => {
                    let elems_7, elems_5, elems_6;
                    const matchValue_5 = props.OnSubmit;
                    if (matchValue_5 == null) {
                        return empty();
                    }
                    else {
                        const onSubmit = matchValue_5;
                        return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["grow", "mr-4"])], (elems_5 = [createElement(TextArea, {
                            ComponentState: "enabled",
                            OnChange: setNewMessage,
                            TestId: "",
                            Value: newMessage,
                        })], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_6 = [createElement(Button, {
                            ComponentState: (newMessage !== "") ? "enabled" : "disabled",
                            Icon: Send_Email(),
                            Label: "",
                            OnClick: () => {
                                onSubmit(newMessage);
                            },
                            TestId: "chat-submit-button",
                            Variant: "primary",
                        })], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", reactApi.Children.toArray(Array.from(elems_7))])]))));
                    }
                }));
            }
            else {
                return empty();
            }
        }
        else {
            const matchValue_6 = props.OnEditClicked;
            if (matchValue_6 == null) {
                return empty();
            }
            else {
                const onEditClicked = matchValue_6;
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_19 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "gap-3", "mb-2"])], (elems_11 = [createElement(Toggle, {
                    Checked: !editMessage.IsVisibleForUser,
                    OnChange: (matchValue_7 = props.UserData.Role, (matchValue_7 === "toolManager") ? ((_arg_2) => {
                        setEditMessage(new Message(editMessage.Id, editMessage.Message, editMessage.ImageUrl, editMessage.Timestamp, editMessage.SenderName, editMessage.SenderId, !editMessage.IsVisibleForUser));
                    }) : ((matchValue_7 === "system") ? ((_arg_2) => {
                        setEditMessage(new Message(editMessage.Id, editMessage.Message, editMessage.ImageUrl, editMessage.Timestamp, editMessage.SenderName, editMessage.SenderId, !editMessage.IsVisibleForUser));
                    }) : ((matchValue_7 === "user") ? ((value_27) => {
                    }) : ((_arg_2) => {
                        setEditMessage(new Message(editMessage.Id, editMessage.Message, editMessage.ImageUrl, editMessage.Timestamp, editMessage.SenderName, editMessage.SenderId, !editMessage.IsVisibleForUser));
                    })))),
                    RightLabel: t("notice.hide_notice_from_user"),
                }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "text-text-muted", "text-sm", "font-semibold"])], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", ["h-4", "w-4", "mr-2"])], (elems_8 = [Pencil()], ["children", reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_9 = toList(delay(() => {
                    const editTranslation = t("notice.edit_notice");
                    const clockTranslation = t("general.clock");
                    return singleton(`${editTranslation}${toString(toLocalTime(editMessage.Timestamp), "dd.MM.yyyy, HH:mm")} ${clockTranslation}`);
                })), ["children", reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "grow"])], (elems_18 = [createElement("div", createObj(ofArray([["className", join(" ", ["grow"])], (elems_12 = [createElement(TextArea, {
                    ComponentState: "enabled",
                    OnChange: (message_1) => {
                        setEditMessage(new Message(editMessage.Id, message_1, editMessage.ImageUrl, editMessage.Timestamp, editMessage.SenderName, editMessage.SenderId, editMessage.IsVisibleForUser));
                    },
                    TestId: "",
                    Value: editMessage.Message,
                })], ["children", reactApi.Children.toArray(Array.from(elems_12))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "ml-3"])], (elems_17 = [createElement("div", createObj(ofArray([["className", join(" ", ["grow", "flex", "flex-row"])], (elems_14 = [createElement("div", createObj(ofArray([["className", join(" ", ["grow"])], (elems_13 = [createElement(Button, {
                    ComponentState: "enabled",
                    Icon: unwrap(Check()),
                    Label: "",
                    OnClick: () => {
                        onEditClicked(editMessage);
                        setIsEdit(false);
                    },
                    TestId: "",
                    Variant: "primary",
                })], ["children", reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["grow", "flex", "flex-row"])], (elems_16 = [createElement("div", createObj(ofArray([["className", join(" ", ["grow"])], (elems_15 = [createElement(Button, {
                    ComponentState: "enabled",
                    Icon: unwrap(Delete_1()),
                    Label: "",
                    OnClick: (message_2) => {
                        setEditMessage(emptyMessage);
                        setIsEdit(false);
                    },
                    TestId: "",
                    Variant: "secondary",
                })], ["children", reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", reactApi.Children.toArray(Array.from(elems_19))])]))));
            }
        }
    })), ["children", reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", reactApi.Children.toArray(Array.from(elems_21))])])));
}

