import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, list_type, class_type, option_type, anonRecord_type, bool_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, FormState__startLoading, SetId as SetId_1, FormState_get_empty, RequestedValue$1, FormState_$reflection, RequestedValue$1_$reflection, TokenDataDto_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName, Receiver_$reflection } from "../../Shared/User.js";
import { DataTransfer_AssignSetNotificationDto, DataTransfer_AssignSetDto, DataTransfer_AssignSetDto_$reflection, DataTransfer_DetailSet_$reflection } from "../../Shared/Set.js";
import { Cmd_ofEffect, Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { sortBy, toArray, map as map_1, ofArray, filter, tryFind, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { assignCmd, assignWithNotificationCmd } from "../../Requests/Set.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { unwrap, defaultArg, map } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { toISOString } from "../../Common.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { safeHash, comparePrimitives, equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { SetInfo } from "../../Widgets/SetInfoWidget.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { GroupedSelect } from "../../Components/Select.js";
import { List_groupBy } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { TextArea } from "../../Components/TextArea.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { ErrorAlert, InformationAlert } from "../../Components/Alert.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AssignedSetResponse", "Assign", "SetWithNotification", "SetWithSignature", "SetComment", "SetSelectedUser", "SetDate", "ReceiversFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Sets.Dialog.AssignDialog.Msg", [], Msg, () => [[["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", list_type(Receiver_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Set$, UserData, SuccessCallback, WithNotification, WithSignature, Dto, OnClose, Receivers, IsWithNotificationAllowed, PlannedReturnDate, FormState) {
        super();
        this.Set = Set$;
        this.UserData = UserData;
        this.SuccessCallback = SuccessCallback;
        this.WithNotification = WithNotification;
        this.WithSignature = WithSignature;
        this.Dto = Dto;
        this.OnClose = OnClose;
        this.Receivers = Receivers;
        this.IsWithNotificationAllowed = IsWithNotificationAllowed;
        this.PlannedReturnDate = PlannedReturnDate;
        this.FormState = FormState;
    }
}

function State_$reflection() {
    return record_type("Sets.Dialog.AssignDialog.State", [], State, () => [["Set", DataTransfer_DetailSet_$reflection()], ["UserData", TokenDataDto_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["WithNotification", bool_type], ["WithSignature", bool_type], ["Dto", DataTransfer_AssignSetDto_$reflection()], ["OnClose", lambda_type(unit_type, unit_type)], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["IsWithNotificationAllowed", bool_type], ["PlannedReturnDate", option_type(class_type("System.DateTime"))], ["FormState", FormState_$reflection()]]);
}

function init(set$, userData, successCallback, onClose) {
    return [new State(set$, userData, successCallback, false, false, new DataTransfer_AssignSetDto("", "", undefined, undefined), onClose, new RequestedValue$1(0, []), false, undefined, FormState_get_empty()), Cmd_batch(singleton(Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(8, [Item_1])))))];
}

function isWithNotifcationAllowed(userData, receiver) {
    if (userData.Role !== "user") {
        const matchValue = receiver.ReceiverType;
        switch (matchValue.tag) {
            case 1:
            case 5:
            case 8:
            case 6:
            case 2:
            case 7:
            case 3:
            case 9:
            case 4:
                return false;
            default:
                return true;
        }
    }
    else {
        return false;
    }
}

function update(msg, state) {
    let bind$0040, bind$0040_1, bind$0040_2, bind$0040_3;
    switch (msg.tag) {
        case 1: {
            const matchValue = state.Receivers;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const matchValue_1 = tryFind((receiver) => (receiver.Id === state.Dto.AffectedReceiverId), matchValue.fields[0]);
                if (matchValue_1 == null) {
                    return [state, Cmd_none()];
                }
                else if (state.WithNotification) {
                    if (isWithNotifcationAllowed(state.UserData, matchValue_1)) {
                        const dto = new DataTransfer_AssignSetNotificationDto(state.Dto.AffectedReceiverId, state.Dto.Comment, state.WithSignature, state.Dto.PlannedReturnDate, "storageToUser");
                        return [state, assignWithNotificationCmd(new SetId_1(parse(state.Set.Id)), dto, (Item_1) => (new Msg(0, [Item_1])), (Item_2) => (new Msg(8, [Item_2])))];
                    }
                    else {
                        return [state, Cmd_none()];
                    }
                }
                else {
                    return [new State(state.Set, state.UserData, state.SuccessCallback, state.WithNotification, state.WithSignature, state.Dto, state.OnClose, state.Receivers, state.IsWithNotificationAllowed, state.PlannedReturnDate, FormState__startLoading(state.FormState)), assignCmd(new SetId_1(parse(state.Set.Id)), state.Dto, (Item_4) => (new Msg(0, [Item_4])), (Item_5) => (new Msg(8, [Item_5])))];
                }
            }
        }
        case 2: {
            const withNotification = msg.fields[0];
            if (!withNotification) {
                return [new State(state.Set, state.UserData, state.SuccessCallback, withNotification, false, state.Dto, state.OnClose, state.Receivers, state.IsWithNotificationAllowed, state.PlannedReturnDate, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.Set, state.UserData, state.SuccessCallback, withNotification, state.WithSignature, state.Dto, state.OnClose, state.Receivers, state.IsWithNotificationAllowed, state.PlannedReturnDate, state.FormState), Cmd_none()];
            }
        }
        case 3: {
            const withSignature = msg.fields[0];
            if (withSignature) {
                return [new State(state.Set, state.UserData, state.SuccessCallback, true, withSignature, state.Dto, state.OnClose, state.Receivers, state.IsWithNotificationAllowed, state.PlannedReturnDate, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.Set, state.UserData, state.SuccessCallback, state.WithNotification, withSignature, state.Dto, state.OnClose, state.Receivers, state.IsWithNotificationAllowed, state.PlannedReturnDate, state.FormState), Cmd_none()];
            }
        }
        case 4:
            return [new State(state.Set, state.UserData, state.SuccessCallback, state.WithNotification, state.WithSignature, (bind$0040 = state.Dto, new DataTransfer_AssignSetDto(bind$0040.AffectedReceiverId, msg.fields[0], bind$0040.PlannedReturnDate, bind$0040.SignatureUrl)), state.OnClose, state.Receivers, state.IsWithNotificationAllowed, state.PlannedReturnDate, state.FormState), Cmd_none()];
        case 5: {
            const value = msg.fields[0];
            if (value == null) {
                return [state, Cmd_none()];
            }
            else {
                const receiver_2 = value;
                return [new State(state.Set, state.UserData, state.SuccessCallback, state.WithNotification, state.WithSignature, (bind$0040_1 = state.Dto, new DataTransfer_AssignSetDto(receiver_2.value.Id, bind$0040_1.Comment, bind$0040_1.PlannedReturnDate, bind$0040_1.SignatureUrl)), state.OnClose, state.Receivers, isWithNotifcationAllowed(state.UserData, receiver_2.value), state.PlannedReturnDate, state.FormState), Cmd_none()];
            }
        }
        case 6: {
            const value_1 = msg.fields[0];
            return [new State(state.Set, state.UserData, state.SuccessCallback, state.WithNotification, state.WithSignature, (bind$0040_2 = state.Dto, new DataTransfer_AssignSetDto(bind$0040_2.AffectedReceiverId, bind$0040_2.Comment, map(toISOString, value_1), bind$0040_2.SignatureUrl)), state.OnClose, state.Receivers, state.IsWithNotificationAllowed, value_1, state.FormState), Cmd_none()];
        }
        case 7: {
            const response_1 = msg.fields[0];
            const receiver_4 = tryFind((receiver_3) => (receiver_3.Id === state.UserData.UserId), response_1);
            let receivers_1;
            const matchValue_2 = state.UserData.Role;
            switch (matchValue_2) {
                case "administrator":
                case "toolManager":
                case "system": {
                    receivers_1 = response_1;
                    break;
                }
                default:
                    receivers_1 = filter((receiver_5) => (receiver_5.Id === state.UserData.UserId), response_1);
            }
            return [new State(state.Set, state.UserData, state.SuccessCallback, state.WithNotification, state.WithSignature, (bind$0040_3 = state.Dto, new DataTransfer_AssignSetDto(defaultArg(map((receiver_6) => receiver_6.Id, receiver_4), ""), bind$0040_3.Comment, bind$0040_3.PlannedReturnDate, bind$0040_3.SignatureUrl)), state.OnClose, new RequestedValue$1(1, [receivers_1]), state.IsWithNotificationAllowed, state.PlannedReturnDate, state.FormState), Cmd_none()];
        }
        case 8:
            return [state, Cmd_none()];
        default: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.Set, state.UserData, state.SuccessCallback, state.WithNotification, state.WithSignature, state.Dto, state.OnClose, state.Receivers, state.IsWithNotificationAllowed, state.PlannedReturnDate, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.Set, state.UserData, state.SuccessCallback, state.WithNotification, state.WithSignature, state.Dto, state.OnClose, state.Receivers, state.IsWithNotificationAllowed, state.PlannedReturnDate, FormState_get_empty()), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
    }
}

function DialogBody(props) {
    let elems_2;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "assign-tool-dialog"], ["className", join(" ", ["grid", "gap-5"])], (elems_2 = toList(delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(SetInfo, {
            DisplayReservationAlert: true,
            SetId: new SetId_1(parse(props.Set.Id)),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(singleton_1(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
            ComponentState: props.ComponentState,
            IsClearable: false,
            Key: "assign-tool-select",
            Label: t("tool.assign_tool"),
            NoOptionsMessage: "select.employee_no_options",
            OnChange: (arg) => {
                props.Dispatch(new Msg(5, [arg]));
            },
            Options: map_1((tupledArg) => {
                const receiverType = tupledArg[0];
                const receivers_1 = toArray(sortBy((receiver_2) => receiver_2.label.toLowerCase(), map_1((receiver_1) => {
                    const matchValue_1 = receiver_1.LocationName;
                    if (matchValue_1 != null) {
                        const locationName = matchValue_1;
                        if (equals(receiverType, new ReceiverTypeName(4, []))) {
                            return {
                                label: `${locationName} - ${receiver_1.Name}`,
                                value: receiver_1,
                            };
                        }
                        else {
                            return {
                                label: `${receiver_1.Name} - ${locationName}`,
                                value: receiver_1,
                            };
                        }
                    }
                    else {
                        return {
                            label: `${receiver_1.Name}`,
                            value: receiver_1,
                        };
                    }
                }, tupledArg[1]), {
                    Compare: comparePrimitives,
                }));
                return {
                    label: Helper_receiverTypeNameTranslation(t, receiverType),
                    options: receivers_1,
                };
            }, List_groupBy((receiver) => receiver.ReceiverType, filter((u) => {
                if (props.WithNotification) {
                    const matchValue = u.ReceiverType;
                    switch (matchValue.tag) {
                        case 1:
                        case 5:
                        case 8:
                        case 6:
                        case 2:
                        case 7:
                        case 3:
                        case 9:
                        case 4:
                            return false;
                        default:
                            return true;
                    }
                }
                else {
                    return true;
                }
            }, props.Receivers), {
                Equals: equals,
                GetHashCode: safeHash,
            })),
            PlaceholderKey: "select.employee_placeholder",
            Value: unwrap(props.SelectedUser),
        }))), delay(() => append(singleton_1(createElement(TextArea, {
            ComponentState: props.ComponentState,
            Label: t("general.comment"),
            OnChange: (arg_1) => {
                props.Dispatch(new Msg(4, [arg_1]));
            },
            TestId: "assign-tool-comment-test-id",
            Value: props.Comment,
        })), delay(() => {
            let Label_2, matchValue_2, selectedUser;
            return append(props.IsWithNotificationAllowed ? append(singleton_1(Checkbox((Label_2 = t("tool.dialog_assign_with_notification"), {
                ComponentState: (matchValue_2 = props.SelectedUser, (matchValue_2 != null) ? ((selectedUser = matchValue_2, (props.ComponentState === "enabled") ? (equals(selectedUser.value.ReceiverType, new ReceiverTypeName(0, [])) ? "enabled" : "disabled") : props.ComponentState)) : props.ComponentState),
                IsChecked: props.WithNotification,
                Label: Label_2,
                OnCheck: (arg_2) => {
                    props.Dispatch(new Msg(2, [arg_2]));
                },
                TestId: "assign-tool-dialog-with-notification-test-id",
            }))), delay(() => {
                let elems_1;
                return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(InformationAlert, {
                    Label: t("tool.dialog_assign_with_notification_info"),
                })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                    let Label_3, matchValue_4, selectedUser_1;
                    return singleton_1(Checkbox((Label_3 = t("tool.dialog_assign_with_signature"), {
                        ComponentState: (matchValue_4 = props.SelectedUser, (matchValue_4 != null) ? ((selectedUser_1 = matchValue_4, (props.ComponentState === "enabled") ? (equals(selectedUser_1.value.ReceiverType, new ReceiverTypeName(0, [])) ? "enabled" : "disabled") : props.ComponentState)) : props.ComponentState),
                        IsChecked: props.WithSignature,
                        Label: Label_3,
                        OnCheck: (arg_3) => {
                            props.Dispatch(new Msg(3, [arg_3]));
                        },
                        TestId: "assign-tool-dialog-with-notification-test-id",
                    })));
                }));
            })) : empty(), delay(() => append(singleton_1(createElement(DatePicker, {
                ComponentState: props.ComponentState,
                IsClearable: true,
                Label: t("tool.dialog_planned_return_date"),
                OnChange: (arg_4) => {
                    props.Dispatch(new Msg(6, [arg_4]));
                },
                TestId: "assign-tool-dialog-planned-return-date-test-id",
                Value: unwrap(props.Date),
            })), delay(() => {
                const matchValue_6 = FormState__getValidation(props.FormState, t, "global");
                if (matchValue_6 == null) {
                    return empty();
                }
                else {
                    return singleton_1(createElement(ErrorAlert, {
                        Label: matchValue_6,
                    }));
                }
            }))));
        }))))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function OpenAssignDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.Set, props.UserData, props.SuccessCallback, props.OnClose);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const formState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("tool.assign_now"), {
        Body: toList(delay(() => {
            const matchValue = state_1.Receivers;
            if (matchValue.tag === 0) {
                return singleton_1(createElement(Skeleton, {
                    Variant: "normal",
                }));
            }
            else {
                const receivers = matchValue.fields[0];
                return singleton_1(createElement(DialogBody, {
                    Comment: state_1.Dto.Comment,
                    ComponentState: formState,
                    Date: unwrap(state_1.PlannedReturnDate),
                    Dispatch: dispatch,
                    FormState: state_1.FormState,
                    IsWithNotificationAllowed: state_1.IsWithNotificationAllowed,
                    Receivers: receivers,
                    SelectedUser: unwrap(map((receiver_1) => ({
                        label: receiver_1.Name,
                        value: receiver_1,
                    }), tryFind((receiver) => (receiver.Id === state_1.Dto.AffectedReceiverId), receivers))),
                    Set: state_1.Set,
                    WithNotification: state_1.WithNotification,
                    WithSignature: state_1.WithSignature,
                }));
            }
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-reservation-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: state_1.FormState.IsLoading ? "disabled" : ((state_1.Dto.AffectedReceiverId === "") ? "disabled" : "enabled"),
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(1, []));
            },
            TestId: "save-reservation-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

