import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { isEmpty, ofArray, empty, toArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createObj, int32ToString } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { SignatureDialog } from "../../Components/SignatureDialog.js";
import { FormState__getValidation, FormState_get_empty } from "../../Shared/Shared.js";
import { Msg } from "./Types.js";
import { empty as empty_1, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Variant as Variant_3 } from "../../Variant.js";
import { ErrorAlert, InformationAlert } from "../../Components/Alert.js";
import { PropertyList } from "../../Widgets/PropertyListWidget.js";
import { toLocalTime, toString } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { TableContainer } from "../../Widgets/Container.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { TextValue } from "../../Components/Text.js";
import { Button, TextButton } from "../../Components/Button.js";
import Delete_1 from "../../../public/assets/icons/Delete_1.svg";
import Check from "../../../public/assets/icons/Check.svg";
import { update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

function ToolTable(props) {
    let TableRows;
    const t = useTranslation()[0];
    const xs = [createElement(Table, (TableRows = toArray(props.Tools), {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (row) => (new CellContent(4, [row.ImageUrl])), "none", "image"), new ColumnDefinition$1("name", "", t("general.name"), (row_1) => (new CellContent(0, [row_1.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_2) => (new CellContent(0, [row_2.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_3) => (new CellContent(0, [row_3.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_4) => (new CellContent(0, [row_4.Model])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function ToolWithQuantityTable(props) {
    let TableRows;
    const t = useTranslation()[0];
    const xs = [createElement(Table, (TableRows = toArray(props.ToolsWithQuantity), {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (row) => (new CellContent(4, [row.ImageUrl])), "none", "image"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_1) => (new CellContent(0, [int32ToString(row_1.Quantity)])), "text", "text"), new ColumnDefinition$1("name", "", t("general.name"), (row_2) => (new CellContent(0, [row_2.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_3) => (new CellContent(0, [row_3.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_4) => (new CellContent(0, [row_4.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_5) => (new CellContent(0, [row_5.Model])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function ConsumableTable(props) {
    let TableRows;
    const t = useTranslation()[0];
    const xs = [createElement(Table, (TableRows = toArray(props.Consumables), {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (row) => (new CellContent(4, [row.ImageUrl])), "none", "image"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_1) => (new CellContent(0, [row_1.Quantity.toString()])), "none", "text"), new ColumnDefinition$1("name", "", t("general.name"), (row_2) => (new CellContent(0, [row_2.Name])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_3) => (new CellContent(0, [row_3.InventoryNumber])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function SetTable(props) {
    let TableRows;
    const t = useTranslation()[0];
    const xs = [createElement(Table, (TableRows = toArray(props.Sets), {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (row) => (new CellContent(4, [row.ImageUrl])), "none", "image"), new ColumnDefinition$1("name", "", t("general.name"), (row_1) => (new CellContent(0, [row_1.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_2) => (new CellContent(0, [row_2.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_3) => (new CellContent(0, [row_3.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_4) => (new CellContent(0, [row_4.Model])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function DetailView(props) {
    let elems_11, elems_10;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsSignatureDialogOpen = patternInput_1[1];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-shrink-0", "flex-col", "mb-3", "rounded", "px-12", "py-9", "bg-background", "min-h-full", "items-center"])], (elems_11 = [createElement(SignatureDialog, {
        Description: "",
        FormState: FormState_get_empty(),
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setIsSignatureDialogOpen(false);
        },
        OnConfirm: (file) => {
            props.Dispatch(new Msg(5, [file]));
            setIsSignatureDialogOpen(false);
        },
    }), createElement("div", createObj(ofArray([["className", join(" ", ["max-w-[90%] lg:min-w-[80rem]", "flex", "flex-col"])], (elems_10 = toList(delay(() => {
        let elems_2, elems, elems_1;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-shrink-0", "flex-col", "gap-5", "bg-white", "rounded", "px-12", "py-9"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center"])], (elems = [createElement("img", {
            className: join(" ", ["w-80"]),
            src: (Variant_3.tag === 0) ? "assets/images/mwk_logo.png" : ((Variant_3.tag === 2) ? "assets/images/eagletrackmate-logo.png" : "assets/images/inventory-one-logo.png"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(InformationAlert, {
            Label: "notification.email_assignment_user_info",
        }), createElement("div", createObj(ofArray([["className", join(" ", ["flex"])], (elems_1 = [createElement(PropertyList, {
            Properties: toList(delay(() => append(singleton({
                Label: t("general.sender"),
                TestId: "email-notification-sender-name-test-id",
                Value: props.AssignEmailNotificationDetailDto.SenderName,
            }), delay(() => append(singleton({
                Label: t("general.receiver"),
                TestId: "email-notification-receiver-name-test-id",
                Value: props.AssignEmailNotificationDetailDto.ReceiverName,
            }), delay(() => {
                let matchValue, returnDate;
                return append((matchValue = props.AssignEmailNotificationDetailDto.OptionalReturnDate, (matchValue == null) ? (empty_1()) : ((returnDate = matchValue, singleton({
                    Label: t("tool.planned_return_date"),
                    TestId: "email-notification-planned-return-date-test-id",
                    Value: toString(toLocalTime(returnDate), "dd.MM.yyyy"),
                })))), delay(() => singleton({
                    Label: t("general.comment"),
                    TestId: "email-notification-comment-test-id",
                    Value: props.AssignEmailNotificationDetailDto.Comment,
                })));
            })))))),
        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
            let Title_1;
            return append(!isEmpty(props.AssignEmailNotificationDetailDto.Tools) ? singleton(createElement(TableContainer, (Title_1 = t("general.tool"), {
                Content: createElement(ToolTable, {
                    Tools: props.AssignEmailNotificationDetailDto.Tools,
                }),
                Title: unwrap(Title_1),
            }))) : empty_1(), delay(() => {
                let Title_2;
                return append(!isEmpty(props.AssignEmailNotificationDetailDto.ToolsWithQuantity) ? singleton(createElement(TableContainer, (Title_2 = t("general.tool_with_quantity"), {
                    Content: createElement(ToolWithQuantityTable, {
                        ToolsWithQuantity: props.AssignEmailNotificationDetailDto.ToolsWithQuantity,
                    }),
                    Title: unwrap(Title_2),
                }))) : empty_1(), delay(() => {
                    let Title_3;
                    return append(!isEmpty(props.AssignEmailNotificationDetailDto.Sets) ? singleton(createElement(TableContainer, (Title_3 = t("general.set"), {
                        Content: createElement(SetTable, {
                            Sets: props.AssignEmailNotificationDetailDto.Sets,
                        }),
                        Title: unwrap(Title_3),
                    }))) : empty_1(), delay(() => {
                        let Title_4;
                        return append(!isEmpty(props.AssignEmailNotificationDetailDto.Consumables) ? singleton(createElement(TableContainer, (Title_4 = t("general.consumable"), {
                            Content: createElement(ConsumableTable, {
                                Consumables: props.AssignEmailNotificationDetailDto.Consumables,
                            }),
                            Title: unwrap(Title_4),
                        }))) : empty_1(), delay(() => {
                            let elems_9, elems_6, elems_8, elems_7, Label_5, Label_6;
                            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "sm:flex-row", "gap-3", "justify-between", "mt-8", "bg-white", "rounded", "px-5", "sm:px-12", "py-9"])], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_6 = toList(delay(() => {
                                let matchValue_1, elems_4, signature, imageUrl, elems_3;
                                return props.AssignEmailNotificationDetailDto.WithSignature ? append((matchValue_1 = props.Signature, (matchValue_1 == null) ? append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_4 = [createElement(TextValue, {
                                    FontWeight: "normal",
                                    TestId: "",
                                    Text: t("notification.signature_description"),
                                })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => singleton(createElement(TextButton, {
                                    ComponentState: componentState,
                                    Label: t("notification.sign"),
                                    OnClick: () => {
                                        setIsSignatureDialogOpen(true);
                                    },
                                    TestId: "notification-sign-button-test-id",
                                    Variant: "blueButton",
                                })))) : ((signature = matchValue_1, (imageUrl = URL.createObjectURL(signature), singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_3 = [createElement(TextValue, {
                                    FontWeight: "bold",
                                    TestId: "",
                                    Text: t("general.signature"),
                                }), createElement("img", {
                                    className: join(" ", ["h-24"]),
                                    src: imageUrl,
                                })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))))))), delay(() => {
                                    let elems_5;
                                    const matchValue_2 = FormState__getValidation(props.FormState, t, "signature");
                                    if (matchValue_2 == null) {
                                        return empty_1();
                                    }
                                    else {
                                        const validationMessage = matchValue_2;
                                        return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["max-w-[75rem]", "mb-5"])], (elems_5 = [createElement(ErrorAlert, {
                                            Label: validationMessage,
                                        })], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))));
                                    }
                                })) : empty_1();
                            })), ["children", reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-2", "sm:gap-5", "place-items-center"])], (elems_8 = [createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_7 = [createElement(Button, (Label_5 = t("notification.reject"), {
                                ComponentState: componentState,
                                Icon: Delete_1(),
                                Label: Label_5,
                                OnClick: () => {
                                    props.Dispatch(new Msg(1, []));
                                },
                                TestId: "notification-reject-button",
                                Variant: "redButton",
                            }))], ["children", reactApi.Children.toArray(Array.from(elems_7))])]))), createElement(Button, (Label_6 = t("notification.accept"), {
                                ComponentState: (props.AssignEmailNotificationDetailDto.WithSignature && (props.Signature == null)) ? "disabled" : componentState,
                                Icon: Check(),
                                Label: Label_6,
                                OnClick: () => {
                                    if (props.AssignEmailNotificationDetailDto.WithSignature && (props.Signature != null)) {
                                        props.Dispatch(new Msg(6, []));
                                    }
                                    else {
                                        props.Dispatch(new Msg(0, []));
                                    }
                                },
                                TestId: "notification-assign-button",
                                Variant: "primary",
                            }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", reactApi.Children.toArray(Array.from(elems_9))])]))));
                        }));
                    }));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", reactApi.Children.toArray(Array.from(elems_11))])])));
}

export function AcceptEmailNotificationView(props) {
    let patternInput;
    const init = init_1(props.NotificationId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const matchValue = state_1.AcceptEmailNotificationDetail;
    if (matchValue.tag === 0) {
        return createElement(Skeleton, {
            Variant: "detail",
        });
    }
    else {
        return createElement(DetailView, {
            AssignEmailNotificationDetailDto: matchValue.fields[0],
            Dispatch: patternInput[1],
            FormState: state_1.FormState,
            Signature: unwrap(state_1.Signature),
        });
    }
}

