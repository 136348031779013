import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { TextValue, BlockHeader } from "../Components/Text.js";
import { Button, TextButton } from "../Components/Button.js";
import { Msg } from "./Types.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { LanguageSelector } from "../Components/I18n.js";
import { EmailNotification } from "../Widgets/EmailNotification.js";
import { update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { singleton as singleton_1, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Skeleton } from "../Components/Skeleton.js";

export function ViewContent(props) {
    let elems_5, elems_1, elems, elems_3, elems_2, elems_4, children;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid grid-cols-2 gap-4"])], (elems_5 = [createElement("div", createObj(singleton((elems_1 = [createElement(BlockHeader, {
        Text: t("profile.password_header"),
    }), createElement(TextValue, {
        FontWeight: "normal",
        TestId: "",
        Text: t("profile.password_reset_description_1"),
    }), createElement(TextValue, {
        FontWeight: "normal",
        TestId: "",
        Text: t("profile.password_reset_description_2"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["mt-2"])], (elems = [createElement(TextButton, {
        ComponentState: "enabled",
        Label: t("profile.reset_password"),
        OnClick: () => {
            props.Dispatch(new Msg(0, []));
        },
        TestId: "profile-resetpassword-button",
        Variant: "blueButton",
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])))), createElement("div", createObj(singleton((elems_3 = [createElement(BlockHeader, {
        Text: t("profile.language_header"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["w-64"])], (elems_2 = [createElement(LanguageSelector, {
        ChangeCallback: (arg) => {
            props.Dispatch(new Msg(1, [arg]));
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])))), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems_4 = [createElement(EmailNotification, {
        FormState: "enabled",
        OnChangeEmailNotification: (arg_1) => {
            props.Dispatch(new Msg(4, [arg_1]));
        },
        User: props.User,
    }), (children = singleton(createElement(Button, {
        ComponentState: "enabled",
        Label: t("general.save"),
        OnClick: () => {
            props.Dispatch(new Msg(6, []));
        },
        TestId: "update-email-notification-button",
        Variant: "primary",
    })), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function ProfileView(props) {
    let elems;
    let patternInput;
    const init = init_1(props.TokenDataDto);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    return createElement("div", createObj(ofArray([["className", ""], (elems = toList(delay(() => {
        const matchValue = state_1.User;
        const matchValue_1 = state_1.Storages;
        let matchResult, user;
        if (matchValue.tag === 1) {
            if (matchValue_1.tag === 1) {
                matchResult = 0;
            }
            else {
                matchResult = 0;
            }
        }
        else if (matchValue_1.tag === 0) {
            matchResult = 1;
            user = matchValue.fields[0];
        }
        else {
            matchResult = 0;
        }
        switch (matchResult) {
            case 0:
                return singleton_1(createElement(Skeleton, {
                    Variant: "normal",
                }));
            default:
                return singleton_1(createElement(ViewContent, {
                    Dispatch: patternInput[1],
                    User: user,
                }));
        }
    })), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

