import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { OverviewNotification_$reflection } from "../../Shared/Notification.js";
import { union_type, class_type, record_type, string_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { RequestedValue$1, RequestedValue$1_$reflection } from "../../Shared/Shared.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getNotifications } from "../../Requests/Notification.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { empty, map, singleton as singleton_1, isEmpty, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import Loading_Circle from "../../../public/assets/icons/Loading_Circle.svg";
import { DropdownButton } from "../../Components/Button.js";
import Notification_Alarm_2 from "../../../public/assets/icons/Notification_Alarm_2.svg";
import Bell_Notification from "../../../public/assets/icons/Bell_Notification.svg";

class State extends Record {
    constructor(Notifications, FormState) {
        super();
        this.Notifications = Notifications;
        this.FormState = FormState;
    }
}

function State_$reflection() {
    return record_type("Widgets.Notification.State", [], State, () => [["Notifications", RequestedValue$1_$reflection(list_type(OverviewNotification_$reflection()))], ["FormState", string_type]]);
}

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FetchNotifications", "NotificationsFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Widgets.Notification.Msg", [], Msg, () => [[], [["Item", list_type(OverviewNotification_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

const init = [new State(new RequestedValue$1(0, []), "enabled"), Cmd_none()];

function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.FormState), Cmd_none()];
        case 2:
            return [state, Cmd_none()];
        default:
            return [state, getNotifications((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])))];
    }
}

export function toDropdownButtonElement(t, notification) {
    let arg_3, arg_5, arg_7, arg_9, arg_11, arg_13, arg_15, arg_17, arg_19, arg_21, arg_23, arg_25, arg_27, arg_29, arg_31, arg_1;
    const matchValue = notification.NotificationType;
    switch (matchValue) {
        case "toolUserToStorageRequest":
            return {
                DisplayName: (arg_3 = {
                    name: notification.EntityName,
                    sender: notification.Sender,
                }, t("notification.tool_user_to_storage_request", arg_3)),
                OnClick: (_arg_1) => {
                    RouterModule_nav(ofArray(["tools", notification.EntityId]), 1, 1);
                },
            };
        case "toolStorageToUserAssignment":
            return {
                DisplayName: (arg_5 = {
                    name: notification.EntityName,
                }, t("notification.tool_storage_to_user", arg_5)),
                OnClick: (_arg_2) => {
                    RouterModule_nav(ofArray(["tools", notification.EntityId]), 1, 1);
                },
            };
        case "toolUserToUserAssignment":
            return {
                DisplayName: (arg_7 = {
                    name: notification.EntityName,
                    sender: notification.Sender,
                }, t("notification.tool_user_to_user", arg_7)),
                OnClick: (_arg_3) => {
                    RouterModule_nav(ofArray(["tools", notification.EntityId]), 1, 1);
                },
            };
        case "toolWithQuantityBackToStorage":
            return {
                DisplayName: (arg_9 = {
                    name: notification.EntityName,
                    sender: notification.Sender,
                }, t("notification.tool_with_quantity_back_to_storage", arg_9)),
                OnClick: (_arg_4) => {
                    RouterModule_nav(ofArray(["toolswithquantity", notification.EntityId]), 1, 1);
                },
            };
        case "toolWithQuantityUserToStorageRequest":
            return {
                DisplayName: (arg_11 = {
                    name: notification.EntityName,
                    sender: notification.Sender,
                }, t("notification.tool_with_quantity_user_to_storage_request", arg_11)),
                OnClick: (_arg_5) => {
                    RouterModule_nav(ofArray(["toolswithquantity", notification.EntityId]), 1, 1);
                },
            };
        case "toolWithQuantityStorageToUserAssignment":
            return {
                DisplayName: (arg_13 = {
                    name: notification.EntityName,
                }, t("notification.tool_with_quantity_storage_to_user", arg_13)),
                OnClick: (_arg_6) => {
                    RouterModule_nav(ofArray(["toolswithquantity", notification.EntityId]), 1, 1);
                },
            };
        case "toolWithQuantityUserToUserAssignment":
            return {
                DisplayName: (arg_15 = {
                    name: notification.EntityName,
                    sender: notification.Sender,
                }, t("notification.tool_with_quantity_user_to_user", arg_15)),
                OnClick: (_arg_7) => {
                    RouterModule_nav(ofArray(["toolswithquantity", notification.EntityId]), 1, 1);
                },
            };
        case "setBackToStorage":
            return {
                DisplayName: (arg_17 = {
                    name: notification.EntityName,
                    sender: notification.Sender,
                }, t("notification.set_back_to_storage", arg_17)),
                OnClick: (_arg_8) => {
                    RouterModule_nav(ofArray(["sets", notification.EntityId]), 1, 1);
                },
            };
        case "setUserToStorageRequest":
            return {
                DisplayName: (arg_19 = {
                    name: notification.EntityName,
                    sender: notification.Sender,
                }, t("notification.set_user_to_storage_request", arg_19)),
                OnClick: (_arg_9) => {
                    RouterModule_nav(ofArray(["sets", notification.EntityId]), 1, 1);
                },
            };
        case "setStorageToUserAssignment":
            return {
                DisplayName: (arg_21 = {
                    name: notification.EntityName,
                }, t("notification.set_storage_to_user", arg_21)),
                OnClick: (_arg_10) => {
                    RouterModule_nav(ofArray(["sets", notification.EntityId]), 1, 1);
                },
            };
        case "setUserToUserAssignment":
            return {
                DisplayName: (arg_23 = {
                    name: notification.EntityName,
                    sender: notification.Sender,
                }, t("notification.set_user_to_user", arg_23)),
                OnClick: (_arg_11) => {
                    RouterModule_nav(ofArray(["sets", notification.EntityId]), 1, 1);
                },
            };
        case "consumableStorageToUserAssignment":
            return {
                DisplayName: (arg_25 = {
                    name: notification.EntityName,
                }, t("notification.consumable_storage_to_user", arg_25)),
                OnClick: (_arg_12) => {
                    RouterModule_nav(ofArray(["consumables", notification.EntityId]), 1, 1);
                },
            };
        case "consumableReturnConsumableToStorage":
            return {
                DisplayName: (arg_27 = {
                    name: notification.EntityName,
                    sender: notification.Sender,
                }, t("notification.consumable_back_to_storage", arg_27)),
                OnClick: (_arg_13) => {
                    RouterModule_nav(ofArray(["consumables", notification.EntityId]), 1, 1);
                },
            };
        case "consumableReturnConsumableToStorageRequest":
            return {
                DisplayName: (arg_29 = {
                    name: notification.EntityName,
                    sender: notification.Sender,
                }, t("notification.consumable_user_to_storage_request", arg_29)),
                OnClick: (_arg_14) => {
                    RouterModule_nav(ofArray(["consumables", notification.EntityId]), 1, 1);
                },
            };
        case "storageToUserBulk":
            return {
                DisplayName: (arg_31 = {
                    name: notification.EntityName,
                    sender: notification.Sender,
                }, t("notification.storage_to_user_bulk", arg_31)),
                OnClick: (_arg_15) => {
                    RouterModule_nav(ofArray(["todo", notification.EntityId]), 1, 1);
                },
            };
        default:
            return {
                DisplayName: (arg_1 = {
                    name: notification.EntityName,
                    sender: notification.Sender,
                }, t("notification.tool_back_to_storage", arg_1)),
                OnClick: (_arg) => {
                    RouterModule_nav(ofArray(["tools", notification.EntityId]), 1, 1);
                },
            };
    }
}

export function Notifications() {
    let elems_1;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update, (_arg, _arg_1) => {
    }), undefined, undefined);
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    reactApi.useEffect(() => {
        dispatch(new Msg(0, []));
        setInterval(() => {
            dispatch(new Msg(0, []));
        }, 60000);
    }, []);
    return createElement("div", createObj(ofArray([["className", join(" ", ["mr-5"])], (elems_1 = toList(delay(() => {
        let elems;
        const matchValue = patternInput[0].Notifications;
        return (matchValue.tag === 0) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["animate-[spin_1.5s_linear_infinite]", "text-primary"])], (elems = [Loading_Circle()], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : (!isEmpty(matchValue.fields[0]) ? singleton(createElement(DropdownButton, {
            Classes: singleton_1("z-[11]"),
            ComponentState: "enabled",
            DisplayArrow: false,
            Elements: map((notification) => toDropdownButtonElement((arg_1, arg_2) => (patternInput_1[1].t(arg_1, arg_2)), notification), matchValue.fields[0]),
            Icon: Notification_Alarm_2(),
            TestId: "",
        })) : singleton(createElement(DropdownButton, {
            Classes: singleton_1("z-[11]"),
            ComponentState: "disabled",
            DisplayArrow: false,
            Elements: empty(),
            Icon: Bell_Notification(),
            TestId: "",
        })));
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

