import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { PageHeader } from "../Components/Text.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { TextButton } from "../Components/Button.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";

export function View(viewInputProps) {
    let elems_5, elems, elems_1, children, elems_2, children_2, children_4, elems_4, value_34;
    const _arg = viewInputProps._arg1;
    const t = useTranslation()[0];
    const phoneNumber = t("global.phone_number");
    return createElement("div", createObj(ofArray([["id", "support-container"], ["className", ""], (elems_5 = [createElement(PageHeader, {
        Text: "Support",
    }), createElement("h2", createObj(singleton((elems = [t("general.contact")], ["children", reactApi.Children.toArray(Array.from(elems))])))), createElement("div", createObj(ofArray([["className", "mb-4"], (elems_1 = [t("support.description")], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), (children = ofArray([createElement("span", createObj(ofArray([["className", "category mr-1"], (elems_2 = [t("support.phone")], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("a", {
        href: `tel:${phoneNumber}`,
        children: phoneNumber,
    })]), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), (children_2 = ofArray([createElement("span", {
        className: "category mr-1",
        children: "E-Mail:",
    }), createElement("a", {
        href: "mailto:support@inventory-one.com",
        children: "support@inventory-one.com",
    })]), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = ofArray([createElement("span", {
        className: "category mr-1",
        children: "Homepage:",
    }), createElement(TextButton, {
        ComponentState: "enabled",
        Label: "www.inventory-one.com",
        OnClick: () => {
            RouterModule_nav(singleton("https://www.inventory-one.com"), 1, 1);
        },
        TestId: "support-inventoryone-button",
        Variant: "default",
    })]), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    })), createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems_4 = [createElement("h2", {
        children: reactApi.Children.toArray(["Feedback"]),
    }), createElement("p", createObj(ofArray([["className", join(" ", ["mb-4"])], (value_34 = "Dein Feedback ist uns wichtig! Über den folgenden Link erreichst du das Feedback-Formular, damit wir uns stetig verbessern können.", ["children", value_34])]))), createElement(TextButton, {
        ComponentState: "enabled",
        Label: "Feedback-Formular",
        OnClick: () => {
            RouterModule_nav(singleton("https://forms.office.com/e/en5R0qHt68"), 1, 1);
        },
        TestId: "support-feedback-formular-button",
        Variant: "default",
    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
}

