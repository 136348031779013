import { createElement } from "react";
import React from "react";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { singleton as singleton_1, map, fold, empty as empty_1, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { newGuid } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import Arrow_Up_3 from "../../public/assets/icons/Arrow_Up_3.svg";
import Arrow_Down_3 from "../../public/assets/icons/Arrow_Down_3.svg";
import { reactApi as reactApi_1 } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { Transition, Menu } from "@headlessui/react";

export function BaseButton(props) {
    let elems_1;
    return createElement("button", createObj(ofArray([["data-test-id", props.TestId], ["className", join(" ", toList(delay(() => append(singleton("flex"), delay(() => append(singleton("items-center"), delay(() => append(singleton("justify-center"), delay(() => append(singleton("h-10"), delay(() => append(singleton("px-3.5"), delay(() => append(singleton("text-sm"), delay(() => append(singleton("rounded-lg"), delay(() => append(singleton("font-semibold"), delay(() => {
        let matchValue, matchValue_1;
        return append((matchValue = props.Level, (matchValue_1 = props.ComponentState, (matchValue === "secondary") ? ((matchValue_1 === "disabled") ? append(singleton("border"), delay(() => append(singleton("border-border-muted"), delay(() => singleton("text-text-muted"))))) : append(singleton("border"), delay(() => append(singleton("border-[#B6BECB]"), delay(() => singleton("text-black")))))) : ((matchValue === "blueButton") ? ((matchValue_1 === "disabled") ? append(singleton("border"), delay(() => append(singleton("border-border-muted"), delay(() => singleton("text-text-muted"))))) : append(singleton("border"), delay(() => append(singleton("border-primary"), delay(() => singleton("text-primary")))))) : ((matchValue === "redButton") ? ((matchValue_1 === "disabled") ? append(singleton("border"), delay(() => append(singleton("border-border-muted"), delay(() => singleton("text-text-muted"))))) : append(singleton("bg-transparent"), delay(() => append(singleton("border"), delay(() => append(singleton("border-red"), delay(() => singleton("text-red")))))))) : ((matchValue_1 === "disabled") ? append(singleton("bg-border-muted"), delay(() => singleton("text-white"))) : append(singleton("bg-primary"), delay(() => singleton("text-white")))))))), delay(() => append((props.ComponentState === "disabled") ? singleton("cursor-normal") : singleton("cursor-pointer"), delay(() => props.Classes))));
    })))))))))))))))))))], ["disabled", props.ComponentState === "disabled"], ["onClick", (_arg) => {
        props.OnClick();
    }], (elems_1 = toList(delay(() => {
        let matchValue_4, iconElement;
        return append((matchValue_4 = props.Icon, (matchValue_4 == null) ? (empty()) : ((iconElement = matchValue_4, singleton(createElement("div", {
            className: join(" ", toList(delay(() => append(singleton("stroke-1"), delay(() => {
                let matchValue_5;
                return append((matchValue_5 = props.Level, (matchValue_5 === "secondary") ? singleton("text-black") : ((matchValue_5 === "blueButton") ? singleton("text-primary") : ((matchValue_5 === "redButton") ? singleton("text-red") : singleton("text-white")))), delay(() => append(singleton("h-5"), delay(() => append(singleton("w-5"), delay(() => ((props.Label !== "") ? singleton("mr-2.5") : empty())))))));
            }))))),
            children: reactApi.Children.toArray([iconElement]),
        }))))), delay(() => singleton(props.Label)));
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function Button(props) {
    return createElement(BaseButton, {
        Classes: empty_1(),
        ComponentState: props.ComponentState,
        Icon: unwrap(props.Icon),
        Label: props.Label,
        Level: props.Variant,
        OnClick: props.OnClick,
        TestId: props.TestId,
    });
}

export function TextButton(props) {
    let elems_1;
    return createElement("div", createObj(ofArray([["data-test-id", props.TestId], ["className", join(" ", toList(delay(() => append(singleton("flex"), delay(() => append(singleton("content-center"), delay(() => ((props.ComponentState === "disabled") ? singleton("cursor-default") : singleton("cursor-pointer")))))))))], ["onClick", (props.ComponentState === "disabled") ? ((value_3) => {
    }) : ((_arg) => {
        props.OnClick();
    })], (elems_1 = toList(delay(() => {
        let matchValue_2, iconElement;
        return append((matchValue_2 = props.Icon, (matchValue_2 == null) ? (empty()) : ((iconElement = matchValue_2, singleton(createElement("div", {
            className: join(" ", toList(delay(() => {
                let matchValue_3, matchValue_4;
                return append((matchValue_3 = props.Variant, (matchValue_4 = props.ComponentState, (matchValue_3 === "blueButton") ? ((matchValue_4 === "disabled") ? singleton("text-text-muted") : singleton("text-primary-link")) : ((matchValue_3 === "redButton") ? ((matchValue_4 === "disabled") ? singleton("text-text-muted") : singleton("text-red")) : ((matchValue_3 === "muted") ? ((matchValue_4 === "disabled") ? singleton("text-text-muted") : singleton("text-text-muted")) : ((matchValue_4 === "disabled") ? singleton("text-text-muted") : singleton("text-black")))))), delay(() => append(singleton("h-5"), delay(() => append(singleton("w-5"), delay(() => singleton("mr-2.5")))))));
            }))),
            children: reactApi.Children.toArray([iconElement]),
        }))))), delay(() => singleton(createElement("div", {
            className: join(" ", toList(delay(() => {
                let matchValue_6, matchValue_7;
                return append((matchValue_6 = props.Variant, (matchValue_7 = props.ComponentState, (matchValue_6 === "blueButton") ? ((matchValue_7 === "disabled") ? singleton("text-text-muted") : singleton("text-primary-link")) : ((matchValue_6 === "redButton") ? ((matchValue_7 === "disabled") ? singleton("text-text-muted") : singleton("text-red")) : ((matchValue_6 === "muted") ? ((matchValue_7 === "disabled") ? singleton("text-text-muted") : singleton("text-text-muted")) : ((matchValue_7 === "disabled") ? singleton("text-text-muted") : (empty())))))), delay(() => append(singleton("text-sm"), delay(() => append(singleton("font-medium"), delay(() => append(singleton("flex"), delay(() => singleton("items-center")))))))));
            }))),
            children: props.Label,
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function SidebarTextButton(props) {
    let elems;
    return createElement("div", createObj(ofArray([["data-test-id", props.TestId], ["className", join(" ", ["flex", "content-center", "cursor-pointer"])], ["onClick", (_arg) => {
        props.OnClick();
    }], (elems = toList(delay(() => {
        let matchValue, iconName;
        return append((matchValue = props.Icon, (matchValue == null) ? (empty()) : ((iconName = matchValue, singleton(createElement("img", {
            className: join(" ", ["mr-2.5"]),
            src: `/assets/icons/${iconName}`,
        }))))), delay(() => singleton(createElement("div", {
            className: join(" ", toList(delay(() => {
                let matchValue_1;
                return append((matchValue_1 = props.Variant, (matchValue_1 === "blueButton") ? singleton("text-primary-link") : ((matchValue_1 === "redButton") ? singleton("text-red") : ((matchValue_1 === "muted") ? singleton("text-text-muted") : (empty())))), delay(() => append(singleton("text-sm"), delay(() => append(singleton("font-medium"), delay(() => append(singleton("flex"), delay(() => append(singleton("items-center"), delay(() => singleton("font-semibold")))))))))));
            }))),
            children: props.Label,
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function DropdownButton(props) {
    const key = newGuid();
    const props_12 = ofArray([["as", "div"], ["key", `${key}-dropdown-key`], ["className", fold((a, b) => (`${a} ${b}`), "", toList(delay(() => append(singleton("relative"), delay(() => append(singleton("z-10"), delay(() => props.Classes)))))))], ["children", (value_4) => {
        let elems_3, props_5, value_20, props_11, value_42, props_10, value_41, elems_4;
        return ofArray([createElement("div", createObj(ofArray([["key", `${key}-dropdown-children-key`], (elems_3 = [(props_5 = ofArray([["disabled", props.ComponentState === "disabled"], ["className", fold((a_1, b_1) => (`${a_1} ${b_1}`), "", ofArray(["flex", "items-center", "gap-3.5"]))], ["key", `${key}-dropdown-button-key`], (value_20 = toList(delay(() => {
            let matchValue_1;
            return append((matchValue_1 = props.Label, (matchValue_1 == null) ? (empty()) : singleton(createElement("div", {
                key: "dropdown-button-text-key",
                children: matchValue_1,
            }))), delay(() => {
                let matchValue_2, iconElement;
                return append((matchValue_2 = props.Icon, (matchValue_2 == null) ? (empty()) : ((iconElement = matchValue_2, singleton(createElement("div", {
                    className: join(" ", toList(delay(() => append(singleton("h-5"), delay(() => append(singleton("w-5"), delay(() => ((props.ComponentState === "disabled") ? singleton("text-text-muted") : empty())))))))),
                    children: reactApi.Children.toArray([iconElement]),
                }))))), delay(() => {
                    let elems_1, elems_2;
                    return props.DisplayArrow ? (value_4.open ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["ml-2.5", "h-3", "w-3"])], (elems_1 = [Arrow_Up_3()], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : singleton(createElement("div", createObj(ofArray([["className", join(" ", ["ml-2.5", "h-3", "w-3"])], (elems_2 = [Arrow_Down_3()], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))))) : empty();
                }));
            }));
        })), ["children", reactApi_1.Children.toArray(value_20)])]), reactApi_1.createElement(Menu.Button, createObj(props_5)))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), (props_11 = ofArray([["key", "transition-key"], ["enter", "transition ease-out duration-100"], ["enterFrom", "transform opacity-0 scale-95"], ["enterTo", "transform opacity-100 scale-100"], ["leave", "transition ease-in duration-75"], ["leaveFrom", "transform opacity-100 scale-100"], ["leaveTo", "transform opacity-0 scale-95"], (value_42 = singleton_1((props_10 = ofArray([["key", "dropdown-item-key"], ["className", fold((a_2, b_2) => (`${a_2} ${b_2}`), "", ofArray(["bg-white", "absolute", "right-0", "shadow-lg", "shadow-slate-400", "mt-1", "w-44", "py-2"]))], (value_41 = singleton_1(createElement("div", createObj(ofArray([["key", "dropdown-items"], ["className", join(" ", ["px-4", "py-2"])], (elems_4 = toList(delay(() => map((element) => reactApi_1.createElement(Menu.Item, {
            key: element.DisplayName,
            children: (_arg) => createElement("button", {
                className: join(" ", ["text-sm", "font-medium", "flex", "items-center", "p-1"]),
                children: element.DisplayName,
                onClick: (_arg_1) => {
                    element.OnClick();
                },
            }),
        }), props.Elements))), ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), ["children", reactApi_1.Children.toArray(value_41)])]), reactApi_1.createElement(Menu.Items, createObj(props_10)))), ["children", reactApi_1.Children.toArray(value_42)])]), reactApi_1.createElement(Transition, createObj(props_11)))]);
    }]]);
    return reactApi_1.createElement(Menu, createObj(props_12));
}

export function IconButton(props) {
    let elems_1;
    return createElement("div", createObj(ofArray([["data-test-id", props.TestId], ["className", join(" ", toList(delay(() => append(singleton("flex"), delay(() => append(singleton("content-center"), delay(() => ((props.ComponentState === "disabled") ? singleton("cursor-default") : singleton("cursor-pointer")))))))))], ["onClick", (props.ComponentState === "disabled") ? ((value_3) => {
    }) : ((_arg) => {
        props.OnClick();
    })], (elems_1 = [createElement("div", {
        className: join(" ", toList(delay(() => append((props.ComponentState === "disabled") ? singleton("text-text-muted") : singleton("text-black"), delay(() => append(singleton("h-5"), delay(() => append(singleton("w-5"), delay(() => singleton("mr-2.5")))))))))),
        children: reactApi.Children.toArray([props.Icon]),
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

