import { FormState__setValidationResponse_5219762A, FormState_get_empty, RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State, DialogOpenState } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getStocktaking } from "../../Requests/Stocktaking.js";
import { ofArray, tryFind, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { map, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../../CommunicationV2.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { StocktakingListId__get_unwrap, StocktakingId__get_unwrap } from "../../Shared/Shared.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Cmd_errorToast } from "../../Components/Toast.js";

export function init(stocktakingId, stocktakingListId) {
    return [new State(stocktakingId, stocktakingListId, new RequestedValue$1(0, []), new RequestedValue$1(0, []), new DialogOpenState(false, false), FormState_get_empty()), Cmd_batch(singleton(getStocktaking(stocktakingId, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(7, [Item_1])))))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const stocktaking = msg.fields[0];
            return [new State(state.StocktakingId, state.StocktakingListId, new RequestedValue$1(1, [stocktaking]), defaultArg(map((Item_2) => (new RequestedValue$1(1, [Item_2])), tryFind((stocktakingList) => equals(stocktakingList.Id, state.StocktakingListId), stocktaking.StocktakingLists)), new RequestedValue$1(0, [])), state.DialogOpenState, state.FormState), Cmd_none()];
        }
        case 2:
            return [new State(state.StocktakingId, state.StocktakingListId, state.Stocktaking, state.StocktakingList, state.DialogOpenState, FormState_get_empty()), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_2;
                return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                    const pr = response.arrayBuffer();
                    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                }, refreshOn)))(true);
            })), [`/api/stocktakings/${StocktakingId__get_unwrap(state.StocktakingId)}/lists/${StocktakingListId__get_unwrap(state.StocktakingListId)}/state/start`, defaultOf()], (Item_3) => (new Msg(6, [Item_3])), (Item_4) => (new Msg(7, [Item_4])))];
        case 3:
            return [new State(state.StocktakingId, state.StocktakingListId, state.Stocktaking, state.StocktakingList, state.DialogOpenState, FormState_get_empty()), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_5;
                return ((url_5 = addPrefix(tupledArg_1[0]), (refreshOn_1) => fetchWithDecoder(url_5, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                    const pr_1 = response_1.arrayBuffer();
                    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                }, refreshOn_1)))(true);
            })), [`/api/stocktakings/${StocktakingId__get_unwrap(state.StocktakingId)}/lists/${StocktakingListId__get_unwrap(state.StocktakingListId)}/state/done`, defaultOf()], (Item_5) => (new Msg(6, [Item_5])), (Item_6) => (new Msg(7, [Item_6])))];
        case 4:
            return [new State(state.StocktakingId, state.StocktakingListId, state.Stocktaking, state.StocktakingList, new DialogOpenState(msg.fields[0], false), FormState_get_empty()), Cmd_none()];
        case 5:
            return [new State(state.StocktakingId, state.StocktakingListId, state.Stocktaking, state.StocktakingList, new DialogOpenState(false, msg.fields[0]), FormState_get_empty()), Cmd_none()];
        case 6: {
            const response_2 = msg.fields[0];
            if (response_2.tag === 1) {
                return [new State(state.StocktakingId, state.StocktakingListId, state.Stocktaking, state.StocktakingList, state.DialogOpenState, FormState__setValidationResponse_5219762A(state.FormState, response_2.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.StocktakingId, state.StocktakingListId, state.Stocktaking, state.StocktakingList, new DialogOpenState(false, false), FormState_get_empty()), singleton((dispatch) => {
                    dispatch(new Msg(0, []));
                })];
            }
        }
        case 7:
            return [state, Cmd_none()];
        default:
            return [state, getStocktaking(state.StocktakingId, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(7, [Item_1])))];
    }
}

