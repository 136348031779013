import { Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { ChangelogId_$reflection } from "./Shared.js";
import { record_type, class_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class Changelog extends Record {
    constructor(Id, EntityId, EntityType, Event$, NewValue, OldValue, Timestamp, UpdatedByUserId) {
        super();
        this.Id = Id;
        this.EntityId = EntityId;
        this.EntityType = EntityType;
        this.Event = Event$;
        this.NewValue = NewValue;
        this.OldValue = OldValue;
        this.Timestamp = Timestamp;
        this.UpdatedByUserId = UpdatedByUserId;
    }
}

export function Changelog_$reflection() {
    return record_type("Shared.Changelog.Changelog", [], Changelog, () => [["Id", ChangelogId_$reflection()], ["EntityId", string_type], ["EntityType", string_type], ["Event", string_type], ["NewValue", string_type], ["OldValue", string_type], ["Timestamp", class_type("System.DateTime")], ["UpdatedByUserId", string_type]]);
}

export function Helper_entityTypeToString(_arg) {
    switch (_arg) {
        case "toolWithQuantity":
            return "tool_with_quantity";
        case "consumable":
            return "consumable";
        case "set":
            return "set";
        case "receiver":
            return "receiver";
        case "storage":
            return "storage";
        case "location":
            return "location";
        case "category":
            return "category";
        default:
            return "tool";
    }
}

export function Helper_entityTypeFromString(_arg) {
    switch (_arg) {
        case "tool":
            return "tool";
        case "tool_with_quantity":
            return "toolWithQuantity";
        case "consumable":
            return "consumable";
        case "set":
            return "set";
        case "receiver":
            return "receiver";
        case "storage":
            return "storage";
        case "location":
            return "location";
        case "category":
            return "category";
        default:
            return "tool";
    }
}

export function Helper_changelogMessage(changelog) {
    if (changelog.OldValue === "") {
        return changelog.NewValue;
    }
    else {
        return `${changelog.OldValue} -> ${changelog.NewValue}`;
    }
}

export function Helper_eventToTranslationKey(_arg) {
    switch (_arg) {
        case "event_created":
            return "event.created";
        case "inventory_number":
            return "general.inventory_number";
        case "storage_space":
            return "tool.storage_space";
        case "qr_code_ids":
            return "general.qr_codes";
        case "unit":
            return "general.unit";
        case "display_name":
            return "general.name";
        case "description":
            return "general.description";
        case "additional_name":
            return "consumable.additional_name";
        case "image_url":
            return "general.image";
        case "serial_number":
            return "general.serial_number";
        case "manufacturer":
            return "tool.manufacturer";
        case "comment":
            return "general.comment";
        case "prinzing_check_number":
            return "tool.prinzing.check_number";
        case "model":
            return "tool.model";
        case "last_inspection_date":
            return "tool.last_check";
        case "commercial_data_comment":
            return "general.comment";
        case "warranty_date":
            return "general.gurantee_date";
        case "purchase_price":
            return "general.purchase_price";
        case "supplier_article_number":
            return "general.supplier_article_number";
        case "supplier":
            return "general.supplier";
        case "purchase_date":
            return "general.purchase_date";
        default:
            return _arg;
    }
}

export function Helper_displayHistoryComment(eventName, comment) {
    if (comment === "") {
        return eventName;
    }
    else {
        return `${eventName}: ${comment}`;
    }
}

