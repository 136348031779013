import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, SetId, FormState__startLoading, FormState_get_empty, FormState_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { DataTransfer_UpdateStateDto, DataTransfer_UpdateStateDto_$reflection, DataTransfer_DetailSet_$reflection } from "../../Shared/Set.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { putStateCmd } from "../../Requests/Set.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextArea } from "../../Components/TextArea.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateState", "SetComment", "StateUpdated", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Sets.Dialog.StateChangeDialog.Msg", [], Msg, () => [[], [["Item", string_type]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Set$, Dto, OnClose, SuccessCallback, FormState) {
        super();
        this.Set = Set$;
        this.Dto = Dto;
        this.OnClose = OnClose;
        this.SuccessCallback = SuccessCallback;
        this.FormState = FormState;
    }
}

function State_$reflection() {
    return record_type("Sets.Dialog.StateChangeDialog.State", [], State, () => [["Set", DataTransfer_DetailSet_$reflection()], ["Dto", DataTransfer_UpdateStateDto_$reflection()], ["OnClose", lambda_type(unit_type, unit_type)], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["FormState", FormState_$reflection()]]);
}

function labelByState(t, newState) {
    switch (newState) {
        case "inMaintenance":
            return t("tool.in_maintenance_tool");
        case "retired":
            return t("tool.retire_tool");
        case "lost":
            return t("tool.lost_tool");
        default:
            return t("tool.activate_tool");
    }
}

function init(set$, setState, successCallback, onClose) {
    return [new State(set$, new DataTransfer_UpdateStateDto(setState, ""), onClose, successCallback, FormState_get_empty()), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 0:
            return [new State(state.Set, state.Dto, state.OnClose, state.SuccessCallback, FormState__startLoading(state.FormState)), putStateCmd(new SetId(parse(state.Set.Id)), state.Dto, (Item_1) => (new Msg(2, [Item_1])), (Item_2) => (new Msg(3, [Item_2])))];
        case 2: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.Set, state.Dto, state.OnClose, state.SuccessCallback, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.Set, state.Dto, state.OnClose, state.SuccessCallback, FormState_get_empty()), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 3:
            return [state, Cmd_none()];
        default:
            return [new State(state.Set, new DataTransfer_UpdateStateDto(state.Dto.NewState, msg.fields[0]), state.OnClose, state.SuccessCallback, state.FormState), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-5"])], (elems = toList(delay(() => append(singleton(createElement(TextArea, {
        ComponentState: props.FormState.IsLoading ? "disabled" : "enabled",
        Label: t("general.comment"),
        OnChange: (arg) => {
            props.Dispatch(new Msg(1, [arg]));
        },
        TestId: "tool-state-change-dialog-comment-test-id",
        Value: props.Comment,
    })), delay(() => {
        const matchValue = FormState__getValidation(props.FormState, t, "global");
        if (matchValue == null) {
            return empty();
        }
        else {
            return singleton(createElement(ErrorAlert, {
                Label: matchValue,
            }));
        }
    })))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function OpenStateChangeDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.Set, props.SetState, props.SuccessCallback, props.OnClose);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.OnClose]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = labelByState(t, state_1.Dto.NewState), {
        Body: singleton_1(createElement(DialogBody, {
            Comment: state_1.Dto.Comment,
            Dispatch: dispatch,
            FormState: state_1.FormState,
            Set: props.Set,
            SetState: state_1.Dto.NewState,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-state-change-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(0, []));
            },
            TestId: "save-state-change-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

