import { compare, trimStart } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { zip, tryPick, filter, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { split } from "../fable_modules/fable-library-js.4.19.2/RegExp.js";
import { isDigit } from "../fable_modules/fable-library-js.4.19.2/Char.js";
import { Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, array_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { compareWith } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { compareTo } from "../fable_modules/fable-library-js.4.19.2/Date.js";

const Impl_regex = /([0-9]+)/gu;

function Impl_trimLeadingZeros(s) {
    return trimStart(s, "0");
}

function Impl_toChars(s) {
    return s.split("");
}

function Impl_split(text) {
    return toList(filter((s) => (s.length > 0), split(Impl_regex, text)));
}

function Impl_compareStrings(s1, s2) {
    const isNumeric1 = isDigit(s1[0]);
    const isNumeric2 = isDigit(s2[0]);
    if (isNumeric1) {
        if (isNumeric2) {
            const matchValue_1 = Impl_trimLeadingZeros(s1);
            const n2 = Impl_trimLeadingZeros(s2);
            const n1 = matchValue_1;
            if (n1.length < n2.length) {
                return -1;
            }
            else if (n2.length < n1.length) {
                return 1;
            }
            else {
                const result = tryPick((tupledArg) => {
                    const c1 = tupledArg[0];
                    const c2 = tupledArg[1];
                    if (c1 < c2) {
                        return -1;
                    }
                    else if (c2 < c1) {
                        return 1;
                    }
                    else {
                        return undefined;
                    }
                }, zip(Impl_toChars(n1), Impl_toChars(n2)));
                if (result == null) {
                    return 0;
                }
                else {
                    return result | 0;
                }
            }
        }
        else {
            return -1;
        }
    }
    else if (isNumeric2) {
        return 1;
    }
    else {
        return compare(s1, s2, true) | 0;
    }
}

class Impl_Pair extends Record {
    constructor(Name, Pieces) {
        super();
        this.Name = Name;
        this.Pieces = Pieces;
    }
}

function Impl_Pair_$reflection() {
    return record_type("NaturalOrder.Impl.Pair", [], Impl_Pair, () => [["Name", string_type], ["Pieces", array_type(string_type)]]);
}

export function sortAsc(a, b) {
    const b_1 = Impl_split(b);
    return compareWith(Impl_compareStrings, Impl_split(a), b_1) | 0;
}

export function sortDesc(a, b) {
    return compareWith(Impl_compareStrings, Impl_split(b), Impl_split(a));
}

export function customSort(a, b, order, _arg, _arg_1, _arg_2) {
    if (order === "asc") {
        return sortAsc(a, b) | 0;
    }
    else {
        return sortDesc(a, b) | 0;
    }
}

export function customSortWithExtractor(extractor, stringA, stringB, order, _arg, a, b) {
    const a_1 = extractor(a);
    const b_1 = extractor(b);
    if (order === "asc") {
        return sortAsc(a_1, b_1) | 0;
    }
    else {
        return sortDesc(a_1, b_1) | 0;
    }
}

export function dateSort(extractor, stringA, stringB, order, _arg, a, b) {
    const a_1 = extractor(a);
    const b_1 = extractor(b);
    if (order === "asc") {
        return compareTo(a_1, b_1) | 0;
    }
    else {
        return compareTo(b_1, a_1) | 0;
    }
}

