import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { createObj, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { BoxHeader, TextValue } from "../../Components/Text.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { ReceiverVisibilityConfiguration, CustomerConfiguration, ReceiverCreateReservationAllowedConfiguration as ReceiverCreateReservationAllowedConfiguration_10 } from "../../Shared/Shared.js";
import { AppVariant, Variant } from "../../Variant.js";
import { Feature, isFeatureEnabled } from "../../FeatureFlip.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Msg } from "./Types.js";
import { InformationAlert } from "../../Components/Alert.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { Skeleton } from "../../Components/Skeleton.js";

function ReceiverCreateReservationConfiguration(props) {
    let elems_1, elems;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(props.CustomerConfiguration);
    const customerConfiguration = patternInput_1[0];
    const dependencies = [props.CustomerConfiguration];
    reactApi.useEffect(() => {
        if (!equals(props.CustomerConfiguration, customerConfiguration)) {
            patternInput_1[1](props.CustomerConfiguration);
        }
    }, dependencies);
    const componentState = customerConfiguration.IsSelfServiceReservationForUserActive ? "enabled" : "disabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_1 = [createElement(TextValue, {
        FontWeight: "bold",
        TestId: "",
        Text: t("settings.permissions_receiver_create_reservation_permission"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-1"])], (elems = toList(delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Users ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Users,
        Label: t("receivers.users"),
        OnCheck: (value_2) => {
            let bind$0040;
            props.SetCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, (bind$0040 = customerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration_10(bind$0040.ConstructionSites, bind$0040.Employees, bind$0040.ExternalPersons, bind$0040.Projects, bind$0040.Rooms, bind$0040.SchoolClasses, bind$0040.Students, bind$0040.Subcontractos, value_2, bind$0040.Vehicles)), customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "create-reservation-checkbox-users",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Employees ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Employees,
        Label: t("receivers.employees"),
        OnCheck: (value_3) => {
            let bind$0040_1;
            props.SetCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, (bind$0040_1 = customerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration_10(bind$0040_1.ConstructionSites, value_3, bind$0040_1.ExternalPersons, bind$0040_1.Projects, bind$0040_1.Rooms, bind$0040_1.SchoolClasses, bind$0040_1.Students, bind$0040_1.Subcontractos, bind$0040_1.Users, bind$0040_1.Vehicles)), customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "create-reservation-checkbox-employees",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.ConstructionSites ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: customerConfiguration.ReceiverCreateReservationAllowedConfiguration.ConstructionSites,
        Label: t("receivers.constructionsites"),
        OnCheck: (value_4) => {
            let bind$0040_2;
            props.SetCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, (bind$0040_2 = customerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration_10(value_4, bind$0040_2.Employees, bind$0040_2.ExternalPersons, bind$0040_2.Projects, bind$0040_2.Rooms, bind$0040_2.SchoolClasses, bind$0040_2.Students, bind$0040_2.Subcontractos, bind$0040_2.Users, bind$0040_2.Vehicles)), customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "create-reservation-checkbox-constructionsites",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Projects ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Projects,
        Label: t("receivers.projects"),
        OnCheck: (value_5) => {
            let bind$0040_3;
            props.SetCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, (bind$0040_3 = customerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration_10(bind$0040_3.ConstructionSites, bind$0040_3.Employees, bind$0040_3.ExternalPersons, value_5, bind$0040_3.Rooms, bind$0040_3.SchoolClasses, bind$0040_3.Students, bind$0040_3.Subcontractos, bind$0040_3.Users, bind$0040_3.Vehicles)), customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "create-reservation-checkbox-projects",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Rooms ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Rooms,
        Label: t("receivers.rooms"),
        OnCheck: (value_6) => {
            let bind$0040_4;
            props.SetCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, (bind$0040_4 = customerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration_10(bind$0040_4.ConstructionSites, bind$0040_4.Employees, bind$0040_4.ExternalPersons, bind$0040_4.Projects, value_6, bind$0040_4.SchoolClasses, bind$0040_4.Students, bind$0040_4.Subcontractos, bind$0040_4.Users, bind$0040_4.Vehicles)), customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "create-reservation-checkbox-rooms",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.ExternalPersons ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: customerConfiguration.ReceiverCreateReservationAllowedConfiguration.ExternalPersons,
        Label: t("receivers.externalpersons"),
        OnCheck: (value_7) => {
            let bind$0040_5;
            props.SetCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, (bind$0040_5 = customerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration_10(bind$0040_5.ConstructionSites, bind$0040_5.Employees, value_7, bind$0040_5.Projects, bind$0040_5.Rooms, bind$0040_5.SchoolClasses, bind$0040_5.Students, bind$0040_5.Subcontractos, bind$0040_5.Users, bind$0040_5.Vehicles)), customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "create-reservation-checkbox-externalpersons",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Subcontractos ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Subcontractos,
        Label: t("receivers.subcontractors"),
        OnCheck: (value_8) => {
            let bind$0040_6;
            props.SetCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, (bind$0040_6 = customerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration_10(bind$0040_6.ConstructionSites, bind$0040_6.Employees, bind$0040_6.ExternalPersons, bind$0040_6.Projects, bind$0040_6.Rooms, bind$0040_6.SchoolClasses, bind$0040_6.Students, value_8, bind$0040_6.Users, bind$0040_6.Vehicles)), customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "create-reservation-checkbox-subcontractors",
    })) : empty(), delay(() => (equals(Variant, new AppVariant(1, [])) ? append(customerConfiguration.ReceiverVisibilityConfiguration.SchoolClasses ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: customerConfiguration.ReceiverCreateReservationAllowedConfiguration.SchoolClasses,
        Label: t("receivers.school_classes"),
        OnCheck: (value_9) => {
            let bind$0040_7;
            props.SetCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, (bind$0040_7 = customerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration_10(bind$0040_7.ConstructionSites, bind$0040_7.Employees, bind$0040_7.ExternalPersons, bind$0040_7.Projects, bind$0040_7.Rooms, value_9, bind$0040_7.Students, bind$0040_7.Subcontractos, bind$0040_7.Users, bind$0040_7.Vehicles)), customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "create-reservation-checkbox-school_classes",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Students ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Students,
        Label: t("receivers.students"),
        OnCheck: (value_10) => {
            let bind$0040_8;
            props.SetCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, (bind$0040_8 = customerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration_10(bind$0040_8.ConstructionSites, bind$0040_8.Employees, bind$0040_8.ExternalPersons, bind$0040_8.Projects, bind$0040_8.Rooms, bind$0040_8.SchoolClasses, value_10, bind$0040_8.Subcontractos, bind$0040_8.Users, bind$0040_8.Vehicles)), customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "create-reservation-checkbox-students",
    })) : empty(), delay(() => (isFeatureEnabled(new Feature(11, [])) ? (customerConfiguration.ReceiverVisibilityConfiguration.Vehicles ? singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Vehicles,
        Label: t("receivers.vehicles"),
        OnCheck: (value_11) => {
            let bind$0040_9;
            props.SetCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, (bind$0040_9 = customerConfiguration.ReceiverCreateReservationAllowedConfiguration, new ReceiverCreateReservationAllowedConfiguration_10(bind$0040_9.ConstructionSites, bind$0040_9.Employees, bind$0040_9.ExternalPersons, bind$0040_9.Projects, bind$0040_9.Rooms, bind$0040_9.SchoolClasses, bind$0040_9.Students, bind$0040_9.Subcontractos, bind$0040_9.Users, value_11)), customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "create-reservation-checkbox-vehicles",
    })) : empty()) : empty()))))) : empty()))))))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function Permissions(props) {
    let elems_10, elems_1, elems, elems_4, elems_2, elems_3, elems_9, elems_5, elems_6, elems_7, elems_8;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(props.CustomerConfiguration);
    const setCustomerConfiguration = patternInput_1[1];
    const customerConfiguration = patternInput_1[0];
    const dependencies = [customerConfiguration];
    reactApi.useEffect(() => {
        if (!equals(props.CustomerConfiguration, customerConfiguration)) {
            props.Dispatch(new Msg(0, [customerConfiguration]));
        }
    }, dependencies);
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_10 = [createElement(BoxHeader, {
        Text: "System",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-5"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems = [Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.NotificationEmailsOnlyToAdministrators,
        Label: t("settings.permissions_notification_emails_only_to_administrators"),
        OnCheck: (value_3) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, value_3, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    }), createElement(InformationAlert, {
        Label: t("settings.permissions_notification_emails_only_to_administrators_info"),
    }), Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.CanUserWriteNotices,
        Label: t("settings.permissions_can_write_notices"),
        OnCheck: (value_4) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, value_4, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    }), Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.CanUserSeeAllTools,
        Label: t("settings.permissions_user_can_see_all_tools"),
        OnCheck: (value_5) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, value_5, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement(BoxHeader, {
        Text: t("general.storage"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-5"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_2 = [Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.GlobalSelfServiceStorageActive,
        Label: t("settings.global_self_service_storage"),
        OnCheck: (value_10) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, value_10, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    }), createElement(InformationAlert, {
        Label: t("settings.global_self_service_storage_info"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_3 = [Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification,
        Label: t("settings.permissions_self_service_user_return_tool"),
        OnCheck: (value_13) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, value_13, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    }), createElement(InformationAlert, {
        Label: t("settings.permissions_self_service_user_return_tool_info"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_9 = [createElement(BoxHeader, {
        Text: "App",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_5 = [Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.IsGlobalAppSearchActiveForUserRole,
        Label: t("settings.permissions_activate_global_search_for_user"),
        OnCheck: (value_18) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, value_18, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    }), createElement(InformationAlert, {
        Label: t("settings.permissions_activate_global_search_for_user_info"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_6 = [Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.IsSelfServiceReservationForUserActive,
        Label: t("settings.permissions_self_service_user_reservation"),
        OnCheck: (value_21) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, value_21, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    }), createElement(InformationAlert, {
        Label: t("settings.permissions_self_service_user_reservation_info"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))), createElement(ReceiverCreateReservationConfiguration, {
        CustomerConfiguration: customerConfiguration,
        SetCustomerConfiguration: setCustomerConfiguration,
    }), createElement(TextValue, {
        FontWeight: "bold",
        TestId: "",
        Text: t("settings.permissions_app_receiver_visibility"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-1"])], (elems_7 = toList(delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Users ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Users,
        Label: t("receivers.users"),
        OnCheck: (value_24) => {
            let bind$0040;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040.ConstructionSites, bind$0040.Employees, bind$0040.ExternalPersons, bind$0040.Projects, bind$0040.Rooms, bind$0040.SchoolClasses, bind$0040.Students, bind$0040.Subcontractos, value_24, bind$0040.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Employees ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Employees,
        Label: t("receivers.employees"),
        OnCheck: (value_25) => {
            let bind$0040_1;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_1 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_1.ConstructionSites, value_25, bind$0040_1.ExternalPersons, bind$0040_1.Projects, bind$0040_1.Rooms, bind$0040_1.SchoolClasses, bind$0040_1.Students, bind$0040_1.Subcontractos, bind$0040_1.Users, bind$0040_1.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.ConstructionSites ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.ConstructionSites,
        Label: t("receivers.constructionsites"),
        OnCheck: (value_26) => {
            let bind$0040_2;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_2 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(value_26, bind$0040_2.Employees, bind$0040_2.ExternalPersons, bind$0040_2.Projects, bind$0040_2.Rooms, bind$0040_2.SchoolClasses, bind$0040_2.Students, bind$0040_2.Subcontractos, bind$0040_2.Users, bind$0040_2.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Projects ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Projects,
        Label: t("receivers.projects"),
        OnCheck: (value_27) => {
            let bind$0040_3;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_3 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_3.ConstructionSites, bind$0040_3.Employees, bind$0040_3.ExternalPersons, value_27, bind$0040_3.Rooms, bind$0040_3.SchoolClasses, bind$0040_3.Students, bind$0040_3.Subcontractos, bind$0040_3.Users, bind$0040_3.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Rooms ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Rooms,
        Label: t("receivers.rooms"),
        OnCheck: (value_28) => {
            let bind$0040_4;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_4 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_4.ConstructionSites, bind$0040_4.Employees, bind$0040_4.ExternalPersons, bind$0040_4.Projects, value_28, bind$0040_4.SchoolClasses, bind$0040_4.Students, bind$0040_4.Subcontractos, bind$0040_4.Users, bind$0040_4.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.ExternalPersons ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.ExternalPersons,
        Label: t("receivers.externalpersons"),
        OnCheck: (value_29) => {
            let bind$0040_5;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_5 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_5.ConstructionSites, bind$0040_5.Employees, value_29, bind$0040_5.Projects, bind$0040_5.Rooms, bind$0040_5.SchoolClasses, bind$0040_5.Students, bind$0040_5.Subcontractos, bind$0040_5.Users, bind$0040_5.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Subcontractos ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Subcontractos,
        Label: t("receivers.subcontractors"),
        OnCheck: (value_30) => {
            let bind$0040_6;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_6 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_6.ConstructionSites, bind$0040_6.Employees, bind$0040_6.ExternalPersons, bind$0040_6.Projects, bind$0040_6.Rooms, bind$0040_6.SchoolClasses, bind$0040_6.Students, value_30, bind$0040_6.Users, bind$0040_6.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    })) : empty(), delay(() => (equals(Variant, new AppVariant(1, [])) ? append(customerConfiguration.ReceiverVisibilityConfiguration.SchoolClasses ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.SchoolClasses,
        Label: t("receivers.school_classes"),
        OnCheck: (value_31) => {
            let bind$0040_7;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_7 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_7.ConstructionSites, bind$0040_7.Employees, bind$0040_7.ExternalPersons, bind$0040_7.Projects, bind$0040_7.Rooms, value_31, bind$0040_7.Students, bind$0040_7.Subcontractos, bind$0040_7.Users, bind$0040_7.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    })) : empty(), delay(() => append(customerConfiguration.ReceiverVisibilityConfiguration.Students ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Students,
        Label: t("receivers.students"),
        OnCheck: (value_32) => {
            let bind$0040_8;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_8 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_8.ConstructionSites, bind$0040_8.Employees, bind$0040_8.ExternalPersons, bind$0040_8.Projects, bind$0040_8.Rooms, bind$0040_8.SchoolClasses, value_32, bind$0040_8.Subcontractos, bind$0040_8.Users, bind$0040_8.Vehicles)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    })) : empty(), delay(() => (isFeatureEnabled(new Feature(11, [])) ? (customerConfiguration.ReceiverVisibilityConfiguration.Vehicles ? singleton(Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.AppVisibilityPermissionReceiver.Vehicles,
        Label: t("receivers.vehicles"),
        OnCheck: (value_33) => {
            let bind$0040_9;
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, (bind$0040_9 = customerConfiguration.AppVisibilityPermissionReceiver, new ReceiverVisibilityConfiguration(bind$0040_9.ConstructionSites, bind$0040_9.Employees, bind$0040_9.ExternalPersons, bind$0040_9.Projects, bind$0040_9.Rooms, bind$0040_9.SchoolClasses, bind$0040_9.Students, bind$0040_9.Subcontractos, bind$0040_9.Users, value_33)), customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection));
        },
        TestId: "",
    })) : empty()) : empty()))))) : empty()))))))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems_7))])]))), createElement(InformationAlert, {
        Label: t("settings.permissions_app_receiver_visibility_info"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_8 = [Checkbox({
        ComponentState: "enabled",
        IsChecked: customerConfiguration.AllowUserToUserAssignmentViaUserSelection,
        Label: t("settings.permissions_user_to_user_assignment_allowed_via_user_selection"),
        OnCheck: (value_36) => {
            setCustomerConfiguration(new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, customerConfiguration.ReceiverVisibilityConfiguration, customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, value_36));
        },
        TestId: "",
    }), createElement(InformationAlert, {
        Label: t("settings.permissions_user_to_user_assignment_allowed_via_user_selection_info"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", reactApi.Children.toArray(Array.from(elems_10))])])));
}

export function PermissionsView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init_1, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const matchValue = patternInput[0].CustomerConfiguration;
    if (matchValue.tag === 0) {
        return createElement(Skeleton, {
            Variant: "normal",
        });
    }
    else {
        return createElement(Permissions, {
            CustomerConfiguration: matchValue.fields[0],
            Dispatch: patternInput[1],
        });
    }
}

