import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { class_type, union_type, bool_type, option_type, tuple_type, list_type, record_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { EmptyResponse_$reflection, ClientApiResponse$1_$reflection, UserId_$reflection, RequestedValue$1_$reflection, CustomerConfiguration_$reflection, Customer_$reflection, TokenDataDto_$reflection } from "../Shared/Shared.js";
import { ReceiverHistoryRow_$reflection, ToolBoxTool_$reflection, ToolBox_$reflection } from "../Shared/Receiver.js";
import { ConsumableHistoryRow_$reflection } from "../Shared/Consumable.js";
import { Location_$reflection } from "../Shared/Location.js";
import { PropertyConfiguration_$reflection } from "../Shared/PropertyConfiguration.js";
import { TableConfiguration_$reflection, Requests_UpdateReceiverArchivedResponse_$reflection, Requests_UpdateReceiverImageResponse_$reflection, Requests_DeleteReceiverImageResponse_$reflection, UserConfiguration_$reflection } from "../Shared/User.js";
import { ReservationDto_$reflection } from "../Shared/Planning.js";
import { FileUploadResponse_$reflection } from "../Shared/File.js";

export class ToolBoxRow extends Record {
    constructor(UserId, DisplayName, ToolQuantity, ToolWithQuantityQuantity) {
        super();
        this.UserId = UserId;
        this.DisplayName = DisplayName;
        this.ToolQuantity = ToolQuantity;
        this.ToolWithQuantityQuantity = ToolWithQuantityQuantity;
    }
}

export function ToolBoxRow_$reflection() {
    return record_type("ToolBox.Types.ToolBoxRow", [], ToolBoxRow, () => [["UserId", string_type], ["DisplayName", string_type], ["ToolQuantity", string_type], ["ToolWithQuantityQuantity", string_type]]);
}

export class State extends Record {
    constructor(UserData, ToolBox, HistoryRows, Customer, ConsumableHistoryRows, Message, CustomerConfiguration, SelectedTools, Locations, TriggerRerender, PropertyConfigurations, UserConfiguration, Reservations) {
        super();
        this.UserData = UserData;
        this.ToolBox = ToolBox;
        this.HistoryRows = HistoryRows;
        this.Customer = Customer;
        this.ConsumableHistoryRows = ConsumableHistoryRows;
        this.Message = Message;
        this.CustomerConfiguration = CustomerConfiguration;
        this.SelectedTools = SelectedTools;
        this.Locations = Locations;
        this.TriggerRerender = TriggerRerender;
        this.PropertyConfigurations = PropertyConfigurations;
        this.UserConfiguration = UserConfiguration;
        this.Reservations = Reservations;
    }
}

export function State_$reflection() {
    return record_type("ToolBox.Types.State", [], State, () => [["UserData", TokenDataDto_$reflection()], ["ToolBox", option_type(tuple_type(ToolBox_$reflection(), list_type(ToolBoxTool_$reflection())))], ["HistoryRows", list_type(ReceiverHistoryRow_$reflection())], ["Customer", Customer_$reflection()], ["ConsumableHistoryRows", list_type(ConsumableHistoryRow_$reflection())], ["Message", option_type(string_type)], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["SelectedTools", list_type(ToolBoxTool_$reflection())], ["Locations", RequestedValue$1_$reflection(list_type(Location_$reflection()))], ["TriggerRerender", bool_type], ["PropertyConfigurations", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["UserConfiguration", RequestedValue$1_$reflection(UserConfiguration_$reflection())], ["Reservations", RequestedValue$1_$reflection(list_type(ReservationDto_$reflection()))]]);
}

export class ReceiverDeleteResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Deleted", "SelfDeleteError", "HasToolsInBox"];
    }
}

export function ReceiverDeleteResponse_$reflection() {
    return union_type("ToolBox.Types.ReceiverDeleteResponse", [], ReceiverDeleteResponse, () => [[], [], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DeleteImage", "ImageDeleted", "ReceiverUpdated", "UploadFile", "FileUploaded", "ToolBoxFetched", "SelectTools", "CustomerConfigurationFetched", "LocationsFetched", "PropertyConfigurationsFetched", "ReservationsFetched", "NavigateToToolbox", "ArchiveReceiver", "ReceiverArchived", "FetchToolBox", "FetchReservations", "FetchError", "DeleteReceiver", "ReceiverDeleted", "UserDeleted", "UserConfigurationFetched", "UserConfigurationUpdated", "UpdateTableConfiguration"];
    }
}

export function Msg_$reflection() {
    return union_type("ToolBox.Types.Msg", [], Msg, () => [[], [["Item", Requests_DeleteReceiverImageResponse_$reflection()]], [["Item", Requests_UpdateReceiverImageResponse_$reflection()]], [["Item", class_type("Browser.Types.File", undefined)]], [["Item", FileUploadResponse_$reflection()]], [["Item", ToolBox_$reflection()]], [["Item1", list_type(ToolBoxTool_$reflection())], ["isSelect", bool_type], ["all", bool_type]], [["Item", CustomerConfiguration_$reflection()]], [["Item", list_type(Location_$reflection())]], [["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", list_type(ReservationDto_$reflection())]], [["userId", string_type]], [["Item", bool_type]], [["Item", Requests_UpdateReceiverArchivedResponse_$reflection()]], [], [], [["Item", class_type("System.Exception")]], [["Item", UserId_$reflection()]], [["Item", ReceiverDeleteResponse_$reflection()]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", UserConfiguration_$reflection()]], [["Item", EmptyResponse_$reflection()]], [["Item", TableConfiguration_$reflection()]]]);
}

