import { RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getAccountStatistic, getCustomerPortalUrl, getAccountSettings } from "../../Requests/Customer.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";

export const init = [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, [])), Cmd_batch(ofArray([getAccountSettings((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(3, [Item_1]))), getCustomerPortalUrl((Item_2) => (new Msg(1, [Item_2])), (Item_3) => (new Msg(3, [Item_3]))), getAccountStatistic((Item_4) => (new Msg(2, [Item_4])), (Item_5) => (new Msg(3, [Item_5])))]))];

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.AccountSettings, new RequestedValue$1(1, [msg.fields[0]]), state.AccountStatistic), Cmd_none()];
        case 2:
            return [new State(state.AccountSettings, state.CustomerPortalUrl, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 3:
            return [state, Cmd_none()];
        default:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.CustomerPortalUrl, state.AccountStatistic), Cmd_none()];
    }
}

