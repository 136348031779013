import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, option_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, SetId, FormState__startLoading, UpdateReturnDateDto, FormState_get_empty, FormState_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { DataTransfer_DetailSet_$reflection } from "../../Shared/Set.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { unwrap, map } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { toISOString } from "../../Common.js";
import { updateReturnDateCmd } from "../../Requests/Set.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { utcNow, date } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SaveResponse", "OnSave", "SetDate", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Sets.Dialogs.ChangeReturnDateDialog.Msg", [], Msg, () => [[["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", option_type(class_type("System.DateTime"))]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(FormState, SuccessCallback, Date$, OnClose, Set$) {
        super();
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.Date = Date$;
        this.OnClose = OnClose;
        this.Set = Set$;
    }
}

function State_$reflection() {
    return record_type("Sets.Dialogs.ChangeReturnDateDialog.State", [], State, () => [["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["Date", option_type(class_type("System.DateTime"))], ["OnClose", lambda_type(unit_type, unit_type)], ["Set", DataTransfer_DetailSet_$reflection()]]);
}

function init(props) {
    return [new State(FormState_get_empty(), props.SuccessCallback, props.Set.LocationInformation.PlannedReturnDate, props.OnClose, props.Set), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const dto = new UpdateReturnDateDto(map(toISOString, state.Date));
            return [new State(FormState__startLoading(state.FormState), state.SuccessCallback, state.Date, state.OnClose, state.Set), updateReturnDateCmd(new SetId(parse(state.Set.Id)), dto, (Item_1) => (new Msg(0, [Item_1])), (Item_2) => (new Msg(3, [Item_2])))];
        }
        case 2:
            return [new State(state.FormState, state.SuccessCallback, msg.fields[0], state.OnClose, state.Set), Cmd_none()];
        case 3:
            return [state, Cmd_none()];
        default: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(FormState__setValidationResponse_5219762A(state.FormState, response.fields[0]), state.SuccessCallback, state.Date, state.OnClose, state.Set), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(FormState_get_empty(), state.SuccessCallback, state.Date, state.OnClose, state.Set), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
    }
}

function DialogBody(props) {
    let elems;
    const t = useTranslation()[0];
    const today = date(utcNow());
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["id", "change-return-date-tool-dialog"], ["className", join(" ", ["grid", "gap-5"])], (elems = toList(delay(() => append(singleton(createElement(DatePicker, {
        ComponentState: componentState,
        IsClearable: true,
        Label: t("tool.dialog_planned_return_date"),
        OnChange: props.SetDate,
        TestId: "change-return-date-test-id",
        Value: unwrap(props.Date),
    })), delay(() => {
        const matchValue = FormState__getValidation(props.FormState, t, "global");
        if (matchValue == null) {
            return empty();
        }
        else {
            return singleton(createElement(ErrorAlert, {
                Label: matchValue,
            }));
        }
    })))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function ChangeReturnDateDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        Set: props.Set,
        SuccessCallback: props.SuccessCallback,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("tool.planned_return_date"), {
        Body: singleton_1(createElement(DialogBody, {
            Date: unwrap(state_1.Date),
            FormState: state_1.FormState,
            SetDate: (value) => {
                dispatch(new Msg(2, [value]));
            },
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-change-return-date-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: state_1.FormState.IsLoading ? "disabled" : "enabled",
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(1, []));
            },
            TestId: "save-change-return-date-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

