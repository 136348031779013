import { empty as empty_1, isEmpty, tryFind, find, singleton as singleton_1, exists, length, filter, map, ofArray, contains, choose } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { equals, createObj, stringHash } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { Helper_detailedElementId, DispolistAssignToolWithQuantity, DispolistAssignTool, DispolistAssignConsumable } from "../Shared/Dispolist.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { InformationAlert, ErrorAlert, WarningAlert } from "../Components/Alert.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { Button, TextButton } from "../Components/Button.js";
import Recycle_Bin_1 from "../../public/assets/icons/Recycle_Bin_1.svg";
import { Msg } from "./Types.js";
import { TabsMenu } from "../Widgets/TabsMenu.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { parse } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import { ConsumableId, ToolId } from "../Shared/Shared.js";
import { NumberInput } from "../Components/Input.js";
import { numberInputIntMaxValue } from "../Common.js";
import { unwrap, map as map_1 } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { unwrapStorageId } from "../Shared/Helper.js";
import { Select } from "../Components/Select.js";
import { TextArea } from "../Components/TextArea.js";
import { Checkbox } from "../Components/Checkbox.js";
import { update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ModalSheet } from "../Components/ModalSheet.js";
import { SectionHeader } from "../Components/Text.js";

export function toDispolistAssignElement(dispolist, removedDispolistElementIds) {
    const dispolistAssignConsumable = choose((e) => {
        switch (e.tag) {
            case 1:
            case 2:
                return undefined;
            default: {
                const ec = e.fields[0];
                if (!contains(ec.Id, removedDispolistElementIds, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: stringHash,
                })) {
                    return new DispolistAssignConsumable(ec.ConsumableId, ec.Quantity, ec.StorageId);
                }
                else {
                    return undefined;
                }
            }
        }
    }, dispolist.Elements);
    return [choose((e_1) => {
        switch (e_1.tag) {
            case 0:
            case 2:
                return undefined;
            default: {
                const et = e_1.fields[0];
                if (!contains(et.Id, removedDispolistElementIds, {
                    Equals: (x_1, y_1) => (x_1 === y_1),
                    GetHashCode: stringHash,
                })) {
                    return new DispolistAssignTool(et.ToolId, et.StorageId);
                }
                else {
                    return undefined;
                }
            }
        }
    }, dispolist.Elements), choose((e_2) => {
        switch (e_2.tag) {
            case 0:
            case 1:
                return undefined;
            default: {
                const et_1 = e_2.fields[0];
                if (!contains(et_1.Id, removedDispolistElementIds, {
                    Equals: (x_2, y_2) => (x_2 === y_2),
                    GetHashCode: stringHash,
                })) {
                    return new DispolistAssignToolWithQuantity(et_1.ToolId, et_1.Quantity, et_1.StorageId);
                }
                else {
                    return undefined;
                }
            }
        }
    }, dispolist.Elements), dispolistAssignConsumable];
}

function NotValidElementTable(props) {
    let elems_6, elems_5, elems_1, elems, children;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_6 = [createElement(WarningAlert, {
        Label: t("dispolist_assign.invalid_elements_message"),
    }), createElement("table", createObj(ofArray([["className", join(" ", ["table", "w-full"])], (elems_5 = [createElement("thead", createObj(ofArray([["className", join(" ", ["border-[#E1E7F0]", "border-b-2"])], (elems_1 = [createElement("tr", createObj(ofArray([["className", join(" ", ["border-[#E1E7F0]", "border-b", "h-[4rem]"])], (elems = toList(delay(() => append(singleton(createElement("th", {
        className: join(" ", ["w-64"]),
        children: t("dispolist_assign.manufacturer"),
    })), delay(() => append(singleton(createElement("th", {
        className: join(" ", ["w-64"]),
        children: t("dispolist_assign.model"),
    })), delay(() => append(singleton(createElement("th", {
        className: join(" ", ["w-64"]),
        children: t("dispolist_assign.display_name"),
    })), delay(() => {
        if (props.Customer.tag === 1) {
            return singleton(createElement("th", {
                className: join(" ", ["w-64"]),
                children: t("dispolist_assign.check_number"),
            }));
        }
        else {
            return empty();
        }
    })))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), (children = map((ele) => {
        let elems_3, elems_4, elems_2;
        switch (ele.tag) {
            case 2: {
                const t_2 = ele.fields[0];
                return createElement("tr", createObj(ofArray([["className", join(" ", ["border-[#E1E7F0]", "border-b", "h-[4rem]", "bg-light-red"])], (elems_3 = toList(delay(() => append(singleton(createElement("td", {
                    className: join(" ", ["text-center"]),
                    children: t_2.Manufacturer,
                })), delay(() => append(singleton(createElement("td", {
                    className: join(" ", ["text-center"]),
                    children: t_2.Model,
                })), delay(() => append(singleton(createElement("td", {
                    className: join(" ", ["text-center"]),
                    children: t_2.DisplayName,
                })), delay(() => {
                    if (props.Customer.tag === 1) {
                        return singleton(createElement("td", {
                            className: join(" ", ["text-center"]),
                            children: t_2.CheckNumber,
                        }));
                    }
                    else {
                        return empty();
                    }
                })))))))), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
            }
            case 0: {
                const t_3 = ele.fields[0];
                return createElement("tr", createObj(ofArray([["className", join(" ", ["border-[#E1E7F0]", "border-b", "h-[4rem]", "bg-light-red"])], (elems_4 = toList(delay(() => append(singleton(createElement("td", {
                    className: join(" ", ["text-center"]),
                    children: "",
                })), delay(() => append(singleton(createElement("td", {
                    className: join(" ", ["text-center"]),
                    children: "",
                })), delay(() => append(singleton(createElement("td", {
                    className: join(" ", ["text-center"]),
                    children: `${t_3.Name} ${t_3.AdditionalName}`,
                })), delay(() => {
                    if (props.Customer.tag === 1) {
                        return singleton(createElement("td", {
                            className: join(" ", ["text-center"]),
                            children: t_3.CheckNumber,
                        }));
                    }
                    else {
                        return empty();
                    }
                })))))))), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
            }
            default: {
                const t_1 = ele.fields[0];
                return createElement("tr", createObj(ofArray([["className", join(" ", ["border-[#E1E7F0]", "border-b", "h-[4rem]", "bg-light-red"])], (elems_2 = toList(delay(() => append(singleton(createElement("td", {
                    className: join(" ", ["text-center"]),
                    children: t_1.Manufacturer,
                })), delay(() => append(singleton(createElement("td", {
                    className: join(" ", ["text-center"]),
                    children: t_1.Model,
                })), delay(() => append(singleton(createElement("td", {
                    className: join(" ", ["text-center"]),
                    children: t_1.DisplayName,
                })), delay(() => {
                    if (props.Customer.tag === 1) {
                        return singleton(createElement("td", {
                            className: join(" ", ["text-center"]),
                            children: t_1.CheckNumber,
                        }));
                    }
                    else {
                        return empty();
                    }
                })))))))), ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
            }
        }
    }, props.InvalidElements), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])));
}

export function DispolistDetail(props) {
    let elems_25;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const patternInput_1 = reactApi.useState(true);
    const setIsAddedToolsTabSelected = patternInput_1[1];
    const patternInput_2 = reactApi.useState(true);
    const setIsToolSelecteed = patternInput_2[1];
    const isToolsSelected = patternInput_2[0];
    const removedTools = filter((e) => contains(Helper_detailedElementId(e), props.RemovedDispolistElementIds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), props.SelectedDispolist.Elements);
    const dependencies = [props.SelectedDispolist, props.NotAssignableTools];
    reactApi.useEffect(() => {
    }, dependencies);
    const removeButton = (propsButton) => {
        let elems_1, elems;
        return createElement("td", createObj(ofArray([["data-test-id", `${propsButton.TestId}-remove-button`], ["className", join(" ", [])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "grow", "justify-center"])], (elems = [createElement(TextButton, {
            ComponentState: "enabled",
            Icon: Recycle_Bin_1(),
            Label: "",
            OnClick: (e_1) => {
                props.Dispatch(new Msg(6, [propsButton.ElementId]));
            },
            TestId: "dispolist-remove-button",
            Variant: "default",
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
    };
    const addButton = (elementId_1) => {
        let elems_3, elems_2;
        return createElement("td", createObj(ofArray([["className", join(" ", [])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "grow", "justify-center"])], (elems_2 = [createElement(TextButton, {
            ComponentState: "enabled",
            Icon: Recycle_Bin_1(),
            Label: "",
            OnClick: (e_2) => {
                props.Dispatch(new Msg(7, [elementId_1]));
            },
            TestId: "dispolist-add-button",
            Variant: "default",
        })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
    };
    let tabHeaderRemovedElements;
    const arg_1 = {
        numberOfRemovedTools: length(removedTools),
    };
    tabHeaderRemovedElements = (patternInput[1].t("dispolist_assign.removed_elements", arg_1));
    return createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems_25 = toList(delay(() => {
        let elems_4;
        return append((props.NotAssignableTools == null) ? (empty()) : singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_4 = [createElement(NotValidElementTable, {
            Customer: props.Customer,
            InvalidElements: props.SelectedDispolist.InvalidElements,
        })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
            let elems_5;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "gap-4", "mb-4"])], (elems_5 = [createElement(TabsMenu, {
                Tabs: ofArray([{
                    IsSelected: isToolsSelected,
                    Label: props.SelectedDispolist.Name,
                    OnClick: () => {
                        setIsAddedToolsTabSelected(true);
                        setIsToolSelecteed(true);
                    },
                    TestId: "",
                }, {
                    IsSelected: !isToolsSelected,
                    Label: tabHeaderRemovedElements,
                    OnClick: () => {
                        setIsAddedToolsTabSelected(false);
                        setIsToolSelecteed(false);
                    },
                    TestId: "",
                }]),
            })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                let elems_18, elems_17, elems_24;
                return patternInput_1[0] ? singleton(createElement("div", createObj(ofArray([["className", "tab-content"], (elems_18 = [createElement("div", createObj(ofArray([["id", "addedTools"], ["className", "tab-pane active"], (elems_17 = toList(delay(() => append((props.NotAssignableTools == null) ? singleton(defaultOf()) : singleton(createElement(ErrorAlert, {
                    Label: "dispolist_assign.not_valid_error_msg",
                })), delay(() => {
                    let elems_13, elems_6, children, children_6;
                    return append(singleton(createElement("table", createObj(ofArray([["className", join(" ", ["table"])], (elems_13 = [createElement("thead", createObj(ofArray([["className", join(" ", ["border-[#E1E7F0]", "border-b-2"])], (elems_6 = [(children = toList(delay(() => append(singleton(createElement("th", {
                        className: join(" ", ["w-64"]),
                        children: t("dispolist_assign.manufacturer"),
                    })), delay(() => append(singleton(createElement("th", {
                        className: join(" ", ["w-64"]),
                        children: t("dispolist_assign.model"),
                    })), delay(() => append(singleton(createElement("th", {
                        className: join(" ", ["w-64"]),
                        children: t("dispolist_assign.display_name"),
                    })), delay(() => append((props.Customer.tag === 1) ? singleton(createElement("th", {
                        className: join(" ", ["w-64"]),
                        children: t("dispolist_assign.check_number"),
                    })) : (empty()), delay(() => append(singleton(createElement("th", {
                        className: join(" ", ["w-64"]),
                        children: t("dispolist_assign.quantity"),
                    })), delay(() => append(singleton(createElement("th", {
                        className: join(" ", ["w-64"]),
                        children: t("dispolist_assign.storage"),
                    })), delay(() => singleton(createElement("th", {
                        className: join(" ", ["w-64"]),
                        children: t("dispolist_assign.action"),
                    })))))))))))))))), createElement("tr", {
                        children: reactApi.Children.toArray(Array.from(children)),
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))), (children_6 = map((ele) => {
                        let elems_10, elems_12, elems_8;
                        switch (ele.tag) {
                            case 2: {
                                const t_2 = ele.fields[0];
                                return createElement("tr", createObj(ofArray([["className", join(" ", toList(delay(() => {
                                    let matchValue_2, notAssignabledTools_2, toolId_3;
                                    return append(((matchValue_2 = props.NotAssignableTools, (matchValue_2 == null) ? false : ((notAssignabledTools_2 = matchValue_2, (toolId_3 = (new ToolId(parse(t_2.ToolId))), exists((c_2) => equals(c_2.Id, toolId_3), notAssignabledTools_2.ToolWithQuantity)))))) ? singleton("bg-light-red") : singleton(""), delay(() => append(singleton("border-[#E1E7F0]"), delay(() => append(singleton("border-b"), delay(() => singleton("h-[4rem]")))))));
                                })))], (elems_10 = toList(delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_2.Manufacturer,
                                })), delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_2.Model,
                                })), delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_2.DisplayName,
                                })), delay(() => append((props.Customer.tag === 1) ? singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_2.CheckNumber,
                                })) : (empty()), delay(() => {
                                    let children_2;
                                    return append(singleton((children_2 = singleton_1(createElement(NumberInput, {
                                        ComponentState: "enabled",
                                        MaxValue: numberInputIntMaxValue,
                                        OnChange: (newValue) => {
                                            props.Dispatch(new Msg(5, [t_2.Id, undefined, map_1((value_92) => ~~value_92, newValue)]));
                                        },
                                        TestId: "",
                                        Value: t_2.Quantity,
                                    })), createElement("td", {
                                        children: reactApi.Children.toArray(Array.from(children_2)),
                                    }))), delay(() => {
                                        let elems_9;
                                        return append(singleton(createElement("td", createObj(ofArray([["className", join(" ", ["w-80"])], (elems_9 = toList(delay(() => {
                                            const selectedStorage_1 = find((s_1) => (unwrapStorageId(s_1.Id) === t_2.StorageId), t_2.Storages);
                                            const options_1 = map((d_1) => ({
                                                label: d_1.Name,
                                                value: d_1,
                                            }), t_2.Storages);
                                            return singleton(createElement(Select, {
                                                ComponentState: "enabled",
                                                IsClearable: false,
                                                NoOptionsMessage: "select.employee_no_options",
                                                OnChange: (value_95) => {
                                                    if (value_95 == null) {
                                                    }
                                                    else {
                                                        props.Dispatch(new Msg(4, [t_2.Id, unwrapStorageId(value_95.value.Id)]));
                                                    }
                                                },
                                                Options: options_1,
                                                PlaceholderKey: "select.employee_placeholder",
                                                TestId: "",
                                                Value: unwrap(tryFind((value_94) => equals(value_94.value.Id, selectedStorage_1.Id), options_1)),
                                            }));
                                        })), ["children", reactApi.Children.toArray(Array.from(elems_9))])])))), delay(() => singleton(removeButton({
                                            ElementId: t_2.Id,
                                            TestId: `${t_2.Model}-remove-button`,
                                        }))));
                                    }));
                                })))))))))), ["children", reactApi.Children.toArray(Array.from(elems_10))])])));
                            }
                            case 0: {
                                const t_3 = ele.fields[0];
                                return createElement("tr", createObj(ofArray([["className", join(" ", toList(delay(() => {
                                    let matchValue, notAssignabledTools, consumableId_1;
                                    return append(((matchValue = props.NotAssignableTools, (matchValue == null) ? false : ((notAssignabledTools = matchValue, (consumableId_1 = (new ConsumableId(parse(t_3.ConsumableId))), exists((c) => equals(c.Id, consumableId_1), notAssignabledTools.Consumables)))))) ? singleton("bg-light-red") : singleton(""), delay(() => append(singleton("border-[#E1E7F0]"), delay(() => append(singleton("border-b"), delay(() => singleton("h-[4rem]")))))));
                                })))], (elems_12 = toList(delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: "",
                                })), delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: "",
                                })), delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: `${t_3.Name} ${t_3.AdditionalName}`,
                                })), delay(() => append((props.Customer.tag === 1) ? singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_3.CheckNumber,
                                })) : (empty()), delay(() => {
                                    let children_4;
                                    return append(singleton((children_4 = singleton_1(createElement(NumberInput, {
                                        ComponentState: "enabled",
                                        OnChange: (newValue_1) => {
                                            props.Dispatch(new Msg(5, [t_3.Id, undefined, map_1((value_113) => ~~value_113, newValue_1)]));
                                        },
                                        TestId: "",
                                        Value: t_3.Quantity,
                                    })), createElement("td", {
                                        children: reactApi.Children.toArray(Array.from(children_4)),
                                    }))), delay(() => {
                                        let elems_11;
                                        return append(singleton(createElement("td", createObj(ofArray([["className", join(" ", ["w-80"])], (elems_11 = toList(delay(() => {
                                            const selectedStorage_2 = find((s_2) => (unwrapStorageId(s_2.Id) === t_3.StorageId), t_3.Storages);
                                            const options_2 = map((d_2) => ({
                                                label: d_2.Name,
                                                value: d_2,
                                            }), t_3.Storages);
                                            return singleton(createElement(Select, {
                                                ComponentState: "enabled",
                                                IsClearable: false,
                                                NoOptionsMessage: "select.employee_no_options",
                                                OnChange: (value_116) => {
                                                    if (value_116 == null) {
                                                    }
                                                    else {
                                                        props.Dispatch(new Msg(4, [t_3.Id, unwrapStorageId(value_116.value.Id)]));
                                                    }
                                                },
                                                Options: options_2,
                                                PlaceholderKey: "select.employee_placeholder",
                                                TestId: "",
                                                Value: unwrap(tryFind((value_115) => equals(value_115.value.Id, selectedStorage_2.Id), options_2)),
                                            }));
                                        })), ["children", reactApi.Children.toArray(Array.from(elems_11))])])))), delay(() => singleton(removeButton({
                                            ElementId: t_3.Id,
                                            TestId: `${t_3.Name}-remove-button`,
                                        }))));
                                    }));
                                })))))))))), ["children", reactApi.Children.toArray(Array.from(elems_12))])])));
                            }
                            default: {
                                const t_1 = ele.fields[0];
                                return createElement("tr", createObj(ofArray([["className", join(" ", toList(delay(() => {
                                    let matchValue_1, notAssignabledTools_1, toolId_1;
                                    return append(((matchValue_1 = props.NotAssignableTools, (matchValue_1 == null) ? false : ((notAssignabledTools_1 = matchValue_1, (toolId_1 = (new ToolId(parse(t_1.ToolId))), exists((c_1) => equals(c_1.Id, toolId_1), notAssignabledTools_1.Tools)))))) ? singleton("bg-light-red") : singleton(""), delay(() => append(singleton("border-[#E1E7F0]"), delay(() => append(singleton("border-b"), delay(() => singleton("h-[4rem]")))))));
                                })))], (elems_8 = toList(delay(() => append(singleton(createElement("td", {
                                    "data-test-id": `${t_1.Manufacturer}-cell`,
                                    className: join(" ", ["text-center"]),
                                    children: t_1.Manufacturer,
                                })), delay(() => append(singleton(createElement("td", {
                                    "data-test-id": `${t_1.Model}-cell`,
                                    className: join(" ", ["text-center"]),
                                    children: t_1.Model,
                                })), delay(() => append(singleton(createElement("td", {
                                    "data-test-id": `${t_1.DisplayName}-cell`,
                                    className: join(" ", ["text-center"]),
                                    children: t_1.DisplayName,
                                })), delay(() => append((props.Customer.tag === 1) ? singleton(createElement("td", {
                                    "data-test-id": `${t_1.CheckNumber}-cell`,
                                    className: join(" ", ["text-center"]),
                                    children: t_1.CheckNumber,
                                })) : (empty()), delay(() => append(singleton(createElement("td", {
                                    "data-test-id": "1-cell",
                                    className: join(" ", ["text-center"]),
                                    children: "1",
                                })), delay(() => {
                                    let elems_7;
                                    return append(singleton(createElement("td", createObj(ofArray([["className", join(" ", ["w-80"])], (elems_7 = toList(delay(() => {
                                        const selectedStorage = find((s) => (unwrapStorageId(s.Id) === t_1.StorageId), t_1.Storages);
                                        const options = map((d) => ({
                                            label: d.Name,
                                            value: d,
                                        }), t_1.Storages);
                                        return singleton(createElement(Select, {
                                            ComponentState: "enabled",
                                            IsClearable: false,
                                            NoOptionsMessage: "select.employee_no_options",
                                            OnChange: (value_74) => {
                                                if (value_74 == null) {
                                                }
                                                else {
                                                    props.Dispatch(new Msg(4, [t_1.Id, unwrapStorageId(value_74.value.Id)]));
                                                }
                                            },
                                            Options: options,
                                            PlaceholderKey: "select.employee_placeholder",
                                            TestId: "",
                                            Value: unwrap(tryFind((value_73) => equals(value_73.value.Id, selectedStorage.Id), options)),
                                        }));
                                    })), ["children", reactApi.Children.toArray(Array.from(elems_7))])])))), delay(() => singleton(removeButton({
                                        ElementId: t_1.Id,
                                        TestId: `${t_1.Model}-remove-button`,
                                    }))));
                                })))))))))))), ["children", reactApi.Children.toArray(Array.from(elems_8))])])));
                            }
                        }
                    }, filter((e_3) => !contains(Helper_detailedElementId(e_3), props.RemovedDispolistElementIds, {
                        Equals: (x_1, y_1) => (x_1 === y_1),
                        GetHashCode: stringHash,
                    }), props.SelectedDispolist.Elements)), createElement("tbody", {
                        children: reactApi.Children.toArray(Array.from(children_6)),
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_13))])])))), delay(() => {
                        let elems_15, elems_14;
                        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-start", "items-center", "mt-3"])], (elems_15 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems_14 = toList(delay(() => append(singleton(createElement(TextArea, {
                            ComponentState: props.FormState,
                            Label: t("general.comment"),
                            OnChange: props.SetComment,
                            TestId: "",
                            Value: props.Comment,
                        })), delay(() => {
                            if (props.Receiver.ReceiverType.tag === 0) {
                                return append(singleton(Checkbox({
                                    ComponentState: props.FormState,
                                    IsChecked: props.WithNotification,
                                    Label: t("tool.dialog_assign_with_notification"),
                                    OnCheck: props.SetWithNotification,
                                    TestId: "",
                                })), delay(() => singleton(createElement(InformationAlert, {
                                    Label: t("tool.dialog_assign_with_notification_info"),
                                }))));
                            }
                            else {
                                return empty();
                            }
                        })))), ["children", reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", reactApi.Children.toArray(Array.from(elems_15))])])))), delay(() => {
                            let elems_16;
                            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems_16 = [createElement(Button, {
                                ComponentState: isEmpty(props.SelectedDispolist.InvalidElements) ? props.FormState : "disabled",
                                Label: t("assign_multiple_tools_dialog.assign"),
                                OnClick: () => {
                                    props.Dispatch(new Msg(2, []));
                                },
                                TestId: "assign-multiple-tools-dialog-button",
                                Variant: "primary",
                            })], ["children", reactApi.Children.toArray(Array.from(elems_16))])]))));
                        }));
                    }));
                })))), ["children", reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", reactApi.Children.toArray(Array.from(elems_18))])])))) : singleton(createElement("div", createObj(ofArray([["id", "removedTools"], ["className", "tab-pane"], (elems_24 = toList(delay(() => {
                    let elems_23, elems_19, children_8, children_10;
                    return isEmpty(removedTools) ? singleton(defaultOf()) : singleton(createElement("table", createObj(ofArray([["className", join(" ", ["table"])], (elems_23 = [createElement("thead", createObj(ofArray([["className", join(" ", ["border-[#E1E7F0]", "border-b-2"])], (elems_19 = [(children_8 = toList(delay(() => append(singleton(createElement("th", {
                        className: join(" ", ["px-4", "w-80"]),
                        children: t("dispolist_assign.manufacturer"),
                    })), delay(() => append(singleton(createElement("th", {
                        className: join(" ", ["px-4", "w-80"]),
                        children: t("dispolist_assign.model"),
                    })), delay(() => append(singleton(createElement("th", {
                        className: join(" ", ["px-4", "w-80"]),
                        children: t("dispolist_assign.display_name"),
                    })), delay(() => append((props.Customer.tag === 1) ? singleton(createElement("th", {
                        className: join(" ", ["px-4", "w-80"]),
                        children: t("dispolist_assign.check_number"),
                    })) : (empty()), delay(() => append(singleton(createElement("th", {
                        className: join(" ", ["px-4", "w-80"]),
                        children: t("dispolist_assign.storage"),
                    })), delay(() => singleton(createElement("th", {
                        className: join(" ", ["px-4", "w-80"]),
                        children: t("dispolist_assign.action"),
                    })))))))))))))), createElement("tr", {
                        children: reactApi.Children.toArray(Array.from(children_8)),
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_19))])]))), (children_10 = map((ele_1) => {
                        let elems_21, elems_22, elems_20;
                        switch (ele_1.tag) {
                            case 2: {
                                const t_5 = ele_1.fields[0];
                                return createElement("tr", createObj(ofArray([["className", join(" ", ["border-[#E1E7F0]", "border-b", "h-[4rem]"])], (elems_21 = toList(delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_5.Manufacturer,
                                })), delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_5.Model,
                                })), delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_5.DisplayName,
                                })), delay(() => append((props.Customer.tag === 1) ? singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_5.CheckNumber,
                                })) : (empty()), delay(() => append(singleton(createElement("td", createObj(toList(delay(() => append(singleton(["className", join(" ", ["text-center"])]), delay(() => singleton(["children", find((s_4) => (unwrapStorageId(s_4.Id) === t_5.StorageId), t_5.Storages).Name])))))))), delay(() => singleton(addButton(t_5.Id)))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems_21))])])));
                            }
                            case 0: {
                                const t_6 = ele_1.fields[0];
                                return createElement("tr", createObj(ofArray([["className", join(" ", ["border-[#E1E7F0]", "border-b", "h-[4rem]"])], (elems_22 = toList(delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: "",
                                })), delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: "",
                                })), delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: `${t_6.Name} ${t_6.AdditionalName}`,
                                })), delay(() => append((props.Customer.tag === 1) ? singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_6.CheckNumber,
                                })) : (empty()), delay(() => append(singleton(createElement("td", createObj(toList(delay(() => append(singleton(["className", join(" ", ["text-center"])]), delay(() => singleton(["children", find((s_5) => (unwrapStorageId(s_5.Id) === t_6.StorageId), t_6.Storages).Name])))))))), delay(() => singleton(addButton(t_6.Id)))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems_22))])])));
                            }
                            default: {
                                const t_4 = ele_1.fields[0];
                                return createElement("tr", createObj(ofArray([["className", join(" ", ["border-[#E1E7F0]", "border-b", "h-[4rem]"])], (elems_20 = toList(delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_4.Manufacturer,
                                })), delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_4.Model,
                                })), delay(() => append(singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_4.DisplayName,
                                })), delay(() => append((props.Customer.tag === 1) ? singleton(createElement("td", {
                                    className: join(" ", ["text-center"]),
                                    children: t_4.CheckNumber,
                                })) : (empty()), delay(() => append(singleton(createElement("td", createObj(toList(delay(() => append(singleton(["className", join(" ", ["text-center"])]), delay(() => singleton(["children", find((s_3) => (unwrapStorageId(s_3.Id) === t_4.StorageId), t_4.Storages).Name])))))))), delay(() => singleton(addButton(t_4.Id)))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems_20))])])));
                            }
                        }
                    }, removedTools), createElement("tbody", {
                        children: reactApi.Children.toArray(Array.from(children_10)),
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_23))])]))));
                })), ["children", reactApi.Children.toArray(Array.from(elems_24))])]))));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_25))])])));
}

export function DispolistAssignView(props) {
    let elems_2, elems_1;
    let patternInput;
    const init = init_1({
        Customer: props.Customer,
        OnClose: props.OnClose,
        Receiver: props.Receiver,
        SuccessCallback: props.SuccessCallback,
        ToAssignConsumables: empty_1(),
        ToAssignTools: empty_1(),
        ToAssignToolsWithQuantity: empty_1(),
    });
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const t = useTranslation()[0];
    const formState = state_1.RequestState ? "disabled" : "enabled";
    return createElement(ModalSheet, {
        CloseButtonTestId: "",
        Content: createElement("div", createObj(ofArray([["className", join(" ", ["min-h-48"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["p-4"])], (elems_1 = toList(delay(() => {
            let children;
            const matchValue = state_1.Receiver;
            if (matchValue == null) {
                return empty();
            }
            else {
                const receiver = matchValue;
                return singleton((children = toList(delay(() => {
                    let elems;
                    return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["w-80"])], (elems = [createElement(SectionHeader, {
                        Text: t("dispolist_assign.assign_dispolist"),
                    }), createElement(Select, {
                        ComponentState: formState,
                        IsClearable: false,
                        Label: t("dispolist_assign.choose_dispolist"),
                        NoOptionsMessage: "dispolist_assign.select_no_dispolist_found",
                        OnChange: (value_3) => {
                            if (value_3 == null) {
                            }
                            else {
                                dispatch(new Msg(8, [value_3.value.Id]));
                            }
                        },
                        Options: map((d) => ({
                            label: d.Name,
                            value: d,
                        }), state_1.Dispolists),
                        PlaceholderKey: "dispolist_assign.select_dispolist_placeholder",
                        TestId: "dispolist-assign-select-list",
                    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                        const matchValue_1 = state_1.SelectedDispolist;
                        return (matchValue_1 != null) ? singleton(createElement(DispolistDetail, {
                            Comment: state_1.Comment,
                            Customer: state_1.Customer,
                            Dispatch: dispatch,
                            FormState: formState,
                            NotAssignableTools: unwrap(state_1.NotAssignableTools),
                            Receiver: receiver,
                            RemovedDispolistElementIds: state_1.RemovedDispolistElementIds,
                            SelectedDispolist: matchValue_1,
                            SetComment: (value_6) => {
                                dispatch(new Msg(1, [value_6]));
                            },
                            SetWithNotification: (value_7) => {
                                dispatch(new Msg(0, [value_7]));
                            },
                            WithNotification: state_1.WithNotification,
                        })) : singleton(defaultOf());
                    }));
                })), createElement("div", {
                    children: reactApi.Children.toArray(Array.from(children)),
                })));
            }
        })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))),
        Controls: empty_1(),
        IsModalOpen: props.IsOpen,
        OnClose: props.OnClose,
    });
}

