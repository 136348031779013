import { createElement } from "react";
import React from "react";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { TextButton, Button } from "../Components/Button.js";
import Delete_1 from "../../public/assets/icons/Delete_1.svg";

export function FloatingDialog(props) {
    let elems_4;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "h-20", "rounded-lg", "drop-shadow-2xl", "bg-white"])], (elems_4 = toList(delay(() => append(singleton(createElement("div", {
        className: join(" ", ["h-100", "w-2.5", "rounded-l-lg", "bg-primary"]),
    })), delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex"])], (elems = [createElement("div", {
            className: join(" ", ["flex", "items-center", "ml-12", "text-[#808080]"]),
            children: props.Text,
        }), createElement("div", {
            className: join(" ", ["flex", "items-center", "ml-1", "font-bold", "text-[#808080]"]),
            children: props.Date,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(singleton(createElement("div", {
            className: join(" ", ["grow"]),
        })), delay(() => {
            let elems_1;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["ml-4", "flex", "items-center", "mr-11"])], (elems_1 = [createElement(Button, {
                ComponentState: "enabled",
                Label: "Upgrade",
                OnClick: props.OnClick,
                TestId: "upgrade-button",
                Variant: "primary",
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let elems_3, elems_2;
                const matchValue = props.OnCancelClick;
                if (matchValue == null) {
                    return empty();
                }
                else {
                    const onCancelClick = matchValue;
                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mt-3"])], (elems_3 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Icon: createElement("div", createObj(ofArray([["className", join(" ", ["h-4", "w-4"])], (elems_2 = [Delete_1()], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))),
                        Label: "",
                        OnClick: onCancelClick,
                        TestId: "cancel-button",
                        Variant: "default",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
                }
            }));
        }))));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

