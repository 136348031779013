import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { ToolTypes_$reflection, ToolWithQuantityUpdateFormDto_$reflection, ToolWithQuantityCreateFormDto_$reflection } from "../../../Shared/Tool.js";
import { array_type, anonRecord_type, record_type, string_type, int32_type, class_type, bool_type, list_type, option_type, union_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ClientApiResponse$1_$reflection, LabelId_$reflection, StorageId_$reflection, FormState_$reflection, Customer_$reflection, TokenDataDto_$reflection, Label_$reflection, RequestedValue$1_$reflection, ToolId_$reflection } from "../../../Shared/Shared.js";
import { Storage_$reflection } from "../../../Shared/Storage.js";
import { PropertyConfigurationEnabledResponse_$reflection, CustomProperty_$reflection, PropertyConfiguration_$reflection } from "../../../Shared/PropertyConfiguration.js";
import { OptionType$1_$reflection } from "../../../SharedComponents/ReactSelect.js";
import { FileUploadResponse_$reflection } from "../../../Shared/File.js";

export class ToolWithQuantityForm extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Create", "Update"];
    }
}

export function ToolWithQuantityForm_$reflection() {
    return union_type("Tools.ToolWithQuantity.Form.Types.ToolWithQuantityForm", [], ToolWithQuantityForm, () => [[["Item", ToolWithQuantityCreateFormDto_$reflection()]], [["Item", ToolWithQuantityUpdateFormDto_$reflection()]]]);
}

export class State extends Record {
    constructor(ToolId, ToolForm, Storages, Labels, PropertyConfigurations, IsCustomPropertiesEnabled, PurchaseDate, WarrantyDate, StorageStock, UserData, Customer, Manufacturers, SelectedFile, FormState) {
        super();
        this.ToolId = ToolId;
        this.ToolForm = ToolForm;
        this.Storages = Storages;
        this.Labels = Labels;
        this.PropertyConfigurations = PropertyConfigurations;
        this.IsCustomPropertiesEnabled = IsCustomPropertiesEnabled;
        this.PurchaseDate = PurchaseDate;
        this.WarrantyDate = WarrantyDate;
        this.StorageStock = StorageStock;
        this.UserData = UserData;
        this.Customer = Customer;
        this.Manufacturers = Manufacturers;
        this.SelectedFile = SelectedFile;
        this.FormState = FormState;
    }
}

export function State_$reflection() {
    return record_type("Tools.ToolWithQuantity.Form.Types.State", [], State, () => [["ToolId", option_type(ToolId_$reflection())], ["ToolForm", RequestedValue$1_$reflection(ToolWithQuantityForm_$reflection())], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["Labels", RequestedValue$1_$reflection(list_type(Label_$reflection()))], ["PropertyConfigurations", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["IsCustomPropertiesEnabled", RequestedValue$1_$reflection(bool_type)], ["PurchaseDate", option_type(class_type("System.DateTime"))], ["WarrantyDate", option_type(class_type("System.DateTime"))], ["StorageStock", option_type(int32_type)], ["UserData", TokenDataDto_$reflection()], ["Customer", Customer_$reflection()], ["Manufacturers", list_type(string_type)], ["SelectedFile", option_type(class_type("Browser.Types.File", undefined))], ["FormState", FormState_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetManufacturer", "SetDisplayName", "SetModel", "SetInventoryNumber", "SelectStorage", "SetStorageSpace", "SetStock", "SetComment", "AddLabelId", "SetCustomProperties", "SetPrice", "SetSupplier", "SetArticleNumber", "SetPurchaseDate", "SetWarrantyDate", "SetCommercialDataComment", "SaveTool", "UpdateTool", "FetchTool", "ToolFetched", "CopyToolFetched", "ToolUpdated", "ToolCreated", "StoragesFetched", "LabelsFetched", "ManufacturersFetched", "PropertyConfigurationsFetched", "FetchCategories", "SetSelectedFile", "ImageUploaded", "CustomPropertiesEnabledFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Tools.ToolWithQuantity.Form.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", anonRecord_type(["label", string_type], ["value", StorageId_$reflection()])]], [["Item", string_type]], [["Item", option_type(int32_type)]], [["Item", string_type]], [["Item", array_type(OptionType$1_$reflection(LabelId_$reflection()))]], [["Item", list_type(CustomProperty_$reflection())]], [["Item", option_type(int32_type)]], [["Item", string_type]], [["Item", string_type]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", string_type]], [], [], [], [["Item", ToolTypes_$reflection()]], [["Item", ToolTypes_$reflection()]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", list_type(Storage_$reflection())]], [["Item", list_type(Label_$reflection())]], [["Item", list_type(string_type)]], [["Item", list_type(PropertyConfiguration_$reflection())]], [], [["Item", option_type(class_type("Browser.Types.File", undefined))]], [["Item", FileUploadResponse_$reflection()]], [["Item", PropertyConfigurationEnabledResponse_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

