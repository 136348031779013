import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { ConsumableDto_$reflection } from "../../Shared/Consumable.js";
import { union_type, class_type, record_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { EmptyResponse_$reflection, Customer_$reflection, TokenDataDto_$reflection, CustomerConfiguration_$reflection, RequestedValue$1_$reflection } from "../../Shared/Shared.js";
import { PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { TableConfiguration_$reflection, UserConfiguration_$reflection } from "../../Shared/User.js";

export class State extends Record {
    constructor(Consumables, PropertyConfigurations, UserConfiguration, CustomerConfiguration, UserData, Customer) {
        super();
        this.Consumables = Consumables;
        this.PropertyConfigurations = PropertyConfigurations;
        this.UserConfiguration = UserConfiguration;
        this.CustomerConfiguration = CustomerConfiguration;
        this.UserData = UserData;
        this.Customer = Customer;
    }
}

export function State_$reflection() {
    return record_type("Consumables.Overview.Types.State", [], State, () => [["Consumables", RequestedValue$1_$reflection(list_type(ConsumableDto_$reflection()))], ["PropertyConfigurations", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["UserConfiguration", RequestedValue$1_$reflection(UserConfiguration_$reflection())], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["UserData", TokenDataDto_$reflection()], ["Customer", Customer_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FetchConsumables", "ConsumablesFetched", "PropertyConfigurationsFetched", "UserConfigurationFetched", "UpdateTableConfiguration", "UserConfigurationUpdated", "CustomerConfigurationFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Consumables.Overview.Types.Msg", [], Msg, () => [[], [["Item", list_type(ConsumableDto_$reflection())]], [["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", UserConfiguration_$reflection()]], [["Item", TableConfiguration_$reflection()]], [["Item", EmptyResponse_$reflection()]], [["Item", CustomerConfiguration_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

