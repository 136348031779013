import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { Storage_$reflection } from "../../../Shared/Storage.js";
import { record_type, anonRecord_type, list_type, lambda_type, unit_type, union_type, class_type, int32_type, string_type, option_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, FormState__setValidations_Z51B1D053, ToolId, FormState__startLoading, FormState_get_empty, StorageId__get_unwrap, UpdateToolWithQuantityStorageDto, FormState_$reflection, TokenDataDto_$reflection, UpdateToolWithQuantityStorageDto_$reflection, ClientApiResponse$1_$reflection } from "../../../Shared/Shared.js";
import { ToolWithQuantityDetailDto_$reflection } from "../../../Shared/Tool.js";
import { singleton as singleton_1, ofArray, tryFind, exists, filter, map } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { unwrapStorageId } from "../../../Shared/Helper.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { updateToolWithQuantityStorage } from "../../../Requests/ToolWithQuantity.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { empty, singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Cmd_successToast, Cmd_errorToast } from "../../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { equals, createObj } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { Select } from "../../../Components/Select.js";
import { map as map_1, unwrap } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Input, NumberInput } from "../../../Components/Input.js";
import { numberInputIntMaxValue } from "../../../Common.js";
import { ErrorAlert } from "../../../Components/Alert.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../../Components/Dialog.js";
import { TextButton } from "../../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateStorage", "SetSelectedTargetStorage", "SetSelectedSourceStorage", "SetStorageSpace", "SetQuantity", "StorageUpdated", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Tools.ToolWithQuantity.Dialogs.SwitchStorageDialog.Msg", [], Msg, () => [[], [["Item", option_type(Storage_$reflection())]], [["Item", option_type(Storage_$reflection())]], [["Item", string_type]], [["Item", option_type(int32_type)]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Tool, Dto, Quantity, OnClose, SuccessCallback, UserData, Storages, TargetStorageOptions, SourceStorageOptions, SelectedTargetStorage, SelectedSourceStorage, FormState) {
        super();
        this.Tool = Tool;
        this.Dto = Dto;
        this.Quantity = Quantity;
        this.OnClose = OnClose;
        this.SuccessCallback = SuccessCallback;
        this.UserData = UserData;
        this.Storages = Storages;
        this.TargetStorageOptions = TargetStorageOptions;
        this.SourceStorageOptions = SourceStorageOptions;
        this.SelectedTargetStorage = SelectedTargetStorage;
        this.SelectedSourceStorage = SelectedSourceStorage;
        this.FormState = FormState;
    }
}

function State_$reflection() {
    return record_type("Tools.ToolWithQuantity.Dialogs.SwitchStorageDialog.State", [], State, () => [["Tool", ToolWithQuantityDetailDto_$reflection()], ["Dto", UpdateToolWithQuantityStorageDto_$reflection()], ["Quantity", option_type(int32_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["UserData", TokenDataDto_$reflection()], ["Storages", list_type(Storage_$reflection())], ["TargetStorageOptions", list_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))], ["SourceStorageOptions", list_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))], ["SelectedTargetStorage", option_type(Storage_$reflection())], ["SelectedSourceStorage", option_type(Storage_$reflection())], ["FormState", FormState_$reflection()]]);
}

function init(tool, userData, successCallback, isOpen, onClose, storages, selectedStorageId) {
    let storageId;
    return [new State(tool, new UpdateToolWithQuantityStorageDto("", "", 0, ""), undefined, onClose, successCallback, userData, storages, map((storage) => ({
        label: storage.Name,
        value: storage,
    }), storages), map((storage_2) => ({
        label: storage_2.Name,
        value: storage_2,
    }), filter((storage_1) => exists((sps) => (sps.StorageId === unwrapStorageId(storage_1.Id)), tool.StockPerStorage), storages)), undefined, (selectedStorageId == null) ? undefined : ((storageId = selectedStorageId, tryFind((storage_3) => (StorageId__get_unwrap(storage_3.Id) === storageId), storages))), FormState_get_empty()), Cmd_none()];
}

function update(msg, state) {
    let bind$0040;
    switch (msg.tag) {
        case 2:
            return [new State(state.Tool, state.Dto, state.Quantity, state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, state.SourceStorageOptions, state.SelectedTargetStorage, msg.fields[0], state.FormState), Cmd_none()];
        case 4:
            return [new State(state.Tool, state.Dto, msg.fields[0], state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, state.SourceStorageOptions, state.SelectedTargetStorage, state.SelectedSourceStorage, state.FormState), Cmd_none()];
        case 3:
            return [new State(state.Tool, (bind$0040 = state.Dto, new UpdateToolWithQuantityStorageDto(bind$0040.OldStorageId, bind$0040.NewStorageId, bind$0040.Quantity, msg.fields[0])), state.Quantity, state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, state.SourceStorageOptions, state.SelectedTargetStorage, state.SelectedSourceStorage, state.FormState), Cmd_none()];
        case 0: {
            const matchValue = state.SelectedSourceStorage;
            const matchValue_1 = state.SelectedTargetStorage;
            const matchValue_2 = state.Quantity;
            let matchResult, quantity, sourceStorage, targetStorage, quantity_1, sourceStorage_1, targetStorage_1;
            if (matchValue != null) {
                if (matchValue_1 != null) {
                    if (matchValue_2 != null) {
                        matchResult = 0;
                        quantity = matchValue_2;
                        sourceStorage = matchValue;
                        targetStorage = matchValue_1;
                    }
                    else {
                        matchResult = 1;
                        quantity_1 = matchValue_2;
                        sourceStorage_1 = matchValue;
                        targetStorage_1 = matchValue_1;
                    }
                }
                else {
                    matchResult = 1;
                    quantity_1 = matchValue_2;
                    sourceStorage_1 = matchValue;
                    targetStorage_1 = matchValue_1;
                }
            }
            else {
                matchResult = 1;
                quantity_1 = matchValue_2;
                sourceStorage_1 = matchValue;
                targetStorage_1 = matchValue_1;
            }
            switch (matchResult) {
                case 0: {
                    const dto = new UpdateToolWithQuantityStorageDto(StorageId__get_unwrap(sourceStorage.Id), StorageId__get_unwrap(targetStorage.Id), quantity, state.Dto.StorageSpace);
                    return [new State(state.Tool, state.Dto, state.Quantity, state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, state.SourceStorageOptions, state.SelectedTargetStorage, state.SelectedSourceStorage, FormState__startLoading(state.FormState)), updateToolWithQuantityStorage(new ToolId(parse(state.Tool.Id)), dto, (Item_1) => (new Msg(5, [Item_1])), (Item_2) => (new Msg(6, [Item_2])))];
                }
                default:
                    return [new State(state.Tool, state.Dto, state.Quantity, state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, state.SourceStorageOptions, state.SelectedTargetStorage, state.SelectedSourceStorage, FormState__setValidations_Z51B1D053(state.FormState, toList(delay(() => append((sourceStorage_1 == null) ? singleton(["source-storage", "validation.select.storage"]) : empty(), delay(() => append((targetStorage_1 == null) ? singleton(["target-storage", "validation.select.storage"]) : empty(), delay(() => ((quantity_1 == null) ? singleton(["quantity", "validation.input.quantity_not_empty"]) : empty()))))))))), Cmd_errorToast("validation.toast")];
            }
        }
        case 5: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.Tool, state.Dto, state.Quantity, state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, state.SourceStorageOptions, state.SelectedTargetStorage, state.SelectedSourceStorage, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.Tool, state.Dto, state.Quantity, state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, state.SourceStorageOptions, state.SelectedTargetStorage, state.SelectedSourceStorage, FormState_get_empty()), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 6:
            return [state, Cmd_none()];
        default:
            return [new State(state.Tool, state.Dto, state.Quantity, state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, state.SourceStorageOptions, msg.fields[0], state.SelectedSourceStorage, state.FormState), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems;
    const t = useTranslation()[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = toList(delay(() => {
        let Label, Options, Value, matchValue_1, sourceStorage;
        return append(singleton(createElement(Select, (Label = t("tool.switch_storage_source"), (Options = filter((sourceStorageOption) => {
            const matchValue = props.SelectedTargetStorage;
            if (matchValue == null) {
                return true;
            }
            else {
                return !equals(matchValue.Id, sourceStorageOption.value.Id);
            }
        }, props.SourceStorageOptions), (Value = ((matchValue_1 = props.SelectedSourceStorage, (matchValue_1 == null) ? undefined : ((sourceStorage = matchValue_1, tryFind((option_2) => equals(option_2.value, sourceStorage), props.SourceStorageOptions))))), {
            ComponentState: componentState,
            IsClearable: false,
            Label: unwrap(Label),
            NoOptionsMessage: "select.storage_no_options",
            OnChange: (option) => {
                if (option == null) {
                }
                else {
                    props.Dispatch(new Msg(2, [option.value]));
                }
            },
            Options: Options,
            PlaceholderKey: "select.storage_placeholder",
            TestId: "switch-storage-dialog-storage-source-test-id",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "source-storage")),
            Value: unwrap(Value),
        }))))), delay(() => {
            let Label_1, Options_1, Value_1, matchValue_3, targetStorage;
            return append(singleton(createElement(Select, (Label_1 = t("tool.switch_storage_target"), (Options_1 = filter((targetStorageOptions) => {
                const matchValue_2 = props.SelectedSourceStorage;
                if (matchValue_2 == null) {
                    return true;
                }
                else {
                    return !equals(matchValue_2.Id, targetStorageOptions.value.Id);
                }
            }, props.TargetStorageOptions), (Value_1 = ((matchValue_3 = props.SelectedTargetStorage, (matchValue_3 == null) ? undefined : ((targetStorage = matchValue_3, tryFind((option_5) => equals(option_5.value, targetStorage), props.TargetStorageOptions))))), {
                ComponentState: componentState,
                IsClearable: false,
                Label: unwrap(Label_1),
                NoOptionsMessage: "select.storage_no_options",
                OnChange: (option_3) => {
                    if (option_3 == null) {
                    }
                    else {
                        props.Dispatch(new Msg(1, [option_3.value]));
                    }
                },
                Options: Options_1,
                PlaceholderKey: "select.storage_placeholder",
                TestId: "switch-storage-dialog-target-test-id",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "target-storage")),
                Value: unwrap(Value_1),
            }))))), delay(() => {
                let Label_2, Value_2;
                return append(singleton(createElement(NumberInput, (Label_2 = t("general.quantity_3"), (Value_2 = map_1((value_1) => value_1, props.Quantity), {
                    ComponentState: componentState,
                    Label: unwrap(Label_2),
                    MaxValue: numberInputIntMaxValue,
                    OnChange: (value_2) => {
                        props.Dispatch(new Msg(4, [map_1((value_3) => ~~value_3, value_2)]));
                    },
                    TestId: "switch-storage-dialog-quantity-test-id",
                    ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "quantity")),
                    Value: unwrap(Value_2),
                })))), delay(() => append(singleton(createElement(Input, {
                    ComponentState: componentState,
                    Label: t("tool.storage_space"),
                    OnChange: (arg) => {
                        props.Dispatch(new Msg(3, [arg]));
                    },
                    TestId: "change-storage-space-test-id",
                    Value: props.StorageSpace,
                })), delay(() => {
                    const matchValue_4 = FormState__getValidation(props.FormState, t, "global");
                    if (matchValue_4 == null) {
                        return empty();
                    }
                    else {
                        return singleton(createElement(ErrorAlert, {
                            Label: matchValue_4,
                        }));
                    }
                }))));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function ChangeStorageDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.Tool, props.UserData, props.SuccessCallback, props.IsOpen, props.OnClose, props.Storages, props.SelectedStorageId);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.OnClose]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("tool.switch_storage"), {
        Body: singleton_1(createElement(DialogBody, {
            Dispatch: dispatch,
            FormState: state_1.FormState,
            Quantity: unwrap(state_1.Quantity),
            SelectedSourceStorage: unwrap(state_1.SelectedSourceStorage),
            SelectedTargetStorage: unwrap(state_1.SelectedTargetStorage),
            SourceStorageOptions: state_1.SourceStorageOptions,
            StorageSpace: state_1.Dto.StorageSpace,
            Storages: state_1.Storages,
            SuccessCallback: props.SuccessCallback,
            TargetStorageOptions: state_1.TargetStorageOptions,
            Tool: props.Tool,
            UserId: state_1.UserData.UserId,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-reservation-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("tool.switch_storage"),
            OnClick: () => {
                dispatch(new Msg(0, []));
            },
            TestId: "save-switch-storage-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

