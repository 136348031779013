import { createElement } from "react";
import React from "react";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { useTranslation } from "react-i18next";
import { singleton, toArray, ofArray, empty, tryFind, choose } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { unwrapDispolistId, unwrapToolId, unwrapConsumableId } from "../../Shared/Helper.js";
import { int32ToString, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { ConfirmationDialog } from "../../Components/Dialog.js";
import { Msg } from "./Types.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { DetailViewHeader } from "../../Widgets/DetailViewHeader.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { LabeledValue } from "../../Components/Text.js";
import { TableContainer } from "../../Widgets/Container.js";
import { ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Helper_unitToHumanString } from "../../Shared/Consumable.js";
import { update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

function DetailView(props) {
    let elems_2, Title, elems_1, elems, Title_2, TableRows, Title_3, TableRows_1, Title_4, TableRows_2;
    const patternInput = reactApi.useState(false);
    const setIsDeleteDialogOpen = patternInput[1];
    const isDeleteDialogOpen = patternInput[0];
    const t = useTranslation()[0];
    const toolElements = choose((element) => {
        switch (element.tag) {
            case 0:
            case 2:
                return undefined;
            default:
                return element;
        }
    }, props.Dispolist.Elements);
    const toolWithQuantityElements = choose((element_1) => {
        switch (element_1.tag) {
            case 0:
            case 1:
                return undefined;
            default:
                return element_1;
        }
    }, props.Dispolist.Elements);
    const consumableElements = choose((element_2) => {
        switch (element_2.tag) {
            case 1:
            case 2:
                return undefined;
            default:
                return element_2;
        }
    }, props.Dispolist.Elements);
    const getElement = (element_3) => tryFind((e) => {
        switch (element_3.tag) {
            case 0:
                return unwrapConsumableId(element_3.fields[0].ConsumableId) === e.Id;
            case 2:
                return unwrapToolId(element_3.fields[0].ToolId) === e.Id;
            default:
                return unwrapToolId(element_3.fields[0].ToolId) === e.Id;
        }
    }, props.Elements);
    return createElement("div", createObj(singleton((elems_2 = [createElement(ConfirmationDialog, (Title = t("general.remove"), {
        Description: t("dispolist.dispolist_delete_question"),
        IsOpen: isDeleteDialogOpen,
        OnClose: () => {
            setIsDeleteDialogOpen(false);
        },
        OnConfirm: () => {
            props.Dispatch(new Msg(0, []));
        },
        Title: Title,
    })), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(DetailViewHeader, {
        Badges: empty(),
        OnBack: () => {
            RouterModule_nav(ofArray(["inventory-lists"]), 1, 1);
        },
        OnDelete: () => {
            setIsDeleteDialogOpen(!isDeleteDialogOpen);
        },
        OnEdit: () => {
            RouterModule_nav(ofArray(["inventory-lists", unwrapDispolistId(props.Dispolist.Id), "edit"]), 1, 1);
        },
        Title: t("dispolist.dispolist_detail_header"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-shrink-0", "flex-col", "mb-3", "bg-white", "rounded", "px-12", "py-9"])], (elems = [LabeledValue({
        Label: t("general.name"),
        Value: props.Dispolist.Name,
    }), LabeledValue({
        Label: t("general.description"),
        Value: props.Dispolist.Description,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(TableContainer, (Title_2 = t("general.tool"), {
        Content: createElement(Table, (TableRows = toArray(toolElements), {
            ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (row) => {
                const matchValue = getElement(row);
                return (matchValue != null) ? (new CellContent(0, [matchValue.DisplayName])) : (new CellContent(0, [""]));
            }, "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_1) => {
                const matchValue_1 = getElement(row_1);
                return (matchValue_1 != null) ? (new CellContent(0, [matchValue_1.InventoryNumber])) : (new CellContent(0, [""]));
            }, "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_2) => {
                const matchValue_2 = getElement(row_2);
                return (matchValue_2 != null) ? (new CellContent(0, [matchValue_2.Manufacturer])) : (new CellContent(0, [""]));
            }, "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_3) => {
                const matchValue_3 = getElement(row_3);
                return (matchValue_3 != null) ? (new CellContent(0, [matchValue_3.Model])) : (new CellContent(0, [""]));
            }, "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_4) => {
                const matchValue_4 = getElement(row_4);
                return (matchValue_4 != null) ? (new CellContent(0, [matchValue_4.SerialNumber])) : (new CellContent(0, [""]));
            }, "text", "text")],
            Controls: empty(),
            Options: {
                BorderStyle: "full",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["name", "asc"],
                GlobalSearch: false,
                RowSelection: "disabled",
            },
            TableRows: TableRows,
        })),
        Title: unwrap(Title_2),
    })), createElement(TableContainer, (Title_3 = t("general.tool_with_quantity"), {
        Content: createElement(Table, (TableRows_1 = toArray(toolWithQuantityElements), {
            ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (row_5) => {
                const matchValue_5 = getElement(row_5);
                return (matchValue_5 != null) ? (new CellContent(0, [matchValue_5.DisplayName])) : (new CellContent(0, [""]));
            }, "text", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_6) => ((row_6.tag === 0) ? (new CellContent(0, [""])) : ((row_6.tag === 2) ? (new CellContent(0, [int32ToString(row_6.fields[0].Quantity)])) : (new CellContent(0, [""])))), "none", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_7) => {
                const matchValue_6 = getElement(row_7);
                return (matchValue_6 != null) ? (new CellContent(0, [matchValue_6.InventoryNumber])) : (new CellContent(0, [""]));
            }, "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_8) => {
                const matchValue_7 = getElement(row_8);
                return (matchValue_7 != null) ? (new CellContent(0, [matchValue_7.Manufacturer])) : (new CellContent(0, [""]));
            }, "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_9) => {
                const matchValue_8 = getElement(row_9);
                return (matchValue_8 != null) ? (new CellContent(0, [matchValue_8.Model])) : (new CellContent(0, [""]));
            }, "text", "text")],
            Controls: empty(),
            Options: {
                BorderStyle: "full",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["name", "asc"],
                GlobalSearch: false,
                RowSelection: "disabled",
            },
            TableRows: TableRows_1,
        })),
        Title: unwrap(Title_3),
    })), createElement(TableContainer, (Title_4 = t("general.consumable"), {
        Content: createElement(Table, (TableRows_2 = toArray(consumableElements), {
            ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (row_10) => {
                const matchValue_9 = getElement(row_10);
                return (matchValue_9 != null) ? (new CellContent(0, [matchValue_9.DisplayName])) : (new CellContent(0, [""]));
            }, "text", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_11) => ((row_11.tag === 2) ? (new CellContent(0, [""])) : ((row_11.tag === 0) ? (new CellContent(0, [row_11.fields[0].Quantity.toString()])) : (new CellContent(0, [""])))), "none", "text"), new ColumnDefinition$1("unit", "", t("general.unit"), (row_12) => {
                const matchValue_10 = getElement(row_12);
                if (matchValue_10 != null) {
                    const matchValue_11 = matchValue_10.Unit;
                    return (matchValue_11 == null) ? (new CellContent(0, [t("unit.piece")])) : (new CellContent(0, [Helper_unitToHumanString(matchValue_11)]));
                }
                else {
                    return new CellContent(0, [""]);
                }
            }, "none", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_13) => {
                const matchValue_12 = getElement(row_13);
                return (matchValue_12 != null) ? (new CellContent(0, [matchValue_12.InventoryNumber])) : (new CellContent(0, [""]));
            }, "text", "text"), new ColumnDefinition$1("additionalName", "", t("consumable.additional_name"), (row_14) => {
                const matchValue_13 = getElement(row_14);
                return (matchValue_13 != null) ? (new CellContent(0, [matchValue_13.AdditionalName])) : (new CellContent(0, [""]));
            }, "text", "text")],
            Controls: empty(),
            Options: {
                BorderStyle: "full",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["name", "asc"],
                GlobalSearch: false,
                RowSelection: "disabled",
            },
            TableRows: TableRows_2,
        })),
        Title: unwrap(Title_4),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))));
}

export function DetailInventoryListView(props) {
    let patternInput;
    const init = init_1(props.DispolistId, props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.DispolistId]);
    const state_1 = patternInput[0];
    const matchValue = state_1.Dispolist;
    const matchValue_1 = state_1.Elements;
    let matchResult, dispolist, elements;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        dispolist = matchValue.fields[0];
        elements = matchValue_1.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(DetailView, {
                Dispatch: patternInput[1],
                Dispolist: dispolist,
                Elements: elements,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

