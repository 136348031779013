import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import Check_Circle from "../../public/assets/icons/Check_Circle.svg";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import Delete_Circle from "../../public/assets/icons/Delete_Circle.svg";

export function ToastAlert(props) {
    let elems_2;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append((props.ToastType === "error") ? append(singleton("bg-light-red"), delay(() => singleton("text-[#981B1B]"))) : append(singleton("bg-[#EFFEF4]"), delay(() => singleton("text-[#1E6939]"))), delay(() => append(singleton("rounded"), delay(() => append(singleton("p-3.5"), delay(() => append(singleton("text-sm"), delay(() => append(singleton("font-bold"), delay(() => append(singleton("flex"), delay(() => singleton("items-center"))))))))))))))))], (elems_2 = toList(delay(() => {
        let elems_1, elems;
        return append((props.ToastType === "success") ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["h-3.5", "w-3.5", "text-icon-green"])], (elems_1 = [Check_Circle()], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : singleton(createElement("div", createObj(ofArray([["className", join(" ", ["h-3.5", "w-3.5", "text-icon-red"])], (elems = [Delete_Circle()], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => singleton(createElement("div", {
            className: join(" ", ["mx-3.5"]),
            children: patternInput[0](props.Text),
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

