import { some } from "../fable-library-js.4.19.2/Option.js";
import { ofArray, singleton, toArray } from "../fable-library-js.4.19.2/List.js";
import { Union } from "../fable-library-js.4.19.2/Types.js";
import { union_type, int32_type, string_type } from "../fable-library-js.4.19.2/Reflection.js";
import { ProgramModule_setState, ProgramModule_map, ProgramModule_mapErrorHandler } from "../Fable.Elmish.4.2.0/program.fs.js";
import { defaultOf, uncurry3 } from "../fable-library-js.4.19.2/Util.js";
import { Sub_map, Sub_batch } from "../Fable.Elmish.4.2.0/sub.fs.js";
import { extractState } from "remotedev";
import { last } from "../fable-library-js.4.19.2/Array.js";

export function Debugger_showError(msgs) {
    console.error(some("[ELMISH DEBUGGER]"), ...toArray(msgs));
}

export function Debugger_showWarning(msgs) {
    console.warn(some("[ELMISH DEBUGGER]"), ...toArray(msgs));
}

export class Debugger_ConnectionOptions extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ViaExtension", "Remote", "Secure"];
    }
}

export function Debugger_ConnectionOptions_$reflection() {
    return union_type("Elmish.Debug.Debugger.ConnectionOptions", [], Debugger_ConnectionOptions, () => [[], [["address", string_type], ["port", int32_type]], [["address", string_type], ["port", int32_type]]]);
}

export function Program_withDebuggerUsing(deflater, inflater, connection, program) {
    return ProgramModule_mapErrorHandler((userOnError, tupledArg) => {
        const text = tupledArg[0];
        const ex_1 = tupledArg[1];
        userOnError([text, ex_1]);
        connection.error(text + ex_1.message);
    }, ProgramModule_map((userInit, a) => {
        const patternInput = userInit(a);
        const model = patternInput[0];
        connection.init(deflater(model), undefined);
        return [model, patternInput[1]];
    }, (userUpdate, msg, model_1) => {
        const patternInput_1 = userUpdate(msg)(model_1);
        const model$0027 = patternInput_1[0];
        connection.send(msg, deflater(model$0027));
        return [model$0027, patternInput_1[1]];
    }, uncurry3((x_1) => x_1), uncurry3((x_2) => x_2), (userSubscribe, model_2) => Sub_batch(ofArray([singleton([singleton("debugger"), (dispatch) => {
        const unsub = connection.subscribe((_arg) => {
            if (_arg.type === "DISPATCH") {
                const msg_2 = _arg;
                try {
                    const matchValue = msg_2.payload.type;
                    switch (matchValue) {
                        case "JUMP_TO_ACTION":
                        case "JUMP_TO_STATE": {
                            const state = inflater(extractState(msg_2));
                            ProgramModule_setState(program)(state)(dispatch);
                            break;
                        }
                        case "IMPORT_STATE": {
                            const state_1 = last(msg_2.payload.nextLiftedState.computedStates);
                            const state_2 = inflater(state_1.state);
                            ProgramModule_setState(program)(state_2)(dispatch);
                            connection.send(defaultOf(), msg_2.payload.nextLiftedState);
                            break;
                        }
                        default:
                            undefined;
                    }
                }
                catch (ex) {
                    Debugger_showError(ofArray(["Unable to process monitor command", ex.message, msg_2]));
                }
            }
        });
        return {
            Dispose() {
                unsub();
            },
        };
    }]), Sub_map("user", (x) => x, userSubscribe(model_2))])), (tupledArg_1) => [tupledArg_1[0], tupledArg_1[1]], program));
}

