import { createElement, useState } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { nativeType, useDrop } from "../Bindings/Fable.ReactDnD.js";
import { item, equalsWith } from "../fable_modules/fable-library-js.4.19.2/Array.js";
import { createObj, stringHash, defaultOf, equals } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { tryFind, append as append_1, singleton as singleton_1, ofArray, contains } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Dialog } from "../Components/Dialog.js";
import { isNullOrEmpty, join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextButton } from "../Components/Button.js";
import { unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { ImageDecorator, CommonProps } from "../SharedComponents/ReactImageViewer.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import react_viewer from "react-viewer";

export function ImageUploadDialog(props) {
    let Title, elems_3, elems_1, elems, elems_2;
    const t = useTranslation()[0];
    const patternInput_1 = useState(undefined);
    const setCurrentFile = patternInput_1[1];
    const currentFile = patternInput_1[0];
    const dependencies = [props.IsOpen];
    reactApi.useEffect(() => {
        setCurrentFile((_arg_1) => undefined);
    }, dependencies);
    const uploadFile = (file) => {
        setCurrentFile((_arg_2) => file);
        const img = document.getElementById("img-preview");
        const url = URL.createObjectURL(file);
        img.src = url;
    };
    const patternInput_2 = useDrop({
        accept: nativeType.FILE,
        collect: (monitor) => {
            const isOver = monitor.isOver();
            return {
                canDrop: monitor.canDrop(),
                isOver: isOver,
            };
        },
        drop: (draggedFile) => {
            let testExpr, testExpr_1;
            const matchValue = draggedFile.files;
            if (matchValue == null) {
            }
            else if ((testExpr = matchValue, !equalsWith(equals, testExpr, defaultOf()) && (testExpr.length === 0))) {
            }
            else if ((testExpr_1 = matchValue, !equalsWith(equals, testExpr_1, defaultOf()) && (testExpr_1.length === 1))) {
                const file_1 = item(0, matchValue);
                if (contains(file_1.type, ofArray(["image/jpeg", "image/png"]), {
                    Equals: (x_2, y_2) => (x_2 === y_2),
                    GetHashCode: stringHash,
                })) {
                    uploadFile(file_1);
                }
            }
        },
    });
    const dropData = patternInput_2[0];
    return createElement(Dialog, (Title = t("general.upload_image"), {
        Body: singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("flex"), delay(() => append(singleton("flex-col"), delay(() => append(singleton("justify-center"), delay(() => append(singleton("items-center"), delay(() => append(singleton("mt-4"), delay(() => append(singleton("mb-4"), delay(() => append(singleton("rounded-lg"), delay(() => append(singleton("border"), delay(() => append(singleton("border-dashed"), delay(() => append(singleton("border-primary"), delay(() => ((dropData.canDrop && dropData.isOver) ? singleton("bg-background") : empty()))))))))))))))))))))))))], ["ref", patternInput_2[1]], (elems_1 = [createElement("img", {
            className: join(" ", toList(delay(() => append(singleton("shadow"), delay(() => append(singleton("rounded"), delay(() => append(singleton("border-none"), delay(() => append(singleton("max-h-64"), delay(() => ((currentFile != null) ? singleton("mt-4") : empty())))))))))))),
            id: "img-preview",
        }), createElement("div", createObj(ofArray([["className", join(" ", ["mt-4", "mb-4"])], (elems = [createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.select_file"),
            OnClick: () => {
                const element = document.getElementById("file-upload");
                element.click();
            },
            TestId: "open-file-explorer-test-id",
            Variant: "blueButton",
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "justify-center"])], (elems_2 = [createElement("input", {
            id: "file-upload",
            className: join(" ", ["hidden"]),
            type: "file",
            accept: ".jpg, .jpeg, .png",
            onChange: (ev) => {
                const files = ev.target.files;
                if (!(files == null) && (files.length > 0)) {
                    uploadFile(files.item(0));
                }
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))),
        Controls: toList(delay(() => {
            let elems_4;
            return append((props.ImageUrl == null) ? (empty()) : singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mr-auto"])], (elems_4 = [createElement(TextButton, {
                ComponentState: "enabled",
                Label: t("general.delete"),
                OnClick: props.OnDeleteImage,
                TestId: "delete-image-test-id",
                Variant: "redButton",
            })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => append(singleton(createElement(TextButton, {
                ComponentState: "enabled",
                Label: t("general.cancel"),
                OnClick: props.OnClose,
                TestId: "close-upload-dialog-test-id",
                Variant: "blueButton",
            })), delay(() => {
                let Label_3;
                return singleton(createElement(TextButton, (Label_3 = t("general.upload_image"), {
                    ComponentState: (currentFile == null) ? "disabled" : "enabled",
                    Label: Label_3,
                    OnClick: () => {
                        if (currentFile == null) {
                        }
                        else {
                            props.OnUploadImage(currentFile);
                        }
                    },
                    TestId: "upload-file-button-test-id",
                    Variant: "blueButton",
                })));
            }))));
        })),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

export function ImageWidget(props) {
    let elems_1;
    const patternInput = useTranslation();
    const getImage = (_arg) => {
        if (isNullOrEmpty(props.ImageUrl)) {
            return undefined;
        }
        else {
            return props.ImageUrl;
        }
    };
    const patternInput_1 = useState(getImage());
    const imageUrl = patternInput_1[0];
    const patternInput_2 = reactApi.useState(false);
    const setIsImageViewerOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(false);
    const setIsDialogOpen = patternInput_3[1];
    const dependencies = [props.ImageUrl];
    reactApi.useEffect(() => {
        setIsDialogOpen(false);
        patternInput_1[1](getImage);
    }, dependencies);
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "items-center", "w-96"])], (elems_1 = toList(delay(() => append(singleton(createElement(ImageUploadDialog, {
        ImageUrl: unwrap(imageUrl),
        IsOpen: patternInput_3[0],
        OnClose: () => {
            setIsDialogOpen(false);
        },
        OnDeleteImage: props.OnDeleteImage,
        OnUploadImage: props.OnUploadImage,
    })), delay(() => {
        let matchValue, imageUrl_1;
        return append((matchValue = imageUrl, (matchValue != null) ? ((imageUrl_1 = matchValue, append(singleton(createElement("img", {
            "data-test-id": "img-viewer-preview",
            className: join(" ", ["cursor-pointer", "h-64", "object-cover"]),
            src: imageUrl_1,
            onClick: (_arg_4) => {
                setIsImageViewerOpen(true);
            },
        })), delay(() => {
            let props_3, props_4;
            return singleton((props_3 = ofArray([new CommonProps(3, ["img-viewer"]), new CommonProps(2, [[new ImageDecorator(imageUrl_1)]]), new CommonProps(0, [patternInput_2[0]]), new CommonProps(4, [() => {
                setIsImageViewerOpen(false);
            }])]), (props_4 = keyValueList(append_1(props_3, toList(delay(() => append(singleton(new CommonProps(6, [false])), delay(() => {
                const matchValue_1 = tryFind((prop) => {
                    if (prop.tag === 4) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }, props_3);
                let matchResult, onClose;
                if (matchValue_1 != null) {
                    if (matchValue_1.tag === 4) {
                        matchResult = 0;
                        onClose = matchValue_1.fields[0];
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0:
                        return singleton(new CommonProps(5, [onClose]));
                    default: {
                        return empty();
                    }
                }
            }))))), 1), react.createElement(react_viewer, props_4))));
        })))) : singleton(createElement("img", {
            "data-test-id": "img-viewer-preview-empty",
            className: join(" ", ["h-64", "object-cover"]),
            src: "/assets/images/placeholder-image-light.png",
            onClick: (_arg_3) => {
                setIsImageViewerOpen(true);
            },
        }))), delay(() => {
            let elems;
            return props.CanEditImage ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems = [createElement(TextButton, {
                ComponentState: "enabled",
                Label: patternInput[0]("general.image_action"),
                OnClick: () => {
                    setIsDialogOpen(true);
                },
                TestId: "picture-upload-button",
                Variant: "blueButton",
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
        }));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

