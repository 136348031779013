import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, record_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class SupportMessageDto extends Record {
    constructor(PhoneNumber, Message) {
        super();
        this.PhoneNumber = PhoneNumber;
        this.Message = Message;
    }
}

export function SupportMessageDto_$reflection() {
    return record_type("Shared.Support.SupportMessageDto", [], SupportMessageDto, () => [["PhoneNumber", string_type], ["Message", string_type]]);
}

export class SupportMessageResponse extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Sent"];
    }
}

export function SupportMessageResponse_$reflection() {
    return union_type("Shared.Support.SupportMessageResponse", [], SupportMessageResponse, () => [[]]);
}

