import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, int32_type, array_type, anonRecord_type, class_type, list_type, record_type, bool_type, option_type, lambda_type, unit_type, string_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ClientApiResponse$1_$reflection, LabelId_$reflection, FormKind_$reflection, FormState_$reflection, Customer_$reflection, TokenDataDto_$reflection, Label_$reflection, RequestedValue$1_$reflection, ToolId_$reflection, StorageId_$reflection } from "../../../Shared/Shared.js";
import { ToolTypes_$reflection, ToolFormDto_$reflection } from "../../../Shared/Tool.js";
import { Storage_$reflection } from "../../../Shared/Storage.js";
import { PropertyConfigurationEnabledResponse_$reflection, CustomProperty_$reflection, PropertyConfiguration_$reflection } from "../../../Shared/PropertyConfiguration.js";
import { FileUploadResponse_$reflection } from "../../../Shared/File.js";

export class ModalState extends Record {
    constructor(OnSuccessCallback, SelectedStorageId, IsSelectedStorageIdNeeded) {
        super();
        this.OnSuccessCallback = OnSuccessCallback;
        this.SelectedStorageId = SelectedStorageId;
        this.IsSelectedStorageIdNeeded = IsSelectedStorageIdNeeded;
    }
}

export function ModalState_$reflection() {
    return record_type("Tools.Tool.Form.Types.ModalState", [], ModalState, () => [["OnSuccessCallback", lambda_type(string_type, unit_type)], ["SelectedStorageId", option_type(StorageId_$reflection())], ["IsSelectedStorageIdNeeded", bool_type]]);
}

export class State extends Record {
    constructor(ToolId, ToolForm, Storages, PropertyConfigurations, IsCustomPropertiesEnabled, Labels, Manufacturers, LastInspectionDate, PurchaseDate, WarrantyDate, UserData, Customer, SelectedFile, ModalState, FormState, FormKind) {
        super();
        this.ToolId = ToolId;
        this.ToolForm = ToolForm;
        this.Storages = Storages;
        this.PropertyConfigurations = PropertyConfigurations;
        this.IsCustomPropertiesEnabled = IsCustomPropertiesEnabled;
        this.Labels = Labels;
        this.Manufacturers = Manufacturers;
        this.LastInspectionDate = LastInspectionDate;
        this.PurchaseDate = PurchaseDate;
        this.WarrantyDate = WarrantyDate;
        this.UserData = UserData;
        this.Customer = Customer;
        this.SelectedFile = SelectedFile;
        this.ModalState = ModalState;
        this.FormState = FormState;
        this.FormKind = FormKind;
    }
}

export function State_$reflection() {
    return record_type("Tools.Tool.Form.Types.State", [], State, () => [["ToolId", option_type(ToolId_$reflection())], ["ToolForm", RequestedValue$1_$reflection(ToolFormDto_$reflection())], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["PropertyConfigurations", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["IsCustomPropertiesEnabled", RequestedValue$1_$reflection(bool_type)], ["Labels", RequestedValue$1_$reflection(list_type(Label_$reflection()))], ["Manufacturers", list_type(string_type)], ["LastInspectionDate", option_type(class_type("System.DateTime"))], ["PurchaseDate", option_type(class_type("System.DateTime"))], ["WarrantyDate", option_type(class_type("System.DateTime"))], ["UserData", TokenDataDto_$reflection()], ["Customer", Customer_$reflection()], ["SelectedFile", option_type(class_type("Browser.Types.File", undefined))], ["ModalState", option_type(ModalState_$reflection())], ["FormState", FormState_$reflection()], ["FormKind", FormKind_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetManufacturer", "SetDisplayName", "SetModel", "SetSerialNumber", "SetInventoryNumber", "SelectStorage", "SetStorageSpace", "SetComment", "SetLastInspectionDate", "AddCategoryId", "SetCustomProperties", "SetQrCode", "SetSupplier", "SetArticleNumber", "SetPrice", "SetPurchaseDate", "SetWarrantyDate", "SetCommercialDataComment", "SetPrinzingCheckNumber", "SaveTool", "UpdateTool", "FetchTool", "FetchCategories", "DeleteQRCodes", "ToolFetched", "ToolUpdated", "ToolCreated", "StoragesFetched", "LabelsFetched", "ManufacturersFetched", "PropertyConfigurationsFetched", "SetSelectedFile", "ImageUploaded", "CustomPropertiesEnabledFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Tools.Tool.Form.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", anonRecord_type(["label", string_type], ["value", StorageId_$reflection()])]], [["Item", string_type]], [["Item", string_type]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", option_type(array_type(anonRecord_type(["label", string_type], ["value", LabelId_$reflection()])))]], [["Item", list_type(CustomProperty_$reflection())]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", option_type(int32_type)]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", string_type]], [["Item", string_type]], [], [], [], [], [["Item", list_type(string_type)]], [["Item", ToolTypes_$reflection()]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", list_type(Storage_$reflection())]], [["Item", list_type(Label_$reflection())]], [["Item", list_type(string_type)]], [["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", option_type(class_type("Browser.Types.File", undefined))]], [["Item", FileUploadResponse_$reflection()]], [["Item", PropertyConfigurationEnabledResponse_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

