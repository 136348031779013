import { Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { ofNullable } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { singleton, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { fetchWithDecoder, addPrefix, token as token_2 } from "../Communication.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetch$ } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { utcNow, toLocalTime, toString } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { State, Msg } from "./Types.js";
import { RequestedValue$1 } from "../Shared/Shared.js";
import { getCustomerConfiguration } from "../Requests/Configuration.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { ImportHistoryEntry_$reflection } from "../Shared/Importer.js";
import { list_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";

export const exportSpecificToolCmd = Cmd_OfPromise_either((tupledArg) => {
    const body_1 = tupledArg[2];
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const tenant = ofNullable(localStorage.getItem("tenant"));
        const props = (body_1 == null) ? ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
            let matchValue_2;
            return append((matchValue_2 = token_2(undefined), (matchValue_2 == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                const matchValue_3 = tenant;
                if (matchValue_3 == null) {
                    return empty();
                }
                else {
                    return singleton(["X-ONE-Tenant", matchValue_3]);
                }
            }))));
        })), 0)])]) : ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [body_1]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
            let matchValue;
            return append((matchValue = token_2(undefined), (matchValue == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                const matchValue_1 = tenant;
                if (matchValue_1 == null) {
                    return empty();
                }
                else {
                    return singleton(["X-ONE-Tenant", matchValue_1]);
                }
            }))));
        })), 0)])]);
        return fetch$(addPrefix(tupledArg[0]), props).then((_arg) => (Promise.resolve([_arg, tupledArg[1]])));
    }));
}, ["/api/export/tools", `tool_export_${toString(toLocalTime(utcNow()), "dd.MM.yyyy_HH_mm_ss")}.xlsx`, undefined], (tupledArg_1) => (new Msg(6, [tupledArg_1[0], tupledArg_1[1]])), (Item_2) => (new Msg(8, [Item_2])));

export const exportSimpleToolsCmd = Cmd_OfPromise_either((tupledArg) => {
    const body_1 = tupledArg[2];
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const tenant = ofNullable(localStorage.getItem("tenant"));
        const props = (body_1 == null) ? ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
            let matchValue_2;
            return append((matchValue_2 = token_2(undefined), (matchValue_2 == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                const matchValue_3 = tenant;
                if (matchValue_3 == null) {
                    return empty();
                }
                else {
                    return singleton(["X-ONE-Tenant", matchValue_3]);
                }
            }))));
        })), 0)])]) : ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [body_1]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
            let matchValue;
            return append((matchValue = token_2(undefined), (matchValue == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                const matchValue_1 = tenant;
                if (matchValue_1 == null) {
                    return empty();
                }
                else {
                    return singleton(["X-ONE-Tenant", matchValue_1]);
                }
            }))));
        })), 0)])]);
        return fetch$(addPrefix(tupledArg[0]), props).then((_arg) => (Promise.resolve([_arg, tupledArg[1]])));
    }));
}, ["/api/export/tools-with-quantity", `tool_with_quantity_export_${toString(toLocalTime(utcNow()), "dd.MM.yyyy_HH_mm_ss")}.xlsx`, undefined], (tupledArg_1) => (new Msg(6, [tupledArg_1[0], tupledArg_1[1]])), (Item_2) => (new Msg(8, [Item_2])));

export const exportConsumablesCmd = Cmd_OfPromise_either((tupledArg) => {
    const body_1 = tupledArg[2];
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const tenant = ofNullable(localStorage.getItem("tenant"));
        const props = (body_1 == null) ? ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
            let matchValue_2;
            return append((matchValue_2 = token_2(undefined), (matchValue_2 == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                const matchValue_3 = tenant;
                if (matchValue_3 == null) {
                    return empty();
                }
                else {
                    return singleton(["X-ONE-Tenant", matchValue_3]);
                }
            }))));
        })), 0)])]) : ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [body_1]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
            let matchValue;
            return append((matchValue = token_2(undefined), (matchValue == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                const matchValue_1 = tenant;
                if (matchValue_1 == null) {
                    return empty();
                }
                else {
                    return singleton(["X-ONE-Tenant", matchValue_1]);
                }
            }))));
        })), 0)])]);
        return fetch$(addPrefix(tupledArg[0]), props).then((_arg) => (Promise.resolve([_arg, tupledArg[1]])));
    }));
}, ["/api/export/consumables", `consumable_export_${toString(toLocalTime(utcNow()), "dd.MM.yyyy_HH_mm_ss")}.xlsx`, undefined], (tupledArg_1) => (new Msg(6, [tupledArg_1[0], tupledArg_1[1]])), (Item_2) => (new Msg(8, [Item_2])));

export function init(currentUser) {
    return [new State(false, undefined, new RequestedValue$1(0, []), currentUser), Cmd_batch(ofArray([getCustomerConfiguration((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(8, [Item_1]))), Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton_1(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(ImportHistoryEntry_$reflection())));
    })))), "/api/import/history", (Item_2) => (new Msg(1, [Item_2])), (Item_3) => (new Msg(8, [Item_3])))]))];
}

export function extractBlob(response, filename) {
    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response.blob().then((_arg) => (Promise.resolve([_arg, filename]))))));
}

export function getBlob(response, filename) {
    return Cmd_OfPromise_either((tupledArg) => extractBlob(tupledArg[0], tupledArg[1]), [response, filename], (tupledArg_1) => (new Msg(7, [tupledArg_1[0], tupledArg_1[1]])), (Item_2) => (new Msg(8, [Item_2])));
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.Loading, state.CustomerConfiguration, new RequestedValue$1(1, [msg.fields[0]]), state.CurrentUser), Cmd_none()];
        case 2:
            return [new State(state.Loading, msg.fields[0], state.ImportHistory, state.CurrentUser), Cmd_none()];
        case 3:
            return [new State(true, state.CustomerConfiguration, state.ImportHistory, state.CurrentUser), exportSpecificToolCmd];
        case 4:
            return [new State(true, state.CustomerConfiguration, state.ImportHistory, state.CurrentUser), exportSimpleToolsCmd];
        case 5:
            return [new State(true, state.CustomerConfiguration, state.ImportHistory, state.CurrentUser), exportConsumablesCmd];
        case 6:
            return [new State(false, state.CustomerConfiguration, state.ImportHistory, state.CurrentUser), getBlob(msg.fields[0], msg.fields[1])];
        case 7: {
            const url_3 = window.URL.createObjectURL(msg.fields[0]);
            const element = document.createElement("a");
            element.setAttribute("href", url_3);
            element.setAttribute("download", msg.fields[1]);
            document.body.appendChild(element);
            element.click();
            window.URL.revokeObjectURL(url_3);
            element.remove();
            return [state, Cmd_none()];
        }
        case 8:
            return [new State(false, state.CustomerConfiguration, state.ImportHistory, state.CurrentUser), Cmd_none()];
        default:
            return [new State(state.Loading, state.CustomerConfiguration, new RequestedValue$1(0, []), state.CurrentUser), Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton_1(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
                const pr = response.arrayBuffer();
                return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(ImportHistoryEntry_$reflection())));
            })))), "/api/import/history", (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(8, [Item_1])))];
    }
}

