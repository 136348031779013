import { EmptyResponse_$reflection, RequestedValue$1 } from "../../Shared/Shared.js";
import { filter as filter_1, ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { string_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Location_$reflection } from "../../Shared/Location.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../CommunicationV2.js";
import { PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { ValidationOnGetRequest } from "../../ErrorHandling.js";
import { UserConfiguration as UserConfiguration_1, UserConfiguration_$reflection } from "../../Shared/User.js";
import { getCustomerConfiguration } from "../../Requests/Configuration.js";
import { getAccountSettings } from "../../Requests/Customer.js";
import { add } from "../../fable_modules/fable-library-js.4.19.2/Map.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";

export function initState(userData) {
    return new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), userData, userData.Customer, new RequestedValue$1(0, []), empty(), new RequestedValue$1(0, []), undefined, new RequestedValue$1(0, []), new RequestedValue$1(0, []));
}

export function init(userData) {
    return [initState(userData), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(12, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Location_$reflection())));
    })))), "/api/locations", (Item_2) => (new Msg(4, [Item_2])), (Item_3) => (new Msg(12, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_3 = _arg;
            return (result_3.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_3.fields[0]);
            })())) : (Promise.resolve(result_3.fields[0]));
        });
    })), "/api/property-configurations", (Item_4) => (new Msg(5, [Item_4])), (Item_5) => (new Msg(12, [Item_5]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_9), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
        const pr_3 = response_3.arrayBuffer();
        return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), UserConfiguration_$reflection()));
    })))), "/api/users/configuration", (Item_6) => (new Msg(8, [Item_6])), (Item_7) => (new Msg(12, [Item_7]))), getCustomerConfiguration((Item_8) => (new Msg(9, [Item_8])), (Item_9) => (new Msg(12, [Item_9]))), getAccountSettings((Item_10) => (new Msg(10, [Item_10])), (Item_11) => (new Msg(12, [Item_11]))), Cmd_OfPromise_either((url_12) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_12), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_4) => {
        const pr_4 = response_4.arrayBuffer();
        return pr_4.then((blob_4) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_4)), list_type(string_type)));
    })))), "/api/tools/reservations/name-suggestions", (Item_12) => (new Msg(11, [Item_12])), (Item_13) => (new Msg(12, [Item_13])))]))];
}

export function update(msg, state) {
    let userConfiguration_1;
    switch (msg.tag) {
        case 1:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.Storages, state.Locations, state.UserConfiguration, state.CustomerConfiguration, state.Receivers, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PropertyConfigurations, state.SelectedFilter, state.AccountSettings, state.ReservationNames), Cmd_none()];
        case 2:
            return [new State(state.Tools, new RequestedValue$1(1, [msg.fields[0]]), state.Locations, state.UserConfiguration, state.CustomerConfiguration, state.Receivers, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PropertyConfigurations, state.SelectedFilter, state.AccountSettings, state.ReservationNames), Cmd_none()];
        case 3:
            return [new State(state.Tools, state.Storages, state.Locations, state.UserConfiguration, state.CustomerConfiguration, state.Receivers, state.UserData, state.Customer, new RequestedValue$1(1, [msg.fields[0]]), state.SelectedTools, state.PropertyConfigurations, state.SelectedFilter, state.AccountSettings, state.ReservationNames), Cmd_none()];
        case 4:
            return [new State(state.Tools, state.Storages, new RequestedValue$1(1, [msg.fields[0]]), state.UserConfiguration, state.CustomerConfiguration, state.Receivers, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PropertyConfigurations, state.SelectedFilter, state.AccountSettings, state.ReservationNames), Cmd_none()];
        case 5:
            return [new State(state.Tools, state.Storages, state.Locations, state.UserConfiguration, state.CustomerConfiguration, state.Receivers, state.UserData, state.Customer, state.Labels, state.SelectedTools, new RequestedValue$1(1, [filter_1((propertyConfiguration) => {
                const matchValue = propertyConfiguration.Visibility;
                switch (matchValue.tag) {
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        return false;
                    default:
                        return true;
                }
            }, msg.fields[0])]), state.SelectedFilter, state.AccountSettings, state.ReservationNames), Cmd_none()];
        case 6: {
            const matchValue_1 = state.UserConfiguration;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const userConfiguration = matchValue_1.fields[0];
                return [state, (userConfiguration_1 = (new UserConfiguration_1(add("tool-table", msg.fields[0], userConfiguration.TableConfiguration), userConfiguration.QuickOverviewConfiguration)), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response_2) => {
                    const pr = response_2.arrayBuffer();
                    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), EmptyResponse_$reflection()));
                })))), ["/api/users/configuration", toString(0, Auto_generateBoxedEncoder_437914C6(UserConfiguration_$reflection(), undefined, undefined, undefined)(userConfiguration_1))], (Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(12, [Item_1]))))];
            }
        }
        case 7:
            return [state, Cmd_none()];
        case 8:
            return [new State(state.Tools, state.Storages, state.Locations, new RequestedValue$1(1, [msg.fields[0]]), state.CustomerConfiguration, state.Receivers, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PropertyConfigurations, state.SelectedFilter, state.AccountSettings, state.ReservationNames), Cmd_none()];
        case 9:
            return [new State(state.Tools, state.Storages, state.Locations, state.UserConfiguration, new RequestedValue$1(1, [msg.fields[0]]), state.Receivers, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PropertyConfigurations, state.SelectedFilter, state.AccountSettings, state.ReservationNames), Cmd_none()];
        case 10:
            return [new State(state.Tools, state.Storages, state.Locations, state.UserConfiguration, state.CustomerConfiguration, state.Receivers, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PropertyConfigurations, state.SelectedFilter, new RequestedValue$1(1, [msg.fields[0]]), state.ReservationNames), Cmd_none()];
        case 11:
            return [new State(state.Tools, state.Storages, state.Locations, state.UserConfiguration, state.CustomerConfiguration, state.Receivers, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PropertyConfigurations, state.SelectedFilter, state.AccountSettings, new RequestedValue$1(1, [filter_1((reservationName) => (reservationName !== ""), msg.fields[0])])), Cmd_none()];
        case 12:
            return [state, Cmd_none()];
        default:
            return [new State(state.Tools, state.Storages, state.Locations, state.UserConfiguration, state.CustomerConfiguration, state.Receivers, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PropertyConfigurations, msg.fields[0], state.AccountSettings, state.ReservationNames), Cmd_none()];
    }
}

