import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { QRCodeScanResultResponse_$reflection } from "../../Shared/Tool.js";
import { Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { unwrapConsumableId, unwrapToolId } from "../../Shared/Helper.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { SetId__get_unwrap } from "../../Shared/Shared.js";
import { Cmd_errorToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Dialog } from "../../Components/Dialog.js";
import { Input } from "../../Components/Input.js";
import { TextButton } from "../../Components/Button.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetValue", "FetchToolIdByQRCode", "ToolIdByQRCodeFetched", "CloseDialog", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Widgets.Dialogs.QRCodeSearchDialog.Msg", [], Msg, () => [[["Item", string_type]], [], [["Item", QRCodeScanResultResponse_$reflection()]], [], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(OnClose, Value, FormState) {
        super();
        this.OnClose = OnClose;
        this.Value = Value;
        this.FormState = FormState;
    }
}

function State_$reflection() {
    return record_type("Widgets.Dialogs.QRCodeSearchDialog.State", [], State, () => [["OnClose", lambda_type(unit_type, unit_type)], ["Value", string_type], ["FormState", string_type]]);
}

function getToolIdByQRCodeId(qrCodeId) {
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_1), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), QRCodeScanResultResponse_$reflection()));
    })))), `/api/tools/${qrCodeId}/toolidbyqrcode`, (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(4, [Item_1])));
}

function update(msg, state) {
    let id_1, id_2, fullPath_8, id;
    switch (msg.tag) {
        case 3: {
            state.OnClose();
            return [state, Cmd_none()];
        }
        case 1:
            return [new State(state.OnClose, state.Value, "disabled"), getToolIdByQRCodeId(state.Value)];
        case 2: {
            const response = msg.fields[0];
            return [new State(state.OnClose, state.Value, "enabled"), ((response.tag === 1) ? ((id_1 = unwrapToolId(response.fields[0]), [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg_1) => {
                RouterModule_nav(singleton(`/toolswithquantity/${id_1}`), 1, 1);
            }), singleton((dispatch_1) => {
                dispatch_1(new Msg(3, []));
            })]))])) : ((response.tag === 3) ? ((id_2 = unwrapConsumableId(response.fields[0]), [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg_2) => {
                RouterModule_nav(singleton(`/consumables/${id_2}`), 1, 1);
            }), singleton((dispatch_2) => {
                dispatch_2(new Msg(3, []));
            })]))])) : ((response.tag === 2) ? [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg_3) => {
                RouterModule_nav(singleton(`/receivers/${response.fields[0]}`), 1, 1);
            }), singleton((dispatch_3) => {
                dispatch_3(new Msg(3, []));
            })]))] : ((response.tag === 4) ? [state, Cmd_batch(ofArray([(fullPath_8 = (`/sets/${SetId__get_unwrap(response.fields[0])}`), Cmd_ofEffect((_arg_4) => {
                RouterModule_nav(singleton(fullPath_8), 1, 1);
            })), singleton((dispatch_4) => {
                dispatch_4(new Msg(3, []));
            })]))] : ((response.tag === 5) ? [state, Cmd_errorToast("general.no_entry_found_with_code")] : ((id = unwrapToolId(response.fields[0]), [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                RouterModule_nav(singleton(`/tools/${id}`), 1, 1);
            }), singleton((dispatch) => {
                dispatch(new Msg(3, []));
            })]))])))))))[1]];
        }
        case 4:
            return [state, Cmd_none()];
        default:
            return [new State(state.OnClose, msg.fields[0], state.FormState), Cmd_none()];
    }
}

function init(onClose) {
    return [new State(onClose, "", "enabled"), Cmd_none()];
}

function QRCodeDialog(props) {
    let elems, Title;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["data-test-id", "search-qr-code-dialog-body"], (elems = [createElement(Dialog, (Title = t("search_qr_code_dialog.title"), {
        Body: singleton(createElement(Input, {
            ComponentState: props.FormState,
            Label: t("search_qr_code_dialog.code"),
            OnChange: props.SetValue,
            TestId: "",
            Value: props.Value,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: props.FormState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-qr-code-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: props.FormState,
            Label: t("general.search"),
            OnClick: props.OnSearch,
            TestId: "search-qr-code-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }))], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function SearchQRCodeDialog(props) {
    let patternInput;
    const init_1 = init(props.OnClose);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement(QRCodeDialog, {
        FormState: state_1.FormState,
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        OnSearch: () => {
            dispatch(new Msg(1, []));
        },
        SetValue: (value) => {
            dispatch(new Msg(0, [value]));
        },
        Value: state_1.Value,
    });
}

