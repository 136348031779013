import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, lambda_type, unit_type, array_type, int32_type, bool_type, record_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class ImageDecorator extends Record {
    constructor(src) {
        super();
        this.src = src;
    }
}

export function ImageDecorator_$reflection() {
    return record_type("SharedComponents.ReactImageViewer.ImageDecorator", [], ImageDecorator, () => [["src", string_type]]);
}

export class CommonProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Visible", "ActiveIndex", "Images", "ClassName", "OnClose", "OnMaskClick", "Scalable"];
    }
}

export function CommonProps_$reflection() {
    return union_type("SharedComponents.ReactImageViewer.CommonProps", [], CommonProps, () => [[["Item", bool_type]], [["Item", int32_type]], [["Item", array_type(ImageDecorator_$reflection())]], [["Item", string_type]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", bool_type]]]);
}

