import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { PostResponse$1_$reflection, CustomerConfigurationResponseResult_$reflection, ReceiverVisibilityConfiguration_$reflection, RequestedValue$1_$reflection, CustomerConfiguration_$reflection } from "../../Shared/Shared.js";
import { union_type, class_type, record_type, bool_type, option_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class State extends Record {
    constructor(CustomerConfiguration, Message, IsValidated) {
        super();
        this.CustomerConfiguration = CustomerConfiguration;
        this.Message = Message;
        this.IsValidated = IsValidated;
    }
}

export function State_$reflection() {
    return record_type("Settings.Permissions.Types.State", [], State, () => [["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["Message", option_type(string_type)], ["IsValidated", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateCustomerConfigurationPermissions", "UpdateReceiverVisibilityConfiguration", "UpdateCustomerConfiguration", "CustomerConfigurationFetched", "CustomerConfigurationUpdated", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Settings.Permissions.Types.Msg", [], Msg, () => [[["Item", CustomerConfiguration_$reflection()]], [["Item", ReceiverVisibilityConfiguration_$reflection()]], [], [["Item", CustomerConfiguration_$reflection()]], [["Item", PostResponse$1_$reflection(CustomerConfigurationResponseResult_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

