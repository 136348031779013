import { FormState__setValidationResponse_5219762A, LabelId__get_unwrap, FormState__startLoading, StorageId__get_unwrap, Label_$reflection, FormState_get_empty, RequestedValue$1, CommercialDataFormDto } from "../../Shared/Shared.js";
import { filter, item, length, map as map_1, head, ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { ConsumableUpdateFormDto, ConsumableCreateFormDto, ConsumableFormStockPerStorage } from "../../Shared/Consumable.js";
import { Msg, ConsumableForm, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../CommunicationV2.js";
import { PropertyConfigurationEnabledResponse_$reflection, PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { ValidationOnGetRequest } from "../../ErrorHandling.js";
import { updateConsumable, createConsumable, getConsumable } from "../../Requests/Consumable.js";
import { ofNullable, map, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { toISOString } from "../../Common.js";
import { uploadImage } from "../../Requests/Files.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { map as map_2 } from "../../fable_modules/fable-library-js.4.19.2/Array.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";

export const emptyCommercialData = new CommercialDataFormDto(undefined, "", "", undefined, undefined, "");

export const emptyConsumableForm = new ConsumableCreateFormDto("", "", "", "piece", "", singleton(new ConsumableFormStockPerStorage("", "", 0, undefined, empty())), empty(), emptyCommercialData, "", empty());

export function initialModel(userData) {
    return new State(undefined, new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), undefined, undefined, undefined, userData, userData.Customer, undefined, FormState_get_empty());
}

export function initNewView(userData) {
    let bind$0040;
    return [(bind$0040 = initialModel(userData), new State(bind$0040.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [emptyConsumableForm])]), bind$0040.PropertyConfigurations, bind$0040.Storages, bind$0040.Labels, bind$0040.IsCustomPropertiesEnabled, bind$0040.StorageStock, bind$0040.PurchaseDate, bind$0040.WarrantyDate, bind$0040.UserData, bind$0040.Customer, bind$0040.SelectedFile, bind$0040.FormState)), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item) => (new Msg(27, [Item])), (Item_1) => (new Msg(31, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Label_$reflection())));
    })))), "/api/labels", (Item_2) => (new Msg(28, [Item_2])), (Item_3) => (new Msg(31, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_3 = _arg;
            return (result_3.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_3.fields[0]);
            })())) : (Promise.resolve(result_3.fields[0]));
        });
    })), "/api/property-configurations", (Item_4) => (new Msg(29, [Item_4])), (Item_5) => (new Msg(31, [Item_5]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_11;
        return ((url_11 = addPrefix_1(url_9), (refreshOn_1) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
            const pr_3 = response_3.arrayBuffer();
            return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_5 = _arg_1;
            return (result_5.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_5.fields[0]);
            })())) : (Promise.resolve(result_5.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_6) => (new Msg(30, [Item_6])), (Item_7) => (new Msg(31, [Item_7])))]))];
}

export function initCopyView(consumableId, userData) {
    return [initialModel(userData), Cmd_batch(ofArray([getConsumable((Item) => (new Msg(24, [Item])), (Item_1) => (new Msg(31, [Item_1])), consumableId), Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_2) => (new Msg(27, [Item_2])), (Item_3) => (new Msg(31, [Item_3]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Label_$reflection())));
    })))), "/api/labels", (Item_4) => (new Msg(28, [Item_4])), (Item_5) => (new Msg(31, [Item_5]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_3 = _arg;
            return (result_3.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_3.fields[0]);
            })())) : (Promise.resolve(result_3.fields[0]));
        });
    })), "/api/property-configurations", (Item_6) => (new Msg(29, [Item_6])), (Item_7) => (new Msg(31, [Item_7]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_11;
        return ((url_11 = addPrefix_1(url_9), (refreshOn_1) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
            const pr_3 = response_3.arrayBuffer();
            return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_5 = _arg_1;
            return (result_5.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_5.fields[0]);
            })())) : (Promise.resolve(result_5.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_8) => (new Msg(30, [Item_8])), (Item_9) => (new Msg(31, [Item_9])))]))];
}

export function initEditView(consumableId, userData, customer) {
    return [initialModel(userData), Cmd_batch(ofArray([getConsumable((Item) => (new Msg(23, [Item])), (Item_1) => (new Msg(31, [Item_1])), consumableId), Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_2) => (new Msg(27, [Item_2])), (Item_3) => (new Msg(31, [Item_3]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Label_$reflection())));
    })))), "/api/labels", (Item_4) => (new Msg(28, [Item_4])), (Item_5) => (new Msg(31, [Item_5]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_3 = _arg;
            return (result_3.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_3.fields[0]);
            })())) : (Promise.resolve(result_3.fields[0]));
        });
    })), "/api/property-configurations", (Item_6) => (new Msg(29, [Item_6])), (Item_7) => (new Msg(31, [Item_7]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_11;
        return ((url_11 = addPrefix_1(url_9), (refreshOn_1) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
            const pr_3 = response_3.arrayBuffer();
            return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_5 = _arg_1;
            return (result_5.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_5.fields[0]);
            })())) : (Promise.resolve(result_5.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_8) => (new Msg(30, [Item_8])), (Item_9) => (new Msg(31, [Item_9])))]))];
}

export function update(msg, state) {
    let bind$0040, bind$0040_1, bind$0040_3, bind$0040_2, bind$0040_5, bind$0040_4, bind$0040_7, bind$0040_6, bind$0040_9, bind$0040_8, bind$0040_10, bind$0040_11, WarrantyDate, bind$0040_12, WarrantyDate_1, bind$0040_13, bind$0040_14, WarrantyDate_2, xs, xs_2;
    switch (msg.tag) {
        case 1: {
            const value_1 = msg.fields[0];
            const matchValue_1 = state.ConsumablesForm;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (matchValue_1.fields[0].tag === 1) {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(1, [new ConsumableUpdateFormDto(matchValue_1.fields[0].fields[0].Name, value_1, matchValue_1.fields[0].fields[0].Description, matchValue_1.fields[0].fields[0].Unit, matchValue_1.fields[0].fields[0].ImageUrl, matchValue_1.fields[0].fields[0].CategoryIds, matchValue_1.fields[0].fields[0].CommercialData, matchValue_1.fields[0].fields[0].InventoryNumber, matchValue_1.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(matchValue_1.fields[0].fields[0].Name, value_1, matchValue_1.fields[0].fields[0].Description, matchValue_1.fields[0].fields[0].Unit, matchValue_1.fields[0].fields[0].ImageUrl, matchValue_1.fields[0].fields[0].StockPerStorage, matchValue_1.fields[0].fields[0].CategoryIds, matchValue_1.fields[0].fields[0].CommercialData, matchValue_1.fields[0].fields[0].InventoryNumber, matchValue_1.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
        }
        case 3: {
            const value_2 = msg.fields[0];
            const matchValue_2 = state.ConsumablesForm;
            if (matchValue_2.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (matchValue_2.fields[0].tag === 1) {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(1, [new ConsumableUpdateFormDto(matchValue_2.fields[0].fields[0].Name, matchValue_2.fields[0].fields[0].AdditionalName, matchValue_2.fields[0].fields[0].Description, matchValue_2.fields[0].fields[0].Unit, matchValue_2.fields[0].fields[0].ImageUrl, matchValue_2.fields[0].fields[0].CategoryIds, matchValue_2.fields[0].fields[0].CommercialData, value_2, matchValue_2.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(matchValue_2.fields[0].fields[0].Name, matchValue_2.fields[0].fields[0].AdditionalName, matchValue_2.fields[0].fields[0].Description, matchValue_2.fields[0].fields[0].Unit, matchValue_2.fields[0].fields[0].ImageUrl, matchValue_2.fields[0].fields[0].StockPerStorage, matchValue_2.fields[0].fields[0].CategoryIds, matchValue_2.fields[0].fields[0].CommercialData, value_2, matchValue_2.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
        }
        case 2: {
            const value_3 = msg.fields[0];
            const matchValue_3 = state.ConsumablesForm;
            if (matchValue_3.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (matchValue_3.fields[0].tag === 1) {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(1, [new ConsumableUpdateFormDto(matchValue_3.fields[0].fields[0].Name, matchValue_3.fields[0].fields[0].AdditionalName, value_3, matchValue_3.fields[0].fields[0].Unit, matchValue_3.fields[0].fields[0].ImageUrl, matchValue_3.fields[0].fields[0].CategoryIds, matchValue_3.fields[0].fields[0].CommercialData, matchValue_3.fields[0].fields[0].InventoryNumber, matchValue_3.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(matchValue_3.fields[0].fields[0].Name, matchValue_3.fields[0].fields[0].AdditionalName, value_3, matchValue_3.fields[0].fields[0].Unit, matchValue_3.fields[0].fields[0].ImageUrl, matchValue_3.fields[0].fields[0].StockPerStorage, matchValue_3.fields[0].fields[0].CategoryIds, matchValue_3.fields[0].fields[0].CommercialData, matchValue_3.fields[0].fields[0].InventoryNumber, matchValue_3.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
        }
        case 4: {
            const value_4 = msg.fields[0];
            const matchValue_4 = state.ConsumablesForm;
            if (matchValue_4.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (matchValue_4.fields[0].tag === 1) {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(1, [new ConsumableUpdateFormDto(matchValue_4.fields[0].fields[0].Name, matchValue_4.fields[0].fields[0].AdditionalName, matchValue_4.fields[0].fields[0].Description, value_4, matchValue_4.fields[0].fields[0].ImageUrl, matchValue_4.fields[0].fields[0].CategoryIds, matchValue_4.fields[0].fields[0].CommercialData, matchValue_4.fields[0].fields[0].InventoryNumber, matchValue_4.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(matchValue_4.fields[0].fields[0].Name, matchValue_4.fields[0].fields[0].AdditionalName, matchValue_4.fields[0].fields[0].Description, value_4, matchValue_4.fields[0].fields[0].ImageUrl, matchValue_4.fields[0].fields[0].StockPerStorage, matchValue_4.fields[0].fields[0].CategoryIds, matchValue_4.fields[0].fields[0].CommercialData, matchValue_4.fields[0].fields[0].InventoryNumber, matchValue_4.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
        }
        case 5: {
            const matchValue_5 = state.ConsumablesForm;
            let matchResult, consumable_10;
            if (matchValue_5.tag === 1) {
                if (matchValue_5.fields[0].tag === 0) {
                    matchResult = 0;
                    consumable_10 = matchValue_5.fields[0].fields[0];
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0:
                    return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(consumable_10.Name, consumable_10.AdditionalName, consumable_10.Description, consumable_10.Unit, consumable_10.ImageUrl, singleton((bind$0040 = head(consumable_10.StockPerStorage), new ConsumableFormStockPerStorage(StorageId__get_unwrap(msg.fields[0]), bind$0040.StorageSpace, bind$0040.Stock, bind$0040.MinimumStockQuantity, bind$0040.QRCodeIds))), consumable_10.CategoryIds, consumable_10.CommercialData, consumable_10.InventoryNumber, consumable_10.CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
                default:
                    return [state, Cmd_none()];
            }
        }
        case 6: {
            const matchValue_6 = state.ConsumablesForm;
            let matchResult_1, consumable_11;
            if (matchValue_6.tag === 1) {
                if (matchValue_6.fields[0].tag === 0) {
                    matchResult_1 = 0;
                    consumable_11 = matchValue_6.fields[0].fields[0];
                }
                else {
                    matchResult_1 = 1;
                }
            }
            else {
                matchResult_1 = 1;
            }
            switch (matchResult_1) {
                case 0:
                    return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(consumable_11.Name, consumable_11.AdditionalName, consumable_11.Description, consumable_11.Unit, consumable_11.ImageUrl, singleton((bind$0040_1 = head(consumable_11.StockPerStorage), new ConsumableFormStockPerStorage(bind$0040_1.StorageId, msg.fields[0], bind$0040_1.Stock, bind$0040_1.MinimumStockQuantity, bind$0040_1.QRCodeIds))), consumable_11.CategoryIds, consumable_11.CommercialData, consumable_11.InventoryNumber, consumable_11.CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
                default:
                    return [state, Cmd_none()];
            }
        }
        case 7:
            return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, msg.fields[0], state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
        case 13: {
            const value_8 = msg.fields[0];
            const matchValue_7 = state.ConsumablesForm;
            if (matchValue_7.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (matchValue_7.fields[0].tag === 1) {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(1, [new ConsumableUpdateFormDto(matchValue_7.fields[0].fields[0].Name, matchValue_7.fields[0].fields[0].AdditionalName, matchValue_7.fields[0].fields[0].Description, matchValue_7.fields[0].fields[0].Unit, matchValue_7.fields[0].fields[0].ImageUrl, matchValue_7.fields[0].fields[0].CategoryIds, (bind$0040_3 = matchValue_7.fields[0].fields[0].CommercialData, new CommercialDataFormDto(bind$0040_3.PurchaseDate, value_8, bind$0040_3.SupplierArticleNumber, bind$0040_3.PurchasePrice, bind$0040_3.WarrantyDate, bind$0040_3.Comment)), matchValue_7.fields[0].fields[0].InventoryNumber, matchValue_7.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(matchValue_7.fields[0].fields[0].Name, matchValue_7.fields[0].fields[0].AdditionalName, matchValue_7.fields[0].fields[0].Description, matchValue_7.fields[0].fields[0].Unit, matchValue_7.fields[0].fields[0].ImageUrl, matchValue_7.fields[0].fields[0].StockPerStorage, matchValue_7.fields[0].fields[0].CategoryIds, (bind$0040_2 = matchValue_7.fields[0].fields[0].CommercialData, new CommercialDataFormDto(bind$0040_2.PurchaseDate, value_8, bind$0040_2.SupplierArticleNumber, bind$0040_2.PurchasePrice, bind$0040_2.WarrantyDate, bind$0040_2.Comment)), matchValue_7.fields[0].fields[0].InventoryNumber, matchValue_7.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
        }
        case 14: {
            const value_9 = msg.fields[0];
            const matchValue_8 = state.ConsumablesForm;
            if (matchValue_8.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (matchValue_8.fields[0].tag === 1) {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(1, [new ConsumableUpdateFormDto(matchValue_8.fields[0].fields[0].Name, matchValue_8.fields[0].fields[0].AdditionalName, matchValue_8.fields[0].fields[0].Description, matchValue_8.fields[0].fields[0].Unit, matchValue_8.fields[0].fields[0].ImageUrl, matchValue_8.fields[0].fields[0].CategoryIds, (bind$0040_5 = matchValue_8.fields[0].fields[0].CommercialData, new CommercialDataFormDto(bind$0040_5.PurchaseDate, bind$0040_5.Supplier, value_9, bind$0040_5.PurchasePrice, bind$0040_5.WarrantyDate, bind$0040_5.Comment)), matchValue_8.fields[0].fields[0].InventoryNumber, matchValue_8.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(matchValue_8.fields[0].fields[0].Name, matchValue_8.fields[0].fields[0].AdditionalName, matchValue_8.fields[0].fields[0].Description, matchValue_8.fields[0].fields[0].Unit, matchValue_8.fields[0].fields[0].ImageUrl, matchValue_8.fields[0].fields[0].StockPerStorage, matchValue_8.fields[0].fields[0].CategoryIds, (bind$0040_4 = matchValue_8.fields[0].fields[0].CommercialData, new CommercialDataFormDto(bind$0040_4.PurchaseDate, bind$0040_4.Supplier, value_9, bind$0040_4.PurchasePrice, bind$0040_4.WarrantyDate, bind$0040_4.Comment)), matchValue_8.fields[0].fields[0].InventoryNumber, matchValue_8.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
        }
        case 15: {
            const value_10 = msg.fields[0];
            const matchValue_9 = state.ConsumablesForm;
            if (matchValue_9.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (matchValue_9.fields[0].tag === 1) {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(1, [new ConsumableUpdateFormDto(matchValue_9.fields[0].fields[0].Name, matchValue_9.fields[0].fields[0].AdditionalName, matchValue_9.fields[0].fields[0].Description, matchValue_9.fields[0].fields[0].Unit, matchValue_9.fields[0].fields[0].ImageUrl, matchValue_9.fields[0].fields[0].CategoryIds, (bind$0040_7 = matchValue_9.fields[0].fields[0].CommercialData, new CommercialDataFormDto(bind$0040_7.PurchaseDate, bind$0040_7.Supplier, bind$0040_7.SupplierArticleNumber, bind$0040_7.PurchasePrice, bind$0040_7.WarrantyDate, value_10)), matchValue_9.fields[0].fields[0].InventoryNumber, matchValue_9.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(matchValue_9.fields[0].fields[0].Name, matchValue_9.fields[0].fields[0].AdditionalName, matchValue_9.fields[0].fields[0].Description, matchValue_9.fields[0].fields[0].Unit, matchValue_9.fields[0].fields[0].ImageUrl, matchValue_9.fields[0].fields[0].StockPerStorage, matchValue_9.fields[0].fields[0].CategoryIds, (bind$0040_6 = matchValue_9.fields[0].fields[0].CommercialData, new CommercialDataFormDto(bind$0040_6.PurchaseDate, bind$0040_6.Supplier, bind$0040_6.SupplierArticleNumber, bind$0040_6.PurchasePrice, bind$0040_6.WarrantyDate, value_10)), matchValue_9.fields[0].fields[0].InventoryNumber, matchValue_9.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
        }
        case 16: {
            const price = msg.fields[0];
            const matchValue_10 = state.ConsumablesForm;
            if (matchValue_10.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (matchValue_10.fields[0].tag === 1) {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(1, [new ConsumableUpdateFormDto(matchValue_10.fields[0].fields[0].Name, matchValue_10.fields[0].fields[0].AdditionalName, matchValue_10.fields[0].fields[0].Description, matchValue_10.fields[0].fields[0].Unit, matchValue_10.fields[0].fields[0].ImageUrl, matchValue_10.fields[0].fields[0].CategoryIds, (bind$0040_9 = matchValue_10.fields[0].fields[0].CommercialData, new CommercialDataFormDto(bind$0040_9.PurchaseDate, bind$0040_9.Supplier, bind$0040_9.SupplierArticleNumber, price, bind$0040_9.WarrantyDate, bind$0040_9.Comment)), matchValue_10.fields[0].fields[0].InventoryNumber, matchValue_10.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(matchValue_10.fields[0].fields[0].Name, matchValue_10.fields[0].fields[0].AdditionalName, matchValue_10.fields[0].fields[0].Description, matchValue_10.fields[0].fields[0].Unit, matchValue_10.fields[0].fields[0].ImageUrl, matchValue_10.fields[0].fields[0].StockPerStorage, matchValue_10.fields[0].fields[0].CategoryIds, (bind$0040_8 = matchValue_10.fields[0].fields[0].CommercialData, new CommercialDataFormDto(bind$0040_8.PurchaseDate, bind$0040_8.Supplier, bind$0040_8.SupplierArticleNumber, price, bind$0040_8.WarrantyDate, bind$0040_8.Comment)), matchValue_10.fields[0].fields[0].InventoryNumber, matchValue_10.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
        }
        case 17:
            return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, msg.fields[0], state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
        case 18:
            return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, msg.fields[0], state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
        case 8: {
            const matchValue_11 = state.ConsumablesForm;
            let matchResult_2, consumable_20;
            if (matchValue_11.tag === 1) {
                if (matchValue_11.fields[0].tag === 0) {
                    matchResult_2 = 0;
                    consumable_20 = matchValue_11.fields[0].fields[0];
                }
                else {
                    matchResult_2 = 1;
                }
            }
            else {
                matchResult_2 = 1;
            }
            switch (matchResult_2) {
                case 0: {
                    const matchValue_12 = state.SelectedFile;
                    if (matchValue_12 == null) {
                        const dto = new ConsumableCreateFormDto(consumable_20.Name, consumable_20.AdditionalName, consumable_20.Description, consumable_20.Unit, consumable_20.ImageUrl, singleton((bind$0040_10 = head(consumable_20.StockPerStorage), new ConsumableFormStockPerStorage(bind$0040_10.StorageId, bind$0040_10.StorageSpace, defaultArg(state.StorageStock, 0), bind$0040_10.MinimumStockQuantity, bind$0040_10.QRCodeIds))), consumable_20.CategoryIds, (bind$0040_11 = consumable_20.CommercialData, (WarrantyDate = map(toISOString, state.WarrantyDate), new CommercialDataFormDto(map(toISOString, state.PurchaseDate), bind$0040_11.Supplier, bind$0040_11.SupplierArticleNumber, bind$0040_11.PurchasePrice, WarrantyDate, bind$0040_11.Comment))), consumable_20.InventoryNumber, consumable_20.CustomProperties);
                        return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, FormState__startLoading(state.FormState)), createConsumable(dto, (Item_42) => (new Msg(26, [Item_42])), (Item_43) => (new Msg(31, [Item_43])))];
                    }
                    else {
                        const file = matchValue_12;
                        const formData = new FormData();
                        formData.append(file.name, file);
                        return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, FormState__startLoading(state.FormState)), uploadImage((Item_40) => (new Msg(21, [Item_40])), (Item_41) => (new Msg(31, [Item_41])), formData)];
                    }
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 9: {
            const matchValue_13 = state.ConsumableId;
            const matchValue_14 = state.ConsumablesForm;
            let matchResult_3, consumable_21, consumableId;
            if (matchValue_13 != null) {
                if (matchValue_14.tag === 1) {
                    if (matchValue_14.fields[0].tag === 1) {
                        matchResult_3 = 0;
                        consumable_21 = matchValue_14.fields[0].fields[0];
                        consumableId = matchValue_13;
                    }
                    else {
                        matchResult_3 = 1;
                    }
                }
                else {
                    matchResult_3 = 1;
                }
            }
            else {
                matchResult_3 = 1;
            }
            switch (matchResult_3) {
                case 0: {
                    const dto_1 = new ConsumableUpdateFormDto(consumable_21.Name, consumable_21.AdditionalName, consumable_21.Description, consumable_21.Unit, consumable_21.ImageUrl, consumable_21.CategoryIds, (bind$0040_12 = consumable_21.CommercialData, (WarrantyDate_1 = map(toISOString, state.WarrantyDate), new CommercialDataFormDto(map(toISOString, state.PurchaseDate), bind$0040_12.Supplier, bind$0040_12.SupplierArticleNumber, bind$0040_12.PurchasePrice, WarrantyDate_1, bind$0040_12.Comment))), consumable_21.InventoryNumber, consumable_21.CustomProperties);
                    return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, FormState__startLoading(state.FormState)), updateConsumable(consumableId, dto_1, (Item_44) => (new Msg(25, [Item_44])), (Item_45) => (new Msg(31, [Item_45])))];
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 20:
            return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, msg.fields[0], state.FormState), Cmd_none()];
        case 21: {
            const response = msg.fields[0];
            switch (response.tag) {
                case 0:
                    return [state, Cmd_errorToast("general.a_error_occured")];
                case 1: {
                    const matchValue_16 = state.ConsumablesForm;
                    let matchResult_4, consumable_22;
                    if (matchValue_16.tag === 1) {
                        if (matchValue_16.fields[0].tag === 0) {
                            matchResult_4 = 0;
                            consumable_22 = matchValue_16.fields[0].fields[0];
                        }
                        else {
                            matchResult_4 = 1;
                        }
                    }
                    else {
                        matchResult_4 = 1;
                    }
                    switch (matchResult_4) {
                        case 0:
                            return [state, createConsumable(new ConsumableCreateFormDto(consumable_22.Name, consumable_22.AdditionalName, consumable_22.Description, consumable_22.Unit, response.fields[0].ImageUrl, singleton((bind$0040_13 = head(consumable_22.StockPerStorage), new ConsumableFormStockPerStorage(bind$0040_13.StorageId, bind$0040_13.StorageSpace, defaultArg(state.StorageStock, 0), bind$0040_13.MinimumStockQuantity, bind$0040_13.QRCodeIds))), consumable_22.CategoryIds, (bind$0040_14 = consumable_22.CommercialData, (WarrantyDate_2 = map(toISOString, state.WarrantyDate), new CommercialDataFormDto(map(toISOString, state.PurchaseDate), bind$0040_14.Supplier, bind$0040_14.SupplierArticleNumber, bind$0040_14.PurchasePrice, WarrantyDate_2, bind$0040_14.Comment))), consumable_22.InventoryNumber, consumable_22.CustomProperties), (Item_46) => (new Msg(26, [Item_46])), (Item_47) => (new Msg(31, [Item_47])))];
                        default:
                            return [state, Cmd_none()];
                    }
                }
                default:
                    return [state, Cmd_errorToast("general.file_too_large")];
            }
        }
        case 10: {
            const labelIds = msg.fields[0];
            const matchValue_17 = state.ConsumablesForm;
            if (matchValue_17.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (matchValue_17.fields[0].tag === 1) {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(1, [new ConsumableUpdateFormDto(matchValue_17.fields[0].fields[0].Name, matchValue_17.fields[0].fields[0].AdditionalName, matchValue_17.fields[0].fields[0].Description, matchValue_17.fields[0].fields[0].Unit, matchValue_17.fields[0].fields[0].ImageUrl, map_1(LabelId__get_unwrap, ofArray(map_2((u_1) => u_1.value, defaultArg(ofNullable(labelIds), [])))), matchValue_17.fields[0].fields[0].CommercialData, matchValue_17.fields[0].fields[0].InventoryNumber, matchValue_17.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(matchValue_17.fields[0].fields[0].Name, matchValue_17.fields[0].fields[0].AdditionalName, matchValue_17.fields[0].fields[0].Description, matchValue_17.fields[0].fields[0].Unit, matchValue_17.fields[0].fields[0].ImageUrl, matchValue_17.fields[0].fields[0].StockPerStorage, map_1(LabelId__get_unwrap, ofArray(map_2((u) => u.value, defaultArg(ofNullable(labelIds), [])))), matchValue_17.fields[0].fields[0].CommercialData, matchValue_17.fields[0].fields[0].InventoryNumber, matchValue_17.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
        }
        case 11: {
            const properties = msg.fields[0];
            const matchValue_18 = state.ConsumablesForm;
            if (matchValue_18.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (matchValue_18.fields[0].tag === 1) {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(1, [new ConsumableUpdateFormDto(matchValue_18.fields[0].fields[0].Name, matchValue_18.fields[0].fields[0].AdditionalName, matchValue_18.fields[0].fields[0].Description, matchValue_18.fields[0].fields[0].Unit, matchValue_18.fields[0].fields[0].ImageUrl, matchValue_18.fields[0].fields[0].CategoryIds, matchValue_18.fields[0].fields[0].CommercialData, matchValue_18.fields[0].fields[0].InventoryNumber, properties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(matchValue_18.fields[0].fields[0].Name, matchValue_18.fields[0].fields[0].AdditionalName, matchValue_18.fields[0].fields[0].Description, matchValue_18.fields[0].fields[0].Unit, matchValue_18.fields[0].fields[0].ImageUrl, matchValue_18.fields[0].fields[0].StockPerStorage, matchValue_18.fields[0].fields[0].CategoryIds, matchValue_18.fields[0].fields[0].CommercialData, matchValue_18.fields[0].fields[0].InventoryNumber, properties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
        }
        case 19: {
            const matchValue_19 = state.ConsumableId;
            if (matchValue_19 == null) {
                return [state, Cmd_none()];
            }
            else {
                return [state, getConsumable((Item_56) => (new Msg(23, [Item_56])), (Item_57) => (new Msg(31, [Item_57])), matchValue_19)];
            }
        }
        case 22: {
            const matchValue_20 = state.ConsumableId;
            if (matchValue_20 == null) {
                return [state, Cmd_none()];
            }
            else {
                return [state, getConsumable((Item_58) => (new Msg(23, [Item_58])), (Item_59) => (new Msg(31, [Item_59])), matchValue_20)];
            }
        }
        case 26: {
            const response_1 = msg.fields[0];
            if (response_1.tag === 1) {
                return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, FormState__setValidationResponse_5219762A(state.FormState, response_1.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, FormState_get_empty()), Cmd_batch(ofArray([(xs = ["consumables", response_1.fields[0]], Cmd_ofEffect((_arg) => {
                    RouterModule_nav(ofArray(xs), 1, 1);
                })), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 25: {
            const response_2 = msg.fields[0];
            if (response_2.tag === 1) {
                return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, FormState__setValidationResponse_5219762A(state.FormState, response_2.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, FormState_get_empty()), Cmd_batch(ofArray([(xs_2 = ["consumables", response_2.fields[0]], Cmd_ofEffect((_arg_1) => {
                    RouterModule_nav(ofArray(xs_2), 1, 1);
                })), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 28:
            return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, new RequestedValue$1(1, [msg.fields[0]]), state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
        case 23: {
            const consumable_27 = msg.fields[0];
            return [new State(consumable_27.Id, new RequestedValue$1(1, [new ConsumableForm(1, [new ConsumableUpdateFormDto(consumable_27.Name, consumable_27.AdditionalName, consumable_27.Description, consumable_27.Unit, consumable_27.ImageUrl, map_1(LabelId__get_unwrap, consumable_27.LabelIds), new CommercialDataFormDto(undefined, consumable_27.CommercialData.Supplier, consumable_27.CommercialData.SupplierArticleNumber, consumable_27.CommercialData.PurchasePrice, undefined, consumable_27.CommercialData.Comment), consumable_27.InventoryNumber, consumable_27.CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, consumable_27.CommercialData.PurchaseDate, consumable_27.CommercialData.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
        }
        case 24: {
            const consumable_28 = msg.fields[0];
            const stockPerStorage = (length(consumable_28.StockPerStorage) === 1) ? item(0, consumable_28.StockPerStorage) : undefined;
            return [new State(consumable_28.Id, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(consumable_28.Name, consumable_28.AdditionalName, consumable_28.Description, consumable_28.Unit, consumable_28.ImageUrl, singleton(new ConsumableFormStockPerStorage(defaultArg(map((stockPerStorage_1) => StorageId__get_unwrap(stockPerStorage_1.StorageId), stockPerStorage), ""), defaultArg(map((stockPerStorage_2) => stockPerStorage_2.StorageSpace, stockPerStorage), ""), defaultArg(map((stockPerStorage_3) => stockPerStorage_3.Stock, stockPerStorage), 0), defaultArg(map((stockPerStorage_4) => stockPerStorage_4.MinimumStockQuantity, stockPerStorage), undefined), empty())), map_1(LabelId__get_unwrap, consumable_28.LabelIds), new CommercialDataFormDto(undefined, consumable_28.CommercialData.Supplier, consumable_28.CommercialData.SupplierArticleNumber, consumable_28.CommercialData.PurchasePrice, undefined, consumable_28.CommercialData.Comment), consumable_28.InventoryNumber, consumable_28.CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, consumable_28.CommercialData.PurchaseDate, consumable_28.CommercialData.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
        }
        case 27:
            return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, new RequestedValue$1(1, [msg.fields[0]]), state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
        case 29:
            return [new State(state.ConsumableId, state.ConsumablesForm, new RequestedValue$1(1, [filter((propertyConfiguration_1) => {
                const matchValue_21 = propertyConfiguration_1.Visibility;
                switch (matchValue_21.tag) {
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        return false;
                    default:
                        return true;
                }
            }, msg.fields[0])]), state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
        case 30:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, state.Labels, new RequestedValue$1(1, [false]), state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.ConsumableId, state.ConsumablesForm, state.PropertyConfigurations, state.Storages, state.Labels, new RequestedValue$1(1, [true]), state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
        case 31:
            return [state, Cmd_errorToast("general.a_error_occured")];
        case 12:
            return [state, Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_1), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_6) => {
                const pr = response_6.arrayBuffer();
                return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Label_$reflection())));
            })))), "/api/labels", (Item_60) => (new Msg(28, [Item_60])), (Item_61) => (new Msg(31, [Item_61])))];
        default: {
            const value = msg.fields[0];
            const matchValue = state.ConsumablesForm;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (matchValue.fields[0].tag === 1) {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(1, [new ConsumableUpdateFormDto(value, matchValue.fields[0].fields[0].AdditionalName, matchValue.fields[0].fields[0].Description, matchValue.fields[0].fields[0].Unit, matchValue.fields[0].fields[0].ImageUrl, matchValue.fields[0].fields[0].CategoryIds, matchValue.fields[0].fields[0].CommercialData, matchValue.fields[0].fields[0].InventoryNumber, matchValue.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
            else {
                return [new State(state.ConsumableId, new RequestedValue$1(1, [new ConsumableForm(0, [new ConsumableCreateFormDto(value, matchValue.fields[0].fields[0].AdditionalName, matchValue.fields[0].fields[0].Description, matchValue.fields[0].fields[0].Unit, matchValue.fields[0].fields[0].ImageUrl, matchValue.fields[0].fields[0].StockPerStorage, matchValue.fields[0].fields[0].CategoryIds, matchValue.fields[0].fields[0].CommercialData, matchValue.fields[0].fields[0].InventoryNumber, matchValue.fields[0].fields[0].CustomProperties)])]), state.PropertyConfigurations, state.Storages, state.Labels, state.IsCustomPropertiesEnabled, state.StorageStock, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.FormState), Cmd_none()];
            }
        }
    }
}

