import { Cmd_none, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { ToolBoxDispolist_$reflection } from "../../Shared/Receiver.js";
import { printf, toText } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { State, Msg } from "./Types.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";

export function getToolBoxDispolistCmd(userId, eventId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), ToolBoxDispolist_$reflection()));
    })))), toText(printf("/api/toolbox/%s/dispolist/%s"))(userId)(eventId), (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])));
}

function initialState(userId, customer) {
    return new State(undefined, userId);
}

export function initToolBoxDispolist(userId, eventId, customer) {
    return [initialState(userId, customer), getToolBoxDispolistCmd(userId, eventId)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(msg.fields[0], state.UserId), Cmd_none()];
        case 2:
            return [state, Cmd_none()];
        default:
            return [state, Cmd_ofEffect((_arg) => {
                RouterModule_nav(singleton(`/receivers/${state.UserId}`), 1, 1);
            })];
    }
}

