import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { ImportDeleteResponse_$reflection } from "../../Shared/Importer.js";
import { PostResponse$1_$reflection } from "../../Shared/Shared.js";
import { record_type, lambda_type, unit_type, bool_type, union_type, class_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Cmd_successToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ConfirmationDialog } from "../../Components/Dialog.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DeleteImport", "ImportDeletedResponse", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Importer.Dialogs.DeleteImport.Msg", [], Msg, () => [[], [["Item", PostResponse$1_$reflection(ImportDeleteResponse_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

function deleteImportCmd(id) {
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_1), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ImportDeleteResponse_$reflection())));
    })))), `/api/import/history/${id}`, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])));
}

class State extends Record {
    constructor(ImportId, IsLoading, SuccessCallback, OnClose) {
        super();
        this.ImportId = ImportId;
        this.IsLoading = IsLoading;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
    }
}

function State_$reflection() {
    return record_type("Importer.Dialogs.DeleteImport.State", [], State, () => [["ImportId", class_type("System.Guid")], ["IsLoading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)]]);
}

function init(props) {
    return [new State(props.ImportId, false, props.SuccessCallback, props.OnClose), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 0:
            return [new State(state.ImportId, false, state.SuccessCallback, state.OnClose), deleteImportCmd(state.ImportId)];
        case 2:
            return [new State(state.ImportId, false, state.SuccessCallback, state.OnClose), Cmd_none()];
        default:
            return [new State(state.ImportId, true, state.SuccessCallback, state.OnClose), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            }), Cmd_successToast("importer.dialog_delete_success")]))];
    }
}

export function ImportDeleteDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        ImportId: props.ImportId,
        OnClose: props.OnClose,
        SuccessCallback: props.SuccessCallback,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    return createElement(ConfirmationDialog, (Title = t("general.delete"), {
        Description: t("importer.import_delete_msg"),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        OnConfirm: patternInput_1[0].IsLoading ? (() => {
        }) : (() => {
            patternInput_1[1](new Msg(0, []));
        }),
        Title: Title,
    }));
}

