import { createElement } from "react";
import React from "react";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";

export function ViewContainer(props) {
    return createElement("div", {
        className: join(" ", ["bg-background", "px-7", "pb-5", "pt-7", "overflow-auto", "h-full"]),
        children: reactApi.Children.toArray([props.Children]),
    });
}

