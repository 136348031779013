import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, bool_type, string_type, union_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { PropertyConfigurationId_$reflection } from "../../Shared/Shared.js";
import { Helper_initialPropertyConfiguration, PropertyConfigurationVisibility, PropertyConfigurationVisibility_$reflection, PropertyConfigurationType_$reflection } from "../../Shared/PropertyConfiguration.js";
import { ofArray, tryHead, tryFind, toArray, concat, append, filter, sortBy, map, reduce, isEmpty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { map as map_1, defaultArg, unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { createObj, comparePrimitives, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { compare } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append as append_1, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { FormType, CustomPropertyDialog } from "./Dialogs/CustomPropertyDialog.js";
import { Msg } from "./Types.js";
import { DeleteCustomPropertyDialog } from "./Dialogs/DeleteCustomPropertyDialog.js";
import { TableControl$1, ColumnDefinition$1, CellContent, OnTableRowClick$1, Table } from "../../Widgets/Table/Table.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import Shipment_Add from "../../../public/assets/icons/Shipment_Add.svg";
import Recycle_Bin_1 from "../../../public/assets/icons/Recycle_Bin_1.svg";
import { EditReceiverCustomPropertyDialog, NewReceiverCustomPropertyDialog } from "./Dialogs/ReceiverCustomPropertyFormDialog.js";
import { TabsMenu } from "../../Widgets/TabsMenu.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { InformationAlert } from "../../Components/Alert.js";

export class VisibleTab extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Inventory", "Receiver"];
    }
}

export function VisibleTab_$reflection() {
    return union_type("Settings.CustomProperty.View.VisibleTab", [], VisibleTab, () => [[], []]);
}

export class ReceiverPropertyConfigurationRow extends Record {
    constructor(Id, Name, Type, Receiver, Required) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Type = Type;
        this.Receiver = Receiver;
        this.Required = Required;
    }
}

export function ReceiverPropertyConfigurationRow_$reflection() {
    return record_type("Settings.CustomProperty.View.ReceiverPropertyConfigurationRow", [], ReceiverPropertyConfigurationRow, () => [["Id", PropertyConfigurationId_$reflection()], ["Name", string_type], ["Type", PropertyConfigurationType_$reflection()], ["Receiver", PropertyConfigurationVisibility_$reflection()], ["Required", bool_type]]);
}

export function propertyConfigurationType(pcType) {
    switch (pcType.tag) {
        case 4:
            return "settings.custom_property.types.bool";
        case 1:
            return "settings.custom_property.types.enum";
        case 2:
            return "settings.custom_property.types.multi_enum";
        case 3:
            return "settings.custom_property.types.date";
        case 5:
            return "settings.custom_property.types.url";
        case 6:
            return "settings.custom_property.types.currency";
        default:
            return "settings.custom_property.types.text";
    }
}

export function propertyConfigurationValue(pcType) {
    switch (pcType.tag) {
        case 1: {
            const enums = pcType.fields[0];
            if (isEmpty(enums)) {
                return "";
            }
            else {
                return reduce((e1, e2) => (`${e1},${e2}`), map((e) => e.Name, enums));
            }
        }
        case 2: {
            const enums_1 = pcType.fields[0];
            if (isEmpty(enums_1)) {
                return "";
            }
            else {
                return reduce((e1_1, e2_1) => (`${e1_1}, ${e2_1}`), map((e_1) => e_1.Name, enums_1));
            }
        }
        default:
            return "";
    }
}

function InventoryPropertyConfiguration(props) {
    let elems;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsNewCustomPropertyDialogOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(undefined);
    const setIsEditCustomPropertyDialogOpen = patternInput_2[1];
    const isEditCustomPropertyDialogOpen = patternInput_2[0];
    const patternInput_3 = reactApi.useState(undefined);
    const setIsDeleteDialogOpen = patternInput_3[1];
    const isDeleteDialogOpen = patternInput_3[0];
    const propertyConfigurationContentTable = (categoryId, propertyConfigurations) => map((pc) => ({
        CategoryId: unwrap(categoryId),
        Id: pc.Id,
        Name: pc.Name,
        Required: pc.Required,
        Type: pc.Type,
    }), sortBy((pc_2) => pc_2.CreatedAt, filter((pc_1) => {
        if (categoryId != null) {
            const catId = categoryId;
            const matchValue = pc_1.Visibility;
            switch (matchValue.tag) {
                case 1:
                    return equals(matchValue.fields[0], catId);
                case 0:
                    return false;
                default:
                    return false;
            }
        }
        else {
            return equals(pc_1.Visibility, new PropertyConfigurationVisibility(0, []));
        }
    }, propertyConfigurations), {
        Compare: compare,
    }));
    const globalPropConfiguration = propertyConfigurationContentTable(undefined, filter((pc_3) => equals(pc_3.Visibility, new PropertyConfigurationVisibility(0, [])), props.PropertyConfigurations));
    const rows = append(concat(map((l_1) => propertyConfigurationContentTable(l_1.Id, filter((pc_4) => {
        const matchValue_1 = pc_4.Visibility;
        switch (matchValue_1.tag) {
            case 0:
                return false;
            case 1:
                return equals(matchValue_1.fields[0], l_1.Id);
            default:
                return false;
        }
    }, props.PropertyConfigurations)), sortBy((l) => l.Name, props.Labels, {
        Compare: comparePrimitives,
    }))), globalPropConfiguration);
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems = toList(delay(() => append_1(singleton(createElement(CustomPropertyDialog, {
        FormType: new FormType(0, [Helper_initialPropertyConfiguration(new PropertyConfigurationVisibility(0, []))]),
        IsOpen: patternInput_1[0],
        Labels: props.Labels,
        OnClose: () => {
            setIsNewCustomPropertyDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(0, []));
        },
    })), delay(() => {
        let matchValue_4;
        return append_1((matchValue_4 = isEditCustomPropertyDialogOpen, (matchValue_4 == null) ? (empty()) : singleton(createElement(CustomPropertyDialog, {
            FormType: new FormType(1, [matchValue_4]),
            IsOpen: isEditCustomPropertyDialogOpen != null,
            Labels: props.Labels,
            OnClose: () => {
                setIsEditCustomPropertyDialogOpen(undefined);
            },
            SuccessCallback: () => {
                props.Dispatch(new Msg(0, []));
            },
        }))), delay(() => {
            let matchValue_5;
            return append_1((matchValue_5 = isDeleteDialogOpen, (matchValue_5 == null) ? (empty()) : singleton(createElement(DeleteCustomPropertyDialog, {
                IsOpen: isDeleteDialogOpen != null,
                OnClose: () => {
                    setIsDeleteDialogOpen(undefined);
                },
                PropertyConfigurationId: matchValue_5.Id,
                SuccessCallback: () => {
                    props.Dispatch(new Msg(0, []));
                },
            }))), delay(() => {
                let TableRows, Options;
                return singleton(createElement(Table, (TableRows = toArray(rows), (Options = {
                    BorderStyle: "full",
                    CSVDownload: false,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["name", "asc"],
                    GlobalSearch: false,
                    OnRowClick: new OnTableRowClick$1(0, [(row_5) => {
                        setIsEditCustomPropertyDialogOpen(tryFind((pc_5) => equals(pc_5.Id, row_5.Id), props.PropertyConfigurations));
                    }]),
                    RowSelection: "singleRow",
                }, {
                    ColumnDefinitions: [new ColumnDefinition$1("name", "", t("settings.custom_property.header.name"), (row) => (new CellContent(0, [row.Name])), "text", "text"), new ColumnDefinition$1("type", "", t("settings.custom_property.header.type"), (row_1) => (new CellContent(0, [t(propertyConfigurationType(row_1.Type))])), "select", "text"), new ColumnDefinition$1("label", "", t("general.category"), (row_2) => {
                        const matchValue_2 = row_2.CategoryId;
                        if (matchValue_2 != null) {
                            const categoryId_2 = matchValue_2;
                            return new CellContent(0, [defaultArg(map_1((label) => label.Name, tryFind((l_2) => equals(l_2.Id, categoryId_2), props.Labels)), "")]);
                        }
                        else {
                            return new CellContent(0, [""]);
                        }
                    }, "select", "text"), new ColumnDefinition$1("required", "", t("settings.custom_property.header.required"), (row_3) => {
                        let matchValue_3;
                        return new CellContent(1, [(matchValue_3 = row_3.Type, (matchValue_3.tag === 5) ? Checkbox({
                            ComponentState: "enabled",
                            IsChecked: row_3.Required,
                            Label: "",
                            OnCheck: (value_1) => {
                            },
                            TestId: "",
                        }) : ((matchValue_3.tag === 0) ? Checkbox({
                            ComponentState: "enabled",
                            IsChecked: row_3.Required,
                            Label: "",
                            OnCheck: (value_1) => {
                            },
                            TestId: "",
                        }) : ((matchValue_3.tag === 1) ? Checkbox({
                            ComponentState: "enabled",
                            IsChecked: row_3.Required,
                            Label: "",
                            OnCheck: (value_1) => {
                            },
                            TestId: "",
                        }) : ((matchValue_3.tag === 2) ? Checkbox({
                            ComponentState: "enabled",
                            IsChecked: row_3.Required,
                            Label: "",
                            OnCheck: (value_1) => {
                            },
                            TestId: "",
                        }) : ((matchValue_3.tag === 3) ? Checkbox({
                            ComponentState: "enabled",
                            IsChecked: row_3.Required,
                            Label: "",
                            OnCheck: (value_1) => {
                            },
                            TestId: "",
                        }) : ((matchValue_3.tag === 6) ? Checkbox({
                            ComponentState: "enabled",
                            IsChecked: row_3.Required,
                            Label: "",
                            OnCheck: (value_1) => {
                            },
                            TestId: "",
                        }) : defaultOf()))))))]);
                    }, "none", "text"), new ColumnDefinition$1("value", "", t("settings.custom_property.header.value"), (row_4) => (new CellContent(0, [propertyConfigurationValue(row_4.Type)])), "none", "text")],
                    Controls: ofArray([new TableControl$1(t("settings.custom_property.create"), Shipment_Add(), (_arg) => {
                        setIsNewCustomPropertyDialogOpen(true);
                    }, false, false, ""), new TableControl$1(t("settings.custom_property.delete"), Recycle_Bin_1(), (row_6) => {
                        setIsDeleteDialogOpen(tryHead(row_6));
                    }, true, false, "")]),
                    Options: Options,
                    TableRows: TableRows,
                }))));
            }));
        }));
    })))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

function ReceiverPropertyConfiguration(props) {
    let elems;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsNewCustomPropertyDialogOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(undefined);
    const setIsEditCustomPropertyDialogOpen = patternInput_2[1];
    const isEditCustomPropertyDialogOpen = patternInput_2[0];
    const patternInput_3 = reactApi.useState(undefined);
    const setIsDeleteDialogOpen = patternInput_3[1];
    const isDeleteDialogOpen = patternInput_3[0];
    const rows = map((propertyConfiguration) => (new ReceiverPropertyConfigurationRow(propertyConfiguration.Id, propertyConfiguration.Name, propertyConfiguration.Type, propertyConfiguration.Visibility, propertyConfiguration.Required)), props.PropertyConfigurations);
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems = toList(delay(() => append_1(singleton(createElement(NewReceiverCustomPropertyDialog, {
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setIsNewCustomPropertyDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(0, []));
        },
    })), delay(() => {
        let matchValue;
        return append_1((matchValue = isEditCustomPropertyDialogOpen, (matchValue == null) ? (empty()) : singleton(createElement(EditReceiverCustomPropertyDialog, {
            IsOpen: isEditCustomPropertyDialogOpen != null,
            OnClose: () => {
                setIsEditCustomPropertyDialogOpen(undefined);
            },
            PropertyConfiguration: matchValue,
            SuccessCallback: () => {
                props.Dispatch(new Msg(0, []));
            },
        }))), delay(() => {
            let matchValue_1;
            return append_1((matchValue_1 = isDeleteDialogOpen, (matchValue_1 == null) ? (empty()) : singleton(createElement(DeleteCustomPropertyDialog, {
                IsOpen: isDeleteDialogOpen != null,
                OnClose: () => {
                    setIsDeleteDialogOpen(undefined);
                },
                PropertyConfigurationId: matchValue_1.Id,
                SuccessCallback: () => {
                    props.Dispatch(new Msg(0, []));
                },
            }))), delay(() => {
                let TableRows, Options;
                return singleton(createElement(Table, (TableRows = toArray(rows), (Options = {
                    BorderStyle: "full",
                    CSVDownload: false,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["name", "asc"],
                    GlobalSearch: false,
                    OnRowClick: new OnTableRowClick$1(0, [(row_5) => {
                        setIsEditCustomPropertyDialogOpen(tryFind((pc) => equals(pc.Id, row_5.Id), props.PropertyConfigurations));
                    }]),
                    RowSelection: "singleRow",
                }, {
                    ColumnDefinitions: [new ColumnDefinition$1("name", "", t("settings.custom_property.header.name"), (row) => (new CellContent(0, [row.Name])), "text", "text"), new ColumnDefinition$1("type", "", t("settings.custom_property.header.type"), (row_1) => (new CellContent(0, [t(propertyConfigurationType(row_1.Type))])), "select", "text"), new ColumnDefinition$1("receiver", "", t("general.receiver"), (row_2) => {
                        let matchValue_2;
                        return new CellContent(0, [t((matchValue_2 = row_2.Receiver, (matchValue_2.tag === 3) ? "receivers.employee" : ((matchValue_2.tag === 4) ? "receivers.externalperson" : ((matchValue_2.tag === 5) ? "receivers.subcontractor" : ((matchValue_2.tag === 6) ? "receivers.room" : ((matchValue_2.tag === 7) ? "receivers.project" : ((matchValue_2.tag === 8) ? "receivers.constructionsite" : ((matchValue_2.tag === 9) ? "receivers.vehicle" : ((matchValue_2.tag === 10) ? "receivers.student" : ((matchValue_2.tag === 11) ? "receivers.school_class" : ((matchValue_2.tag === 0) ? "" : ((matchValue_2.tag === 1) ? "" : "receivers.user"))))))))))))]);
                    }, "select", "text"), new ColumnDefinition$1("required", "", t("settings.custom_property.header.required"), (row_3) => {
                        let matchValue_3;
                        return new CellContent(1, [(matchValue_3 = row_3.Type, (matchValue_3.tag === 5) ? Checkbox({
                            ComponentState: "enabled",
                            IsChecked: row_3.Required,
                            Label: "",
                            OnCheck: (value_1) => {
                            },
                            TestId: "",
                        }) : ((matchValue_3.tag === 0) ? Checkbox({
                            ComponentState: "enabled",
                            IsChecked: row_3.Required,
                            Label: "",
                            OnCheck: (value_1) => {
                            },
                            TestId: "",
                        }) : ((matchValue_3.tag === 1) ? Checkbox({
                            ComponentState: "enabled",
                            IsChecked: row_3.Required,
                            Label: "",
                            OnCheck: (value_1) => {
                            },
                            TestId: "",
                        }) : ((matchValue_3.tag === 2) ? Checkbox({
                            ComponentState: "enabled",
                            IsChecked: row_3.Required,
                            Label: "",
                            OnCheck: (value_1) => {
                            },
                            TestId: "",
                        }) : ((matchValue_3.tag === 3) ? Checkbox({
                            ComponentState: "enabled",
                            IsChecked: row_3.Required,
                            Label: "",
                            OnCheck: (value_1) => {
                            },
                            TestId: "",
                        }) : ((matchValue_3.tag === 6) ? Checkbox({
                            ComponentState: "enabled",
                            IsChecked: row_3.Required,
                            Label: "",
                            OnCheck: (value_1) => {
                            },
                            TestId: "",
                        }) : defaultOf()))))))]);
                    }, "none", "text"), new ColumnDefinition$1("value", "", t("settings.custom_property.header.value"), (row_4) => (new CellContent(0, [propertyConfigurationValue(row_4.Type)])), "none", "text")],
                    Controls: ofArray([new TableControl$1(t("settings.custom_property.create"), Shipment_Add(), (_arg_6) => {
                        setIsNewCustomPropertyDialogOpen(true);
                    }, false, false, ""), new TableControl$1(t("settings.custom_property.delete"), Recycle_Bin_1(), (row_6) => {
                        setIsDeleteDialogOpen(tryHead(row_6));
                    }, true, false, "")]),
                    Options: Options,
                    TableRows: TableRows,
                }))));
            }));
        }));
    })))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

function Overview(props) {
    let elems;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(new VisibleTab(0, []));
    const visibleTab = patternInput_1[0];
    const setVisibleTab = patternInput_1[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-3"])], (elems = toList(delay(() => {
        let Label, Label_1;
        return append_1(singleton(createElement(TabsMenu, {
            Tabs: ofArray([(Label = t("settings.custom_property.inventory"), {
                IsSelected: equals(visibleTab, new VisibleTab(0, [])),
                Label: Label,
                OnClick: () => {
                    setVisibleTab(new VisibleTab(0, []));
                },
                TestId: "inventory-categories-tab",
            }), (Label_1 = t("settings.custom_property.receiver"), {
                IsSelected: equals(visibleTab, new VisibleTab(1, [])),
                Label: Label_1,
                OnClick: () => {
                    setVisibleTab(new VisibleTab(1, []));
                },
                TestId: "receiver-categories-tab",
            })]),
        })), delay(() => ((visibleTab.tag === 1) ? singleton(createElement(ReceiverPropertyConfiguration, {
            Dispatch: props.Dispatch,
            InvalidMessage: unwrap(props.InvalidMessage),
            PropertyConfigurations: filter((propertyConfiguration_1) => {
                const matchValue_2 = propertyConfiguration_1.Visibility;
                switch (matchValue_2.tag) {
                    case 0:
                    case 1:
                        return false;
                    default:
                        return true;
                }
            }, props.PropertyConfigurations),
        })) : singleton(createElement(InventoryPropertyConfiguration, {
            Dispatch: props.Dispatch,
            InvalidMessage: unwrap(props.InvalidMessage),
            Labels: props.Labels,
            PropertyConfigurations: filter((propertyConfiguration) => {
                const matchValue_1 = propertyConfiguration.Visibility;
                switch (matchValue_1.tag) {
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        return false;
                    default:
                        return true;
                }
            }, props.PropertyConfigurations),
        })))));
    })), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function View() {
    let elems;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init_1, update_1, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const matchValue = state_1.CustomProperties;
    const matchValue_1 = state_1.Labels;
    const matchValue_2 = state_1.IsEnabled;
    let matchResult, customProperties, isEnabled, labels;
    if (matchValue.tag === 1) {
        if (matchValue_1.tag === 1) {
            if (matchValue_2.tag === 1) {
                matchResult = 1;
                customProperties = matchValue.fields[0];
                isEnabled = matchValue_2.fields[0];
                labels = matchValue_1.fields[0];
            }
            else {
                matchResult = 0;
            }
        }
        else {
            matchResult = 0;
        }
    }
    else {
        matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return createElement(Skeleton, {
                Variant: "table",
            });
        default:
            if (isEnabled) {
                return createElement(Overview, {
                    Dispatch: patternInput[1],
                    InvalidMessage: unwrap(state_1.InvalidMessage),
                    Labels: labels,
                    PropertyConfigurations: customProperties,
                });
            }
            else {
                return createElement("div", createObj(ofArray([["className", join(" ", ["w-10/12"])], (elems = [createElement(InformationAlert, {
                    Label: "settings.custom_property.not_enabled_info",
                })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
            }
    }
}

