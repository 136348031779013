import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";

export function WizardComplete() {
    let elems;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "m-auto"])], (elems = [createElement("div", {
        className: join(" ", ["text-2xl", "font-bold"]),
        children: patternInput[0]("customerdata.wizard.completed.message"),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

