import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { ImportValidateResponse_$reflection, ImportDataResponse_$reflection, ImportResult_$reflection } from "../../Shared/Importer.js";
import { union_type, class_type, record_type, string_type, bool_type, option_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { PostResponse$1_$reflection } from "../../Shared/Shared.js";

export class State extends Record {
    constructor(Import, ImportActive, ValidateErrorMessage) {
        super();
        this.Import = Import;
        this.ImportActive = ImportActive;
        this.ValidateErrorMessage = ValidateErrorMessage;
    }
}

export function State_$reflection() {
    return record_type("Importer.Tool.Types.State", [], State, () => [["Import", option_type(ImportResult_$reflection())], ["ImportActive", bool_type], ["ValidateErrorMessage", option_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ImportData", "DataImported", "Validate", "ValidationCompleted", "DownloadTemplate", "DownloadedTemplate", "BlobReceived", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Importer.Tool.Types.Msg", [], Msg, () => [[], [["Item", PostResponse$1_$reflection(ImportDataResponse_$reflection())]], [["Item", class_type("Browser.Types.FormData", undefined)]], [["Item", PostResponse$1_$reflection(ImportValidateResponse_$reflection())]], [], [["Item1", class_type("Fetch.Types.Response")], ["Item2", string_type]], [["Item1", class_type("Browser.Types.Blob", undefined)], ["Item2", string_type]], [["Item", class_type("System.Exception")]]]);
}

