import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { record_type, list_type, anonRecord_type, lambda_type, unit_type, union_type, class_type, string_type, option_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { StorageId, FormState__getValidation, FormState__setValidationResponse_5219762A, SetId, FormState__startLoading, FormState_get_empty, FormState_$reflection, TokenDataDto_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { DataTransfer_ChangeStorageDto, DataTransfer_ChangeStorageDto_$reflection, DataTransfer_DetailSet_$reflection } from "../../Shared/Set.js";
import { singleton, tryFind, ofArray, contains, filter, map } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { equals, createObj, stringHash } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { putChangeStorageCmd } from "../../Requests/Set.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { unwrapStorageId } from "../../Shared/Helper.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Select } from "../../Components/Select.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Input } from "../../Components/Input.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateStorage", "SetSelectedTargetStorage", "SetStorageSpace", "StorageUpdated", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Sets.Dialog.ChangeStorageDialog.Msg", [], Msg, () => [[], [["Item", option_type(Storage_$reflection())]], [["Item", string_type]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Set$, Dto, OnClose, SuccessCallback, UserData, TargetStorageOptions, FormState) {
        super();
        this.Set = Set$;
        this.Dto = Dto;
        this.OnClose = OnClose;
        this.SuccessCallback = SuccessCallback;
        this.UserData = UserData;
        this.TargetStorageOptions = TargetStorageOptions;
        this.FormState = FormState;
    }
}

function State_$reflection() {
    return record_type("Sets.Dialog.ChangeStorageDialog.State", [], State, () => [["Set", DataTransfer_DetailSet_$reflection()], ["Dto", DataTransfer_ChangeStorageDto_$reflection()], ["OnClose", lambda_type(unit_type, unit_type)], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["UserData", TokenDataDto_$reflection()], ["TargetStorageOptions", list_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))], ["FormState", FormState_$reflection()]]);
}

function init(set$, userData, successCallback, isOpen, onClose, storages) {
    return [new State(set$, new DataTransfer_ChangeStorageDto(set$.LocationInformation.StorageId, set$.LocationInformation.StorageSpace), onClose, successCallback, userData, map((storage_1) => ({
        label: storage_1.Name,
        value: storage_1,
    }), filter((storage) => {
        const matchValue = userData.Role;
        switch (matchValue) {
            case "administrator":
            case "system":
                return true;
            case "toolManager":
                return contains(userData.UserId, storage.AssignedUserIds, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: stringHash,
                });
            default:
                return false;
        }
    }, storages)), FormState_get_empty()), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 2:
            return [new State(state.Set, new DataTransfer_ChangeStorageDto(state.Dto.NewStorageId, msg.fields[0]), state.OnClose, state.SuccessCallback, state.UserData, state.TargetStorageOptions, state.FormState), Cmd_none()];
        case 0:
            return [new State(state.Set, state.Dto, state.OnClose, state.SuccessCallback, state.UserData, state.TargetStorageOptions, FormState__startLoading(state.FormState)), putChangeStorageCmd(new SetId(parse(state.Set.Id)), state.Dto, (Item_1) => (new Msg(3, [Item_1])), (Item_2) => (new Msg(4, [Item_2])))];
        case 3: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.Set, state.Dto, state.OnClose, state.SuccessCallback, state.UserData, state.TargetStorageOptions, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.Set, state.Dto, state.OnClose, state.SuccessCallback, state.UserData, state.TargetStorageOptions, FormState_get_empty()), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 4:
            return [state, Cmd_none()];
        default: {
            const storage = msg.fields[0];
            if (storage == null) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.Set, new DataTransfer_ChangeStorageDto(unwrapStorageId(storage.Id), state.Dto.NewStorageSpace), state.OnClose, state.SuccessCallback, state.UserData, state.TargetStorageOptions, state.FormState), Cmd_none()];
            }
        }
    }
}

function DialogBody(props) {
    let elems, Label, Value, matchValue, targetStorage;
    const t = useTranslation()[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = [createElement(Select, (Label = t("tool.switch_storage_target"), (Value = ((matchValue = props.SelectedTargetStorage, (matchValue == null) ? undefined : ((targetStorage = matchValue, tryFind((option_2) => equals(option_2.value, targetStorage), props.TargetStorageOptions))))), {
        ComponentState: componentState,
        IsClearable: false,
        Label: unwrap(Label),
        NoOptionsMessage: "",
        OnChange: (option) => {
            if (option == null) {
            }
            else {
                props.SetSelectedTargetStorage(option.value);
            }
        },
        Options: props.TargetStorageOptions,
        PlaceholderKey: "",
        TestId: "switch-storage-dialog-storage-target-test-id",
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "storage")),
        Value: unwrap(Value),
    }))), createElement(Input, {
        ComponentState: componentState,
        Label: t("tool.storage_space"),
        OnChange: props.SetStorageSpace,
        TestId: "change-storage-space-test-id",
        Value: props.StorageSpace,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function ChangeStorageDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.Set, props.UserData, props.SuccessCallback, props.IsOpen, props.OnClose, props.Storages);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.OnClose]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("tool.switch_storage"), {
        Body: singleton(createElement(DialogBody, {
            Dispatch: dispatch,
            FormState: state_1.FormState,
            SelectedTargetStorage: unwrap(tryFind((storage) => equals(storage.Id, new StorageId(parse(state_1.Dto.NewStorageId))), props.Storages)),
            SetSelectedTargetStorage: (storage_1) => {
                dispatch(new Msg(1, [storage_1]));
            },
            SetStorageSpace: (value) => {
                dispatch(new Msg(2, [value]));
            },
            StorageSpace: state_1.Dto.NewStorageSpace,
            TargetStorageOptions: state_1.TargetStorageOptions,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-reservation-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("tool.switch_storage"),
            OnClick: () => {
                dispatch(new Msg(0, []));
            },
            TestId: "save-switch-storage-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

