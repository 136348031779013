import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { int32ToString, createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { BlockHeader, TextValue } from "../Components/Text.js";
import { TextButton } from "../Components/Button.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { empty as empty_1, toArray, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { OpenFullDataExportDialog } from "./Dialogs/FullDataExportDialog.js";
import { Msg } from "./Types.js";
import { Feature, isFeatureEnabled } from "../FeatureFlip.js";
import { ImportDeleteDialog } from "./Dialogs/DeleteImport.js";
import { ColumnDefinition$1, CellContent, Table } from "../Widgets/Table/Table.js";
import { update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../Components/Skeleton.js";

export function SelectListItem(props) {
    let elems_1, elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "p-4", "w-[25rem]", "rounded"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-between"])], (elems = toList(delay(() => append(singleton(createElement(TextValue, {
        FontWeight: "normal",
        TestId: "",
        Text: t(props.BtnTitleTranslationKey),
    })), delay(() => (props.IsDisabled ? singleton(createElement(TextValue, {
        FontWeight: "bold",
        TestId: "",
        Text: t("importer.not_active"),
    })) : singleton(createElement(TextButton, {
        ComponentState: "enabled",
        Label: props.BtnText,
        OnClick: props.Callback,
        TestId: "importer-select-open-button",
        Variant: "blueButton",
    }))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export const consumableText = "importer.consumable_description";

export const userText = "importer.user_import_description";

export const roomsText = "importer.rooms_import_description";

export const labelsText = "importer.labels_import_description";

export const employeesText = "importer.employees_import_description";

export const projectsText = "importer.projects_import_description";

export const constructionSiteText = "importer.constructionsites_import_description";

export const externalPersonText = "importer.externalprojects_import_description";

export const studentsText = "importer.students_import_description";

export const schoolClassesText = "importer.schoolclasses_import_description";

export const vehiclesText = "importer.vehicles_import_description";

export const subcontractorText = "importer.subcontractor_import_description";

export const exportSpecificToolText = "importer.export_specific_tool_description";

export const exportSimpleToolText = "importer.export_toolwithquantity_description";

export const exportConsumableText = "importer.export_consumables_description";

function SelectImportToolType(props) {
    let elems;
    const patternInput = useTranslation();
    const matchValue = props.State.CustomerConfiguration;
    if (matchValue == null) {
        return defaultOf();
    }
    else {
        const config = matchValue;
        const btnText = patternInput[0]("general.open");
        return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = toList(delay(() => append(singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "importer.specific_tool",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "tool"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: "importer.specific_tool_description",
        })), delay(() => append(singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "importer.simple_tool",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "toolwithquantity"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: "importer.simple_tool_description",
        })), delay(() => append(singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "general.consumable",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "consumable"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: consumableText,
        })), delay(() => append(singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "general.category",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "label"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: labelsText,
        })), delay(() => append(config.ReceiverVisibilityConfiguration.Users ? singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "receivers.user",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "user"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: userText,
        })) : empty(), delay(() => append(config.ReceiverVisibilityConfiguration.Employees ? singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "receivers.employee",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "employee"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: employeesText,
        })) : empty(), delay(() => append(config.ReceiverVisibilityConfiguration.Projects ? singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "receivers.project",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "project"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: projectsText,
        })) : empty(), delay(() => append(config.ReceiverVisibilityConfiguration.Rooms ? singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "receivers.room",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "room"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: roomsText,
        })) : empty(), delay(() => append(config.ReceiverVisibilityConfiguration.Subcontractos ? singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "receivers.subcontractor",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "subcontractor"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: subcontractorText,
        })) : empty(), delay(() => append(config.ReceiverVisibilityConfiguration.ConstructionSites ? singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "receivers.constructionsite",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "constructionsites"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: constructionSiteText,
        })) : empty(), delay(() => append(config.ReceiverVisibilityConfiguration.ExternalPersons ? singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "receivers.externalperson",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "externalperson"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: externalPersonText,
        })) : empty(), delay(() => append(config.ReceiverVisibilityConfiguration.Students ? singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "receivers.student",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "student"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: studentsText,
        })) : empty(), delay(() => append(config.ReceiverVisibilityConfiguration.SchoolClasses ? singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "receivers.school_class",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "schoolclasses"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: schoolClassesText,
        })) : empty(), delay(() => append(config.ReceiverVisibilityConfiguration.Vehicles ? singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "receivers.vehicles",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "vehicles"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: vehiclesText,
        })) : empty(), delay(() => singleton(createElement(SelectListItem, {
            BtnText: btnText,
            BtnTitleTranslationKey: "tool.last_check",
            Callback: () => {
                RouterModule_nav(ofArray(["importer", "lastinspectiondates"]), 1, 1);
            },
            IsDisabled: props.FormState === "disabled",
            TranslationKey: vehiclesText,
        })))))))))))))))))))))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
    }
}

function SelectExportToolType(props) {
    let elems;
    const t = useTranslation()[0];
    const btnText = t("general.download_table");
    const patternInput_1 = reactApi.useState(false);
    const setIsFullDataBackupDialogOpen = patternInput_1[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = toList(delay(() => append(singleton(createElement(OpenFullDataExportDialog, {
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setIsFullDataBackupDialogOpen(false);
        },
        SuccessCallback: () => {
            setIsFullDataBackupDialogOpen(false);
        },
    })), delay(() => append(singleton(createElement(SelectListItem, {
        BtnText: btnText,
        BtnTitleTranslationKey: "importer.specific_tool",
        Callback: () => {
            props.Dispatch(new Msg(3, []));
        },
        IsDisabled: props.FormState === "disabled",
        TranslationKey: exportSpecificToolText,
    })), delay(() => append(singleton(createElement(SelectListItem, {
        BtnText: btnText,
        BtnTitleTranslationKey: "importer.simple_tool",
        Callback: () => {
            props.Dispatch(new Msg(4, []));
        },
        IsDisabled: props.FormState === "disabled",
        TranslationKey: exportSimpleToolText,
    })), delay(() => append(singleton(createElement(SelectListItem, {
        BtnText: btnText,
        BtnTitleTranslationKey: "general.consumable",
        Callback: () => {
            props.Dispatch(new Msg(5, []));
        },
        IsDisabled: props.FormState === "disabled",
        TranslationKey: exportConsumableText,
    })), delay(() => {
        let IsDisabled_3;
        return isFeatureEnabled(new Feature(14, [])) ? ((props.State.CurrentUser.Role === "administrator") ? singleton(createElement(SelectListItem, (IsDisabled_3 = (props.FormState === "disabled"), {
            BtnText: t("importer.full_data_export_dialog.request"),
            BtnTitleTranslationKey: "importer.full_data_export_dialog.title",
            Callback: () => {
                setIsFullDataBackupDialogOpen(true);
            },
            IsDisabled: IsDisabled_3,
            TranslationKey: exportConsumableText,
        }))) : empty()) : empty();
    })))))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

function ImportHistory(props) {
    let elems;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(undefined);
    const setIsDeleteDialogOpen = patternInput_1[1];
    const isDeleteDialogOpen = patternInput_1[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems = toList(delay(() => {
        let matchValue;
        return append((matchValue = isDeleteDialogOpen, (matchValue == null) ? (empty()) : singleton(createElement(ImportDeleteDialog, {
            ImportId: matchValue,
            IsOpen: isDeleteDialogOpen != null,
            OnClose: () => {
                setIsDeleteDialogOpen(undefined);
            },
            SuccessCallback: () => {
                props.Dispatch(new Msg(0, []));
            },
        }))), delay(() => {
            let TableRows;
            return singleton(createElement(Table, (TableRows = toArray(props.History), {
                ColumnDefinitions: [new ColumnDefinition$1("date", "", t("general.date"), (dto) => (new CellContent(5, [dto.Timestamp])), "dateRange", "date"), new ColumnDefinition$1("importedElements", "", t("general.quantity_3"), (dto_1) => (new CellContent(0, [int32ToString(dto_1.ImportedElements)])), "none", "text"), new ColumnDefinition$1("importType", "", t("importer.type"), (dto_2) => (new CellContent(0, [t(dto_2.ImportType)])), "select", "text"), new ColumnDefinition$1("commission", "", t("importer.is_deleted"), (dto_3) => (dto_3.IsDeleted ? (new CellContent(0, [t("general.yes")])) : (new CellContent(0, [t("general.no")]))), "select", "text"), new ColumnDefinition$1("delete", "", t("general.delete"), (dto_4) => (dto_4.IsDeleted ? (new CellContent(0, [""])) : ((dto_4.ImportType === "importer.tool") ? (new CellContent(1, [createElement(TextButton, {
                    ComponentState: "enabled",
                    Label: t("general.delete"),
                    OnClick: () => {
                        setIsDeleteDialogOpen(dto_4.Id);
                    },
                    TestId: "importer-select-open-button",
                    Variant: "blueButton",
                })])) : (new CellContent(0, [""])))), "select", "text")],
                Controls: empty_1(),
                Options: {
                    BorderStyle: "full",
                    CSVDownload: false,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["date", "asc"],
                    GlobalSearch: false,
                    RowSelection: "disabled",
                },
                TableRows: TableRows,
            })));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function View(props) {
    let elems_4;
    let patternInput;
    const init = init_1(props.CurrentUser);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const formState = state_1.Loading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_4 = toList(delay(() => {
        let elems_2, elems, elems_1;
        const matchValue = state_1.ImportHistory;
        return (matchValue.tag === 0) ? singleton(createElement(Skeleton, {
            Variant: "table",
        })) : append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(BlockHeader, {
            Text: "Importer",
        }), createElement(SelectImportToolType, {
            FormState: formState,
            State: state_1,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(BlockHeader, {
            Text: "Exporter",
        }), createElement(SelectExportToolType, {
            Dispatch: dispatch,
            FormState: formState,
            State: state_1,
        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
            let elems_3;
            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems_3 = [createElement(BlockHeader, {
                Text: patternInput_1[0]("importer.history_header"),
            }), createElement(ImportHistory, {
                Dispatch: dispatch,
                FormState: formState,
                History: matchValue.fields[0],
            })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

