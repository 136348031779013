import { join, substring } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { min, max } from "../fable_modules/fable-library-js.4.19.2/Double.js";
import { createElement } from "react";
import React from "react";

function getRGB(c) {
    return parseInt(c, 16);
}

function getsRGB(c) {
    const rgb = getRGB(c);
    if ((rgb / 255) <= 0.03928) {
        return (rgb / 255) / 12.92;
    }
    else {
        return Math.pow(((rgb / 255) + 0.055) / 1.055, 2.4);
    }
}

function getLuminance(hexColor) {
    return ((0.2126 * getsRGB(substring(hexColor, 1, 2))) + (0.7152 * getsRGB(substring(hexColor, 3, 2)))) + (0.0722 * getsRGB(substring(hexColor, hexColor.length - 2)));
}

function getContrast(f, b) {
    const L1 = getLuminance(f);
    const L2 = getLuminance(b);
    return (max(L1, L2) + 0.05) / (min(L1, L2) + 0.05);
}

function getTextColor(bgColor) {
    if (getContrast(bgColor, "#ffffff") > getContrast(bgColor, "#000000")) {
        return "#ffffff";
    }
    else {
        return "#000000";
    }
}

export function Badge(props) {
    return createElement("div", {
        style: {
            backgroundColor: props.Color,
            color: getTextColor(props.Color),
        },
        className: join(" ", ["flex", "h-6", "text-xs", "mr-2", "px-2.5", "py-0.5", "rounded-xl", "items-center", "justify-center", "whitespace-nowrap"]),
        children: props.Label,
        "data-test-id": props.TestId,
    });
}

