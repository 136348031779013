import { Helper_containsConsumableId, Helper_containsToolId, ElementType } from "../../Shared/Dispolist.js";
import { selectWithLabel, toNoOptionsMessage, CommonProps$1, OptionType$1 } from "../../SharedComponents/ReactSelect.js";
import { tryFind, append } from "../../fable_modules/fable-library-js.4.19.2/Array.js";
import { value as value_104 } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { DispolistElementRow, Msg } from "../Types.js";
import { stringHash, createObj, int32ToString, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { contains, singleton as singleton_1, ofArray, empty, choose, filter, map, toArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { ConsumableId, ToolId } from "../../Shared/Shared.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { printf, toText, replace, isNullOrWhiteSpace, join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { parse as parse_1 } from "../../fable_modules/fable-library-js.4.19.2/Int32.js";
import { parse as parse_2 } from "../../fable_modules/fable-library-js.4.19.2/Double.js";
import { Helper_unitToHumanString } from "../../Shared/Consumable.js";
import { TextButton, DropdownButton, Button } from "../../Components/Button.js";
import { PageHeader } from "../../Components/Text.js";
import { colProp_csvPrint_Z1FBCCD16, colProp_csvFormatter_Z2DAA2C97, Formatter$1, colProp_formatter_Z11BEB8A2, ColumnFilter$1, colProp_filter_770189E8, SortFunction$1, colProp_sortFunction_Z3EEDBD7A, colProp_label_Z721C83C5, colProp_selector_Z721C83C5, tableColumn, tableProp_columns_ZCDDEA8C, tableProp_tableKey_Z721C83C5, tableProp_rows_5CDFDA7, tableProp_defaultSortField_Z384F8060, tableProp_keyField_Z721C83C5, ReactTable } from "../../SharedComponents/ReactTable.js";
import { unwrapDispolistId } from "../../Shared/Helper.js";
import { RouterModule_encodeParts, RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import Recycle_Bin_1 from "../../../public/assets/icons/Recycle_Bin_1.svg";

function toolTypeSelectProps(selectedValue, dispatch) {
    const options = [new OptionType$1("tool.specific_tool", new ElementType(1, [])), new OptionType$1("tool.simple_tool", new ElementType(0, [])), new OptionType$1("general.consumable", new ElementType(2, []))];
    return append([new CommonProps$1(1, [options]), new CommonProps$1(2, [(s) => {
        dispatch(new Msg(12, [value_104(s).value]));
    }]), new CommonProps$1(5, [true]), new CommonProps$1(9, [false]), new CommonProps$1(6, ["dispolist.tool_type_placeholder"]), new CommonProps$1(10, [(_arg) => toNoOptionsMessage("dispolist.tool_type_not_found", _arg)]), new CommonProps$1(11, ["flex-grow-1"])], [new CommonProps$1(4, [tryFind((o) => {
        if (selectedValue == null) {
            return false;
        }
        else {
            return equals(selectedValue, o.value);
        }
    }, options)])]);
}

function toolSelectProps(dispolist, currentDispolistSelectElementType, currentElement, state, dispatch) {
    const options = toArray(map((t_2) => (new OptionType$1(t_2.AdditionalName, t_2)), filter((t_1) => {
        const matchValue = t_1.Type;
        switch (matchValue.tag) {
            case 0:
                return !Helper_containsToolId(dispolist, new ToolId(parse(t_1.Id)));
            case 2:
                return !Helper_containsConsumableId(dispolist, new ConsumableId(parse(t_1.Id)));
            default:
                return !Helper_containsToolId(dispolist, new ToolId(parse(t_1.Id)));
        }
    }, filter((t) => equals(t.Type, currentDispolistSelectElementType), state.Tools))));
    return append([new CommonProps$1(0, [options]), new CommonProps$1(2, [(s) => {
        dispatch(new Msg(13, [value_104(s).value]));
    }]), new CommonProps$1(5, [true]), new CommonProps$1(9, [false]), new CommonProps$1(6, ["dispolist.tool_placeholder"]), new CommonProps$1(10, [(_arg) => toNoOptionsMessage("dispolist.tool_not_found", _arg)]), new CommonProps$1(11, ["flex-grow-1"])], [new CommonProps$1(3, [tryFind((o) => {
        if (currentElement == null) {
            return false;
        }
        else {
            return equals(currentElement, o.value);
        }
    }, options)])]);
}

function NewDispolistForm(props) {
    let elems_3;
    const dispolist = props.Form.Dispolist;
    const t = useTranslation()[0];
    const rows = map((e) => {
        switch (e.tag) {
            case 1: {
                const t_1 = e.fields[0];
                return new DispolistElementRow(t_1.DisplayName, t_1.Id, "1", undefined, new ElementType(1, []));
            }
            case 2: {
                const t_2 = e.fields[0];
                return new DispolistElementRow(t_2.DisplayName, t_2.Id, int32ToString(t_2.Quantity), undefined, new ElementType(0, []));
            }
            default: {
                const c = e.fields[0];
                return new DispolistElementRow(c.DisplayName, c.Id, c.Quantity.toString(), c.Unit, new ElementType(2, []));
            }
        }
    }, props.Form.Dispolist.Elements);
    const notValidRowIds = props.State.IsValidated ? choose((e_1) => {
        switch (e_1.tag) {
            case 2:
                return e_1.fields[0].Id;
            case 0:
                return e_1.fields[0].Id;
            default:
                return undefined;
        }
    }, props.Form.Dispolist.Elements) : empty();
    return createElement("div", createObj(ofArray([["id", "settings-form"], (elems_3 = toList(delay(() => {
        let matchValue;
        return append_1((matchValue = props.State.Message, (matchValue != null) ? singleton(createElement(ErrorAlert, {
            Label: matchValue,
        })) : singleton(defaultOf())), delay(() => {
            let elems_2;
            return singleton(createElement("div", createObj(ofArray([["className", "form-group"], (elems_2 = toList(delay(() => append_1(singleton(createElement("label", {
                htmlFor: "input-dispolistname",
                className: "form-label",
                children: t("dispolist.name"),
            })), delay(() => {
                let value_16;
                return append_1(singleton(createElement("input", createObj(ofArray([["id", "input-dispolistname"], ["className", join(" ", toList(delay(() => append_1(singleton("form-control mb-3"), delay(() => ((props.State.IsValidated && isNullOrWhiteSpace(dispolist.Name)) ? singleton("is-invalid") : empty_1()))))))], ["data-cy", "dispolistname-input"], (value_16 = dispolist.Name, ["ref", (e_2) => {
                    if (!(e_2 == null) && !equals(e_2.value, value_16)) {
                        e_2.value = value_16;
                    }
                }]), ["onChange", (ev) => {
                    props.Dispatch(new Msg(9, [ev.target.value]));
                }], ["placeholder", "Monteurliste"]])))), delay(() => append_1((props.State.IsValidated && isNullOrWhiteSpace(dispolist.Name)) ? singleton(createElement("div", {
                    className: "invalid-feedback",
                    children: t("dispolist.invalid_name"),
                })) : empty_1(), delay(() => append_1(singleton(createElement("label", {
                    htmlFor: "input-dispolist-description",
                    className: "form-label",
                    children: t("dispolist.description"),
                })), delay(() => {
                    let value_37;
                    return append_1(singleton(createElement("input", createObj(ofArray([["id", "input-dispolist-description"], ["className", join(" ", ["form-control mb-3"])], ["data-cy", "dispolistname-input"], (value_37 = dispolist.Description, ["ref", (e_3) => {
                        if (!(e_3 == null) && !equals(e_3.value, value_37)) {
                            e_3.value = value_37;
                        }
                    }]), ["onChange", (ev_1) => {
                        props.Dispatch(new Msg(10, [ev_1.target.value]));
                    }], ["placeholder", "Beschreibung Monteurliste"]])))), delay(() => append_1(singleton(selectWithLabel(toolTypeSelectProps(props.Form.CurrentDispolistSelectElementType, props.Dispatch), t("dispolist.choose_tool_type"), "")), delay(() => append_1(singleton(selectWithLabel(toolSelectProps(props.Form.Dispolist, props.Form.CurrentDispolistSelectElementType, props.Form.CurrentElement, props.State, props.Dispatch), t("dispolist.choose_tool"), "")), delay(() => {
                        let elems;
                        return append_1(singleton(createElement("div", createObj(ofArray([["className", "controls d-flex"], (elems = [createElement("button", {
                            className: "btn btn-primary ml-auto mb-3",
                            onClick: (arg_2) => {
                                props.Dispatch(new Msg(14, [arg_2]));
                            },
                            children: t("dispolist.add"),
                        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                            let children_15, elems_1, children_2, children, children_12;
                            return singleton((children_15 = singleton_1(createElement("table", createObj(ofArray([["className", "table"], (elems_1 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
                                children: t("general.name"),
                            }), createElement("th", {
                                children: t("general.quantity_3"),
                            }), createElement("th", {
                                children: t("general.unit"),
                            }), createElement("th", {
                                children: t("general.action"),
                            })]), createElement("tr", {
                                children: reactApi.Children.toArray(Array.from(children)),
                            }))), createElement("thead", {
                                children: reactApi.Children.toArray(Array.from(children_2)),
                            })), (children_12 = map((row) => {
                                let children_4, children_6, children_8;
                                const rowIsInvalid = contains(row.Id, notValidRowIds, {
                                    Equals: (x, y) => (x === y),
                                    GetHashCode: stringHash,
                                });
                                const children_10 = ofArray([createElement("td", {
                                    children: [row.Name],
                                }), (children_4 = toList(delay(() => {
                                    let value_71, value_87;
                                    const matchValue_1 = row.Type;
                                    return (matchValue_1.tag === 0) ? append_1(singleton(createElement("input", createObj(ofArray([["id", row.Id], ["className", join(" ", toList(delay(() => append_1(singleton("form-control"), delay(() => ((props.State.IsValidated && rowIsInvalid) ? singleton("is-invalid") : empty_1()))))))], ["data-cy", "dispolist-element-input"], (value_71 = row.Quantity, ["ref", (e_4) => {
                                        if (!(e_4 == null) && !equals(e_4.value, value_71)) {
                                            e_4.value = value_71;
                                        }
                                    }]), ["onChange", (ev_2) => {
                                        const value_73 = ev_2.target.value;
                                        props.Dispatch(new Msg(16, [row.Id, value_104((() => {
                                            try {
                                                return (parse_1(value_73, 511, false, 32) > 0) ? parse_1(value_73, 511, false, 32) : undefined;
                                            }
                                            catch (matchValue_2) {
                                                return undefined;
                                            }
                                        })())]));
                                    }], ["placeholder", ""]])))), delay(() => ((props.State.IsValidated && rowIsInvalid) ? singleton(createElement("div", {
                                        className: "invalid-feedback",
                                        children: t("dispolist.invalid_number"),
                                    })) : empty_1()))) : ((matchValue_1.tag === 2) ? append_1(singleton(createElement("input", createObj(ofArray([["id", row.Id], ["className", join(" ", toList(delay(() => append_1(singleton("form-control"), delay(() => ((props.State.IsValidated && rowIsInvalid) ? singleton("is-invalid") : empty_1()))))))], ["data-cy", "dispolist-element-input"], (value_87 = row.Quantity, ["ref", (e_5) => {
                                        if (!(e_5 == null) && !equals(e_5.value, value_87)) {
                                            e_5.value = value_87;
                                        }
                                    }]), ["onChange", (ev_3) => {
                                        const value_89 = ev_3.target.value;
                                        let parsedQuantity_1;
                                        try {
                                            parsedQuantity_1 = parse_2(replace(value_89, ",", "."));
                                        }
                                        catch (matchValue_3) {
                                            parsedQuantity_1 = undefined;
                                        }
                                        props.Dispatch(new Msg(17, [row.Id, 0]));
                                    }], ["placeholder", ""]])))), delay(() => ((props.State.IsValidated && rowIsInvalid) ? singleton(createElement("div", {
                                        className: "invalid-feedback",
                                        children: t("dispolist.invalid_number"),
                                    })) : empty_1()))) : singleton(row.Quantity));
                                })), createElement("td", {
                                    children: reactApi.Children.toArray(Array.from(children_4)),
                                })), (children_6 = toList(delay(() => {
                                    const matchValue_4 = row.Type;
                                    switch (matchValue_4.tag) {
                                        case 2: {
                                            const matchValue_5 = row.Unit;
                                            return (matchValue_5 == null) ? singleton(defaultOf()) : singleton(Helper_unitToHumanString(matchValue_5));
                                        }
                                        default:
                                            return singleton(createElement("p", {
                                                children: t("unit.piece"),
                                            }));
                                    }
                                })), createElement("td", {
                                    children: reactApi.Children.toArray(Array.from(children_6)),
                                })), (children_8 = singleton_1(createElement(Button, {
                                    ComponentState: "enabled",
                                    Label: "",
                                    OnClick: () => {
                                        props.Dispatch(new Msg(15, [row.Id]));
                                    },
                                    TestId: "settings-dispolist-delete-button",
                                    Variant: "secondary",
                                })), createElement("td", {
                                    children: reactApi.Children.toArray(Array.from(children_8)),
                                }))]);
                                return createElement("tr", {
                                    children: reactApi.Children.toArray(Array.from(children_10)),
                                });
                            }, rows), createElement("tbody", {
                                children: reactApi.Children.toArray(Array.from(children_12)),
                            }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("div", {
                                children: reactApi.Children.toArray(Array.from(children_15)),
                            })));
                        }));
                    }))))));
                }))))));
            })))), ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function NewDispolistView(props) {
    let elems_1, elems;
    const patternInput = useTranslation();
    const matchValue = props.State.DispolistForm;
    switch (matchValue.tag) {
        case 1:
        case 2:
            return defaultOf();
        default:
            return createElement("div", createObj(ofArray([["id", "settings-form-container"], ["className", "flex-grow-1 d-flex flex-column"], (elems_1 = [createElement(PageHeader, {
                Text: "settings.create_new_dispolist",
            }), createElement(NewDispolistForm, {
                Dispatch: props.Dispatch,
                Form: matchValue.fields[0],
                State: props.State,
            }), createElement("div", createObj(ofArray([["className", "controls d-flex"], (elems = [createElement("button", {
                className: "btn btn-primary ml-auto save-submit",
                onClick: (arg) => {
                    props.Dispatch(new Msg(18, [arg]));
                },
                children: patternInput[0]("general.save"),
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
    }
}

function ViewDispolistForm(props) {
    let elems_1, elems;
    const t = useTranslation()[0];
    const rows = map((e) => {
        switch (e.tag) {
            case 1: {
                const t_1 = e.fields[0];
                return new DispolistElementRow(t_1.DisplayName, t_1.Id, "1", undefined, new ElementType(1, []));
            }
            case 2: {
                const t_2 = e.fields[0];
                return new DispolistElementRow(t_2.DisplayName, t_2.Id, int32ToString(t_2.Quantity), undefined, new ElementType(0, []));
            }
            default: {
                const c = e.fields[0];
                return new DispolistElementRow(c.DisplayName, c.Id, c.Quantity.toString(), c.Unit, new ElementType(2, []));
            }
        }
    }, props.Dispolist.Elements);
    return createElement("div", createObj(ofArray([["id", "settings-form"], ["className", join(" ", ["disabled-form"])], (elems_1 = [createElement("div", createObj(ofArray([["className", "form-group"], (elems = toList(delay(() => append_1(singleton(createElement("label", {
        htmlFor: "input-dispolistname",
        className: "form-label",
        children: t("dispolist.name"),
    })), delay(() => {
        let value_17;
        return append_1(singleton(createElement("input", createObj(ofArray([["id", "input-dispolist"], ["className", join(" ", toList(delay(() => append_1(singleton("form-control"), delay(() => ((props.State.IsValidated && isNullOrWhiteSpace(props.Dispolist.Name)) ? singleton("is-invalid") : empty_1()))))))], ["data-cy", "dispolistname-input"], (value_17 = props.Dispolist.Name, ["ref", (e_1) => {
            if (!(e_1 == null) && !equals(e_1.value, value_17)) {
                e_1.value = value_17;
            }
        }]), ["onChange", (ev) => {
            props.Dispatch(new Msg(9, [ev.target.value]));
        }], ["placeholder", "Monteurliste"], ["disabled", true]])))), delay(() => append_1((props.State.IsValidated && isNullOrWhiteSpace(props.Dispolist.Name)) ? singleton(createElement("div", {
            className: "invalid-feedback",
            children: t("dispolist.invalid_name"),
        })) : empty_1(), delay(() => append_1(singleton(createElement("label", {
            htmlFor: "input-dispolist-description",
            className: "form-label",
            children: t("dispolist.description"),
        })), delay(() => {
            let value_40;
            return append_1(singleton(createElement("input", createObj(ofArray([["id", "input-dispolist-description"], ["className", join(" ", ["form-control"])], ["data-cy", "dispolistname-input"], (value_40 = props.Dispolist.Description, ["ref", (e_2) => {
                if (!(e_2 == null) && !equals(e_2.value, value_40)) {
                    e_2.value = value_40;
                }
            }]), ["onChange", (ev_1) => {
                props.Dispatch(new Msg(10, [ev_1.target.value]));
            }], ["placeholder", "Beschreibung Monteurliste"], ["disabled", true]])))), delay(() => {
                let children;
                return singleton((children = singleton_1(createElement(ReactTable, {
                    props: ofArray([tableProp_keyField_Z721C83C5("Id"), tableProp_defaultSortField_Z384F8060("Name", "asc"), tableProp_rows_5CDFDA7(rows), tableProp_tableKey_Z721C83C5("dispolistelements"), tableProp_columns_ZCDDEA8C(ofArray([tableColumn(ofArray([colProp_selector_Z721C83C5("Name"), colProp_label_Z721C83C5("general.name"), colProp_sortFunction_Z3EEDBD7A(new SortFunction$1(1, [])), colProp_filter_770189E8(new ColumnFilter$1(1, []))])), tableColumn(ofArray([colProp_selector_Z721C83C5("Quantity"), colProp_label_Z721C83C5("general.quantity_3"), colProp_sortFunction_Z3EEDBD7A(new SortFunction$1(1, []))])), tableColumn(ofArray([colProp_selector_Z721C83C5("Unit"), colProp_label_Z721C83C5("general.unit"), colProp_sortFunction_Z3EEDBD7A(new SortFunction$1(1, [])), colProp_formatter_Z11BEB8A2(new Formatter$1(4, [(_arg, row) => {
                        const matchValue = row.Type;
                        switch (matchValue.tag) {
                            case 2: {
                                const matchValue_1 = row.Unit;
                                return (matchValue_1 == null) ? defaultOf() : Helper_unitToHumanString(matchValue_1);
                            }
                            default:
                                return createElement("p", {
                                    children: t("unit.piece"),
                                });
                        }
                    }])), colProp_csvFormatter_Z2DAA2C97((_arg_2, row_1) => {
                        const matchValue_2 = row_1.Type;
                        switch (matchValue_2.tag) {
                            case 2: {
                                const matchValue_3 = row_1.Unit;
                                return (matchValue_3 == null) ? defaultOf() : Helper_unitToHumanString(matchValue_3);
                            }
                            default:
                                return Helper_unitToHumanString("piece");
                        }
                    })]))]))]),
                })), createElement("div", {
                    children: reactApi.Children.toArray(Array.from(children)),
                })));
            }));
        }))))));
    })))), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function ViewDispolistView(props) {
    let elems_2, elems_1, elems;
    const patternInput = useTranslation();
    const matchValue = props.State.DispolistForm;
    switch (matchValue.tag) {
        case 1:
        case 0:
            return defaultOf();
        default: {
            const form = matchValue.fields[0];
            const dispolistId = unwrapDispolistId(form.Id);
            return createElement("div", createObj(ofArray([["id", "settings-form-container"], ["className", "flex-grow-1 d-flex flex-column"], (elems_2 = [createElement("div", createObj(ofArray([["className", "d-flex"], (elems_1 = [createElement(PageHeader, {
                Text: "dispolist.dispolist_detail_header",
            }), createElement("div", createObj(ofArray([["className", "mb-2 d-flex flex-grow-1 align-items-end justify-content-end"], (elems = [createElement(DropdownButton, {
                Classes: empty(),
                ComponentState: "enabled",
                DisplayArrow: true,
                Elements: singleton_1({
                    DisplayName: patternInput[0]("general.edit"),
                    OnClick: () => {
                        RouterModule_nav(singleton_1(`/settings/edit/dispolist/${dispolistId}`), 1, 1);
                    },
                }),
                TestId: "",
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement(ViewDispolistForm, {
                Dispatch: props.Dispatch,
                Dispolist: form,
                State: props.State,
            })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
        }
    }
}

function EditDispolistForm(props) {
    let elems_3;
    const dispolist = props.Form.Dispolist;
    const t = useTranslation()[0];
    const rows = map((e) => {
        switch (e.tag) {
            case 1: {
                const t_1 = e.fields[0];
                return new DispolistElementRow(t_1.DisplayName, t_1.Id, "1", undefined, new ElementType(1, []));
            }
            case 2: {
                const t_2 = e.fields[0];
                return new DispolistElementRow(t_2.DisplayName, t_2.Id, int32ToString(t_2.Quantity), undefined, new ElementType(0, []));
            }
            default: {
                const c = e.fields[0];
                return new DispolistElementRow(c.DisplayName, c.Id, c.Quantity.toString(), c.Unit, new ElementType(2, []));
            }
        }
    }, props.Form.Dispolist.Elements);
    const notValidRowIds = props.State.IsValidated ? choose((e_1) => {
        switch (e_1.tag) {
            case 2:
                return e_1.fields[0].Id;
            case 0:
                return e_1.fields[0].Id;
            default:
                return undefined;
        }
    }, props.Form.Dispolist.Elements) : empty();
    return createElement("div", createObj(ofArray([["id", "settings-form"], (elems_3 = toList(delay(() => {
        let matchValue;
        return append_1((matchValue = props.State.Message, (matchValue != null) ? singleton(createElement(ErrorAlert, {
            Label: matchValue,
        })) : singleton(defaultOf())), delay(() => {
            let elems_2;
            return singleton(createElement("div", createObj(ofArray([["className", "form-group"], (elems_2 = toList(delay(() => append_1(singleton(createElement("label", {
                htmlFor: "input-dispolistname",
                className: "form-label",
                children: t("dispolist.name"),
            })), delay(() => {
                let value_16;
                return append_1(singleton(createElement("input", createObj(ofArray([["id", "input-dispolistname"], ["className", join(" ", toList(delay(() => append_1(singleton("form-control mb-3"), delay(() => ((props.State.IsValidated && isNullOrWhiteSpace(dispolist.Name)) ? singleton("is-invalid") : empty_1()))))))], ["data-cy", "dispolistname-input"], (value_16 = dispolist.Name, ["ref", (e_2) => {
                    if (!(e_2 == null) && !equals(e_2.value, value_16)) {
                        e_2.value = value_16;
                    }
                }]), ["onChange", (ev) => {
                    props.Dispatch(new Msg(9, [ev.target.value]));
                }], ["placeholder", "Monteurliste"]])))), delay(() => append_1((props.State.IsValidated && isNullOrWhiteSpace(dispolist.Name)) ? singleton(createElement("div", {
                    className: "invalid-feedback",
                    children: t("dispolist.invalid_name"),
                })) : empty_1(), delay(() => append_1(singleton(createElement("label", {
                    htmlFor: "input-dispolist-description",
                    className: "form-label",
                    children: t("dispolist.description"),
                })), delay(() => {
                    let value_37;
                    return append_1(singleton(createElement("input", createObj(ofArray([["id", "input-dispolist-description"], ["className", join(" ", ["form-control mb-3"])], ["data-cy", "dispolistname-input"], (value_37 = dispolist.Description, ["ref", (e_3) => {
                        if (!(e_3 == null) && !equals(e_3.value, value_37)) {
                            e_3.value = value_37;
                        }
                    }]), ["onChange", (ev_1) => {
                        props.Dispatch(new Msg(10, [ev_1.target.value]));
                    }], ["placeholder", "Beschreibung Monteurliste"]])))), delay(() => append_1(singleton(selectWithLabel(toolTypeSelectProps(props.Form.CurrentDispolistSelectElementType, props.Dispatch), t("dispolist.choose_tool_type"), "")), delay(() => append_1(singleton(selectWithLabel(toolSelectProps(props.Form.Dispolist, props.Form.CurrentDispolistSelectElementType, props.Form.CurrentElement, props.State, props.Dispatch), t("dispolist.choose_tool"), "")), delay(() => {
                        let elems;
                        return append_1(singleton(createElement("div", createObj(ofArray([["className", "controls d-flex"], (elems = [createElement("button", {
                            className: "btn btn-primary ml-auto mb-3",
                            onClick: (arg_2) => {
                                props.Dispatch(new Msg(14, [arg_2]));
                            },
                            children: t("dispolist.add"),
                        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                            let children_15, elems_1, children_2, children, children_12;
                            return singleton((children_15 = singleton_1(createElement("table", createObj(ofArray([["className", "table"], (elems_1 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
                                children: t("general.name"),
                            }), createElement("th", {
                                children: t("general.quantity_3"),
                            }), createElement("th", {
                                children: t("general.unit"),
                            }), createElement("th", {
                                children: t("general.action"),
                            })]), createElement("tr", {
                                children: reactApi.Children.toArray(Array.from(children)),
                            }))), createElement("thead", {
                                children: reactApi.Children.toArray(Array.from(children_2)),
                            })), (children_12 = map((row) => {
                                let children_4, children_6, children_8;
                                const rowIsInvalid = contains(row.Id, notValidRowIds, {
                                    Equals: (x, y) => (x === y),
                                    GetHashCode: stringHash,
                                });
                                const children_10 = ofArray([createElement("td", {
                                    children: [row.Name],
                                }), (children_4 = toList(delay(() => {
                                    let value_71, value_87;
                                    const matchValue_1 = row.Type;
                                    return (matchValue_1.tag === 0) ? append_1(singleton(createElement("input", createObj(ofArray([["id", row.Id], ["className", join(" ", toList(delay(() => append_1(singleton("form-control"), delay(() => ((props.State.IsValidated && rowIsInvalid) ? singleton("is-invalid") : empty_1()))))))], ["data-cy", "dispolist-element-input"], (value_71 = row.Quantity, ["ref", (e_4) => {
                                        if (!(e_4 == null) && !equals(e_4.value, value_71)) {
                                            e_4.value = value_71;
                                        }
                                    }]), ["onChange", (ev_2) => {
                                        const value_73 = ev_2.target.value;
                                        let parsedQuantity;
                                        try {
                                            parsedQuantity = ((parse_1(value_73, 511, false, 32) > 0) ? parse_1(value_73, 511, false, 32) : undefined);
                                        }
                                        catch (matchValue_2) {
                                            parsedQuantity = undefined;
                                        }
                                        props.Dispatch(new Msg(16, [row.Id, 0]));
                                    }], ["placeholder", ""]])))), delay(() => ((props.State.IsValidated && rowIsInvalid) ? singleton(createElement("div", {
                                        className: "invalid-feedback",
                                        children: t("dispolist.invalid_number"),
                                    })) : empty_1()))) : ((matchValue_1.tag === 2) ? append_1(singleton(createElement("input", createObj(ofArray([["id", row.Id], ["className", join(" ", toList(delay(() => append_1(singleton("form-control"), delay(() => ((props.State.IsValidated && rowIsInvalid) ? singleton("is-invalid") : empty_1()))))))], ["data-cy", "dispolist-element-input"], (value_87 = row.Quantity, ["ref", (e_5) => {
                                        if (!(e_5 == null) && !equals(e_5.value, value_87)) {
                                            e_5.value = value_87;
                                        }
                                    }]), ["onChange", (ev_3) => {
                                        const value_89 = ev_3.target.value;
                                        let parsedQuantity_1;
                                        try {
                                            parsedQuantity_1 = parse_2(replace(value_89, ",", "."));
                                        }
                                        catch (matchValue_3) {
                                            parsedQuantity_1 = undefined;
                                        }
                                        props.Dispatch(new Msg(17, [row.Id, 0]));
                                    }], ["placeholder", ""]])))), delay(() => ((props.State.IsValidated && rowIsInvalid) ? singleton(createElement("div", {
                                        className: "invalid-feedback",
                                        children: t("dispolist.invalid_number"),
                                    })) : empty_1()))) : singleton(row.Quantity));
                                })), createElement("td", {
                                    children: reactApi.Children.toArray(Array.from(children_4)),
                                })), (children_6 = toList(delay(() => {
                                    const matchValue_4 = row.Type;
                                    switch (matchValue_4.tag) {
                                        case 2: {
                                            const matchValue_5 = row.Unit;
                                            return (matchValue_5 == null) ? singleton(defaultOf()) : singleton(Helper_unitToHumanString(matchValue_5));
                                        }
                                        default:
                                            return singleton(createElement("p", {
                                                children: t("unit.piece"),
                                            }));
                                    }
                                })), createElement("td", {
                                    children: reactApi.Children.toArray(Array.from(children_6)),
                                })), (children_8 = singleton_1(createElement(TextButton, {
                                    ComponentState: "enabled",
                                    Icon: Recycle_Bin_1(),
                                    Label: "",
                                    OnClick: () => {
                                        props.Dispatch(new Msg(15, [row.Id]));
                                    },
                                    TestId: "profile-resetpassword-button",
                                    Variant: "default",
                                })), createElement("td", {
                                    children: reactApi.Children.toArray(Array.from(children_8)),
                                }))]);
                                return createElement("tr", {
                                    children: reactApi.Children.toArray(Array.from(children_10)),
                                });
                            }, rows), createElement("tbody", {
                                children: reactApi.Children.toArray(Array.from(children_12)),
                            }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("div", {
                                children: reactApi.Children.toArray(Array.from(children_15)),
                            })));
                        }));
                    }))))));
                }))))));
            })))), ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function EditDispolistView(props) {
    let elems_2, elems, elems_1;
    const patternInput = useTranslation();
    const matchValue = props.State.DispolistForm;
    switch (matchValue.tag) {
        case 2:
        case 0:
            return defaultOf();
        default:
            return createElement("div", createObj(ofArray([["id", "settings-form-container"], ["className", "flex-grow-1 d-flex flex-column"], (elems_2 = [createElement("div", createObj(ofArray([["className", "d-flex"], (elems = [createElement(PageHeader, {
                Text: "dispolist.dispolist_edit_header",
            })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(EditDispolistForm, {
                Dispatch: props.Dispatch,
                Form: matchValue.fields[0],
                State: props.State,
            }), createElement("div", createObj(ofArray([["className", "controls d-flex"], (elems_1 = [createElement("button", {
                className: "btn btn-primary ml-auto save-submit",
                onClick: (arg) => {
                    props.Dispatch(new Msg(18, [arg]));
                },
                children: patternInput[0]("general.save"),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }
}

export function OverviewDispolistView(props) {
    let elems_2, elems_1, children;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", "d-flex flex-column"], (elems_2 = [createElement("div", createObj(ofArray([["className", "ml-auto controls"], (elems_1 = [createElement(Button, {
        ComponentState: "enabled",
        Label: t("settings.create_new_dispolist"),
        OnClick: () => {
            RouterModule_nav(singleton_1("/settings/new/dispolist"), 1, 1);
        },
        TestId: "passwordreset-login-reset-button",
        Variant: "primary",
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), (children = singleton_1(createElement(ReactTable, {
        props: ofArray([tableProp_keyField_Z721C83C5("Id"), tableProp_defaultSortField_Z384F8060("Name", "asc"), tableProp_rows_5CDFDA7(props.State.Dispolists), tableProp_tableKey_Z721C83C5("labels"), tableProp_columns_ZCDDEA8C(ofArray([tableColumn(ofArray([colProp_selector_Z721C83C5("Name"), colProp_label_Z721C83C5("general.name"), colProp_sortFunction_Z3EEDBD7A(new SortFunction$1(1, [])), colProp_filter_770189E8(new ColumnFilter$1(1, []))])), tableColumn(ofArray([colProp_selector_Z721C83C5("SumTools"), colProp_label_Z721C83C5("dispolist.sum_tools"), colProp_sortFunction_Z3EEDBD7A(new SortFunction$1(1, [])), colProp_csvPrint_Z1FBCCD16(false)])), tableColumn(ofArray([colProp_selector_Z721C83C5("SumToolsWithQuantity"), colProp_label_Z721C83C5("dispolist.sum_tools_with_quantity"), colProp_sortFunction_Z3EEDBD7A(new SortFunction$1(1, [])), colProp_csvPrint_Z1FBCCD16(false)])), tableColumn(ofArray([colProp_selector_Z721C83C5("SumConsumables"), colProp_label_Z721C83C5("dispolist.sum_consumables"), colProp_sortFunction_Z3EEDBD7A(new SortFunction$1(1, [])), colProp_csvPrint_Z1FBCCD16(false)])), tableColumn(ofArray([colProp_selector_Z721C83C5("Aktion"), colProp_label_Z721C83C5("general.action"), colProp_formatter_Z11BEB8A2(new Formatter$1(4, [(_arg_1, row) => {
            let elems;
            const id = unwrapDispolistId(row.Id);
            return createElement("a", createObj(ofArray([["href", RouterModule_encodeParts(singleton_1(RouterModule_encodeParts(singleton_1(toText(printf("/settings/view/dispolist/%O"))(id)), 1)), 1)], (elems = [createElement("i", {
                className: "fas fa-eye",
            }), createElement("span", {
                className: "url-text",
                children: t("general.display"),
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
        }])), colProp_csvPrint_Z1FBCCD16(false)]))]))]),
    })), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

