import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { DataTransfer_CreateOrUpdateSet_$reflection, DataTransfer_AssignedTool_$reflection } from "../../Shared/Set.js";
import { union_type, int32_type, array_type, anonRecord_type, record_type, string_type, class_type, bool_type, option_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ClientApiResponse$1_$reflection, LabelId_$reflection, FormKind_$reflection, FormState_$reflection, Label_$reflection, Customer_$reflection, TokenDataDto_$reflection, SetId_$reflection, RequestedValue$1_$reflection } from "../../Shared/Shared.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { Location_$reflection } from "../../Shared/Location.js";
import { ToolDto_$reflection } from "../../Shared/Tool.js";
import { PropertyConfigurationEnabledResponse_$reflection, CustomProperty_$reflection, PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { OptionType$1_$reflection } from "../../SharedComponents/ReactSelect.js";
import { FileUploadResponse_$reflection } from "../../Shared/File.js";

export class State extends Record {
    constructor(Tools, SetForm, Storages, Locations, SetId, IsValidated, UserData, Customer, Labels, SelectedTools, PurchaseDate, WarrantyDate, ImageViewerOpen, Manufacturers, PropertyConfigurations, SelectedFile, IsCustomPropertiesEnabled, FormState, FormKind) {
        super();
        this.Tools = Tools;
        this.SetForm = SetForm;
        this.Storages = Storages;
        this.Locations = Locations;
        this.SetId = SetId;
        this.IsValidated = IsValidated;
        this.UserData = UserData;
        this.Customer = Customer;
        this.Labels = Labels;
        this.SelectedTools = SelectedTools;
        this.PurchaseDate = PurchaseDate;
        this.WarrantyDate = WarrantyDate;
        this.ImageViewerOpen = ImageViewerOpen;
        this.Manufacturers = Manufacturers;
        this.PropertyConfigurations = PropertyConfigurations;
        this.SelectedFile = SelectedFile;
        this.IsCustomPropertiesEnabled = IsCustomPropertiesEnabled;
        this.FormState = FormState;
        this.FormKind = FormKind;
    }
}

export function State_$reflection() {
    return record_type("Sets.Form.Types.State", [], State, () => [["Tools", RequestedValue$1_$reflection(list_type(DataTransfer_AssignedTool_$reflection()))], ["SetForm", RequestedValue$1_$reflection(DataTransfer_CreateOrUpdateSet_$reflection())], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["Locations", RequestedValue$1_$reflection(list_type(Location_$reflection()))], ["SetId", option_type(SetId_$reflection())], ["IsValidated", bool_type], ["UserData", option_type(TokenDataDto_$reflection())], ["Customer", option_type(Customer_$reflection())], ["Labels", RequestedValue$1_$reflection(list_type(Label_$reflection()))], ["SelectedTools", list_type(ToolDto_$reflection())], ["PurchaseDate", option_type(class_type("System.DateTime"))], ["WarrantyDate", option_type(class_type("System.DateTime"))], ["ImageViewerOpen", bool_type], ["Manufacturers", list_type(string_type)], ["PropertyConfigurations", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["SelectedFile", option_type(class_type("Browser.Types.File", undefined))], ["IsCustomPropertiesEnabled", RequestedValue$1_$reflection(bool_type)], ["FormState", FormState_$reflection()], ["FormKind", FormKind_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetManufacturer", "SetDisplayName", "SetModel", "SetSerialNumber", "SetInventoryNumber", "SelectStorage", "SetStorageSpace", "SetComment", "AddLabelId", "SetCustomProperties", "SetQrCode", "SetSupplier", "SetArticleNumber", "SetPrice", "SetPurchaseDate", "SetWarrantyDate", "SetCommercialDataComment", "AssignToolId", "RemoveToolId", "OpenImageViewer", "CloseImageViewer", "SaveSet", "UpdateSet", "FetchSet", "FetchCategories", "FetchTools", "DeleteQRCodes", "ToolsFetched", "SetBasisFetched", "SetUpdated", "UpdatedAfterImageUpload", "SetCreated", "StoragesFetched", "LabelsFetched", "ManufacturersFetched", "LocationsFetched", "PropertyConfigurationsFetched", "SetSelectedFile", "ImageUploaded", "CustomPropertiesEnabledFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Sets.Form.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", anonRecord_type(["label", string_type], ["value", string_type])]], [["Item", string_type]], [["Item", string_type]], [["Item", array_type(OptionType$1_$reflection(LabelId_$reflection()))]], [["Item", list_type(CustomProperty_$reflection())]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", option_type(int32_type)]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", string_type]], [["Item", list_type(string_type)]], [["Item", list_type(string_type)]], [], [], [], [], [], [], [], [["Item", list_type(string_type)]], [["Item", list_type(DataTransfer_AssignedTool_$reflection())]], [["Item", DataTransfer_CreateOrUpdateSet_$reflection()]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", list_type(Storage_$reflection())]], [["Item", list_type(Label_$reflection())]], [["Item", list_type(string_type)]], [["Item", list_type(Location_$reflection())]], [["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", option_type(class_type("Browser.Types.File", undefined))]], [["Item", FileUploadResponse_$reflection()]], [["Item", PropertyConfigurationEnabledResponse_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

