import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { AccountStatistic_$reflection, RequestedValue$1_$reflection, AccountSettings_$reflection } from "../../Shared/Shared.js";
import { union_type, class_type, record_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class State extends Record {
    constructor(AccountSettings, CustomerPortalUrl, AccountStatistic) {
        super();
        this.AccountSettings = AccountSettings;
        this.CustomerPortalUrl = CustomerPortalUrl;
        this.AccountStatistic = AccountStatistic;
    }
}

export function State_$reflection() {
    return record_type("CustomerData.Detail.Types.State", [], State, () => [["AccountSettings", RequestedValue$1_$reflection(AccountSettings_$reflection())], ["CustomerPortalUrl", RequestedValue$1_$reflection(string_type)], ["AccountStatistic", RequestedValue$1_$reflection(AccountStatistic_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AccountSettingsFetched", "CustomerPortalUrlFetched", "AccountStatisticFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("CustomerData.Detail.Types.Msg", [], Msg, () => [[["Item", AccountSettings_$reflection()]], [["Item", string_type]], [["Item", AccountStatistic_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

