import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { LocationId_$reflection } from "./Shared.js";
import { union_type, record_type, class_type, bool_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class Location extends Record {
    constructor(Id, Name, Description, ImageUrl, IsDeleted, CreatedAt, UpdatedAt) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.ImageUrl = ImageUrl;
        this.IsDeleted = IsDeleted;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function Location_$reflection() {
    return record_type("Shared.Location.Location", [], Location, () => [["Id", LocationId_$reflection()], ["Name", string_type], ["Description", string_type], ["ImageUrl", string_type], ["IsDeleted", bool_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export class DeleteLocationResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Deleted", "IsInUse"];
    }
}

export function DeleteLocationResponse_$reflection() {
    return union_type("Shared.Location.DeleteLocationResponse", [], DeleteLocationResponse, () => [[], []]);
}

