import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../../../Components/Input.js";
import { Msg } from "./Types.js";
import { unwrap } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { FormState__getValidation } from "../../../Shared/Shared.js";

export function PrinzingSpecificForm(props) {
    const t = useTranslation()[0];
    return createElement(Input, {
        ComponentState: props.ComponentState,
        Label: t("tool.prinzing.check_number"),
        OnChange: (arg) => {
            props.Dispatch(new Msg(18, [arg]));
        },
        TestId: "",
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "prinzingCheckNumber")),
        Value: props.PrinzingSpecific.CheckNumber,
    });
}

