import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, bool_type, union_type, class_type, string_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { PropertyConfigurationId_$reflection, ClientApiResponse$1_$reflection } from "../../../Shared/Shared.js";
import { Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../../../CommunicationV2.js";
import { Types_RequestProperties } from "../../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { unwrapPropertyConfigurationId } from "../../../Shared/Helper.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ConfirmationDialog } from "../../../Components/Dialog.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CustomPropertyDeletedResponse", "DeleteCustomProperty", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Settings.CustomProperty.Dialogs.DeleteCustomPropertyDialog.Msg", [], Msg, () => [[["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", class_type("System.Exception")]]]);
}

function deleteCustomProperty(propertyConfigurationId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), `/api/property-configurations/${unwrapPropertyConfigurationId(propertyConfigurationId)}`, (Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(2, [Item_1])));
}

class State extends Record {
    constructor(PropertyConfigurationId, IsLoading, SuccessCallback, OnClose) {
        super();
        this.PropertyConfigurationId = PropertyConfigurationId;
        this.IsLoading = IsLoading;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
    }
}

function State_$reflection() {
    return record_type("Settings.CustomProperty.Dialogs.DeleteCustomPropertyDialog.State", [], State, () => [["PropertyConfigurationId", PropertyConfigurationId_$reflection()], ["IsLoading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)]]);
}

function init(props) {
    return [new State(props.PropertyConfigurationId, false, props.SuccessCallback, props.OnClose), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.PropertyConfigurationId, true, state.SuccessCallback, state.OnClose), deleteCustomProperty(state.PropertyConfigurationId)];
        case 2:
            return [new State(state.PropertyConfigurationId, false, state.SuccessCallback, state.OnClose), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.PropertyConfigurationId, false, state.SuccessCallback, state.OnClose), Cmd_errorToast("settings.custom_property.not_enabled_info")];
            }
            else {
                return [new State(state.PropertyConfigurationId, false, state.SuccessCallback, state.OnClose), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast("general.saved_successfully")]))];
            }
    }
}

export function DeleteCustomPropertyDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        PropertyConfigurationId: props.PropertyConfigurationId,
        SuccessCallback: props.SuccessCallback,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    return createElement(ConfirmationDialog, (Title = t("general.delete"), {
        Description: t("settings.custom_property.delete_msg"),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        OnConfirm: patternInput_1[0].IsLoading ? (() => {
        }) : (() => {
            patternInput_1[1](new Msg(1, []));
        }),
        Title: Title,
    }));
}

