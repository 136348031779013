import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { float64_type, int32_type, list_type, bool_type, union_type, record_type, option_type, class_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { DispolistResponse_$reflection, DispolistOverview_$reflection, DispolistElement_$reflection, Dispolist_$reflection, ElementType_$reflection } from "../Shared/Dispolist.js";
import { CustomerConfigurationResponseResult_$reflection, LabelResponse_$reflection, PostResponse$1_$reflection, CustomerConfiguration_$reflection, ReceiverVisibilityConfiguration_$reflection, Label_$reflection } from "../Shared/Shared.js";

export class DispolistElementRow extends Record {
    constructor(Name, Id, Quantity, Unit, Type) {
        super();
        this.Name = Name;
        this.Id = Id;
        this.Quantity = Quantity;
        this.Unit = Unit;
        this.Type = Type;
    }
}

export function DispolistElementRow_$reflection() {
    return record_type("Settings.Types.DispolistElementRow", [], DispolistElementRow, () => [["Name", string_type], ["Id", class_type("System.Guid")], ["Quantity", string_type], ["Unit", option_type(string_type)], ["Type", ElementType_$reflection()]]);
}

export class SelectedTab extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Label"];
    }
}

export function SelectedTab_$reflection() {
    return union_type("Settings.Types.SelectedTab", [], SelectedTab, () => [[]]);
}

export class LabelForm extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["LabelForm"];
    }
}

export function LabelForm_$reflection() {
    return union_type("Settings.Types.LabelForm", [], LabelForm, () => [[["Item", Label_$reflection()]]]);
}

export class NewDispolistForm extends Record {
    constructor(Dispolist, CurrentDispolistSelectElementType, CurrentElement) {
        super();
        this.Dispolist = Dispolist;
        this.CurrentDispolistSelectElementType = CurrentDispolistSelectElementType;
        this.CurrentElement = CurrentElement;
    }
}

export function NewDispolistForm_$reflection() {
    return record_type("Settings.Types.NewDispolistForm", [], NewDispolistForm, () => [["Dispolist", Dispolist_$reflection()], ["CurrentDispolistSelectElementType", ElementType_$reflection()], ["CurrentElement", option_type(DispolistElement_$reflection())]]);
}

export class EditDispolistForm extends Record {
    constructor(Dispolist, CurrentDispolistSelectElementType, CurrentElement) {
        super();
        this.Dispolist = Dispolist;
        this.CurrentDispolistSelectElementType = CurrentDispolistSelectElementType;
        this.CurrentElement = CurrentElement;
    }
}

export function EditDispolistForm_$reflection() {
    return record_type("Settings.Types.EditDispolistForm", [], EditDispolistForm, () => [["Dispolist", Dispolist_$reflection()], ["CurrentDispolistSelectElementType", ElementType_$reflection()], ["CurrentElement", option_type(DispolistElement_$reflection())]]);
}

export class DispolistForm extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NewDispolistForm", "EditDispolistForm", "DispolistForm"];
    }
}

export function DispolistForm_$reflection() {
    return union_type("Settings.Types.DispolistForm", [], DispolistForm, () => [[["Item", NewDispolistForm_$reflection()]], [["Item", EditDispolistForm_$reflection()]], [["Item", Dispolist_$reflection()]]]);
}

export class LabelRow extends Record {
    constructor(Id, Name, ColorHex) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.ColorHex = ColorHex;
    }
}

export function LabelRow_$reflection() {
    return record_type("Settings.Types.LabelRow", [], LabelRow, () => [["Id", string_type], ["Name", string_type], ["ColorHex", string_type]]);
}

export class ReceiverTypeForm extends Record {
    constructor(IsFormDisabled, OldReceiverVisibilityConfiguration) {
        super();
        this.IsFormDisabled = IsFormDisabled;
        this.OldReceiverVisibilityConfiguration = OldReceiverVisibilityConfiguration;
    }
}

export function ReceiverTypeForm_$reflection() {
    return record_type("Settings.Types.ReceiverTypeForm", [], ReceiverTypeForm, () => [["IsFormDisabled", bool_type], ["OldReceiverVisibilityConfiguration", option_type(ReceiverVisibilityConfiguration_$reflection())]]);
}

export class State extends Record {
    constructor(Labels, LabelTable, LabelForm, Dispolists, DispolistForm, Tools, CustomerConfiguration, SelectedTab, Message, IsValidated, IsLabelColorSelectorOpen, ReceiverTypeForm) {
        super();
        this.Labels = Labels;
        this.LabelTable = LabelTable;
        this.LabelForm = LabelForm;
        this.Dispolists = Dispolists;
        this.DispolistForm = DispolistForm;
        this.Tools = Tools;
        this.CustomerConfiguration = CustomerConfiguration;
        this.SelectedTab = SelectedTab;
        this.Message = Message;
        this.IsValidated = IsValidated;
        this.IsLabelColorSelectorOpen = IsLabelColorSelectorOpen;
        this.ReceiverTypeForm = ReceiverTypeForm;
    }
}

export function State_$reflection() {
    return record_type("Settings.Types.State", [], State, () => [["Labels", list_type(Label_$reflection())], ["LabelTable", list_type(LabelRow_$reflection())], ["LabelForm", LabelForm_$reflection()], ["Dispolists", list_type(DispolistOverview_$reflection())], ["DispolistForm", DispolistForm_$reflection()], ["Tools", list_type(DispolistElement_$reflection())], ["CustomerConfiguration", option_type(CustomerConfiguration_$reflection())], ["SelectedTab", SelectedTab_$reflection()], ["Message", option_type(string_type)], ["IsValidated", bool_type], ["IsLabelColorSelectorOpen", bool_type], ["ReceiverTypeForm", ReceiverTypeForm_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OpenNewLabelForm", "SetName", "SetColorHex", "SetVisibleForUser", "SetSelectedTab", "SaveLabel", "UpdateLabel", "ToggleColorSelector", "UpdateCustomerConfigurationPermissions", "SetDispolistName", "SetDispolistDescription", "ToolsFetched", "SetToolType", "SelectTool", "AddElementToDispolist", "RemoveElementFromDispolist", "SetQuantityForToolWithQuantityElement", "SetQuantityForConsumableElement", "SaveDispolist", "DispolistSaved", "SetEditReceivers", "UpdateReceiverVisibilityConfiguration", "UpdateCustomerConfiguration", "LabelFetched", "LabelsFetched", "DispolistFetched", "DispolistsFetched", "LabelSaved", "CustomerConfigurationFetched", "CustomerConfigurationUpdated", "LabelDeleted", "DispolistDeleted", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Settings.Types.Msg", [], Msg, () => [[], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", SelectedTab_$reflection()]], [["Item", class_type("Browser.Types.MouseEvent", undefined)]], [["Item", class_type("Browser.Types.MouseEvent", undefined)]], [], [["Item", CustomerConfiguration_$reflection()]], [["Item", string_type]], [["Item", string_type]], [["Item", list_type(DispolistElement_$reflection())]], [["Item", ElementType_$reflection()]], [["Item", DispolistElement_$reflection()]], [["Item", class_type("Browser.Types.MouseEvent", undefined)]], [["Item", class_type("System.Guid")]], [["Item1", class_type("System.Guid")], ["Item2", int32_type]], [["Item1", class_type("System.Guid")], ["Item2", float64_type]], [["Item", class_type("Browser.Types.MouseEvent", undefined)]], [["Item", PostResponse$1_$reflection(DispolistResponse_$reflection())]], [], [["Item", ReceiverVisibilityConfiguration_$reflection()]], [], [["Item", Label_$reflection()]], [["Item", list_type(Label_$reflection())]], [["Item", Dispolist_$reflection()]], [["Item", list_type(DispolistOverview_$reflection())]], [["Item", PostResponse$1_$reflection(LabelResponse_$reflection())]], [["Item", CustomerConfiguration_$reflection()]], [["Item", PostResponse$1_$reflection(CustomerConfigurationResponseResult_$reflection())]], [], [], [["Item", class_type("System.Exception")]]]);
}

