import { createElement } from "react";
import React from "react";
import { round, createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { PositiveNumberInput } from "../Components/Input.js";
import { numberInputIntMaxValue } from "../Common.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { tryFind, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { useTranslation } from "react-i18next";
import { unwrap, defaultArg } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { Select } from "../Components/Select.js";

function Input(props) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["w-14", "mr-1.5"])], (elems = [createElement(PositiveNumberInput, {
        ComponentState: props.ComponentState,
        MaxValue: numberInputIntMaxValue,
        OnChange: (value_1) => {
            if (value_1 == null) {
                props.SetValue(0);
            }
            else {
                props.SetValue(~~round(value_1));
            }
        },
        TestId: "",
        Value: props.Value,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function DayWeekMonthYearInput(props) {
    let elems_1, elems;
    const t = useTranslation()[0];
    let patternInput_1;
    const initial = defaultArg(props.InitialInterval, "day");
    patternInput_1 = reactApi.useState(initial);
    const selectedInterval = patternInput_1[0];
    const options = props.IsBefore ? ofArray([{
        label: t("custom_interval_before.days"),
        value: "day",
    }, {
        label: t("custom_interval_before.months"),
        value: "month",
    }, {
        label: t("custom_interval_before.weeks"),
        value: "week",
    }, {
        label: t("custom_interval_before.years"),
        value: "year",
    }]) : ofArray([{
        label: t("custom_interval.days"),
        value: "day",
    }, {
        label: t("custom_interval.months"),
        value: "month",
    }, {
        label: t("custom_interval.weeks"),
        value: "week",
    }, {
        label: t("custom_interval.years"),
        value: "year",
    }]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center"])], (elems_1 = [createElement(Input, {
        ComponentState: props.ComponentState,
        SetValue: (value_2) => {
            props.SetValue([selectedInterval, value_2]);
        },
        Value: props.Value,
    }), createElement("div", createObj(ofArray([["className", join(" ", ["grow"])], (elems = [createElement(Select, {
        ComponentState: "enabled",
        IsClearable: false,
        NoOptionsMessage: "",
        OnChange: (option_1) => {
            if (option_1 == null) {
            }
            else {
                const option_2 = option_1;
                patternInput_1[1](option_2.value);
                props.SetValue([option_2.value, props.Value]);
            }
        },
        Options: options,
        PlaceholderKey: "",
        TestId: "",
        Value: unwrap(tryFind((option_3) => (option_3.value === selectedInterval), options)),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

