import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { printf, toText, join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { append, singleton, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, initToolBoxDispolist } from "./State.js";
import { spinner } from "../../SharedComponents/Spinners.js";
import { PageHeader } from "../../Components/Text.js";
import { ColumnFilter$1, colProp_filter_770189E8, Formatter$1, colProp_formatter_Z11BEB8A2, SortFunction$1, colProp_sortFunction_Z3EEDBD7A, colProp_label_Z721C83C5, colProp_selector_Z721C83C5, tableColumn, tableProp_columns_ZCDDEA8C, tableProp_rows_5CDFDA7, tableProp_defaultSortField_Z384F8060, tableProp_keyField_Z721C83C5, ReactTable } from "../../SharedComponents/ReactTable.js";
import { RouterModule_encodeParts } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";

export function UserDetails(props) {
    let elems_3, elems, value_17, elems_1, value_34, elems_2, value_50;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "user-form"], ["className", join(" ", toList(delay(() => singleton("disabled-form"))))], (elems_3 = [createElement("div", createObj(ofArray([["className", "form-group"], (elems = [createElement("label", {
        htmlFor: "input-firstname",
        className: "form-label",
        children: "Name",
    }), createElement("input", createObj(ofArray([["id", "input-firstname"], ["className", "form-control"], ["data-cy", "login-firstname-input"], (value_17 = props.ToolBoxDispolist.User.Name, ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_17)) {
            e.value = value_17;
        }
    }]), ["placeholder", "Phillipp"], ["disabled", true]])))], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("label", createObj(ofArray([["className", "mt-auto mb-auto mr-2"], (elems_1 = [t("general.dispolist")], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("input", createObj(ofArray([["id", "input-email"], ["className", "form-control"], ["data-cy", "login-email-input"], (value_34 = props.ToolBoxDispolist.DispolistName, ["ref", (e_1) => {
        if (!(e_1 == null) && !equals(e_1.value, value_34)) {
            e_1.value = value_34;
        }
    }]), ["placeholder", "name@meinwerkzeugkoffer.de"], ["disabled", true]]))), createElement("label", createObj(ofArray([["className", "mt-auto mb-auto mr-2"], (elems_2 = [t("general.sender")], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("input", createObj(ofArray([["id", "input-email"], ["className", "form-control"], ["data-cy", "login-email-input"], (value_50 = props.ToolBoxDispolist.Sender, ["ref", (e_2) => {
        if (!(e_2 == null) && !equals(e_2.value, value_50)) {
            e_2.value = value_50;
        }
    }]), ["placeholder", "name@meinwerkzeugkoffer.de"], ["disabled", true]])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function View(props) {
    let elems_7;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => initToolBoxDispolist(props.UserId, props.EventId, undefined), update_1, (_arg, _arg_1) => {
    }), undefined, [props.UserId, props.EventId]);
    const patternInput_1 = useTranslation();
    return createElement("div", createObj(ofArray([["id", "toolbox-container"], ["className", "flex-grow-1 d-flex flex-column"], (elems_7 = toList(delay(() => {
        let elems_1;
        const matchValue = patternInput[0].ToolBoxDispolist;
        if (matchValue == null) {
            return singleton(spinner);
        }
        else {
            const toolBoxDispolist = matchValue;
            return append(singleton(createElement("div", createObj(ofArray([["className", "d-flex flex-shrink-0"], (elems_1 = [createElement(PageHeader, {
                Text: "toolbox.detail_toolbox_dispolist",
            }), createElement("div", {
                className: "mb-2 d-flex flex-grow-1 align-items-end justify-content-end",
                children: reactApi.Children.toArray([]),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let toolBoxDispolist_1, elems_6, elems_5, children;
                return singleton((toolBoxDispolist_1 = toolBoxDispolist, createElement("div", createObj(singleton_1((elems_6 = [createElement("div", createObj(ofArray([["className", "user-details d-flex mb-3"], (elems_5 = [createElement(UserDetails, {
                    ToolBoxDispolist: toolBoxDispolist_1,
                })], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))), (children = singleton_1(createElement(ReactTable, {
                    props: ofArray([tableProp_keyField_Z721C83C5("Id"), tableProp_defaultSortField_Z384F8060("EventDate", "desc"), tableProp_rows_5CDFDA7(toolBoxDispolist_1.Rows), tableProp_columns_ZCDDEA8C(ofArray([tableColumn(ofArray([colProp_selector_Z721C83C5("ElementType"), colProp_label_Z721C83C5("general.tool_type"), colProp_sortFunction_Z3EEDBD7A(new SortFunction$1(1, [])), colProp_formatter_Z11BEB8A2(new Formatter$1(1, [])), colProp_filter_770189E8(new ColumnFilter$1(3, [ofArray(["general.consumable", "general.tool"]), (a) => a.ElementType]))])), tableColumn(ofArray([colProp_selector_Z721C83C5("Name"), colProp_label_Z721C83C5("general.tool"), colProp_sortFunction_Z3EEDBD7A(new SortFunction$1(1, [])), colProp_filter_770189E8(new ColumnFilter$1(1, [])), colProp_formatter_Z11BEB8A2(new Formatter$1(4, [(_arg_2, dto) => {
                        let elems_3, elems_4, elems_2;
                        const row = dto;
                        const matchValue_1 = row.Id;
                        return (matchValue_1.tag === 1) ? createElement("a", createObj(ofArray([["href", RouterModule_encodeParts(singleton_1(RouterModule_encodeParts(singleton_1(toText(printf("/tools/view/toolwithquantity/%O"))(matchValue_1.fields[0].fields[0])), 1)), 1)], (elems_3 = [createElement("span", {
                            className: "ml-1 url-text",
                            children: row.Name,
                        })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))) : ((matchValue_1.tag === 2) ? createElement("a", createObj(ofArray([["href", RouterModule_encodeParts(singleton_1(RouterModule_encodeParts(singleton_1(toText(printf("/consumables/view/%O"))(matchValue_1.fields[0].fields[0])), 1)), 1)], (elems_4 = [createElement("span", {
                            className: "ml-1 url-text",
                            children: row.Name,
                        })], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))) : createElement("a", createObj(ofArray([["href", RouterModule_encodeParts(singleton_1(RouterModule_encodeParts(singleton_1(toText(printf("/tools/view/tool/%O"))(matchValue_1.fields[0].fields[0])), 1)), 1)], (elems_2 = [createElement("span", {
                            className: "ml-1 url-text",
                            children: row.Name,
                        })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
                    }]))])), tableColumn(ofArray([colProp_selector_Z721C83C5("Quantity"), colProp_label_Z721C83C5("general.quantity_3"), colProp_sortFunction_Z3EEDBD7A(new SortFunction$1(1, [])), colProp_filter_770189E8(new ColumnFilter$1(1, []))])), tableColumn(ofArray([colProp_selector_Z721C83C5("Storage"), colProp_label_Z721C83C5("general.storage"), colProp_sortFunction_Z3EEDBD7A(new SortFunction$1(1, [])), colProp_filter_770189E8(new ColumnFilter$1(1, []))]))]))]),
                })), createElement("div", {
                    children: reactApi.Children.toArray(Array.from(children)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_6))]))))));
            }));
        }
    })), ["children", reactApi.Children.toArray(Array.from(elems_7))])])));
}

