import { Variant, debugActive } from "./Variant.js";
import i18next from "i18next";
import i18next_http_backend from "i18next-http-backend";
import i18next_browser_languagedetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { i18nextPlugin } from "translation-check";

const i18nOptions = (() => {
    const fallbackLng = ["de"];
    const supportedLngs = ["de", "en", "fr", "it", "pl", "uk"];
    const preload = ["de", "en", "fr", "it", "pl", "uk"];
    const interpolation = {
        escapeValue: false,
    };
    const ns = ["mwk", "i24", "1014"];
    return {
        backend: {
            loadPath: "/assets/locales/{{ns}}/{{lng}}.json",
            requestOptions: {
                cache: "no-store",
            },
        },
        debug: debugActive,
        defaultNS: (Variant.tag === 1) ? "i24" : ((Variant.tag === 2) ? "i24" : "mwk"),
        fallbackLng: fallbackLng,
        fallbackNS: "mwk",
        interpolation: interpolation,
        ns: ns,
        preload: preload,
        react: {
            useSuspense: false,
        },
        supportedLngs: supportedLngs,
    };
})();

export const i18n = (() => {
    let i18n_2;
    const i18n_1 = ((i18next.use(i18next_http_backend)).use(i18next_browser_languagedetector)).use(initReactI18next);
    i18n_2 = (debugActive ? (i18n_1.use(i18nextPlugin)) : i18n_1);
    i18n_2.init(i18nOptions);
})();

