import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { DeleteLocationResponse_$reflection, Location_$reflection } from "../Shared/Location.js";
import { union_type, class_type, record_type, bool_type, option_type, string_type, list_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { RequestedValue$1_$reflection } from "../Shared/Shared.js";

export class State extends Record {
    constructor(Locations, Form, Message, IsInvalid) {
        super();
        this.Locations = Locations;
        this.Form = Form;
        this.Message = Message;
        this.IsInvalid = IsInvalid;
    }
}

export function State_$reflection() {
    return record_type("Location.Types.State", [], State, () => [["Locations", RequestedValue$1_$reflection(list_type(Location_$reflection()))], ["Form", RequestedValue$1_$reflection(Location_$reflection())], ["Message", option_type(string_type)], ["IsInvalid", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetName", "SetDescription", "CreateLocation", "UpdateLocation", "DeleteLocation", "LocationFetched", "LocationsFetched", "LocationSaved", "FetchError", "SaveError", "LocationDeleted"];
    }
}

export function Msg_$reflection() {
    return union_type("Location.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [], [], [], [["Item", Location_$reflection()]], [["Item", list_type(Location_$reflection())]], [["Item", Location_$reflection()]], [["Item", class_type("System.Exception")]], [["Item", class_type("System.Exception")]], [["Item", DeleteLocationResponse_$reflection()]]]);
}

