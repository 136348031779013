import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";

export function PageHeader(props) {
    const patternInput = useTranslation();
    return createElement("div", {
        className: join(" ", ["font-ubuntu", "font-bold", "text-4xl", "text-black", "mb-4"]),
        children: patternInput[0](props.Text),
    });
}

export function SectionHeader(props) {
    const patternInput = useTranslation();
    return createElement("div", {
        className: join(" ", ["font-bold", "text-2xl", "text-black", "mb-4"]),
        children: patternInput[0](props.Text),
    });
}

export function BlockHeader(props) {
    const patternInput = useTranslation();
    return createElement("div", {
        className: join(" ", ["font-bold", "text-sm", "text-black", "mb-4"]),
        children: patternInput[0](props.Text),
    });
}

export function BoxHeader(props) {
    const patternInput = useTranslation();
    return createElement("div", {
        className: join(" ", ["font-bold", "text-xl", "text-black", "mb-4"]),
        children: patternInput[0](props.Text),
    });
}

export function TextValue(props) {
    return createElement("div", {
        className: join(" ", toList(delay(() => {
            let matchValue;
            return append((matchValue = props.FontWeight, (matchValue === "semiBold") ? singleton("font-semibold") : ((matchValue === "normal") ? singleton("font-normal") : ((matchValue === "small") ? singleton("text-sm") : singleton("font-bold")))), delay(() => append(singleton("text-sm"), delay(() => append(singleton("text-black"), delay(() => singleton("whitespace-pre-wrap")))))));
        }))),
        children: props.Text,
        "data-test-id": props.TestId,
    });
}

export function LabeledValue(props) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["mb-3"])], (elems = [createElement("div", {
        className: join(" ", ["font-bold", "mb-1"]),
        children: props.Label,
    }), createElement("div", {
        className: join(" ", ["text-lg", "min-h-[28px]"]),
        children: props.Value,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function InvalidTextValue(props) {
    return createElement("div", {
        "data-test-id": props.TestId,
        className: join(" ", ["text-red", "text-sm"]),
        children: props.Text,
    });
}

