import { Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../Communication.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { contains, filter, sortByDescending, append, ofArray, empty, singleton } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { UserConfiguration as UserConfiguration_1, Requests_ArchiveReceiverDto_$reflection, Requests_UpdateReceiverArchivedResponse_$reflection, Requests_ArchiveReceiverDto, Requests_UpdateImageUrl_$reflection, Requests_UpdateReceiverImageResponse_$reflection, Requests_UpdateImageUrl, UserConfiguration_$reflection, Requests_DeleteReceiverImageResponse_$reflection } from "../Shared/User.js";
import { ReceiverDeleteResponse_$reflection, State, Msg } from "./Types.js";
import { EmptyResponse_$reflection, RequestedValue$1 } from "../Shared/Shared.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getCustomerConfiguration } from "../Requests/Configuration.js";
import { Cmd_getToolBox } from "../Requests/Toolbox.js";
import { Location_$reflection } from "../Shared/Location.js";
import { string_type, list_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../CommunicationV2.js";
import { PropertyConfiguration_$reflection } from "../Shared/PropertyConfiguration.js";
import { ValidationOnGetRequest } from "../ErrorHandling.js";
import { ReservationDto_$reflection } from "../Shared/Planning.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../Components/Toast.js";
import { uploadImage } from "../Requests/Files.js";
import { compare } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { safeHash, equals } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { unwrapUserId } from "../Shared/Helper.js";
import { Cmd_ofEffect } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { add } from "../fable_modules/fable-library-js.4.19.2/Map.js";

export function deleteImageCmd(receiverId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Requests_DeleteReceiverImageResponse_$reflection()));
    })))), `/api/receivers/image/${receiverId}`, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(16, [Item_1])));
}

function initialState(userData) {
    return new State(userData, undefined, empty(), userData.Customer, empty(), undefined, new RequestedValue$1(0, []), empty(), new RequestedValue$1(0, []), false, new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []));
}

export function init(userData) {
    return [initialState(userData), Cmd_batch(ofArray([Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), UserConfiguration_$reflection()));
    })))), "/api/users/configuration", (Item) => (new Msg(20, [Item])), (Item_1) => (new Msg(16, [Item_1]))), getCustomerConfiguration((Item_2) => (new Msg(7, [Item_2])), (Item_3) => (new Msg(16, [Item_3])))]))];
}

export function initToolBox(userId, userData) {
    return [initialState(userData), Cmd_batch(ofArray([Cmd_getToolBox(userId, (Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(16, [Item_1]))), Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Location_$reflection())));
    })))), "/api/locations", (Item_2) => (new Msg(8, [Item_2])), (Item_3) => (new Msg(16, [Item_3]))), Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_5;
        return ((url_5 = addPrefix_1(url_3), (refreshOn) => fetchWithDecoder_1(url_5, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
            const pr_1 = response_1.arrayBuffer();
            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_2 = _arg;
            return (result_2.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_2.fields[0]);
            })())) : (Promise.resolve(result_2.fields[0]));
        });
    })), "/api/property-configurations", (Item_4) => (new Msg(9, [Item_4])), (Item_5) => (new Msg(16, [Item_5]))), getCustomerConfiguration((Item_6) => (new Msg(7, [Item_6])), (Item_7) => (new Msg(16, [Item_7]))), Cmd_OfPromise_either_1((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn_1) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(ReservationDto_$reflection())));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_4 = _arg_1;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), `/api/receivers/${userId}/reservations`, (Item_8) => (new Msg(10, [Item_8])), (Item_9) => (new Msg(16, [Item_9])))]))];
}

export function update(msg, state) {
    let dto, archived, matchValue_8, userConfiguration_1;
    switch (msg.tag) {
        case 1:
            return [new State(state.UserData, state.ToolBox, state.HistoryRows, state.Customer, state.ConsumableHistoryRows, state.Message, state.CustomerConfiguration, state.SelectedTools, state.Locations, !state.TriggerRerender, state.PropertyConfigurations, state.UserConfiguration, state.Reservations), Cmd_none()];
        case 2:
            return [new State(state.UserData, state.ToolBox, state.HistoryRows, state.Customer, state.ConsumableHistoryRows, state.Message, state.CustomerConfiguration, state.SelectedTools, state.Locations, !state.TriggerRerender, state.PropertyConfigurations, state.UserConfiguration, state.Reservations), Cmd_none()];
        case 4: {
            const response = msg.fields[0];
            const matchValue_1 = state.ToolBox;
            let matchResult, toolbox_2, value;
            if (matchValue_1 == null) {
                matchResult = 2;
            }
            else {
                switch (response.tag) {
                    case 1: {
                        matchResult = 0;
                        toolbox_2 = matchValue_1;
                        value = response.fields[0];
                        break;
                    }
                    case 2: {
                        matchResult = 1;
                        break;
                    }
                    default:
                        matchResult = 2;
                }
            }
            switch (matchResult) {
                case 0:
                    return [state, (dto = (new Requests_UpdateImageUrl(value.ImageUrl)), Cmd_OfPromise_either_1((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response_1) => {
                        const pr = response_1.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Requests_UpdateReceiverImageResponse_$reflection()));
                    })))), [`/api/receivers/image-url/${toolbox_2[0].Receiver.Id}`, toString(0, Auto_generateBoxedEncoder_437914C6(Requests_UpdateImageUrl_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(16, [Item_1]))))];
                case 1:
                    return [state, Cmd_errorToast("general.a_error_occured")];
                default:
                    return [state, Cmd_none()];
            }
        }
        case 3: {
            const file = msg.fields[0];
            const formData = new FormData();
            formData.append(file.name, file);
            return [state, uploadImage((Item_2) => (new Msg(4, [Item_2])), (Item_3) => (new Msg(16, [Item_3])), formData)];
        }
        case 12: {
            const matchValue_3 = state.ToolBox;
            if (matchValue_3 != null) {
                return [state, (archived = (new Requests_ArchiveReceiverDto(msg.fields[0])), Cmd_OfPromise_either_1((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg_1[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_2) => {
                    const pr_1 = response_2.arrayBuffer();
                    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), Requests_UpdateReceiverArchivedResponse_$reflection()));
                })))), [`/api/receivers/${matchValue_3[0].Receiver.Id}/archive`, toString(0, Auto_generateBoxedEncoder_437914C6(Requests_ArchiveReceiverDto_$reflection(), undefined, undefined, undefined)(archived))], (Item_4) => (new Msg(13, [Item_4])), (Item_5) => (new Msg(16, [Item_5]))))];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 13: {
            const response_3 = msg.fields[0];
            switch (response_3.tag) {
                case 1:
                    return [state, Cmd_errorToast("general.receiver_tool_validation_error")];
                case 2:
                    return [state, Cmd_errorToast("general.a_error_occured")];
                default:
                    return [new State(state.UserData, state.ToolBox, state.HistoryRows, state.Customer, state.ConsumableHistoryRows, state.Message, state.CustomerConfiguration, state.SelectedTools, state.Locations, !state.TriggerRerender, state.PropertyConfigurations, state.UserConfiguration, state.Reservations), Cmd_batch(ofArray([Cmd_successToast("general.saved_successfully"), singleton((dispatch) => {
                        dispatch(new Msg(14, []));
                    })]))];
            }
        }
        case 5: {
            const toolBox_2 = msg.fields[0];
            return [new State(state.UserData, [toolBox_2, append(toolBox_2.ToolsWithQuantity, toolBox_2.Tools)], sortByDescending((row) => row.EventDate, toolBox_2.ReceiverHistory, {
                Compare: compare,
            }), state.Customer, state.ConsumableHistoryRows, state.Message, state.CustomerConfiguration, state.SelectedTools, state.Locations, state.TriggerRerender, state.PropertyConfigurations, state.UserConfiguration, state.Reservations), Cmd_none()];
        }
        case 6: {
            const tools = msg.fields[0];
            const isSelect = msg.fields[1];
            return [new State(state.UserData, state.ToolBox, state.HistoryRows, state.Customer, state.ConsumableHistoryRows, state.Message, state.CustomerConfiguration, msg.fields[2] ? (isSelect ? tools : empty()) : (isSelect ? append(tools, state.SelectedTools) : filter((t) => !contains(t, tools, {
                Equals: equals,
                GetHashCode: safeHash,
            }), state.SelectedTools)), state.Locations, state.TriggerRerender, state.PropertyConfigurations, state.UserConfiguration, state.Reservations), Cmd_none()];
        }
        case 15: {
            const matchValue_4 = state.ToolBox;
            if (matchValue_4 == null) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_OfPromise_either_1((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let url_8;
                    return ((url_8 = addPrefix_1(url_6), (refreshOn) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_4) => {
                        const pr_2 = response_4.arrayBuffer();
                        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(ReservationDto_$reflection())));
                    }, refreshOn)))(true).then((_arg) => {
                        const result_3 = _arg;
                        return (result_3.tag === 1) ? (Promise.resolve((() => {
                            throw new ValidationOnGetRequest(result_3.fields[0]);
                        })())) : (Promise.resolve(result_3.fields[0]));
                    });
                })), `/api/receivers/${matchValue_4[0].Receiver.Id}/reservations`, (Item_6) => (new Msg(10, [Item_6])), (Item_7) => (new Msg(16, [Item_7])))];
            }
        }
        case 14: {
            const matchValue_5 = state.ToolBox;
            if (matchValue_5 == null) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_getToolBox(matchValue_5[0].Receiver.Id, (Item_8) => (new Msg(5, [Item_8])), (Item_9) => (new Msg(16, [Item_9])))];
            }
        }
        case 7:
            return [new State(state.UserData, state.ToolBox, state.HistoryRows, state.Customer, state.ConsumableHistoryRows, state.Message, new RequestedValue$1(1, [msg.fields[0]]), state.SelectedTools, state.Locations, state.TriggerRerender, state.PropertyConfigurations, state.UserConfiguration, state.Reservations), Cmd_none()];
        case 8:
            return [new State(state.UserData, state.ToolBox, state.HistoryRows, state.Customer, state.ConsumableHistoryRows, state.Message, state.CustomerConfiguration, state.SelectedTools, new RequestedValue$1(1, [msg.fields[0]]), state.TriggerRerender, state.PropertyConfigurations, state.UserConfiguration, state.Reservations), Cmd_none()];
        case 9:
            return [new State(state.UserData, state.ToolBox, state.HistoryRows, state.Customer, state.ConsumableHistoryRows, state.Message, state.CustomerConfiguration, state.SelectedTools, state.Locations, state.TriggerRerender, new RequestedValue$1(1, [msg.fields[0]]), state.UserConfiguration, state.Reservations), Cmd_none()];
        case 10:
            return [new State(state.UserData, state.ToolBox, state.HistoryRows, state.Customer, state.ConsumableHistoryRows, state.Message, state.CustomerConfiguration, state.SelectedTools, state.Locations, state.TriggerRerender, state.PropertyConfigurations, state.UserConfiguration, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 17: {
            const userId = msg.fields[0];
            const matchValue_6 = state.ToolBox;
            if (matchValue_6 == null) {
                return [state, Cmd_none()];
            }
            else {
                const matchValue_7 = matchValue_6[0].Receiver.ReceiverType;
                switch (matchValue_7.tag) {
                    case 1:
                        return [state, Cmd_OfPromise_either_1((url_12) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_12), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response_6) => {
                            const pr_4 = response_6.arrayBuffer();
                            return pr_4.then((blob_4) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_4)), ReceiverDeleteResponse_$reflection()));
                        })))), `/api/receivers/employees/${unwrapUserId(userId)}`, (Item_12) => (new Msg(18, [Item_12])), (Item_13) => (new Msg(16, [Item_13])))];
                    case 2:
                        return [state, Cmd_OfPromise_either_1((url_15) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_15), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response_7) => {
                            const pr_5 = response_7.arrayBuffer();
                            return pr_5.then((blob_5) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_5)), ReceiverDeleteResponse_$reflection()));
                        })))), `/api/receivers/external-persons/${unwrapUserId(userId)}`, (Item_14) => (new Msg(18, [Item_14])), (Item_15) => (new Msg(16, [Item_15])))];
                    case 3:
                        return [state, Cmd_OfPromise_either_1((url_18) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_18), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response_8) => {
                            const pr_6 = response_8.arrayBuffer();
                            return pr_6.then((blob_6) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_6)), ReceiverDeleteResponse_$reflection()));
                        })))), `/api/receivers/subcontractors/${unwrapUserId(userId)}`, (Item_16) => (new Msg(18, [Item_16])), (Item_17) => (new Msg(16, [Item_17])))];
                    case 4:
                        return [state, Cmd_OfPromise_either_1((url_21) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_21), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response_9) => {
                            const pr_7 = response_9.arrayBuffer();
                            return pr_7.then((blob_7) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_7)), ReceiverDeleteResponse_$reflection()));
                        })))), `/api/receivers/rooms/${unwrapUserId(userId)}`, (Item_18) => (new Msg(18, [Item_18])), (Item_19) => (new Msg(16, [Item_19])))];
                    case 5:
                        return [state, Cmd_OfPromise_either_1((url_24) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_24), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response_10) => {
                            const pr_8 = response_10.arrayBuffer();
                            return pr_8.then((blob_8) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_8)), ReceiverDeleteResponse_$reflection()));
                        })))), `/api/receivers/projects/${unwrapUserId(userId)}`, (Item_20) => (new Msg(18, [Item_20])), (Item_21) => (new Msg(16, [Item_21])))];
                    case 6:
                        return [state, Cmd_OfPromise_either_1((url_27) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_27), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response_11) => {
                            const pr_9 = response_11.arrayBuffer();
                            return pr_9.then((blob_9) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_9)), ReceiverDeleteResponse_$reflection()));
                        })))), `/api/receivers/construction-sites/${unwrapUserId(userId)}`, (Item_22) => (new Msg(18, [Item_22])), (Item_23) => (new Msg(16, [Item_23])))];
                    case 7:
                        return [state, Cmd_OfPromise_either_1((url_30) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_30), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response_12) => {
                            const pr_10 = response_12.arrayBuffer();
                            return pr_10.then((blob_10) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_10)), ReceiverDeleteResponse_$reflection()));
                        })))), `/api/receivers/students/${unwrapUserId(userId)}`, (Item_24) => (new Msg(18, [Item_24])), (Item_25) => (new Msg(16, [Item_25])))];
                    case 8:
                        return [state, Cmd_OfPromise_either_1((url_33) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_33), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response_13) => {
                            const pr_11 = response_13.arrayBuffer();
                            return pr_11.then((blob_11) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_11)), ReceiverDeleteResponse_$reflection()));
                        })))), `/api/receivers/school-classes/${unwrapUserId(userId)}`, (Item_26) => (new Msg(18, [Item_26])), (Item_27) => (new Msg(16, [Item_27])))];
                    case 9:
                        return [state, Cmd_OfPromise_either_1((url_36) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_36), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response_14) => {
                            const pr_12 = response_14.arrayBuffer();
                            return pr_12.then((blob_12) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_12)), ReceiverDeleteResponse_$reflection()));
                        })))), `/api/receivers/vehicles/${unwrapUserId(userId)}`, (Item_28) => (new Msg(18, [Item_28])), (Item_29) => (new Msg(16, [Item_29])))];
                    default:
                        return [state, Cmd_OfPromise_either_1((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            let url_11;
                            return ((url_11 = addPrefix_1(url_9), (refreshOn_1) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response_5) => {
                                const pr_3 = response_5.arrayBuffer();
                                return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), string_type));
                            }, refreshOn_1)))(true);
                        })), `/api/receivers/users/${unwrapUserId(userId)}`, (Item_10) => (new Msg(19, [Item_10])), (Item_11) => (new Msg(16, [Item_11])))];
                }
            }
        }
        case 19: {
            const response_15 = msg.fields[0];
            if (response_15.tag === 1) {
                return [state, (matchValue_8 = response_15.fields[0].GlobalValidationMessage, (matchValue_8 == null) ? Cmd_none() : Cmd_errorToast(matchValue_8))];
            }
            else {
                return [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg_1) => {
                    RouterModule_nav(singleton("receivers"), 1, 1);
                }), Cmd_successToast("general.deleted_successfully")]))];
            }
        }
        case 18: {
            const response_16 = msg.fields[0];
            switch (response_16.tag) {
                case 1:
                    return [state, Cmd_batch(singleton(Cmd_errorToast("receivers.delete_self_error")))];
                case 2:
                    return [state, Cmd_errorToast("receivers.user_failed_delete_tools_assigned")];
                default:
                    return [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg_2) => {
                        RouterModule_nav(singleton("receivers"), 1, 1);
                    }), Cmd_successToast("general.deleted_successfully")]))];
            }
        }
        case 11:
            return [state, Cmd_getToolBox(msg.fields[0], (Item_30) => (new Msg(5, [Item_30])), (Item_31) => (new Msg(16, [Item_31])))];
        case 20:
            return [new State(state.UserData, state.ToolBox, state.HistoryRows, state.Customer, state.ConsumableHistoryRows, state.Message, state.CustomerConfiguration, state.SelectedTools, state.Locations, state.TriggerRerender, state.PropertyConfigurations, new RequestedValue$1(1, [msg.fields[0]]), state.Reservations), Cmd_none()];
        case 21:
            return [state, Cmd_none()];
        case 22: {
            const matchValue_9 = state.UserConfiguration;
            if (matchValue_9.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const userConfiguration = matchValue_9.fields[0];
                return [state, (userConfiguration_1 = (new UserConfiguration_1(add("receiver-overview-table", msg.fields[0], userConfiguration.TableConfiguration), userConfiguration.QuickOverviewConfiguration)), Cmd_OfPromise_either_1((tupledArg_2) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg_2[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_2[1]])]), "application/json", (response_18) => {
                    const pr_13 = response_18.arrayBuffer();
                    return pr_13.then((blob_13) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_13)), EmptyResponse_$reflection()));
                })))), ["/api/users/configuration", toString(0, Auto_generateBoxedEncoder_437914C6(UserConfiguration_$reflection(), undefined, undefined, undefined)(userConfiguration_1))], (Item_32) => (new Msg(21, [Item_32])), (Item_33) => (new Msg(16, [Item_33]))))];
            }
        }
        case 16:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.ToolBox;
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                return [state, deleteImageCmd(matchValue[0].Receiver.Id)];
            }
        }
    }
}

