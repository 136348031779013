import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { User_$reflection } from "../../Shared/User.js";
import { union_type, class_type, record_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { CustomerConfiguration_$reflection, RequestedValue$1_$reflection } from "../../Shared/Shared.js";

export class State extends Record {
    constructor(Users, CustomerConfiguration) {
        super();
        this.Users = Users;
        this.CustomerConfiguration = CustomerConfiguration;
    }
}

export function State_$reflection() {
    return record_type("Settings.Users.Types.State", [], State, () => [["Users", RequestedValue$1_$reflection(list_type(User_$reflection()))], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CustomerConfigurationFetched", "UsersFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Settings.Users.Types.Msg", [], Msg, () => [[["Item", CustomerConfiguration_$reflection()]], [["Item", list_type(User_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

