import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { DispolistOverview_$reflection } from "../../Shared/Dispolist.js";
import { record_type, union_type, class_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { RequestedValue$1_$reflection, TokenDataDto_$reflection } from "../../Shared/Shared.js";

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DispolistsFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("InventoryLists.Overview.Types.Msg", [], Msg, () => [[["Item", list_type(DispolistOverview_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class State extends Record {
    constructor(UserData, Dispolists) {
        super();
        this.UserData = UserData;
        this.Dispolists = Dispolists;
    }
}

export function State_$reflection() {
    return record_type("InventoryLists.Overview.Types.State", [], State, () => [["UserData", TokenDataDto_$reflection()], ["Dispolists", RequestedValue$1_$reflection(list_type(DispolistOverview_$reflection()))]]);
}

