import { Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { DocumentId_$reflection } from "../../Shared/Shared.js";
import { record_type, bool_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ImageDecorator, CommonProps } from "../../SharedComponents/ReactImageViewer.js";
import { tryHead, item, length, toArray, map, tryFind, append, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { keyValueList } from "../../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { empty, singleton, append as append_1, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import react_viewer from "react-viewer";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Delete_FileDeleteDialog, Edit_FileEditDialog, Upload_FileUploadDialog } from "../Dialogs/FileUploadDialog.js";
import { TableControl$1, ColumnDefinition$1, CellContent, OnTableRowClick$1, Table } from "./Table.js";
import { join, endsWith } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import Convert_PDF_2 from "../../../public/assets/icons/Convert_PDF_2.svg";
import Upload_File from "../../../public/assets/icons/Upload_File.svg";
import Download_File from "../../../public/assets/icons/Download_File.svg";
import Pencil from "../../../public/assets/icons/Pencil.svg";
import Recycle_Bin_1 from "../../../public/assets/icons/Recycle_Bin_1.svg";

export class TableDocument extends Record {
    constructor(Id, Description, UploadDate, Name, VisibleForUserRole, Url) {
        super();
        this.Id = Id;
        this.Description = Description;
        this.UploadDate = UploadDate;
        this.Name = Name;
        this.VisibleForUserRole = VisibleForUserRole;
        this.Url = Url;
    }
}

export function TableDocument_$reflection() {
    return record_type("Widgets.DocumentTable.TableDocument", [], TableDocument, () => [["Id", DocumentId_$reflection()], ["Description", string_type], ["UploadDate", class_type("System.DateTime")], ["Name", string_type], ["VisibleForUserRole", bool_type], ["Url", string_type]]);
}

export function ImageGalery(props) {
    const props_1 = ofArray([new CommonProps(3, ["img-viewer z-10 relative"]), new CommonProps(2, [[new ImageDecorator(props.ImageUrl)]]), new CommonProps(0, [props.IsOpen]), new CommonProps(4, [props.OnClose])]);
    const props_2 = keyValueList(append(props_1, toList(delay(() => append_1(singleton(new CommonProps(6, [false])), delay(() => {
        const matchValue = tryFind((prop) => {
            if (prop.tag === 4) {
                return true;
            }
            else {
                return false;
            }
        }, props_1);
        let matchResult, onClose;
        if (matchValue != null) {
            if (matchValue.tag === 4) {
                matchResult = 0;
                onClose = matchValue.fields[0];
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0:
                return singleton(new CommonProps(5, [onClose]));
            default: {
                return empty();
            }
        }
    }))))), 1);
    return react.createElement(react_viewer, props_2);
}

export function DocumentTable(props) {
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsUploadDialogOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(undefined);
    const setIsEditDialogOpen = patternInput_2[1];
    const isEditDialogOpen = patternInput_2[0];
    const patternInput_3 = reactApi.useState(undefined);
    const setIsDeleteDialogOpen = patternInput_3[1];
    const isDeleteDialogOpen = patternInput_3[0];
    const patternInput_4 = reactApi.useState(undefined);
    const setSelectedImage = patternInput_4[1];
    const selectedImage = patternInput_4[0];
    const rows = map((d) => (new TableDocument(d.Id, d.Description, d.UploadDate, d.Name, d.IsVisibleForUserRole, d.Url)), props.Documents);
    const xs_1 = toList(delay(() => append_1(singleton(ImageGalery({
        ImageUrl: defaultArg(selectedImage, ""),
        IsOpen: selectedImage != null,
        OnClose: () => {
            setSelectedImage(undefined);
        },
    })), delay(() => append_1(singleton(createElement(Upload_FileUploadDialog, {
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setIsUploadDialogOpen(false);
        },
        SuccessCallback: props.UploadSuccessCallback,
        UploadType: props.UploadType,
    })), delay(() => {
        let matchValue;
        return append_1((matchValue = isEditDialogOpen, (matchValue == null) ? (empty()) : singleton(createElement(Edit_FileEditDialog, {
            Document: matchValue,
            IsOpen: isEditDialogOpen != null,
            IsUserAuthorized: props.UserData.Role !== "user",
            OnClose: () => {
                setIsEditDialogOpen(undefined);
            },
            SuccessCallback: props.UploadSuccessCallback,
            UploadType: props.UploadType,
        }))), delay(() => {
            let matchValue_1;
            return append_1((matchValue_1 = isDeleteDialogOpen, (matchValue_1 == null) ? (empty()) : singleton(createElement(Delete_FileDeleteDialog, {
                Document: matchValue_1,
                IsOpen: isDeleteDialogOpen != null,
                IsUserAuthorized: props.UserData.Role !== "user",
                OnClose: () => {
                    setIsDeleteDialogOpen(undefined);
                },
                SuccessCallback: props.UploadSuccessCallback,
                UploadType: props.UploadType,
            }))), delay(() => {
                let TableRows, Options;
                return singleton(createElement(Table, (TableRows = toArray(rows), (Options = {
                    BorderStyle: "row",
                    CSVDownload: false,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["uploadDate", "asc"],
                    GlobalSearch: false,
                    OnRowClick: new OnTableRowClick$1(0, [(row_5) => {
                        if (endsWith(row_5.Name.toLocaleLowerCase(), ".pdf")) {
                            window.open(row_5.Url, "_blank", "noreferrer");
                        }
                        else {
                            setSelectedImage(row_5.Url);
                        }
                    }]),
                    RowSelection: "singleRow",
                }, {
                    ColumnDefinitions: [new ColumnDefinition$1("image", "", t("general.preview"), (row) => {
                        let elems;
                        return endsWith(row.Name.toLocaleLowerCase(), ".pdf") ? (new CellContent(1, [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "h-5"])], (elems = [Convert_PDF_2()], ["children", reactApi.Children.toArray(Array.from(elems))])])))])) : (new CellContent(4, [row.Url]));
                    }, "none", "image"), new ColumnDefinition$1("uploadDate", "", t("tool.uploaded_on"), (row_1) => (new CellContent(6, [row_1.UploadDate])), "none", "dateTime"), new ColumnDefinition$1("name", "", t("general.name"), (row_2) => (new CellContent(0, [row_2.Name])), "none", "text"), new ColumnDefinition$1("description", "", t("general.description"), (row_3) => (new CellContent(0, [row_3.Description])), "none", "text"), new ColumnDefinition$1("visibleForUserRole", "", t("tool.document_visible_for_user_role"), (row_4) => (row_4.VisibleForUserRole ? (new CellContent(0, [t("general.yes")])) : (new CellContent(0, [t("general.no")]))), "none", "text")],
                    Controls: toList(delay(() => {
                        let matchValue_2;
                        return append_1((matchValue_2 = props.UserData.Role, (matchValue_2 === "system") ? singleton(new TableControl$1(t("general.upload_file"), Upload_File(), (_arg_4) => {
                            setIsUploadDialogOpen(true);
                        }, false, false, "")) : ((matchValue_2 === "administrator") ? singleton(new TableControl$1(t("general.upload_file"), Upload_File(), (_arg_4) => {
                            setIsUploadDialogOpen(true);
                        }, false, false, "")) : ((matchValue_2 === "toolManager") ? singleton(new TableControl$1(t("general.upload_file"), Upload_File(), (_arg_4) => {
                            setIsUploadDialogOpen(true);
                        }, false, false, "")) : (empty())))), delay(() => append_1(singleton(new TableControl$1(t("general.download_file"), Download_File(), (rows_1) => {
                            if (length(rows_1) === 1) {
                                props.OnDownloadFile([item(0, rows_1).Id, item(0, rows_1).Name]);
                            }
                        }, true, false, "")), delay(() => {
                            const matchValue_3 = props.UserData.Role;
                            switch (matchValue_3) {
                                case "system":
                                case "administrator":
                                case "toolManager":
                                    return append_1(singleton(new TableControl$1(t("general.edit"), Pencil(), (row_6) => {
                                        const matchValue_4 = tryHead(row_6);
                                        if (matchValue_4 == null) {
                                        }
                                        else {
                                            const row_7 = matchValue_4;
                                            setIsEditDialogOpen(tryFind((doc) => equals(doc.Id, row_7.Id), props.Documents));
                                        }
                                    }, true, false, "")), delay(() => singleton(new TableControl$1(t("general.delete"), Recycle_Bin_1(), (row_8) => {
                                        const matchValue_5 = tryHead(row_8);
                                        if (matchValue_5 == null) {
                                        }
                                        else {
                                            const row_9 = matchValue_5;
                                            setIsDeleteDialogOpen(tryFind((doc_1) => equals(doc_1.Id, row_9.Id), props.Documents));
                                        }
                                    }, true, false, ""))));
                                default: {
                                    return empty();
                                }
                            }
                        }))));
                    })),
                    Options: Options,
                    TableRows: TableRows,
                }))));
            }));
        }));
    }))))));
    return react.createElement(react.Fragment, {}, ...xs_1);
}

