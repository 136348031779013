import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, option_type, union_type, string_type, class_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FileUploadResponse_$reflection } from "../../Shared/File.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, ConsumableId, SetId, ToolId, FormState__startLoading, FormState_get_empty, FormState_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { OverviewNotification_$reflection } from "../../Shared/Notification.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { uploadSignature } from "../../Requests/Files.js";
import { Cmd_errorToast } from "../../Components/Toast.js";
import { ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { acceptToolNotification, acceptConsumableNotification, acceptSetNotification, acceptToolWithQuantityNotification } from "../../Requests/Notification.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { AcceptNotificationDto } from "../../Shared/Tool.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { SignatureDialog } from "../../Components/SignatureDialog.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { TextValue } from "../../Components/Text.js";
import { toLocalTime, toString } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { TextButton } from "../../Components/Button.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSignature", "UploadSignature", "SignatureUploaded", "AcceptNotification", "AcceptNotificationResponse", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Widgets.AcceptNotificationDialog.Msg", [], Msg, () => [[["Item", class_type("Browser.Types.File", undefined)]], [], [["Item", FileUploadResponse_$reflection()]], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

export class NotificationToolType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Tool", "ToolWithQuantity", "Set", "Consumable"];
    }
}

export function NotificationToolType_$reflection() {
    return union_type("Widgets.AcceptNotificationDialog.NotificationToolType", [], NotificationToolType, () => [[], [], [], []]);
}

class State extends Record {
    constructor(Notification, Signature, SignatureUrl, FormState, OnConfirm, NotificationToolType) {
        super();
        this.Notification = Notification;
        this.Signature = Signature;
        this.SignatureUrl = SignatureUrl;
        this.FormState = FormState;
        this.OnConfirm = OnConfirm;
        this.NotificationToolType = NotificationToolType;
    }
}

function State_$reflection() {
    return record_type("Widgets.AcceptNotificationDialog.State", [], State, () => [["Notification", OverviewNotification_$reflection()], ["Signature", option_type(class_type("Browser.Types.File", undefined))], ["SignatureUrl", option_type(string_type)], ["FormState", FormState_$reflection()], ["OnConfirm", lambda_type(unit_type, unit_type)], ["NotificationToolType", NotificationToolType_$reflection()]]);
}

function init(notificationToolType, notification, onConfirm) {
    return [new State(notification, undefined, undefined, FormState_get_empty(), onConfirm, notificationToolType), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const matchValue = state.Signature;
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                const signature = matchValue;
                const formData = new FormData();
                formData.append(signature.name, signature);
                return [new State(state.Notification, state.Signature, undefined, FormState__startLoading(state.FormState), state.OnConfirm, state.NotificationToolType), uploadSignature(formData, (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(5, [Item_1])))];
            }
        }
        case 2: {
            const response = msg.fields[0];
            switch (response.tag) {
                case 0:
                    return [state, Cmd_errorToast("general.file_too_large")];
                case 2:
                    return [state, Cmd_errorToast("general.a_error_occured")];
                default:
                    return [new State(state.Notification, state.Signature, response.fields[0].ImageUrl, state.FormState, state.OnConfirm, state.NotificationToolType), singleton((dispatch) => {
                        dispatch(new Msg(3, []));
                    })];
            }
        }
        case 3: {
            let acceptCommand;
            const matchValue_1 = state.NotificationToolType;
            acceptCommand = ((matchValue_1.tag === 1) ? acceptToolWithQuantityNotification(new ToolId(parse(state.Notification.EntityId)), state.Notification.Id, new AcceptNotificationDto(state.SignatureUrl, undefined), (Item_6) => (new Msg(4, [Item_6])), (Item_7) => (new Msg(5, [Item_7]))) : ((matchValue_1.tag === 2) ? acceptSetNotification(new SetId(parse(state.Notification.EntityId)), state.Notification.Id, new AcceptNotificationDto(state.SignatureUrl, undefined), (Item_9) => (new Msg(4, [Item_9])), (Item_10) => (new Msg(5, [Item_10]))) : ((matchValue_1.tag === 3) ? acceptConsumableNotification(new ConsumableId(parse(state.Notification.EntityId)), state.Notification.Id, new AcceptNotificationDto(state.SignatureUrl, undefined), (Item_12) => (new Msg(4, [Item_12])), (Item_13) => (new Msg(5, [Item_13]))) : acceptToolNotification(new ToolId(parse(state.Notification.EntityId)), state.Notification.Id, new AcceptNotificationDto(state.SignatureUrl, undefined), (Item_3) => (new Msg(4, [Item_3])), (Item_4) => (new Msg(5, [Item_4]))))));
            return [new State(state.Notification, state.Signature, state.SignatureUrl, FormState__startLoading(state.FormState), state.OnConfirm, state.NotificationToolType), acceptCommand];
        }
        case 4: {
            const response_1 = msg.fields[0];
            if (response_1.tag === 1) {
                return [new State(state.Notification, state.Signature, state.SignatureUrl, FormState__setValidationResponse_5219762A(state.FormState, response_1.fields[0]), state.OnConfirm, state.NotificationToolType), Cmd_none()];
            }
            else {
                state.OnConfirm();
                return [new State(state.Notification, state.Signature, state.SignatureUrl, FormState_get_empty(), state.OnConfirm, state.NotificationToolType), Cmd_none()];
            }
        }
        case 5:
            return [state, Cmd_none()];
        default:
            return [new State(state.Notification, msg.fields[0], state.SignatureUrl, state.FormState, state.OnConfirm, state.NotificationToolType), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems_10;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsSignatureDialogOpen = patternInput_1[1];
    return createElement("div", createObj(singleton((elems_10 = toList(delay(() => append(singleton_1(createElement(SignatureDialog, {
        Description: "",
        FormState: FormState_get_empty(),
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setIsSignatureDialogOpen(false);
        },
        OnConfirm: (file) => {
            props.Dispatch(new Msg(0, [file]));
            setIsSignatureDialogOpen(false);
        },
    })), delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mb-5"])], (elems = [createElement(TextValue, {
            FontWeight: "normal",
            TestId: "",
            Text: props.Description,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_2, elems_1;
            return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_2 = [createElement("div", {
                className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                children: t("general.sender"),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_1 = [createElement(TextValue, {
                FontWeight: "normal",
                TestId: "",
                Text: props.Notification.Sender,
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                let elems_4, elems_3;
                return append((props.Notification.Comment !== "") ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_4 = [createElement("div", {
                    className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                    children: t("general.comment"),
                }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_3 = [createElement(TextValue, {
                    FontWeight: "normal",
                    TestId: "",
                    Text: props.Notification.Comment,
                })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))) : empty(), delay(() => {
                    let matchValue, plannedReturnDate, elems_6, elems_5;
                    return append((matchValue = props.Notification.PlannedReturnDate, (matchValue == null) ? (empty()) : ((plannedReturnDate = matchValue, singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_6 = [createElement("div", {
                        className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                        children: t("tool.planned_return_date"),
                    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_5 = [createElement(TextValue, {
                        FontWeight: "normal",
                        TestId: "",
                        Text: toString(toLocalTime(plannedReturnDate), "dd.MM.yyyy"),
                    })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))))))), delay(() => {
                        let elems_9;
                        return append(props.Notification.WithSignature ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_9 = toList(delay(() => append(singleton_1(createElement("div", {
                            className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                            children: t("general.signature"),
                        })), delay(() => {
                            let elems_8, elems_7;
                            const matchValue_1 = props.Signature;
                            if (matchValue_1 == null) {
                                return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_8 = [createElement(TextValue, {
                                    FontWeight: "normal",
                                    TestId: "",
                                    Text: t("notification.signature_description"),
                                })], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))), delay(() => singleton_1(createElement(TextButton, {
                                    ComponentState: props.ComponentState,
                                    Label: t("notification.sign"),
                                    OnClick: () => {
                                        setIsSignatureDialogOpen(true);
                                    },
                                    TestId: "switch-storage-text-button-test-id",
                                    Variant: "blueButton",
                                }))));
                            }
                            else {
                                const signature = matchValue_1;
                                const imageUrl = URL.createObjectURL(signature);
                                return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_7 = [createElement("img", {
                                    className: join(" ", ["h-24"]),
                                    src: imageUrl,
                                })], ["children", reactApi.Children.toArray(Array.from(elems_7))])]))));
                            }
                        })))), ["children", reactApi.Children.toArray(Array.from(elems_9))])])))) : empty(), delay(() => {
                            const matchValue_2 = FormState__getValidation(props.FormState, t, "global");
                            if (matchValue_2 == null) {
                                return empty();
                            }
                            else {
                                return singleton_1(createElement(ErrorAlert, {
                                    Label: matchValue_2,
                                }));
                            }
                        }));
                    }));
                }));
            }));
        }));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_10))]))));
}

export function AcceptNotificationDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.NotificationToolType, props.Notification, props.OnConfirm);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const componentState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("notification.accept"), {
        Body: singleton(createElement(DialogBody, {
            ComponentState: componentState,
            Description: props.Description,
            Dispatch: dispatch,
            FormState: state_1.FormState,
            Notification: props.Notification,
            Signature: unwrap(state_1.Signature),
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-accept-notification-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: (state_1.Notification.WithSignature && (state_1.Signature == null)) ? "disabled" : componentState,
            Label: t("notification.accept"),
            OnClick: () => {
                if (state_1.Notification.WithSignature && (state_1.Signature != null)) {
                    dispatch(new Msg(1, []));
                }
                else {
                    dispatch(new Msg(3, []));
                }
            },
            TestId: "accept-notification-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

