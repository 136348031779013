import { FSharpRef, Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { createElement } from "react";
import React from "react";
import { equals, createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { replace, join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { singleton as singleton_1, item, isEmpty, length, filter, fold, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { newGuid } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import { defaultArg, map, flatten, unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { parse, tryParse } from "../fable_modules/fable-library-js.4.19.2/Double.js";
import { reactApi as reactApi_1 } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { NumericFormat } from "react-number-format";
import react_currency_input_field from "react-currency-input-field";
import Visible from "../../public/assets/icons/Visible.svg";
import Invisible_1 from "../../public/assets/icons/Invisible_1.svg";
import { useTranslation } from "react-i18next";
import { useVirtualizer } from "@tanstack/react-virtual";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { Combobox } from "@headlessui/react";
import { map as map_1 } from "../fable_modules/fable-library-js.4.19.2/Array.js";

class InputType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Text", "Number", "Password"];
    }
}

function InputType_$reflection() {
    return union_type("Components.Input.InputType", [], InputType, () => [[], [], []]);
}

function InputSkeleton(props) {
    let elems_4;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-1.5"])], (elems_4 = toList(delay(() => {
        let matchValue, label;
        return append((matchValue = props.Label, (matchValue == null) ? (empty()) : ((label = matchValue, singleton(createElement("label", {
            htmlFor: props.Id,
            className: join(" ", ["text-sm", "font-bold"]),
            children: label,
        }))))), delay(() => {
            let elems_3;
            return singleton(createElement("div", createObj(ofArray([["data-test-id", props.TestId], ["className", join(" ", [])], (elems_3 = toList(delay(() => {
                let elems_2;
                return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "relative"])], (elems_2 = toList(delay(() => {
                    let matchValue_1, leftIcon;
                    return append((matchValue_1 = props.LeftIcon, (matchValue_1 == null) ? (empty()) : ((leftIcon = matchValue_1, singleton(createElement("div", {
                        className: join(" ", ["absolute", "left-0", "h-5", "w-5", "ml-2"]),
                        children: reactApi.Children.toArray([leftIcon]),
                    }))))), delay(() => append(singleton(props.InputField), delay(() => {
                        const matchValue_2 = props.RightIcon;
                        if (matchValue_2 == null) {
                            return empty();
                        }
                        else {
                            const rightIcon = matchValue_2;
                            return singleton(createElement("div", createObj(toList(delay(() => append(singleton(["className", join(" ", ["absolute", "right-0", "h-5", "w-5", "mr-2"])]), delay(() => {
                                let matchValue_3, onClick;
                                return append((matchValue_3 = props.RightIconOnClick, (matchValue_3 == null) ? (empty()) : ((onClick = matchValue_3, singleton(["onClick", (_arg) => {
                                    onClick();
                                }])))), delay(() => singleton(["children", reactApi.Children.toArray([rightIcon])])));
                            })))))));
                        }
                    }))));
                })), ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                    const matchValue_4 = props.ValidationMessage;
                    if (matchValue_4 != null) {
                        const invalidMessage = matchValue_4;
                        return singleton(createElement("p", {
                            "data-test-id": `${props.TestId}-validation-message`,
                            className: join(" ", ["mt-2", "text-red", "text-sm"]),
                            children: invalidMessage,
                        }));
                    }
                    else {
                        return empty();
                    }
                }));
            })), ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

function BaseInput(props) {
    return createElement(InputSkeleton, {
        ComponentState: props.ComponentState,
        Id: newGuid(),
        InputField: createElement("input", createObj(toList(delay(() => append(singleton(["id", `${props.TestId}-input-test-id`]), delay(() => append(singleton(["className", join(" ", toList(delay(() => append(singleton("bg-white"), delay(() => append(singleton("border"), delay(() => append(singleton("border-[#B6BECB]"), delay(() => append(singleton("focus:border-primary"), delay(() => append(singleton("focus:outline-none"), delay(() => append(singleton("rounded-lg"), delay(() => append(singleton("h-10"), delay(() => append(singleton("w-full"), delay(() => append(singleton("px-3"), delay(() => append((props.LeftIcon == null) ? (empty()) : singleton("pl-8"), delay(() => append((props.RightIcon == null) ? (empty()) : singleton("pr-8"), delay(() => append(singleton("text-base"), delay(() => append(singleton("font-normal"), delay(() => append((props.ComponentState === "disabled") ? singleton("text-text-muted") : (empty()), delay(() => {
            if (props.ValidationMessage == null) {
                return empty();
            }
            else {
                return append(singleton("border-red"), delay(() => singleton("focus:border-red")));
            }
        })))))))))))))))))))))))))))))))]), delay(() => {
            let value_4;
            return append(singleton((value_4 = props.Value, ["ref", (e) => {
                if (!(e == null) && !equals(e.value, value_4)) {
                    e.value = value_4;
                }
            }])), delay(() => append(singleton(["onChange", (ev) => {
                props.OnChange(ev.target.value);
            }]), delay(() => append(singleton(["disabled", props.ComponentState === "disabled"]), delay(() => {
                const matchValue_4 = props.InputType;
                switch (matchValue_4.tag) {
                    case 1:
                        return singleton(["type", "number"]);
                    case 2:
                        return singleton(["type", "password"]);
                    default: {
                        return empty();
                    }
                }
            }))))));
        })))))))),
        Label: unwrap(props.Label),
        LeftIcon: unwrap(props.LeftIcon),
        OnKeyPress: unwrap(props.OnKeyPress),
        RightIcon: unwrap(props.RightIcon),
        RightIconOnClick: unwrap(props.RightIconOnClick),
        TestId: props.TestId,
        ValidationMessage: unwrap(props.ValidationMessage),
    });
}

export function Input(props) {
    return createElement(BaseInput, {
        ComponentState: props.ComponentState,
        InputType: new InputType(0, []),
        Label: unwrap(props.Label),
        OnChange: props.OnChange,
        TestId: props.TestId,
        ValidationMessage: unwrap(props.ValidationMessage),
        Value: props.Value,
    });
}

export function IconInput(props) {
    return createElement(BaseInput, {
        ComponentState: props.ComponentState,
        InputType: new InputType(0, []),
        LeftIcon: props.LeftIcon,
        OnChange: props.OnChange,
        TestId: props.TestId,
        ValidationMessage: unwrap(props.ValidationMessage),
        Value: props.Value,
    });
}

export function $007CParsedFloat$007C(input) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse(input, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return undefined;
    }
}

export function NumberInput(props) {
    let props_1;
    const id = newGuid();
    return createElement(InputSkeleton, {
        ComponentState: props.ComponentState,
        Id: id,
        InputField: (props_1 = toList(delay(() => append(singleton(["id", id]), delay(() => append(singleton(["className", fold((a, b) => (`${a} ${b}`), "", toList(delay(() => append(singleton("bg-white"), delay(() => append(singleton("border"), delay(() => append(singleton("focus:outline-none"), delay(() => append(singleton("rounded-lg"), delay(() => append(singleton("h-10"), delay(() => append(singleton("w-full"), delay(() => append(singleton("px-3"), delay(() => append(singleton("text-base"), delay(() => append(singleton("font-normal"), delay(() => append((props.ComponentState === "disabled") ? append(singleton("text-text-muted"), delay(() => singleton("border-border-muted"))) : singleton("border-[#B6BECB]"), delay(() => ((props.ValidationMessage == null) ? singleton("focus:border-primary") : singleton("border-red")))))))))))))))))))))))))]), delay(() => append(singleton(["disabled", props.ComponentState === "disabled"]), delay(() => append(singleton(["thousandSeparator", "."]), delay(() => append(singleton(["decimalSeparator", ","]), delay(() => append(singleton(["value", props.Value]), delay(() => append(singleton(["onValueChange", (value_7) => {
            props.OnChange(flatten(map((value_8) => value_8.floatValue, value_7)));
        }]), delay(() => {
            const matchValue_2 = props.MaxValue;
            if (matchValue_2 != null) {
                const maxValue = matchValue_2;
                return singleton(["isAllowed", (value_10) => defaultArg(map((value_11) => (value_11 <= maxValue), value_10.floatValue), true)]);
            }
            else {
                return empty();
            }
        })))))))))))))))), reactApi_1.createElement(NumericFormat, createObj(props_1))),
        Label: unwrap(props.Label),
        TestId: props.TestId,
        ValidationMessage: unwrap(props.ValidationMessage),
    });
}

export function PositiveNumberInput(props) {
    let props_1;
    const id = newGuid();
    return createElement(InputSkeleton, {
        ComponentState: props.ComponentState,
        Id: id,
        InputField: (props_1 = toList(delay(() => append(singleton(["id", id]), delay(() => append(singleton(["className", fold((a, b) => (`${a} ${b}`), "", toList(delay(() => append(singleton("bg-white"), delay(() => append(singleton("border"), delay(() => append(singleton("focus:outline-none"), delay(() => append(singleton("rounded-lg"), delay(() => append(singleton("h-10"), delay(() => append(singleton("w-full"), delay(() => append(singleton("px-3"), delay(() => append(singleton("text-base"), delay(() => append(singleton("font-normal"), delay(() => append((props.ComponentState === "disabled") ? append(singleton("text-text-muted"), delay(() => singleton("border-border-muted"))) : singleton("border-[#B6BECB]"), delay(() => ((props.ValidationMessage == null) ? singleton("focus:border-primary") : singleton("border-red")))))))))))))))))))))))))]), delay(() => append(singleton(["disabled", props.ComponentState === "disabled"]), delay(() => append(singleton(["thousandSeparator", "."]), delay(() => append(singleton(["decimalSeparator", ","]), delay(() => append(singleton(["value", props.Value]), delay(() => append(singleton(["onValueChange", (value_7) => {
            props.OnChange(flatten(map((value_8) => value_8.floatValue, value_7)));
        }]), delay(() => {
            const matchValue_2 = props.MaxValue;
            if (matchValue_2 != null) {
                const maxValue = matchValue_2;
                return singleton(["isAllowed", (value_10) => defaultArg(map((value_11) => {
                    if (value_11 <= maxValue) {
                        return value_11 >= 0;
                    }
                    else {
                        return false;
                    }
                }, value_10.floatValue), true)]);
            }
            else {
                return empty();
            }
        })))))))))))))))), reactApi_1.createElement(NumericFormat, createObj(props_1))),
        Label: unwrap(props.Label),
        TestId: props.TestId,
        ValidationMessage: unwrap(props.ValidationMessage),
    });
}

export function CurrencyInput(props) {
    let props_1;
    const id = newGuid();
    const value_1 = map((price) => (price / 100), props.Value);
    return createElement(InputSkeleton, {
        ComponentState: props.ComponentState,
        Id: id,
        InputField: (props_1 = ofArray([["id", id], ["className", fold((a, b) => (`${a} ${b}`), "", toList(delay(() => append(singleton("bg-white"), delay(() => append(singleton("border"), delay(() => append(singleton("focus:outline-none"), delay(() => append(singleton("rounded-lg"), delay(() => append(singleton("h-10"), delay(() => append(singleton("w-full"), delay(() => append(singleton("px-3"), delay(() => append(singleton("text-base"), delay(() => append(singleton("font-normal"), delay(() => append((props.ComponentState === "disabled") ? append(singleton("text-text-muted"), delay(() => singleton("border-border-muted"))) : singleton("border-[#B6BECB]"), delay(() => ((props.ValidationMessage == null) ? singleton("focus:border-primary") : singleton("border-red")))))))))))))))))))))))))], ["disabled", props.ComponentState === "disabled"], ["intlConfig", {
            currency: "EUR",
            locale: "de-de",
        }], ["allowNegativeValue", false], ["defaultValue", value_1], ["onValueChange", (value_8) => {
            props.OnChange(map((value_11) => ~~(value_11 * 100), map((value_9) => parse(replace(value_9, ",", ".")), value_8)));
        }]]), reactApi_1.createElement(react_currency_input_field, createObj(props_1))),
        Label: unwrap(props.Label),
        TestId: props.TestId,
        ValidationMessage: unwrap(props.ValidationMessage),
    });
}

export function PasswordInput(props) {
    return createElement(BaseInput, {
        ComponentState: props.ComponentState,
        InputType: props.PasswordVisible ? (new InputType(0, [])) : (new InputType(2, [])),
        Label: props.Label,
        OnChange: props.OnChange,
        OnKeyPress: unwrap(props.OnKeyPress),
        RightIcon: props.PasswordVisible ? Visible() : Invisible_1(),
        RightIconOnClick: props.OnSetPasswordVisible,
        TestId: props.TestId,
        ValidationMessage: unwrap(props.ValidationMessage),
        Value: props.Value,
    });
}

export function InvalidFeedback(props) {
    return createElement("div", {
        className: join(" ", ["mt-1", "text-xs", "text-[#dc3545]"]),
        children: props.Text,
    });
}

export function AutoSuggestInput(props) {
    let elems_3;
    const patternInput = useTranslation();
    const id = `${props.Label}-auto-suggest-id`;
    const filteredSuggestions = filter((suggestion) => {
        const baseString = suggestion.toLocaleLowerCase();
        const searchString = props.Value.toLocaleLowerCase();
        return baseString.includes(searchString);
    }, props.Suggestions);
    const parentRef = reactApi.useRef(undefined);
    const rowVirtualizer = useVirtualizer({
        count: length(filteredSuggestions),
        estimateSize: (_arg_1) => 50,
        getScrollElement: (_arg) => parentRef.current,
        overscan: 20,
    });
    const items = rowVirtualizer.getVirtualItems();
    return createElement("div", createObj(ofArray([["ref", parentRef], ["data-test-id", props.TestId], ["className", join(" ", ["flex", "flex-col", "gap-1.5"])], (elems_3 = toList(delay(() => {
        let matchValue, label;
        return append((matchValue = props.Label, (matchValue == null) ? singleton(defaultOf()) : ((label = matchValue, singleton(createElement("label", {
            htmlFor: id,
            className: join(" ", ["text-sm", "font-bold"]),
            children: label,
        }))))), delay(() => {
            let props_10, elems_2;
            return singleton((props_10 = ofArray([["disabled", props.ComponentState === "disabled"], ["key", "autosuggest-key"], ["value", props.Value], ["children", singleton_1(createElement("div", createObj(ofArray([["key", "input-container"], ["className", join(" ", ["relative"])], (elems_2 = toList(delay(() => {
                let elems, props_2;
                return append(singleton(createElement("div", createObj(ofArray([["key", "input-content"], ["className", join(" ", ["relative", "w-full", "overflow-hidden", "bg-white", "rounded-lg", "text-left"])], (elems = [(props_2 = ofArray([["id", id], ["disabled", props.ComponentState === "disabled"], ["key", "autosuggest-input-key"], ["onChange", (event) => {
                    props.OnChange(event.target.value);
                }], ["className", fold((a, b) => (`${a} ${b}`), "", toList(delay(() => append(singleton("w-full"), delay(() => append(singleton("py-2"), delay(() => append(singleton("pl-3"), delay(() => append(singleton("pr-10"), delay(() => append(singleton("text-base"), delay(() => append(singleton("font-normal"), delay(() => append(singleton("rounded-lg"), delay(() => append(singleton("border"), delay(() => append(singleton("focus:outline-none"), delay(() => append((props.ValidationMessage == null) ? singleton("focus:border-primary") : singleton("border-red"), delay(() => ((props.ComponentState === "disabled") ? append(singleton("text-text-muted"), delay(() => singleton("border-border-muted"))) : singleton("border-[#B6BECB]")))))))))))))))))))))))))]]), reactApi_1.createElement(Combobox.Input, createObj(props_2)))], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                    let matchValue_3, invalidMessage;
                    return append((matchValue_3 = props.ValidationMessage, (matchValue_3 != null) ? ((invalidMessage = matchValue_3, singleton(createElement("p", {
                        "data-test-id": `${props.Label}-test-id`,
                        className: join(" ", ["mt-2", "text-red", "text-sm"]),
                        children: invalidMessage,
                    })))) : (empty())), delay(() => {
                        let props_8;
                        return singleton((props_8 = ofArray([["key", "suggestion-options-key"], ["disabled", props.ComponentState === "disabled"], ["className", fold((a_1, b_1) => (`${a_1} ${b_1}`), "", ofArray(["bg-white", "absolute", "overflow-auto", "max-h-60", "mt-1", "py-2", "px-3", "w-full", "rounded", "shadow", "text-base", "font-normal", "z-50"]))], ["children", toList(delay(() => (isEmpty(filteredSuggestions) ? singleton(createElement("div", {
                            key: "auto-suggest-not-found-id",
                            className: join(" ", ["relative", "cursor-default", "select-none", "py-2", "px-4", "text-gray-700"]),
                            children: patternInput[0]("general.no_matching_value_found"),
                        })) : map_1((virtualItem) => {
                            const suggestion_1 = item(virtualItem.index, filteredSuggestions);
                            const props_7 = ofArray([["className", fold((a_2, b_2) => (`${a_2} ${b_2}`), "", ofArray(["cursor-pointer", "hover:bg-[#F2F4FD]", "rounded", "p-2"]))], ["key", `${suggestion_1}-${virtualItem.index}`], ["children", singleton_1(createElement("div", {
                                key: `${suggestion_1}-child-${virtualItem.index}`,
                                children: reactApi.Children.toArray([suggestion_1]),
                                onClick: (_arg_2) => {
                                    props.OnChange(suggestion_1);
                                },
                            }))]]);
                            return reactApi_1.createElement(Combobox.Option, createObj(props_7));
                        }, items))))]]), reactApi_1.createElement(Combobox.Options, createObj(props_8))));
                    }));
                }));
            })), ["children", reactApi.Children.toArray(Array.from(elems_2))])]))))]]), reactApi_1.createElement(Combobox, createObj(props_10))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

