import { EmptyResponse_$reflection, RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getConsumables } from "../../Requests/Consumable.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../../CommunicationV2.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { filter, ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ValidationOnGetRequest } from "../../ErrorHandling.js";
import { addPrefix as addPrefix_1, fetchWithDecoder as fetchWithDecoder_1 } from "../../Communication.js";
import { UserConfiguration as UserConfiguration_1, UserConfiguration_$reflection } from "../../Shared/User.js";
import { getCustomerConfiguration } from "../../Requests/Configuration.js";
import { add } from "../../fable_modules/fable-library-js.4.19.2/Map.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";

export function initOverview(userData) {
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), userData, userData.Customer), Cmd_batch(ofArray([getConsumables((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(7, [Item_1]))), Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), "/api/property-configurations", (Item_2) => (new Msg(2, [Item_2])), (Item_3) => (new Msg(7, [Item_3]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), UserConfiguration_$reflection()));
    })))), "/api/users/configuration", (Item_4) => (new Msg(3, [Item_4])), (Item_5) => (new Msg(7, [Item_5]))), getCustomerConfiguration((Item_6) => (new Msg(6, [Item_6])), (Item_7) => (new Msg(7, [Item_7])))]))];
}

export function update(msg, state) {
    let userConfiguration_2;
    switch (msg.tag) {
        case 1:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.PropertyConfigurations, state.UserConfiguration, state.CustomerConfiguration, state.UserData, state.Customer), Cmd_none()];
        case 2:
            return [new State(state.Consumables, new RequestedValue$1(1, [filter((propertyConfiguration_1) => {
                const matchValue = propertyConfiguration_1.Visibility;
                switch (matchValue.tag) {
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        return false;
                    default:
                        return true;
                }
            }, msg.fields[0])]), state.UserConfiguration, state.CustomerConfiguration, state.UserData, state.Customer), Cmd_none()];
        case 3:
            return [new State(state.Consumables, state.PropertyConfigurations, new RequestedValue$1(1, [msg.fields[0]]), state.CustomerConfiguration, state.UserData, state.Customer), Cmd_none()];
        case 4: {
            const matchValue_1 = state.UserConfiguration;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const userConfiguration_1 = matchValue_1.fields[0];
                return [state, (userConfiguration_2 = (new UserConfiguration_1(add("consumables-table", msg.fields[0], userConfiguration_1.TableConfiguration), userConfiguration_1.QuickOverviewConfiguration)), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response_1) => {
                    const pr = response_1.arrayBuffer();
                    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), EmptyResponse_$reflection()));
                })))), ["/api/users/configuration", toString(0, Auto_generateBoxedEncoder_437914C6(UserConfiguration_$reflection(), undefined, undefined, undefined)(userConfiguration_2))], (Item_2) => (new Msg(5, [Item_2])), (Item_3) => (new Msg(7, [Item_3]))))];
            }
        }
        case 5:
            return [state, Cmd_none()];
        case 6:
            return [new State(state.Consumables, state.PropertyConfigurations, state.UserConfiguration, new RequestedValue$1(1, [msg.fields[0]]), state.UserData, state.Customer), Cmd_none()];
        case 7:
            return [state, Cmd_none()];
        default:
            return [state, getConsumables((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(7, [Item_1])))];
    }
}

