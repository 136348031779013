import { selectWithLabel, toNoOptionsMessage, CommonProps$1, OptionType$1 } from "../../SharedComponents/ReactSelect.js";
import { ofArray, tryFind, map, toArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { defaultArg, unwrap, map as map_1 } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormContainer } from "../../Components/FormContainer.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Input } from "../../Components/Input.js";
import { Msg } from "./Types.js";
import { FormState__getValidation } from "../../Shared/Shared.js";
import { Feature, isFeatureEnabled } from "../../FeatureFlip.js";
import { NewReceiverCustomProperties } from "../../Widgets/CustomProperties.js";
import { PropertyConfigurationVisibility } from "../../Shared/PropertyConfiguration.js";
import { Table_UserVisibleTable } from "../UserSelect.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { initEdit, update as update_1, initNew } from "./State.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { unwrapUserId } from "../../Shared/Helper.js";

function locationProps(locations, locationId, setLocation, isDisabled) {
    const locationToSelectElement = (location) => (new OptionType$1(location.Name, location));
    return [new CommonProps$1(0, [toArray(map(locationToSelectElement, locations))]), new CommonProps$1(2, [setLocation]), new CommonProps$1(5, [true]), new CommonProps$1(9, [false]), new CommonProps$1(6, ["user.select_storage_placeholder"]), new CommonProps$1(10, [(_arg) => toNoOptionsMessage("user.select_storage_no_options", _arg)]), new CommonProps$1(11, ["flex-grow-1"]), new CommonProps$1(7, [isDisabled]), new CommonProps$1(3, [map_1(locationToSelectElement, tryFind((location_1) => equals(location_1.Id, locationId), locations))])];
}

export function RoomFormContent(props) {
    const t = useTranslation()[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(FormContainer, {
        Children: toList(delay(() => append(singleton(createElement(Input, {
            ComponentState: componentState,
            Label: t("room.name"),
            OnChange: (arg) => {
                props.Dispatch(new Msg(0, [arg]));
            },
            TestId: "",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "name")),
            Value: props.Room.Name,
        })), delay(() => append(singleton(createElement(Input, {
            ComponentState: componentState,
            Label: t("room.number"),
            OnChange: (arg_1) => {
                props.Dispatch(new Msg(1, [arg_1]));
            },
            TestId: "",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "number")),
            Value: props.Room.Number,
        })), delay(() => {
            let Label_2, Value_2;
            return append(isFeatureEnabled(new Feature(15, [])) ? singleton(createElement(Input, (Label_2 = t("general.email"), (Value_2 = defaultArg(props.Room.Email, ""), {
                ComponentState: componentState,
                Label: unwrap(Label_2),
                OnChange: (arg_2) => {
                    props.Dispatch(new Msg(2, [arg_2]));
                },
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "email")),
                Value: Value_2,
            })))) : empty(), delay(() => append(singleton(selectWithLabel(locationProps(props.Locations, props.Room.LocationId, (arg_3) => {
                props.Dispatch(new Msg(3, [arg_3]));
            }, false), createElement("span", {
                children: [t("room.location")],
            }), "")), delay(() => append(props.IsCustomPropertiesEnabled ? singleton(createElement(NewReceiverCustomProperties, {
                CustomProperties: props.Room.CustomProperties,
                FormState: props.FormState,
                OnSetCustomProperty: (arg_4) => {
                    props.Dispatch(new Msg(4, [arg_4]));
                },
                PropertyConfigurationType: new PropertyConfigurationVisibility(6, []),
                PropertyConfigurations: props.PropertyConfigurations,
            })) : empty(), delay(() => singleton(createElement(Table_UserVisibleTable, {
                IsOpen: props.VisibleUserModalIsOpen,
                OnClose: () => {
                    props.Dispatch(new Msg(9, []));
                },
                OnDelete: props.OnDeleteVisibleUserIds,
                OnSave: props.OnSaveVisibleUserIds,
                PropertyConfigurations: props.PropertyConfigurations,
                SetIsOpen: () => {
                    props.Dispatch(new Msg(9, []));
                },
                Users: props.Users,
                VisibleForUserIds: props.Room.VisibleForUserIds,
            }))))))));
        })))))),
        Header: props.Header,
        OnBack: props.OnBack,
        OnSave: props.OnSave,
    });
}

export function NewRoomView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(initNew, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    const matchValue_1 = state_1.Locations;
    const matchValue_2 = state_1.IsCustomPropertiesEnabled;
    const matchValue_3 = state_1.PropertyConfigurations;
    const matchValue_4 = state_1.Users;
    let matchResult, isCustomPropertiesEnabled, locations, propertyConfigurations, room, users;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isCustomPropertiesEnabled = matchValue_2.fields[0];
        locations = matchValue_1.fields[0];
        propertyConfigurations = matchValue_3.fields[0];
        room = matchValue.fields[0];
        users = matchValue_4.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(RoomFormContent, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                Header: patternInput_1[0]("room.create_new_room"),
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled,
                Locations: locations,
                OnBack: () => {
                    RouterModule_nav(ofArray(["receivers", "rooms"]), 1, 1);
                },
                OnDeleteVisibleUserIds: (userIds_1) => {
                    dispatch(new Msg(11, [userIds_1]));
                },
                OnSave: () => {
                    dispatch(new Msg(5, []));
                },
                OnSaveVisibleUserIds: (userIds) => {
                    dispatch(new Msg(10, [userIds]));
                },
                PropertyConfigurations: propertyConfigurations,
                Room: room,
                Users: users,
                VisibleUserModalIsOpen: state_1.VisibleUserModalIsOpen,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

export function EditRoomView(props) {
    let patternInput;
    const init = initEdit(props.RoomId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.RoomId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    const matchValue_1 = state_1.Locations;
    const matchValue_2 = state_1.IsCustomPropertiesEnabled;
    const matchValue_3 = state_1.PropertyConfigurations;
    const matchValue_4 = state_1.Users;
    let matchResult, isCustomPropertiesEnabled, locations, propertyConfigurations, room, users;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isCustomPropertiesEnabled = matchValue_2.fields[0];
        locations = matchValue_1.fields[0];
        propertyConfigurations = matchValue_3.fields[0];
        room = matchValue.fields[0];
        users = matchValue_4.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(RoomFormContent, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                Header: patternInput_1[0]("room.edit_room"),
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled,
                Locations: locations,
                OnBack: () => {
                    RouterModule_nav(ofArray(["receivers", unwrapUserId(props.RoomId)]), 1, 1);
                },
                OnDeleteVisibleUserIds: (userIds_1) => {
                    dispatch(new Msg(11, [userIds_1]));
                },
                OnSave: () => {
                    dispatch(new Msg(6, []));
                },
                OnSaveVisibleUserIds: (userIds) => {
                    dispatch(new Msg(10, [userIds]));
                },
                PropertyConfigurations: propertyConfigurations,
                Room: room,
                Users: users,
                VisibleUserModalIsOpen: state_1.VisibleUserModalIsOpen,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

