import { createElement } from "react";
import React from "react";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { useTranslation } from "react-i18next";
import { equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ArchiveDialog } from "./Dialog/ArchiveDialog.js";
import { Msg } from "../Types.js";
import { Feature, isFeatureEnabled } from "../../FeatureFlip.js";
import { TextButton } from "../../Components/Button.js";
import Visible from "../../../public/assets/icons/Visible.svg";
import Invisible_1 from "../../../public/assets/icons/Invisible_1.svg";
import { filter, tryFind, map, isEmpty, choose, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import Followers from "../../../public/assets/icons/Followers.svg";
import Following from "../../../public/assets/icons/Following.svg";
import { DispolistAssignView } from "../../InventoryLists/View.js";
import { UnassignDispolistDialog } from "./Dialog/UnassignDispolistDialog.js";
import { useFeliz_React__React_useDeferred_Static_Z241A641 } from "../../fable_modules/Feliz.UseDeferred.2.0.0/UseDeferred.fs.js";
import { ImageWidget } from "../../Widgets/ImageWidget.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { ChangePasswordDialog } from "../../Receiver/ChangePasswordDialog.js";
import { ChangeUserEmailOrPhoneNumberDialog } from "../../Receiver/ChangeEmailOrPhoneNumberDialog.js";
import Pencil from "../../../public/assets/icons/Pencil.svg";
import { ChangeUserWithoutLoginToUserWithLoginDialog } from "../../Receiver/TransformUserWithoutLoginToUserWithLoginDialog.js";
import { map as map_1, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { UserId as UserId_2, PropertyConfigurationId } from "../../Shared/Shared.js";
import { addPrefix, Async_fetchWithDecoder } from "../../Communication.js";
import { unwrapUserId } from "../../Shared/Helper.js";
import { HttpMethod } from "../../fable_modules/Fable.SimpleHttp.3.6.0/Types.fs.js";
import { singleton as singleton_1 } from "../../fable_modules/fable-library-js.4.19.2/AsyncBuilder.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Requests_VehicleDto_$reflection, Requests_StudentDto_$reflection, Requests_SchoolClassDto_$reflection, Requests_ConstructionSiteDto_$reflection, Requests_ProjectDto_$reflection, Requests_SubcontractorDto_$reflection, Requests_RoomDto_$reflection, Requests_ExternalPersonDto_$reflection, Requests_EmployeeDto_$reflection, Requests_UserDto_$reflection } from "../../Shared/User.js";
import { PropertyList } from "../../Widgets/PropertyListWidget.js";
import { CustomPropertiesPropertyList } from "../../Widgets/CustomPropertiesPropertyList.js";

export function ReceiverDetailButtons(props) {
    let elems;
    const patternInput = reactApi.useState(false);
    const setIsArchivedDialogOpen = patternInput[1];
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "gap-4"])], (elems = toList(delay(() => append(singleton(createElement(ArchiveDialog, {
        IsArchived: props.IsArchived,
        IsOpen: patternInput[0],
        OnClose: () => {
            setIsArchivedDialogOpen(false);
        },
        OnSave: () => {
            props.Dispatch(new Msg(12, [!props.IsArchived]));
        },
    })), delay(() => {
        let Label;
        if (isFeatureEnabled(new Feature(6, []))) {
            const matchValue = props.UserData.Role;
            switch (matchValue) {
                case "toolManager":
                case "user": {
                    return empty();
                }
                default:
                    return singleton(createElement(TextButton, (Label = (props.IsArchived ? t("user.retrive_from_archive") : t("user.archive")), {
                        ComponentState: "enabled",
                        Icon: props.IsArchived ? Visible() : Invisible_1(),
                        Label: Label,
                        OnClick: () => {
                            setIsArchivedDialogOpen(true);
                        },
                        TestId: "toolbox-receiver-detail-view-archived-archive-button",
                        Variant: "default",
                    })));
            }
        }
        else {
            return empty();
        }
    })))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function ToolboxDetailActions(props) {
    let elems_1, elems;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    const dispolistHistoryRows = choose((row) => {
        const matchValue = row.ToolHistoryElementType;
        switch (matchValue.tag) {
            case 0:
            case 3:
            case 1:
                return undefined;
            default:
                return [row.InventoryName, row.ToolId, row.EventDate];
        }
    }, props.HistoryRows);
    const patternInput_2 = reactApi.useState(false);
    const setIsUnassignDispolistDialogOpen = patternInput_2[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-7", "mb-16", "justify-between"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "gap-3"])], (elems = toList(delay(() => {
        let Label;
        if (isFeatureEnabled(new Feature(0, []))) {
            const matchValue_1 = props.UserData.Role;
            switch (matchValue_1) {
                case "user": {
                    return empty();
                }
                default:
                    return !props.Receiver.IsArchived ? append(singleton(createElement(TextButton, (Label = t("dispolist_assign.assign_dispolist"), {
                        ComponentState: "enabled",
                        Icon: Followers(),
                        Label: Label,
                        OnClick: () => {
                            setIsModalOpen(true);
                        },
                        TestId: "assign-dispolist-button",
                        Variant: "default",
                    }))), delay(() => {
                        let Label_1;
                        return !isEmpty(dispolistHistoryRows) ? singleton(createElement(TextButton, (Label_1 = t("toolbox.dispolist_return"), {
                            ComponentState: "enabled",
                            Icon: Following(),
                            Label: Label_1,
                            OnClick: () => {
                                setIsUnassignDispolistDialogOpen(true);
                            },
                            TestId: "",
                            Variant: "default",
                        }))) : empty();
                    })) : empty();
            }
        }
        else {
            return empty();
        }
    })), ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(DispolistAssignView, {
        Customer: props.Customer,
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setIsModalOpen(false);
        },
        Receiver: props.Toolbox.Receiver,
        SuccessCallback: () => {
            props.Dispatch(new Msg(14, []));
        },
    }), createElement(UnassignDispolistDialog, {
        DispolistIdsAndNames: dispolistHistoryRows,
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsUnassignDispolistDialogOpen(false);
        },
        SenderId: props.Toolbox.Receiver.Id,
        SuccessCallback: () => {
            props.Dispatch(new Msg(14, []));
        },
        UserData: props.UserData,
    }), props.Actions], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function ReceiverDetails(props) {
    let elems_2, elems_1, ImageUrl, matchValue, elems;
    const data = useFeliz_React__React_useDeferred_Static_Z241A641(props.FetchReceiver, [props.TriggerRequest]);
    switch (data.tag) {
        case 2: {
            const receiver = data.fields[0];
            return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "grow"])], (elems_2 = [props.ReceiverActions(receiver), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "grow"])], (elems_1 = [createElement(ImageWidget, (ImageUrl = props.ImageUrl(receiver), {
                CanEditImage: (matchValue = props.UserData.Role, (matchValue === "system") ? true : ((matchValue === "toolManager") ? true : (!(matchValue === "user")))),
                ImageUrl: ImageUrl,
                OnDeleteImage: props.OnDeleteImage,
                OnUploadImage: props.OnUploadImage,
            })), createElement("div", createObj(ofArray([["className", join(" ", ["ml-10"])], (elems = [props.ReceiverDetails(receiver)], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
        }
        default:
            return defaultOf();
    }
}

function UserDetailButtons(props) {
    let elems;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsChangePasswordDialogOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsChangeEmailOrPhoneNumerDialogOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(false);
    const setIsArchivedDialogOpen = patternInput_3[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "gap-4"])], (elems = toList(delay(() => append(singleton(createElement(ChangePasswordDialog, {
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setIsChangePasswordDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(14, []));
        },
        UserId: props.User.Id,
    })), delay(() => append(singleton(createElement(ChangeUserEmailOrPhoneNumberDialog, {
        CurrentLoginInformation: props.User.LoginInformation,
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsChangeEmailOrPhoneNumerDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(14, []));
        },
        UserId: props.User.Id,
    })), delay(() => append(singleton(createElement(ArchiveDialog, {
        IsArchived: props.Receiver.IsArchived,
        IsOpen: patternInput_3[0],
        OnClose: () => {
            setIsArchivedDialogOpen(false);
        },
        OnSave: () => {
            props.Dispatch(new Msg(12, [!props.Receiver.IsArchived]));
        },
    })), delay(() => {
        let Label;
        return append(!props.Receiver.IsArchived ? append((props.User.LoginInformation.tag === 0) ? singleton(createElement(TextButton, (Label = t("user.change_password_dialog.title"), {
            ComponentState: "enabled",
            Icon: Pencil(),
            Label: Label,
            OnClick: () => {
                setIsChangePasswordDialogOpen(true);
            },
            TestId: "",
            Variant: "default",
        }))) : (empty()), delay(() => {
            let Label_1;
            return singleton(createElement(TextButton, (Label_1 = ((props.User.LoginInformation.tag === 0) ? t("user.change_email") : t("user.change_phone_number")), {
                ComponentState: "enabled",
                Icon: Pencil(),
                Label: Label_1,
                OnClick: () => {
                    setIsChangeEmailOrPhoneNumerDialogOpen(true);
                },
                TestId: "",
                Variant: "default",
            })));
        })) : empty(), delay(() => {
            let Label_2;
            return isFeatureEnabled(new Feature(6, [])) ? singleton(createElement(TextButton, (Label_2 = (props.Receiver.IsArchived ? t("user.retrive_from_archive") : t("user.archive")), {
                ComponentState: "enabled",
                Icon: props.Receiver.IsArchived ? Visible() : Invisible_1(),
                Label: Label_2,
                OnClick: () => {
                    setIsArchivedDialogOpen(true);
                },
                TestId: "",
                Variant: "default",
            }))) : empty();
        }));
    })))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

function EmployeeDetailButtons(props) {
    let elems;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsTransformEmployeeToUserDialogOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsArchivedDialogOpen = patternInput_2[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "gap-4"])], (elems = toList(delay(() => append(singleton(createElement(ChangeUserWithoutLoginToUserWithLoginDialog, {
        Employee: props.Employee,
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setIsTransformEmployeeToUserDialogOpen(false);
        },
        SuccessCallback: (userId) => {
            props.Dispatch(new Msg(11, [userId]));
        },
    })), delay(() => append(singleton(createElement(ArchiveDialog, {
        IsArchived: props.Receiver.IsArchived,
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsArchivedDialogOpen(false);
        },
        OnSave: () => {
            props.Dispatch(new Msg(12, [!props.Receiver.IsArchived]));
        },
    })), delay(() => {
        let Label;
        return append(!props.Receiver.IsArchived ? singleton(createElement(TextButton, (Label = t("user.change_user_type"), {
            ComponentState: "enabled",
            Icon: Pencil(),
            Label: Label,
            OnClick: () => {
                setIsTransformEmployeeToUserDialogOpen(true);
            },
            TestId: "",
            Variant: "default",
        }))) : empty(), delay(() => {
            let Label_1;
            return isFeatureEnabled(new Feature(6, [])) ? singleton(createElement(TextButton, (Label_1 = (props.Receiver.IsArchived ? t("user.retrive_from_archive") : t("user.archive")), {
                ComponentState: "enabled",
                Icon: props.Receiver.IsArchived ? Visible() : Invisible_1(),
                Label: Label_1,
                OnClick: () => {
                    setIsArchivedDialogOpen(true);
                },
                TestId: "",
                Variant: "default",
            }))) : empty();
        }));
    })))))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function customPropertiesFields(propertyConfigurations, customProperties, propertyConfigurationType) {
    return map((propertyConfiguration_1) => ({
        Label: propertyConfiguration_1.Name,
        Value: defaultArg(map_1((customProperty_1) => customProperty_1.Value, tryFind((customProperty) => equals(new PropertyConfigurationId(parse(customProperty.Id)), propertyConfiguration_1.Id), customProperties)), ""),
    }), filter((propertyConfiguration) => equals(propertyConfiguration.Visibility, propertyConfigurationType), propertyConfigurations));
}

export function UserDetails(props) {
    const t = useTranslation()[0];
    return createElement(ReceiverDetails, {
        FetchReceiver: Async_fetchWithDecoder(0, addPrefix(`/api/receivers/users/${unwrapUserId(new UserId_2(props.ReceiverId))}/details`), "", new HttpMethod(0, []), "application/json", (response) => singleton_1.Delay(() => {
            let resp;
            const matchValue = response.content;
            switch (matchValue.tag) {
                case 0: {
                    throw new Error("not implemented - text response");
                    break;
                }
                case 1: {
                    throw new Error("not implemented - blob response");
                    break;
                }
                case 3: {
                    resp = matchValue.fields[0];
                    break;
                }
                default:
                    resp = (new Uint8Array(matchValue.fields[0]));
            }
            return singleton_1.Return(Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(resp), Requests_UserDto_$reflection()));
        })),
        ImageUrl: (user) => user.ImageUrl,
        OnDeleteImage: props.OnDeleteImage,
        OnUploadImage: props.OnUploadImage,
        ReceiverActions: (user_1) => {
            let matchValue_1;
            return createElement(ToolboxDetailActions, {
                Actions: (matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? createElement(UserDetailButtons, {
                    Dispatch: props.Dispatch,
                    Receiver: props.ToolBox.Receiver,
                    User: user_1,
                }) : ((matchValue_1 === "toolManager") ? defaultOf() : ((matchValue_1 === "user") ? defaultOf() : createElement(UserDetailButtons, {
                    Dispatch: props.Dispatch,
                    Receiver: props.ToolBox.Receiver,
                    User: user_1,
                })))),
                Customer: props.Customer,
                Dispatch: props.Dispatch,
                HistoryRows: props.HistoryRows,
                Receiver: props.ToolBox.Receiver,
                Toolbox: props.ToolBox,
                UserData: props.UserData,
            });
        },
        ReceiverDetails: (user_2) => {
            let elems_1, elems;
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(PropertyList, {
                Properties: toList(delay(() => append(singleton({
                    Label: t("general.name"),
                    TestId: "receiver-name",
                    Value: user_2.Name,
                }), delay(() => append(singleton({
                    Label: t("user.personnel_number"),
                    TestId: "receiver-personnel-number",
                    Value: user_2.PersonnelNumber,
                }), delay(() => append(singleton({
                    Label: t("user.department"),
                    TestId: "receiver-department",
                    Value: user_2.Department,
                }), delay(() => append(isFeatureEnabled(new Feature(6, [])) ? singleton({
                    Label: t("user.is_archived"),
                    TestId: "receiver-is-archived",
                    Value: user_2.IsArchived ? t("general.yes") : t("general.no"),
                }) : empty(), delay(() => {
                    let matchValue_2;
                    return append((matchValue_2 = user_2.LoginInformation, (matchValue_2.tag === 1) ? singleton({
                        Label: t("user.phone_number"),
                        TestId: "receiver-phone-number",
                        Value: matchValue_2.fields[0],
                    }) : singleton({
                        Label: t("general.email"),
                        TestId: "receiver-email",
                        Value: matchValue_2.fields[0],
                    })), delay(() => {
                        let matchValue_3;
                        return singleton({
                            Label: t("general.role"),
                            TestId: "receiver-role",
                            Value: (matchValue_3 = user_2.Role, (matchValue_3 === "toolManager") ? t("role.tool_manager") : ((matchValue_3 === "user") ? t("role.employee") : ((matchValue_3 === "system") ? "system" : t("role.admin")))),
                        });
                    }));
                })))))))))),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["border-t", "border-divider"])], (elems = [createElement(CustomPropertiesPropertyList, {
                CustomProperties: user_2.CustomProperties,
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        },
        TriggerRequest: props.TriggerRerender,
        UserData: props.UserData,
    });
}

export function EmployeeDetails(props) {
    const t = useTranslation()[0];
    return createElement(ReceiverDetails, {
        FetchReceiver: Async_fetchWithDecoder(0, addPrefix(`/api/receivers/employees/${unwrapUserId(new UserId_2(props.ReceiverId))}/details`), "", new HttpMethod(0, []), "application/json", (response) => singleton_1.Delay(() => {
            let resp;
            const matchValue = response.content;
            switch (matchValue.tag) {
                case 0: {
                    throw new Error("not implemented - text response");
                    break;
                }
                case 1: {
                    throw new Error("not implemented - blob response");
                    break;
                }
                case 3: {
                    resp = matchValue.fields[0];
                    break;
                }
                default:
                    resp = (new Uint8Array(matchValue.fields[0]));
            }
            return singleton_1.Return(Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(resp), Requests_EmployeeDto_$reflection()));
        })),
        ImageUrl: (employee) => employee.ImageUrl,
        OnDeleteImage: props.OnDeleteImage,
        OnUploadImage: props.OnUploadImage,
        ReceiverActions: (employee_1) => {
            let matchValue_1;
            return createElement(ToolboxDetailActions, {
                Actions: (matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? createElement(EmployeeDetailButtons, {
                    Dispatch: props.Dispatch,
                    Employee: employee_1,
                    Receiver: props.ToolBox.Receiver,
                }) : ((matchValue_1 === "toolManager") ? defaultOf() : ((matchValue_1 === "user") ? defaultOf() : createElement(EmployeeDetailButtons, {
                    Dispatch: props.Dispatch,
                    Employee: employee_1,
                    Receiver: props.ToolBox.Receiver,
                })))),
                Customer: props.Customer,
                Dispatch: props.Dispatch,
                HistoryRows: props.HistoryRows,
                Receiver: props.ToolBox.Receiver,
                Toolbox: props.ToolBox,
                UserData: props.UserData,
            });
        },
        ReceiverDetails: (employee_2) => {
            let elems_1, elems;
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(PropertyList, {
                Properties: toList(delay(() => append(singleton({
                    Label: t("general.name"),
                    TestId: "receiver-name",
                    Value: employee_2.Name,
                }), delay(() => append(isFeatureEnabled(new Feature(6, [])) ? singleton({
                    Label: t("user.is_archived"),
                    TestId: "receiver-is-archived",
                    Value: employee_2.IsArchived ? t("general.yes") : t("general.no"),
                }) : empty(), delay(() => append(isFeatureEnabled(new Feature(15, [])) ? singleton({
                    Label: t("general.email"),
                    TestId: "receiver-email",
                    Value: employee_2.Email,
                }) : empty(), delay(() => append(singleton({
                    Label: t("user.personnel_number"),
                    TestId: "receiver-personnel-number",
                    Value: employee_2.PersonnelNumber,
                }), delay(() => singleton({
                    Label: t("user.department"),
                    TestId: "receiver-department",
                    Value: employee_2.Department,
                }))))))))))),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["border-t", "border-divider"])], (elems = [createElement(CustomPropertiesPropertyList, {
                CustomProperties: employee_2.CustomProperties,
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        },
        TriggerRequest: props.TriggerRerender,
        UserData: props.UserData,
    });
}

export function ExternalPersonDetails(props) {
    const t = useTranslation()[0];
    return createElement(ReceiverDetails, {
        FetchReceiver: Async_fetchWithDecoder(0, addPrefix(`/api/receivers/external-persons/${unwrapUserId(new UserId_2(props.ReceiverId))}/details`), "", new HttpMethod(0, []), "application/json", (response) => singleton_1.Delay(() => {
            let resp;
            const matchValue = response.content;
            switch (matchValue.tag) {
                case 0: {
                    throw new Error("not implemented - text response");
                    break;
                }
                case 1: {
                    throw new Error("not implemented - blob response");
                    break;
                }
                case 3: {
                    resp = matchValue.fields[0];
                    break;
                }
                default:
                    resp = (new Uint8Array(matchValue.fields[0]));
            }
            return singleton_1.Return(Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(resp), Requests_ExternalPersonDto_$reflection()));
        })),
        ImageUrl: (externalPerson) => externalPerson.ImageUrl,
        OnDeleteImage: props.OnDeleteImage,
        OnUploadImage: props.OnUploadImage,
        ReceiverActions: (_arg) => createElement(ToolboxDetailActions, {
            Actions: createElement(ReceiverDetailButtons, {
                Dispatch: props.Dispatch,
                IsArchived: props.ToolBox.Receiver.IsArchived,
                UserData: props.UserData,
            }),
            Customer: props.Customer,
            Dispatch: props.Dispatch,
            HistoryRows: props.HistoryRows,
            Receiver: props.ToolBox.Receiver,
            Toolbox: props.ToolBox,
            UserData: props.UserData,
        }),
        ReceiverDetails: (externalPerson_1) => {
            let elems_1, elems;
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(PropertyList, {
                Properties: toList(delay(() => append(singleton({
                    Label: t("general.name"),
                    TestId: "receiver-name",
                    Value: externalPerson_1.Name,
                }), delay(() => append(isFeatureEnabled(new Feature(15, [])) ? singleton({
                    Label: t("general.email"),
                    TestId: "receiver-email",
                    Value: externalPerson_1.Email,
                }) : empty(), delay(() => (isFeatureEnabled(new Feature(6, [])) ? singleton({
                    Label: t("user.is_archived"),
                    TestId: "receiver-is-archived",
                    Value: externalPerson_1.IsArchived ? t("general.yes") : t("general.no"),
                }) : empty()))))))),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["border-t", "border-divider"])], (elems = [createElement(CustomPropertiesPropertyList, {
                CustomProperties: externalPerson_1.CustomProperties,
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        },
        TriggerRequest: props.TriggerRerender,
        UserData: props.UserData,
    });
}

export function RoomDetails(props) {
    const t = useTranslation()[0];
    return createElement(ReceiverDetails, {
        FetchReceiver: Async_fetchWithDecoder(0, addPrefix(`/api/receivers/rooms/${unwrapUserId(new UserId_2(props.ReceiverId))}/details`), "", new HttpMethod(0, []), "application/json", (response) => singleton_1.Delay(() => {
            let resp;
            const matchValue = response.content;
            switch (matchValue.tag) {
                case 0: {
                    throw new Error("not implemented - text response");
                    break;
                }
                case 1: {
                    throw new Error("not implemented - blob response");
                    break;
                }
                case 3: {
                    resp = matchValue.fields[0];
                    break;
                }
                default:
                    resp = (new Uint8Array(matchValue.fields[0]));
            }
            return singleton_1.Return(Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(resp), Requests_RoomDto_$reflection()));
        })),
        ImageUrl: (room) => room.ImageUrl,
        OnDeleteImage: props.OnDeleteImage,
        OnUploadImage: props.OnUploadImage,
        ReceiverActions: (_arg) => createElement(ToolboxDetailActions, {
            Actions: createElement(ReceiverDetailButtons, {
                Dispatch: props.Dispatch,
                IsArchived: props.ToolBox.Receiver.IsArchived,
                UserData: props.UserData,
            }),
            Customer: props.Customer,
            Dispatch: props.Dispatch,
            HistoryRows: props.HistoryRows,
            Receiver: props.ToolBox.Receiver,
            Toolbox: props.ToolBox,
            UserData: props.UserData,
        }),
        ReceiverDetails: (room_1) => {
            let elems_1, elems;
            let locationName;
            const matchValue_1 = room_1.LocationId;
            if (matchValue_1 == null) {
                locationName = "";
            }
            else {
                const locationId = matchValue_1;
                locationName = defaultArg(map_1((location_1) => location_1.Name, tryFind((location) => equals(location.Id, locationId), props.Locations)), "");
            }
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(PropertyList, {
                Properties: toList(delay(() => append(singleton({
                    Label: t("general.name"),
                    TestId: "receiver-name",
                    Value: room_1.Name,
                }), delay(() => append(isFeatureEnabled(new Feature(15, [])) ? singleton({
                    Label: t("general.email"),
                    TestId: "receiver-email",
                    Value: room_1.Email,
                }) : empty(), delay(() => append(isFeatureEnabled(new Feature(6, [])) ? singleton({
                    Label: t("user.is_archived"),
                    TestId: "receiver-is-archived",
                    Value: room_1.IsArchived ? t("general.yes") : t("general.no"),
                }) : empty(), delay(() => append(singleton({
                    Label: t("room.number"),
                    TestId: "receiver-room-number",
                    Value: room_1.Number,
                }), delay(() => singleton({
                    Label: t("room.location"),
                    TestId: "receiver-location",
                    Value: locationName,
                }))))))))))),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["border-t", "border-divider"])], (elems = [createElement(CustomPropertiesPropertyList, {
                CustomProperties: room_1.CustomProperties,
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        },
        TriggerRequest: props.TriggerRerender,
        UserData: props.UserData,
    });
}

export function SubcontractorDetails(props) {
    const t = useTranslation()[0];
    return createElement(ReceiverDetails, {
        FetchReceiver: Async_fetchWithDecoder(0, addPrefix(`/api/receivers/subcontractors/${unwrapUserId(new UserId_2(props.ReceiverId))}/details`), "", new HttpMethod(0, []), "application/json", (response) => singleton_1.Delay(() => {
            let resp;
            const matchValue = response.content;
            switch (matchValue.tag) {
                case 0: {
                    throw new Error("not implemented - text response");
                    break;
                }
                case 1: {
                    throw new Error("not implemented - blob response");
                    break;
                }
                case 3: {
                    resp = matchValue.fields[0];
                    break;
                }
                default:
                    resp = (new Uint8Array(matchValue.fields[0]));
            }
            return singleton_1.Return(Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(resp), Requests_SubcontractorDto_$reflection()));
        })),
        ImageUrl: (subcontractor) => subcontractor.ImageUrl,
        OnDeleteImage: props.OnDeleteImage,
        OnUploadImage: props.OnUploadImage,
        ReceiverActions: (_arg) => createElement(ToolboxDetailActions, {
            Actions: createElement(ReceiverDetailButtons, {
                Dispatch: props.Dispatch,
                IsArchived: props.ToolBox.Receiver.IsArchived,
                UserData: props.UserData,
            }),
            Customer: props.Customer,
            Dispatch: props.Dispatch,
            HistoryRows: props.HistoryRows,
            Receiver: props.ToolBox.Receiver,
            Toolbox: props.ToolBox,
            UserData: props.UserData,
        }),
        ReceiverDetails: (subcontractor_1) => {
            let elems_1, elems;
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(PropertyList, {
                Properties: toList(delay(() => append(singleton({
                    Label: t("general.name"),
                    TestId: "receiver-name",
                    Value: subcontractor_1.Name,
                }), delay(() => append(isFeatureEnabled(new Feature(15, [])) ? singleton({
                    Label: t("general.email"),
                    TestId: "receiver-email",
                    Value: subcontractor_1.Email,
                }) : empty(), delay(() => (isFeatureEnabled(new Feature(6, [])) ? singleton({
                    Label: t("user.is_archived"),
                    TestId: "receiver-is-archived",
                    Value: subcontractor_1.IsArchived ? t("general.yes") : t("general.no"),
                }) : empty()))))))),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["border-t", "border-divider"])], (elems = [createElement(CustomPropertiesPropertyList, {
                CustomProperties: subcontractor_1.CustomProperties,
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        },
        TriggerRequest: props.TriggerRerender,
        UserData: props.UserData,
    });
}

export function ProjectDetails(props) {
    const t = useTranslation()[0];
    return createElement(ReceiverDetails, {
        FetchReceiver: Async_fetchWithDecoder(0, addPrefix(`/api/receivers/projects/${unwrapUserId(new UserId_2(props.ReceiverId))}/details`), "", new HttpMethod(0, []), "application/json", (response) => singleton_1.Delay(() => {
            let resp;
            const matchValue = response.content;
            switch (matchValue.tag) {
                case 0: {
                    throw new Error("not implemented - text response");
                    break;
                }
                case 1: {
                    throw new Error("not implemented - blob response");
                    break;
                }
                case 3: {
                    resp = matchValue.fields[0];
                    break;
                }
                default:
                    resp = (new Uint8Array(matchValue.fields[0]));
            }
            return singleton_1.Return(Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(resp), Requests_ProjectDto_$reflection()));
        })),
        ImageUrl: (project) => project.ImageUrl,
        OnDeleteImage: props.OnDeleteImage,
        OnUploadImage: props.OnUploadImage,
        ReceiverActions: (_arg) => createElement(ToolboxDetailActions, {
            Actions: createElement(ReceiverDetailButtons, {
                Dispatch: props.Dispatch,
                IsArchived: props.ToolBox.Receiver.IsArchived,
                UserData: props.UserData,
            }),
            Customer: props.Customer,
            Dispatch: props.Dispatch,
            HistoryRows: props.HistoryRows,
            Receiver: props.ToolBox.Receiver,
            Toolbox: props.ToolBox,
            UserData: props.UserData,
        }),
        ReceiverDetails: (project_1) => {
            let elems_1, elems;
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(PropertyList, {
                Properties: toList(delay(() => append(singleton({
                    Label: t("general.name"),
                    TestId: "receiver-name",
                    Value: project_1.Name,
                }), delay(() => append(isFeatureEnabled(new Feature(15, [])) ? singleton({
                    Label: t("general.email"),
                    TestId: "receiver-email",
                    Value: project_1.Email,
                }) : empty(), delay(() => append(singleton({
                    Label: t("general.description"),
                    TestId: "receiver-description",
                    Value: project_1.Description,
                }), delay(() => (isFeatureEnabled(new Feature(6, [])) ? singleton({
                    Label: t("user.is_archived"),
                    TestId: "receiver-is-archived",
                    Value: project_1.IsArchived ? t("general.yes") : t("general.no"),
                }) : empty()))))))))),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["border-t", "border-divider"])], (elems = [createElement(CustomPropertiesPropertyList, {
                CustomProperties: project_1.CustomProperties,
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        },
        TriggerRequest: props.TriggerRerender,
        UserData: props.UserData,
    });
}

export function ConstructionSiteDetails(props) {
    const t = useTranslation()[0];
    return createElement(ReceiverDetails, {
        FetchReceiver: Async_fetchWithDecoder(0, addPrefix(`/api/receivers/construction-sites/${unwrapUserId(new UserId_2(props.ReceiverId))}/details`), "", new HttpMethod(0, []), "application/json", (response) => singleton_1.Delay(() => {
            let resp;
            const matchValue = response.content;
            switch (matchValue.tag) {
                case 0: {
                    throw new Error("not implemented - text response");
                    break;
                }
                case 1: {
                    throw new Error("not implemented - blob response");
                    break;
                }
                case 3: {
                    resp = matchValue.fields[0];
                    break;
                }
                default:
                    resp = (new Uint8Array(matchValue.fields[0]));
            }
            return singleton_1.Return(Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(resp), Requests_ConstructionSiteDto_$reflection()));
        })),
        ImageUrl: (constructionSite) => constructionSite.ImageUrl,
        OnDeleteImage: props.OnDeleteImage,
        OnUploadImage: props.OnUploadImage,
        ReceiverActions: (_arg) => createElement(ToolboxDetailActions, {
            Actions: createElement(ReceiverDetailButtons, {
                Dispatch: props.Dispatch,
                IsArchived: props.ToolBox.Receiver.IsArchived,
                UserData: props.UserData,
            }),
            Customer: props.Customer,
            Dispatch: props.Dispatch,
            HistoryRows: props.HistoryRows,
            Receiver: props.ToolBox.Receiver,
            Toolbox: props.ToolBox,
            UserData: props.UserData,
        }),
        ReceiverDetails: (constructionSite_1) => {
            let elems_1, elems;
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(PropertyList, {
                Properties: toList(delay(() => append(singleton({
                    Label: t("general.name"),
                    TestId: "receiver-name",
                    Value: constructionSite_1.Name,
                }), delay(() => append(isFeatureEnabled(new Feature(15, [])) ? singleton({
                    Label: t("general.email"),
                    TestId: "receiver-email",
                    Value: constructionSite_1.Email,
                }) : empty(), delay(() => append(singleton({
                    Label: t("general.description"),
                    TestId: "receiver-description",
                    Value: constructionSite_1.Description,
                }), delay(() => (isFeatureEnabled(new Feature(6, [])) ? singleton({
                    Label: t("user.is_archived"),
                    TestId: "receiver-is-archived",
                    Value: constructionSite_1.IsArchived ? t("general.yes") : t("general.no"),
                }) : empty()))))))))),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["border-t", "border-divider"])], (elems = [createElement(CustomPropertiesPropertyList, {
                CustomProperties: constructionSite_1.CustomProperties,
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        },
        TriggerRequest: props.TriggerRerender,
        UserData: props.UserData,
    });
}

export function SchoolClassDetails(props) {
    const t = useTranslation()[0];
    return createElement(ReceiverDetails, {
        FetchReceiver: Async_fetchWithDecoder(0, addPrefix(`/api/receivers/school-classes/${unwrapUserId(new UserId_2(props.ReceiverId))}/details`), "", new HttpMethod(0, []), "application/json", (response) => singleton_1.Delay(() => {
            let resp;
            const matchValue = response.content;
            switch (matchValue.tag) {
                case 0: {
                    throw new Error("not implemented - text response");
                    break;
                }
                case 1: {
                    throw new Error("not implemented - blob response");
                    break;
                }
                case 3: {
                    resp = matchValue.fields[0];
                    break;
                }
                default:
                    resp = (new Uint8Array(matchValue.fields[0]));
            }
            return singleton_1.Return(Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(resp), Requests_SchoolClassDto_$reflection()));
        })),
        ImageUrl: (schoolClass) => schoolClass.ImageUrl,
        OnDeleteImage: props.OnDeleteImage,
        OnUploadImage: props.OnUploadImage,
        ReceiverActions: (_arg) => createElement(ToolboxDetailActions, {
            Actions: createElement(ReceiverDetailButtons, {
                Dispatch: props.Dispatch,
                IsArchived: props.ToolBox.Receiver.IsArchived,
                UserData: props.UserData,
            }),
            Customer: props.Customer,
            Dispatch: props.Dispatch,
            HistoryRows: props.HistoryRows,
            Receiver: props.ToolBox.Receiver,
            Toolbox: props.ToolBox,
            UserData: props.UserData,
        }),
        ReceiverDetails: (schoolClass_1) => {
            let elems_1, elems;
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(PropertyList, {
                Properties: toList(delay(() => append(singleton({
                    Label: t("general.name"),
                    TestId: "receiver-name",
                    Value: schoolClass_1.Name,
                }), delay(() => append(isFeatureEnabled(new Feature(15, [])) ? singleton({
                    Label: t("general.email"),
                    TestId: "receiver-email",
                    Value: schoolClass_1.Email,
                }) : empty(), delay(() => append(singleton({
                    Label: t("general.description"),
                    TestId: "receiver-description",
                    Value: schoolClass_1.Description,
                }), delay(() => (isFeatureEnabled(new Feature(6, [])) ? singleton({
                    Label: t("user.is_archived"),
                    TestId: "receiver-is-archived",
                    Value: schoolClass_1.IsArchived ? t("general.yes") : t("general.no"),
                }) : empty()))))))))),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["border-t", "border-divider"])], (elems = [createElement(CustomPropertiesPropertyList, {
                CustomProperties: schoolClass_1.CustomProperties,
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        },
        TriggerRequest: props.TriggerRerender,
        UserData: props.UserData,
    });
}

export function StudentDetails(props) {
    const t = useTranslation()[0];
    return createElement(ReceiverDetails, {
        FetchReceiver: Async_fetchWithDecoder(0, addPrefix(`/api/receivers/students/${unwrapUserId(new UserId_2(props.ReceiverId))}/details`), "", new HttpMethod(0, []), "application/json", (response) => singleton_1.Delay(() => {
            let resp;
            const matchValue = response.content;
            switch (matchValue.tag) {
                case 0: {
                    throw new Error("not implemented - text response");
                    break;
                }
                case 1: {
                    throw new Error("not implemented - blob response");
                    break;
                }
                case 3: {
                    resp = matchValue.fields[0];
                    break;
                }
                default:
                    resp = (new Uint8Array(matchValue.fields[0]));
            }
            return singleton_1.Return(Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(resp), Requests_StudentDto_$reflection()));
        })),
        ImageUrl: (student) => student.ImageUrl,
        OnDeleteImage: props.OnDeleteImage,
        OnUploadImage: props.OnUploadImage,
        ReceiverActions: (_arg) => createElement(ToolboxDetailActions, {
            Actions: createElement(ReceiverDetailButtons, {
                Dispatch: props.Dispatch,
                IsArchived: props.ToolBox.Receiver.IsArchived,
                UserData: props.UserData,
            }),
            Customer: props.Customer,
            Dispatch: props.Dispatch,
            HistoryRows: props.HistoryRows,
            Receiver: props.ToolBox.Receiver,
            Toolbox: props.ToolBox,
            UserData: props.UserData,
        }),
        ReceiverDetails: (student_1) => {
            let elems_1, elems;
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(PropertyList, {
                Properties: toList(delay(() => append(singleton({
                    Label: t("general.name"),
                    TestId: "receiver-name",
                    Value: student_1.Name,
                }), delay(() => append(singleton({
                    Label: t("student.student_number"),
                    TestId: "receiver-student-number",
                    Value: student_1.StudentNumber,
                }), delay(() => append(isFeatureEnabled(new Feature(15, [])) ? singleton({
                    Label: t("general.email"),
                    TestId: "receiver-email",
                    Value: student_1.Email,
                }) : empty(), delay(() => (isFeatureEnabled(new Feature(6, [])) ? singleton({
                    Label: t("user.is_archived"),
                    TestId: "receiver-is-archived",
                    Value: student_1.IsArchived ? t("general.yes") : t("general.no"),
                }) : empty()))))))))),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["border-t", "border-divider"])], (elems = [createElement(CustomPropertiesPropertyList, {
                CustomProperties: student_1.CustomProperties,
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        },
        TriggerRequest: props.TriggerRerender,
        UserData: props.UserData,
    });
}

export function VehicleDetails(props) {
    const t = useTranslation()[0];
    return createElement(ReceiverDetails, {
        FetchReceiver: Async_fetchWithDecoder(0, addPrefix(`/api/receivers/vehicles/${unwrapUserId(new UserId_2(props.ReceiverId))}/details`), "", new HttpMethod(0, []), "application/json", (response) => singleton_1.Delay(() => {
            let resp;
            const matchValue = response.content;
            switch (matchValue.tag) {
                case 0: {
                    throw new Error("not implemented - text response");
                    break;
                }
                case 1: {
                    throw new Error("not implemented - blob response");
                    break;
                }
                case 3: {
                    resp = matchValue.fields[0];
                    break;
                }
                default:
                    resp = (new Uint8Array(matchValue.fields[0]));
            }
            return singleton_1.Return(Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(resp), Requests_VehicleDto_$reflection()));
        })),
        ImageUrl: (student) => student.ImageUrl,
        OnDeleteImage: props.OnDeleteImage,
        OnUploadImage: props.OnUploadImage,
        ReceiverActions: (_arg) => createElement(ToolboxDetailActions, {
            Actions: createElement(ReceiverDetailButtons, {
                Dispatch: props.Dispatch,
                IsArchived: props.ToolBox.Receiver.IsArchived,
                UserData: props.UserData,
            }),
            Customer: props.Customer,
            Dispatch: props.Dispatch,
            HistoryRows: props.HistoryRows,
            Receiver: props.ToolBox.Receiver,
            Toolbox: props.ToolBox,
            UserData: props.UserData,
        }),
        ReceiverDetails: (vehicle) => {
            let elems_1, elems;
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(PropertyList, {
                Properties: toList(delay(() => append(singleton({
                    Label: t("general.name"),
                    TestId: "receiver-name",
                    Value: vehicle.Name,
                }), delay(() => append(singleton({
                    Label: t("vehicle.numberplate"),
                    TestId: "receiver-numberplate",
                    Value: vehicle.NumberPlate,
                }), delay(() => append(singleton({
                    Label: t("vehicle.manufacturer"),
                    TestId: "receiver-manufacturer",
                    Value: vehicle.Manufacturer,
                }), delay(() => append(singleton({
                    Label: t("vehicle.model"),
                    TestId: "receiver-model",
                    Value: vehicle.Model,
                }), delay(() => append(isFeatureEnabled(new Feature(15, [])) ? singleton({
                    Label: t("general.email"),
                    TestId: "receiver-email",
                    Value: vehicle.Email,
                }) : empty(), delay(() => (isFeatureEnabled(new Feature(6, [])) ? singleton({
                    Label: t("user.is_archived"),
                    TestId: "receiver-is-archived",
                    Value: vehicle.IsArchived ? t("general.yes") : t("general.no"),
                }) : empty()))))))))))))),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["border-t", "border-divider"])], (elems = [createElement(CustomPropertiesPropertyList, {
                CustomProperties: vehicle.CustomProperties,
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        },
        TriggerRequest: props.TriggerRerender,
        UserData: props.UserData,
    });
}

