import { createElement } from "react";
import React from "react";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ofArray, map } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { TextButton } from "../Components/Button.js";
import { unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";

export function TabsMenu(props) {
    let elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "align-text-middle"])], (elems_1 = toList(delay(() => map((tab) => {
        let elems;
        return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("px-4"), delay(() => append(singleton("my-auto"), delay(() => append(singleton("py-1.5"), delay(() => append(singleton("text-center"), delay(() => append(singleton("max-h-[100%]"), delay(() => append(singleton("text-muted"), delay(() => (tab.IsSelected ? append(singleton("bg-white"), delay(() => append(singleton("rounded-2xl"), delay(() => singleton("px-auto"))))) : empty()))))))))))))))))], (elems = [createElement(TextButton, {
            ComponentState: "enabled",
            Icon: unwrap(tab.Icon),
            Label: tab.Label,
            OnClick: tab.OnClick,
            TestId: tab.TestId,
            Variant: tab.IsSelected ? "default" : "muted",
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
    }, props.Tabs))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

