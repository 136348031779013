import { RequestedValue$1, LocationId } from "../Shared/Shared.js";
import { now } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { DeleteLocationResponse_$reflection, Location_$reflection, Location } from "../Shared/Location.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../Communication.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { list_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { unwrapLocationId } from "../Shared/Helper.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { unwrapLocationId as unwrapLocationId_1 } from "../Shared/Helper.js";
import { Cmd_ofEffect } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_encodeParts, RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Cmd_errorToast, Cmd_successToast } from "../Components/Toast.js";

export const emptyLocation = new Location(new LocationId("00000000-0000-0000-0000-000000000000"), "", "", "", false, now(), now());

export function init() {
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), undefined, false), Cmd_batch(singleton(Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Location_$reflection())));
    })))), "/api/locations", (Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(8, [Item_1])))))];
}

export function initNewLocationView() {
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(1, [emptyLocation]), undefined, false), Cmd_batch(singleton(Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Location_$reflection())));
    })))), "/api/locations", (Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(8, [Item_1])))))];
}

export function initViewLocationView(locationId) {
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), undefined, false), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Location_$reflection()));
    })))), `/api/locations/${unwrapLocationId(locationId)}`, (Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(8, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Location_$reflection())));
    })))), "/api/locations", (Item_2) => (new Msg(6, [Item_2])), (Item_3) => (new Msg(8, [Item_3])))]))];
}

export function initEditLocationView(locationId) {
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), undefined, false), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Location_$reflection()));
    })))), `/api/locations/${unwrapLocationId(locationId)}`, (Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(8, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Location_$reflection())));
    })))), "/api/locations", (Item_2) => (new Msg(6, [Item_2])), (Item_3) => (new Msg(8, [Item_3])))]))];
}

export function update(msg, state) {
    let location_1, xs, fullPath_1;
    switch (msg.tag) {
        case 1: {
            const matchValue_1 = state.Form;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const form_1 = matchValue_1.fields[0];
                return [new State(state.Locations, new RequestedValue$1(1, [new Location(form_1.Id, form_1.Name, msg.fields[0], form_1.ImageUrl, form_1.IsDeleted, form_1.CreatedAt, form_1.UpdatedAt)]), state.Message, state.IsInvalid), Cmd_none()];
            }
        }
        case 2: {
            const matchValue_2 = state.Form;
            if (matchValue_2.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const form_2 = matchValue_2.fields[0];
                if (isNullOrWhiteSpace(form_2.Name)) {
                    return [new State(state.Locations, state.Form, state.Message, true), Cmd_none()];
                }
                else {
                    return [new State(state.Locations, state.Form, undefined, false), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                        const pr = response.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Location_$reflection()));
                    })))), ["/api/locations", toString(0, Auto_generateBoxedEncoder_437914C6(Location_$reflection(), undefined, undefined, undefined)(form_2))], (Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(9, [Item_1])))];
                }
            }
        }
        case 3: {
            const matchValue_3 = state.Form;
            if (matchValue_3.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const form_3 = matchValue_3.fields[0];
                if (isNullOrWhiteSpace(form_3.Name)) {
                    return [new State(state.Locations, state.Form, state.Message, true), Cmd_none()];
                }
                else {
                    return [new State(state.Locations, state.Form, undefined, false), (location_1 = form_3, Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg_1[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                        const pr_1 = response_1.arrayBuffer();
                        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), Location_$reflection()));
                    })))), [`/api/locations/${location_1.Id}`, toString(0, Auto_generateBoxedEncoder_437914C6(Location_$reflection(), undefined, undefined, undefined)(location_1))], (Item_2) => (new Msg(7, [Item_2])), (Item_3) => (new Msg(9, [Item_3]))))];
                }
            }
        }
        case 5:
            return [new State(state.Locations, new RequestedValue$1(1, [msg.fields[0]]), state.Message, state.IsInvalid), Cmd_none()];
        case 6:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.Form, state.Message, state.IsInvalid), Cmd_none()];
        case 7:
            return [state, Cmd_batch(ofArray([(xs = ["locations", unwrapLocationId_1(msg.fields[0].Id)], Cmd_ofEffect((_arg) => {
                RouterModule_nav(ofArray(xs), 1, 1);
            })), Cmd_successToast("location.location_saved_success")]))];
        case 8:
            return [state, Cmd_errorToast("storage.fail_fetch_data")];
        case 9:
            return [state, Cmd_errorToast("location.location_saved_error")];
        case 4: {
            const matchValue_4 = state.Form;
            if (matchValue_4.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response_2) => {
                    const pr_2 = response_2.arrayBuffer();
                    return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), DeleteLocationResponse_$reflection()));
                })))), `/api/locations/${unwrapLocationId(matchValue_4.fields[0].Id)}`, (Item_4) => (new Msg(10, [Item_4])), (Item_5) => (new Msg(8, [Item_5])))];
            }
        }
        case 10:
            if (msg.fields[0].tag === 1) {
                return [state, Cmd_errorToast("location.location_deleted_error")];
            }
            else {
                return [state, Cmd_batch(ofArray([(fullPath_1 = RouterModule_encodeParts(singleton("locations"), 1), Cmd_ofEffect((_arg_1) => {
                    RouterModule_nav(singleton(fullPath_1), 1, 1);
                })), Cmd_successToast("location.location_deleted_success")]))];
            }
        default: {
            const matchValue = state.Form;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const form = matchValue.fields[0];
                return [new State(state.Locations, new RequestedValue$1(1, [new Location(form.Id, msg.fields[0], form.Description, form.ImageUrl, form.IsDeleted, form.CreatedAt, form.UpdatedAt)]), state.Message, state.IsInvalid), Cmd_none()];
            }
        }
    }
}

