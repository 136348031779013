import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { empty, tryFind, choose, ofArray, singleton, toArray, filter } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createObj, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Helper_toElement, Helper_containsElement, ElementType } from "../../Shared/Dispolist.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { BoxHeader } from "../../Components/Text.js";
import { TableControl$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import Shipment_Add from "../../../public/assets/icons/Shipment_Add.svg";
import { Helper_unitToHumanString } from "../../Shared/Consumable.js";
import { unwrapToolId, unwrapConsumableId } from "../../Shared/Helper.js";
import { FormContainer } from "../../Components/FormContainer.js";
import { ModalSheet } from "../../Components/ModalSheet.js";
import { Msg } from "./Types.js";
import { NumberInput, Input } from "../../Components/Input.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { TableContainer } from "../../Widgets/Container.js";
import { RouterModule_encodeParts, RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { initEdit, update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

function ToolElementModal(props) {
    let elems, TableRows, Options;
    const t = useTranslation()[0];
    let patternInput_1;
    const initial = filter((element) => equals(element.Type, new ElementType(1, [])), props.Elements);
    patternInput_1 = reactApi.useState(initial);
    return createElement("div", createObj(ofArray([["className", join(" ", ["p-4"])], (elems = [createElement(BoxHeader, {
        Text: t("general.tool"),
    }), createElement(Table, (TableRows = toArray(patternInput_1[0]), (Options = {
        BorderStyle: "full",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: "multiRow",
    }, {
        ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (row) => (new CellContent(0, [row.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_1) => (new CellContent(0, [row_1.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_2) => (new CellContent(0, [row_2.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_3) => (new CellContent(0, [row_3.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_4) => (new CellContent(0, [row_4.SerialNumber])), "text", "text")],
        Controls: singleton(new TableControl$1(t("dispolist.add"), Shipment_Add(), (row_5) => {
            props.SetSelectedElements(row_5);
            props.IsOpen(false);
        }, false, false, "")),
        Options: Options,
        TableRows: TableRows,
    })))], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

function ConsumableElementModal(props) {
    let elems, TableRows, Options;
    const t = useTranslation()[0];
    let patternInput_1;
    const initial = filter((element) => equals(element.Type, new ElementType(2, [])), props.Elements);
    patternInput_1 = reactApi.useState(initial);
    return createElement("div", createObj(ofArray([["className", join(" ", ["p-4"])], (elems = [createElement(BoxHeader, {
        Text: t("general.consumable"),
    }), createElement(Table, (TableRows = toArray(patternInput_1[0]), (Options = {
        BorderStyle: "full",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: "multiRow",
    }, {
        ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (row) => (new CellContent(0, [row.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_1) => (new CellContent(0, [row_1.InventoryNumber])), "text", "text"), new ColumnDefinition$1("additionalName", "", t("consumable.additional_name"), (row_2) => (new CellContent(0, [row_2.AdditionalName])), "text", "text"), new ColumnDefinition$1("unit", "", t("general.unit"), (row_3) => {
            const matchValue = row_3.Unit;
            return (matchValue == null) ? (new CellContent(0, [t("unit.piece")])) : (new CellContent(0, [Helper_unitToHumanString(matchValue)]));
        }, "none", "text")],
        Controls: singleton(new TableControl$1(t("dispolist.add"), Shipment_Add(), (row_4) => {
            props.SetSelectedElements(row_4);
            props.IsOpen(false);
        }, false, false, "")),
        Options: Options,
        TableRows: TableRows,
    })))], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

function ToolWithQuantityElementModal(props) {
    let elems, TableRows, Options;
    const t = useTranslation()[0];
    let patternInput_1;
    const initial = filter((element) => equals(element.Type, new ElementType(0, [])), props.Elements);
    patternInput_1 = reactApi.useState(initial);
    return createElement("div", createObj(ofArray([["className", join(" ", ["p-4"])], (elems = [createElement(BoxHeader, {
        Text: t("general.tool_with_quantity"),
    }), createElement(Table, (TableRows = toArray(patternInput_1[0]), (Options = {
        BorderStyle: "full",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: "multiRow",
    }, {
        ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (row) => (new CellContent(0, [row.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_1) => (new CellContent(0, [row_1.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_2) => (new CellContent(0, [row_2.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_3) => (new CellContent(0, [row_3.Model])), "text", "text")],
        Controls: singleton(new TableControl$1(t("dispolist.add"), Shipment_Add(), (row_4) => {
            props.SetSelectedElements(row_4);
            props.IsOpen(false);
        }, false, false, "")),
        Options: Options,
        TableRows: TableRows,
    })))], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

function Form(props) {
    let matchValue, Title, TableRows, Options, Title_1, TableRows_1, Options_1, Title_2, TableRows_2, Options_2;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsAddToolModalOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsAddToolWithQuantityModalOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(false);
    const setIsAddConsumableModalOpen = patternInput_3[1];
    const selectedElements = filter((e) => !Helper_containsElement(props.Dispolist, Helper_toElement(e)), props.Elements);
    const selectableToolElements = filter((element_1) => equals(element_1.Type, new ElementType(1, [])), selectedElements);
    const selectableToolWithQuantityElements = filter((element_2) => equals(element_2.Type, new ElementType(0, [])), selectedElements);
    const selectableConsumableElements = filter((element_3) => equals(element_3.Type, new ElementType(2, [])), selectedElements);
    const formState = props.IsLoading ? "disabled" : "enabled";
    const toolElements = choose((element_4) => {
        switch (element_4.tag) {
            case 0:
            case 2:
                return undefined;
            default:
                return element_4;
        }
    }, props.Dispolist.Elements);
    const toolWithQuantityElements = choose((element_5) => {
        switch (element_5.tag) {
            case 0:
            case 1:
                return undefined;
            default:
                return element_5;
        }
    }, props.Dispolist.Elements);
    const consumableElements = choose((element_6) => {
        switch (element_6.tag) {
            case 1:
            case 2:
                return undefined;
            default:
                return element_6;
        }
    }, props.Dispolist.Elements);
    const getElement = (element_7) => tryFind((e_1) => {
        switch (element_7.tag) {
            case 0:
                return unwrapConsumableId(element_7.fields[0].ConsumableId) === e_1.Id;
            case 2:
                return unwrapToolId(element_7.fields[0].ToolId) === e_1.Id;
            default:
                return unwrapToolId(element_7.fields[0].ToolId) === e_1.Id;
        }
    }, props.Elements);
    return createElement(FormContainer, {
        Children: ofArray([createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: createElement(ToolElementModal, {
                Elements: selectableToolElements,
                IsOpen: setIsAddToolModalOpen,
                SetSelectedElements: (elements) => {
                    props.Dispatch(new Msg(3, [elements]));
                },
            }),
            Controls: empty(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsAddToolModalOpen(false);
            },
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: createElement(ToolWithQuantityElementModal, {
                Elements: selectableToolWithQuantityElements,
                IsOpen: setIsAddToolWithQuantityModalOpen,
                SetSelectedElements: (elements_1) => {
                    props.Dispatch(new Msg(3, [elements_1]));
                },
            }),
            Controls: empty(),
            IsModalOpen: patternInput_2[0],
            OnClose: () => {
                setIsAddToolWithQuantityModalOpen(false);
            },
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: createElement(ConsumableElementModal, {
                Elements: selectableConsumableElements,
                IsOpen: setIsAddConsumableModalOpen,
                SetSelectedElements: (elements_2) => {
                    props.Dispatch(new Msg(3, [elements_2]));
                },
            }),
            Controls: empty(),
            IsModalOpen: patternInput_3[0],
            OnClose: () => {
                setIsAddConsumableModalOpen(false);
            },
        }), createElement(Input, {
            ComponentState: formState,
            Label: t("general.name"),
            OnChange: (arg) => {
                props.Dispatch(new Msg(0, [arg]));
            },
            TestId: "",
            ValidationMessage: unwrap(props.IsValidated ? t("dispolist.invalid_name") : ((matchValue = props.Message, (matchValue == null) ? undefined : t(matchValue)))),
            Value: props.Dispolist.Name,
        }), createElement(Input, {
            ComponentState: formState,
            Label: t("general.description"),
            OnChange: (arg_1) => {
                props.Dispatch(new Msg(1, [arg_1]));
            },
            TestId: "",
            Value: props.Dispolist.Description,
        }), createElement(TableContainer, (Title = t("general.tool"), {
            Content: createElement(Table, (TableRows = toArray(toolElements), (Options = {
                BorderStyle: "full",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["name", "asc"],
                GlobalSearch: false,
                RowSelection: "disabled",
            }, {
                ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (row) => {
                    const matchValue_1 = getElement(row);
                    return (matchValue_1 != null) ? (new CellContent(0, [matchValue_1.DisplayName])) : (new CellContent(0, [""]));
                }, "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_1) => {
                    const matchValue_2 = getElement(row_1);
                    return (matchValue_2 != null) ? (new CellContent(0, [matchValue_2.InventoryNumber])) : (new CellContent(0, [""]));
                }, "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_2) => {
                    const matchValue_3 = getElement(row_2);
                    return (matchValue_3 != null) ? (new CellContent(0, [matchValue_3.Manufacturer])) : (new CellContent(0, [""]));
                }, "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_3) => {
                    const matchValue_4 = getElement(row_3);
                    return (matchValue_4 != null) ? (new CellContent(0, [matchValue_4.Model])) : (new CellContent(0, [""]));
                }, "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_4) => {
                    const matchValue_5 = getElement(row_4);
                    return (matchValue_5 != null) ? (new CellContent(0, [matchValue_5.SerialNumber])) : (new CellContent(0, [""]));
                }, "text", "text"), new ColumnDefinition$1("action", "", t("general.action"), (row_5) => (new CellContent(2, [t("general.remove"), () => {
                    props.Dispatch(new Msg(4, [row_5]));
                }])), "none", "button")],
                Controls: singleton(new TableControl$1(t("dispolist.add"), Shipment_Add(), (_arg_6) => {
                    setIsAddToolModalOpen(true);
                }, false, false, "")),
                Options: Options,
                TableRows: TableRows,
            }))),
            Title: unwrap(Title),
        })), createElement(TableContainer, (Title_1 = t("general.tool_with_quantity"), {
            Content: createElement(Table, (TableRows_1 = toArray(toolWithQuantityElements), (Options_1 = {
                BorderStyle: "full",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["name", "asc"],
                GlobalSearch: false,
                RowSelection: "disabled",
            }, {
                ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (row_6) => {
                    const matchValue_6 = getElement(row_6);
                    return (matchValue_6 != null) ? (new CellContent(0, [matchValue_6.DisplayName])) : (new CellContent(0, [""]));
                }, "text", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_7) => {
                    switch (row_7.tag) {
                        case 1:
                            return new CellContent(0, ["1"]);
                        case 2: {
                            const element_15 = row_7.fields[0];
                            return new CellContent(1, [createElement(NumberInput, {
                                ComponentState: formState,
                                OnChange: (value_3) => {
                                    props.Dispatch(new Msg(2, [element_15.Id, value_3]));
                                },
                                TestId: "",
                                Value: element_15.Quantity,
                            })]);
                        }
                        default: {
                            const element_14 = row_7.fields[0];
                            return new CellContent(1, [createElement(NumberInput, {
                                ComponentState: formState,
                                OnChange: (value_1) => {
                                    props.Dispatch(new Msg(2, [element_14.Id, value_1]));
                                },
                                TestId: "",
                                Value: element_14.Quantity,
                            })]);
                        }
                    }
                }, "none", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_8) => {
                    const matchValue_7 = getElement(row_8);
                    return (matchValue_7 != null) ? (new CellContent(0, [matchValue_7.InventoryNumber])) : (new CellContent(0, [""]));
                }, "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_9) => {
                    const matchValue_8 = getElement(row_9);
                    return (matchValue_8 != null) ? (new CellContent(0, [matchValue_8.Manufacturer])) : (new CellContent(0, [""]));
                }, "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_10) => {
                    const matchValue_9 = getElement(row_10);
                    return (matchValue_9 != null) ? (new CellContent(0, [matchValue_9.Model])) : (new CellContent(0, [""]));
                }, "text", "text"), new ColumnDefinition$1("action", "", t("general.action"), (row_11) => (new CellContent(2, [t("general.remove"), () => {
                    props.Dispatch(new Msg(4, [row_11]));
                }])), "none", "button")],
                Controls: singleton(new TableControl$1(t("dispolist.add"), Shipment_Add(), (_arg_8) => {
                    setIsAddToolWithQuantityModalOpen(true);
                }, false, false, "")),
                Options: Options_1,
                TableRows: TableRows_1,
            }))),
            Title: unwrap(Title_1),
        })), createElement(TableContainer, (Title_2 = t("general.consumable"), {
            Content: createElement(Table, (TableRows_2 = toArray(consumableElements), (Options_2 = {
                BorderStyle: "full",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["name", "asc"],
                GlobalSearch: false,
                RowSelection: "disabled",
            }, {
                ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (row_12) => {
                    const matchValue_10 = getElement(row_12);
                    return (matchValue_10 != null) ? (new CellContent(0, [matchValue_10.DisplayName])) : (new CellContent(0, [""]));
                }, "text", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_13) => {
                    switch (row_13.tag) {
                        case 1:
                            return new CellContent(0, ["1"]);
                        case 2: {
                            const element_21 = row_13.fields[0];
                            return new CellContent(1, [createElement(NumberInput, {
                                ComponentState: formState,
                                OnChange: (value_6) => {
                                    props.Dispatch(new Msg(2, [element_21.Id, value_6]));
                                },
                                TestId: "",
                                Value: element_21.Quantity,
                            })]);
                        }
                        default: {
                            const element_20 = row_13.fields[0];
                            return new CellContent(1, [createElement(NumberInput, {
                                ComponentState: formState,
                                OnChange: (value_4) => {
                                    props.Dispatch(new Msg(2, [element_20.Id, value_4]));
                                },
                                TestId: "",
                                Value: element_20.Quantity,
                            })]);
                        }
                    }
                }, "none", "text"), new ColumnDefinition$1("unit", "", t("general.unit"), (row_14) => {
                    const matchValue_11 = getElement(row_14);
                    if (matchValue_11 != null) {
                        const matchValue_12 = matchValue_11.Unit;
                        return (matchValue_12 == null) ? (new CellContent(0, [t("unit.piece")])) : (new CellContent(0, [Helper_unitToHumanString(matchValue_12)]));
                    }
                    else {
                        return new CellContent(0, [""]);
                    }
                }, "none", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_15) => {
                    const matchValue_13 = getElement(row_15);
                    return (matchValue_13 != null) ? (new CellContent(0, [matchValue_13.InventoryNumber])) : (new CellContent(0, [""]));
                }, "text", "text"), new ColumnDefinition$1("additionalName", "", t("consumable.additional_name"), (row_16) => {
                    const matchValue_14 = getElement(row_16);
                    return (matchValue_14 != null) ? (new CellContent(0, [matchValue_14.AdditionalName])) : (new CellContent(0, [""]));
                }, "text", "text"), new ColumnDefinition$1("action", "", t("general.action"), (row_17) => (new CellContent(2, [t("general.remove"), () => {
                    props.Dispatch(new Msg(4, [row_17]));
                }])), "none", "button")],
                Controls: singleton(new TableControl$1(t("dispolist.add"), Shipment_Add(), (_arg_11) => {
                    setIsAddConsumableModalOpen(true);
                }, false, false, "")),
                Options: Options_2,
                TableRows: TableRows_2,
            }))),
            Title: unwrap(Title_2),
        }))]),
        Header: "",
        OnBack: () => {
            RouterModule_nav(singleton(RouterModule_encodeParts(singleton("inventory-lists"), 1)), 1, 1);
        },
        OnSave: () => {
            props.Dispatch(new Msg(6, []));
        },
    });
}

export function NewInventoryListView(props) {
    let patternInput;
    const init = init_1(props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const matchValue = state_1.Elements;
    const matchValue_1 = state_1.Dispolist;
    let matchResult, dispolist, elements;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        dispolist = matchValue_1.fields[0];
        elements = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(Form, {
                Dispatch: patternInput[1],
                Dispolist: dispolist,
                Elements: elements,
                IsLoading: state_1.IsLoading,
                IsValidated: state_1.IsValidated,
                Message: unwrap(state_1.Message),
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

export function EditInventoryListView(props) {
    let patternInput;
    const init = initEdit(props.DispolistId, props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.DispolistId]);
    const state_1 = patternInput[0];
    const matchValue = state_1.Elements;
    const matchValue_1 = state_1.Dispolist;
    let matchResult, dispolist, elements;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        dispolist = matchValue_1.fields[0];
        elements = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(Form, {
                Dispatch: patternInput[1],
                Dispolist: dispolist,
                Elements: elements,
                IsLoading: state_1.IsLoading,
                IsValidated: state_1.IsValidated,
                Message: unwrap(state_1.Message),
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

