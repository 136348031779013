import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ConfirmationDialog, Dialog } from "../../Components/Dialog.js";
import { Input } from "../../Components/Input.js";
import { tryHead, toArray, empty, ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { TextButton } from "../../Components/Button.js";
import { AssignQRCodeDialog } from "../Dialogs/QRCodeDialog.js";
import { TableControl$1, ColumnDefinition$1, CellContent, Table } from "./Table.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import Qr_Code from "../../../public/assets/icons/Qr_Code.svg";
import Recycle_Bin_1 from "../../../public/assets/icons/Recycle_Bin_1.svg";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";

export function AssignQrCodeByInputDialog(props) {
    let Title;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState("");
    const setQrCodeInput = patternInput_1[1];
    const qrCodeInput = patternInput_1[0];
    const dependencies = [props.IsOpen];
    reactApi.useEffect(() => {
        setQrCodeInput("");
    }, dependencies);
    return createElement(Dialog, (Title = t("tool.assign_qr_code"), {
        Body: singleton(createElement(Input, {
            ComponentState: "enabled",
            Label: t("general.qr_code"),
            OnChange: setQrCodeInput,
            TestId: "assign-qr-code-input",
            Value: qrCodeInput,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-reservation-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.save"),
            OnClick: () => {
                props.OnSubmit(qrCodeInput);
            },
            TestId: "save-reservation-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

export function QrCodeTable(props) {
    let Title, TableRows, Options, matchValue;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(empty());
    const setSelectedQrCodes = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsAssignQrCodeDialogOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(false);
    const setIsAssignByInputQrCodeDialogOpen = patternInput_3[1];
    const patternInput_4 = reactApi.useState(false);
    const setIsDeleteQrCodeConfirmationDialogOpen = patternInput_4[1];
    const xs_1 = [createElement(AssignQRCodeDialog, {
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsAssignQrCodeDialogOpen(false);
        },
        OnQRCodeRead: props.OnAssignQrCode,
    }), createElement(AssignQrCodeByInputDialog, {
        IsOpen: patternInput_3[0],
        OnClose: () => {
            setIsAssignByInputQrCodeDialogOpen(false);
        },
        OnSubmit: (qrCode) => {
            props.OnAssignQrCode(qrCode);
            setIsAssignByInputQrCodeDialogOpen(false);
        },
    }), createElement(ConfirmationDialog, (Title = t("tool.dialog_delete_qr_code_confirmation_title"), {
        Description: t("tool.dialog_delete_qr_code_confirmation_description"),
        IsOpen: patternInput_4[0],
        OnClose: () => {
            setIsDeleteQrCodeConfirmationDialogOpen(false);
            setSelectedQrCodes(empty());
        },
        OnConfirm: () => {
            props.OnDeleteQrCodes(patternInput_1[0]);
            setIsDeleteQrCodeConfirmationDialogOpen(false);
            setSelectedQrCodes(empty());
        },
        Title: Title,
    })), createElement(Table, (TableRows = toArray(props.QrCodes), (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["qrCodeId", "asc"],
        GlobalSearch: false,
        RowSelection: "multiRow",
    }, {
        ColumnDefinitions: [new ColumnDefinition$1("qrCodeId", "", t("general.qr_code"), (Item) => (new CellContent(0, [Item])), "none", "text")],
        Controls: (matchValue = props.UserData.Role, (matchValue === "system") ? toList(delay(() => append(singleton_1(new TableControl$1(t("tool.assign_qr_code_by_scan"), Qr_Code(), (_arg_4) => {
            setIsAssignQrCodeDialogOpen(true);
        }, false, false, "qr-code-table-scan-qrcode")), delay(() => append(singleton_1(new TableControl$1(t("tool.assign_qr_code_by_input"), Qr_Code(), (_arg_5) => {
            setIsAssignByInputQrCodeDialogOpen(true);
        }, false, false, "qr-code-table-enter-qrcode")), delay(() => append(singleton_1(new TableControl$1(t("tool.delete_qr_codes"), Recycle_Bin_1(), (codes) => {
            setSelectedQrCodes(codes);
            setIsDeleteQrCodeConfirmationDialogOpen(true);
        }, true, false, "")), delay(() => {
            const matchValue_1 = props.UserData.Role;
            switch (matchValue_1) {
                case "toolManager":
                case "user": {
                    return empty_1();
                }
                default:
                    return singleton_1(new TableControl$1(t("general.order"), Qr_Code(), (_arg_6) => {
                        RouterModule_nav(ofArray(["shop"]), 1, 1);
                    }, false, false, ""));
            }
        })))))))) : ((matchValue === "administrator") ? toList(delay(() => append(singleton_1(new TableControl$1(t("tool.assign_qr_code_by_scan"), Qr_Code(), (_arg_4) => {
            setIsAssignQrCodeDialogOpen(true);
        }, false, false, "qr-code-table-scan-qrcode")), delay(() => append(singleton_1(new TableControl$1(t("tool.assign_qr_code_by_input"), Qr_Code(), (_arg_5) => {
            setIsAssignByInputQrCodeDialogOpen(true);
        }, false, false, "qr-code-table-enter-qrcode")), delay(() => append(singleton_1(new TableControl$1(t("tool.delete_qr_codes"), Recycle_Bin_1(), (codes) => {
            setSelectedQrCodes(codes);
            setIsDeleteQrCodeConfirmationDialogOpen(true);
        }, true, false, "")), delay(() => {
            const matchValue_1 = props.UserData.Role;
            switch (matchValue_1) {
                case "toolManager":
                case "user": {
                    return empty_1();
                }
                default:
                    return singleton_1(new TableControl$1(t("general.order"), Qr_Code(), (_arg_6) => {
                        RouterModule_nav(ofArray(["shop"]), 1, 1);
                    }, false, false, ""));
            }
        })))))))) : ((matchValue === "toolManager") ? toList(delay(() => append(singleton_1(new TableControl$1(t("tool.assign_qr_code_by_scan"), Qr_Code(), (_arg_4) => {
            setIsAssignQrCodeDialogOpen(true);
        }, false, false, "qr-code-table-scan-qrcode")), delay(() => append(singleton_1(new TableControl$1(t("tool.assign_qr_code_by_input"), Qr_Code(), (_arg_5) => {
            setIsAssignByInputQrCodeDialogOpen(true);
        }, false, false, "qr-code-table-enter-qrcode")), delay(() => append(singleton_1(new TableControl$1(t("tool.delete_qr_codes"), Recycle_Bin_1(), (codes) => {
            setSelectedQrCodes(codes);
            setIsDeleteQrCodeConfirmationDialogOpen(true);
        }, true, false, "")), delay(() => {
            const matchValue_1 = props.UserData.Role;
            switch (matchValue_1) {
                case "toolManager":
                case "user": {
                    return empty_1();
                }
                default:
                    return singleton_1(new TableControl$1(t("general.order"), Qr_Code(), (_arg_6) => {
                        RouterModule_nav(ofArray(["shop"]), 1, 1);
                    }, false, false, ""));
            }
        })))))))) : empty()))),
        Options: Options,
        TableRows: TableRows,
    })))];
    return react.createElement(react.Fragment, {}, ...xs_1);
}

export function QrCodeTableWithStorage(props) {
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(undefined);
    const setSelectedQrCodes = patternInput_1[1];
    const selectedQrCodes = patternInput_1[0];
    const patternInput_2 = reactApi.useState(undefined);
    const setIsAssignQrCodeDialogOpen = patternInput_2[1];
    const isAssignQrCodeDialogOpen = patternInput_2[0];
    const patternInput_3 = reactApi.useState(undefined);
    const setIsAssignByInputQrCodeDialogOpen = patternInput_3[1];
    const isAssingByInputQrCodeDialogOpen = patternInput_3[0];
    const patternInput_4 = reactApi.useState(undefined);
    const setIsDeleteQrCodeConfirmationDialogOpen = patternInput_4[1];
    const isDeleteQrCodeConfirmationDialogOpen = patternInput_4[0];
    const xs_1 = toList(delay(() => {
        let matchValue, storageId;
        return append((matchValue = isAssignQrCodeDialogOpen, (matchValue == null) ? (empty_1()) : ((storageId = matchValue, singleton_1(createElement(AssignQRCodeDialog, {
            IsOpen: isAssignQrCodeDialogOpen != null,
            OnClose: () => {
                setIsAssignQrCodeDialogOpen(undefined);
            },
            OnQRCodeRead: (qrCodeId) => {
                props.OnAssignQrCode([qrCodeId, storageId]);
            },
        }))))), delay(() => {
            let matchValue_1, storageId_1;
            return append((matchValue_1 = isAssingByInputQrCodeDialogOpen, (matchValue_1 == null) ? (empty_1()) : ((storageId_1 = matchValue_1, singleton_1(createElement(AssignQrCodeByInputDialog, {
                IsOpen: isAssingByInputQrCodeDialogOpen != null,
                OnClose: () => {
                    setIsAssignByInputQrCodeDialogOpen(undefined);
                },
                OnSubmit: (qrCode) => {
                    props.OnAssignQrCode([qrCode, storageId_1]);
                    setIsAssignByInputQrCodeDialogOpen(undefined);
                },
            }))))), delay(() => {
                let qrCodeId_1, storageId_2, Title;
                return append((isDeleteQrCodeConfirmationDialogOpen == null) ? ((selectedQrCodes != null) ? (empty_1()) : (empty_1())) : ((selectedQrCodes == null) ? (empty_1()) : ((qrCodeId_1 = selectedQrCodes, (storageId_2 = isDeleteQrCodeConfirmationDialogOpen, singleton_1(createElement(ConfirmationDialog, (Title = t("tool.dialog_delete_qr_code_confirmation_title"), {
                    Description: t("tool.dialog_delete_qr_code_confirmation_description"),
                    IsOpen: isDeleteQrCodeConfirmationDialogOpen != null,
                    OnClose: () => {
                        setIsDeleteQrCodeConfirmationDialogOpen(undefined);
                        setSelectedQrCodes(undefined);
                    },
                    OnConfirm: () => {
                        props.OnDeleteQrCodes([qrCodeId_1, storageId_2]);
                        setIsDeleteQrCodeConfirmationDialogOpen(undefined);
                        setSelectedQrCodes(undefined);
                    },
                    Title: Title,
                }))))))), delay(() => {
                    let TableRows, Options, matchValue_3;
                    return singleton_1(createElement(Table, (TableRows = toArray(props.Data), (Options = {
                        BorderStyle: "row",
                        CSVDownload: false,
                        ConfigurableColumns: false,
                        DefaultSortColumn: ["storageName", "asc"],
                        GlobalSearch: false,
                        RowSelection: "singleRow",
                    }, {
                        ColumnDefinitions: [new ColumnDefinition$1("storageName", "", t("general.storage"), (row) => (new CellContent(0, [row.StorageName])), "none", "text"), new ColumnDefinition$1("storageSpace", "", t("tool.storage_space"), (row_1) => (new CellContent(0, [row_1.StorageSpace])), "none", "text"), new ColumnDefinition$1("qrCodeId", "", t("general.qr_code"), (row_2) => (new CellContent(0, [row_2.QrCode])), "none", "text")],
                        Controls: (matchValue_3 = props.UserData.Role, (matchValue_3 === "system") ? toList(delay(() => append(singleton_1(new TableControl$1(t("tool.assign_qr_code_by_scan"), Qr_Code(), (row_3) => {
                            const matchValue_4 = tryHead(row_3);
                            if (matchValue_4 == null) {
                            }
                            else {
                                setIsAssignQrCodeDialogOpen(matchValue_4.StorageId);
                            }
                        }, true, false, "")), delay(() => append(singleton_1(new TableControl$1(t("tool.assign_qr_code_by_input"), Qr_Code(), (row_5) => {
                            const matchValue_5 = tryHead(row_5);
                            if (matchValue_5 == null) {
                            }
                            else {
                                setIsAssignByInputQrCodeDialogOpen(matchValue_5.StorageId);
                            }
                        }, true, false, "")), delay(() => append(singleton_1(new TableControl$1(t("tool.delete_qr_codes"), Recycle_Bin_1(), (row_7) => {
                            const matchValue_6 = tryHead(row_7);
                            if (matchValue_6 == null) {
                            }
                            else {
                                const row_8 = matchValue_6;
                                setSelectedQrCodes(row_8.QrCode);
                                setIsDeleteQrCodeConfirmationDialogOpen(row_8.StorageId);
                            }
                        }, true, false, "")), delay(() => {
                            const matchValue_7 = props.UserData.Role;
                            switch (matchValue_7) {
                                case "toolManager":
                                case "user": {
                                    return empty_1();
                                }
                                default:
                                    return singleton_1(new TableControl$1(t("general.order"), Qr_Code(), (_arg_4) => {
                                        RouterModule_nav(ofArray(["shop"]), 1, 1);
                                    }, false, false, ""));
                            }
                        })))))))) : ((matchValue_3 === "administrator") ? toList(delay(() => append(singleton_1(new TableControl$1(t("tool.assign_qr_code_by_scan"), Qr_Code(), (row_3) => {
                            const matchValue_4 = tryHead(row_3);
                            if (matchValue_4 == null) {
                            }
                            else {
                                setIsAssignQrCodeDialogOpen(matchValue_4.StorageId);
                            }
                        }, true, false, "")), delay(() => append(singleton_1(new TableControl$1(t("tool.assign_qr_code_by_input"), Qr_Code(), (row_5) => {
                            const matchValue_5 = tryHead(row_5);
                            if (matchValue_5 == null) {
                            }
                            else {
                                setIsAssignByInputQrCodeDialogOpen(matchValue_5.StorageId);
                            }
                        }, true, false, "")), delay(() => append(singleton_1(new TableControl$1(t("tool.delete_qr_codes"), Recycle_Bin_1(), (row_7) => {
                            const matchValue_6 = tryHead(row_7);
                            if (matchValue_6 == null) {
                            }
                            else {
                                const row_8 = matchValue_6;
                                setSelectedQrCodes(row_8.QrCode);
                                setIsDeleteQrCodeConfirmationDialogOpen(row_8.StorageId);
                            }
                        }, true, false, "")), delay(() => {
                            const matchValue_7 = props.UserData.Role;
                            switch (matchValue_7) {
                                case "toolManager":
                                case "user": {
                                    return empty_1();
                                }
                                default:
                                    return singleton_1(new TableControl$1(t("general.order"), Qr_Code(), (_arg_4) => {
                                        RouterModule_nav(ofArray(["shop"]), 1, 1);
                                    }, false, false, ""));
                            }
                        })))))))) : ((matchValue_3 === "toolManager") ? toList(delay(() => append(singleton_1(new TableControl$1(t("tool.assign_qr_code_by_scan"), Qr_Code(), (row_3) => {
                            const matchValue_4 = tryHead(row_3);
                            if (matchValue_4 == null) {
                            }
                            else {
                                setIsAssignQrCodeDialogOpen(matchValue_4.StorageId);
                            }
                        }, true, false, "")), delay(() => append(singleton_1(new TableControl$1(t("tool.assign_qr_code_by_input"), Qr_Code(), (row_5) => {
                            const matchValue_5 = tryHead(row_5);
                            if (matchValue_5 == null) {
                            }
                            else {
                                setIsAssignByInputQrCodeDialogOpen(matchValue_5.StorageId);
                            }
                        }, true, false, "")), delay(() => append(singleton_1(new TableControl$1(t("tool.delete_qr_codes"), Recycle_Bin_1(), (row_7) => {
                            const matchValue_6 = tryHead(row_7);
                            if (matchValue_6 == null) {
                            }
                            else {
                                const row_8 = matchValue_6;
                                setSelectedQrCodes(row_8.QrCode);
                                setIsDeleteQrCodeConfirmationDialogOpen(row_8.StorageId);
                            }
                        }, true, false, "")), delay(() => {
                            const matchValue_7 = props.UserData.Role;
                            switch (matchValue_7) {
                                case "toolManager":
                                case "user": {
                                    return empty_1();
                                }
                                default:
                                    return singleton_1(new TableControl$1(t("general.order"), Qr_Code(), (_arg_4) => {
                                        RouterModule_nav(ofArray(["shop"]), 1, 1);
                                    }, false, false, ""));
                            }
                        })))))))) : empty()))),
                        Options: Options,
                        TableRows: TableRows,
                    }))));
                }));
            }));
        }));
    }));
    return react.createElement(react.Fragment, {}, ...xs_1);
}

