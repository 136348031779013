import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, record_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class FileUploaded extends Record {
    constructor(ImageUrl) {
        super();
        this.ImageUrl = ImageUrl;
    }
}

export function FileUploaded_$reflection() {
    return record_type("Shared.File.FileUploaded", [], FileUploaded, () => [["ImageUrl", string_type]]);
}

export class FileUploadResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FileTooLarge", "Successful", "Failed"];
    }
}

export function FileUploadResponse_$reflection() {
    return union_type("Shared.File.FileUploadResponse", [], FileUploadResponse, () => [[], [["Item", FileUploaded_$reflection()]], []]);
}

