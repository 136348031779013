import { iterate } from "../fable-library-js.4.19.2/Seq.js";
import { disposeSafe } from "../fable-library-js.4.19.2/Util.js";
import { toArray } from "../fable-library-js.4.19.2/Option.js";

export function optDispose(disposeOption) {
    return {
        Dispose() {
            iterate((d) => {
                let copyOfStruct = d;
                disposeSafe(copyOfStruct);
            }, toArray(disposeOption));
        },
    };
}

