import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { singleton as singleton_1, tryFind, ofArray, contains, filter, map } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createObj, safeHash, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Select, MultiSelect } from "../../Components/Select.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { toNoOptionsMessage, CommonProps$1, OptionType$1 } from "../../SharedComponents/ReactSelect.js";
import { tryFind as tryFind_1 } from "../../fable_modules/fable-library-js.4.19.2/Array.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { RadioButton } from "../../Components/RadioButton.js";
import { Msg } from "./Types.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { AutoSuggestInput, Input } from "../../Components/Input.js";
import { FormKind as FormKind_1, FormState__getValidation } from "../../Shared/Shared.js";
import { FormContainer } from "../../Components/FormContainer.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { AllowUserToSubcontractorTransfer } from "../../Shared/User.js";
import { TooltipProps, Tooltip } from "../../SharedComponents/ReactTooltip.js";
import { EmailNotification } from "../../Widgets/EmailNotification.js";
import { NewReceiverCustomProperties } from "../../Widgets/CustomProperties.js";
import { PropertyConfigurationVisibility } from "../../Shared/PropertyConfiguration.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { initEdit, update as update_1, initNew } from "./State.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

function StorageSelect(props) {
    const patternInput = useTranslation();
    const options = map((d) => ({
        label: d.Name,
        value: d,
    }), filter((s) => !contains(s.Id, map((selectedStorage) => selectedStorage.value.Id, props.SelectedStorages), {
        Equals: equals,
        GetHashCode: safeHash,
    }), props.Storages));
    return createElement(MultiSelect, {
        ComponentState: props.ComponentState,
        IsClearable: false,
        Label: patternInput[0](props.LabelTranslationKey),
        NoOptionsMessage: "user.select_storage_no_options",
        OnChange: props.OnChange,
        Options: options,
        PlaceholderKey: "select.employee_placeholder",
        TestId: "",
        Value: props.SelectedStorages,
    });
}

function RoleSelect(props) {
    const t = useTranslation()[0];
    const options = ofArray([{
        label: t("role.admin"),
        value: "administrator",
    }, {
        label: t("role.tool_manager"),
        value: "toolManager",
    }, {
        label: t("role.employee"),
        value: "user",
    }]);
    const selectedElement = tryFind((o) => (o.value === props.User.Role), options);
    return createElement(Select, {
        ComponentState: props.ComponentState,
        IsClearable: false,
        Label: t("general.role"),
        NoOptionsMessage: "user.role_select_no_options_message",
        OnChange: props.OnChange,
        Options: options,
        PlaceholderKey: "select.employee_placeholder",
        TestId: "",
        Value: unwrap(selectedElement),
    });
}

function roleProps(user, isDisabled, selectRole) {
    const options = [new OptionType$1("role.admin", "administrator"), new OptionType$1("role.tool_manager", "toolManager"), new OptionType$1("role.employee", "user")];
    return [new CommonProps$1(1, [options]), new CommonProps$1(2, [selectRole]), new CommonProps$1(5, [true]), new CommonProps$1(9, [false]), new CommonProps$1(6, ["user.role_select_placeholder"]), new CommonProps$1(10, [(_arg) => toNoOptionsMessage("user.role_select_no_options_message", _arg)]), new CommonProps$1(11, ["mechanic-select flex-grow-1"]), new CommonProps$1(7, [isDisabled]), new CommonProps$1(4, [tryFind_1((o) => (o.value === user.Role), options)])];
}

export function LoginInformation(props) {
    const t = useTranslation()[0];
    const user = props.User;
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    const xs_1 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(singleton_1((elems = [RadioButton({
            ComponentState: componentState,
            Label: t("user.login_with_email"),
            OnSelect: (_arg) => {
                props.Dispatch(new Msg(11, [false]));
            },
            Selected: !(user.LoginInformation.tag === 1),
        }), RadioButton({
            ComponentState: componentState,
            Label: t("user.login_with_phone_number"),
            OnSelect: (_arg_1) => {
                props.Dispatch(new Msg(11, [true]));
            },
            Selected: !(user.LoginInformation.tag === 0),
        })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), delay(() => {
            const matchValue_2 = user.LoginInformation;
            return (matchValue_2.tag === 0) ? singleton(createElement(Input, {
                ComponentState: componentState,
                Label: t("general.email"),
                OnChange: (arg_1) => {
                    props.Dispatch(new Msg(2, [arg_1]));
                },
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "email")),
                Value: matchValue_2.fields[0],
            })) : singleton(createElement(Input, {
                ComponentState: componentState,
                Label: t("user.phone_number"),
                OnChange: (arg) => {
                    props.Dispatch(new Msg(3, [arg]));
                },
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "phoneNumber")),
                Value: matchValue_2.fields[0],
            }));
        }));
    }));
    return react.createElement(react.Fragment, {}, ...xs_1);
}

export function UserForm(props) {
    const t = useTranslation()[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(FormContainer, {
        Children: toList(delay(() => {
            let matchValue;
            return append((matchValue = FormState__getValidation(props.FormState, t, "global"), (matchValue == null) ? singleton(defaultOf()) : singleton(createElement(ErrorAlert, {
                Label: matchValue,
            }))), delay(() => append(equals(props.FormKind, new FormKind_1(0, [])) ? singleton(createElement(LoginInformation, {
                Dispatch: props.Dispatch,
                FormState: props.FormState,
                User: props.User,
            })) : empty(), delay(() => append(singleton(createElement(Input, {
                ComponentState: componentState,
                Label: t("general.name"),
                OnChange: (arg) => {
                    props.Dispatch(new Msg(4, [arg]));
                },
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "name")),
                Value: props.User.Name,
            })), delay(() => append(singleton(createElement(Input, {
                ComponentState: componentState,
                Label: t("user.personnel_number"),
                OnChange: (arg_1) => {
                    props.Dispatch(new Msg(0, [arg_1]));
                },
                TestId: "",
                Value: props.User.PersonnelNumber,
            })), delay(() => {
                let dependencies_1;
                return append(singleton((dependencies_1 = [props.User.Department, props.DepartmentSuggestions, componentState], reactApi.useMemo(() => createElement(AutoSuggestInput, {
                    ComponentState: componentState,
                    Label: t("user.department"),
                    OnChange: (arg_2) => {
                        props.Dispatch(new Msg(1, [arg_2]));
                    },
                    Suggestions: props.DepartmentSuggestions,
                    TestId: "",
                    Value: props.User.Department,
                }), dependencies_1))), delay(() => append(singleton(createElement(RoleSelect, {
                    ComponentState: componentState,
                    OnChange: (arg_3) => {
                        props.Dispatch(new Msg(5, [arg_3]));
                    },
                    User: props.User,
                })), delay(() => {
                    let matchValue_1;
                    return append((matchValue_1 = props.User.Role, (matchValue_1 === "toolManager") ? append(singleton(createElement(StorageSelect, {
                        ComponentState: componentState,
                        LabelTranslationKey: "general.storage",
                        OnChange: (value_2) => {
                            if (value_2 == null) {
                            }
                            else {
                                props.Dispatch(new Msg(9, [ofArray(value_2)]));
                            }
                        },
                        SelectedStorages: props.SelectedAssignedStorageIds,
                        Storages: props.Storages,
                    })), delay(() => singleton(createElement(StorageSelect, {
                        ComponentState: componentState,
                        LabelTranslationKey: "general.self_service_storage",
                        OnChange: (value_4) => {
                            if (value_4 == null) {
                            }
                            else {
                                props.Dispatch(new Msg(10, [ofArray(value_4)]));
                            }
                        },
                        SelectedStorages: props.SelectedSelfServiceStorageIds,
                        Storages: props.Storages,
                    })))) : ((matchValue_1 === "user") ? (props.CustomerConfiguration.GlobalSelfServiceStorageActive ? (empty()) : singleton(createElement(StorageSelect, {
                        ComponentState: componentState,
                        LabelTranslationKey: "general.self_service_storage",
                        OnChange: (value_6) => {
                            if (value_6 == null) {
                            }
                            else {
                                props.Dispatch(new Msg(10, [ofArray(value_6)]));
                            }
                        },
                        SelectedStorages: props.SelectedSelfServiceStorageIds,
                        Storages: props.Storages,
                    }))) : ((matchValue_1 === "system") ? singleton(defaultOf()) : singleton(createElement(StorageSelect, {
                        ComponentState: componentState,
                        LabelTranslationKey: "general.storage",
                        OnChange: (value) => {
                            if (value == null) {
                            }
                            else {
                                props.Dispatch(new Msg(9, [ofArray(value)]));
                            }
                        },
                        SelectedStorages: props.SelectedAssignedStorageIds,
                        Storages: props.Storages,
                    }))))), delay(() => {
                        let matchValue_2, elems_1, elems;
                        return append((matchValue_2 = props.User.Role, (matchValue_2 === "toolManager") ? (empty()) : ((matchValue_2 === "system") ? (empty()) : ((matchValue_2 === "user") ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "pt-5"])], (elems_1 = [Checkbox({
                            ComponentState: componentState,
                            IsChecked: equals(props.User.AllowUserToSubcontractorTransfer, new AllowUserToSubcontractorTransfer(0, [])),
                            Label: t("user.user_to_subcontractor"),
                            OnCheck: (arg_4) => {
                                props.Dispatch(new Msg(6, [arg_4]));
                            },
                            TestId: "",
                        }), createElement("div", createObj(ofArray([["className", join(" ", ["ml-2", "pt-5"])], (elems = [createElement(Tooltip, {
                            props: ofArray([new TooltipProps(4, [true]), new TooltipProps(0, ["user.user_to_subcontractor_tool_tip"])]),
                        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : (empty())))), delay(() => append(singleton(createElement(EmailNotification, {
                            FormState: componentState,
                            OnChangeEmailNotification: props.OnChangeEmailNotification,
                            User: props.User,
                        })), delay(() => (props.IsCustomPropertiesEnabled ? singleton(createElement(NewReceiverCustomProperties, {
                            CustomProperties: props.User.CustomProperties,
                            FormState: props.FormState,
                            OnSetCustomProperty: (arg_5) => {
                                props.Dispatch(new Msg(7, [arg_5]));
                            },
                            PropertyConfigurationType: new PropertyConfigurationVisibility(2, []),
                            PropertyConfigurations: props.PropertyConfigurations,
                        })) : empty())))));
                    }));
                }))));
            }))))))));
        })),
        Header: props.Header,
        OnBack: props.OnBack,
        OnSave: props.OnSave,
    });
}

export function NewUserView(props) {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(initNew, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    const matchValue_1 = state_1.CustomerConfiguration;
    const matchValue_2 = state_1.IsCustomPropertiesEnabled;
    const matchValue_3 = state_1.PropertyConfigurations;
    const matchValue_4 = state_1.DepartmentSuggestions;
    const matchValue_5 = state_1.Storages;
    let matchResult, configuration, departmentSuggestions, isCustomPropertiesEnabled, propertyConfigurations, storages, user;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_5.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        configuration = matchValue_1.fields[0];
        departmentSuggestions = matchValue_4.fields[0];
        isCustomPropertiesEnabled = matchValue_2.fields[0];
        propertyConfigurations = matchValue_3.fields[0];
        storages = matchValue_5.fields[0];
        user = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(UserForm, {
                CustomerConfiguration: configuration,
                DepartmentSuggestions: departmentSuggestions,
                Dispatch: dispatch,
                FormKind: new FormKind_1(0, []),
                FormState: state_1.FormState,
                Header: patternInput_1[0]("user.create_new_user"),
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled,
                OnBack: () => {
                    RouterModule_nav(ofArray(props.OnBackPath), 1, 1);
                },
                OnChangeEmailNotification: (arg) => {
                    dispatch(new Msg(8, [arg]));
                },
                OnSave: () => {
                    dispatch(new Msg(12, []));
                },
                PropertyConfigurations: propertyConfigurations,
                SelectedAssignedStorageIds: user.SelectedAssignedStorages,
                SelectedSelfServiceStorageIds: user.SelectedSelfServiceStorages,
                Storages: storages,
                User: user.User,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

export function EditUserView(props) {
    let patternInput;
    const init = initEdit(props.UserId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.UserId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    const matchValue_1 = state_1.CustomerConfiguration;
    const matchValue_2 = state_1.IsCustomPropertiesEnabled;
    const matchValue_3 = state_1.PropertyConfigurations;
    const matchValue_4 = state_1.DepartmentSuggestions;
    const matchValue_5 = state_1.Storages;
    let matchResult, configuration, departmentSuggestions, isCustomPropertiesEnabled, propertyConfigurations, storages, user;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_5.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        configuration = matchValue_1.fields[0];
        departmentSuggestions = matchValue_4.fields[0];
        isCustomPropertiesEnabled = matchValue_2.fields[0];
        propertyConfigurations = matchValue_3.fields[0];
        storages = matchValue_5.fields[0];
        user = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(UserForm, {
                CustomerConfiguration: configuration,
                DepartmentSuggestions: departmentSuggestions,
                Dispatch: dispatch,
                FormKind: new FormKind_1(1, []),
                FormState: state_1.FormState,
                Header: patternInput_1[0]("user.edit_user"),
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled,
                OnBack: () => {
                    RouterModule_nav(ofArray(props.OnBackPath), 1, 1);
                },
                OnChangeEmailNotification: (arg) => {
                    dispatch(new Msg(8, [arg]));
                },
                OnSave: () => {
                    dispatch(new Msg(13, []));
                },
                PropertyConfigurations: propertyConfigurations,
                SelectedAssignedStorageIds: user.SelectedAssignedStorages,
                SelectedSelfServiceStorageIds: user.SelectedSelfServiceStorages,
                Storages: storages,
                User: user.User,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

