import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { SetId, ConsumableReservationId, ConsumableId, ToolReservationId, ToolId, ClientApiResponse$1_$reflection, PostResponse$1_$reflection, EmptyResponse_$reflection } from "../../Shared/Shared.js";
import { record_type, lambda_type, unit_type, bool_type, union_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { ReservationDto_$reflection } from "../../Shared/Planning.js";
import { Cmd_successToast } from "../../Components/Toast.js";
import { deleteToolWithQuantityReservation } from "../../Requests/ToolWithQuantity.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { deleteConsumableReservation } from "../../Requests/Consumable.js";
import { deleteReservationCmd } from "../../Requests/Set.js";
import { deleteReservationCmd as deleteReservationCmd_1 } from "../../Requests/Reservation.js";
import { defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ConfirmationDialog } from "../../Components/Dialog.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ToolReservationDeleted", "SetReservationDeleted", "GroupReservationDeleted", "ConsumableReservationDeleted", "DeleteReservation", "Success", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Widgets.Dialogs.DeleteReservationDialog.Msg", [], Msg, () => [[["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [], [], [["Item", class_type("System.Exception")]]]);
}

function deleteToolReservationCmd(toolId, reservationId) {
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_1), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), `/api/tools/${toolId.fields[0]}/reservations/${reservationId.fields[0]}`, (Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(6, [Item_1])));
}

export class State extends Record {
    constructor(Loading, SuccessCallback, OnClose, Reservation) {
        super();
        this.Loading = Loading;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Reservation = Reservation;
    }
}

export function State_$reflection() {
    return record_type("Widgets.Dialogs.DeleteReservationDialog.State", [], State, () => [["Loading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Reservation", ReservationDto_$reflection()]]);
}

function init(props) {
    return [new State(false, props.SuccessCallback, props.OnClose, props.Reservation), Cmd_none()];
}

function update(msg, state) {
    let matchValue;
    switch (msg.tag) {
        case 1:
            return [state, singleton((dispatch) => {
                dispatch(new Msg(5, []));
            })];
        case 2:
            return [state, singleton((dispatch_1) => {
                dispatch_1(new Msg(5, []));
            })];
        case 0:
            return [state, singleton((dispatch_2) => {
                dispatch_2(new Msg(5, []));
            })];
        case 3:
            return [state, singleton((dispatch_3) => {
                dispatch_3(new Msg(5, []));
            })];
        case 5:
            return [new State(false, state.SuccessCallback, state.OnClose, state.Reservation), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_remove_reservation_success")]))];
        case 6:
            return [new State(false, state.SuccessCallback, state.OnClose, state.Reservation), Cmd_none()];
        default:
            return [new State(true, state.SuccessCallback, state.OnClose, state.Reservation), (matchValue = state.Reservation.ToolInformation.Type, (matchValue.tag === 1) ? deleteToolWithQuantityReservation(new ToolId(parse(state.Reservation.ToolInformation.Id)), new ToolReservationId(parse(state.Reservation.Id)), (Item_3) => (new Msg(0, [Item_3])), (Item_4) => (new Msg(6, [Item_4]))) : ((matchValue.tag === 2) ? deleteConsumableReservation(new ConsumableId(parse(state.Reservation.ToolInformation.Id)), new ConsumableReservationId(parse(state.Reservation.Id)), (Item_6) => (new Msg(3, [Item_6])), (Item_7) => (new Msg(6, [Item_7]))) : ((matchValue.tag === 3) ? deleteReservationCmd(new SetId(parse(state.Reservation.ToolInformation.Id)), state.Reservation.Id, (Item_9) => (new Msg(1, [Item_9])), (Item_10) => (new Msg(6, [Item_10]))) : ((matchValue.tag === 4) ? deleteReservationCmd_1(defaultArg(state.Reservation.GroupId, state.Reservation.Id), (Item_11) => (new Msg(2, [Item_11])), (Item_12) => (new Msg(6, [Item_12]))) : deleteToolReservationCmd(new ToolId(parse(state.Reservation.ToolInformation.Id)), new ToolReservationId(parse(state.Reservation.Id)))))))];
    }
}

export function DeleteReservationDialog(props) {
    let Title;
    const matchValue = props.Reservation;
    if (matchValue != null) {
        const reservation = matchValue;
        const t = useTranslation()[0];
        let patternInput_1;
        const init_1 = init({
            OnClose: props.OnClose,
            Reservation: reservation,
            SuccessCallback: props.SuccessCallback,
        });
        patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
        }), undefined, [props.IsOpen]);
        return createElement(ConfirmationDialog, (Title = t("general.delete"), {
            Description: t("tool.dialog_remove_reservation_query"),
            IsOpen: props.IsOpen,
            OnClose: props.OnClose,
            OnConfirm: patternInput_1[0].Loading ? (() => {
            }) : (() => {
                patternInput_1[1](new Msg(4, []));
            }),
            Title: Title,
        }));
    }
    else {
        return defaultOf();
    }
}

