import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, class_type, record_type, list_type, bool_type, option_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Student_$reflection, Requests_StudentFormDto_$reflection } from "../../Shared/User.js";
import { ClientApiResponse$1_$reflection, FormState_$reflection, RequestedValue$1_$reflection } from "../../Shared/Shared.js";
import { PropertyConfigurationEnabledResponse_$reflection, CustomProperty_$reflection, PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";

export class State extends Record {
    constructor(StudentId, Form, IsCustomPropertiesEnabled, PropertyConfigurations, FormState) {
        super();
        this.StudentId = StudentId;
        this.Form = Form;
        this.IsCustomPropertiesEnabled = IsCustomPropertiesEnabled;
        this.PropertyConfigurations = PropertyConfigurations;
        this.FormState = FormState;
    }
}

export function State_$reflection() {
    return record_type("Receiver.Student.Types.State", [], State, () => [["StudentId", option_type(string_type)], ["Form", RequestedValue$1_$reflection(Requests_StudentFormDto_$reflection())], ["IsCustomPropertiesEnabled", RequestedValue$1_$reflection(bool_type)], ["PropertyConfigurations", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["FormState", FormState_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetName", "SetStudentNumber", "SetEmail", "SetCustomProperties", "CreateStudent", "UpdateStudent", "StudentFetched", "StudentSaved", "CustomPropertiesEnabledFetched", "PropertyConfigurationsFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Receiver.Student.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", list_type(CustomProperty_$reflection())]], [], [], [["Item", Student_$reflection()]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", PropertyConfigurationEnabledResponse_$reflection()]], [["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

