import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { AccountSettings_$reflection, BillingData_$reflection, ShoppingCartPositionId_$reflection } from "../Shared/Shared.js";
import { union_type, class_type, option_type, record_type, int32_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { PackageUnit_$reflection, QRCodeSize_$reflection, ShoppingCartPosition_$reflection, ShoppingCart_$reflection } from "../Shared/Shop.js";

export class ShoppingCartPositionRow extends Record {
    constructor(Id, Size, PackageUnit, Amount, PricePerPositionAsString, PricePerPosition) {
        super();
        this.Id = Id;
        this.Size = Size;
        this.PackageUnit = PackageUnit;
        this.Amount = (Amount | 0);
        this.PricePerPositionAsString = PricePerPositionAsString;
        this.PricePerPosition = (PricePerPosition | 0);
    }
}

export function ShoppingCartPositionRow_$reflection() {
    return record_type("Shop.Types.ShoppingCartPositionRow", [], ShoppingCartPositionRow, () => [["Id", ShoppingCartPositionId_$reflection()], ["Size", string_type], ["PackageUnit", string_type], ["Amount", int32_type], ["PricePerPositionAsString", string_type], ["PricePerPosition", int32_type]]);
}

export class State extends Record {
    constructor(ShoppingCart, CurrentShoppingCartPosition, BillingData) {
        super();
        this.ShoppingCart = ShoppingCart;
        this.CurrentShoppingCartPosition = CurrentShoppingCartPosition;
        this.BillingData = BillingData;
    }
}

export function State_$reflection() {
    return record_type("Shop.Types.State", [], State, () => [["ShoppingCart", ShoppingCart_$reflection()], ["CurrentShoppingCartPosition", ShoppingCartPosition_$reflection()], ["BillingData", option_type(BillingData_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AddPositionToOrderRow", "RemovePosition", "SetCompanyName", "SetStreet", "SetZipCode", "SetCity", "OrderConfirmed", "SetQRCodeSize", "SetPackageUnit", "SetAmount", "ResetShoppingCart", "CustomerDataFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Shop.Types.Msg", [], Msg, () => [[], [["Item", ShoppingCartPositionId_$reflection()]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", QRCodeSize_$reflection()]], [["Item", PackageUnit_$reflection()]], [["Item1", option_type(ShoppingCartPositionId_$reflection())], ["Item2", string_type]], [], [["Item", AccountSettings_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

