import { Record, Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { int32_type, option_type, record_type, string_type, union_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class CountryCode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DE", "AT", "CH"];
    }
}

export function CountryCode_$reflection() {
    return union_type("Shared.CustomerDataShared.CountryCode", [], CountryCode, () => [[], [], []]);
}

export class StripeAddress extends Record {
    constructor(CountryCode, Address, PostalCode, City) {
        super();
        this.CountryCode = CountryCode;
        this.Address = Address;
        this.PostalCode = PostalCode;
        this.City = City;
    }
}

export function StripeAddress_$reflection() {
    return record_type("Shared.CustomerDataShared.StripeAddress", [], StripeAddress, () => [["CountryCode", string_type], ["Address", string_type], ["PostalCode", string_type], ["City", string_type]]);
}

export class StripeCustomer extends Record {
    constructor(Id, Email, Name, Address, TaxId) {
        super();
        this.Id = Id;
        this.Email = Email;
        this.Name = Name;
        this.Address = Address;
        this.TaxId = TaxId;
    }
}

export function StripeCustomer_$reflection() {
    return record_type("Shared.CustomerDataShared.StripeCustomer", [], StripeCustomer, () => [["Id", string_type], ["Email", string_type], ["Name", string_type], ["Address", StripeAddress_$reflection()], ["TaxId", string_type]]);
}

export class CreateStripeCustomerDto extends Record {
    constructor(Email, CompanyName, CountryCode, Address, PostalCode, City, TaxId) {
        super();
        this.Email = Email;
        this.CompanyName = CompanyName;
        this.CountryCode = CountryCode;
        this.Address = Address;
        this.PostalCode = PostalCode;
        this.City = City;
        this.TaxId = TaxId;
    }
}

export function CreateStripeCustomerDto_$reflection() {
    return record_type("Shared.CustomerDataShared.CreateStripeCustomerDto", [], CreateStripeCustomerDto, () => [["Email", string_type], ["CompanyName", string_type], ["CountryCode", string_type], ["Address", string_type], ["PostalCode", string_type], ["City", string_type], ["TaxId", option_type(string_type)]]);
}

export class CreateStripeCustomerAndSubscriptionDto extends Record {
    constructor(StripeCustomer, StripePackage, PaymentMethod, AdditionalUsers) {
        super();
        this.StripeCustomer = StripeCustomer;
        this.StripePackage = StripePackage;
        this.PaymentMethod = PaymentMethod;
        this.AdditionalUsers = (AdditionalUsers | 0);
    }
}

export function CreateStripeCustomerAndSubscriptionDto_$reflection() {
    return record_type("Shared.CustomerDataShared.CreateStripeCustomerAndSubscriptionDto", [], CreateStripeCustomerAndSubscriptionDto, () => [["StripeCustomer", CreateStripeCustomerDto_$reflection()], ["StripePackage", string_type], ["PaymentMethod", string_type], ["AdditionalUsers", int32_type]]);
}

export class CreateCustomerAndSubscriptionResponse extends Record {
    constructor(CustomerId, SubscriptionId, ClientSecret) {
        super();
        this.CustomerId = CustomerId;
        this.SubscriptionId = SubscriptionId;
        this.ClientSecret = ClientSecret;
    }
}

export function CreateCustomerAndSubscriptionResponse_$reflection() {
    return record_type("Shared.CustomerDataShared.CreateCustomerAndSubscriptionResponse", [], CreateCustomerAndSubscriptionResponse, () => [["CustomerId", string_type], ["SubscriptionId", option_type(string_type)], ["ClientSecret", option_type(string_type)]]);
}

export class CreateStripeInvoicePaymentDto extends Record {
    constructor(CustomerId, PackageConfiguration, AdditionalUsers) {
        super();
        this.CustomerId = CustomerId;
        this.PackageConfiguration = PackageConfiguration;
        this.AdditionalUsers = (AdditionalUsers | 0);
    }
}

export function CreateStripeInvoicePaymentDto_$reflection() {
    return record_type("Shared.CustomerDataShared.CreateStripeInvoicePaymentDto", [], CreateStripeInvoicePaymentDto, () => [["CustomerId", string_type], ["PackageConfiguration", string_type], ["AdditionalUsers", int32_type]]);
}

