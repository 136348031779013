import { class_type } from "../fable-library-js.4.19.2/Reflection.js";
import { ReactElementTypeModule_memoWith } from "./Fable.React.Helpers.fs.js";
import * as react from "react";

export class FunctionComponentPreparedRenderFunctionCache {
    constructor() {
    }
}

export function FunctionComponentPreparedRenderFunctionCache_$reflection() {
    return class_type("Fable.React.FunctionComponentPreparedRenderFunctionCache", undefined, FunctionComponentPreparedRenderFunctionCache);
}

export function FunctionComponentPreparedRenderFunctionCache_$ctor() {
    return new FunctionComponentPreparedRenderFunctionCache();
}

(() => {
    FunctionComponentPreparedRenderFunctionCache.cache = (new Map());
})();

export function FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E(cacheKey, displayName, render, memoizeWith, withKey, __callingMemberName) {
    if (FunctionComponentPreparedRenderFunctionCache.cache.has(cacheKey)) {
        return FunctionComponentPreparedRenderFunctionCache.cache.get(cacheKey);
    }
    else {
        let v;
        render.displayName = displayName;
        let elemType;
        if (memoizeWith == null) {
            elemType = render;
        }
        else {
            const memoElement = ReactElementTypeModule_memoWith(memoizeWith, render);
            memoElement.displayName = (("Memo(" + displayName) + ")");
            elemType = memoElement;
        }
        v = ((props) => {
            let props_1;
            if (withKey == null) {
                props_1 = props;
            }
            else {
                const f_1 = withKey;
                props.key = f_1(props);
                props_1 = props;
            }
            return react.createElement(elemType, props_1);
        });
        FunctionComponentPreparedRenderFunctionCache.cache.set(cacheKey, v);
        return v;
    }
}

export class FunctionComponent {
    constructor() {
    }
}

export function FunctionComponent_$reflection() {
    return class_type("Fable.React.FunctionComponent", undefined, FunctionComponent);
}

