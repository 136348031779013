import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, record_type, option_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ClientApiResponse$1_$reflection, RequestedValue$1_$reflection, FormState_$reflection } from "../../Shared/Shared.js";
import { DataTransferObject_AssignEmailNotificationDetailDto_$reflection } from "../../Shared/Notification.js";
import { FileUploadResponse_$reflection } from "../../Shared/File.js";

export class State extends Record {
    constructor(NotificationId, FormState, AcceptEmailNotificationDetail, Signature, SignatureUrl) {
        super();
        this.NotificationId = NotificationId;
        this.FormState = FormState;
        this.AcceptEmailNotificationDetail = AcceptEmailNotificationDetail;
        this.Signature = Signature;
        this.SignatureUrl = SignatureUrl;
    }
}

export function State_$reflection() {
    return record_type("Notification.AcceptEmailNotification.Types.State", [], State, () => [["NotificationId", string_type], ["FormState", FormState_$reflection()], ["AcceptEmailNotificationDetail", RequestedValue$1_$reflection(DataTransferObject_AssignEmailNotificationDetailDto_$reflection())], ["Signature", option_type(class_type("Browser.Types.File", undefined))], ["SignatureUrl", option_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AcceptNotification", "RejectNotification", "AcceptNotificationResponse", "RejectNotificationResponse", "NotificationFetched", "SetSignature", "UploadSignature", "SignatureUploaded", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Notification.AcceptEmailNotification.Types.Msg", [], Msg, () => [[], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", DataTransferObject_AssignEmailNotificationDetailDto_$reflection()]], [["Item", class_type("Browser.Types.File", undefined)]], [], [["Item", FileUploadResponse_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

