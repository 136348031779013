import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { FormState__setValidationResponse_5219762A, FormState__startLoading, FormState_get_empty, ClientApiResponse$1_$reflection, FormState_$reflection, StocktakingListId_$reflection, StocktakingId_$reflection } from "../../Shared/Shared.js";
import { union_type, class_type, record_type, lambda_type, unit_type, list_type, string_type, option_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { singleton, contains, filter, ofArray, tryFind, map as map_1, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { map, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { stringHash, createObj, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Cmd_ofEffect, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { UpdateStocktakingListHelperDto_$reflection, UpdateStocktakingListHelperDto } from "../../Shared/Stocktaking.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../../CommunicationV2.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { StocktakingListId__get_unwrap, StocktakingId__get_unwrap } from "../../Shared/Shared.js";
import { Cmd_errorToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "../../Components/Select.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class State extends Record {
    constructor(StocktakingId, StocktakingListId, SelectedUsers, FormState, OnClose) {
        super();
        this.StocktakingId = StocktakingId;
        this.StocktakingListId = StocktakingListId;
        this.SelectedUsers = SelectedUsers;
        this.FormState = FormState;
        this.OnClose = OnClose;
    }
}

function State_$reflection() {
    return record_type("Stocktaking.Dialog.StocktakingListHelperDialog.State", [], State, () => [["StocktakingId", StocktakingId_$reflection()], ["StocktakingListId", option_type(StocktakingListId_$reflection())], ["SelectedUsers", list_type(string_type)], ["FormState", FormState_$reflection()], ["OnClose", lambda_type(unit_type, unit_type)]]);
}

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSelectedUsers", "SaveSelectedUsers", "SelectedUsersSaved", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Stocktaking.Dialog.StocktakingListHelperDialog.Msg", [], Msg, () => [[["Item", list_type(string_type)]], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

function init(stocktaking, selectedStocktakingList, onClose) {
    let stocktakingListId;
    return [new State(stocktaking.Id, selectedStocktakingList, (selectedStocktakingList == null) ? empty() : ((stocktakingListId = selectedStocktakingList, defaultArg(map((stocktakingList_1) => map_1((helper) => helper.Id, stocktakingList_1.Helper), tryFind((stocktakingList) => equals(stocktakingList.Id, stocktakingListId), stocktaking.StocktakingLists)), empty()))), FormState_get_empty(), onClose), Cmd_none()];
}

function update(msg, state) {
    let stocktaking, body;
    switch (msg.tag) {
        case 1: {
            const matchValue = state.StocktakingListId;
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingListId = matchValue;
                return [new State(state.StocktakingId, state.StocktakingListId, state.SelectedUsers, FormState__startLoading(state.FormState), state.OnClose), (stocktaking = (new UpdateStocktakingListHelperDto(state.SelectedUsers)), (body = toString(0, Auto_generateBoxedEncoder_437914C6(UpdateStocktakingListHelperDto_$reflection(), undefined, undefined, undefined)(stocktaking)), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let url_2;
                    return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                        const pr = response.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                    }, refreshOn)))(true);
                })), [`/api/stocktakings/${StocktakingId__get_unwrap(state.StocktakingId)}/lists/${StocktakingListId__get_unwrap(stocktakingListId)}/helper`, body], (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])))))];
            }
        }
        case 2: {
            const response_1 = msg.fields[0];
            if (response_1.tag === 1) {
                return [new State(state.StocktakingId, state.StocktakingListId, state.SelectedUsers, FormState__setValidationResponse_5219762A(state.FormState, response_1.fields[0]), state.OnClose), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.StocktakingId, state.StocktakingListId, state.SelectedUsers, FormState_get_empty(), state.OnClose), Cmd_ofEffect((_arg) => {
                    state.OnClose();
                })];
            }
        }
        case 3:
            return [state, Cmd_none()];
        default:
            return [new State(state.StocktakingId, state.StocktakingListId, msg.fields[0], state.FormState, state.OnClose), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems;
    const patternInput = useTranslation();
    const options = map_1((user) => ({
        label: user.Name,
        value: user.Id,
    }), props.Users);
    return createElement("div", createObj(singleton((elems = [createElement(MultiSelect, {
        ComponentState: "enabled",
        IsClearable: true,
        Label: patternInput[0]("stocktaking.stocktaking_helper"),
        NoOptionsMessage: "",
        OnChange: (option) => {
            props.Dispatch(new Msg(0, [map_1((option_2) => option_2.value, ofArray(defaultArg(option, [])))]));
        },
        Options: options,
        PlaceholderKey: "",
        TestId: "",
        Value: filter((option_3) => contains(option_3.value, props.SelectedUsers, {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        }), options),
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))));
}

export function StocktakingListHelperDialog(props) {
    let Title;
    let patternInput;
    const init_1 = init(props.Stocktaking, props.IsOpen, props.OnClose);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen, props.Stocktaking]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const t = useTranslation()[0];
    const stocktakingHelperIds = map_1((helper) => helper.Id, props.Stocktaking.Helpers);
    const stocktakingHelper = filter((user) => contains(user.Id, stocktakingHelperIds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), props.Users);
    const componentState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("stocktaking.assign_stocktaking_helper_to_list"), {
        Body: singleton(createElement(DialogBody, {
            Dispatch: dispatch,
            SelectedUsers: state_1.SelectedUsers,
            Stocktaking: props.Stocktaking,
            Users: stocktakingHelper,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-select-users-dialog",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(1, []));
            },
            TestId: "save-selected-users",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen != null,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

