import { filter, ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Requests_StudentFormDto_$reflection, Student_$reflection, Requests_StudentFormDto } from "../../Shared/User.js";
import { FormState__setValidationResponse_5219762A, FormState__startLoading, FormState_get_empty, RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../../CommunicationV2.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { PropertyConfigurationEnabledResponse, PropertyConfiguration_$reflection, PropertyConfigurationEnabledResponse_$reflection } from "../../Shared/PropertyConfiguration.js";
import { ValidationOnGetRequest } from "../../ErrorHandling.js";
import { string_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { addPrefix as addPrefix_1, fetchWithDecoder as fetchWithDecoder_1 } from "../../Communication.js";
import { unwrapUserId } from "../../Shared/Helper.js";
import { isNullOrEmpty } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_errorToast } from "../../Components/Toast.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";

export const emptyStudent = new Requests_StudentFormDto("", "", "", empty(), undefined, empty());

export const initialState = new State(undefined, new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), FormState_get_empty());

export function initNew() {
    return [new State(initialState.StudentId, new RequestedValue$1(1, [emptyStudent]), initialState.IsCustomPropertiesEnabled, initialState.PropertyConfigurations, initialState.FormState), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item) => (new Msg(8, [Item])), (Item_1) => (new Msg(10, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_5;
        return ((url_5 = addPrefix(url_3), (refreshOn_1) => fetchWithDecoder(url_5, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
            const pr_1 = response_1.arrayBuffer();
            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_3 = _arg_1;
            return (result_3.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_3.fields[0]);
            })())) : (Promise.resolve(result_3.fields[0]));
        });
    })), "/api/property-configurations", (Item_2) => (new Msg(9, [Item_2])), (Item_3) => (new Msg(10, [Item_3])))]))];
}

export function initEdit(studentId) {
    return [initialState, Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Student_$reflection()));
    })))), `/api/receivers/students/${unwrapUserId(studentId)}`, (Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(10, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_5;
        return ((url_5 = addPrefix(url_3), (refreshOn) => fetchWithDecoder(url_5, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
            const pr_1 = response_1.arrayBuffer();
            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_2 = _arg;
            return (result_2.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_2.fields[0]);
            })())) : (Promise.resolve(result_2.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_2) => (new Msg(8, [Item_2])), (Item_3) => (new Msg(10, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix(url_6), (refreshOn_1) => fetchWithDecoder(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_4 = _arg_1;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), "/api/property-configurations", (Item_4) => (new Msg(9, [Item_4])), (Item_5) => (new Msg(10, [Item_5])))]))];
}

export function update(msg, state) {
    let xs;
    switch (msg.tag) {
        case 2: {
            const email = msg.fields[0];
            const matchValue_1 = state.Form;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const student_1 = matchValue_1.fields[0];
                return [new State(state.StudentId, new RequestedValue$1(1, [new Requests_StudentFormDto(student_1.Name, student_1.StudentNumber, student_1.ImageUrl, student_1.QRCodeIds, isNullOrEmpty(email.trim()) ? undefined : email, student_1.CustomProperties)]), state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
        }
        case 1: {
            const matchValue_2 = state.Form;
            if (matchValue_2.tag === 1) {
                const student_2 = matchValue_2.fields[0];
                return [new State(state.StudentId, new RequestedValue$1(1, [new Requests_StudentFormDto(student_2.Name, msg.fields[0], student_2.ImageUrl, student_2.QRCodeIds, student_2.Email, student_2.CustomProperties)]), state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 3: {
            const matchValue_3 = state.Form;
            if (matchValue_3.tag === 1) {
                const student_3 = matchValue_3.fields[0];
                return [new State(state.StudentId, new RequestedValue$1(1, [new Requests_StudentFormDto(student_3.Name, student_3.StudentNumber, student_3.ImageUrl, student_3.QRCodeIds, student_3.Email, msg.fields[0])]), state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 4: {
            const matchValue_4 = state.Form;
            if (matchValue_4.tag === 1) {
                return [new State(state.StudentId, state.Form, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, FormState__startLoading(state.FormState)), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let url_2;
                    return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                        const pr = response.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                    }, refreshOn)))(true);
                })), ["/api/receivers/students", toString(0, Auto_generateBoxedEncoder_437914C6(Requests_StudentFormDto_$reflection(), undefined, undefined, undefined)(matchValue_4.fields[0]))], (Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(10, [Item_1])))];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 5: {
            const matchValue_5 = state.StudentId;
            const matchValue_6 = state.Form;
            let matchResult, student_6, studentId;
            if (matchValue_5 != null) {
                if (matchValue_6.tag === 1) {
                    matchResult = 1;
                    student_6 = matchValue_6.fields[0];
                    studentId = matchValue_5;
                }
                else {
                    matchResult = 0;
                }
            }
            else {
                matchResult = 0;
            }
            switch (matchResult) {
                case 0:
                    return [state, Cmd_none()];
                default:
                    return [new State(state.StudentId, state.Form, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, FormState__startLoading(state.FormState)), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_5;
                        return ((url_5 = addPrefix(tupledArg_1[0]), (refreshOn_1) => fetchWithDecoder(url_5, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                            const pr_1 = response_1.arrayBuffer();
                            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                        }, refreshOn_1)))(true);
                    })), [`/api/receivers/students/${studentId}`, toString(0, Auto_generateBoxedEncoder_437914C6(Requests_StudentFormDto_$reflection(), undefined, undefined, undefined)(student_6))], (Item_2) => (new Msg(7, [Item_2])), (Item_3) => (new Msg(10, [Item_3])))];
            }
        }
        case 6: {
            const student_8 = msg.fields[0];
            return [new State(student_8.Id, new RequestedValue$1(1, [new Requests_StudentFormDto(student_8.Name, student_8.StudentNumber, student_8.ImageUrl, student_8.QRCodeIds, student_8.Email, student_8.CustomProperties)]), state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
        }
        case 7: {
            const response_2 = msg.fields[0];
            if (response_2.tag === 1) {
                return [new State(state.StudentId, state.Form, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, FormState__setValidationResponse_5219762A(state.FormState, response_2.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [state, (xs = ["receivers", response_2.fields[0]], Cmd_ofEffect((_arg) => {
                    RouterModule_nav(ofArray(xs), 1, 1);
                }))];
            }
        }
        case 8:
            return [new State(state.StudentId, state.Form, new RequestedValue$1(1, [equals(msg.fields[0], new PropertyConfigurationEnabledResponse(0, []))]), state.PropertyConfigurations, state.FormState), Cmd_none()];
        case 9:
            return [new State(state.StudentId, state.Form, state.IsCustomPropertiesEnabled, new RequestedValue$1(1, [filter((propertyConfiguration) => {
                const matchValue_8 = propertyConfiguration.Visibility;
                switch (matchValue_8.tag) {
                    case 2:
                    case 0:
                    case 1:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 11:
                        return false;
                    default:
                        return true;
                }
            }, msg.fields[0])]), state.FormState), Cmd_none()];
        case 10:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.Form;
            if (matchValue.tag === 1) {
                const student = matchValue.fields[0];
                return [new State(state.StudentId, new RequestedValue$1(1, [new Requests_StudentFormDto(msg.fields[0], student.StudentNumber, student.ImageUrl, student.QRCodeIds, student.Email, student.CustomProperties)]), state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
    }
}

