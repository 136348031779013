import { tryFind, filter, contains, exists, map } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { safeHash, equals, stringHash } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { unwrapStorageId } from "../Shared/Helper.js";

export function isVisibleForUser(toolTypes, storages, userData) {
    const matchValue = userData.Role;
    switch (matchValue) {
        case "user":
            return false;
        case "administrator":
        case "system":
            return true;
        default:
            if (toolTypes.tag === 1) {
                const storageIdsOfToolWithQuantity = map((sps) => sps.StorageId, toolTypes.fields[0].StockPerStorage);
                return exists((s_2) => contains(userData.UserId, s_2.AssignedUserIds, {
                    Equals: (x_2, y_2) => (x_2 === y_2),
                    GetHashCode: stringHash,
                }), filter((s_1) => contains(s_1.Id, storageIdsOfToolWithQuantity, {
                    Equals: equals,
                    GetHashCode: safeHash,
                }), storages));
            }
            else {
                const toolStorage = tryFind((s) => equals(s.Id, toolTypes.fields[0].StorageId), storages);
                if (toolStorage != null) {
                    return contains(userData.UserId, toolStorage.AssignedUserIds, {
                        Equals: (x, y) => (x === y),
                        GetHashCode: stringHash,
                    });
                }
                else {
                    return false;
                }
            }
    }
}

export function isVisibleForUserByToolDetailDto(tool, storages, userData) {
    const matchValue = userData.Role;
    switch (matchValue) {
        case "user":
            return false;
        case "administrator":
        case "system":
            return true;
        default: {
            const toolStorage = tryFind((s) => (unwrapStorageId(s.Id) === tool.StorageInformation.StorageId), storages);
            if (toolStorage != null) {
                return contains(userData.UserId, toolStorage.AssignedUserIds, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: stringHash,
                });
            }
            else {
                return false;
            }
        }
    }
}

