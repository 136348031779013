import { Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { token as token_2, addPrefix, fetchWithDecoder } from "../Communication.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { head, ofArray, singleton } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { ConsumableByCommissionnumberDto, ReportCsvExportDto, Helper_reportById, ReportResultDto_$reflection } from "../Shared/Report.js";
import { now, addYears, addMonths, addDays, month, year, create, utcNow, date as date_1, toString } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { RequestedValue$1, StorageId__get_unwrap } from "../Shared/Shared.js";
import { State, Msg } from "./Types.js";
import { toString as toString_1 } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { ofNullable } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { singleton as singleton_1, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { fetch$ } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../CommunicationV2.js";
import { PropertyConfiguration_$reflection } from "../Shared/PropertyConfiguration.js";
import { list_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ValidationOnGetRequest } from "../ErrorHandling.js";
import { Storage_$reflection } from "../Shared/Storage.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";

export function getReportsCmd(reportId, startDate, endDate, storageId) {
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_1), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), ReportResultDto_$reflection()));
    })))), (`/api/reports/${reportId}?startDate=${toString(startDate, "dd.MM.yyyy")}&endDate=${toString(endDate, "dd.MM.yyyy")}`) + ((storageId == null) ? "" : (`&storageId=${StorageId__get_unwrap(storageId)}`)), (Item) => (new Msg(11, [Item])), (Item_1) => (new Msg(13, [Item_1])));
}

export function exportReport(dto, csvName) {
    const body = toString_1(0, dto);
    return Cmd_OfPromise_either((tupledArg) => {
        const body_2 = tupledArg[2];
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const tenant = ofNullable(localStorage.getItem("tenant"));
            const props = (body_2 == null) ? ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue_2;
                return append((matchValue_2 = token_2(undefined), (matchValue_2 == null) ? (empty()) : singleton_1(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2]))), delay(() => append(singleton_1(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_3 = tenant;
                    if (matchValue_3 == null) {
                        return empty();
                    }
                    else {
                        return singleton_1(["X-ONE-Tenant", matchValue_3]);
                    }
                }))));
            })), 0)])]) : ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [body_2]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue;
                return append((matchValue = token_2(undefined), (matchValue == null) ? (empty()) : singleton_1(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue]))), delay(() => append(singleton_1(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_1 = tenant;
                    if (matchValue_1 == null) {
                        return empty();
                    }
                    else {
                        return singleton_1(["X-ONE-Tenant", matchValue_1]);
                    }
                }))));
            })), 0)])]);
            return fetch$(addPrefix(tupledArg[0]), props).then((_arg) => (Promise.resolve([_arg, tupledArg[1]])));
        }));
    }, ["/api/reports/export/csv", (csvName == null) ? "export.csv" : csvName, body], (tupledArg_1) => (new Msg(9, [tupledArg_1[0], tupledArg_1[1]])), (Item_2) => (new Msg(13, [Item_2])));
}

function initialState(customer) {
    const date = date_1(utcNow());
    const firstDayOfMonth = create(year(date), month(date), 1);
    return new State(undefined, undefined, undefined, undefined, firstDayOfMonth, customer, addDays(addMonths(firstDayOfMonth, 1), -1), undefined, false, new RequestedValue$1(0, []), new RequestedValue$1(0, []));
}

export function init(customer) {
    return [initialState(customer), Cmd_batch(ofArray([Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix_1(url), (refreshOn) => fetchWithDecoder_1(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), "/api/property-configurations", (Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(13, [Item_1]))), Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_2) => (new Msg(12, [Item_2])), (Item_3) => (new Msg(13, [Item_3])))]))];
}

export function initWithQueryParameter(customer, param) {
    switch (param) {
        case "problemreports": {
            const startDate = addYears(now(), -1);
            let state;
            const bind$0040 = initialState(customer);
            state = (new State(16, bind$0040.SelectedUserId, bind$0040.ReportResult, bind$0040.SelectedConsumableByCommissionnumber, startDate, bind$0040.Customer, bind$0040.EndDate, bind$0040.SelectedStorageId, true, bind$0040.PropertyConfiguration, bind$0040.Storages));
            return [state, Cmd_batch(ofArray([Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_2;
                return ((url_2 = addPrefix_1(url), (refreshOn) => fetchWithDecoder_1(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
                    const pr = response.arrayBuffer();
                    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(PropertyConfiguration_$reflection())));
                }, refreshOn)))(true).then((_arg) => {
                    const result_1 = _arg;
                    return (result_1.tag === 1) ? (Promise.resolve((() => {
                        throw new ValidationOnGetRequest(result_1.fields[0]);
                    })())) : (Promise.resolve(result_1.fields[0]));
                });
            })), "/api/property-configurations", (Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(13, [Item_1]))), getReportsCmd(16, state.StartDate, state.EndDate, state.SelectedStorageId)]))];
        }
        case "appointments": {
            const startDate_1 = addYears(now(), -1);
            let state_1;
            const bind$0040_1 = initialState(customer);
            state_1 = (new State(17, bind$0040_1.SelectedUserId, bind$0040_1.ReportResult, bind$0040_1.SelectedConsumableByCommissionnumber, startDate_1, bind$0040_1.Customer, bind$0040_1.EndDate, bind$0040_1.SelectedStorageId, true, bind$0040_1.PropertyConfiguration, bind$0040_1.Storages));
            return [state_1, Cmd_batch(ofArray([Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_5;
                return ((url_5 = addPrefix_1(url_3), (refreshOn_1) => fetchWithDecoder_1(url_5, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
                    const pr_1 = response_1.arrayBuffer();
                    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(PropertyConfiguration_$reflection())));
                }, refreshOn_1)))(true).then((_arg_1) => {
                    const result_3 = _arg_1;
                    return (result_3.tag === 1) ? (Promise.resolve((() => {
                        throw new ValidationOnGetRequest(result_3.fields[0]);
                    })())) : (Promise.resolve(result_3.fields[0]));
                });
            })), "/api/property-configurations", (Item_2) => (new Msg(0, [Item_2])), (Item_3) => (new Msg(13, [Item_3]))), getReportsCmd(17, state_1.StartDate, state_1.EndDate, state_1.SelectedStorageId)]))];
        }
        case "minimumstockquantity": {
            const startDate_2 = addYears(now(), -1);
            let state_2;
            const bind$0040_2 = initialState(customer);
            state_2 = (new State(18, bind$0040_2.SelectedUserId, bind$0040_2.ReportResult, bind$0040_2.SelectedConsumableByCommissionnumber, startDate_2, bind$0040_2.Customer, bind$0040_2.EndDate, bind$0040_2.SelectedStorageId, true, bind$0040_2.PropertyConfiguration, bind$0040_2.Storages));
            return [state_2, Cmd_batch(ofArray([Cmd_OfPromise_either_1((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_8;
                return ((url_8 = addPrefix_1(url_6), (refreshOn_2) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
                    const pr_2 = response_2.arrayBuffer();
                    return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(PropertyConfiguration_$reflection())));
                }, refreshOn_2)))(true).then((_arg_2) => {
                    const result_5 = _arg_2;
                    return (result_5.tag === 1) ? (Promise.resolve((() => {
                        throw new ValidationOnGetRequest(result_5.fields[0]);
                    })())) : (Promise.resolve(result_5.fields[0]));
                });
            })), "/api/property-configurations", (Item_4) => (new Msg(0, [Item_4])), (Item_5) => (new Msg(13, [Item_5]))), getReportsCmd(18, state_2.StartDate, state_2.EndDate, state_2.SelectedStorageId)]))];
        }
        case "plannedreturndate": {
            const startDate_3 = addYears(now(), -1);
            let state_3;
            const bind$0040_3 = initialState(customer);
            state_3 = (new State(19, bind$0040_3.SelectedUserId, bind$0040_3.ReportResult, bind$0040_3.SelectedConsumableByCommissionnumber, startDate_3, bind$0040_3.Customer, bind$0040_3.EndDate, bind$0040_3.SelectedStorageId, true, bind$0040_3.PropertyConfiguration, bind$0040_3.Storages));
            return [state_3, Cmd_batch(ofArray([Cmd_OfPromise_either_1((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_11;
                return ((url_11 = addPrefix_1(url_9), (refreshOn_3) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
                    const pr_3 = response_3.arrayBuffer();
                    return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), list_type(PropertyConfiguration_$reflection())));
                }, refreshOn_3)))(true).then((_arg_3) => {
                    const result_7 = _arg_3;
                    return (result_7.tag === 1) ? (Promise.resolve((() => {
                        throw new ValidationOnGetRequest(result_7.fields[0]);
                    })())) : (Promise.resolve(result_7.fields[0]));
                });
            })), "/api/property-configurations", (Item_6) => (new Msg(0, [Item_6])), (Item_7) => (new Msg(13, [Item_7]))), getReportsCmd(19, state_3.StartDate, state_3.EndDate, state_3.SelectedStorageId)]))];
        }
        case "reservations": {
            const initialState_1 = initialState(customer);
            const startDate_4 = date_1(utcNow());
            const state_4 = new State(20, initialState_1.SelectedUserId, initialState_1.ReportResult, initialState_1.SelectedConsumableByCommissionnumber, startDate_4, initialState_1.Customer, addYears(initialState_1.EndDate, 1), initialState_1.SelectedStorageId, true, initialState_1.PropertyConfiguration, initialState_1.Storages);
            return [state_4, Cmd_batch(ofArray([Cmd_OfPromise_either_1((url_12) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_14;
                return ((url_14 = addPrefix_1(url_12), (refreshOn_4) => fetchWithDecoder_1(url_14, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_4) => {
                    const pr_4 = response_4.arrayBuffer();
                    return pr_4.then((blob_4) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_4)), list_type(PropertyConfiguration_$reflection())));
                }, refreshOn_4)))(true).then((_arg_4) => {
                    const result_9 = _arg_4;
                    return (result_9.tag === 1) ? (Promise.resolve((() => {
                        throw new ValidationOnGetRequest(result_9.fields[0]);
                    })())) : (Promise.resolve(result_9.fields[0]));
                });
            })), "/api/property-configurations", (Item_8) => (new Msg(0, [Item_8])), (Item_9) => (new Msg(13, [Item_9]))), getReportsCmd(20, startDate_4, state_4.EndDate, state_4.SelectedStorageId)]))];
        }
        case "in_maintenance": {
            const initialState_2 = initialState(customer);
            const startDate_5 = date_1(utcNow());
            const state_5 = new State(23, initialState_2.SelectedUserId, initialState_2.ReportResult, initialState_2.SelectedConsumableByCommissionnumber, startDate_5, initialState_2.Customer, addYears(initialState_2.EndDate, 1), initialState_2.SelectedStorageId, true, initialState_2.PropertyConfiguration, initialState_2.Storages);
            return [state_5, Cmd_batch(ofArray([Cmd_OfPromise_either_1((url_15) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_17;
                return ((url_17 = addPrefix_1(url_15), (refreshOn_5) => fetchWithDecoder_1(url_17, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_5) => {
                    const pr_5 = response_5.arrayBuffer();
                    return pr_5.then((blob_5) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_5)), list_type(PropertyConfiguration_$reflection())));
                }, refreshOn_5)))(true).then((_arg_5) => {
                    const result_11 = _arg_5;
                    return (result_11.tag === 1) ? (Promise.resolve((() => {
                        throw new ValidationOnGetRequest(result_11.fields[0]);
                    })())) : (Promise.resolve(result_11.fields[0]));
                });
            })), "/api/property-configurations", (Item_10) => (new Msg(0, [Item_10])), (Item_11) => (new Msg(13, [Item_11]))), getReportsCmd(23, startDate_5, state_5.EndDate, state_5.SelectedStorageId)]))];
        }
        default:
            return [initialState(customer), Cmd_batch(ofArray([Cmd_OfPromise_either_1((url_18) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_20;
                return ((url_20 = addPrefix_1(url_18), (refreshOn_6) => fetchWithDecoder_1(url_20, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_6) => {
                    const pr_6 = response_6.arrayBuffer();
                    return pr_6.then((blob_6) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_6)), list_type(PropertyConfiguration_$reflection())));
                }, refreshOn_6)))(true).then((_arg_6) => {
                    const result_13 = _arg_6;
                    return (result_13.tag === 1) ? (Promise.resolve((() => {
                        throw new ValidationOnGetRequest(result_13.fields[0]);
                    })())) : (Promise.resolve(result_13.fields[0]));
                });
            })), "/api/property-configurations", (Item_12) => (new Msg(0, [Item_12])), (Item_13) => (new Msg(13, [Item_13]))), Cmd_OfPromise_either_1((url_21) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_21), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_7) => {
                const pr_7 = response_7.arrayBuffer();
                return pr_7.then((blob_7) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_7)), list_type(Storage_$reflection())));
            })))), "/api/storages", (Item_14) => (new Msg(12, [Item_14])), (Item_15) => (new Msg(13, [Item_15])))]))];
    }
}

export function extractBlob(response, filename) {
    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response.blob().then((_arg) => (Promise.resolve([_arg, filename]))))));
}

export function getBlob(response, filename) {
    return Cmd_OfPromise_either((tupledArg) => extractBlob(tupledArg[0], tupledArg[1]), [response, filename], (tupledArg_1) => (new Msg(10, [tupledArg_1[0], tupledArg_1[1]])), (Item_2) => (new Msg(13, [Item_2])));
}

export function update(msg, state) {
    let matchValue_2;
    switch (msg.tag) {
        case 5:
            return [new State(state.SelectedReportId, msg.fields[0], state.ReportResult, state.SelectedConsumableByCommissionnumber, state.StartDate, state.Customer, state.EndDate, state.SelectedStorageId, state.RequestState, state.PropertyConfiguration, state.Storages), Cmd_none()];
        case 1:
            return [new State(state.SelectedReportId, state.SelectedUserId, state.ReportResult, state.SelectedConsumableByCommissionnumber, msg.fields[0], state.Customer, state.EndDate, state.SelectedStorageId, state.RequestState, state.PropertyConfiguration, state.Storages), Cmd_none()];
        case 2:
            return [new State(state.SelectedReportId, state.SelectedUserId, state.ReportResult, state.SelectedConsumableByCommissionnumber, state.StartDate, state.Customer, msg.fields[0], state.SelectedStorageId, state.RequestState, state.PropertyConfiguration, state.Storages), Cmd_none()];
        case 3:
            return [new State(state.SelectedReportId, state.SelectedUserId, state.ReportResult, msg.fields[0], state.StartDate, state.Customer, state.EndDate, state.SelectedStorageId, state.RequestState, state.PropertyConfiguration, state.Storages), Cmd_none()];
        case 4:
            return [new State(msg.fields[0], state.SelectedUserId, undefined, state.SelectedConsumableByCommissionnumber, state.StartDate, state.Customer, state.EndDate, state.SelectedStorageId, state.RequestState, state.PropertyConfiguration, state.Storages), Cmd_none()];
        case 6:
            return [new State(state.SelectedReportId, state.SelectedUserId, state.ReportResult, state.SelectedConsumableByCommissionnumber, state.StartDate, state.Customer, state.EndDate, msg.fields[0], state.RequestState, state.PropertyConfiguration, state.Storages), Cmd_none()];
        case 11:
            return [new State(state.SelectedReportId, state.SelectedUserId, msg.fields[0], state.SelectedConsumableByCommissionnumber, state.StartDate, state.Customer, state.EndDate, state.SelectedStorageId, false, state.PropertyConfiguration, state.Storages), Cmd_none()];
        case 12: {
            const storages = msg.fields[0];
            return [new State(state.SelectedReportId, state.SelectedUserId, state.ReportResult, state.SelectedConsumableByCommissionnumber, state.StartDate, state.Customer, state.EndDate, head(storages).Id, state.RequestState, state.PropertyConfiguration, new RequestedValue$1(1, [storages])), Cmd_none()];
        }
        case 7: {
            let patternInput;
            const matchValue = state.SelectedReportId;
            if (matchValue == null) {
                patternInput = [state, Cmd_none()];
            }
            else {
                const report = Helper_reportById(matchValue);
                if (report == null) {
                    patternInput = [state, Cmd_none()];
                }
                else {
                    const report_1 = report;
                    const matchValue_1 = report_1.Type;
                    switch (matchValue_1.tag) {
                        case 9: {
                            const matchValue_3 = state.SelectedUserId;
                            if (matchValue_3 == null) {
                                patternInput = [state, Cmd_none()];
                            }
                            else {
                                const userName = matchValue_3[1];
                                patternInput = [state, exportReport(new ReportCsvExportDto(1, [matchValue_3[0]]), `${userName.trim()}.csv`)];
                            }
                            break;
                        }
                        case 14: {
                            patternInput = [state, exportReport(new ReportCsvExportDto(2, []), "prinzing-auswertung.xlsx")];
                            break;
                        }
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 12:
                        case 7:
                        case 10:
                        case 11:
                        case 13:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 21:
                        case 20:
                        case 8:
                        case 22:
                        case 23: {
                            patternInput = [state, Cmd_none()];
                            break;
                        }
                        default:
                            patternInput = [state, exportReport(new ReportCsvExportDto(0, [new ConsumableByCommissionnumberDto(state.StartDate, state.EndDate, report_1.Type, (matchValue_2 = state.SelectedConsumableByCommissionnumber, (matchValue_2 == null) ? "" : matchValue_2.CommissionNumber))]), undefined)];
                    }
                }
            }
            return [patternInput[0], patternInput[1]];
        }
        case 9:
            return [state, getBlob(msg.fields[0], msg.fields[1])];
        case 10: {
            const url = window.URL.createObjectURL(msg.fields[0]);
            const element = document.createElement("a");
            element.setAttribute("href", url);
            element.setAttribute("download", msg.fields[1]);
            document.body.appendChild(element);
            element.click();
            window.URL.revokeObjectURL(url);
            element.remove();
            return [state, Cmd_none()];
        }
        case 8: {
            const matchValue_4 = state.SelectedReportId;
            if (matchValue_4 == null) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.SelectedReportId, state.SelectedUserId, state.ReportResult, state.SelectedConsumableByCommissionnumber, state.StartDate, state.Customer, state.EndDate, state.SelectedStorageId, true, state.PropertyConfiguration, state.Storages), getReportsCmd(matchValue_4, state.StartDate, state.EndDate, state.SelectedStorageId)];
            }
        }
        case 13:
            return [new State(state.SelectedReportId, state.SelectedUserId, state.ReportResult, state.SelectedConsumableByCommissionnumber, state.StartDate, state.Customer, state.EndDate, state.SelectedStorageId, false, state.PropertyConfiguration, state.Storages), Cmd_none()];
        default:
            return [new State(state.SelectedReportId, state.SelectedUserId, state.ReportResult, state.SelectedConsumableByCommissionnumber, state.StartDate, state.Customer, state.EndDate, state.SelectedStorageId, state.RequestState, new RequestedValue$1(1, [msg.fields[0]]), state.Storages), Cmd_none()];
    }
}

