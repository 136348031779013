import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, bool_type, union_type, string_type, class_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { ofNullable } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { keyValueList } from "../../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { singleton, empty, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { addPrefix, token as token_2 } from "../../Communication.js";
import { toArray, empty as empty_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetch$ } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { toString } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { StorageId_$reflection, StorageId__get_unwrap } from "../../Shared/Shared.js";
import { SwissAthleticReportResult_$reflection } from "../../Shared/Report.js";
import { onBlobReceived, getBlob } from "../../Communication.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Button } from "../../Components/Button.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { InfiniteTable } from "../../Widgets/Table/InfiniteTable.js";
import { ColumnDefinition$1, CellContent } from "../../Widgets/Table/Table.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DownloadExcelFile", "ExportFileFetched", "BlobReceived", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Report.CustomerReports.SwissAthletic.Msg", [], Msg, () => [[], [["Item1", class_type("Fetch.Types.Response")], ["Item2", string_type]], [["Item1", class_type("Browser.Types.Blob", undefined)], ["Item2", string_type]], [["Item", class_type("System.Exception")]]]);
}

function exportExcelCmd(startDate, endDate, storageId) {
    return Cmd_OfPromise_either((tupledArg) => {
        const body_1 = tupledArg[2];
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const tenant = ofNullable(localStorage.getItem("tenant"));
            const props = (body_1 == null) ? ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue_2;
                return append((matchValue_2 = token_2(undefined), (matchValue_2 == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_3 = tenant;
                    if (matchValue_3 == null) {
                        return empty();
                    }
                    else {
                        return singleton(["X-ONE-Tenant", matchValue_3]);
                    }
                }))));
            })), 0)])]) : ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [body_1]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue;
                return append((matchValue = token_2(undefined), (matchValue == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_1 = tenant;
                    if (matchValue_1 == null) {
                        return empty();
                    }
                    else {
                        return singleton(["X-ONE-Tenant", matchValue_1]);
                    }
                }))));
            })), 0)])]);
            return fetch$(addPrefix(tupledArg[0]), props).then((_arg) => (Promise.resolve([_arg, tupledArg[1]])));
        }));
    }, [`/api/reports/swiss-athletic/user-assignment/${toString(startDate, "dd.MM.yyyy")}/${toString(endDate, "dd.MM.yyyy")}/${StorageId__get_unwrap(storageId)}`, "swiss_athletic_report.xlsx", undefined], (tupledArg_1) => (new Msg(1, [tupledArg_1[0], tupledArg_1[1]])), (Item_2) => (new Msg(3, [Item_2])));
}

class State extends Record {
    constructor(IsLoading, SwissAthleticReportResult, StartDate, EndDate, StorageId) {
        super();
        this.IsLoading = IsLoading;
        this.SwissAthleticReportResult = SwissAthleticReportResult;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.StorageId = StorageId;
    }
}

function State_$reflection() {
    return record_type("Report.CustomerReports.SwissAthletic.State", [], State, () => [["IsLoading", bool_type], ["SwissAthleticReportResult", SwissAthleticReportResult_$reflection()], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["StorageId", StorageId_$reflection()]]);
}

function init(swissAthleticReportResult, startDate, endDate, storageId) {
    return [new State(false, swissAthleticReportResult, startDate, endDate, storageId), Cmd_batch(empty_1())];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(false, state.SwissAthleticReportResult, state.StartDate, state.EndDate, state.StorageId), getBlob(msg.fields[0], msg.fields[1], (tupledArg) => (new Msg(2, [tupledArg[0], tupledArg[1]])), (Item_2) => (new Msg(3, [Item_2])))];
        case 2: {
            onBlobReceived(msg.fields[0], msg.fields[1]);
            return [state, Cmd_none()];
        }
        case 3:
            return [new State(false, state.SwissAthleticReportResult, state.StartDate, state.EndDate, state.StorageId), Cmd_errorToast("Es ist ein Fehler aufgetreten")];
        default:
            return [new State(true, state.SwissAthleticReportResult, state.StartDate, state.EndDate, state.StorageId), Cmd_batch(ofArray([Cmd_successToast("Der Download wurde gestartet"), exportExcelCmd(state.StartDate, state.EndDate, state.StorageId)]))];
    }
}

function ReportContent(props) {
    let elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-full"])], (elems_1 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: "Excel Export",
            OnClick: props.DownloadExcelFile,
            TestId: "download-excel-file",
            Variant: "blueButton",
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => singleton(createElement(InfiniteTable, {
            ColumnDefinitions: [new ColumnDefinition$1("lFDADRNR", "", "LFDADRNR", (dto) => (new CellContent(0, [dto.LFDADRNR])), "text", "text"), new ColumnDefinition$1("firstName", "", "Vorname", (dto_1) => (new CellContent(0, [dto_1.FirstName])), "text", "text"), new ColumnDefinition$1("surname", "", "Nachname", (dto_2) => (new CellContent(0, [dto_2.Surname])), "text", "text"), new ColumnDefinition$1("clothingItem", "", "Kleidungsstück", (dto_3) => (new CellContent(0, [dto_3.ClothingItem])), "text", "text"), new ColumnDefinition$1("lFDKLDNR", "", "LFDKLDNR", (dto_4) => (new CellContent(0, [dto_4.LFDKLDNR])), "text", "text"), new ColumnDefinition$1("date", "", "Datum", (dto_5) => (new CellContent(0, [dto_5.Date])), "text", "text"), new ColumnDefinition$1("pieces", "", "Stück", (dto_6) => (new CellContent(0, [dto_6.Pieces])), "text", "text"), new ColumnDefinition$1("remark", "", "Bemerkung", (dto_7) => (new CellContent(0, [dto_7.Remark])), "text", "text"), new ColumnDefinition$1("category", "", "Kategorie", (dto_8) => (new CellContent(0, [dto_8.Category])), "text", "text")],
            Controls: empty_1(),
            Options: {
                BorderStyle: "row",
                CSVDownload: true,
                ConfigurableColumns: false,
                DefaultSortColumn: ["lFDADRNR", "asc"],
                GlobalSearch: true,
                RowSelection: "disabled",
                WithQueryParameters: false,
            },
            TableRows: toArray(props.SwissAthleticReportRows),
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function SwissAthleticReport(props) {
    let elems;
    let patternInput;
    const init_1 = init(props.SwissAthleticReportResult, props.StartDate, props.EndDate, props.StorageId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.SwissAthleticReportResult]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-full"])], (elems = [createElement(ReportContent, {
        DownloadExcelFile: () => {
            patternInput[1](new Msg(0, []));
        },
        SwissAthleticReportRows: patternInput[0].SwissAthleticReportResult.Rows,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

