import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, string_type, class_type, record_type, list_type, option_type, bool_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { TokenDataDto_$reflection, RequestedValue$1_$reflection, CustomerConfiguration_$reflection } from "../Shared/Shared.js";
import { ImportHistoryEntry_$reflection } from "../Shared/Importer.js";

export class State extends Record {
    constructor(Loading, CustomerConfiguration, ImportHistory, CurrentUser) {
        super();
        this.Loading = Loading;
        this.CustomerConfiguration = CustomerConfiguration;
        this.ImportHistory = ImportHistory;
        this.CurrentUser = CurrentUser;
    }
}

export function State_$reflection() {
    return record_type("Importer.Types.State", [], State, () => [["Loading", bool_type], ["CustomerConfiguration", option_type(CustomerConfiguration_$reflection())], ["ImportHistory", RequestedValue$1_$reflection(list_type(ImportHistoryEntry_$reflection()))], ["CurrentUser", TokenDataDto_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FetchImportHistory", "ImportHistoryFetched", "CustomerConfigurationFetched", "ExportSpecificTools", "ExportSimpleTools", "ExportConsumables", "ExportExcelFetched", "BlobReceived", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Importer.Types.Msg", [], Msg, () => [[], [["Item", list_type(ImportHistoryEntry_$reflection())]], [["Item", CustomerConfiguration_$reflection()]], [], [], [], [["Item1", class_type("Fetch.Types.Response")], ["Item2", string_type]], [["Item1", class_type("Browser.Types.Blob", undefined)], ["Item2", string_type]], [["Item", class_type("System.Exception")]]]);
}

