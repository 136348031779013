import { RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getSets } from "../../Requests/Set.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../../CommunicationV2.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { filter, ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ValidationOnGetRequest } from "../../ErrorHandling.js";
import { getCustomerConfiguration } from "../../Requests/Configuration.js";
import { getAccountSettings } from "../../Requests/Customer.js";

export const init = [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, [])), Cmd_batch(ofArray([getSets((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(5, [Item_1]))), Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
    let url_2;
    return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(PropertyConfiguration_$reflection())));
    }, refreshOn)))(true).then((_arg) => {
        const result_1 = _arg;
        return (result_1.tag === 1) ? (Promise.resolve((() => {
            throw new ValidationOnGetRequest(result_1.fields[0]);
        })())) : (Promise.resolve(result_1.fields[0]));
    });
})), "/api/property-configurations", (Item_2) => (new Msg(0, [Item_2])), (Item_3) => (new Msg(5, [Item_3]))), getCustomerConfiguration((Item_4) => (new Msg(2, [Item_4])), (Item_5) => (new Msg(5, [Item_5]))), getAccountSettings((Item_6) => (new Msg(3, [Item_6])), (Item_7) => (new Msg(5, [Item_7])))]))];

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.PropertyConfiguration, state.CustomerConfiguration, state.AccountSettings), Cmd_none()];
        case 0:
            return [new State(state.Sets, new RequestedValue$1(1, [filter((propertyConfiguration) => {
                const matchValue = propertyConfiguration.Visibility;
                switch (matchValue.tag) {
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        return false;
                    default:
                        return true;
                }
            }, msg.fields[0])]), state.CustomerConfiguration, state.AccountSettings), Cmd_none()];
        case 2:
            return [new State(state.Sets, state.PropertyConfiguration, new RequestedValue$1(1, [msg.fields[0]]), state.AccountSettings), Cmd_none()];
        case 3:
            return [new State(state.Sets, state.PropertyConfiguration, state.CustomerConfiguration, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 5:
            return [state, Cmd_none()];
        default:
            return [state, getSets((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(5, [Item_1])))];
    }
}

