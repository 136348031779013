import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { bool_type, record_type, class_type, option_type, string_type, union_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { StocktakingListDto_$reflection, StocktakingDetailDto_$reflection, StocktakingResultType_$reflection } from "../../Shared/Stocktaking.js";
import { ClientApiResponse$1_$reflection, FormState_$reflection, RequestedValue$1_$reflection, StocktakingListId_$reflection, StocktakingId_$reflection } from "../../Shared/Shared.js";

export class SelectedTab extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OpenTasks", "AllRows"];
    }
}

export function SelectedTab_$reflection() {
    return union_type("Stocktaking.StocktakingList.Types.SelectedTab", [], SelectedTab, () => [[], []]);
}

export class StocktakingListTableRow extends Record {
    constructor(ImageUrl, InventoryNumber, Unit, Name, Quantity, RecordedBy, RecordTimestamp, RecordedQuantity, CorrectedBy, CorrectionTimestamp, CorrectionQuantity, StocktakingResultType) {
        super();
        this.ImageUrl = ImageUrl;
        this.InventoryNumber = InventoryNumber;
        this.Unit = Unit;
        this.Name = Name;
        this.Quantity = Quantity;
        this.RecordedBy = RecordedBy;
        this.RecordTimestamp = RecordTimestamp;
        this.RecordedQuantity = RecordedQuantity;
        this.CorrectedBy = CorrectedBy;
        this.CorrectionTimestamp = CorrectionTimestamp;
        this.CorrectionQuantity = CorrectionQuantity;
        this.StocktakingResultType = StocktakingResultType;
    }
}

export function StocktakingListTableRow_$reflection() {
    return record_type("Stocktaking.StocktakingList.Types.StocktakingListTableRow", [], StocktakingListTableRow, () => [["ImageUrl", string_type], ["InventoryNumber", string_type], ["Unit", string_type], ["Name", string_type], ["Quantity", string_type], ["RecordedBy", option_type(string_type)], ["RecordTimestamp", option_type(class_type("System.DateTime"))], ["RecordedQuantity", option_type(string_type)], ["CorrectedBy", option_type(string_type)], ["CorrectionTimestamp", option_type(class_type("System.DateTime"))], ["CorrectionQuantity", option_type(string_type)], ["StocktakingResultType", StocktakingResultType_$reflection()]]);
}

export class DialogOpenState extends Record {
    constructor(IsConfirmationStateStartDialogOpen, IsConfirmationStateDoneDialogOpen) {
        super();
        this.IsConfirmationStateStartDialogOpen = IsConfirmationStateStartDialogOpen;
        this.IsConfirmationStateDoneDialogOpen = IsConfirmationStateDoneDialogOpen;
    }
}

export function DialogOpenState_$reflection() {
    return record_type("Stocktaking.StocktakingList.Types.DialogOpenState", [], DialogOpenState, () => [["IsConfirmationStateStartDialogOpen", bool_type], ["IsConfirmationStateDoneDialogOpen", bool_type]]);
}

export class State extends Record {
    constructor(StocktakingId, StocktakingListId, Stocktaking, StocktakingList, DialogOpenState, FormState) {
        super();
        this.StocktakingId = StocktakingId;
        this.StocktakingListId = StocktakingListId;
        this.Stocktaking = Stocktaking;
        this.StocktakingList = StocktakingList;
        this.DialogOpenState = DialogOpenState;
        this.FormState = FormState;
    }
}

export function State_$reflection() {
    return record_type("Stocktaking.StocktakingList.Types.State", [], State, () => [["StocktakingId", StocktakingId_$reflection()], ["StocktakingListId", StocktakingListId_$reflection()], ["Stocktaking", RequestedValue$1_$reflection(StocktakingDetailDto_$reflection())], ["StocktakingList", RequestedValue$1_$reflection(StocktakingListDto_$reflection())], ["DialogOpenState", DialogOpenState_$reflection()], ["FormState", FormState_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FetchStocktaking", "StocktakingFetched", "SetStocktakingStateStart", "SetStocktakingStateDone", "OnCloseConfirmationStateStartDialog", "OnCloseConfirmationStateDoneDialog", "StocktakingStateSet", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Stocktaking.StocktakingList.Types.Msg", [], Msg, () => [[], [["Item", StocktakingDetailDto_$reflection()]], [], [], [["Item", bool_type]], [["Item", bool_type]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

