import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, record_type, bool_type, lambda_type, unit_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { AcceptNotificationDialog, NotificationToolType_$reflection } from "./AcceptNotificationDialog.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { AbortNotificationDialog, RejectNotificationDialog } from "./RejectNotificationDialog.js";
import Notification_Alarm_2 from "../../../public/assets/icons/Notification_Alarm_2.svg";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { map, isEmpty, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { TextButton } from "../../Components/Button.js";
import { delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";

class State extends Record {
    constructor(OnUpdate, IsAcceptDialogOpen, IsRejectDialogOpen, NotificationToolType) {
        super();
        this.OnUpdate = OnUpdate;
        this.IsAcceptDialogOpen = IsAcceptDialogOpen;
        this.IsRejectDialogOpen = IsRejectDialogOpen;
        this.NotificationToolType = NotificationToolType;
    }
}

function State_$reflection() {
    return record_type("Widgets.NotificationArea.State", [], State, () => [["OnUpdate", lambda_type(unit_type, unit_type)], ["IsAcceptDialogOpen", bool_type], ["IsRejectDialogOpen", bool_type], ["NotificationToolType", NotificationToolType_$reflection()]]);
}

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetIsAcceptDialogOpen", "SetIsRejectDialogOpen"];
    }
}

function Msg_$reflection() {
    return union_type("Widgets.NotificationArea.Msg", [], Msg, () => [[["Item", bool_type]], [["Item", bool_type]]]);
}

function init(notificationToolType, onUpdate) {
    return [new State(onUpdate, false, false, notificationToolType), Cmd_none()];
}

function update(msg, state) {
    if (msg.tag === 1) {
        return [new State(state.OnUpdate, state.IsAcceptDialogOpen, msg.fields[0], state.NotificationToolType), Cmd_none()];
    }
    else {
        return [new State(state.OnUpdate, msg.fields[0], state.IsRejectDialogOpen, state.NotificationToolType), Cmd_none()];
    }
}

function notificationText(t, textForReceiver, notification) {
    const matchValue = notification.NotificationType;
    switch (matchValue) {
        case "toolUserToStorageRequest": {
            const arg_3 = {
                name: notification.EntityName,
                sender: notification.Sender,
            };
            return t("notification.tool_user_to_storage_request", arg_3);
        }
        case "toolStorageToUserAssignment":
            if (textForReceiver) {
                const arg_5 = {
                    name: notification.EntityName,
                };
                return t("notification.tool_storage_to_user", arg_5);
            }
            else {
                const arg_7 = {
                    name: notification.EntityName,
                    receiver: notification.Receiver,
                    sender: notification.Sender,
                };
                return t("notification.tool_storage_to_user_as_sender", arg_7);
            }
        case "toolUserToUserAssignment": {
            const arg_9 = {
                name: notification.EntityName,
                sender: notification.Sender,
            };
            return t("notification.tool_user_to_user", arg_9);
        }
        case "toolWithQuantityBackToStorage": {
            const arg_11 = {
                name: notification.EntityName,
                sender: notification.Sender,
            };
            return t("notification.tool_with_quantity_back_to_storage", arg_11);
        }
        case "toolWithQuantityUserToStorageRequest": {
            const arg_13 = {
                name: notification.EntityName,
                sender: notification.Sender,
            };
            return t("notification.tool_with_quantity_user_to_storage_request", arg_13);
        }
        case "toolWithQuantityStorageToUserAssignment": {
            const arg_15 = {
                name: notification.EntityName,
            };
            return t("notification.tool_with_quantity_storage_to_user", arg_15);
        }
        case "toolWithQuantityUserToUserAssignment": {
            const arg_17 = {
                name: notification.EntityName,
                sender: notification.Sender,
            };
            return t("notification.tool_with_quantity_user_to_user", arg_17);
        }
        case "setBackToStorage": {
            const arg_19 = {
                name: notification.EntityName,
                sender: notification.Sender,
            };
            return t("notification.set_back_to_storage", arg_19);
        }
        case "setUserToStorageRequest": {
            const arg_21 = {
                name: notification.EntityName,
                sender: notification.Sender,
            };
            return t("notification.set_user_to_storage_request", arg_21);
        }
        case "setStorageToUserAssignment": {
            const arg_23 = {
                name: notification.EntityName,
            };
            return t("notification.set_storage_to_user", arg_23);
        }
        case "setUserToUserAssignment": {
            const arg_25 = {
                name: notification.EntityName,
                sender: notification.Sender,
            };
            return t("notification.set_user_to_user", arg_25);
        }
        case "consumableStorageToUserAssignment": {
            const arg_27 = {
                name: notification.EntityName,
            };
            return t("notification.consumable_storage_to_user", arg_27);
        }
        case "consumableReturnConsumableToStorage": {
            const arg_29 = {
                name: notification.EntityName,
                sender: notification.Sender,
            };
            return t("notification.consumable_back_to_storage", arg_29);
        }
        case "consumableReturnConsumableToStorageRequest": {
            const arg_31 = {
                name: notification.EntityName,
                sender: notification.Sender,
            };
            return t("notification.consumable_user_to_storage_request", arg_31);
        }
        case "storageToUserBulk": {
            const arg_33 = {
                name: notification.EntityName,
                sender: notification.Sender,
            };
            return t("notification.storage_to_user_bulk", arg_33);
        }
        default: {
            const arg_1 = {
                name: notification.EntityName,
                sender: notification.Sender,
            };
            return t("notification.tool_back_to_storage", arg_1);
        }
    }
}

function NotificationReceiverElement(props) {
    let elems_1, elems;
    let patternInput;
    const init_1 = init(props.NotificationToolType, props.OnUpdate);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const t = patternInput_1[0];
    const i18n = patternInput_1[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "gap-5", "bg-[#EFF6FF]", "min-w-85", "text-sm", "flex", "flex-row", "p-3.5", "rounded", "w-full"])], (elems_1 = [createElement(RejectNotificationDialog, {
        Description: notificationText((arg, arg_1) => (i18n.t(arg, arg_1)), true, props.Notification),
        IsOpen: state_1.IsRejectDialogOpen,
        Notification: props.Notification,
        OnClose: () => {
            dispatch(new Msg(1, [false]));
            state_1.OnUpdate();
        },
        OnConfirm: () => {
            dispatch(new Msg(1, [false]));
            state_1.OnUpdate();
        },
    }), createElement(AcceptNotificationDialog, {
        Description: notificationText((arg_2, arg_3) => (i18n.t(arg_2, arg_3)), true, props.Notification),
        IsOpen: state_1.IsAcceptDialogOpen,
        Notification: props.Notification,
        NotificationToolType: state_1.NotificationToolType,
        OnClose: () => {
            dispatch(new Msg(0, [false]));
            state_1.OnUpdate();
        },
        OnConfirm: () => {
            dispatch(new Msg(0, [false]));
            state_1.OnUpdate();
        },
    }), createElement("div", createObj(ofArray([["className", join(" ", ["w-5", "h-5"])], (elems = [Notification_Alarm_2()], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", {
        className: join(" ", ["grow"]),
        children: notificationText((arg_4, arg_5) => (i18n.t(arg_4, arg_5)), true, props.Notification),
    }), createElement(TextButton, {
        ComponentState: "enabled",
        Label: t("notification.accept"),
        OnClick: () => {
            dispatch(new Msg(0, [true]));
        },
        TestId: "",
        Variant: "blueButton",
    }), createElement(TextButton, {
        ComponentState: "enabled",
        Label: t("notification.reject"),
        OnClick: () => {
            dispatch(new Msg(1, [true]));
        },
        TestId: "",
        Variant: "redButton",
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function NotificationSenderElement(props) {
    let elems_1, elems;
    let patternInput;
    const init_1 = init(props.NotificationToolType, props.OnUpdate);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const i18n = patternInput_1[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "gap-5", "bg-[#EFF6FF]", "min-w-85", "text-sm", "flex", "flex-row", "p-3.5", "rounded", "w-full"])], (elems_1 = [createElement(AbortNotificationDialog, {
        Description: notificationText((arg, arg_1) => (i18n.t(arg, arg_1)), false, props.Notification),
        IsOpen: state_1.IsRejectDialogOpen,
        Notification: props.Notification,
        OnClose: () => {
            dispatch(new Msg(1, [false]));
            state_1.OnUpdate();
        },
        OnConfirm: () => {
            dispatch(new Msg(0, [false]));
            state_1.OnUpdate();
        },
    }), createElement("div", createObj(ofArray([["className", join(" ", ["w-5", "h-5"])], (elems = [Notification_Alarm_2()], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", {
        className: join(" ", ["grow"]),
        children: notificationText((arg_2, arg_3) => (i18n.t(arg_2, arg_3)), false, props.Notification),
    }), createElement(TextButton, {
        ComponentState: "enabled",
        Label: patternInput_1[0]("general.cancel"),
        OnClick: () => {
            dispatch(new Msg(1, [true]));
        },
        TestId: "",
        Variant: "redButton",
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function NotificationArea(props) {
    let elems;
    const patternInput = useTranslation();
    if (!isEmpty(props.Notifications)) {
        return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "mb-8"])], (elems = toList(delay(() => map((notification) => {
            if (notification.SenderId === props.UserData.UserId) {
                return createElement(NotificationSenderElement, {
                    Notification: notification,
                    NotificationToolType: props.NotificationToolType,
                    OnUpdate: props.OnUpdate,
                });
            }
            else {
                return createElement(NotificationReceiverElement, {
                    Notification: notification,
                    NotificationToolType: props.NotificationToolType,
                    OnUpdate: props.OnUpdate,
                });
            }
        }, props.Notifications))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
    }
    else {
        return defaultOf();
    }
}

