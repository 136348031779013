import { createElement } from "react";
import React from "react";
import { stringHash, defaultOf, equals, createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { TextValue, SectionHeader } from "../Components/Text.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { contains, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { useTranslation } from "react-i18next";
import { nativeType, useDrop } from "../Bindings/Fable.ReactDnD.js";
import { item, equalsWith } from "../fable_modules/fable-library-js.4.19.2/Array.js";
import { TextButton } from "../Components/Button.js";

export function FormSection(props) {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["id", props.Id], (elems_1 = [createElement(SectionHeader, {
        Text: props.Header,
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-5"])], (elems = toList(delay(() => props.Content)), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function FormImageSection(props) {
    let elems_2, elems_1;
    const t = useTranslation()[0];
    const dependencies = [props.SelectedFile];
    reactApi.useEffect(() => {
        const matchValue = props.SelectedFile;
        if (matchValue == null) {
            const img_1 = document.getElementById("img-set-preview");
            img_1.src = "";
        }
        else {
            const file = matchValue;
            const img = document.getElementById("img-set-preview");
            const url = URL.createObjectURL(file);
            img.src = url;
        }
    }, dependencies);
    const patternInput_1 = useDrop({
        accept: nativeType.FILE,
        collect: (monitor) => {
            const isOver = monitor.isOver();
            return {
                canDrop: monitor.canDrop(),
                isOver: isOver,
            };
        },
        drop: (draggedFile) => {
            let testExpr, testExpr_1;
            const matchValue_1 = draggedFile.files;
            if (matchValue_1 == null) {
            }
            else if ((testExpr = matchValue_1, !equalsWith(equals, testExpr, defaultOf()) && (testExpr.length === 0))) {
            }
            else if ((testExpr_1 = matchValue_1, !equalsWith(equals, testExpr_1, defaultOf()) && (testExpr_1.length === 1))) {
                const file_1 = item(0, matchValue_1);
                if (contains(file_1.type, ofArray(["image/jpeg", "image/png"]), {
                    Equals: (x_2, y_2) => (x_2 === y_2),
                    GetHashCode: stringHash,
                })) {
                    props.SetSelectedFile(file_1);
                }
            }
        },
    });
    const dropData = patternInput_1[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-2"])], (elems_2 = [createElement(TextValue, {
        FontWeight: "bold",
        TestId: "",
        Text: t("general.image"),
    }), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("rounded"), delay(() => append(singleton("border"), delay(() => append(singleton("border-dashed"), delay(() => append(singleton("border-primary"), delay(() => append((dropData.isOver && dropData.canDrop) ? singleton("bg-background") : singleton("bg-white"), delay(() => singleton("p-4"))))))))))))))], ["ref", patternInput_1[1]], (elems_1 = toList(delay(() => append(singleton(createElement("img", {
        className: join(" ", ["shadow", "rounded", "border-none", "max-h-24"]),
        id: "img-set-preview",
        "data-test-id": "img-set-preview",
    })), delay(() => append((props.SelectedFile == null) ? singleton(createElement(TextButton, {
        ComponentState: props.FormState,
        Label: t("general.select_file"),
        OnClick: () => {
            const element = document.getElementById("file-upload");
            element.click();
        },
        TestId: "open-file-explorer-test-id",
        Variant: "blueButton",
    })) : singleton(createElement(TextButton, {
        ComponentState: props.FormState,
        Label: t("general.delete"),
        OnClick: () => {
            props.SetSelectedFile(undefined);
        },
        TestId: "delete-image-button",
        Variant: "redButton",
    })), delay(() => {
        let elems;
        return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "justify-center"])], (elems = [createElement("input", {
            id: "file-upload",
            className: join(" ", ["hidden"]),
            type: "file",
            accept: ".jpg, .jpeg, .png",
            onChange: (ev) => {
                const files = ev.target.files;
                if (!(files == null) && (files.length > 0)) {
                    props.SetSelectedFile(files.item(0));
                }
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))));
    })))))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

