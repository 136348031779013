import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, FormState__startLoading, FormState__setValidation, FormState_get_empty, ClientApiResponse$1_$reflection, FormState_$reflection, StocktakingId_$reflection } from "../../Shared/Shared.js";
import { CreateStocktakingListToolResultDto_$reflection, CreateStocktakingListToolResultDto, CreateStocktakingListConsumableResultDto_$reflection, CreateStocktakingListConsumableResultDto, CreateStocktakingListToolWithQuantityResultDto_$reflection, CreateStocktakingListToolWithQuantityResultDto, StocktakingResultType_$reflection, StocktakingListDto_$reflection } from "../../Shared/Stocktaking.js";
import { union_type, class_type, record_type, string_type, lambda_type, unit_type, bool_type, float64_type, int32_type, option_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { unwrap, defaultArg, map } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { singleton as singleton_1, ofArray, find } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createObj, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Cmd_ofEffect, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../../CommunicationV2.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { ConsumableId__get_unwrap, ToolId__get_unwrap, StocktakingListId__get_unwrap, StocktakingId__get_unwrap } from "../../Shared/Shared.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_errorToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { empty, singleton, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { NumberInput } from "../../Components/Input.js";
import { Helper_unitToTranslationKey } from "../../Shared/Consumable.js";
import { Toggle } from "../../Components/Toggle.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class State extends Record {
    constructor(StocktakingId, StocktakingList, StocktakingResultType, ToolWithQuantityQuantity, ConsumableQuantity, IsToolFound, FormState, OnClose, ConsumableUnit) {
        super();
        this.StocktakingId = StocktakingId;
        this.StocktakingList = StocktakingList;
        this.StocktakingResultType = StocktakingResultType;
        this.ToolWithQuantityQuantity = ToolWithQuantityQuantity;
        this.ConsumableQuantity = ConsumableQuantity;
        this.IsToolFound = IsToolFound;
        this.FormState = FormState;
        this.OnClose = OnClose;
        this.ConsumableUnit = ConsumableUnit;
    }
}

function State_$reflection() {
    return record_type("Stocktaking.Dialog.ConfirmStocktakingResultDialog.State", [], State, () => [["StocktakingId", StocktakingId_$reflection()], ["StocktakingList", StocktakingListDto_$reflection()], ["StocktakingResultType", option_type(StocktakingResultType_$reflection())], ["ToolWithQuantityQuantity", option_type(int32_type)], ["ConsumableQuantity", option_type(float64_type)], ["IsToolFound", bool_type], ["FormState", FormState_$reflection()], ["OnClose", lambda_type(unit_type, unit_type)], ["ConsumableUnit", option_type(string_type)]]);
}

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetIsToolFound", "SetToolWithQuantityQuantity", "SetConsumableQuantity", "SaveStocktakingResult", "StocktakingResultSaved", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Stocktaking.Dialog.ConfirmStocktakingResultDialog.Msg", [], Msg, () => [[["Item", bool_type]], [["Item", option_type(int32_type)]], [["Item", option_type(float64_type)]], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

function init(stocktaking, stocktakingList, stocktakingResultType, onClose) {
    let patternInput;
    if (stocktakingResultType == null) {
        patternInput = [false, undefined, undefined, undefined];
    }
    else {
        const stocktakingResultType_1 = stocktakingResultType;
        switch (stocktakingResultType_1.tag) {
            case 1: {
                patternInput = [false, map((result) => result.Quantity, find((tool_2) => equals(tool_2.ToolId, stocktakingResultType_1.fields[0]), stocktakingList.ToolsWithQuantity).Result), undefined, undefined];
                break;
            }
            case 2: {
                const consumable_1 = find((consumable) => equals(consumable.ConsumableId, stocktakingResultType_1.fields[0]), stocktakingList.Consumables);
                patternInput = [false, undefined, map((result_1) => result_1.Quantity, consumable_1.Result), consumable_1.Unit];
                break;
            }
            default:
                patternInput = [defaultArg(map((_arg) => _arg.Existing, find((tool) => equals(tool.ToolId, stocktakingResultType_1.fields[0]), stocktakingList.Tools).Result), false), undefined, undefined, undefined];
        }
    }
    return [new State(stocktaking.Id, stocktakingList, stocktakingResultType, patternInput[1], patternInput[2], patternInput[0], FormState_get_empty(), onClose, patternInput[3]), Cmd_none()];
}

function update(msg, state) {
    let dto_1, dto_2, dto;
    switch (msg.tag) {
        case 1:
            return [new State(state.StocktakingId, state.StocktakingList, state.StocktakingResultType, msg.fields[0], state.ConsumableQuantity, state.IsToolFound, state.FormState, state.OnClose, state.ConsumableUnit), Cmd_none()];
        case 2:
            return [new State(state.StocktakingId, state.StocktakingList, state.StocktakingResultType, state.ToolWithQuantityQuantity, msg.fields[0], state.IsToolFound, state.FormState, state.OnClose, state.ConsumableUnit), Cmd_none()];
        case 3: {
            const matchValue = state.StocktakingResultType;
            const matchValue_1 = state.ToolWithQuantityQuantity;
            const matchValue_2 = state.ConsumableQuantity;
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                switch (matchValue.tag) {
                    case 1:
                        if (matchValue_1 == null) {
                            const toolId_4 = matchValue.fields[0];
                            return [new State(state.StocktakingId, state.StocktakingList, state.StocktakingResultType, state.ToolWithQuantityQuantity, state.ConsumableQuantity, state.IsToolFound, FormState__setValidation(state.FormState, "quantity", "validation.input.quantity_not_empty"), state.OnClose, state.ConsumableUnit), Cmd_none()];
                        }
                        else {
                            const quantity_2 = matchValue_1 | 0;
                            const toolId_2 = matchValue.fields[0];
                            return [new State(state.StocktakingId, state.StocktakingList, state.StocktakingResultType, state.ToolWithQuantityQuantity, state.ConsumableQuantity, state.IsToolFound, FormState__startLoading(state.FormState), state.OnClose, state.ConsumableUnit), (dto_1 = (new CreateStocktakingListToolWithQuantityResultDto(quantity_2)), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                let url_5;
                                return ((url_5 = addPrefix(tupledArg_1[0]), (refreshOn_1) => fetchWithDecoder(url_5, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                                    const pr_1 = response_1.arrayBuffer();
                                    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                                }, refreshOn_1)))(true);
                            })), [`/api/stocktakings/${StocktakingId__get_unwrap(state.StocktakingId)}/lists/${StocktakingListId__get_unwrap(state.StocktakingList.Id)}/toolswithquantity/${ToolId__get_unwrap(toolId_2)}/result`, toString(0, Auto_generateBoxedEncoder_437914C6(CreateStocktakingListToolWithQuantityResultDto_$reflection(), undefined, undefined, undefined)(dto_1))], (Item_2) => (new Msg(4, [Item_2])), (Item_3) => (new Msg(5, [Item_3]))))];
                        }
                    case 2:
                        if (matchValue_2 == null) {
                            const consumableId_2 = matchValue.fields[0];
                            return [new State(state.StocktakingId, state.StocktakingList, state.StocktakingResultType, state.ToolWithQuantityQuantity, state.ConsumableQuantity, state.IsToolFound, FormState__setValidation(state.FormState, "quantity", "validation.input.quantity_not_empty"), state.OnClose, state.ConsumableUnit), Cmd_none()];
                        }
                        else {
                            const consumableId = matchValue.fields[0];
                            const quantity_3 = matchValue_2;
                            return [new State(state.StocktakingId, state.StocktakingList, state.StocktakingResultType, state.ToolWithQuantityQuantity, state.ConsumableQuantity, state.IsToolFound, FormState__startLoading(state.FormState), state.OnClose, state.ConsumableUnit), (dto_2 = (new CreateStocktakingListConsumableResultDto(quantity_3)), Cmd_OfPromise_either((tupledArg_2) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                let url_8;
                                return ((url_8 = addPrefix(tupledArg_2[0]), (refreshOn_2) => fetchWithDecoder(url_8, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg_2[1]])]), "application/json", (response_2) => {
                                    const pr_2 = response_2.arrayBuffer();
                                    return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), string_type));
                                }, refreshOn_2)))(true);
                            })), [`/api/stocktakings/${StocktakingId__get_unwrap(state.StocktakingId)}/lists/${StocktakingListId__get_unwrap(state.StocktakingList.Id)}/consumables/${ConsumableId__get_unwrap(consumableId)}/result`, toString(0, Auto_generateBoxedEncoder_437914C6(CreateStocktakingListConsumableResultDto_$reflection(), undefined, undefined, undefined)(dto_2))], (Item_4) => (new Msg(4, [Item_4])), (Item_5) => (new Msg(5, [Item_5]))))];
                        }
                    default: {
                        const toolId = matchValue.fields[0];
                        return [new State(state.StocktakingId, state.StocktakingList, state.StocktakingResultType, state.ToolWithQuantityQuantity, state.ConsumableQuantity, state.IsToolFound, FormState__startLoading(state.FormState), state.OnClose, state.ConsumableUnit), (dto = (new CreateStocktakingListToolResultDto(state.IsToolFound)), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            let url_2;
                            return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                                const pr = response.arrayBuffer();
                                return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                            }, refreshOn)))(true);
                        })), [`/api/stocktakings/${StocktakingId__get_unwrap(state.StocktakingId)}/lists/${StocktakingListId__get_unwrap(state.StocktakingList.Id)}/tools/${ToolId__get_unwrap(toolId)}/result`, toString(0, Auto_generateBoxedEncoder_437914C6(CreateStocktakingListToolResultDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(5, [Item_1]))))];
                    }
                }
            }
        }
        case 4: {
            const response_3 = msg.fields[0];
            if (response_3.tag === 1) {
                return [new State(state.StocktakingId, state.StocktakingList, state.StocktakingResultType, state.ToolWithQuantityQuantity, state.ConsumableQuantity, state.IsToolFound, FormState__setValidationResponse_5219762A(state.FormState, response_3.fields[0]), state.OnClose, state.ConsumableUnit), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.StocktakingId, state.StocktakingList, state.StocktakingResultType, state.ToolWithQuantityQuantity, state.ConsumableQuantity, state.IsToolFound, FormState_get_empty(), state.OnClose, state.ConsumableUnit), Cmd_ofEffect((_arg) => {
                    state.OnClose();
                })];
            }
        }
        case 5:
            return [state, Cmd_none()];
        default:
            return [new State(state.StocktakingId, state.StocktakingList, state.StocktakingResultType, state.ToolWithQuantityQuantity, state.ConsumableQuantity, msg.fields[0], state.FormState, state.OnClose, state.ConsumableUnit), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(singleton_1((elems = toList(delay(() => {
        let Label, Value, Label_1, matchValue_2, Value_1;
        const matchValue = props.StocktakingResultType;
        if (matchValue != null) {
            const matchValue_1 = matchValue;
            return (matchValue_1.tag === 1) ? singleton(createElement(NumberInput, (Label = t("general.quantity"), (Value = map((value) => value, props.ToolWithQuantityQuantity), {
                ComponentState: props.ComponentState,
                Label: unwrap(Label),
                OnChange: (arg_2) => {
                    props.Dispatch(new Msg(1, [map((value_1) => ~~value_1, arg_2)]));
                },
                TestId: "stocktaking-result-int-quantity",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "quantity")),
                Value: unwrap(Value),
            })))) : ((matchValue_1.tag === 2) ? singleton(createElement(NumberInput, (Label_1 = ((matchValue_2 = props.ConsumableUnit, (matchValue_2 == null) ? t("general.quantity") : t(Helper_unitToTranslationKey(matchValue_2)))), (Value_1 = map((value_2) => value_2, props.ConsumableQuantity), {
                ComponentState: props.ComponentState,
                Label: unwrap(Label_1),
                OnChange: (arg_3) => {
                    props.Dispatch(new Msg(2, [arg_3]));
                },
                TestId: "stocktaking-result-float-quantity",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "quantity")),
                Value: unwrap(Value_1),
            })))) : singleton(createElement(Toggle, {
                Checked: props.IsToolFound,
                LeftLabel: t("stocktaking.not_found"),
                OnChange: (arg) => {
                    props.Dispatch(new Msg(0, [arg]));
                },
                RightLabel: t("stocktaking.found"),
            })));
        }
        else {
            return empty();
        }
    })), ["children", reactApi.Children.toArray(Array.from(elems))]))));
}

export function ConfirmStocktakingResultDialog(props) {
    let Title;
    let patternInput;
    const init_1 = init(props.Stocktaking, props.StocktakingList, props.StocktakingResultType, props.OnClose);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.StocktakingResultType, props.Stocktaking]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const t = useTranslation()[0];
    const componentState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("stocktaking.add_result"), {
        Body: singleton_1(createElement(DialogBody, {
            ComponentState: componentState,
            ConsumableQuantity: unwrap(state_1.ConsumableQuantity),
            ConsumableUnit: unwrap(state_1.ConsumableUnit),
            Dispatch: dispatch,
            FormState: state_1.FormState,
            IsToolFound: state_1.IsToolFound,
            Stocktaking: props.Stocktaking,
            StocktakingResultType: unwrap(props.StocktakingResultType),
            ToolWithQuantityQuantity: unwrap(state_1.ToolWithQuantityQuantity),
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-select-users-dialog",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(3, []));
            },
            TestId: "save-selected-users",
            Variant: "blueButton",
        })]),
        IsOpen: props.StocktakingResultType != null,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

