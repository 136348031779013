import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, list_type, lambda_type, unit_type, bool_type, union_type, class_type, float64_type, option_type, anonRecord_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { UpdateConsumableStockDto, ConsumableId_$reflection, UpdateConsumableStockDto_$reflection, PostResponse$1_$reflection, UpdateStockResult_$reflection } from "../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton, filter, map, tryFind, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { unwrapStorageId } from "../../Shared/Helper.js";
import { createObj, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Cmd_successToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Select } from "../../Components/Select.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { NumberInput } from "../../Components/Input.js";
import { TextArea } from "../../Components/TextArea.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSelectedStorage", "SetComment", "SetQuantity", "UpdateStockResultResponse", "UpdateStock", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Consumables.StockChangeDialog.Msg", [], Msg, () => [[["Item", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))]], [["Item", string_type]], [["Item", option_type(float64_type)]], [["Item", PostResponse$1_$reflection(UpdateStockResult_$reflection())]], [], [["Item", class_type("System.Exception")]]]);
}

function postStockChangeCmd(consumableId, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(UpdateStockResult_$reflection())));
    })))), [`/api/consumables/${consumableId.fields[0]}/stock`, toString(0, Auto_generateBoxedEncoder_437914C6(UpdateConsumableStockDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(5, [Item_1])));
}

class State extends Record {
    constructor(IsLoading, SuccessCallback, OnClose, Dto, ConsumableId, IsInvalid, IsCommandInvalid, IsStockNegative, Quantity, Storages, SelectedStorage, IsStorageInvalid) {
        super();
        this.IsLoading = IsLoading;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Dto = Dto;
        this.ConsumableId = ConsumableId;
        this.IsInvalid = IsInvalid;
        this.IsCommandInvalid = IsCommandInvalid;
        this.IsStockNegative = IsStockNegative;
        this.Quantity = Quantity;
        this.Storages = Storages;
        this.SelectedStorage = SelectedStorage;
        this.IsStorageInvalid = IsStorageInvalid;
    }
}

function State_$reflection() {
    return record_type("Consumables.StockChangeDialog.State", [], State, () => [["IsLoading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Dto", UpdateConsumableStockDto_$reflection()], ["ConsumableId", ConsumableId_$reflection()], ["IsInvalid", bool_type], ["IsCommandInvalid", bool_type], ["IsStockNegative", bool_type], ["Quantity", option_type(float64_type)], ["Storages", list_type(Storage_$reflection())], ["SelectedStorage", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))], ["IsStorageInvalid", bool_type]]);
}

function init(props) {
    let matchValue, matchValue_1, storageId_1, matchValue_2, storage;
    return [new State(false, props.SuccessCallback, props.OnClose, new UpdateConsumableStockDto((matchValue = props.StorageId, (matchValue == null) ? "" : unwrapStorageId(matchValue)), 0, ""), props.ConsumableId, false, false, false, undefined, props.Storages, (matchValue_1 = props.StorageId, (matchValue_1 == null) ? undefined : ((storageId_1 = matchValue_1, (matchValue_2 = tryFind((s) => equals(s.Id, storageId_1), props.Storages), (matchValue_2 == null) ? undefined : ((storage = matchValue_2, {
        label: storage.Name,
        value: storage,
    })))))), false), Cmd_none()];
}

function update(msg, state) {
    let bind$0040;
    switch (msg.tag) {
        case 1:
            return [new State(state.IsLoading, state.SuccessCallback, state.OnClose, (bind$0040 = state.Dto, new UpdateConsumableStockDto(bind$0040.StorageId, bind$0040.Quantity, msg.fields[0])), state.ConsumableId, state.IsInvalid, state.IsCommandInvalid, state.IsStockNegative, state.Quantity, state.Storages, state.SelectedStorage, state.IsStorageInvalid), Cmd_none()];
        case 2:
            return [new State(state.IsLoading, state.SuccessCallback, state.OnClose, state.Dto, state.ConsumableId, state.IsInvalid, state.IsCommandInvalid, state.IsStockNegative, msg.fields[0], state.Storages, state.SelectedStorage, state.IsStorageInvalid), Cmd_none()];
        case 3:
            return [new State(false, state.SuccessCallback, state.OnClose, state.Dto, state.ConsumableId, state.IsInvalid, state.IsCommandInvalid, state.IsStockNegative, state.Quantity, state.Storages, state.SelectedStorage, state.IsStorageInvalid), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            }), Cmd_successToast("consumable.dialog_stock_success")]))];
        case 4: {
            const matchValue = state.SelectedStorage;
            if (matchValue == null) {
                return [new State(false, state.SuccessCallback, state.OnClose, state.Dto, state.ConsumableId, state.IsInvalid, state.IsCommandInvalid, state.IsStockNegative, state.Quantity, state.Storages, state.SelectedStorage, true), Cmd_none()];
            }
            else {
                const storage = matchValue;
                const matchValue_1 = state.Quantity;
                const matchValue_2 = state.Dto.Comment !== "";
                if (matchValue_1 == null) {
                    return [new State(state.IsLoading, state.SuccessCallback, state.OnClose, state.Dto, state.ConsumableId, true, state.IsCommandInvalid, state.IsStockNegative, state.Quantity, state.Storages, state.SelectedStorage, false), Cmd_none()];
                }
                else if (matchValue_2) {
                    const qty = matchValue_1;
                    return [new State(true, state.SuccessCallback, state.OnClose, state.Dto, state.ConsumableId, false, false, state.IsStockNegative, state.Quantity, state.Storages, state.SelectedStorage, false), postStockChangeCmd(state.ConsumableId, new UpdateConsumableStockDto(unwrapStorageId(storage.value.Id), qty, state.Dto.Comment))];
                }
                else {
                    return [new State(state.IsLoading, state.SuccessCallback, state.OnClose, state.Dto, state.ConsumableId, state.IsInvalid, true, state.IsStockNegative, state.Quantity, state.Storages, state.SelectedStorage, false), Cmd_none()];
                }
            }
        }
        case 5:
            return [new State(false, state.SuccessCallback, state.OnClose, state.Dto, state.ConsumableId, state.IsInvalid, state.IsCommandInvalid, state.IsStockNegative, state.Quantity, state.Storages, state.SelectedStorage, state.IsStorageInvalid), Cmd_none()];
        default:
            return [new State(state.IsLoading, state.SuccessCallback, state.OnClose, state.Dto, state.ConsumableId, state.IsInvalid, state.IsCommandInvalid, state.IsStockNegative, state.Quantity, state.Storages, msg.fields[0], state.IsStorageInvalid), Cmd_none()];
    }
}

function DialogBody(props) {
    let matchValue, storage, elems;
    const t = useTranslation()[0];
    const storages = map((storage_1) => ({
        label: storage_1.Name,
        value: storage_1,
    }), (matchValue = props.SelectedStorage, (matchValue == null) ? props.Storages : ((storage = matchValue, filter((s) => !equals(s.Id, storage.value.Id), props.Storages)))));
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = [createElement(Select, {
        ComponentState: props.FormState,
        IsClearable: false,
        Label: t("tool.switch_storage_target"),
        NoOptionsMessage: "",
        OnChange: props.SetSelectedStorage,
        Options: storages,
        PlaceholderKey: "",
        TestId: "",
        ValidationMessage: unwrap(props.IsStorageInvalid ? "" : undefined),
        Value: unwrap(props.SelectedStorage),
    }), createElement(NumberInput, {
        ComponentState: "enabled",
        Label: t("general.quantity_3"),
        OnChange: props.SetQuantity,
        TestId: "",
        ValidationMessage: unwrap(props.IsQuantityInvalid ? t("consumable.dialog_stock_validation_quantity") : (props.IsStockNegative ? t("tool.change_in_stock_negative_msg") : undefined)),
        Value: unwrap(props.Quantity),
    }), createElement(TextArea, {
        ComponentState: props.FormState,
        Label: t("general.comment"),
        OnChange: props.SetComment,
        TestId: "",
        ValidationMessage: unwrap(props.IsCommentInvalid ? t("consumable.dialog_stock_validation") : undefined),
        Value: props.Comment,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function ChangeStockDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        ConsumableId: props.ConsumableId,
        OnClose: props.OnClose,
        StorageId: unwrap(props.StorageId),
        Storages: props.Storages,
        SuccessCallback: props.SuccessCallback,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const formState = state_1.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("consumable.change_in_stock"), {
        Body: singleton(createElement(DialogBody, {
            Comment: state_1.Dto.Comment,
            FormState: formState,
            IsCommentInvalid: state_1.IsCommandInvalid,
            IsQuantityInvalid: state_1.IsInvalid,
            IsStockNegative: state_1.IsInvalid,
            IsStorageInvalid: state_1.IsStorageInvalid,
            Quantity: unwrap(state_1.Quantity),
            SelectedStorage: unwrap(state_1.SelectedStorage),
            SetComment: (value) => {
                dispatch(new Msg(1, [value]));
            },
            SetQuantity: (value_1) => {
                dispatch(new Msg(2, [value_1]));
            },
            SetSelectedStorage: (value_2) => {
                dispatch(new Msg(0, [value_2]));
            },
            Storages: state_1.Storages,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: formState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-stock-change-dialog-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: formState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(4, []));
            },
            TestId: "save-stock-change-dialog-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

