import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { anonRecord_type, list_type, option_type, union_type, record_type, bool_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ClientApiResponse$1_$reflection, EmptyResponse_$reflection, ImageDeletedResponse_$reflection, CustomerConfiguration_$reflection, DocumentDetail_$reflection, Customer_$reflection, TokenDataDto_$reflection, RequestedValue$1_$reflection, DocumentId_$reflection, ProblemReportId_$reflection } from "../../Shared/Shared.js";
import { DataTransfer_ProblemReportTool_$reflection, DataTransfer_CreateOrUpdateSet_$reflection, DataTransfer_AssignedTool_$reflection, DataTransfer_DetailSet_$reflection } from "../../Shared/Set.js";
import { UserConfiguration_$reflection } from "../../Shared/User.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { OverviewNotification_$reflection } from "../../Shared/Notification.js";
import { Message_$reflection } from "../../Widgets/ChatWidget.js";

export class ToolReminderRow extends Record {
    constructor(Id, Date$, Title, Description, IsConfirmed, IsConfirmedAsString, IsInterval, Interval, ConfirmationComment) {
        super();
        this.Id = Id;
        this.Date = Date$;
        this.Title = Title;
        this.Description = Description;
        this.IsConfirmed = IsConfirmed;
        this.IsConfirmedAsString = IsConfirmedAsString;
        this.IsInterval = IsInterval;
        this.Interval = Interval;
        this.ConfirmationComment = ConfirmationComment;
    }
}

export function ToolReminderRow_$reflection() {
    return record_type("Sets.Detail.Types.ToolReminderRow", [], ToolReminderRow, () => [["Id", string_type], ["Date", class_type("System.DateTime")], ["Title", string_type], ["Description", string_type], ["IsConfirmed", bool_type], ["IsConfirmedAsString", string_type], ["IsInterval", bool_type], ["Interval", string_type], ["ConfirmationComment", string_type]]);
}

export class ProblemReportRow extends Record {
    constructor(Id, Solver, Sender, State, Message, Timestamp) {
        super();
        this.Id = Id;
        this.Solver = Solver;
        this.Sender = Sender;
        this.State = State;
        this.Message = Message;
        this.Timestamp = Timestamp;
    }
}

export function ProblemReportRow_$reflection() {
    return record_type("Sets.Detail.Types.ProblemReportRow", [], ProblemReportRow, () => [["Id", ProblemReportId_$reflection()], ["Solver", string_type], ["Sender", string_type], ["State", string_type], ["Message", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class DocumentRow extends Record {
    constructor(UploadDate, Name, Description, IsVisibleForUserRoleAsString, Id) {
        super();
        this.UploadDate = UploadDate;
        this.Name = Name;
        this.Description = Description;
        this.IsVisibleForUserRoleAsString = IsVisibleForUserRoleAsString;
        this.Id = Id;
    }
}

export function DocumentRow_$reflection() {
    return record_type("Sets.Detail.Types.DocumentRow", [], DocumentRow, () => [["UploadDate", class_type("System.DateTime")], ["Name", string_type], ["Description", string_type], ["IsVisibleForUserRoleAsString", string_type], ["Id", DocumentId_$reflection()]]);
}

export class PlanningTypeId extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ReservationId", "ReminderId"];
    }
}

export function PlanningTypeId_$reflection() {
    return union_type("Sets.Detail.Types.PlanningTypeId", [], PlanningTypeId, () => [[["Item", string_type]], [["Item", string_type]]]);
}

export class Planning extends Record {
    constructor(Type, StartDate, EndDate, ReservationName, ReceiverName, Description, PlanningTypeId) {
        super();
        this.Type = Type;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.ReservationName = ReservationName;
        this.ReceiverName = ReceiverName;
        this.Description = Description;
        this.PlanningTypeId = PlanningTypeId;
    }
}

export function Planning_$reflection() {
    return record_type("Sets.Detail.Types.Planning", [], Planning, () => [["Type", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", option_type(class_type("System.DateTime"))], ["ReservationName", string_type], ["ReceiverName", string_type], ["Description", string_type], ["PlanningTypeId", PlanningTypeId_$reflection()]]);
}

export class Filter extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ReservationsInDateRange", "FreeInDateRange", "ReservationNameFilter"];
    }
}

export function Filter_$reflection() {
    return union_type("Sets.Detail.Types.Filter", [], Filter, () => [[["startDate", class_type("System.DateTime")], ["endDate", class_type("System.DateTime")]], [["startDate", class_type("System.DateTime")], ["endDate", class_type("System.DateTime")]], [["value", string_type]]]);
}

export class State extends Record {
    constructor(Set$, Tools, UserConfiguration, UserData, Customer, ImageViewerOpen, QuickOverviewDialog, Documents, CustomerConfiguration, Storages, BaseSet, ProblemReports, Notifications) {
        super();
        this.Set = Set$;
        this.Tools = Tools;
        this.UserConfiguration = UserConfiguration;
        this.UserData = UserData;
        this.Customer = Customer;
        this.ImageViewerOpen = ImageViewerOpen;
        this.QuickOverviewDialog = QuickOverviewDialog;
        this.Documents = Documents;
        this.CustomerConfiguration = CustomerConfiguration;
        this.Storages = Storages;
        this.BaseSet = BaseSet;
        this.ProblemReports = ProblemReports;
        this.Notifications = Notifications;
    }
}

export function State_$reflection() {
    return record_type("Sets.Detail.Types.State", [], State, () => [["Set", RequestedValue$1_$reflection(DataTransfer_DetailSet_$reflection())], ["Tools", RequestedValue$1_$reflection(list_type(DataTransfer_AssignedTool_$reflection()))], ["UserConfiguration", RequestedValue$1_$reflection(UserConfiguration_$reflection())], ["UserData", TokenDataDto_$reflection()], ["Customer", option_type(Customer_$reflection())], ["ImageViewerOpen", bool_type], ["QuickOverviewDialog", anonRecord_type(["IsLoading", bool_type], ["IsOpen", bool_type])], ["Documents", RequestedValue$1_$reflection(list_type(DocumentDetail_$reflection()))], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["BaseSet", RequestedValue$1_$reflection(DataTransfer_CreateOrUpdateSet_$reflection())], ["ProblemReports", RequestedValue$1_$reflection(list_type(DataTransfer_ProblemReportTool_$reflection()))], ["Notifications", RequestedValue$1_$reflection(list_type(OverviewNotification_$reflection()))]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateQuickOverviewItems", "SetIsQuickOverviewDialogOpen", "SetFetched", "AssignQrCode", "DeleteQrCodes", "SaveNotice", "NoticeSaved", "UpdateNotice", "UploadImage", "DeleteImage", "ImageDeleted", "ImageSaved", "BlobReceived", "AssignToolIds", "RemoveToolIds", "UserConfigurationUpdated", "UserConfigurationFetched", "FetchDocuments", "DownloadFile", "DocumentDownloaded", "BaseSetFetched", "SetUpdated", "QrCodesAdded", "QrCodesRemoved", "DocumentsFetched", "ToolsFetched", "CustomerConfigurationFetched", "StoragesFetched", "ProblemReportsFetched", "FetchSet", "FetchTools", "FetchNotifications", "NotificationsFetched", "SetDeleted", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Sets.Detail.Types.Msg", [], Msg, () => [[["Item", list_type(string_type)]], [], [["Item", DataTransfer_DetailSet_$reflection()]], [["Item", string_type]], [["Item", list_type(string_type)]], [["Item1", string_type], ["Item2", bool_type]], [["Item", string_type]], [["Item", Message_$reflection()]], [["Item", class_type("Browser.Types.File", undefined)]], [], [["Item", ImageDeletedResponse_$reflection()]], [["Item", string_type]], [["Item1", class_type("Browser.Types.Blob", undefined)], ["Item2", string_type]], [["toolIds", list_type(string_type)]], [["toolIds", list_type(string_type)]], [["Item", EmptyResponse_$reflection()]], [["Item", UserConfiguration_$reflection()]], [], [["Item1", DocumentId_$reflection()], ["fileName", string_type]], [["Item1", class_type("Fetch.Types.Response")], ["Item2", string_type]], [["Item", DataTransfer_CreateOrUpdateSet_$reflection()]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", list_type(DocumentDetail_$reflection())]], [["Item", list_type(DataTransfer_AssignedTool_$reflection())]], [["Item", CustomerConfiguration_$reflection()]], [["Item", list_type(Storage_$reflection())]], [["Item", list_type(DataTransfer_ProblemReportTool_$reflection())]], [], [], [], [["Item", list_type(OverviewNotification_$reflection())]], [], [["Item", class_type("System.Exception")]]]);
}

