import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { DispolistElement_$reflection, Dispolist_$reflection, DispolistDeleteResponse_$reflection } from "../../Shared/Dispolist.js";
import { record_type, union_type, class_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { TokenDataDto_$reflection, RequestedValue$1_$reflection } from "../../Shared/Shared.js";

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DeleteDispolist", "DispolistDeleted", "DispolistFetched", "ToolsFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("InventoryLists.Detail.Types.Msg", [], Msg, () => [[], [["Item", DispolistDeleteResponse_$reflection()]], [["Item", Dispolist_$reflection()]], [["Item", list_type(DispolistElement_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class State extends Record {
    constructor(Dispolist, UserData, Elements) {
        super();
        this.Dispolist = Dispolist;
        this.UserData = UserData;
        this.Elements = Elements;
    }
}

export function State_$reflection() {
    return record_type("InventoryLists.Detail.Types.State", [], State, () => [["Dispolist", RequestedValue$1_$reflection(Dispolist_$reflection())], ["UserData", TokenDataDto_$reflection()], ["Elements", RequestedValue$1_$reflection(list_type(DispolistElement_$reflection()))]]);
}

