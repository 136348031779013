import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Button } from "../../Components/Button.js";
import { RouterModule_encodeParts, RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { singleton, empty, toArray, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { TableContainer } from "../../Widgets/Container.js";
import { OnTableRowClick$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { StocktakingState__ToI18nString } from "../../Shared/Stocktaking.js";
import { StocktakingId__get_unwrap } from "../../Shared/Shared.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, initOverviewStocktaking } from "./State.js";
import { Skeleton } from "../../Components/Skeleton.js";

export function OverviewTable(props) {
    let elems_2, elems_1, elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "grow"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "mb-3", "justify-items-end"])], (elems_1 = [createElement("div", {
        className: join(" ", ["grow"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["justify-self-end"])], (elems = [createElement(Button, {
        ComponentState: "enabled",
        Label: t("stocktaking.new_stocktaking"),
        OnClick: () => {
            RouterModule_nav(ofArray(["stocktaking", "new"]), 1, 1);
        },
        TestId: "stocktaking-overview-new-stocktaking-button",
        Variant: "primary",
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement(TableContainer, {
        Content: createElement(Table, (TableRows = toArray(props.Stocktakings), {
            ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "text", "text"), new ColumnDefinition$1("description", "", t("general.description"), (dto_1) => (new CellContent(0, [dto_1.Description])), "text", "text"), new ColumnDefinition$1("startDate", "", t("general.start_date"), (dto_2) => (new CellContent(5, [dto_2.StartDate])), "dateRange", "date"), new ColumnDefinition$1("endDate", "", t("general.end_date"), (dto_3) => (new CellContent(5, [dto_3.EndDate])), "dateRange", "date"), new ColumnDefinition$1("state", "", t("general.state"), (dto_4) => (new CellContent(0, [t(StocktakingState__ToI18nString(dto_4.State))])), "select", "text")],
            Controls: empty(),
            Options: {
                BorderStyle: "full",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultColumnFilter: singleton({
                    id: "state",
                    value: [t("stocktaking.state.planned"), t("stocktaking.state.fixed"), t("stocktaking.state.started"), t("stocktaking.state.done")],
                }),
                DefaultSortColumn: ["startDate", "desc"],
                GlobalSearch: false,
                OnRowClick: new OnTableRowClick$1(1, [(stocktaking) => RouterModule_encodeParts(ofArray(["stocktaking", StocktakingId__get_unwrap(stocktaking.Id)]), 1)]),
                RowSelection: "disabled",
            },
            TableRows: TableRows,
        })),
    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function StocktakingOverview() {
    const matchValue = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => initOverviewStocktaking, update_1, (_arg, _arg_1) => {
    }), undefined, undefined)[0].Stocktakings;
    if (matchValue.tag === 0) {
        return createElement(Skeleton, {
            Variant: "table",
        });
    }
    else {
        return createElement(OverviewTable, {
            Stocktakings: matchValue.fields[0],
        });
    }
}

