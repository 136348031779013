import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import Check_Circle from "../../../public/assets/icons/Check_Circle.svg";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { SectionHeader } from "../../Components/Text.js";
import Delete_Circle from "../../../public/assets/icons/Delete_Circle.svg";

export function AssignEmailNotificationSuccessMessage() {
    let elems_3, elems_2, elems, elems_1;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-full", "flex", "justify-center", "content-center", "bg-background"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["grow", "max-w-lg", "my-auto", "p-6", "flex", "flex-col", "border", "border-border", "rounded-md", "bg-[#FFFFFF]"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["mx-auto", "w-24", "h-24", "mb-4", "text-icon-green"])], (elems = [Check_Circle()], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["mx-auto", "mt-8"])], (elems_1 = [createElement(SectionHeader, {
        Text: patternInput[0]("notification.email_assignment_success_title"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function AssignEmailNotificationRejectSuccessMessage() {
    let elems_3, elems_2, elems, elems_1;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-full", "flex", "justify-center", "content-center", "bg-background"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["grow", "max-w-lg", "my-auto", "p-6", "flex", "flex-col", "border", "border-border", "rounded-md", "bg-[#FFFFFF]"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["mx-auto", "w-24", "h-24", "mb-4", "text-icon-red"])], (elems = [Delete_Circle()], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["mx-auto", "mt-8"])], (elems_1 = [createElement(SectionHeader, {
        Text: patternInput[0]("notification.email_assignment_reject_success_title"),
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

