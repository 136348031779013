import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, bool_type, union_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { NoticeId_$reflection, SetId_$reflection } from "../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { deleteNoticeCmd } from "../../Requests/Set.js";
import { Cmd_errorToast } from "../../Components/Toast.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ConfirmationDialog } from "../../Components/Dialog.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DeleteNotice", "NoticeDeletedResponse", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Sets.Dialog.DeleteNoticeDialog.Msg", [], Msg, () => [[], [["Item", string_type]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(SetId, NoticeId, IsLoading, SuccessCallback, OnClose) {
        super();
        this.SetId = SetId;
        this.NoticeId = NoticeId;
        this.IsLoading = IsLoading;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
    }
}

function State_$reflection() {
    return record_type("Sets.Dialog.DeleteNoticeDialog.State", [], State, () => [["SetId", SetId_$reflection()], ["NoticeId", NoticeId_$reflection()], ["IsLoading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)]]);
}

function init(props) {
    return [new State(props.SetId, props.NoticeId, false, props.SuccessCallback, props.OnClose), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 0:
            return [new State(state.SetId, state.NoticeId, true, state.SuccessCallback, state.OnClose), deleteNoticeCmd(state.SetId, state.NoticeId, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])))];
        case 2:
            return [new State(state.SetId, state.NoticeId, false, state.SuccessCallback, state.OnClose), Cmd_errorToast("general.a_error_occured")];
        default:
            return [new State(state.SetId, state.NoticeId, false, state.SuccessCallback, state.OnClose), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            })]))];
    }
}

export function OpenNoticeDeleteDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        NoticeId: props.NoticeId,
        OnClose: props.OnClose,
        SetId: props.SetId,
        SuccessCallback: props.SuccessCallback,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    return createElement(ConfirmationDialog, (Title = t("general.delete"), {
        Description: t("notice.delete_notice_query"),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        OnConfirm: patternInput_1[0].IsLoading ? (() => {
        }) : (() => {
            patternInput_1[1](new Msg(0, []));
        }),
        Title: Title,
    }));
}

