import { Record, Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, anonRecord_type, string_type, option_type, class_type, union_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class DateType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Reservation", "Reminder"];
    }
}

export function DateType_$reflection() {
    return union_type("Shared.Calendar.DateType", [], DateType, () => [[], []]);
}

export class CalendarDto extends Record {
    constructor(StartDate, EndDate, DateType, Receiver, Title, Description, ReservationSubject) {
        super();
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.DateType = DateType;
        this.Receiver = Receiver;
        this.Title = Title;
        this.Description = Description;
        this.ReservationSubject = ReservationSubject;
    }
}

export function CalendarDto_$reflection() {
    return record_type("Shared.Calendar.CalendarDto", [], CalendarDto, () => [["StartDate", class_type("System.DateTime")], ["EndDate", option_type(class_type("System.DateTime"))], ["DateType", DateType_$reflection()], ["Receiver", anonRecord_type(["Id", string_type], ["Name", string_type])], ["Title", string_type], ["Description", string_type], ["ReservationSubject", anonRecord_type(["Id", string_type], ["Name", string_type], ["SubjectType", string_type])]]);
}

