import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { createObj } from "./fable_modules/fable-library-js.4.19.2/Util.js";
import { split, join } from "./fable_modules/fable-library-js.4.19.2/String.js";
import { reactApi } from "./fable_modules/Feliz.2.8.0/Interop.fs.js";
import { TextValue, PageHeader } from "./Components/Text.js";
import { ofArray } from "./fable_modules/fable-library-js.4.19.2/List.js";
import { TextButton } from "./Components/Button.js";
import { RouterModule_nav } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { item } from "./fable_modules/fable-library-js.4.19.2/Array.js";
import { Variant as Variant_2, debugActive } from "./Variant.js";
import Browser_Warning from "../public/assets/icons/Browser_Warning.svg";
import { useEffectOnce } from "./fable_modules/Feliz.2.8.0/Internal.fs.js";
import { defaultOf } from "./fable_modules/fable-library-js.4.19.2/Util.js";
import Browser_Lock from "../public/assets/icons/Browser_Lock.svg";
import Browser_Error_404 from "../public/assets/icons/Browser_Error_404.svg";
import { captureException } from "./Sentry.js";

export function ErrorContainer(props) {
    let elems_5, elems_4, elems_1, elems_2, elems_3;
    const t = useTranslation()[0];
    const auth = useAuth();
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-full", "flex", "justify-center", "content-center", "bg-background"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["grow", "max-w-lg", "my-auto", "p-6", "flex", "flex-col", "border", "border-border", "rounded-md", "bg-[#FFFFFF]"])], (elems_4 = [createElement("div", {
        className: join(" ", ["mx-auto", "w-24", "h-24", "mb-4"]),
        children: reactApi.Children.toArray([props.Icon]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["mx-auto", "mb-5", "mt-8"])], (elems_1 = [createElement(PageHeader, {
        Text: props.ErrorTitle,
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["mx-auto", "mb-5"])], (elems_2 = [createElement(TextValue, {
        FontWeight: "normal",
        TestId: "",
        Text: props.ErrorText,
    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "items-center", "gap-5"])], (elems_3 = [createElement(TextButton, {
        ComponentState: "enabled",
        Label: t("error.to_dashboard"),
        OnClick: () => {
            RouterModule_nav(ofArray(["dashboard"]), 1, 1);
        },
        TestId: "",
        Variant: "blueButton",
    }), createElement(TextButton, {
        ComponentState: "enabled",
        Label: t("navbar.logout"),
        OnClick: () => {
            const subdomain = item(0, split(window.location.host, ["."], undefined, 0));
            auth.removeUser();
            auth.signoutRedirect({
                post_logout_redirect_uri: debugActive ? "http://localhost:8080/#/" : ((Variant_2.tag === 1) ? (`https://${subdomain}.inventory-one.com/#/`) : ((Variant_2.tag === 2) ? (`https://${subdomain}.eagletrackmate.com/#/`) : (`https://${subdomain}.meinwerkzeugkoffer.de/#/`))),
            });
        },
        TestId: "",
        Variant: "redButton",
    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function BadRequest() {
    let Icon, ErrorTitle;
    const t = useTranslation()[0];
    return createElement(ErrorContainer, (Icon = Browser_Warning(), (ErrorTitle = t("error.unexpected_error_title"), {
        ErrorText: t("error.unexpected_error_description"),
        ErrorTitle: ErrorTitle,
        Icon: Icon,
    })));
}

export function Unauthorized() {
    const auth = useAuth();
    useEffectOnce(() => {
        auth.signinRedirect();
    });
    return defaultOf();
}

export function Forbidden() {
    let Icon, ErrorTitle;
    const t = useTranslation()[0];
    return createElement(ErrorContainer, (Icon = Browser_Lock(), (ErrorTitle = t("error.forbidden_title"), {
        ErrorText: t("error.forbidden_description"),
        ErrorTitle: ErrorTitle,
        Icon: Icon,
    })));
}

export function NotFound() {
    let Icon, ErrorTitle;
    const t = useTranslation()[0];
    return createElement(ErrorContainer, (Icon = Browser_Error_404(), (ErrorTitle = t("error.not_found_title"), {
        ErrorText: t("error.not_found_description"),
        ErrorTitle: ErrorTitle,
        Icon: Icon,
    })));
}

export function InternalServerError() {
    let Icon, ErrorTitle;
    const t = useTranslation()[0];
    return createElement(ErrorContainer, (Icon = Browser_Warning(), (ErrorTitle = t("error.unexpected_error_title"), {
        ErrorText: t("error.unexpected_error_description"),
        ErrorTitle: ErrorTitle,
        Icon: Icon,
    })));
}

export function InvalidAuthError() {
    let Icon, ErrorTitle;
    const t = useTranslation()[0];
    useEffectOnce(() => {
        captureException(new Error("Invalid auth error occured. - User is authenticated but token is invalid"));
    });
    return createElement(ErrorContainer, (Icon = Browser_Warning(), (ErrorTitle = t("error.unexpected_error_title"), {
        ErrorText: t("error.unexpected_error_description"),
        ErrorTitle: ErrorTitle,
        Icon: Icon,
    })));
}

