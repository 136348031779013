import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ofArray, map } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { TextValue } from "../Components/Text.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { Input } from "../Components/Input.js";
import { DayWeekMonthYearInput } from "./DayWeekMonthYearInput.js";

export function AdditionalReminderWidget(props) {
    let elems_5;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4", "w-[30rem]"])], (elems_5 = toList(delay(() => map((additionalReminder) => {
        let elems_4, elems_1, elems, elems_3, elems_2;
        return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["mr-1.5"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-1.5"])], (elems = [createElement(TextValue, {
            FontWeight: "bold",
            TestId: "",
            Text: t("reminder_dialog.note_text"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(Input, {
            ComponentState: props.ComponentState,
            OnChange: (value_5) => {
                props.SetName([additionalReminder.Id, value_5]);
            },
            TestId: "",
            Value: additionalReminder.Name,
        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-1.5"])], (elems_2 = [createElement(TextValue, {
            FontWeight: "bold",
            TestId: "",
            Text: t("reminder_dialog.note_time"),
        })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement(DayWeekMonthYearInput, {
            ComponentState: props.ComponentState,
            InitialInterval: additionalReminder.Interval.Interval,
            IsBefore: true,
            SetValue: (tupledArg) => {
                props.SetInterval([additionalReminder.Id, tupledArg[0], tupledArg[1]]);
            },
            Value: additionalReminder.Interval.Value,
        })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
    }, props.AdditionlReminders))), ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
}

