import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { bool_type, list_type, union_type, int32_type, option_type, record_type, float64_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class ToAssignConsumable extends Record {
    constructor(ConsumableId, Quantity, CommissionNumber, StorageId) {
        super();
        this.ConsumableId = ConsumableId;
        this.Quantity = Quantity;
        this.CommissionNumber = CommissionNumber;
        this.StorageId = StorageId;
    }
}

export function ToAssignConsumable_$reflection() {
    return record_type("Shared.DevicesBulk.ToAssignConsumable", [], ToAssignConsumable, () => [["ConsumableId", string_type], ["Quantity", float64_type], ["CommissionNumber", string_type], ["StorageId", string_type]]);
}

export class ToAssignTool extends Record {
    constructor(ToolId, PlannedReturnDate, StorageId) {
        super();
        this.ToolId = ToolId;
        this.PlannedReturnDate = PlannedReturnDate;
        this.StorageId = StorageId;
    }
}

export function ToAssignTool_$reflection() {
    return record_type("Shared.DevicesBulk.ToAssignTool", [], ToAssignTool, () => [["ToolId", string_type], ["PlannedReturnDate", option_type(string_type)], ["StorageId", string_type]]);
}

export class ToAssignToolWithQuantity extends Record {
    constructor(ToolId, Quantity, StorageId) {
        super();
        this.ToolId = ToolId;
        this.Quantity = Quantity;
        this.StorageId = StorageId;
    }
}

export function ToAssignToolWithQuantity_$reflection() {
    return record_type("Shared.DevicesBulk.ToAssignToolWithQuantity", [], ToAssignToolWithQuantity, () => [["ToolId", string_type], ["Quantity", option_type(int32_type)], ["StorageId", string_type]]);
}

export class AssignBulkToolType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ToAssignTool", "ToAssignToolWithQuantity"];
    }
}

export function AssignBulkToolType_$reflection() {
    return union_type("Shared.DevicesBulk.AssignBulkToolType", [], AssignBulkToolType, () => [[["Item", ToAssignTool_$reflection()]], [["Item", ToAssignToolWithQuantity_$reflection()]]]);
}

export class AssignDevicesBulkWithoutNotification extends Record {
    constructor(ReceiverId, SenderId, Type, SignatureUrl, ToAssignToolsWithQuantity, ToAssignTools, ToAssignConsumables, Comment$) {
        super();
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.Type = Type;
        this.SignatureUrl = SignatureUrl;
        this.ToAssignToolsWithQuantity = ToAssignToolsWithQuantity;
        this.ToAssignTools = ToAssignTools;
        this.ToAssignConsumables = ToAssignConsumables;
        this.Comment = Comment$;
    }
}

export function AssignDevicesBulkWithoutNotification_$reflection() {
    return record_type("Shared.DevicesBulk.AssignDevicesBulkWithoutNotification", [], AssignDevicesBulkWithoutNotification, () => [["ReceiverId", string_type], ["SenderId", string_type], ["Type", string_type], ["SignatureUrl", option_type(string_type)], ["ToAssignToolsWithQuantity", list_type(ToAssignToolWithQuantity_$reflection())], ["ToAssignTools", list_type(ToAssignTool_$reflection())], ["ToAssignConsumables", list_type(ToAssignConsumable_$reflection())], ["Comment", option_type(string_type)]]);
}

export class AcceptDevicesBulkNotification extends Record {
    constructor(NotificationId, SignatureUrl, Comment$) {
        super();
        this.NotificationId = NotificationId;
        this.SignatureUrl = SignatureUrl;
        this.Comment = Comment$;
    }
}

export function AcceptDevicesBulkNotification_$reflection() {
    return record_type("Shared.DevicesBulk.AcceptDevicesBulkNotification", [], AcceptDevicesBulkNotification, () => [["NotificationId", string_type], ["SignatureUrl", option_type(string_type)], ["Comment", option_type(string_type)]]);
}

export class UnassignConsumable extends Record {
    constructor(ElementId, ConsumableId, Quantity, CommissionNumber, SenderId, StorageId) {
        super();
        this.ElementId = ElementId;
        this.ConsumableId = ConsumableId;
        this.Quantity = Quantity;
        this.CommissionNumber = CommissionNumber;
        this.SenderId = SenderId;
        this.StorageId = StorageId;
    }
}

export function UnassignConsumable_$reflection() {
    return record_type("Shared.DevicesBulk.UnassignConsumable", [], UnassignConsumable, () => [["ElementId", string_type], ["ConsumableId", string_type], ["Quantity", float64_type], ["CommissionNumber", string_type], ["SenderId", string_type], ["StorageId", string_type]]);
}

export class UnassignTool extends Record {
    constructor(ElementId, ToolId, PlannedReturnDate, SenderId, StorageId) {
        super();
        this.ElementId = ElementId;
        this.ToolId = ToolId;
        this.PlannedReturnDate = PlannedReturnDate;
        this.SenderId = SenderId;
        this.StorageId = StorageId;
    }
}

export function UnassignTool_$reflection() {
    return record_type("Shared.DevicesBulk.UnassignTool", [], UnassignTool, () => [["ElementId", string_type], ["ToolId", string_type], ["PlannedReturnDate", option_type(string_type)], ["SenderId", string_type], ["StorageId", string_type]]);
}

export class UnassignToolWithQuantity extends Record {
    constructor(ElementId, ToolId, Quantity, SenderId, StorageId) {
        super();
        this.ElementId = ElementId;
        this.ToolId = ToolId;
        this.Quantity = Quantity;
        this.SenderId = SenderId;
        this.StorageId = StorageId;
    }
}

export function UnassignToolWithQuantity_$reflection() {
    return record_type("Shared.DevicesBulk.UnassignToolWithQuantity", [], UnassignToolWithQuantity, () => [["ElementId", string_type], ["ToolId", string_type], ["Quantity", option_type(int32_type)], ["SenderId", string_type], ["StorageId", string_type]]);
}

export class UnassignBulkToolType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UnassignTool", "UnassignToolWithQuantity", "UnassignConsumable"];
    }
}

export function UnassignBulkToolType_$reflection() {
    return union_type("Shared.DevicesBulk.UnassignBulkToolType", [], UnassignBulkToolType, () => [[["Item", UnassignTool_$reflection()]], [["Item", UnassignToolWithQuantity_$reflection()]], [["Item", UnassignConsumable_$reflection()]]]);
}

export class UnassignDevicesBulkWithoutNotification extends Record {
    constructor(UnassignToolsWithQuantity, UnassignTools, UnassignConsumables, ReceiverId, Comment$) {
        super();
        this.UnassignToolsWithQuantity = UnassignToolsWithQuantity;
        this.UnassignTools = UnassignTools;
        this.UnassignConsumables = UnassignConsumables;
        this.ReceiverId = ReceiverId;
        this.Comment = Comment$;
    }
}

export function UnassignDevicesBulkWithoutNotification_$reflection() {
    return record_type("Shared.DevicesBulk.UnassignDevicesBulkWithoutNotification", [], UnassignDevicesBulkWithoutNotification, () => [["UnassignToolsWithQuantity", list_type(UnassignToolWithQuantity_$reflection())], ["UnassignTools", list_type(UnassignTool_$reflection())], ["UnassignConsumables", list_type(UnassignConsumable_$reflection())], ["ReceiverId", option_type(string_type)], ["Comment", option_type(string_type)]]);
}

export class AssignDevicesBulkDto extends Record {
    constructor(ReceiverId, SenderId, Type, WithSignature, ToAssignToolsWithQuantity, ToAssignTools, ToAssignConsumables, Comment$) {
        super();
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.Type = Type;
        this.WithSignature = WithSignature;
        this.ToAssignToolsWithQuantity = ToAssignToolsWithQuantity;
        this.ToAssignTools = ToAssignTools;
        this.ToAssignConsumables = ToAssignConsumables;
        this.Comment = Comment$;
    }
}

export function AssignDevicesBulkDto_$reflection() {
    return record_type("Shared.DevicesBulk.AssignDevicesBulkDto", [], AssignDevicesBulkDto, () => [["ReceiverId", string_type], ["SenderId", string_type], ["Type", string_type], ["WithSignature", bool_type], ["ToAssignToolsWithQuantity", list_type(ToAssignToolWithQuantity_$reflection())], ["ToAssignTools", list_type(ToAssignTool_$reflection())], ["ToAssignConsumables", list_type(ToAssignConsumable_$reflection())], ["Comment", option_type(string_type)]]);
}

export class AssignDispolistDto extends Record {
    constructor(ReceiverId, SenderId, Type, Comment$, WithSignature, DispolistId, ToAssignToolsWithQuantity, ToAssignTools, ToAssignConsumables) {
        super();
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.Type = Type;
        this.Comment = Comment$;
        this.WithSignature = WithSignature;
        this.DispolistId = DispolistId;
        this.ToAssignToolsWithQuantity = ToAssignToolsWithQuantity;
        this.ToAssignTools = ToAssignTools;
        this.ToAssignConsumables = ToAssignConsumables;
    }
}

export function AssignDispolistDto_$reflection() {
    return record_type("Shared.DevicesBulk.AssignDispolistDto", [], AssignDispolistDto, () => [["ReceiverId", string_type], ["SenderId", string_type], ["Type", string_type], ["Comment", option_type(string_type)], ["WithSignature", bool_type], ["DispolistId", string_type], ["ToAssignToolsWithQuantity", list_type(ToAssignToolWithQuantity_$reflection())], ["ToAssignTools", list_type(ToAssignTool_$reflection())], ["ToAssignConsumables", list_type(ToAssignConsumable_$reflection())]]);
}

export class AssignDevicesBulkResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "ToolsAssignedToOtherReceiver", "ToolWithQuantityStockTooLess", "ConsumableStockTooLess"];
    }
}

export function AssignDevicesBulkResponse_$reflection() {
    return union_type("Shared.DevicesBulk.AssignDevicesBulkResponse", [], AssignDevicesBulkResponse, () => [[], [["Item", list_type(string_type)]], [["Item", list_type(string_type)]], [["Item", list_type(string_type)]]]);
}

export class UnassignDevicesBulkResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "ToolWithQuantityUserStockTooLess"];
    }
}

export function UnassignDevicesBulkResponse_$reflection() {
    return union_type("Shared.DevicesBulk.UnassignDevicesBulkResponse", [], UnassignDevicesBulkResponse, () => [[], [["toolIds", list_type(string_type)]]]);
}

