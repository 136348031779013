import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";

export function Checkbox(props) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center"])], (elems = [createElement("input", {
        "data-test-id": props.TestId,
        className: join(" ", ["cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-white", "border-[#808080]", "border-2", "rounded-sm", "accent-primary"]),
        type: "checkbox",
        id: `checkbox-${props.Label}-${props.TestId}`,
        checked: props.IsChecked,
        onChange: (ev) => {
            props.OnCheck(ev.target.checked);
        },
        disabled: props.ComponentState === "disabled",
    }), createElement("label", {
        className: join(" ", ["ml-2", "text-sm"]),
        children: props.Label,
        htmlFor: `checkbox-${props.Label}-${props.TestId}`,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

