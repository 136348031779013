import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, bool_type, union_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { SetId, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { DataTransfer_DetailSet_$reflection } from "../../Shared/Set.js";
import { ReservationDto_$reflection } from "../../Shared/Planning.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_successToast } from "../../Components/Toast.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { deleteReservationCmd } from "../../Requests/Set.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ConfirmationDialog } from "../../Components/Dialog.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ReservationDeleted", "DeleteReservation", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Sets.Dialog.DeleteReservationDialog.Msg", [], Msg, () => [[["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", class_type("System.Exception")]]]);
}

export class State extends Record {
    constructor(Loading, SuccessCallback, OnClose, Set$, Reservation) {
        super();
        this.Loading = Loading;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Set = Set$;
        this.Reservation = Reservation;
    }
}

export function State_$reflection() {
    return record_type("Sets.Dialog.DeleteReservationDialog.State", [], State, () => [["Loading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Set", DataTransfer_DetailSet_$reflection()], ["Reservation", ReservationDto_$reflection()]]);
}

function init(props) {
    return [new State(false, props.SuccessCallback, props.OnClose, props.Set, props.Reservation), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 0:
            return [new State(false, state.SuccessCallback, state.OnClose, state.Set, state.Reservation), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_remove_reservation_success")]))];
        case 2:
            return [new State(false, state.SuccessCallback, state.OnClose, state.Set, state.Reservation), Cmd_none()];
        default:
            return [new State(true, state.SuccessCallback, state.OnClose, state.Set, state.Reservation), deleteReservationCmd(new SetId(parse(state.Set.Id)), state.Reservation.Id, (Item_1) => (new Msg(0, [Item_1])), (Item_2) => (new Msg(2, [Item_2])))];
    }
}

export function OpenDeleteReservationDialog(props) {
    let Title;
    const matchValue = props.Reservation;
    if (matchValue != null) {
        const reservation = matchValue;
        const t = useTranslation()[0];
        let patternInput_1;
        const init_1 = init({
            OnClose: props.OnClose,
            Reservation: reservation,
            Set: props.Set,
            SuccessCallback: props.SuccessCallback,
        });
        patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
        }), undefined, [props.IsOpen]);
        return createElement(ConfirmationDialog, (Title = t("general.delete"), {
            Description: t("tool.dialog_remove_reservation_query"),
            IsOpen: props.IsOpen,
            OnClose: props.OnClose,
            OnConfirm: patternInput_1[0].Loading ? (() => {
            }) : (() => {
                patternInput_1[1](new Msg(1, []));
            }),
            Title: Title,
        }));
    }
    else {
        return defaultOf();
    }
}

