import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, list_type, int32_type, class_type, option_type, anonRecord_type, string_type, bool_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName, Receiver_$reflection } from "../../../Shared/User.js";
import { Storage_$reflection } from "../../../Shared/Storage.js";
import { ToolWithQuantityReservationFormDto, ToolWithQuantityDetailReservationDto_$reflection, ToolWithQuantityDetailDto_$reflection, ToolWithQuantityReservationResponse_$reflection, ToolWithQuantityReservationFormDto_$reflection } from "../../../Shared/Tool.js";
import { ToolId__get_unwrap, RequestedValue$1, ToolReservationId, ToolId as ToolId_2, TokenDataDto_$reflection, RequestedValue$1_$reflection, CustomerConfiguration_$reflection, PostResponse$1_$reflection } from "../../../Shared/Shared.js";
import { updateToolWithQuantityReservation, createToolWithQuantityReservation, checkReservationCmd } from "../../../Requests/ToolWithQuantity.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { empty as empty_1, sortBy, toArray, contains, map as map_1, find, tryFind, ofArray, singleton, filter } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { defaultArg, value as value_11, unwrap, map } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Cmd_successToast, Cmd_errorToast } from "../../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { unwrapStorageId } from "../../../Shared/Helper.js";
import { safeHash, comparePrimitives, equals, createObj, stringHash } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton as singleton_1, empty, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { utcNow, toString, compare } from "../../../fable_modules/fable-library-js.4.19.2/Date.js";
import { InformationAlert, WarningAlert } from "../../../Components/Alert.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { Select, GroupedSelect } from "../../../Components/Select.js";
import { List_groupBy } from "../../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { NumberInput, AutoSuggestInput } from "../../../Components/Input.js";
import { DatePicker } from "../../../Components/DatePicker.js";
import { TextArea } from "../../../Components/TextArea.js";
import { Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../../Communication.js";
import { Types_RequestProperties } from "../../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { getCustomerConfiguration } from "../../../Requests/Configuration.js";
import { Cmd_getToolWithQuantityDetailDto } from "../../../Requests/Tool.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../../Components/Dialog.js";
import { Skeleton } from "../../../Components/Skeleton.js";
import { TextButton } from "../../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FetchReservationCheck", "ReservationCheckFetched", "SetSelectableReceivers", "SetSelectedReceiver", "SetSelectedStorage", "SetName", "SetIsReceiverNotificationSet", "SetDescription", "SetStartDate", "SetEndDate", "SetQuantity", "CreateToolWithQuantityReservation", "UpdateToolWithQuantityReservation", "ToolWithQuantityReservationSaved", "ReservationNameSuggestionsFetched", "ReceiversFetched", "StoragesFetched", "InitSelectedReceiver", "InitSelectedStorage", "CustomerConfigurationFetched", "ToolFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("ToolWithQuantity.EditToolWithQuantitReservationDialog.Msg", [], Msg, () => [[], [["Item", bool_type]], [], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [["Item", option_type(int32_type)]], [["Item", ToolWithQuantityReservationFormDto_$reflection()]], [["Item", ToolWithQuantityReservationFormDto_$reflection()]], [["Item", PostResponse$1_$reflection(ToolWithQuantityReservationResponse_$reflection())]], [["Item", list_type(string_type)]], [["Item", list_type(Receiver_$reflection())]], [["Item", list_type(Storage_$reflection())]], [], [], [["Item", CustomerConfiguration_$reflection()]], [["Item", ToolWithQuantityDetailDto_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(ToolWithQuantity, Loading, SuccessCallback, StartDate, EndDate, Description, Name, SelectedUser, IsUserNotificationSet, OnClose, ToolReservation, ReservationNameSuggestions, Receivers, Storages, SelectedStorage, Quantity, CustomerConfiguration, UserData, ReservationId, IsEnoughStock) {
        super();
        this.ToolWithQuantity = ToolWithQuantity;
        this.Loading = Loading;
        this.SuccessCallback = SuccessCallback;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Description = Description;
        this.Name = Name;
        this.SelectedUser = SelectedUser;
        this.IsUserNotificationSet = IsUserNotificationSet;
        this.OnClose = OnClose;
        this.ToolReservation = ToolReservation;
        this.ReservationNameSuggestions = ReservationNameSuggestions;
        this.Receivers = Receivers;
        this.Storages = Storages;
        this.SelectedStorage = SelectedStorage;
        this.Quantity = Quantity;
        this.CustomerConfiguration = CustomerConfiguration;
        this.UserData = UserData;
        this.ReservationId = ReservationId;
        this.IsEnoughStock = IsEnoughStock;
    }
}

function State_$reflection() {
    return record_type("ToolWithQuantity.EditToolWithQuantitReservationDialog.State", [], State, () => [["ToolWithQuantity", RequestedValue$1_$reflection(ToolWithQuantityDetailDto_$reflection())], ["Loading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Description", string_type], ["Name", string_type], ["SelectedUser", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))], ["IsUserNotificationSet", bool_type], ["OnClose", lambda_type(unit_type, unit_type)], ["ToolReservation", option_type(ToolWithQuantityDetailReservationDto_$reflection())], ["ReservationNameSuggestions", list_type(string_type)], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["SelectedStorage", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))], ["Quantity", option_type(int32_type)], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["UserData", TokenDataDto_$reflection()], ["ReservationId", string_type], ["IsEnoughStock", bool_type]]);
}

function update(msg, state) {
    switch (msg.tag) {
        case 0: {
            const matchValue = state.SelectedStorage;
            const matchValue_1 = state.Quantity;
            const matchValue_2 = state.ToolWithQuantity;
            let matchResult, quantity, selectedStorage, toolWithQuantity;
            if (matchValue == null) {
                matchResult = 1;
            }
            else if (matchValue_1 == null) {
                matchResult = 1;
            }
            else if (matchValue_2.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                quantity = matchValue_1;
                selectedStorage = matchValue;
                toolWithQuantity = matchValue_2.fields[0];
            }
            switch (matchResult) {
                case 0:
                    return [state, checkReservationCmd(new ToolId_2(parse(toolWithQuantity.Id)), state.StartDate, state.EndDate, selectedStorage.value.Id, quantity, new ToolReservationId(parse(state.ReservationId)), (Item_2) => (new Msg(1, [Item_2])), (Item_3) => (new Msg(21, [Item_3])))];
                default:
                    return [state, Cmd_none()];
            }
        }
        case 2: {
            const matchValue_4 = state.Receivers;
            const matchValue_5 = state.CustomerConfiguration;
            let matchResult_1, customerConfiguration, receivers;
            if (matchValue_4.tag === 1) {
                if (matchValue_5.tag === 1) {
                    matchResult_1 = 1;
                    customerConfiguration = matchValue_5.fields[0];
                    receivers = matchValue_4.fields[0];
                }
                else {
                    matchResult_1 = 0;
                }
            }
            else {
                matchResult_1 = 0;
            }
            switch (matchResult_1) {
                case 0:
                    return [state, Cmd_none()];
                default: {
                    const matchValue_7 = state.UserData.Role;
                    switch (matchValue_7) {
                        case "user":
                            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, new RequestedValue$1(1, [filter((receiver) => {
                                const matchValue_8 = receiver.ReceiverType;
                                switch (matchValue_8.tag) {
                                    case 9:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Vehicles;
                                    case 8:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.SchoolClasses;
                                    case 7:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Students;
                                    case 2:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.ExternalPersons;
                                    case 4:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Rooms;
                                    case 5:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Projects;
                                    case 3:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Subcontractos;
                                    case 6:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.ConstructionSites;
                                    case 0:
                                        if (!customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Users && customerConfiguration.IsSelfServiceReservationForUserActive) {
                                            return receiver.Id === state.UserData.UserId;
                                        }
                                        else {
                                            return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Users;
                                        }
                                    default:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Employees;
                                }
                            }, receivers)]), state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_none()];
                        default:
                            return [state, Cmd_none()];
                    }
                }
            }
        }
        case 3:
            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, msg.fields[0], state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_none()];
        case 4:
            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, msg.fields[0], state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), singleton((dispatch) => {
                dispatch(new Msg(0, []));
            })];
        case 6:
            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, msg.fields[0], state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_none()];
        case 5:
            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, msg.fields[0], state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_none()];
        case 7:
            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, msg.fields[0], state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_none()];
        case 8:
            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, msg.fields[0], state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), singleton((dispatch_1) => {
                dispatch_1(new Msg(0, []));
            })];
        case 9:
            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, msg.fields[0], state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), singleton((dispatch_2) => {
                dispatch_2(new Msg(0, []));
            })];
        case 10:
            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, msg.fields[0], state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), singleton((dispatch_3) => {
                dispatch_3(new Msg(0, []));
            })];
        case 11:
            return [new State(state.ToolWithQuantity, true, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), createToolWithQuantityReservation(msg.fields[0], (Item_4) => (new Msg(13, [Item_4])), (Item_5) => (new Msg(21, [Item_5])))];
        case 12:
            return [new State(state.ToolWithQuantity, true, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), updateToolWithQuantityReservation(msg.fields[0], (Item_6) => (new Msg(13, [Item_6])), (Item_7) => (new Msg(21, [Item_7])))];
        case 15:
            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, new RequestedValue$1(1, [msg.fields[0]]), state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_batch(ofArray([singleton((dispatch_4) => {
                dispatch_4(new Msg(2, []));
            }), singleton((dispatch_5) => {
                dispatch_5(new Msg(17, []));
            })]))];
        case 17: {
            const matchValue_9 = state.Receivers;
            const matchValue_10 = state.ToolReservation;
            if (matchValue_9.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (matchValue_10 == null) {
                return [state, Cmd_none()];
            }
            else {
                const reservation = matchValue_10;
                return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, map((receiver_3) => ({
                    label: receiver_3.Name,
                    value: receiver_3,
                }), tryFind((receiver_2) => (receiver_2.Id === reservation.ReceiverInformation.ReceiverId), matchValue_9.fields[0])), state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, new RequestedValue$1(1, [matchValue_9.fields[0]]), state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_none()];
            }
        }
        case 18: {
            const matchValue_12 = state.Storages;
            const matchValue_13 = state.ToolReservation;
            if (matchValue_12.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (matchValue_13 == null) {
                return [state, Cmd_none()];
            }
            else {
                const reservation_1 = matchValue_13;
                return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, map((storage_2) => ({
                    label: storage_2.Name,
                    value: storage_2,
                }), tryFind((storage_1) => (storage_1.Name === reservation_1.StorageName), matchValue_12.fields[0])), state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_none()];
            }
        }
        case 13: {
            const matchValue_15 = msg.fields[0].Result;
            switch (matchValue_15.tag) {
                case 1: {
                    state.SuccessCallback();
                    return [new State(state.ToolWithQuantity, false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_none()];
                }
                case 2:
                    return [new State(state.ToolWithQuantity, false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_errorToast("dialog.reservation.toast_messages.not_enough_stock")];
                default: {
                    state.SuccessCallback();
                    return [new State(state.ToolWithQuantity, false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                        state.SuccessCallback();
                    }), Cmd_ofEffect((_arg_1) => {
                        state.OnClose();
                    }), Cmd_successToast("dialog.reservation.toast_messages.created")]))];
                }
            }
        }
        case 14:
            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, msg.fields[0], state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_none()];
        case 16:
            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, new RequestedValue$1(1, [msg.fields[0]]), state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), singleton((dispatch_6) => {
                dispatch_6(new Msg(18, []));
            })];
        case 19:
            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, new RequestedValue$1(1, [msg.fields[0]]), state.UserData, state.ReservationId, state.IsEnoughStock), singleton((dispatch_7) => {
                dispatch_7(new Msg(2, []));
            })];
        case 20: {
            const response_5 = msg.fields[0];
            const reservation_3 = find((reservation_2) => (reservation_2.Id === state.ReservationId), response_5.Reservations);
            return [new State(new RequestedValue$1(1, [response_5]), state.Loading, state.SuccessCallback, reservation_3.StartDate, reservation_3.EndDate, reservation_3.Description, reservation_3.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, reservation_3, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, reservation_3.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_batch(ofArray([singleton((dispatch_8) => {
                dispatch_8(new Msg(18, []));
            }), singleton((dispatch_9) => {
                dispatch_9(new Msg(17, []));
            })]))];
        }
        case 21:
            return [new State(state.ToolWithQuantity, false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, state.IsEnoughStock), Cmd_none()];
        default:
            return [new State(state.ToolWithQuantity, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Storages, state.SelectedStorage, state.Quantity, state.CustomerConfiguration, state.UserData, state.ReservationId, msg.fields[0]), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems_3;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const formState = props.IsLoading ? "disabled" : "enabled";
    const availableStorages = map_1((stockPerStorage) => stockPerStorage.StorageId, props.ToolWithQuantity.StockPerStorage);
    const storageOptions = map_1((storage_1) => ({
        label: storage_1.Name,
        value: storage_1,
    }), filter((storage) => contains(unwrapStorageId(storage.Id), availableStorages, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), props.Storages));
    return createElement("div", createObj(ofArray([["id", "tool-reservation-dialog"], ["className", join(" ", ["grid", "gap-5"])], (elems_3 = toList(delay(() => {
        let matchValue, reminder, elems, arg_1;
        return append((matchValue = tryFind((r_1) => {
            if (compare(r_1.Date, props.StartDate) >= 0) {
                return compare(r_1.Date, props.EndDate) <= 0;
            }
            else {
                return false;
            }
        }, filter((r) => !r.IsConfirmed, props.ReminderDates)), (matchValue == null) ? (empty()) : ((reminder = matchValue, singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["w-96"])], (elems = [createElement(WarningAlert, {
            Label: (arg_1 = {
                dateString: toString(reminder.Date, "dd.MM.yyyy"),
                reminderName: reminder.Title,
            }, patternInput[1].t("tool.reservation_dialog.overlapping_date_warning", arg_1)),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))))))), delay(() => append(!props.IsEnoughStock ? singleton_1(createElement(WarningAlert, {
            Label: t("validation.reservation.reservation_stock_warning"),
        })) : empty(), delay(() => append(singleton_1(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
            ComponentState: formState,
            IsClearable: false,
            Key: "assign-tool-select",
            Label: t("general.user"),
            NoOptionsMessage: "select.employee_no_options",
            OnChange: props.SetSelectedReceiver,
            Options: map_1((tupledArg) => {
                const receiverType = tupledArg[0];
                const receivers_1 = toArray(sortBy((receiver_2) => receiver_2.label.toLowerCase(), map_1((receiver_1) => {
                    const matchValue_2 = receiver_1.LocationName;
                    if (matchValue_2 != null) {
                        const locationName = matchValue_2;
                        if (equals(receiverType, new ReceiverTypeName(4, []))) {
                            return {
                                label: `${locationName} - ${receiver_1.Name}`,
                                value: receiver_1,
                            };
                        }
                        else {
                            return {
                                label: `${receiver_1.Name} - ${locationName}`,
                                value: receiver_1,
                            };
                        }
                    }
                    else {
                        return {
                            label: `${receiver_1.Name}`,
                            value: receiver_1,
                        };
                    }
                }, tupledArg[1]), {
                    Compare: comparePrimitives,
                }));
                return {
                    label: Helper_receiverTypeNameTranslation(t, receiverType),
                    options: receivers_1,
                };
            }, List_groupBy((receiver) => receiver.ReceiverType, filter((u) => {
                if (props.IsReceiverNotificationSet) {
                    const matchValue_1 = u.ReceiverType;
                    switch (matchValue_1.tag) {
                        case 1:
                        case 5:
                        case 8:
                        case 6:
                        case 2:
                        case 7:
                        case 3:
                        case 4:
                        case 9:
                            return false;
                        default:
                            return true;
                    }
                }
                else {
                    return true;
                }
            }, props.Users), {
                Equals: equals,
                GetHashCode: safeHash,
            })),
            PlaceholderKey: "select.employee_placeholder",
            Value: unwrap(props.SelectedReceiver),
        }))), delay(() => append(singleton_1(createElement(Select, {
            ComponentState: "enabled",
            IsClearable: false,
            Label: t("tool.choose_storage"),
            NoOptionsMessage: "select.storage_no_options",
            OnChange: props.SetSelectedStorage,
            Options: storageOptions,
            PlaceholderKey: "select.storage_placeholder",
            TestId: "",
            Value: unwrap(props.SelectedStorage),
        })), delay(() => {
            let dependencies_1;
            return append(singleton_1((dependencies_1 = [props.ReservationName, props.ReservervationNameSuggestions, formState], reactApi.useMemo(() => createElement(AutoSuggestInput, {
                ComponentState: formState,
                Label: t("general.name"),
                OnChange: props.SetReservationName,
                Suggestions: props.ReservervationNameSuggestions,
                TestId: "",
                Value: props.ReservationName,
            }), dependencies_1))), delay(() => append(singleton_1(createElement(InformationAlert, {
                Label: t("tool.reservation_dialog.reservation_name_tooltip"),
            })), delay(() => {
                let elems_1;
                return append(singleton_1(createElement("div", createObj(singleton((elems_1 = [createElement(DatePicker, {
                    ComponentState: "enabled",
                    IsClearable: false,
                    Label: t("general.start_date"),
                    OnChange: (date) => {
                        if (date == null) {
                        }
                        else {
                            const date_1 = date;
                            if (compare(date_1, props.EndDate) > 0) {
                                props.SetEndDate(date_1);
                            }
                            props.SetStartDate(date_1);
                        }
                    },
                    TestId: "",
                    Value: props.StartDate,
                })], ["children", reactApi.Children.toArray(Array.from(elems_1))]))))), delay(() => {
                    let elems_2;
                    return append(singleton_1(createElement("div", createObj(singleton((elems_2 = [createElement(DatePicker, {
                        ComponentState: "enabled",
                        IsClearable: false,
                        Label: t("general.end_date"),
                        MinDate: props.StartDate,
                        OnChange: (date_2) => {
                            if (date_2 == null) {
                            }
                            else {
                                props.SetEndDate(date_2);
                            }
                        },
                        TestId: "",
                        Value: props.EndDate,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))), delay(() => append(singleton_1(createElement(NumberInput, {
                        ComponentState: "enabled",
                        Label: t("general.quantity"),
                        OnChange: (arg_2) => {
                            props.SetQuantity(map((value_9) => ~~value_9, arg_2));
                        },
                        TestId: "tool-with-quantity-reservation-quantity-input",
                        Value: unwrap(map((value_8) => value_8, props.Quantity)),
                    })), delay(() => singleton_1(createElement(TextArea, {
                        ComponentState: formState,
                        Label: t("general.description"),
                        OnChange: props.SetDescription,
                        TestId: "reservation-description",
                        Value: props.Description,
                    }))))));
                }));
            }))));
        }))))))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

function init(props) {
    return [new State(new RequestedValue$1(0, []), false, props.SuccessCallback, utcNow(), utcNow(), "", "", undefined, props.IsUserNotificationSet, props.OnClose, undefined, empty_1(), new RequestedValue$1(0, []), new RequestedValue$1(0, []), undefined, undefined, new RequestedValue$1(0, []), props.UserData, props.ReservationId, true), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(string_type)));
    })))), "/api/tools/reservations/name-suggestions", (Item) => (new Msg(14, [Item])), (Item_1) => (new Msg(21, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_2) => (new Msg(16, [Item_2])), (Item_3) => (new Msg(21, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(Receiver_$reflection())));
    })))), "/api/receivers", (Item_4) => (new Msg(15, [Item_4])), (Item_5) => (new Msg(21, [Item_5]))), getCustomerConfiguration((Item_6) => (new Msg(19, [Item_6])), (Item_7) => (new Msg(21, [Item_7]))), Cmd_getToolWithQuantityDetailDto((Item_8) => (new Msg(20, [Item_8])), (Item_9) => (new Msg(21, [Item_9])), props.ToolId)]))];
}

export function EditToolWithQuantityReservationDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        IsUserNotificationSet: false,
        OnClose: props.OnClose,
        ReservationId: props.ReservationId,
        SuccessCallback: props.SuccessCallback,
        ToolId: props.ToolId,
        UserData: props.UserData,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("dialog.reservation.edit_title"), {
        Body: toList(delay(() => {
            const matchValue = state_1.Storages;
            const matchValue_1 = state_1.Receivers;
            const matchValue_3 = state_1.ToolWithQuantity;
            let matchResult, receivers, storages, tool;
            if (matchValue.tag === 0) {
                matchResult = 1;
            }
            else if (matchValue_1.tag === 0) {
                matchResult = 1;
            }
            else if (state_1.CustomerConfiguration.tag === 0) {
                matchResult = 1;
            }
            else if (matchValue_3.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                receivers = matchValue_1.fields[0];
                storages = matchValue.fields[0];
                tool = matchValue_3.fields[0];
            }
            switch (matchResult) {
                case 0:
                    return singleton_1(createElement(DialogBody, {
                        Description: state_1.Description,
                        EndDate: state_1.EndDate,
                        IsEnoughStock: state_1.IsEnoughStock,
                        IsLoading: state_1.Loading,
                        IsReceiverNotificationSet: state_1.IsUserNotificationSet,
                        Quantity: unwrap(state_1.Quantity),
                        ReminderDates: tool.Reminders,
                        ReservationName: state_1.Name,
                        ReservervationNameSuggestions: state_1.ReservationNameSuggestions,
                        SelectedReceiver: unwrap(state_1.SelectedUser),
                        SelectedStorage: unwrap(state_1.SelectedStorage),
                        SetDescription: (value_3) => {
                            dispatch(new Msg(7, [value_3]));
                        },
                        SetEndDate: (value_6) => {
                            dispatch(new Msg(9, [value_6]));
                        },
                        SetIsReceiverNotificationSet: (value_4) => {
                            dispatch(new Msg(6, [value_4]));
                        },
                        SetQuantity: (arg) => {
                            dispatch(new Msg(10, [arg]));
                        },
                        SetReservationName: (value_2) => {
                            dispatch(new Msg(5, [value_2]));
                        },
                        SetSelectedReceiver: (value) => {
                            dispatch(new Msg(3, [value]));
                        },
                        SetSelectedStorage: (value_1) => {
                            dispatch(new Msg(4, [value_1]));
                        },
                        SetStartDate: (value_5) => {
                            dispatch(new Msg(8, [value_5]));
                        },
                        StartDate: state_1.StartDate,
                        Storages: storages,
                        SuccessCallback: props.SuccessCallback,
                        ToolWithQuantity: tool,
                        UserData: props.UserData,
                        Users: receivers,
                    }));
                default:
                    return singleton_1(createElement(Skeleton, {
                        Variant: "normal",
                    }));
            }
        })),
        Controls: toList(delay(() => {
            const matchValue_5 = state_1.Storages;
            const matchValue_6 = state_1.Receivers;
            const matchValue_8 = state_1.ToolWithQuantity;
            let matchResult_1, receivers_1, storages_1, tool_1;
            if (matchValue_5.tag === 1) {
                if (matchValue_6.tag === 1) {
                    if (state_1.CustomerConfiguration.tag === 1) {
                        if (matchValue_8.tag === 1) {
                            matchResult_1 = 0;
                            receivers_1 = matchValue_6.fields[0];
                            storages_1 = matchValue_5.fields[0];
                            tool_1 = matchValue_8.fields[0];
                        }
                        else {
                            matchResult_1 = 1;
                        }
                    }
                    else {
                        matchResult_1 = 1;
                    }
                }
                else {
                    matchResult_1 = 1;
                }
            }
            else {
                matchResult_1 = 1;
            }
            switch (matchResult_1) {
                case 0:
                    return append(singleton_1(createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: t("general.cancel"),
                        OnClick: props.OnClose,
                        TestId: "close-reservation-dialog-test-id",
                        Variant: "blueButton",
                    })), delay(() => {
                        let Label_1;
                        return singleton_1(createElement(TextButton, (Label_1 = t("general.save"), {
                            ComponentState: (state_1.SelectedUser != null) ? ((state_1.SelectedStorage != null) ? ((state_1.Quantity != null) ? "enabled" : "disabled") : "disabled") : "disabled",
                            Label: Label_1,
                            OnClick: () => {
                                let ToolId_1, Id, ReceiverId, Quantity_1;
                                dispatch(new Msg(12, [(ToolId_1 = ToolId__get_unwrap(props.ToolId), (Id = value_11(state_1.ToolReservation).Id, (ReceiverId = value_11(state_1.SelectedUser).value.Id, (Quantity_1 = (defaultArg(state_1.Quantity, 0) | 0), new ToolWithQuantityReservationFormDto(Id, state_1.StartDate, state_1.EndDate, state_1.Name, ReceiverId, "", "", "", ToolId_1, state_1.IsUserNotificationSet, state_1.Description, defaultArg(map((arg_1) => unwrapStorageId(arg_1.value.Id), state_1.SelectedStorage), ""), Quantity_1)))))]));
                            },
                            TestId: "save-reservation-dialog-test-id",
                            Variant: "blueButton",
                        })));
                    }));
                default: {
                    return empty();
                }
            }
        })),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

