import { list_type, tuple_type, obj_type, string_type, class_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ofNullable, map as map_1, defaultArg } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { Auto_generateBoxedDecoder_Z6670B51, fromString } from "../fable_modules/Thoth.Json.10.2.0/Decode.fs.js";
import { disposeSafe, getEnumerator, uncurry2 } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { empty } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { addToDict } from "../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { map as map_2, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";

export class swrConfig {
    constructor() {
    }
}

export function swrConfig_$reflection() {
    return class_type("Fable.Import.SWR.swrConfig", undefined, swrConfig);
}

export function localStorageProvider(_arg) {
    const data = defaultArg(map_1((json) => {
        const matchValue = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(list_type(tuple_type(string_type, obj_type)), undefined, undefined)), json);
        if (matchValue.tag === 1) {
            throw new Error(matchValue.fields[0]);
        }
        else {
            return matchValue.fields[0];
        }
    }, ofNullable(localStorage.getItem("app-cache"))), empty());
    const map = new Map([]);
    const enumerator = getEnumerator(data);
    try {
        while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
            const forLoopVar = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
            addToDict(map, forLoopVar[0], forLoopVar[1]);
        }
    }
    finally {
        disposeSafe(enumerator);
    }
    window.addEventListener("beforeunload", (_arg_1) => {
        const convertedMap = toList(delay(() => map_2((element) => [element[0], element[1]], map)));
        const data_1 = toString(0, Auto_generateBoxedEncoder_437914C6(list_type(tuple_type(string_type, obj_type)), undefined, undefined, undefined)(convertedMap));
        localStorage.setItem("app-cache", data_1);
    });
    return map;
}

