import { createElement } from "react";
import React from "react";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { SidebarTextButton } from "../Components/Button.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { map, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";

const scrollOptions = new (class {
    get behavior() {
        const browserInfo = navigator.userAgent;
        return (browserInfo.indexOf("Chrome") >= 0) ? "auto" : "smooth";
    }
    set behavior(_arg) {
    }
    get block() {
        return "center";
    }
    set block(_arg_1) {
    }
    get inline() {
        return "center";
    }
    set inline(_arg_2) {
    }
}
)();

function AreaAnchor(props) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["mb-2"])], (elems = [createElement(SidebarTextButton, {
        ComponentState: "enabled",
        Label: props.Label,
        OnClick: () => {
            props.OnClick();
            document.getElementById(props.Id).scrollIntoView(scrollOptions);
        },
        TestId: `area-anchor-button-${props.Id}`,
        Variant: props.Selected ? "blueButton" : "default",
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function AreaAnchorMenu(props) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["sticky", "top-5"])], (elems = toList(delay(() => map((anchor) => createElement(AreaAnchor, {
        Id: anchor.Id,
        Label: anchor.Label,
        OnClick: () => {
            props.OnSelectAnchor(anchor.Id);
        },
        Selected: props.SelectedAnchor === anchor.Id,
    }), props.Anchors))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

