import { EmptyResponse_$reflection, RequestedValue$1 } from "../../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getCustomerConfiguration } from "../../../Requests/Configuration.js";
import { Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../../Communication.js";
import { Types_RequestProperties } from "../../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Location_$reflection } from "../../../Shared/Location.js";
import { list_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { UserConfiguration as UserConfiguration_1, UserConfiguration_$reflection, Room_$reflection } from "../../../Shared/User.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../../CommunicationV2.js";
import { PropertyConfiguration_$reflection } from "../../../Shared/PropertyConfiguration.js";
import { ValidationOnGetRequest } from "../../../ErrorHandling.js";
import { add } from "../../../fable_modules/fable-library-js.4.19.2/Map.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";

export function init() {
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, [])), Cmd_batch(ofArray([getCustomerConfiguration((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(7, [Item_1]))), Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Location_$reflection())));
    })))), "/api/locations", (Item_2) => (new Msg(1, [Item_2])), (Item_3) => (new Msg(7, [Item_3]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Room_$reflection())));
    })))), "/api/receivers/rooms", (Item_4) => (new Msg(0, [Item_4])), (Item_5) => (new Msg(7, [Item_5]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_3 = _arg;
            return (result_3.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_3.fields[0]);
            })())) : (Promise.resolve(result_3.fields[0]));
        });
    })), "/api/property-configurations", (Item_6) => (new Msg(3, [Item_6])), (Item_7) => (new Msg(7, [Item_7]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_9), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
        const pr_3 = response_3.arrayBuffer();
        return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), UserConfiguration_$reflection()));
    })))), "/api/users/configuration", (Item_8) => (new Msg(4, [Item_8])), (Item_9) => (new Msg(7, [Item_9])))]))];
}

export function update(msg, state) {
    let userConfiguration_1;
    switch (msg.tag) {
        case 1:
            return [new State(state.Rooms, state.CustomerConfiguration, new RequestedValue$1(1, [msg.fields[0]]), state.PropertyConfigurations, state.UserConfiguration), Cmd_none()];
        case 2:
            return [new State(state.Rooms, new RequestedValue$1(1, [msg.fields[0]]), state.Locations, state.PropertyConfigurations, state.UserConfiguration), Cmd_none()];
        case 3:
            return [new State(state.Rooms, state.CustomerConfiguration, state.Locations, new RequestedValue$1(1, [msg.fields[0]]), state.UserConfiguration), Cmd_none()];
        case 4:
            return [new State(state.Rooms, state.CustomerConfiguration, state.Locations, state.PropertyConfigurations, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 5:
            return [state, Cmd_none()];
        case 6: {
            const matchValue = state.UserConfiguration;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const userConfiguration = matchValue.fields[0];
                return [state, (userConfiguration_1 = (new UserConfiguration_1(add("receiver-room-overview-table", msg.fields[0], userConfiguration.TableConfiguration), userConfiguration.QuickOverviewConfiguration)), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response_5) => {
                    const pr = response_5.arrayBuffer();
                    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), EmptyResponse_$reflection()));
                })))), ["/api/users/configuration", toString(0, Auto_generateBoxedEncoder_437914C6(UserConfiguration_$reflection(), undefined, undefined, undefined)(userConfiguration_1))], (Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(7, [Item_1]))))];
            }
        }
        case 7:
            return [state, Cmd_none()];
        default:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.CustomerConfiguration, state.Locations, state.PropertyConfigurations, state.UserConfiguration), Cmd_none()];
    }
}

