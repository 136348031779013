import { Cmd_ofEffect as Cmd_ofEffect_1, Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../../Communication.js";
import { Types_RequestProperties } from "../../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { map as map_2, contains, filter, append, ofArray, empty, singleton } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { list_type, string_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ModalState as ModalState_1, State, Msg } from "./Types.js";
import { FormState__setValidationResponse_5219762A, FormState__startLoading, LabelId__get_unwrap, StorageId__get_unwrap, FormKind as FormKind_1, FormState_get_empty, RequestedValue$1, PrinzingCustomerSpecificTool, CommercialDataFormDto, Label_$reflection } from "../../../Shared/Shared.js";
import { ToolFormDto } from "../../../Shared/Tool.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Storage_$reflection } from "../../../Shared/Storage.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../../CommunicationV2.js";
import { PropertyConfigurationEnabledResponse_$reflection, PropertyConfiguration_$reflection } from "../../../Shared/PropertyConfiguration.js";
import { ValidationOnGetRequest } from "../../../ErrorHandling.js";
import { Cmd_updateTool, Cmd_createTool, Cmd_getTool } from "../../../Requests/Tool.js";
import { map } from "../../../fable_modules/fable-library-js.4.19.2/Array.js";
import { map as map_1 } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { toISOString } from "../../../Common.js";
import { uploadImage } from "../../../Requests/Files.js";
import { Cmd_successToast, Cmd_errorToast } from "../../../Components/Toast.js";
import { stringHash } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Cmd_ofEffect } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";

export const getManufacturersCmd = Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
    const pr = response.arrayBuffer();
    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(string_type)));
})))), "/api/tools/manufacturers", (Item) => (new Msg(29, [Item])), (Item_1) => (new Msg(34, [Item_1])));

export const getLabelsCmd = Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
    const pr = response.arrayBuffer();
    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Label_$reflection())));
})))), "/api/labels", (Item) => (new Msg(28, [Item])), (Item_1) => (new Msg(34, [Item_1])));

export const emptyCommercialData = new CommercialDataFormDto(undefined, "", "", undefined, undefined, "");

export function emptyToolForm(customer) {
    return new ToolFormDto("", "", "", "", "", "", "", emptyCommercialData, undefined, (customer.tag === 1) ? (new PrinzingCustomerSpecificTool("")) : undefined, empty(), empty(), "", empty(), "");
}

export function initState(userData) {
    return new State(undefined, new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), empty(), undefined, undefined, undefined, userData, userData.Customer, undefined, undefined, FormState_get_empty(), new FormKind_1(0, []));
}

export function initNewToolView(userData) {
    let bind$0040;
    return [(bind$0040 = initState(userData), new State(bind$0040.ToolId, new RequestedValue$1(1, [emptyToolForm(userData.Customer)]), bind$0040.Storages, bind$0040.PropertyConfigurations, bind$0040.IsCustomPropertiesEnabled, bind$0040.Labels, bind$0040.Manufacturers, bind$0040.LastInspectionDate, bind$0040.PurchaseDate, bind$0040.WarrantyDate, bind$0040.UserData, bind$0040.Customer, bind$0040.SelectedFile, bind$0040.ModalState, bind$0040.FormState, new FormKind_1(0, []))), Cmd_batch(ofArray([Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item) => (new Msg(27, [Item])), (Item_1) => (new Msg(34, [Item_1]))), getLabelsCmd, getManufacturersCmd, Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_5;
        return ((url_5 = addPrefix_1(url_3), (refreshOn) => fetchWithDecoder_1(url_5, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
            const pr_1 = response_1.arrayBuffer();
            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_2 = _arg;
            return (result_2.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_2.fields[0]);
            })())) : (Promise.resolve(result_2.fields[0]));
        });
    })), "/api/property-configurations", (Item_2) => (new Msg(30, [Item_2])), (Item_3) => (new Msg(34, [Item_3]))), Cmd_OfPromise_either_1((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn_1) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_4 = _arg_1;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_4) => (new Msg(33, [Item_4])), (Item_5) => (new Msg(34, [Item_5])))]))];
}

export function initNewToolModalView(userData, onSuccessCallback, isSelectedStorageIdNeeded, selectedStorageId) {
    let bind$0040;
    return [(bind$0040 = initState(userData), new State(bind$0040.ToolId, new RequestedValue$1(1, [emptyToolForm(userData.Customer)]), bind$0040.Storages, bind$0040.PropertyConfigurations, bind$0040.IsCustomPropertiesEnabled, bind$0040.Labels, bind$0040.Manufacturers, bind$0040.LastInspectionDate, bind$0040.PurchaseDate, bind$0040.WarrantyDate, bind$0040.UserData, bind$0040.Customer, bind$0040.SelectedFile, new ModalState_1(onSuccessCallback, selectedStorageId, isSelectedStorageIdNeeded), bind$0040.FormState, new FormKind_1(0, []))), Cmd_batch(ofArray([Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item) => (new Msg(27, [Item])), (Item_1) => (new Msg(34, [Item_1]))), getLabelsCmd, getManufacturersCmd, Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_5;
        return ((url_5 = addPrefix_1(url_3), (refreshOn) => fetchWithDecoder_1(url_5, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
            const pr_1 = response_1.arrayBuffer();
            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_2 = _arg;
            return (result_2.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_2.fields[0]);
            })())) : (Promise.resolve(result_2.fields[0]));
        });
    })), "/api/property-configurations", (Item_2) => (new Msg(30, [Item_2])), (Item_3) => (new Msg(34, [Item_3]))), Cmd_OfPromise_either_1((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn_1) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_4 = _arg_1;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_4) => (new Msg(33, [Item_4])), (Item_5) => (new Msg(34, [Item_5])))]))];
}

export function initCopyNewTool(toolId, userData) {
    let bind$0040;
    return [(bind$0040 = initState(userData), new State(bind$0040.ToolId, new RequestedValue$1(0, []), bind$0040.Storages, bind$0040.PropertyConfigurations, bind$0040.IsCustomPropertiesEnabled, bind$0040.Labels, bind$0040.Manufacturers, bind$0040.LastInspectionDate, bind$0040.PurchaseDate, bind$0040.WarrantyDate, bind$0040.UserData, bind$0040.Customer, bind$0040.SelectedFile, bind$0040.ModalState, bind$0040.FormState, new FormKind_1(2, []))), Cmd_batch(ofArray([Cmd_getTool((Item) => (new Msg(24, [Item])), (Item_1) => (new Msg(34, [Item_1])), toolId), Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_2) => (new Msg(27, [Item_2])), (Item_3) => (new Msg(34, [Item_3]))), getLabelsCmd, getManufacturersCmd, Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_5;
        return ((url_5 = addPrefix_1(url_3), (refreshOn) => fetchWithDecoder_1(url_5, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
            const pr_1 = response_1.arrayBuffer();
            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_2 = _arg;
            return (result_2.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_2.fields[0]);
            })())) : (Promise.resolve(result_2.fields[0]));
        });
    })), "/api/property-configurations", (Item_4) => (new Msg(30, [Item_4])), (Item_5) => (new Msg(34, [Item_5]))), Cmd_OfPromise_either_1((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn_1) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_4 = _arg_1;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_6) => (new Msg(33, [Item_6])), (Item_7) => (new Msg(34, [Item_7])))]))];
}

export function initEditView(userData, toolId) {
    let bind$0040;
    return [(bind$0040 = initState(userData), new State(bind$0040.ToolId, bind$0040.ToolForm, bind$0040.Storages, bind$0040.PropertyConfigurations, bind$0040.IsCustomPropertiesEnabled, bind$0040.Labels, bind$0040.Manufacturers, bind$0040.LastInspectionDate, bind$0040.PurchaseDate, bind$0040.WarrantyDate, bind$0040.UserData, bind$0040.Customer, bind$0040.SelectedFile, bind$0040.ModalState, bind$0040.FormState, new FormKind_1(1, []))), Cmd_batch(ofArray([Cmd_getTool((Item) => (new Msg(24, [Item])), (Item_1) => (new Msg(34, [Item_1])), toolId), Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_2) => (new Msg(27, [Item_2])), (Item_3) => (new Msg(34, [Item_3]))), getLabelsCmd, getManufacturersCmd, Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_5;
        return ((url_5 = addPrefix_1(url_3), (refreshOn) => fetchWithDecoder_1(url_5, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
            const pr_1 = response_1.arrayBuffer();
            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_2 = _arg;
            return (result_2.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_2.fields[0]);
            })())) : (Promise.resolve(result_2.fields[0]));
        });
    })), "/api/property-configurations", (Item_4) => (new Msg(30, [Item_4])), (Item_5) => (new Msg(34, [Item_5]))), Cmd_OfPromise_either_1((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn_1) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_4 = _arg_1;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_6) => (new Msg(33, [Item_6])), (Item_7) => (new Msg(34, [Item_7])))]))];
}

export function update(msg, state) {
    let matchValue_1, tool_1, matchValue_2, tool_2, matchValue_3, tool_3, matchValue_4, tool_4, matchValue_5, tool_5, matchValue_6, tool_6, matchValue_7, tool_7, matchValue_8, tool_8, matchValue_9, tool_9, matchValue_10, tool_10, matchValue_11, tool_11, bind$0040, matchValue_12, tool_12, bind$0040_1, matchValue_13, tool_13, bind$0040_2, matchValue_14, tool_14, bind$0040_3, matchValue_15, tool_15, matchValue_16, specific, bind$0040_4, WarrantyDate, bind$0040_5, WarrantyDate_1, LastInspectionDate_2, bind$0040_6, WarrantyDate_2, xs, xs_2, matchValue_26, matchValue_27, matchValue, tool;
    switch (msg.tag) {
        case 4:
            return [new State(state.ToolId, (matchValue_1 = state.ToolForm, (matchValue_1.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_1 = matchValue_1.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_1.DisplayName, tool_1.Manufacturer, tool_1.Model, tool_1.SerialNumber, tool_1.StorageId, tool_1.StorageSpace, tool_1.Comment, tool_1.CommercialData, tool_1.LastInspectionDate, tool_1.PrinzingSpecificFields, tool_1.CategoryIds, tool_1.QRCodeIds, msg.fields[0], tool_1.CustomProperties, tool_1.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 1:
            return [new State(state.ToolId, (matchValue_2 = state.ToolForm, (matchValue_2.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_2 = matchValue_2.fields[0], new RequestedValue$1(1, [new ToolFormDto(msg.fields[0], tool_2.Manufacturer, tool_2.Model, tool_2.SerialNumber, tool_2.StorageId, tool_2.StorageSpace, tool_2.Comment, tool_2.CommercialData, tool_2.LastInspectionDate, tool_2.PrinzingSpecificFields, tool_2.CategoryIds, tool_2.QRCodeIds, tool_2.InventoryNumber, tool_2.CustomProperties, tool_2.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 2:
            return [new State(state.ToolId, (matchValue_3 = state.ToolForm, (matchValue_3.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_3 = matchValue_3.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_3.DisplayName, tool_3.Manufacturer, msg.fields[0], tool_3.SerialNumber, tool_3.StorageId, tool_3.StorageSpace, tool_3.Comment, tool_3.CommercialData, tool_3.LastInspectionDate, tool_3.PrinzingSpecificFields, tool_3.CategoryIds, tool_3.QRCodeIds, tool_3.InventoryNumber, tool_3.CustomProperties, tool_3.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 3:
            return [new State(state.ToolId, (matchValue_4 = state.ToolForm, (matchValue_4.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_4 = matchValue_4.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_4.DisplayName, tool_4.Manufacturer, tool_4.Model, msg.fields[0], tool_4.StorageId, tool_4.StorageSpace, tool_4.Comment, tool_4.CommercialData, tool_4.LastInspectionDate, tool_4.PrinzingSpecificFields, tool_4.CategoryIds, tool_4.QRCodeIds, tool_4.InventoryNumber, tool_4.CustomProperties, tool_4.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 5:
            return [new State(state.ToolId, (matchValue_5 = state.ToolForm, (matchValue_5.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_5 = matchValue_5.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_5.DisplayName, tool_5.Manufacturer, tool_5.Model, tool_5.SerialNumber, StorageId__get_unwrap(msg.fields[0].value), tool_5.StorageSpace, tool_5.Comment, tool_5.CommercialData, tool_5.LastInspectionDate, tool_5.PrinzingSpecificFields, tool_5.CategoryIds, tool_5.QRCodeIds, tool_5.InventoryNumber, tool_5.CustomProperties, tool_5.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 6:
            return [new State(state.ToolId, (matchValue_6 = state.ToolForm, (matchValue_6.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_6 = matchValue_6.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_6.DisplayName, tool_6.Manufacturer, tool_6.Model, tool_6.SerialNumber, tool_6.StorageId, msg.fields[0], tool_6.Comment, tool_6.CommercialData, tool_6.LastInspectionDate, tool_6.PrinzingSpecificFields, tool_6.CategoryIds, tool_6.QRCodeIds, tool_6.InventoryNumber, tool_6.CustomProperties, tool_6.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 8:
            return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, msg.fields[0], state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 7:
            return [new State(state.ToolId, (matchValue_7 = state.ToolForm, (matchValue_7.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_7 = matchValue_7.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_7.DisplayName, tool_7.Manufacturer, tool_7.Model, tool_7.SerialNumber, tool_7.StorageId, tool_7.StorageSpace, msg.fields[0], tool_7.CommercialData, tool_7.LastInspectionDate, tool_7.PrinzingSpecificFields, tool_7.CategoryIds, tool_7.QRCodeIds, tool_7.InventoryNumber, tool_7.CustomProperties, tool_7.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 11:
            return [new State(state.ToolId, (matchValue_8 = state.ToolForm, (matchValue_8.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_8 = matchValue_8.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_8.DisplayName, tool_8.Manufacturer, tool_8.Model, tool_8.SerialNumber, tool_8.StorageId, tool_8.StorageSpace, tool_8.Comment, tool_8.CommercialData, tool_8.LastInspectionDate, tool_8.PrinzingSpecificFields, tool_8.CategoryIds, append(singleton(msg.fields[0]), tool_8.QRCodeIds), tool_8.InventoryNumber, tool_8.CustomProperties, tool_8.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 9: {
            const labelIds = msg.fields[0];
            const assignedLabelIds = (labelIds != null) ? ofArray(map((u) => LabelId__get_unwrap(u.value), labelIds)) : empty();
            return [new State(state.ToolId, (matchValue_9 = state.ToolForm, (matchValue_9.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_9 = matchValue_9.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_9.DisplayName, tool_9.Manufacturer, tool_9.Model, tool_9.SerialNumber, tool_9.StorageId, tool_9.StorageSpace, tool_9.Comment, tool_9.CommercialData, tool_9.LastInspectionDate, tool_9.PrinzingSpecificFields, assignedLabelIds, tool_9.QRCodeIds, tool_9.InventoryNumber, tool_9.CustomProperties, tool_9.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        }
        case 10:
            return [new State(state.ToolId, (matchValue_10 = state.ToolForm, (matchValue_10.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_10 = matchValue_10.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_10.DisplayName, tool_10.Manufacturer, tool_10.Model, tool_10.SerialNumber, tool_10.StorageId, tool_10.StorageSpace, tool_10.Comment, tool_10.CommercialData, tool_10.LastInspectionDate, tool_10.PrinzingSpecificFields, tool_10.CategoryIds, tool_10.QRCodeIds, tool_10.InventoryNumber, msg.fields[0], tool_10.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 12:
            return [new State(state.ToolId, (matchValue_11 = state.ToolForm, (matchValue_11.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_11 = matchValue_11.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_11.DisplayName, tool_11.Manufacturer, tool_11.Model, tool_11.SerialNumber, tool_11.StorageId, tool_11.StorageSpace, tool_11.Comment, (bind$0040 = tool_11.CommercialData, new CommercialDataFormDto(bind$0040.PurchaseDate, msg.fields[0], bind$0040.SupplierArticleNumber, bind$0040.PurchasePrice, bind$0040.WarrantyDate, bind$0040.Comment)), tool_11.LastInspectionDate, tool_11.PrinzingSpecificFields, tool_11.CategoryIds, tool_11.QRCodeIds, tool_11.InventoryNumber, tool_11.CustomProperties, tool_11.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 13:
            return [new State(state.ToolId, (matchValue_12 = state.ToolForm, (matchValue_12.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_12 = matchValue_12.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_12.DisplayName, tool_12.Manufacturer, tool_12.Model, tool_12.SerialNumber, tool_12.StorageId, tool_12.StorageSpace, tool_12.Comment, (bind$0040_1 = tool_12.CommercialData, new CommercialDataFormDto(bind$0040_1.PurchaseDate, bind$0040_1.Supplier, msg.fields[0], bind$0040_1.PurchasePrice, bind$0040_1.WarrantyDate, bind$0040_1.Comment)), tool_12.LastInspectionDate, tool_12.PrinzingSpecificFields, tool_12.CategoryIds, tool_12.QRCodeIds, tool_12.InventoryNumber, tool_12.CustomProperties, tool_12.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 14:
            return [new State(state.ToolId, (matchValue_13 = state.ToolForm, (matchValue_13.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_13 = matchValue_13.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_13.DisplayName, tool_13.Manufacturer, tool_13.Model, tool_13.SerialNumber, tool_13.StorageId, tool_13.StorageSpace, tool_13.Comment, (bind$0040_2 = tool_13.CommercialData, new CommercialDataFormDto(bind$0040_2.PurchaseDate, bind$0040_2.Supplier, bind$0040_2.SupplierArticleNumber, msg.fields[0], bind$0040_2.WarrantyDate, bind$0040_2.Comment)), tool_13.LastInspectionDate, tool_13.PrinzingSpecificFields, tool_13.CategoryIds, tool_13.QRCodeIds, tool_13.InventoryNumber, tool_13.CustomProperties, tool_13.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 15:
            return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, msg.fields[0], state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 16:
            return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, msg.fields[0], state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 17:
            return [new State(state.ToolId, (matchValue_14 = state.ToolForm, (matchValue_14.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_14 = matchValue_14.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_14.DisplayName, tool_14.Manufacturer, tool_14.Model, tool_14.SerialNumber, tool_14.StorageId, tool_14.StorageSpace, tool_14.Comment, (bind$0040_3 = tool_14.CommercialData, new CommercialDataFormDto(bind$0040_3.PurchaseDate, bind$0040_3.Supplier, bind$0040_3.SupplierArticleNumber, bind$0040_3.PurchasePrice, bind$0040_3.WarrantyDate, msg.fields[0])), tool_14.LastInspectionDate, tool_14.PrinzingSpecificFields, tool_14.CategoryIds, tool_14.QRCodeIds, tool_14.InventoryNumber, tool_14.CustomProperties, tool_14.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 18:
            return [new State(state.ToolId, (matchValue_15 = state.ToolForm, (matchValue_15.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool_15 = matchValue_15.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool_15.DisplayName, tool_15.Manufacturer, tool_15.Model, tool_15.SerialNumber, tool_15.StorageId, tool_15.StorageSpace, tool_15.Comment, tool_15.CommercialData, tool_15.LastInspectionDate, (matchValue_16 = tool_15.PrinzingSpecificFields, (matchValue_16 == null) ? undefined : ((specific = matchValue_16, new PrinzingCustomerSpecificTool(msg.fields[0])))), tool_15.CategoryIds, tool_15.QRCodeIds, tool_15.InventoryNumber, tool_15.CustomProperties, tool_15.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 19: {
            const matchValue_17 = state.ToolForm;
            if (matchValue_17.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const toolForm = matchValue_17.fields[0];
                const matchValue_18 = state.SelectedFile;
                if (matchValue_18 == null) {
                    let dto;
                    const LastInspectionDate = map_1(toISOString, state.LastInspectionDate);
                    dto = (new ToolFormDto(toolForm.DisplayName, toolForm.Manufacturer, toolForm.Model, toolForm.SerialNumber, toolForm.StorageId, toolForm.StorageSpace, toolForm.Comment, (bind$0040_4 = toolForm.CommercialData, (WarrantyDate = map_1(toISOString, state.WarrantyDate), new CommercialDataFormDto(map_1(toISOString, state.PurchaseDate), bind$0040_4.Supplier, bind$0040_4.SupplierArticleNumber, bind$0040_4.PurchasePrice, WarrantyDate, bind$0040_4.Comment))), LastInspectionDate, toolForm.PrinzingSpecificFields, toolForm.CategoryIds, toolForm.QRCodeIds, toolForm.InventoryNumber, toolForm.CustomProperties, toolForm.ImageUrl));
                    return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, FormState__startLoading(state.FormState), state.FormKind), Cmd_createTool(dto, (Item_18) => (new Msg(26, [Item_18])), (Item_19) => (new Msg(34, [Item_19])))];
                }
                else {
                    const file = matchValue_18;
                    const formData = new FormData();
                    formData.append(file.name, file);
                    return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, FormState__startLoading(state.FormState), state.FormKind), uploadImage((Item_16) => (new Msg(32, [Item_16])), (Item_17) => (new Msg(34, [Item_17])), formData)];
                }
            }
        }
        case 20: {
            const matchValue_19 = state.ToolId;
            const matchValue_20 = state.ToolForm;
            let matchResult, toolForm_1, toolId;
            if (matchValue_19 == null) {
                matchResult = 1;
            }
            else if (matchValue_20.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                toolForm_1 = matchValue_20.fields[0];
                toolId = matchValue_19;
            }
            switch (matchResult) {
                case 0: {
                    let dto_1;
                    const LastInspectionDate_1 = map_1(toISOString, state.LastInspectionDate);
                    dto_1 = (new ToolFormDto(toolForm_1.DisplayName, toolForm_1.Manufacturer, toolForm_1.Model, toolForm_1.SerialNumber, toolForm_1.StorageId, toolForm_1.StorageSpace, toolForm_1.Comment, (bind$0040_5 = toolForm_1.CommercialData, (WarrantyDate_1 = map_1(toISOString, state.WarrantyDate), new CommercialDataFormDto(map_1(toISOString, state.PurchaseDate), bind$0040_5.Supplier, bind$0040_5.SupplierArticleNumber, bind$0040_5.PurchasePrice, WarrantyDate_1, bind$0040_5.Comment))), LastInspectionDate_1, toolForm_1.PrinzingSpecificFields, toolForm_1.CategoryIds, toolForm_1.QRCodeIds, toolForm_1.InventoryNumber, toolForm_1.CustomProperties, toolForm_1.ImageUrl));
                    return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, FormState__startLoading(state.FormState), state.FormKind), Cmd_updateTool(toolId, dto_1, (Item_20) => (new Msg(25, [Item_20])), (Item_21) => (new Msg(34, [Item_21])))];
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 32: {
            const response = msg.fields[0];
            switch (response.tag) {
                case 0:
                    return [state, Cmd_errorToast("general.a_error_occured")];
                case 1: {
                    const matchValue_22 = state.ToolForm;
                    if (matchValue_22.tag === 0) {
                        return [state, Cmd_none()];
                    }
                    else {
                        const toolForm_2 = matchValue_22.fields[0];
                        return [state, Cmd_createTool((LastInspectionDate_2 = map_1(toISOString, state.LastInspectionDate), new ToolFormDto(toolForm_2.DisplayName, toolForm_2.Manufacturer, toolForm_2.Model, toolForm_2.SerialNumber, toolForm_2.StorageId, toolForm_2.StorageSpace, toolForm_2.Comment, (bind$0040_6 = toolForm_2.CommercialData, (WarrantyDate_2 = map_1(toISOString, state.WarrantyDate), new CommercialDataFormDto(map_1(toISOString, state.PurchaseDate), bind$0040_6.Supplier, bind$0040_6.SupplierArticleNumber, bind$0040_6.PurchasePrice, WarrantyDate_2, bind$0040_6.Comment))), LastInspectionDate_2, toolForm_2.PrinzingSpecificFields, toolForm_2.CategoryIds, toolForm_2.QRCodeIds, toolForm_2.InventoryNumber, toolForm_2.CustomProperties, response.fields[0].ImageUrl)), (Item_22) => (new Msg(26, [Item_22])), (Item_23) => (new Msg(34, [Item_23])))];
                    }
                }
                default:
                    return [state, Cmd_errorToast("general.file_too_large")];
            }
        }
        case 21: {
            const matchValue_23 = state.ToolId;
            if (matchValue_23 == null) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_getTool((Item_24) => (new Msg(24, [Item_24])), (Item_25) => (new Msg(34, [Item_25])), matchValue_23)];
            }
        }
        case 22:
            return [state, getLabelsCmd];
        case 23: {
            const matchValue_24 = state.ToolForm;
            if (matchValue_24.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const tool_16 = matchValue_24.fields[0];
                return [new State(state.ToolId, new RequestedValue$1(1, [new ToolFormDto(tool_16.DisplayName, tool_16.Manufacturer, tool_16.Model, tool_16.SerialNumber, tool_16.StorageId, tool_16.StorageSpace, tool_16.Comment, tool_16.CommercialData, tool_16.LastInspectionDate, tool_16.PrinzingSpecificFields, tool_16.CategoryIds, filter((code_1) => !contains(code_1, msg.fields[0], {
                    Equals: (x, y) => (x === y),
                    GetHashCode: stringHash,
                }), tool_16.QRCodeIds), tool_16.InventoryNumber, tool_16.CustomProperties, tool_16.ImageUrl)]), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
            }
        }
        case 26: {
            const response_1 = msg.fields[0];
            if (response_1.tag === 1) {
                return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, FormState__setValidationResponse_5219762A(state.FormState, response_1.fields[0]), state.FormKind), Cmd_errorToast("validation.toast")];
            }
            else {
                const toolId_2 = response_1.fields[0];
                const matchValue_25 = state.ModalState;
                if (matchValue_25 == null) {
                    return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, FormState_get_empty(), state.FormKind), Cmd_batch(ofArray([(xs = ["tools", toolId_2], Cmd_ofEffect((_arg_1) => {
                        RouterModule_nav(ofArray(xs), 1, 1);
                    })), Cmd_successToast("general.saved_successfully")]))];
                }
                else {
                    const modalState = matchValue_25;
                    return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, FormState_get_empty(), state.FormKind), Cmd_batch(ofArray([Cmd_ofEffect_1((_arg) => {
                        modalState.OnSuccessCallback(toolId_2);
                    }), Cmd_successToast("general.saved_successfully")]))];
                }
            }
        }
        case 25: {
            const response_2 = msg.fields[0];
            if (response_2.tag === 1) {
                return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, FormState__setValidationResponse_5219762A(state.FormState, response_2.fields[0]), state.FormKind), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, FormState_get_empty(), state.FormKind), Cmd_batch(ofArray([(xs_2 = ["tools", response_2.fields[0]], Cmd_ofEffect((_arg_2) => {
                    RouterModule_nav(ofArray(xs_2), 1, 1);
                })), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 24: {
            const response_3 = msg.fields[0];
            if (response_3.tag === 1) {
                throw new Error("Invalid tool type");
            }
            else {
                const tool_17 = response_3.fields[0];
                return [new State(tool_17.Id, new RequestedValue$1(1, [new ToolFormDto(tool_17.DisplayName, tool_17.Manufacturer, tool_17.Model, tool_17.SerialNumber, StorageId__get_unwrap(tool_17.StorageId), tool_17.StorageSpace, tool_17.Comment, new CommercialDataFormDto(undefined, tool_17.CommercialData.Supplier, tool_17.CommercialData.SupplierArticleNumber, tool_17.CommercialData.PurchasePrice, undefined, tool_17.CommercialData.Comment), undefined, (matchValue_26 = tool_17.CustomerSpecific, (matchValue_26.tag === 1) ? matchValue_26.fields[0] : undefined), map_2(LabelId__get_unwrap, tool_17.LabelIds), (matchValue_27 = state.FormKind, (matchValue_27.tag === 1) ? tool_17.QRCodeIds : ((matchValue_27.tag === 0) ? tool_17.QRCodeIds : empty())), tool_17.InventoryNumber, tool_17.CustomProperties, tool_17.ImageUrl)]), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, tool_17.LastInspectionDate, tool_17.CommercialData.PurchaseDate, tool_17.CommercialData.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
            }
        }
        case 27:
            return [new State(state.ToolId, state.ToolForm, new RequestedValue$1(1, [msg.fields[0]]), state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 28:
            return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, new RequestedValue$1(1, [msg.fields[0]]), state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 29:
            return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, msg.fields[0], state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 30:
            return [new State(state.ToolId, state.ToolForm, state.Storages, new RequestedValue$1(1, [filter((propertyConfiguration) => {
                const matchValue_28 = propertyConfiguration.Visibility;
                switch (matchValue_28.tag) {
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        return false;
                    default:
                        return true;
                }
            }, msg.fields[0])]), state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 31:
            return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, msg.fields[0], state.ModalState, state.FormState, state.FormKind), Cmd_none()];
        case 33:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, new RequestedValue$1(1, [false]), state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
            }
            else {
                return [new State(state.ToolId, state.ToolForm, state.Storages, state.PropertyConfigurations, new RequestedValue$1(1, [true]), state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
            }
        case 34:
            return [state, Cmd_errorToast("general.a_error_occured")];
        default:
            return [new State(state.ToolId, (matchValue = state.ToolForm, (matchValue.tag === 0) ? (new RequestedValue$1(0, [])) : ((tool = matchValue.fields[0], new RequestedValue$1(1, [new ToolFormDto(tool.DisplayName, msg.fields[0], tool.Model, tool.SerialNumber, tool.StorageId, tool.StorageSpace, tool.Comment, tool.CommercialData, tool.LastInspectionDate, tool.PrinzingSpecificFields, tool.CategoryIds, tool.QRCodeIds, tool.InventoryNumber, tool.CustomProperties, tool.ImageUrl)])))), state.Storages, state.PropertyConfigurations, state.IsCustomPropertiesEnabled, state.Labels, state.Manufacturers, state.LastInspectionDate, state.PurchaseDate, state.WarrantyDate, state.UserData, state.Customer, state.SelectedFile, state.ModalState, state.FormState, state.FormKind), Cmd_none()];
    }
}

