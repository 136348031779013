import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { TableConfiguration_$reflection, UserConfiguration_$reflection, Room_$reflection } from "../../../Shared/User.js";
import { union_type, class_type, record_type, list_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { EmptyResponse_$reflection, CustomerConfiguration_$reflection, RequestedValue$1_$reflection } from "../../../Shared/Shared.js";
import { Location_$reflection } from "../../../Shared/Location.js";
import { PropertyConfiguration_$reflection } from "../../../Shared/PropertyConfiguration.js";

export class State extends Record {
    constructor(Rooms, CustomerConfiguration, Locations, PropertyConfigurations, UserConfiguration) {
        super();
        this.Rooms = Rooms;
        this.CustomerConfiguration = CustomerConfiguration;
        this.Locations = Locations;
        this.PropertyConfigurations = PropertyConfigurations;
        this.UserConfiguration = UserConfiguration;
    }
}

export function State_$reflection() {
    return record_type("ToolBox.Overviews.Room.Types.State", [], State, () => [["Rooms", RequestedValue$1_$reflection(list_type(Room_$reflection()))], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["Locations", RequestedValue$1_$reflection(list_type(Location_$reflection()))], ["PropertyConfigurations", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["UserConfiguration", RequestedValue$1_$reflection(UserConfiguration_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RoomsFetched", "LocationsFetched", "CustomerConfigurationFetched", "PropertyConfigurationsFetched", "UserConfigurationFetched", "UserConfigurationUpdated", "UpdateTableConfiguration", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("ToolBox.Overviews.Room.Types.Msg", [], Msg, () => [[["Item", list_type(Room_$reflection())]], [["Item", list_type(Location_$reflection())]], [["Item", CustomerConfiguration_$reflection()]], [["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", UserConfiguration_$reflection()]], [["Item", EmptyResponse_$reflection()]], [["Item", TableConfiguration_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

