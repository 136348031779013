import { Msg, State } from "./Types.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { importLastInspectionDateCmd, validateLastInspectionDateCmd } from "../../Requests/Importer.js";

export function init() {
    return [new State(undefined, false, undefined), Cmd_none()];
}

export function update(msg, state) {
    let matchValue;
    switch (msg.tag) {
        case 1: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(undefined, false, response.fields[0].GlobalValidationMessage), Cmd_errorToast("importer.failed_imported_data")];
            }
            else {
                return [new State(response.fields[0], false, state.ValidateErrorMessage), Cmd_successToast("importer.successfully_imported_data")];
            }
        }
        case 2:
            return [new State(undefined, true, undefined), validateLastInspectionDateCmd(msg.fields[0], (Item_2) => (new Msg(3, [Item_2])), (Item_3) => (new Msg(4, [Item_3])))];
        case 3: {
            const response_1 = msg.fields[0];
            if (response_1.tag === 1) {
                return [new State(undefined, false, response_1.fields[0].GlobalValidationMessage), Cmd_errorToast("importer.failed_imported_data")];
            }
            else {
                return [new State(response_1.fields[0], false, state.ValidateErrorMessage), Cmd_none()];
            }
        }
        case 4:
            return [new State(state.Import, false, "importer.csv_validation_failure"), Cmd_none()];
        default:
            return [new State(state.Import, true, state.ValidateErrorMessage), (matchValue = state.Import, (matchValue == null) ? Cmd_none() : importLastInspectionDateCmd(matchValue, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(4, [Item_1]))))];
    }
}

