import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { bool_type, list_type, class_type, union_type, option_type, int32_type, record_type, float64_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ConsumableId as ConsumableId_1, ToolId as ToolId_2, DispolistId_$reflection, ToolId_$reflection, ConsumableId_$reflection } from "./Shared.js";
import { Consumable_$reflection } from "./Consumable.js";
import { Tool_$reflection, ToolWithQuantity_$reflection } from "./Tool.js";
import { Storage_$reflection } from "./Storage.js";
import { newGuid, parse } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import { value } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { exists } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { equals } from "../fable_modules/fable-library-js.4.19.2/Util.js";

export class DispolistAssignConsumable extends Record {
    constructor(ConsumableId, Quantity, StorageId) {
        super();
        this.ConsumableId = ConsumableId;
        this.Quantity = Quantity;
        this.StorageId = StorageId;
    }
}

export function DispolistAssignConsumable_$reflection() {
    return record_type("Shared.Dispolist.DispolistAssignConsumable", [], DispolistAssignConsumable, () => [["ConsumableId", string_type], ["Quantity", float64_type], ["StorageId", string_type]]);
}

export class DispolistAssignTool extends Record {
    constructor(ToolId, StorageId) {
        super();
        this.ToolId = ToolId;
        this.StorageId = StorageId;
    }
}

export function DispolistAssignTool_$reflection() {
    return record_type("Shared.Dispolist.DispolistAssignTool", [], DispolistAssignTool, () => [["ToolId", string_type], ["StorageId", string_type]]);
}

export class DispolistAssignToolWithQuantity extends Record {
    constructor(ToolId, Quantity, StorageId) {
        super();
        this.ToolId = ToolId;
        this.Quantity = Quantity;
        this.StorageId = StorageId;
    }
}

export function DispolistAssignToolWithQuantity_$reflection() {
    return record_type("Shared.Dispolist.DispolistAssignToolWithQuantity", [], DispolistAssignToolWithQuantity, () => [["ToolId", string_type], ["Quantity", option_type(int32_type)], ["StorageId", string_type]]);
}

export class ElementType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ToolWithQuantity", "Tool", "Consumable"];
    }
}

export function ElementType_$reflection() {
    return union_type("Shared.Dispolist.ElementType", [], ElementType, () => [[], [], []]);
}

export class ConsumableElement extends Record {
    constructor(ConsumableId, Id, DisplayName, Unit, Quantity, Type) {
        super();
        this.ConsumableId = ConsumableId;
        this.Id = Id;
        this.DisplayName = DisplayName;
        this.Unit = Unit;
        this.Quantity = Quantity;
        this.Type = Type;
    }
}

export function ConsumableElement_$reflection() {
    return record_type("Shared.Dispolist.ConsumableElement", [], ConsumableElement, () => [["ConsumableId", ConsumableId_$reflection()], ["Id", class_type("System.Guid")], ["DisplayName", string_type], ["Unit", string_type], ["Quantity", float64_type], ["Type", ElementType_$reflection()]]);
}

export class ToolElement extends Record {
    constructor(ToolId, Id, DisplayName, Type) {
        super();
        this.ToolId = ToolId;
        this.Id = Id;
        this.DisplayName = DisplayName;
        this.Type = Type;
    }
}

export function ToolElement_$reflection() {
    return record_type("Shared.Dispolist.ToolElement", [], ToolElement, () => [["ToolId", ToolId_$reflection()], ["Id", class_type("System.Guid")], ["DisplayName", string_type], ["Type", ElementType_$reflection()]]);
}

export class ToolWithQuantityElement extends Record {
    constructor(ToolId, Id, DisplayName, Quantity, Type) {
        super();
        this.ToolId = ToolId;
        this.Id = Id;
        this.DisplayName = DisplayName;
        this.Quantity = (Quantity | 0);
        this.Type = Type;
    }
}

export function ToolWithQuantityElement_$reflection() {
    return record_type("Shared.Dispolist.ToolWithQuantityElement", [], ToolWithQuantityElement, () => [["ToolId", ToolId_$reflection()], ["Id", class_type("System.Guid")], ["DisplayName", string_type], ["Quantity", int32_type], ["Type", ElementType_$reflection()]]);
}

export class Element$ extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ConsumableElement", "ToolElement", "ToolWithQuantityElement"];
    }
}

export function Element$_$reflection() {
    return union_type("Shared.Dispolist.Element", [], Element$, () => [[["Item", ConsumableElement_$reflection()]], [["Item", ToolElement_$reflection()]], [["Item", ToolWithQuantityElement_$reflection()]]]);
}

export class Dispolist extends Record {
    constructor(Id, Name, Description, Elements, CreatedAt, UpdatedAt, IsDeleted) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Elements = Elements;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.IsDeleted = IsDeleted;
    }
}

export function Dispolist_$reflection() {
    return record_type("Shared.Dispolist.Dispolist", [], Dispolist, () => [["Id", DispolistId_$reflection()], ["Name", string_type], ["Description", string_type], ["Elements", list_type(Element$_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")], ["IsDeleted", bool_type]]);
}

export class DispolistElement extends Record {
    constructor(Id, SerialNumber, Manufacturer, Model, InventoryNumber, DisplayName, AdditionalName, Unit, Type) {
        super();
        this.Id = Id;
        this.SerialNumber = SerialNumber;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.InventoryNumber = InventoryNumber;
        this.DisplayName = DisplayName;
        this.AdditionalName = AdditionalName;
        this.Unit = Unit;
        this.Type = Type;
    }
}

export function DispolistElement_$reflection() {
    return record_type("Shared.Dispolist.DispolistElement", [], DispolistElement, () => [["Id", string_type], ["SerialNumber", string_type], ["Manufacturer", string_type], ["Model", string_type], ["InventoryNumber", string_type], ["DisplayName", string_type], ["AdditionalName", string_type], ["Unit", option_type(string_type)], ["Type", ElementType_$reflection()]]);
}

export class DispolistOverview extends Record {
    constructor(Id, Name, SumTools, SumToolsWithQuantity, SumConsumables) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.SumTools = (SumTools | 0);
        this.SumToolsWithQuantity = (SumToolsWithQuantity | 0);
        this.SumConsumables = (SumConsumables | 0);
    }
}

export function DispolistOverview_$reflection() {
    return record_type("Shared.Dispolist.DispolistOverview", [], DispolistOverview, () => [["Id", DispolistId_$reflection()], ["Name", string_type], ["SumTools", int32_type], ["SumToolsWithQuantity", int32_type], ["SumConsumables", int32_type]]);
}

export class AssignDispolistWithoutNotification extends Record {
    constructor(ReceiverId, SenderId, Type, DispolistId, Comment$, SignatureUrl, ToAssignToolsWithQuantity, ToAssignTools, ToAssignConsumables) {
        super();
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.Type = Type;
        this.DispolistId = DispolistId;
        this.Comment = Comment$;
        this.SignatureUrl = SignatureUrl;
        this.ToAssignToolsWithQuantity = ToAssignToolsWithQuantity;
        this.ToAssignTools = ToAssignTools;
        this.ToAssignConsumables = ToAssignConsumables;
    }
}

export function AssignDispolistWithoutNotification_$reflection() {
    return record_type("Shared.Dispolist.AssignDispolistWithoutNotification", [], AssignDispolistWithoutNotification, () => [["ReceiverId", string_type], ["SenderId", string_type], ["Type", string_type], ["DispolistId", string_type], ["Comment", option_type(string_type)], ["SignatureUrl", option_type(string_type)], ["ToAssignToolsWithQuantity", list_type(DispolistAssignToolWithQuantity_$reflection())], ["ToAssignTools", list_type(DispolistAssignTool_$reflection())], ["ToAssignConsumables", list_type(DispolistAssignConsumable_$reflection())]]);
}

export class AssignDispolistResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Assigned", "NotAssignable"];
    }
}

export function AssignDispolistResponse_$reflection() {
    return union_type("Shared.Dispolist.AssignDispolistResponse", [], AssignDispolistResponse, () => [[], [["Item1", list_type(Consumable_$reflection())], ["Item2", list_type(ToolWithQuantity_$reflection())], ["Item3", list_type(Tool_$reflection())]]]);
}

export class UnassignDispolistResponse extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Unassigned"];
    }
}

export function UnassignDispolistResponse_$reflection() {
    return union_type("Shared.Dispolist.UnassignDispolistResponse", [], UnassignDispolistResponse, () => [[]]);
}

export class DispolistResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Created", "Updated", "NameAlreadyExists"];
    }
}

export function DispolistResponse_$reflection() {
    return union_type("Shared.Dispolist.DispolistResponse", [], DispolistResponse, () => [[["Item", DispolistId_$reflection()]], [["Item", DispolistId_$reflection()]], []]);
}

export class DispolistDeleteResponse extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Successful"];
    }
}

export function DispolistDeleteResponse_$reflection() {
    return union_type("Shared.Dispolist.DispolistDeleteResponse", [], DispolistDeleteResponse, () => [[]]);
}

export class DispolistDetailedAssignConsumable extends Record {
    constructor(ConsumableId, CheckNumber, Id, Name, AdditionalName, Quantity, StorageId, Storages) {
        super();
        this.ConsumableId = ConsumableId;
        this.CheckNumber = CheckNumber;
        this.Id = Id;
        this.Name = Name;
        this.AdditionalName = AdditionalName;
        this.Quantity = Quantity;
        this.StorageId = StorageId;
        this.Storages = Storages;
    }
}

export function DispolistDetailedAssignConsumable_$reflection() {
    return record_type("Shared.Dispolist.DispolistDetailedAssignConsumable", [], DispolistDetailedAssignConsumable, () => [["ConsumableId", string_type], ["CheckNumber", string_type], ["Id", string_type], ["Name", string_type], ["AdditionalName", string_type], ["Quantity", float64_type], ["StorageId", string_type], ["Storages", list_type(Storage_$reflection())]]);
}

export class DispolistDetailedAssignTool extends Record {
    constructor(ToolId, CheckNumber, Id, StorageId, Manufacturer, Model, DisplayName, Storages) {
        super();
        this.ToolId = ToolId;
        this.CheckNumber = CheckNumber;
        this.Id = Id;
        this.StorageId = StorageId;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.DisplayName = DisplayName;
        this.Storages = Storages;
    }
}

export function DispolistDetailedAssignTool_$reflection() {
    return record_type("Shared.Dispolist.DispolistDetailedAssignTool", [], DispolistDetailedAssignTool, () => [["ToolId", string_type], ["CheckNumber", string_type], ["Id", string_type], ["StorageId", string_type], ["Manufacturer", string_type], ["Model", string_type], ["DisplayName", string_type], ["Storages", list_type(Storage_$reflection())]]);
}

export class DispolistDetailedAssignToolWithQuantity extends Record {
    constructor(ToolId, CheckNumber, Id, Manufacturer, Model, DisplayName, Quantity, StorageId, Storages) {
        super();
        this.ToolId = ToolId;
        this.CheckNumber = CheckNumber;
        this.Id = Id;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.DisplayName = DisplayName;
        this.Quantity = (Quantity | 0);
        this.StorageId = StorageId;
        this.Storages = Storages;
    }
}

export function DispolistDetailedAssignToolWithQuantity_$reflection() {
    return record_type("Shared.Dispolist.DispolistDetailedAssignToolWithQuantity", [], DispolistDetailedAssignToolWithQuantity, () => [["ToolId", string_type], ["CheckNumber", string_type], ["Id", string_type], ["Manufacturer", string_type], ["Model", string_type], ["DisplayName", string_type], ["Quantity", int32_type], ["StorageId", string_type], ["Storages", list_type(Storage_$reflection())]]);
}

export class DetailedDispolistElements extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DispolistDetailedAssignConsumable", "DispolistDetailedAssignTool", "DispolistDetailedAssignToolWithQuantity"];
    }
}

export function DetailedDispolistElements_$reflection() {
    return union_type("Shared.Dispolist.DetailedDispolistElements", [], DetailedDispolistElements, () => [[["Item", DispolistDetailedAssignConsumable_$reflection()]], [["Item", DispolistDetailedAssignTool_$reflection()]], [["Item", DispolistDetailedAssignToolWithQuantity_$reflection()]]]);
}

export class DetailedDispolist extends Record {
    constructor(Id, Name, Elements, InvalidElements) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Elements = Elements;
        this.InvalidElements = InvalidElements;
    }
}

export function DetailedDispolist_$reflection() {
    return record_type("Shared.Dispolist.DetailedDispolist", [], DetailedDispolist, () => [["Id", DispolistId_$reflection()], ["Name", string_type], ["Elements", list_type(DetailedDispolistElements_$reflection())], ["InvalidElements", list_type(DetailedDispolistElements_$reflection())]]);
}

export class AcceptDispolistDto extends Record {
    constructor(NotificationId, SignatureUrl, Comment$) {
        super();
        this.NotificationId = NotificationId;
        this.SignatureUrl = SignatureUrl;
        this.Comment = Comment$;
    }
}

export function AcceptDispolistDto_$reflection() {
    return record_type("Shared.Dispolist.AcceptDispolistDto", [], AcceptDispolistDto, () => [["NotificationId", string_type], ["SignatureUrl", option_type(string_type)], ["Comment", option_type(string_type)]]);
}

export function Helper_detailedElementId(element) {
    switch (element.tag) {
        case 1:
            return element.fields[0].Id;
        case 2:
            return element.fields[0].Id;
        default:
            return element.fields[0].Id;
    }
}

export function Helper_toElement(from) {
    const matchValue = from.Type;
    switch (matchValue.tag) {
        case 0:
            return new Element$(2, [new ToolWithQuantityElement(new ToolId_2(parse(from.Id)), newGuid(), "from.DisplayText", 1, from.Type)]);
        case 2:
            return new Element$(0, [new ConsumableElement(new ConsumableId_1(parse(from.Id)), newGuid(), "from.DisplayText", value(from.Unit), 1, from.Type)]);
        default:
            return new Element$(1, [new ToolElement(new ToolId_2(parse(from.Id)), newGuid(), "from.DisplayText", from.Type)]);
    }
}

export function Helper_elementId(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Id;
        case 2:
            return _arg.fields[0].Id;
        default:
            return _arg.fields[0].Id;
    }
}

export function Helper_containsElement(dispolist, element) {
    return exists((e) => {
        switch (e.tag) {
            case 2:
                if (element.tag === 2) {
                    return equals(element.fields[0].ToolId, e.fields[0].ToolId);
                }
                else {
                    return false;
                }
            case 0:
                if (element.tag === 0) {
                    return equals(element.fields[0].ConsumableId, e.fields[0].ConsumableId);
                }
                else {
                    return false;
                }
            default:
                if (element.tag === 1) {
                    return equals(element.fields[0].ToolId, e.fields[0].ToolId);
                }
                else {
                    return false;
                }
        }
    }, dispolist.Elements);
}

export function Helper_containsToolId(dispolist, toolId) {
    return exists((e) => {
        switch (e.tag) {
            case 2:
                return equals(e.fields[0].ToolId, toolId);
            case 0:
                return false;
            default:
                return equals(e.fields[0].ToolId, toolId);
        }
    }, dispolist.Elements);
}

export function Helper_containsConsumableId(dispolist, consumableId) {
    return exists((e) => {
        switch (e.tag) {
            case 0:
                return equals(e.fields[0].ConsumableId, consumableId);
            default:
                return false;
        }
    }, dispolist.Elements);
}

