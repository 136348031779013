import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { NotificationId_$reflection } from "./Shared.js";
import { union_type, anonRecord_type, list_type, float64_type, int32_type, record_type, option_type, class_type, bool_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { DevicesBulkConsumable_$reflection, DevicesBulkTool_$reflection, ToolWithQuantity_$reflection, Tool_$reflection } from "./Tool.js";
import { BusinessLogic_Set_$reflection } from "./Set.js";
import { Consumable_$reflection } from "./Consumable.js";
import { empty, choose } from "../fable_modules/fable-library-js.4.19.2/List.js";

export class ToolNotification extends Record {
    constructor(Id, Receiver, ReceiverId, Sender, SenderId, StorageId, State, WithSignature, Comment$, Tool, PlannedReturnDate) {
        super();
        this.Id = Id;
        this.Receiver = Receiver;
        this.ReceiverId = ReceiverId;
        this.Sender = Sender;
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.State = State;
        this.WithSignature = WithSignature;
        this.Comment = Comment$;
        this.Tool = Tool;
        this.PlannedReturnDate = PlannedReturnDate;
    }
}

export function ToolNotification_$reflection() {
    return record_type("Shared.Notification.ToolNotification", [], ToolNotification, () => [["Id", NotificationId_$reflection()], ["Receiver", string_type], ["ReceiverId", string_type], ["Sender", string_type], ["SenderId", string_type], ["StorageId", string_type], ["State", string_type], ["WithSignature", bool_type], ["Comment", string_type], ["Tool", Tool_$reflection()], ["PlannedReturnDate", option_type(class_type("System.DateTime"))]]);
}

export class ToolWithQuantityNotification extends Record {
    constructor(Id, Receiver, ReceiverId, Sender, SenderId, StorageId, Quantity, State, WithSignature, Comment$, Tool, PlannedReturnDate) {
        super();
        this.Id = Id;
        this.Receiver = Receiver;
        this.ReceiverId = ReceiverId;
        this.Sender = Sender;
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.Quantity = (Quantity | 0);
        this.State = State;
        this.WithSignature = WithSignature;
        this.Comment = Comment$;
        this.Tool = Tool;
        this.PlannedReturnDate = PlannedReturnDate;
    }
}

export function ToolWithQuantityNotification_$reflection() {
    return record_type("Shared.Notification.ToolWithQuantityNotification", [], ToolWithQuantityNotification, () => [["Id", NotificationId_$reflection()], ["Receiver", string_type], ["ReceiverId", string_type], ["Sender", string_type], ["SenderId", string_type], ["StorageId", string_type], ["Quantity", int32_type], ["State", string_type], ["WithSignature", bool_type], ["Comment", string_type], ["Tool", ToolWithQuantity_$reflection()], ["PlannedReturnDate", option_type(class_type("System.DateTime"))]]);
}

export class SetNotification extends Record {
    constructor(Id, Receiver, ReceiverId, Sender, SenderId, StorageId, State, WithSignature, Comment$, Set$, PlannedReturnDate) {
        super();
        this.Id = Id;
        this.Receiver = Receiver;
        this.ReceiverId = ReceiverId;
        this.Sender = Sender;
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.State = State;
        this.WithSignature = WithSignature;
        this.Comment = Comment$;
        this.Set = Set$;
        this.PlannedReturnDate = PlannedReturnDate;
    }
}

export function SetNotification_$reflection() {
    return record_type("Shared.Notification.SetNotification", [], SetNotification, () => [["Id", NotificationId_$reflection()], ["Receiver", string_type], ["ReceiverId", string_type], ["Sender", string_type], ["SenderId", string_type], ["StorageId", string_type], ["State", string_type], ["WithSignature", bool_type], ["Comment", string_type], ["Set", BusinessLogic_Set_$reflection()], ["PlannedReturnDate", option_type(class_type("System.DateTime"))]]);
}

export class ConsumableNotification extends Record {
    constructor(Id, Receiver, ReceiverId, Sender, SenderId, StorageId, CommissionNumber, WithSignature, Quantity, State, Comment$, Consumable) {
        super();
        this.Id = Id;
        this.Receiver = Receiver;
        this.ReceiverId = ReceiverId;
        this.Sender = Sender;
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.CommissionNumber = CommissionNumber;
        this.WithSignature = WithSignature;
        this.Quantity = Quantity;
        this.State = State;
        this.Comment = Comment$;
        this.Consumable = Consumable;
    }
}

export function ConsumableNotification_$reflection() {
    return record_type("Shared.Notification.ConsumableNotification", [], ConsumableNotification, () => [["Id", NotificationId_$reflection()], ["Receiver", string_type], ["ReceiverId", string_type], ["Sender", string_type], ["SenderId", string_type], ["StorageId", string_type], ["CommissionNumber", string_type], ["WithSignature", bool_type], ["Quantity", float64_type], ["State", string_type], ["Comment", string_type], ["Consumable", Consumable_$reflection()]]);
}

export class DevicesBulkNotification extends Record {
    constructor(Id, Receiver, ReceiverId, Sender, SenderId, DispolistId, Comment$, WithSignature, State, DevicesBulkTools, DevicesBulkConsumables) {
        super();
        this.Id = Id;
        this.Receiver = Receiver;
        this.ReceiverId = ReceiverId;
        this.Sender = Sender;
        this.SenderId = SenderId;
        this.DispolistId = DispolistId;
        this.Comment = Comment$;
        this.WithSignature = WithSignature;
        this.State = State;
        this.DevicesBulkTools = DevicesBulkTools;
        this.DevicesBulkConsumables = DevicesBulkConsumables;
    }
}

export function DevicesBulkNotification_$reflection() {
    return record_type("Shared.Notification.DevicesBulkNotification", [], DevicesBulkNotification, () => [["Id", NotificationId_$reflection()], ["Receiver", string_type], ["ReceiverId", string_type], ["Sender", string_type], ["SenderId", string_type], ["DispolistId", option_type(string_type)], ["Comment", string_type], ["WithSignature", bool_type], ["State", string_type], ["DevicesBulkTools", list_type(DevicesBulkTool_$reflection())], ["DevicesBulkConsumables", list_type(DevicesBulkConsumable_$reflection())]]);
}

export class MultiAssignNotification extends Record {
    constructor(Id, Receiver, ReceiverId, Sender, SenderId, Comment$, WithSignature, State, ToolIds, ToolsWithQuantity, SetIds, ConsumableIds) {
        super();
        this.Id = Id;
        this.Receiver = Receiver;
        this.ReceiverId = ReceiverId;
        this.Sender = Sender;
        this.SenderId = SenderId;
        this.Comment = Comment$;
        this.WithSignature = WithSignature;
        this.State = State;
        this.ToolIds = ToolIds;
        this.ToolsWithQuantity = ToolsWithQuantity;
        this.SetIds = SetIds;
        this.ConsumableIds = ConsumableIds;
    }
}

export function MultiAssignNotification_$reflection() {
    return record_type("Shared.Notification.MultiAssignNotification", [], MultiAssignNotification, () => [["Id", NotificationId_$reflection()], ["Receiver", string_type], ["ReceiverId", string_type], ["Sender", string_type], ["SenderId", string_type], ["Comment", string_type], ["WithSignature", bool_type], ["State", string_type], ["ToolIds", list_type(string_type)], ["ToolsWithQuantity", list_type(anonRecord_type(["Id", string_type], ["Quantity", int32_type], ["StorageId", string_type]))], ["SetIds", list_type(string_type)], ["ConsumableIds", list_type(anonRecord_type(["Id", string_type], ["Quantity", float64_type], ["StorageId", string_type]))]]);
}

export class ToolNotificationType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["BackToStorage", "UserToStorageRequest", "StorageToUserAssignment", "UserToUserAssignment"];
    }
}

export function ToolNotificationType_$reflection() {
    return union_type("Shared.Notification.ToolNotificationType", [], ToolNotificationType, () => [[["Item", ToolNotification_$reflection()]], [["Item", ToolNotification_$reflection()]], [["Item", ToolNotification_$reflection()]], [["Item", ToolNotification_$reflection()]]]);
}

export function ToolNotificationType__get_Notification(this$) {
    switch (this$.tag) {
        case 1:
            return this$.fields[0];
        case 2:
            return this$.fields[0];
        case 3:
            return this$.fields[0];
        default:
            return this$.fields[0];
    }
}

export class ToolWithQuantityNotificationType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["BackToStorage", "UserToStorageRequest", "StorageToUserAssignment", "UserToUserAssignment"];
    }
}

export function ToolWithQuantityNotificationType_$reflection() {
    return union_type("Shared.Notification.ToolWithQuantityNotificationType", [], ToolWithQuantityNotificationType, () => [[["Item", ToolWithQuantityNotification_$reflection()]], [["Item", ToolWithQuantityNotification_$reflection()]], [["Item", ToolWithQuantityNotification_$reflection()]], [["Item", ToolWithQuantityNotification_$reflection()]]]);
}

export function ToolWithQuantityNotificationType__get_Notification(this$) {
    switch (this$.tag) {
        case 1:
            return this$.fields[0];
        case 2:
            return this$.fields[0];
        case 3:
            return this$.fields[0];
        default:
            return this$.fields[0];
    }
}

export class SetNotificationType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["BackToStorage", "UserToStorageRequest", "StorageToUserAssignment", "UserToUserAssignment"];
    }
}

export function SetNotificationType_$reflection() {
    return union_type("Shared.Notification.SetNotificationType", [], SetNotificationType, () => [[["Item", SetNotification_$reflection()]], [["Item", SetNotification_$reflection()]], [["Item", SetNotification_$reflection()]], [["Item", SetNotification_$reflection()]]]);
}

export function SetNotificationType__get_Notification(this$) {
    switch (this$.tag) {
        case 1:
            return this$.fields[0];
        case 2:
            return this$.fields[0];
        case 3:
            return this$.fields[0];
        default:
            return this$.fields[0];
    }
}

export class ConsumableNotificationType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["StorageToUserAssignment", "ReturnConsumableToStorage", "ReturnConsumableToStorageRequest"];
    }
}

export function ConsumableNotificationType_$reflection() {
    return union_type("Shared.Notification.ConsumableNotificationType", [], ConsumableNotificationType, () => [[["Item", ConsumableNotification_$reflection()]], [["Item", ConsumableNotification_$reflection()]], [["Item", ConsumableNotification_$reflection()]]]);
}

export function ConsumableNotificationType__get_Notification(this$) {
    switch (this$.tag) {
        case 1:
            return this$.fields[0];
        case 2:
            return this$.fields[0];
        default:
            return this$.fields[0];
    }
}

export class Notification extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ToolNotification", "ToolWithQuantityNotification", "SetNotification", "ConsumableNotification", "StorageToUserBulk"];
    }
}

export function Notification_$reflection() {
    return union_type("Shared.Notification.Notification", [], Notification, () => [[["Item", ToolNotificationType_$reflection()]], [["Item", ToolWithQuantityNotificationType_$reflection()]], [["Item", SetNotificationType_$reflection()]], [["Item", ConsumableNotificationType_$reflection()]], [["Item", DevicesBulkNotification_$reflection()]]]);
}

export function Notification__get_Id(this$) {
    switch (this$.tag) {
        case 1: {
            const notification_5 = this$.fields[0];
            switch (notification_5.tag) {
                case 1:
                    return notification_5.fields[0].Id;
                case 2:
                    return notification_5.fields[0].Id;
                case 3:
                    return notification_5.fields[0].Id;
                default:
                    return notification_5.fields[0].Id;
            }
        }
        case 2: {
            const notification_10 = this$.fields[0];
            switch (notification_10.tag) {
                case 1:
                    return notification_10.fields[0].Id;
                case 2:
                    return notification_10.fields[0].Id;
                case 3:
                    return notification_10.fields[0].Id;
                default:
                    return notification_10.fields[0].Id;
            }
        }
        case 3: {
            const notification_15 = this$.fields[0];
            switch (notification_15.tag) {
                case 1:
                    return notification_15.fields[0].Id;
                case 2:
                    return notification_15.fields[0].Id;
                default:
                    return notification_15.fields[0].Id;
            }
        }
        case 4:
            return this$.fields[0].Id;
        default: {
            const notification = this$.fields[0];
            switch (notification.tag) {
                case 1:
                    return notification.fields[0].Id;
                case 2:
                    return notification.fields[0].Id;
                case 3:
                    return notification.fields[0].Id;
                default:
                    return notification.fields[0].Id;
            }
        }
    }
}

export function Notification__get_SenderId(this$) {
    switch (this$.tag) {
        case 1: {
            const notification_5 = this$.fields[0];
            switch (notification_5.tag) {
                case 1:
                    return notification_5.fields[0].SenderId;
                case 2:
                    return notification_5.fields[0].SenderId;
                case 3:
                    return notification_5.fields[0].SenderId;
                default:
                    return notification_5.fields[0].SenderId;
            }
        }
        case 2: {
            const notification_10 = this$.fields[0];
            switch (notification_10.tag) {
                case 1:
                    return notification_10.fields[0].SenderId;
                case 2:
                    return notification_10.fields[0].SenderId;
                case 3:
                    return notification_10.fields[0].SenderId;
                default:
                    return notification_10.fields[0].SenderId;
            }
        }
        case 3: {
            const notification_15 = this$.fields[0];
            switch (notification_15.tag) {
                case 1:
                    return notification_15.fields[0].SenderId;
                case 2:
                    return notification_15.fields[0].SenderId;
                default:
                    return notification_15.fields[0].SenderId;
            }
        }
        case 4:
            return this$.fields[0].SenderId;
        default: {
            const notification = this$.fields[0];
            switch (notification.tag) {
                case 1:
                    return notification.fields[0].SenderId;
                case 2:
                    return notification.fields[0].SenderId;
                case 3:
                    return notification.fields[0].SenderId;
                default:
                    return notification.fields[0].SenderId;
            }
        }
    }
}

export function Notification__get_ReceiverId(this$) {
    switch (this$.tag) {
        case 1: {
            const notification_5 = this$.fields[0];
            switch (notification_5.tag) {
                case 1:
                    return notification_5.fields[0].ReceiverId;
                case 2:
                    return notification_5.fields[0].ReceiverId;
                case 3:
                    return notification_5.fields[0].ReceiverId;
                default:
                    return notification_5.fields[0].ReceiverId;
            }
        }
        case 2: {
            const notification_10 = this$.fields[0];
            switch (notification_10.tag) {
                case 1:
                    return notification_10.fields[0].ReceiverId;
                case 2:
                    return notification_10.fields[0].ReceiverId;
                case 3:
                    return notification_10.fields[0].ReceiverId;
                default:
                    return notification_10.fields[0].ReceiverId;
            }
        }
        case 3: {
            const notification_15 = this$.fields[0];
            switch (notification_15.tag) {
                case 1:
                    return notification_15.fields[0].ReceiverId;
                case 2:
                    return notification_15.fields[0].ReceiverId;
                default:
                    return notification_15.fields[0].ReceiverId;
            }
        }
        case 4:
            return this$.fields[0].ReceiverId;
        default: {
            const notification = this$.fields[0];
            switch (notification.tag) {
                case 1:
                    return notification.fields[0].ReceiverId;
                case 2:
                    return notification.fields[0].ReceiverId;
                case 3:
                    return notification.fields[0].ReceiverId;
                default:
                    return notification.fields[0].ReceiverId;
            }
        }
    }
}

export function Notification__get_ToolId(this$) {
    switch (this$.tag) {
        case 1: {
            const notification_5 = this$.fields[0];
            switch (notification_5.tag) {
                case 1:
                    return notification_5.fields[0].Tool.Id;
                case 2:
                    return notification_5.fields[0].Tool.Id;
                case 3:
                    return notification_5.fields[0].Tool.Id;
                default:
                    return notification_5.fields[0].Tool.Id;
            }
        }
        case 2: {
            const notification_10 = this$.fields[0];
            switch (notification_10.tag) {
                case 1:
                    return undefined;
                case 2:
                    return undefined;
                case 3:
                    return undefined;
                default:
                    return undefined;
            }
        }
        case 3: {
            const notification_15 = this$.fields[0];
            switch (notification_15.tag) {
                case 1:
                    return undefined;
                case 2:
                    return undefined;
                default:
                    return undefined;
            }
        }
        case 4:
            return undefined;
        default: {
            const notification = this$.fields[0];
            switch (notification.tag) {
                case 1:
                    return notification.fields[0].Tool.Id;
                case 2:
                    return notification.fields[0].Tool.Id;
                case 3:
                    return notification.fields[0].Tool.Id;
                default:
                    return notification.fields[0].Tool.Id;
            }
        }
    }
}

export function Notification__get_ConsumableId(this$) {
    switch (this$.tag) {
        case 1: {
            const notification_5 = this$.fields[0];
            switch (notification_5.tag) {
                case 1:
                    return undefined;
                case 2:
                    return undefined;
                case 3:
                    return undefined;
                default:
                    return undefined;
            }
        }
        case 2: {
            const notification_10 = this$.fields[0];
            switch (notification_10.tag) {
                case 1:
                    return undefined;
                case 2:
                    return undefined;
                case 3:
                    return undefined;
                default:
                    return undefined;
            }
        }
        case 3: {
            const notification_15 = this$.fields[0];
            switch (notification_15.tag) {
                case 1:
                    return notification_15.fields[0].Consumable.Id;
                case 2:
                    return notification_15.fields[0].Consumable.Id;
                default:
                    return notification_15.fields[0].Consumable.Id;
            }
        }
        case 4:
            return undefined;
        default: {
            const notification = this$.fields[0];
            switch (notification.tag) {
                case 1:
                    return undefined;
                case 2:
                    return undefined;
                case 3:
                    return undefined;
                default:
                    return undefined;
            }
        }
    }
}

export function Notification__get_SetId(this$) {
    switch (this$.tag) {
        case 1: {
            const notification_5 = this$.fields[0];
            switch (notification_5.tag) {
                case 1:
                    return undefined;
                case 2:
                    return undefined;
                case 3:
                    return undefined;
                default:
                    return undefined;
            }
        }
        case 2: {
            const notification_10 = this$.fields[0];
            switch (notification_10.tag) {
                case 1:
                    return notification_10.fields[0].Set.Id;
                case 2:
                    return notification_10.fields[0].Set.Id;
                case 3:
                    return notification_10.fields[0].Set.Id;
                default:
                    return notification_10.fields[0].Set.Id;
            }
        }
        case 3: {
            const notification_15 = this$.fields[0];
            switch (notification_15.tag) {
                case 1:
                    return undefined;
                case 2:
                    return undefined;
                default:
                    return undefined;
            }
        }
        case 4:
            return undefined;
        default: {
            const notification = this$.fields[0];
            switch (notification.tag) {
                case 1:
                    return undefined;
                case 2:
                    return undefined;
                case 3:
                    return undefined;
                default:
                    return undefined;
            }
        }
    }
}

export function Notification__get_StorageId(this$) {
    switch (this$.tag) {
        case 1: {
            const notification_5 = this$.fields[0];
            switch (notification_5.tag) {
                case 1:
                    return notification_5.fields[0].StorageId;
                case 2:
                    return notification_5.fields[0].StorageId;
                case 3:
                    return notification_5.fields[0].StorageId;
                default:
                    return notification_5.fields[0].StorageId;
            }
        }
        case 2: {
            const notification_10 = this$.fields[0];
            switch (notification_10.tag) {
                case 1:
                    return notification_10.fields[0].StorageId;
                case 2:
                    return notification_10.fields[0].StorageId;
                case 3:
                    return notification_10.fields[0].StorageId;
                default:
                    return notification_10.fields[0].StorageId;
            }
        }
        case 3: {
            const notification_15 = this$.fields[0];
            switch (notification_15.tag) {
                case 1:
                    return notification_15.fields[0].StorageId;
                case 2:
                    return notification_15.fields[0].StorageId;
                default:
                    return notification_15.fields[0].StorageId;
            }
        }
        case 4:
            return undefined;
        default: {
            const notification = this$.fields[0];
            switch (notification.tag) {
                case 1:
                    return notification.fields[0].StorageId;
                case 2:
                    return notification.fields[0].StorageId;
                case 3:
                    return notification.fields[0].StorageId;
                default:
                    return notification.fields[0].StorageId;
            }
        }
    }
}

export function Notification__get_Quantity(this$) {
    switch (this$.tag) {
        case 1: {
            const notification_1 = this$.fields[0];
            switch (notification_1.tag) {
                case 1:
                    return notification_1.fields[0].Quantity;
                case 2:
                    return notification_1.fields[0].Quantity;
                case 3:
                    return notification_1.fields[0].Quantity;
                default:
                    return notification_1.fields[0].Quantity;
            }
        }
        case 2: {
            const notification_6 = this$.fields[0];
            return undefined;
        }
        case 3: {
            const notification_7 = this$.fields[0];
            switch (notification_7.tag) {
                case 1:
                    return notification_7.fields[0].Quantity;
                case 2:
                    return notification_7.fields[0].Quantity;
                default:
                    return notification_7.fields[0].Quantity;
            }
        }
        case 4:
            return undefined;
        default: {
            const notification = this$.fields[0];
            return undefined;
        }
    }
}

export function Notification__get_PlannedReturnDate(this$) {
    switch (this$.tag) {
        case 1: {
            const notification_5 = this$.fields[0];
            switch (notification_5.tag) {
                case 1:
                    return notification_5.fields[0].PlannedReturnDate;
                case 2:
                    return notification_5.fields[0].PlannedReturnDate;
                case 3:
                    return notification_5.fields[0].PlannedReturnDate;
                default:
                    return notification_5.fields[0].PlannedReturnDate;
            }
        }
        case 2: {
            const notification_10 = this$.fields[0];
            switch (notification_10.tag) {
                case 1:
                    return notification_10.fields[0].PlannedReturnDate;
                case 2:
                    return notification_10.fields[0].PlannedReturnDate;
                case 3:
                    return notification_10.fields[0].PlannedReturnDate;
                default:
                    return notification_10.fields[0].PlannedReturnDate;
            }
        }
        case 3: {
            const notification_15 = this$.fields[0];
            return undefined;
        }
        case 4:
            return undefined;
        default: {
            const notification = this$.fields[0];
            switch (notification.tag) {
                case 1:
                    return notification.fields[0].PlannedReturnDate;
                case 2:
                    return notification.fields[0].PlannedReturnDate;
                case 3:
                    return notification.fields[0].PlannedReturnDate;
                default:
                    return notification.fields[0].PlannedReturnDate;
            }
        }
    }
}

export function Notification__get_WithSignature(this$) {
    switch (this$.tag) {
        case 1: {
            const notification_5 = this$.fields[0];
            switch (notification_5.tag) {
                case 1:
                    return notification_5.fields[0].WithSignature;
                case 2:
                    return notification_5.fields[0].WithSignature;
                case 3:
                    return notification_5.fields[0].WithSignature;
                default:
                    return notification_5.fields[0].WithSignature;
            }
        }
        case 2: {
            const notification_10 = this$.fields[0];
            switch (notification_10.tag) {
                case 1:
                    return notification_10.fields[0].WithSignature;
                case 2:
                    return notification_10.fields[0].WithSignature;
                case 3:
                    return notification_10.fields[0].WithSignature;
                default:
                    return notification_10.fields[0].WithSignature;
            }
        }
        case 3: {
            const notification_15 = this$.fields[0];
            switch (notification_15.tag) {
                case 1:
                    return notification_15.fields[0].WithSignature;
                case 2:
                    return notification_15.fields[0].WithSignature;
                default:
                    return notification_15.fields[0].WithSignature;
            }
        }
        case 4:
            return undefined;
        default: {
            const notification = this$.fields[0];
            switch (notification.tag) {
                case 1:
                    return notification.fields[0].WithSignature;
                case 2:
                    return notification.fields[0].WithSignature;
                case 3:
                    return notification.fields[0].WithSignature;
                default:
                    return notification.fields[0].WithSignature;
            }
        }
    }
}

export function Notification__get_Comment(this$) {
    switch (this$.tag) {
        case 1: {
            const notification_5 = this$.fields[0];
            switch (notification_5.tag) {
                case 1:
                    return notification_5.fields[0].Comment;
                case 2:
                    return notification_5.fields[0].Comment;
                case 3:
                    return notification_5.fields[0].Comment;
                default:
                    return notification_5.fields[0].Comment;
            }
        }
        case 2: {
            const notification_10 = this$.fields[0];
            switch (notification_10.tag) {
                case 1:
                    return notification_10.fields[0].Comment;
                case 2:
                    return notification_10.fields[0].Comment;
                case 3:
                    return notification_10.fields[0].Comment;
                default:
                    return notification_10.fields[0].Comment;
            }
        }
        case 3: {
            const notification_15 = this$.fields[0];
            switch (notification_15.tag) {
                case 1:
                    return notification_15.fields[0].Comment;
                case 2:
                    return notification_15.fields[0].Comment;
                default:
                    return notification_15.fields[0].Comment;
            }
        }
        case 4:
            return this$.fields[0].Comment;
        default: {
            const notification = this$.fields[0];
            switch (notification.tag) {
                case 1:
                    return notification.fields[0].Comment;
                case 2:
                    return notification.fields[0].Comment;
                case 3:
                    return notification.fields[0].Comment;
                default:
                    return notification.fields[0].Comment;
            }
        }
    }
}

export function Notification__TypeToString_1505(this$, _arg) {
    switch (this$.tag) {
        case 1: {
            const notification_5 = this$.fields[0];
            switch (notification_5.tag) {
                case 1:
                    return "toolwithquantity_user_to_storage_request";
                case 2:
                    return "toolwithquantity_storage_to_user";
                case 3:
                    return "toolwithquantity_user_to_user";
                default:
                    return "toolwithquantity_user_to_storage";
            }
        }
        case 2: {
            const notification_10 = this$.fields[0];
            switch (notification_10.tag) {
                case 1:
                    return "set_user_to_storage_request";
                case 2:
                    return "set_storage_to_user";
                case 3:
                    return "set_user_to_user";
                default:
                    return "set_user_to_storage";
            }
        }
        case 3: {
            const notification_15 = this$.fields[0];
            switch (notification_15.tag) {
                case 1:
                    return "return_consumable_to_storage";
                case 2:
                    return "return_consumable_to_storage_request";
                default:
                    return "storage_to_user_consumable";
            }
        }
        case 4:
            return "storage_to_user_bulk";
        default: {
            const notification = this$.fields[0];
            switch (notification.tag) {
                case 1:
                    return "tool_user_to_storage_request";
                case 2:
                    return "tool_storage_to_user";
                case 3:
                    return "tool_user_to_user";
                default:
                    return "tool_user_to_storage";
            }
        }
    }
}

export class OverviewNotification extends Record {
    constructor(Id, Receiver, ReceiverId, Sender, SenderId, EntityId, EntityName, Comment$, PlannedReturnDate, WithSignature, NotificationType) {
        super();
        this.Id = Id;
        this.Receiver = Receiver;
        this.ReceiverId = ReceiverId;
        this.Sender = Sender;
        this.SenderId = SenderId;
        this.EntityId = EntityId;
        this.EntityName = EntityName;
        this.Comment = Comment$;
        this.PlannedReturnDate = PlannedReturnDate;
        this.WithSignature = WithSignature;
        this.NotificationType = NotificationType;
    }
}

export function OverviewNotification_$reflection() {
    return record_type("Shared.Notification.OverviewNotification", [], OverviewNotification, () => [["Id", NotificationId_$reflection()], ["Receiver", string_type], ["ReceiverId", string_type], ["Sender", string_type], ["SenderId", string_type], ["EntityId", string_type], ["EntityName", string_type], ["Comment", string_type], ["PlannedReturnDate", option_type(class_type("System.DateTime"))], ["WithSignature", bool_type], ["NotificationType", string_type]]);
}

export class DataTransferObject_AssignEmailNotificationToolDetail extends Record {
    constructor(Id, ImageUrl, DisplayName, Manufacturer, Model, InventoryNumber) {
        super();
        this.Id = Id;
        this.ImageUrl = ImageUrl;
        this.DisplayName = DisplayName;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.InventoryNumber = InventoryNumber;
    }
}

export function DataTransferObject_AssignEmailNotificationToolDetail_$reflection() {
    return record_type("Shared.Notification.DataTransferObject.AssignEmailNotificationToolDetail", [], DataTransferObject_AssignEmailNotificationToolDetail, () => [["Id", string_type], ["ImageUrl", string_type], ["DisplayName", string_type], ["Manufacturer", string_type], ["Model", string_type], ["InventoryNumber", string_type]]);
}

export class DataTransferObject_AssignEmailNotificationSetDetail extends Record {
    constructor(Id, ImageUrl, DisplayName, Manufacturer, Model, InventoryNumber) {
        super();
        this.Id = Id;
        this.ImageUrl = ImageUrl;
        this.DisplayName = DisplayName;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.InventoryNumber = InventoryNumber;
    }
}

export function DataTransferObject_AssignEmailNotificationSetDetail_$reflection() {
    return record_type("Shared.Notification.DataTransferObject.AssignEmailNotificationSetDetail", [], DataTransferObject_AssignEmailNotificationSetDetail, () => [["Id", string_type], ["ImageUrl", string_type], ["DisplayName", string_type], ["Manufacturer", string_type], ["Model", string_type], ["InventoryNumber", string_type]]);
}

export class DataTransferObject_AssignEmailNotificationToolWithQuantityDetail extends Record {
    constructor(Id, ImageUrl, DisplayName, Manufacturer, Model, InventoryNumber, Quantity) {
        super();
        this.Id = Id;
        this.ImageUrl = ImageUrl;
        this.DisplayName = DisplayName;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.InventoryNumber = InventoryNumber;
        this.Quantity = (Quantity | 0);
    }
}

export function DataTransferObject_AssignEmailNotificationToolWithQuantityDetail_$reflection() {
    return record_type("Shared.Notification.DataTransferObject.AssignEmailNotificationToolWithQuantityDetail", [], DataTransferObject_AssignEmailNotificationToolWithQuantityDetail, () => [["Id", string_type], ["ImageUrl", string_type], ["DisplayName", string_type], ["Manufacturer", string_type], ["Model", string_type], ["InventoryNumber", string_type], ["Quantity", int32_type]]);
}

export class DataTransferObject_AssignEmailNotificationConsumableDetail extends Record {
    constructor(Id, ImageUrl, Name, AdditionalName, InventoryNumber, Quantity) {
        super();
        this.Id = Id;
        this.ImageUrl = ImageUrl;
        this.Name = Name;
        this.AdditionalName = AdditionalName;
        this.InventoryNumber = InventoryNumber;
        this.Quantity = Quantity;
    }
}

export function DataTransferObject_AssignEmailNotificationConsumableDetail_$reflection() {
    return record_type("Shared.Notification.DataTransferObject.AssignEmailNotificationConsumableDetail", [], DataTransferObject_AssignEmailNotificationConsumableDetail, () => [["Id", string_type], ["ImageUrl", string_type], ["Name", string_type], ["AdditionalName", string_type], ["InventoryNumber", string_type], ["Quantity", float64_type]]);
}

export class DataTransferObject_AssignEmailNotificationDetailDto extends Record {
    constructor(Tools, Sets, ToolsWithQuantity, Consumables, SenderName, ReceiverName, WithSignature, OptionalReturnDate, Comment$) {
        super();
        this.Tools = Tools;
        this.Sets = Sets;
        this.ToolsWithQuantity = ToolsWithQuantity;
        this.Consumables = Consumables;
        this.SenderName = SenderName;
        this.ReceiverName = ReceiverName;
        this.WithSignature = WithSignature;
        this.OptionalReturnDate = OptionalReturnDate;
        this.Comment = Comment$;
    }
}

export function DataTransferObject_AssignEmailNotificationDetailDto_$reflection() {
    return record_type("Shared.Notification.DataTransferObject.AssignEmailNotificationDetailDto", [], DataTransferObject_AssignEmailNotificationDetailDto, () => [["Tools", list_type(DataTransferObject_AssignEmailNotificationToolDetail_$reflection())], ["Sets", list_type(DataTransferObject_AssignEmailNotificationSetDetail_$reflection())], ["ToolsWithQuantity", list_type(DataTransferObject_AssignEmailNotificationToolWithQuantityDetail_$reflection())], ["Consumables", list_type(DataTransferObject_AssignEmailNotificationConsumableDetail_$reflection())], ["SenderName", string_type], ["ReceiverName", string_type], ["WithSignature", bool_type], ["OptionalReturnDate", option_type(class_type("System.DateTime"))], ["Comment", string_type]]);
}

export function Helper_setState(state, notification) {
    switch (notification.tag) {
        case 1: {
            const notification_6 = notification.fields[0];
            switch (notification_6.tag) {
                case 1: {
                    const notification_8 = notification_6.fields[0];
                    return new Notification(1, [new ToolWithQuantityNotificationType(1, [new ToolWithQuantityNotification(notification_8.Id, notification_8.Receiver, notification_8.ReceiverId, notification_8.Sender, notification_8.SenderId, notification_8.StorageId, notification_8.Quantity, state, notification_8.WithSignature, notification_8.Comment, notification_8.Tool, notification_8.PlannedReturnDate)])]);
                }
                case 2: {
                    const notification_9 = notification_6.fields[0];
                    return new Notification(1, [new ToolWithQuantityNotificationType(2, [new ToolWithQuantityNotification(notification_9.Id, notification_9.Receiver, notification_9.ReceiverId, notification_9.Sender, notification_9.SenderId, notification_9.StorageId, notification_9.Quantity, state, notification_9.WithSignature, notification_9.Comment, notification_9.Tool, notification_9.PlannedReturnDate)])]);
                }
                case 3: {
                    const notification_10 = notification_6.fields[0];
                    return new Notification(1, [new ToolWithQuantityNotificationType(3, [new ToolWithQuantityNotification(notification_10.Id, notification_10.Receiver, notification_10.ReceiverId, notification_10.Sender, notification_10.SenderId, notification_10.StorageId, notification_10.Quantity, state, notification_10.WithSignature, notification_10.Comment, notification_10.Tool, notification_10.PlannedReturnDate)])]);
                }
                default: {
                    const notification_7 = notification_6.fields[0];
                    return new Notification(1, [new ToolWithQuantityNotificationType(0, [new ToolWithQuantityNotification(notification_7.Id, notification_7.Receiver, notification_7.ReceiverId, notification_7.Sender, notification_7.SenderId, notification_7.StorageId, notification_7.Quantity, state, notification_7.WithSignature, notification_7.Comment, notification_7.Tool, notification_7.PlannedReturnDate)])]);
                }
            }
        }
        case 2: {
            const notification_11 = notification.fields[0];
            switch (notification_11.tag) {
                case 1: {
                    const notification_13 = notification_11.fields[0];
                    return new Notification(2, [new SetNotificationType(1, [new SetNotification(notification_13.Id, notification_13.Receiver, notification_13.ReceiverId, notification_13.Sender, notification_13.SenderId, notification_13.StorageId, state, notification_13.WithSignature, notification_13.Comment, notification_13.Set, notification_13.PlannedReturnDate)])]);
                }
                case 2: {
                    const notification_14 = notification_11.fields[0];
                    return new Notification(2, [new SetNotificationType(2, [new SetNotification(notification_14.Id, notification_14.Receiver, notification_14.ReceiverId, notification_14.Sender, notification_14.SenderId, notification_14.StorageId, state, notification_14.WithSignature, notification_14.Comment, notification_14.Set, notification_14.PlannedReturnDate)])]);
                }
                case 3: {
                    const notification_15 = notification_11.fields[0];
                    return new Notification(2, [new SetNotificationType(3, [new SetNotification(notification_15.Id, notification_15.Receiver, notification_15.ReceiverId, notification_15.Sender, notification_15.SenderId, notification_15.StorageId, state, notification_15.WithSignature, notification_15.Comment, notification_15.Set, notification_15.PlannedReturnDate)])]);
                }
                default: {
                    const notification_12 = notification_11.fields[0];
                    return new Notification(2, [new SetNotificationType(0, [new SetNotification(notification_12.Id, notification_12.Receiver, notification_12.ReceiverId, notification_12.Sender, notification_12.SenderId, notification_12.StorageId, state, notification_12.WithSignature, notification_12.Comment, notification_12.Set, notification_12.PlannedReturnDate)])]);
                }
            }
        }
        case 3: {
            const notification_16 = notification.fields[0];
            switch (notification_16.tag) {
                case 1: {
                    const notification_18 = notification_16.fields[0];
                    return new Notification(3, [new ConsumableNotificationType(1, [new ConsumableNotification(notification_18.Id, notification_18.Receiver, notification_18.ReceiverId, notification_18.Sender, notification_18.SenderId, notification_18.StorageId, notification_18.CommissionNumber, notification_18.WithSignature, notification_18.Quantity, state, notification_18.Comment, notification_18.Consumable)])]);
                }
                case 2: {
                    const notification_19 = notification_16.fields[0];
                    return new Notification(3, [new ConsumableNotificationType(2, [new ConsumableNotification(notification_19.Id, notification_19.Receiver, notification_19.ReceiverId, notification_19.Sender, notification_19.SenderId, notification_19.StorageId, notification_19.CommissionNumber, notification_19.WithSignature, notification_19.Quantity, state, notification_19.Comment, notification_19.Consumable)])]);
                }
                default: {
                    const notification_17 = notification_16.fields[0];
                    return new Notification(3, [new ConsumableNotificationType(0, [new ConsumableNotification(notification_17.Id, notification_17.Receiver, notification_17.ReceiverId, notification_17.Sender, notification_17.SenderId, notification_17.StorageId, notification_17.CommissionNumber, notification_17.WithSignature, notification_17.Quantity, state, notification_17.Comment, notification_17.Consumable)])]);
                }
            }
        }
        case 4: {
            const notification_20 = notification.fields[0];
            return new Notification(4, [new DevicesBulkNotification(notification_20.Id, notification_20.Receiver, notification_20.ReceiverId, notification_20.Sender, notification_20.SenderId, notification_20.DispolistId, notification_20.Comment, notification_20.WithSignature, state, notification_20.DevicesBulkTools, notification_20.DevicesBulkConsumables)]);
        }
        default: {
            const notification_1 = notification.fields[0];
            switch (notification_1.tag) {
                case 1: {
                    const notification_3 = notification_1.fields[0];
                    return new Notification(0, [new ToolNotificationType(1, [new ToolNotification(notification_3.Id, notification_3.Receiver, notification_3.ReceiverId, notification_3.Sender, notification_3.SenderId, notification_3.StorageId, state, notification_3.WithSignature, notification_3.Comment, notification_3.Tool, notification_3.PlannedReturnDate)])]);
                }
                case 2: {
                    const notification_4 = notification_1.fields[0];
                    return new Notification(0, [new ToolNotificationType(2, [new ToolNotification(notification_4.Id, notification_4.Receiver, notification_4.ReceiverId, notification_4.Sender, notification_4.SenderId, notification_4.StorageId, state, notification_4.WithSignature, notification_4.Comment, notification_4.Tool, notification_4.PlannedReturnDate)])]);
                }
                case 3: {
                    const notification_5 = notification_1.fields[0];
                    return new Notification(0, [new ToolNotificationType(3, [new ToolNotification(notification_5.Id, notification_5.Receiver, notification_5.ReceiverId, notification_5.Sender, notification_5.SenderId, notification_5.StorageId, state, notification_5.WithSignature, notification_5.Comment, notification_5.Tool, notification_5.PlannedReturnDate)])]);
                }
                default: {
                    const notification_2 = notification_1.fields[0];
                    return new Notification(0, [new ToolNotificationType(0, [new ToolNotification(notification_2.Id, notification_2.Receiver, notification_2.ReceiverId, notification_2.Sender, notification_2.SenderId, notification_2.StorageId, state, notification_2.WithSignature, notification_2.Comment, notification_2.Tool, notification_2.PlannedReturnDate)])]);
                }
            }
        }
    }
}

export function Helper_notificationBulkToolIds(notification) {
    if (notification.tag === 4) {
        return choose((t) => {
            const matchValue = t.Tool;
            if (matchValue.tag === 1) {
                return undefined;
            }
            else {
                return matchValue.fields[0].Id;
            }
        }, notification.fields[0].DevicesBulkTools);
    }
    else {
        return empty();
    }
}

