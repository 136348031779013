import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, bool_type, union_type, list_type, anonRecord_type, option_type, string_type, class_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { PropertyConfigurationVisibility, PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { utcNow, toString } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { unwrap, ofNullable } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { keyValueList } from "../../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { toArray, singleton, empty, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { addPrefix, token as token_2 } from "../../Communication.js";
import { filter, toArray as toArray_1, empty as empty_1, singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetch$ } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { RequestedValue$1, RequestedValue$1_$reflection } from "../../Shared/Shared.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { fetchWithDecoder, addPrefix as addPrefix_1 } from "../../CommunicationV2.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { ValidationOnGetRequest } from "../../ErrorHandling.js";
import { Cmd_errorToast, Cmd_successToast } from "../../Components/Toast.js";
import { onBlobReceived, getBlob } from "../../Communication.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../Components/Dialog.js";
import { equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { MonthYearDatePicker } from "../../Components/DatePicker.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { Button, TextButton } from "../../Components/Button.js";
import { OnTableRowClick$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { Table_customPropertyColumnDefinitions } from "../../Widgets/CustomProperties.js";
import { RouterModule_encodeParts } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetStartDate", "SetEndDate", "DownloadPositionExcel", "PropertyConfigurationsFetched", "SetSelectedConstructionSiteId", "ExportFileFetched", "BlobReceived", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Report.CustomerReports.PrinzingBaustrom.Msg", [], Msg, () => [[["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [["Item", anonRecord_type(["ConstructionSiteId", option_type(string_type)], ["EndDate", class_type("System.DateTime")], ["StateDate", class_type("System.DateTime")])]], [["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", option_type(string_type)]], [["Item1", class_type("Fetch.Types.Response")], ["Item2", string_type]], [["Item1", class_type("Browser.Types.Blob", undefined)], ["Item2", string_type]], [["Item", class_type("System.Exception")]]]);
}

function exportConsumablesCmd(dto) {
    let matchValue_1;
    const fileName = (dto.ConstructionSiteId == null) ? "prinzing_position_export.zip" : "prinzing_position_export.xlsx";
    const startDate = toString(dto.StateDate, "dd.MM.yyyy");
    const endDate = toString(dto.EndDate, "dd.MM.yyyy");
    return Cmd_OfPromise_either((tupledArg) => {
        const body_1 = tupledArg[2];
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const tenant = ofNullable(localStorage.getItem("tenant"));
            const props = (body_1 == null) ? ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue_2_1;
                return append((matchValue_2_1 = token_2(undefined), (matchValue_2_1 == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2_1]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_3 = tenant;
                    if (matchValue_3 == null) {
                        return empty();
                    }
                    else {
                        return singleton(["X-ONE-Tenant", matchValue_3]);
                    }
                }))));
            })), 0)])]) : ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [body_1]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue_2;
                return append((matchValue_2 = token_2(undefined), (matchValue_2 == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_1_1 = tenant;
                    if (matchValue_1_1 == null) {
                        return empty();
                    }
                    else {
                        return singleton(["X-ONE-Tenant", matchValue_1_1]);
                    }
                }))));
            })), 0)])]);
            return fetch$(addPrefix(tupledArg[0]), props).then((_arg) => (Promise.resolve([_arg, tupledArg[1]])));
        }));
    }, [(matchValue_1 = dto.ConstructionSiteId, (matchValue_1 != null) ? (`/api/reports/prinzing/${matchValue_1}/positions/${startDate}/${endDate}`) : (`/api/reports/prinzing/positions/${startDate}/${endDate}`)), fileName, undefined], (tupledArg_1) => (new Msg(5, [tupledArg_1[0], tupledArg_1[1]])), (Item_2) => (new Msg(7, [Item_2])));
}

class State extends Record {
    constructor(IsLoading, SelectedConstructionSiteId, PropertyConfigurations, StartDate, EndDate) {
        super();
        this.IsLoading = IsLoading;
        this.SelectedConstructionSiteId = SelectedConstructionSiteId;
        this.PropertyConfigurations = PropertyConfigurations;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
    }
}

function State_$reflection() {
    return record_type("Report.CustomerReports.PrinzingBaustrom.State", [], State, () => [["IsLoading", bool_type], ["SelectedConstructionSiteId", option_type(string_type)], ["PropertyConfigurations", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")]]);
}

function init() {
    const today = utcNow();
    return [new State(false, undefined, new RequestedValue$1(0, []), today, today), Cmd_batch(singleton_1(Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix_1(url), (refreshOn) => fetchWithDecoder(url_2, singleton_1(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), "/api/property-configurations", (Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(7, [Item_1])))))];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.IsLoading, state.SelectedConstructionSiteId, state.PropertyConfigurations, state.StartDate, msg.fields[0]), Cmd_none()];
        case 2:
            return [new State(true, state.SelectedConstructionSiteId, state.PropertyConfigurations, state.StartDate, state.EndDate), Cmd_batch(ofArray([Cmd_successToast("Der Download wurde gestartet"), exportConsumablesCmd(msg.fields[0])]))];
        case 3:
            return [new State(state.IsLoading, state.SelectedConstructionSiteId, new RequestedValue$1(1, [msg.fields[0]]), state.StartDate, state.EndDate), Cmd_none()];
        case 4:
            return [new State(state.IsLoading, msg.fields[0], state.PropertyConfigurations, state.StartDate, state.EndDate), Cmd_none()];
        case 5:
            return [new State(false, undefined, state.PropertyConfigurations, state.StartDate, state.EndDate), getBlob(msg.fields[0], msg.fields[1], (tupledArg) => (new Msg(6, [tupledArg[0], tupledArg[1]])), (Item_2) => (new Msg(7, [Item_2])))];
        case 6: {
            onBlobReceived(msg.fields[0], msg.fields[1]);
            return [state, Cmd_none()];
        }
        case 7:
            return [new State(false, state.SelectedConstructionSiteId, state.PropertyConfigurations, state.StartDate, state.EndDate), Cmd_errorToast("Es ist ein Fehler aufgetreten")];
        default:
            return [new State(state.IsLoading, state.SelectedConstructionSiteId, state.PropertyConfigurations, msg.fields[0], state.EndDate), Cmd_none()];
    }
}

function DownloadDialog(props) {
    let Title, elems;
    const t = useTranslation()[0];
    const componentState = props.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("general.download"), {
        Body: singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-5"])], (elems = [createElement(MonthYearDatePicker, {
            ComponentState: componentState,
            IsClearable: false,
            Label: "Von",
            OnChange: (date) => {
                if (date == null) {
                }
                else {
                    props.SetStartDate(date);
                }
            },
            TestId: "select-start-date-test-id",
            Value: props.StartDate,
        }), createElement(MonthYearDatePicker, {
            ComponentState: componentState,
            IsClearable: false,
            Label: "Bis",
            MinDate: props.StartDate,
            OnChange: (date_2) => {
                if (date_2 == null) {
                }
                else {
                    props.SetEndDate(date_2);
                }
            },
            TestId: "select-end-date-test-id",
            Value: props.EndDate,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "download-excel-file-dialog-close",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.download"),
            OnClick: () => {
                const matchValue = props.ConsturctionSiteId;
                if (matchValue == null) {
                }
                else {
                    props.OnSave({
                        ConstructionSiteId: unwrap((matchValue === "") ? undefined : props.ConsturctionSiteId),
                        EndDate: props.EndDate,
                        StateDate: props.StartDate,
                    });
                }
            },
            TestId: "download-excel-file-dialog-save",
            Variant: "blueButton",
        })]),
        IsOpen: props.ConsturctionSiteId != null,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

function ReportContent(props) {
    let elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(singleton_1((elems = toList(delay(() => append(singleton(createElement(DownloadDialog, {
        ConsturctionSiteId: unwrap(props.SelectedConstructionSiteId),
        EndDate: props.EndDate,
        IsLoading: props.IsLoading,
        OnClose: () => {
            props.Dispatch(new Msg(4, [undefined]));
        },
        OnSave: (value) => {
            if (value.ConstructionSiteId == null) {
                props.Dispatch(new Msg(2, [value]));
            }
            else {
                props.Dispatch(new Msg(2, [value]));
            }
        },
        SetEndDate: props.SetEndDate,
        SetStartDate: props.SetStartDate,
        StartDate: props.StartDate,
    })), delay(() => append(singleton(createElement(Button, {
        ComponentState: "enabled",
        Label: "Position .zip Export",
        OnClick: () => {
            props.Dispatch(new Msg(4, [""]));
        },
        TestId: "download-summary-excel-file-dialog-save",
        Variant: "blueButton",
    })), delay(() => singleton(createElement(Table, {
        ColumnDefinitions: toArray(delay(() => append(singleton(new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "text", "text")), delay(() => append(Table_customPropertyColumnDefinitions(props.PropertyConfigurations, t("tool.custom_properties"), (dto_1) => dto_1.CustomProperties, t), delay(() => singleton(new ColumnDefinition$1("positionExcelExport", "", "Position Excel Export", (dto_2) => (new CellContent(2, [t("general.download"), () => {
            props.Dispatch(new Msg(4, [dto_2.Id]));
        }])), "text", "button")))))))),
        Controls: empty_1(),
        Options: {
            BorderStyle: "row",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(dto_3) => RouterModule_encodeParts(ofArray(["receivers", dto_3.Id]), 1)]),
            RowSelection: "disabled",
        },
        TableRows: toArray_1(props.ConstructionSites),
    })))))))), ["children", reactApi.Children.toArray(Array.from(elems))]))));
}

export function PrinzingBaustromReport(props) {
    let patternInput;
    const init_1 = init();
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.ConstructionSites]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const matchValue = state_1.PropertyConfigurations;
    if (matchValue.tag === 0) {
        return createElement(Skeleton, {
            Variant: "table",
        });
    }
    else {
        return createElement(ReportContent, {
            ConstructionSites: props.ConstructionSites,
            Dispatch: dispatch,
            EndDate: state_1.EndDate,
            IsLoading: state_1.IsLoading,
            PropertyConfigurations: filter((propertyConfiguration) => equals(propertyConfiguration.Visibility, new PropertyConfigurationVisibility(8, [])), matchValue.fields[0]),
            SelectedConstructionSiteId: unwrap(state_1.SelectedConstructionSiteId),
            SetEndDate: (date_1) => {
                dispatch(new Msg(1, [date_1]));
            },
            SetStartDate: (date) => {
                dispatch(new Msg(0, [date]));
            },
            StartDate: state_1.StartDate,
        });
    }
}

