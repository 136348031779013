import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { ClientApiResponse$1_$reflection, FormState_$reflection, RequestedValue$1_$reflection, StocktakingId_$reflection } from "../../Shared/Shared.js";
import { union_type, bool_type, string_type, record_type, class_type, list_type, option_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { StocktakingTypeScope_$reflection, StocktakingLocationScope_$reflection, Stocktaking_$reflection, StocktakingState_$reflection, CreateOrUpdateStocktakingDto_$reflection } from "../../Shared/Stocktaking.js";
import { Receiver_$reflection, User_$reflection } from "../../Shared/User.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";

export class State extends Record {
    constructor(StocktakingId, StocktakingForm, Users, Storages, Receiver, PropertyConfigurations, FormState, EditStocktakingState, StartDate, EndDate) {
        super();
        this.StocktakingId = StocktakingId;
        this.StocktakingForm = StocktakingForm;
        this.Users = Users;
        this.Storages = Storages;
        this.Receiver = Receiver;
        this.PropertyConfigurations = PropertyConfigurations;
        this.FormState = FormState;
        this.EditStocktakingState = EditStocktakingState;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
    }
}

export function State_$reflection() {
    return record_type("Stocktaking.Form.Types.State", [], State, () => [["StocktakingId", option_type(StocktakingId_$reflection())], ["StocktakingForm", RequestedValue$1_$reflection(CreateOrUpdateStocktakingDto_$reflection())], ["Users", RequestedValue$1_$reflection(list_type(User_$reflection()))], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["Receiver", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["PropertyConfigurations", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["FormState", FormState_$reflection()], ["EditStocktakingState", option_type(StocktakingState_$reflection())], ["StartDate", option_type(class_type("System.DateTime"))], ["EndDate", option_type(class_type("System.DateTime"))]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["StocktakingFetched", "StocktakingCopyFetched", "CreateStocktaking", "UpdateStocktaking", "StocktakingSaved", "SetName", "SetStartDate", "SetEndDate", "SetDescription", "SetLocationScope", "SetTypeScope", "SetToolsCanBeTaken", "SetIsScannerRequired", "SetHelpers", "RemoveHelper", "RemoveResponsiblePerson", "SetResponsiblePerson", "UsersFetched", "StoragesFetched", "ReceiverFetched", "PropertyConfigurationsFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Stocktaking.Form.Types.Msg", [], Msg, () => [[["Item", Stocktaking_$reflection()]], [["Item", Stocktaking_$reflection()]], [], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", string_type]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", string_type]], [["Item", StocktakingLocationScope_$reflection()]], [["Item", list_type(StocktakingTypeScope_$reflection())]], [["Item", bool_type]], [["Item", bool_type]], [["Item", list_type(User_$reflection())]], [["Item", list_type(User_$reflection())]], [["Item", list_type(User_$reflection())]], [["Item", list_type(User_$reflection())]], [["Item", list_type(User_$reflection())]], [["Item", list_type(Storage_$reflection())]], [["Item", list_type(Receiver_$reflection())]], [["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

