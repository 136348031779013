import { createElement } from "react";
import React from "react";
import { int32ToString, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { sortWith, map, filter, length, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { useTranslation } from "react-i18next";
import { ReactPopover } from "../../Components/ReactPopover.js";
import { IconInput } from "../../Components/Input.js";
import Magnifying_Glass from "../../../public/assets/icons/Magnifying_Glass.svg";
import { delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { sortAsc } from "../../SharedComponents/NaturalOrder.js";
import { TextButton } from "../../Components/Button.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";

export function Element$(props) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["font-medium"])], (elems = [Checkbox({
        ComponentState: "enabled",
        IsChecked: props.Checked,
        Label: props.Id,
        OnCheck: props.OnCheck,
        TestId: `table-select-filter-${props.Id}-checkbox`,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function SelectFilter(props) {
    const patternInput = useTranslation();
    const numberOfSelecteItems = length(filter((item) => item.Checked, props.Items)) | 0;
    return createElement(ReactPopover, {
        Body: (_arg) => {
            let elems_2, elems, elems_1, elems_3;
            return ofArray([createElement("div", createObj(ofArray([["className", join(" ", ["px-6", "py-3", "min-w-max"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems = [createElement(IconInput, {
                ComponentState: "enabled",
                LeftIcon: Magnifying_Glass(),
                OnChange: props.OnSearch,
                TestId: `${props.TestId}-search-value-input`,
                Value: props.SearchValue,
            })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["max-h-[5rem]", "h-sm:max-h-[8rem]", "h-md:max-h-[16rem]", "h-lg:max-h-[30rem]", "overflow-auto"])], (elems_1 = toList(delay(() => map((item_2) => createElement(Element$, {
                Checked: item_2.Checked,
                Id: item_2.Id,
                OnCheck: item_2.OnCheck,
            }), sortWith((item1, item2) => sortAsc(item1.Id, item2.Id), filter((item_1) => (item_1.Id !== ""), props.Items))))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "font-semibold", "pb-2", "min-w-max", "p-2"])], (elems_3 = [createElement(TextButton, {
                ComponentState: "enabled",
                Label: patternInput[0]("table.reset_filter"),
                OnClick: props.OnResetFilter,
                TestId: "",
                Variant: "blueButton",
            })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))]);
        },
        ButtonBadge: unwrap((numberOfSelecteItems === 0) ? undefined : int32ToString(numberOfSelecteItems)),
        ButtonLabel: props.ButtonLabel,
        ComponentState: props.ComponentState,
        TestId: `table-popover-${props.TestId}`,
    });
}

