import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, bool_type, union_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ReminderId, SetId_$reflection } from "../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { deleteReminderCmd } from "../../Requests/Set.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ConfirmationDialog } from "../../Components/Dialog.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DeleteReminder", "ReminderDeleted", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Sets.Dialog.DeleteReminderDialog.Msg", [], Msg, () => [[], [["Item", string_type]], [["Item", class_type("System.Exception")]]]);
}

export class State extends Record {
    constructor(SetId, IsLoading, SuccessCallback, OnClose, ReminderId) {
        super();
        this.SetId = SetId;
        this.IsLoading = IsLoading;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.ReminderId = ReminderId;
    }
}

export function State_$reflection() {
    return record_type("Sets.Dialog.DeleteReminderDialog.State", [], State, () => [["SetId", SetId_$reflection()], ["IsLoading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["ReminderId", string_type]]);
}

function init(props) {
    return [new State(props.SetId, false, props.SuccessCallback, props.OnClose, props.Reminder.Id), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0] === "notAuthorized") {
                return [new State(state.SetId, false, state.SuccessCallback, state.OnClose, state.ReminderId), Cmd_errorToast("validation_error.not_authorized")];
            }
            else {
                return [new State(state.SetId, false, state.SuccessCallback, state.OnClose, state.ReminderId), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast("tool.dialog_remove_reminder_success")]))];
            }
        case 2:
            return [new State(state.SetId, false, state.SuccessCallback, state.OnClose, state.ReminderId), Cmd_none()];
        default:
            return [new State(state.SetId, true, state.SuccessCallback, state.OnClose, state.ReminderId), deleteReminderCmd(state.SetId, new ReminderId(parse(state.ReminderId)), (Item_1) => (new Msg(1, [Item_1])), (Item_2) => (new Msg(2, [Item_2])))];
    }
}

export function OpenDeleteReminderDialog(props) {
    let Title;
    const matchValue = props.Reminder;
    if (matchValue != null) {
        const reminder = matchValue;
        const t = useTranslation()[0];
        let patternInput_1;
        const init_1 = init({
            OnClose: props.OnClose,
            Reminder: reminder,
            SetId: props.SetId,
            SuccessCallback: props.SuccessCallback,
        });
        patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
        }), undefined, [props.IsOpen]);
        return createElement(ConfirmationDialog, (Title = t("general.delete"), {
            Description: t("tool.dialog_remove_reminder_query"),
            IsOpen: props.IsOpen,
            OnClose: props.OnClose,
            OnConfirm: patternInput_1[0].IsLoading ? (() => {
            }) : (() => {
                patternInput_1[1](new Msg(0, []));
            }),
            Title: Title,
        }));
    }
    else {
        return defaultOf();
    }
}

