import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__startLoading, ReportToolProblemDto, FormState_get_empty, FormState_$reflection, ToolId_$reflection, ReportToolProblemDto_$reflection, PostResponse$1_$reflection, EmptyResponse_$reflection } from "../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { unwrapToolId } from "../../Shared/Helper.js";
import { Cmd_successToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { TextArea } from "../../Components/TextArea.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetMessage", "CreateProblemReport", "ProblemReportResponse", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Tools.ToolCreateProblemReportDialog.Msg", [], Msg, () => [[["Item", string_type]], [], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

function postReportToolProblemCmd(dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), ["/api/tools/problem", toString(0, Auto_generateBoxedEncoder_437914C6(ReportToolProblemDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])));
}

class State extends Record {
    constructor(ToolId, FormState, SuccessCallback, OnClose, Dto) {
        super();
        this.ToolId = ToolId;
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Dto = Dto;
    }
}

function State_$reflection() {
    return record_type("Tools.ToolCreateProblemReportDialog.State", [], State, () => [["ToolId", ToolId_$reflection()], ["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Dto", ReportToolProblemDto_$reflection()]]);
}

function init(props) {
    return [new State(props.ToolId, FormState_get_empty(), props.SuccessCallback, props.OnClose, new ReportToolProblemDto(props.UserId, unwrapToolId(props.ToolId), "", undefined)), Cmd_none()];
}

function update(msg, state) {
    let bind$0040;
    switch (msg.tag) {
        case 2:
            return [new State(state.ToolId, FormState_get_empty(), state.SuccessCallback, state.OnClose, state.Dto), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_problem_report_new_success")]))];
        case 1:
            return [new State(state.ToolId, FormState__startLoading(state.FormState), state.SuccessCallback, state.OnClose, state.Dto), postReportToolProblemCmd(state.Dto)];
        case 3:
            return [new State(state.ToolId, FormState_get_empty(), state.SuccessCallback, state.OnClose, state.Dto), Cmd_none()];
        default:
            return [new State(state.ToolId, state.FormState, state.SuccessCallback, state.OnClose, (bind$0040 = state.Dto, new ReportToolProblemDto(bind$0040.SenderId, bind$0040.ToolId, msg.fields[0], bind$0040.ImageUrl))), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = [createElement(TextArea, {
        ComponentState: props.FormState,
        Label: patternInput[0]("general.comment"),
        OnChange: (value_1) => {
            props.Dispatch(new Msg(0, [value_1]));
        },
        TestId: "tool-create-problem-report-dialog-comment-test-id",
        Value: props.Message,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function ToolCreateProblemReportDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        SuccessCallback: props.SuccessCallback,
        ToolId: props.ToolId,
        UserId: props.UserId,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const formState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("tool.create_problem_report"), {
        Body: singleton(createElement(DialogBody, {
            Dispatch: dispatch,
            FormState: formState,
            Message: state_1.Dto.Message,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: formState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-problem-report-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: formState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(1, []));
            },
            TestId: "save-problem-report-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

