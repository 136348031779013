import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { StorageDeleteResponse_$reflection, StorageCreateResponse_$reflection, StorageUpdateResponse_$reflection, Storage_$reflection } from "../Shared/Storage.js";
import { union_type, class_type, array_type, option_type, string_type, record_type, bool_type, list_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { PostResponse$1_$reflection, CustomerConfiguration_$reflection, RequestedValue$1_$reflection } from "../Shared/Shared.js";
import { Location_$reflection } from "../Shared/Location.js";
import { User_$reflection } from "../Shared/User.js";
import { OptionType$1_$reflection } from "../SharedComponents/ReactSelect.js";

export class State extends Record {
    constructor(Storages, Locations, Configuration, Users, StorageForm, IsValidated) {
        super();
        this.Storages = Storages;
        this.Locations = Locations;
        this.Configuration = Configuration;
        this.Users = Users;
        this.StorageForm = StorageForm;
        this.IsValidated = IsValidated;
    }
}

export function State_$reflection() {
    return record_type("Storage.Types.State", [], State, () => [["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["Locations", RequestedValue$1_$reflection(list_type(Location_$reflection()))], ["Configuration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["Users", RequestedValue$1_$reflection(list_type(User_$reflection()))], ["StorageForm", RequestedValue$1_$reflection(Storage_$reflection())], ["IsValidated", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetName", "SetDescription", "SetIsSelfService", "SetLocation", "AddUser", "AddUserToSelfServiceUsers", "RemoveAssignedUser", "SaveStorage", "UpdateStorage", "DeleteStorage", "UsersFetched", "LocationsFetched", "StorageFetched", "StoragesFetched", "StorageUpdated", "StorageCreated", "ConfigurationFetched", "StorageDeleted", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Storage.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", option_type(OptionType$1_$reflection(Location_$reflection()))]], [["Item", array_type(OptionType$1_$reflection(User_$reflection()))]], [["Item", array_type(OptionType$1_$reflection(User_$reflection()))]], [["Item", string_type]], [], [], [], [["Item", list_type(User_$reflection())]], [["Item", list_type(Location_$reflection())]], [["Item", Storage_$reflection()]], [["Item", list_type(Storage_$reflection())]], [["Item", PostResponse$1_$reflection(StorageUpdateResponse_$reflection())]], [["Item", PostResponse$1_$reflection(StorageCreateResponse_$reflection())]], [["Item", CustomerConfiguration_$reflection()]], [["Item", StorageDeleteResponse_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

