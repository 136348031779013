import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, FormState__startLoading, FormState_get_empty, FormState_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { OverviewNotification_$reflection } from "../../Shared/Notification.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { abortNotification, rejectNotification } from "../../Requests/Notification.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { TextValue } from "../../Components/Text.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { toLocalTime, toString } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AbortNotification", "RejectNotification", "NotificationRequestResponse", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Widgets.RejectNotificationDialog.Msg", [], Msg, () => [[], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Notification, FormState, OnConfirm) {
        super();
        this.Notification = Notification;
        this.FormState = FormState;
        this.OnConfirm = OnConfirm;
    }
}

function State_$reflection() {
    return record_type("Widgets.RejectNotificationDialog.State", [], State, () => [["Notification", OverviewNotification_$reflection()], ["FormState", FormState_$reflection()], ["OnConfirm", lambda_type(unit_type, unit_type)]]);
}

function init(notification, onConfirm) {
    return [new State(notification, FormState_get_empty(), onConfirm), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.Notification, FormState__startLoading(state.FormState), state.OnConfirm), rejectNotification(state.Notification.Id, (Item_2) => (new Msg(2, [Item_2])), (Item_3) => (new Msg(3, [Item_3])))];
        case 2: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.Notification, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0]), state.OnConfirm), Cmd_none()];
            }
            else {
                state.OnConfirm();
                return [new State(state.Notification, FormState_get_empty(), state.OnConfirm), Cmd_none()];
            }
        }
        case 3:
            return [state, Cmd_none()];
        default:
            return [new State(state.Notification, FormState__startLoading(state.FormState), state.OnConfirm), abortNotification(state.Notification.Id, (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])))];
    }
}

function DialogBody(props) {
    let elems_9;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    return createElement("div", createObj(singleton_1((elems_9 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-5"])], (elems = [createElement(TextValue, {
            FontWeight: "normal",
            TestId: "",
            Text: props.Description,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_2, elems_1;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_2 = [createElement("div", {
                className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                children: t("general.sender"),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_1 = [createElement(TextValue, {
                FontWeight: "normal",
                TestId: "",
                Text: props.Notification.Sender,
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                let elems_4, elems_3;
                return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_4 = [createElement("div", {
                    className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                    children: t("general.receiver"),
                }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_3 = [createElement(TextValue, {
                    FontWeight: "normal",
                    TestId: "",
                    Text: props.Notification.Receiver,
                })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                    let elems_6, elems_5;
                    return append((props.Notification.Comment !== "") ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_6 = [createElement("div", {
                        className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                        children: t("general.comment"),
                    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_5 = [createElement(TextValue, {
                        FontWeight: "normal",
                        TestId: "",
                        Text: props.Notification.Comment,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))) : empty(), delay(() => {
                        let matchValue, plannedReturnDate, elems_8, elems_7;
                        return append((matchValue = props.Notification.PlannedReturnDate, (matchValue == null) ? (empty()) : ((plannedReturnDate = matchValue, singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_8 = [createElement("div", {
                            className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                            children: t("tool.planned_return_date"),
                        }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_7 = [createElement(TextValue, {
                            FontWeight: "normal",
                            TestId: "",
                            Text: toString(toLocalTime(plannedReturnDate), "dd.MM.yyyy"),
                        })], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", reactApi.Children.toArray(Array.from(elems_8))])]))))))), delay(() => {
                            const matchValue_1 = FormState__getValidation(props.FormState, t, "global");
                            if (matchValue_1 == null) {
                                return empty();
                            }
                            else {
                                return singleton(createElement(ErrorAlert, {
                                    Label: matchValue_1,
                                }));
                            }
                        }));
                    }));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_9))]))));
}

export function RejectNotificationDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.Notification, props.OnConfirm);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const componentState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("notification.reject"), {
        Body: singleton_1(createElement(DialogBody, {
            ComponentState: componentState,
            Description: props.Description,
            Dispatch: dispatch,
            FormState: state_1.FormState,
            Notification: props.Notification,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-accept-notification-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: componentState,
            Label: t("notification.reject"),
            OnClick: () => {
                dispatch(new Msg(1, []));
            },
            TestId: "accept-notification-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

export function AbortNotificationDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.Notification, props.OnConfirm);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const componentState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("general.cancel"), {
        Body: singleton_1(createElement(DialogBody, {
            ComponentState: componentState,
            Description: props.Description,
            Dispatch: dispatch,
            FormState: state_1.FormState,
            Notification: props.Notification,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-accept-notification-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: componentState,
            Label: t("notification.reject"),
            OnClick: () => {
                dispatch(new Msg(0, []));
            },
            TestId: "accept-notification-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

