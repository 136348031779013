import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { Consumable_$reflection } from "../Shared/Consumable.js";
import { union_type, class_type, int32_type, lambda_type, unit_type, string_type, option_type, bool_type, record_type, list_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ToolWithQuantity_$reflection, Tool_$reflection } from "../Shared/Tool.js";
import { Receiver_$reflection } from "../Shared/User.js";
import { DispolistId_$reflection, PostResponse$1_$reflection, Customer_$reflection } from "../Shared/Shared.js";
import { AssignDispolistResponse_$reflection, AssignDispolistWithoutNotification_$reflection, DetailedDispolist_$reflection, DispolistOverview_$reflection } from "../Shared/Dispolist.js";
import { AssignDispolistDto_$reflection } from "../Shared/DevicesBulk.js";

export class NotAssignableTools extends Record {
    constructor(Consumables, Tools, ToolWithQuantity) {
        super();
        this.Consumables = Consumables;
        this.Tools = Tools;
        this.ToolWithQuantity = ToolWithQuantity;
    }
}

export function NotAssignableTools_$reflection() {
    return record_type("Dispolist.Types.NotAssignableTools", [], NotAssignableTools, () => [["Consumables", list_type(Consumable_$reflection())], ["Tools", list_type(Tool_$reflection())], ["ToolWithQuantity", list_type(ToolWithQuantity_$reflection())]]);
}

export class State extends Record {
    constructor(RequestState, Receiver, Customer, Dispolists, SelectedDispolist, RemovedDispolistElementIds, NotAssignableTools, SuccessCallback, OnClose, IsLoading, AssignDispolistDto, AssignDispolistWithoutNotification, WithNotification, Comment$) {
        super();
        this.RequestState = RequestState;
        this.Receiver = Receiver;
        this.Customer = Customer;
        this.Dispolists = Dispolists;
        this.SelectedDispolist = SelectedDispolist;
        this.RemovedDispolistElementIds = RemovedDispolistElementIds;
        this.NotAssignableTools = NotAssignableTools;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.IsLoading = IsLoading;
        this.AssignDispolistDto = AssignDispolistDto;
        this.AssignDispolistWithoutNotification = AssignDispolistWithoutNotification;
        this.WithNotification = WithNotification;
        this.Comment = Comment$;
    }
}

export function State_$reflection() {
    return record_type("Dispolist.Types.State", [], State, () => [["RequestState", bool_type], ["Receiver", option_type(Receiver_$reflection())], ["Customer", Customer_$reflection()], ["Dispolists", list_type(DispolistOverview_$reflection())], ["SelectedDispolist", option_type(DetailedDispolist_$reflection())], ["RemovedDispolistElementIds", list_type(string_type)], ["NotAssignableTools", option_type(NotAssignableTools_$reflection())], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["IsLoading", bool_type], ["AssignDispolistDto", AssignDispolistDto_$reflection()], ["AssignDispolistWithoutNotification", AssignDispolistWithoutNotification_$reflection()], ["WithNotification", bool_type], ["Comment", string_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetWithNotification", "SetComment", "AssignDispolist", "DispolistAssigned", "UpdateElementStorage", "UpdateElementQuantity", "RemoveDispolistElement", "AddDispolistElement", "FetchDispolist", "DispolistsFetched", "ReceiverFetched", "DispolistFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Dispolist.Types.Msg", [], Msg, () => [[["Item", bool_type]], [["Item", string_type]], [], [["Item", PostResponse$1_$reflection(AssignDispolistResponse_$reflection())]], [["id", string_type], ["storageId", string_type]], [["id", string_type], ["value", option_type(string_type)], ["intValue", option_type(int32_type)]], [["id", string_type]], [["id", string_type]], [["Item", DispolistId_$reflection()]], [["Item", list_type(DispolistOverview_$reflection())]], [["Item", Receiver_$reflection()]], [["Item", DetailedDispolist_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

