import { createElement } from "react";
import React from "react";
import { comparePrimitives, stringHash, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { split, join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { toArray as toArray_1, map as map_2, empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { createColumnDefinition, TextTableCell, ButtonTableCell, ImageTableCell, LabelTableCell, ComponentTableCell, DateTimeTableCell, DateTableCell, CheckboxTableCell, TableHeader, CheckboxTableHeader } from "./Table.js";
import { reduce, length, map as map_3, isEmpty, choose, singleton as singleton_1, filter, tryFind as tryFind_1, contains, toArray, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { contains as contains_1, item as item_1, mapIndexed, tryItem, map, collect } from "../../fable_modules/fable-library-js.4.19.2/Array.js";
import { FSharpMap__TryFind, ofArray as ofArray_1, FSharpMap__get_Item, tryFind } from "../../fable_modules/fable-library-js.4.19.2/Map.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { map as map_1, defaultArg, unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { useTranslation } from "react-i18next";
import { TableConfiguration } from "../../Shared/User.js";
import { RouterModule_encodeQueryString, RouterModule_encodeParts, RouterModule_nav, RouterModule_urlSegments } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { getSortedRowModel as getSortedRowModel_1, getFilteredRowModel as getFilteredRowModel_1, getCoreRowModel as getCoreRowModel_1, useReactTable } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { TextButton } from "../../Components/Button.js";
import { IconInput } from "../../Components/Input.js";
import Magnifying_Glass from "../../../public/assets/icons/Magnifying_Glass.svg";
import { ColumnSelect } from "./ColumnSelect.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import Download_File from "../../../public/assets/icons/Download_File.svg";
import { toLocalTime, toString } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { reactApi as reactApi_1 } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { CSVDownload } from "react-csv";

export function InfiniteTableHead(props) {
    let elems;
    return createElement("div", createObj(ofArray([["id", "table-head"], ["className", join(" ", ["row-start-1", "flex", "flex-row", "sticky", "top-0", "z-[1]"])], (elems = toList(delay(() => {
        let matchValue;
        return append((matchValue = props.Options.RowSelection, (matchValue === "singleRow") ? singleton(createElement(CheckboxTableHeader, {
            BorderStyle: props.Options.BorderStyle,
            Checked: props.Table.getIsAllRowsSelected(),
            IsCheckboxVisible: props.Options.RowSelection === "multiRow",
            OnCheck: (arg) => {
                props.Table.toggleAllRowsSelected(arg);
            },
            StickyHeader: props.Options.StickyHeader,
            Table: props.Table,
        })) : ((matchValue === "multiRow") ? singleton(createElement(CheckboxTableHeader, {
            BorderStyle: props.Options.BorderStyle,
            Checked: props.Table.getIsAllRowsSelected(),
            IsCheckboxVisible: props.Options.RowSelection === "multiRow",
            OnCheck: (arg) => {
                props.Table.toggleAllRowsSelected(arg);
            },
            StickyHeader: props.Options.StickyHeader,
            Table: props.Table,
        })) : (empty()))), delay(() => ofArray(collect((x) => x, map((headerGroup) => map((header) => {
            let Key, Text$, ColumnFilter, matchValue_1, FilterValue, OnFilter, objectArg_1;
            return createElement(TableHeader, (($value) => { $value.key = $value.Key; return $value; })((Key = header.id, (Text$ = header.column.columnDef.header, (ColumnFilter = ((matchValue_1 = tryFind(header.id, props.ColumnDefinitionsMap), (matchValue_1 == null) ? "none" : matchValue_1.Filter)), (FilterValue = header.column.getFilterValue(), (OnFilter = ((objectArg_1 = header.column, (arg_1) => {
                objectArg_1.setFilterValue(arg_1);
            })), {
                BorderStyle: props.Options.BorderStyle,
                ColumnDefinitionMap: props.ColumnDefinitionsMap,
                ColumnFilter: ColumnFilter,
                ColumnId: header.id,
                FilterValue: FilterValue,
                Header: header,
                IsDraggable: props.IsDraggable,
                Key: Key,
                OnFilter: OnFilter,
                SetHeaderSize: true,
                SetQueryParams: props.Options.WithQueryParameters,
                StickyHeader: props.Options.StickyHeader,
                Table: props.Table,
                Text: Text$,
            })))))));
        }, headerGroup.headers), props.Table.getHeaderGroups())))));
    })), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function InfiniteBaseTable(props) {
    let elems_2, elems_1;
    const table = props.Table;
    const dependencies = [props.GlobalSearch];
    reactApi.useEffect(() => {
        const matchValue = props.GlobalSearch;
        if (matchValue == null) {
        }
        else {
            const globalSearch = matchValue;
            table.setGlobalFilter(globalSearch);
        }
    }, dependencies);
    const items = props.Virtualizer.getVirtualItems();
    return createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "rounded-md", "w-full", "relative", "grid", "grid-rows-[auto,1fr]"])], ["style", {
        height: props.Virtualizer.getTotalSize(),
    }], (elems_2 = [InfiniteTableHead({
        ColumnDefinitionsMap: props.ColumnDefinitionsMap,
        IsDraggable: props.IsHeaderDraggable,
        Options: {
            BorderStyle: props.Options.BorderStyle,
            ConfigurableColumns: props.Options.ConfigurableColumns,
            OnRowClick: unwrap(props.Options.OnRowClick),
            RowSelection: props.Options.RowSelection,
            StickyHeader: props.Options.StickyHeader,
            WithQueryParameters: props.Options.WithQueryParameters,
        },
        Table: table,
    }), createElement("div", createObj(ofArray([["id", "table-body"], ["className", join(" ", ["absolute", "left-0", "w-full", "z-0", "row-start-2"])], ["style", {
        transform: ("translateY(" + defaultArg(map_1((item) => item.start, tryItem(0, items)), 0)) + "px)",
    }], (elems_1 = toList(delay(() => ofArray(mapIndexed((rowIndex, virtualItem) => {
        let elems;
        const row = item_1(virtualItem.index, table.getRowModel().rows);
        return createElement("div", createObj(ofArray([["key", row.id], ["data-index", virtualItem.index], ["ref", (arg) => {
            props.Virtualizer.measureElement(arg);
        }], ["className", join(" ", toList(delay(() => append(singleton("group"), delay(() => append(singleton("flex"), delay(() => append(singleton("flex-row"), delay(() => ((props.Options.OnRowClick != null) ? singleton("hover:cursor-pointer") : empty()))))))))))], (elems = toList(delay(() => {
            let matchValue_1;
            return append((matchValue_1 = props.Options.RowSelection, (matchValue_1 === "multiRow") ? singleton(createElement(CheckboxTableCell, {
                BorderStyle: props.Options.BorderStyle,
                Checked: row.getIsSelected(),
                OnCheck: (arg_1) => {
                    row.toggleSelected(arg_1);
                },
                Row: row,
            })) : ((matchValue_1 === "singleRow") ? singleton(createElement(CheckboxTableCell, {
                BorderStyle: props.Options.BorderStyle,
                Checked: row.getIsSelected(),
                OnCheck: (arg_1) => {
                    row.toggleSelected(arg_1);
                },
                Row: row,
            })) : (empty()))), delay(() => map((cell) => {
                const columnDefinition = FSharpMap__get_Item(props.ColumnDefinitionsMap, cell.column.id);
                const matchValue_2 = columnDefinition.AccessorFunction(row.original);
                switch (matchValue_2.tag) {
                    case 5:
                        return createElement(DateTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            Cell: cell,
                            Date: unwrap(matchValue_2.fields[0]),
                            OnClick: unwrap(props.Options.OnRowClick),
                            Row: row,
                            TestId: `row-${rowIndex}-column-${columnDefinition.Id}`,
                        });
                    case 6:
                        return createElement(DateTimeTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            Cell: cell,
                            Date: matchValue_2.fields[0],
                            OnClick: unwrap(props.Options.OnRowClick),
                            Row: row,
                        });
                    case 1:
                        return createElement(ComponentTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            Cell: cell,
                            Component: matchValue_2.fields[0],
                            OnClick: unwrap(props.Options.OnRowClick),
                            Row: row,
                        });
                    case 3:
                        return createElement(LabelTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            Cell: cell,
                            Labels: matchValue_2.fields[0],
                            OnClick: unwrap(props.Options.OnRowClick),
                            Row: row,
                        });
                    case 4:
                        return createElement(ImageTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            Cell: cell,
                            ImageUrl: matchValue_2.fields[0],
                            Row: row,
                        });
                    case 2:
                        return createElement(ButtonTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            ButtonContent: {
                                Label: matchValue_2.fields[0],
                                OnClick: matchValue_2.fields[1],
                            },
                            Cell: cell,
                            Row: row,
                        });
                    default:
                        return createElement(TextTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            Cell: cell,
                            OnClick: unwrap(props.Options.OnRowClick),
                            Row: row,
                            TestId: `row-${rowIndex}-column-${columnDefinition.Id}`,
                            Text: matchValue_2.fields[0],
                        });
                }
            }, row.getVisibleCells())));
        })), ["children", reactApi.Children.toArray(Array.from(elems))])])));
    }, items)))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function InfiniteTable(props) {
    let getCoreRowModel, getFilteredRowModel, getSortedRowModel, state, enableRowSelection, matchValue_8, matchValue_9, elems_4;
    const patternInput = useTranslation();
    const patternInput_1 = reactApi.useState(false);
    const patternInput_2 = reactApi.useState("");
    const globalSearch = patternInput_2[0];
    const patternInput_3 = reactApi.useState(() => ({}));
    let patternInput_4;
    const initial_2 = defaultArg(map_1(toArray, props.Options.DefaultColumnFilter), []);
    patternInput_4 = reactApi.useState(initial_2);
    const setColumnFilters = patternInput_4[1];
    const columnFilters = patternInput_4[0];
    let patternInput_5;
    let initial_3;
    const matchValue = props.TableConfiguration;
    if (matchValue == null) {
        initial_3 = {};
    }
    else {
        const matchValue_1 = matchValue.TableConfiguration;
        if (matchValue_1 == null) {
            initial_3 = {};
        }
        else {
            const tableConfiguration_1 = matchValue_1;
            initial_3 = createObj(map((columndDefinition) => [columndDefinition.Id, false], props.ColumnDefinitions.filter((columnDefinition) => !contains(columnDefinition.Id, tableConfiguration_1.VisibleColumns, {
                Equals: (x, y) => (x === y),
                GetHashCode: stringHash,
            }))));
        }
    }
    patternInput_5 = reactApi.useState(initial_3);
    const columnVisibility = patternInput_5[0];
    let patternInput_6;
    let initial_4;
    const matchValue_2 = props.TableConfiguration;
    if (matchValue_2 == null) {
        initial_4 = map((column_1) => column_1.Id, props.ColumnDefinitions);
    }
    else {
        const matchValue_3 = matchValue_2.TableConfiguration;
        initial_4 = ((matchValue_3 == null) ? map((column) => column.Id, props.ColumnDefinitions) : toArray(matchValue_3.ColumnOrder));
    }
    patternInput_6 = reactApi.useState(initial_4);
    const columnOrder = patternInput_6[0];
    let patternInput_7;
    const initial_5 = [{
        desc: props.Options.DefaultSortColumn[1] === "desc",
        id: props.Options.DefaultSortColumn[0],
    }];
    patternInput_7 = reactApi.useState(initial_5);
    const isHeaderDraggable = props.TableConfiguration != null;
    const columnDefinitionMap = ofArray_1(map((columnDefinition_1) => [columnDefinition_1.Id, columnDefinition_1], props.ColumnDefinitions), {
        Compare: comparePrimitives,
    });
    const columnDefinition_2 = createColumnDefinition(props.ColumnDefinitions, columnFilters);
    const getColumnVisibilityConfiguration = (visibility) => {
        let array_5;
        const hiddenColumns_1 = map((tuple) => tuple[0], (array_5 = Array.from(Object.entries(visibility)), array_5.filter((tupledArg) => !tupledArg[1])));
        return map((columndDefinition_1) => columndDefinition_1.Id, props.ColumnDefinitions.filter((columnDefinition_3) => !contains_1(columnDefinition_3.Id, hiddenColumns_1, {
            Equals: (x_2, y_2) => (x_2 === y_2),
            GetHashCode: stringHash,
        })));
    };
    const updateColumnVisibility = (visibility_1) => {
        const matchValue_4 = props.TableConfiguration;
        if (matchValue_4 == null) {
        }
        else {
            matchValue_4.OnSaveTableConfiguration(new TableConfiguration(ofArray(columnOrder), ofArray(getColumnVisibilityConfiguration(visibility_1))));
        }
    };
    const dependencies = [columnVisibility];
    reactApi.useEffect(() => {
        updateColumnVisibility(columnVisibility);
    }, dependencies);
    reactApi.useEffect(() => {
        const matchValue_6 = tryFind_1((segment) => (segment.indexOf("?") === 0), RouterModule_urlSegments(window.location.hash, 1));
        if (matchValue_6 == null) {
        }
        else {
            const queryParams = matchValue_6;
            const queryParamsList = toList(delay(() => map_2((entry) => [item_1(0, entry), item_1(1, entry)], (new URLSearchParams(queryParams)).entries())));
            RouterModule_nav(singleton_1(RouterModule_encodeParts(filter((segment_1) => !(segment_1.indexOf("?") === 0), RouterModule_urlSegments(window.location.hash, 1)), 1) + RouterModule_encodeQueryString(queryParamsList)), 2, 1);
            setColumnFilters(toArray(choose((tupledArg_1) => {
                const queryParamId = tupledArg_1[0];
                const queryParamValue = tupledArg_1[1];
                return map_1((columnDefinition_4) => {
                    const matchValue_7 = columnDefinition_4.Filter;
                    switch (matchValue_7) {
                        case "select":
                            return {
                                id: queryParamId,
                                value: split(queryParamValue, [","], undefined, 0),
                            };
                        case "dateRange":
                            return {
                                id: queryParamId,
                                value: split(queryParamValue, [","], undefined, 0),
                            };
                        default:
                            return {
                                id: queryParamId,
                                value: queryParamValue,
                            };
                    }
                }, FSharpMap__TryFind(columnDefinitionMap, queryParamId));
            }, queryParamsList)));
        }
    }, []);
    const table = useReactTable((getCoreRowModel = getCoreRowModel_1(), (getFilteredRowModel = getFilteredRowModel_1(), (getSortedRowModel = getSortedRowModel_1(), (state = {
        columnFilters: columnFilters,
        columnOrder: columnOrder,
        columnVisibility: columnVisibility,
        rowSelection: patternInput_3[0],
        sorting: patternInput_7[0],
    }, (enableRowSelection = ((matchValue_8 = props.Options.RowSelection, (matchValue_8 === "singleRow") ? true : (matchValue_8 === "multiRow"))), {
        columnResizeMode: "onChange",
        columns: columnDefinition_2,
        data: props.TableRows,
        debugTable: false,
        defaultColumn: {
            maxSize: 10000,
            minSize: 75,
            size: 200,
        },
        enableGlobalFilter: true,
        enableMultiRowSelection: (matchValue_9 = props.Options.RowSelection, (matchValue_9 === "singleRow") ? false : (matchValue_9 === "multiRow")),
        enableRowSelection: enableRowSelection,
        enableSortingRemoval: false,
        getCoreRowModel: getCoreRowModel,
        getFilteredRowModel: getFilteredRowModel,
        getSortedRowModel: getSortedRowModel,
        onColumnFiltersChange: setColumnFilters,
        onColumnOrderChange: (newOrder) => {
            patternInput_6[1](newOrder);
            const matchValue_5 = props.TableConfiguration;
            if (matchValue_5 == null) {
            }
            else {
                matchValue_5.OnSaveTableConfiguration(new TableConfiguration(ofArray(newOrder), ofArray(getColumnVisibilityConfiguration(columnVisibility))));
            }
        },
        onColumnVisibilityChange: (visibility_2) => {
            updateColumnVisibility(visibility_2());
            patternInput_5[1](visibility_2);
        },
        onRowSelectionChange: patternInput_3[1],
        onSortingChange: patternInput_7[1],
        state: state,
    }))))));
    const parentRef = reactApi.useRef(undefined);
    const rowVirtualizer = useVirtualizer({
        count: table.getRowModel().rows.length,
        estimateSize: (_arg_3) => 50,
        getScrollElement: (_arg_2) => parentRef.current,
        overscan: 20,
    });
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-[calc(100%-40px)]"])], (elems_4 = toList(delay(() => {
        let elems_2, elems, elems_1;
        return append((((!isEmpty(props.Controls) ? true : props.Options.ConfigurableColumns) ? true : props.Options.GlobalSearch) ? true : props.Options.CSVDownload) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("flex"), delay(() => append(singleton("px-6"), delay(() => append(singleton("top-[0px]"), delay(() => append(singleton("left-1"), delay(() => append(singleton("bg-[#fefefe]"), delay(() => append(singleton("z-10"), delay(() => {
            const matchValue_10 = props.Options.BorderStyle;
            switch (matchValue_10) {
                case "full":
                case "none": {
                    return empty();
                }
                default:
                    return append(singleton("border-b"), delay(() => singleton("border-border")));
            }
        })))))))))))))))], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "gap-5", "grow"])], (elems = toList(delay(() => map_3((control) => {
            let OnClick, matchValue_11, onClick, array_16;
            return createElement(TextButton, (OnClick = ((matchValue_11 = control.OnClick, (matchValue_11 == null) ? (() => {
            }) : ((onClick = matchValue_11, () => {
                onClick(ofArray(map((row) => row.original, table.getSelectedRowModel().rows)));
                table.toggleAllRowsSelected(false);
            })))), {
                ComponentState: control.EnabledOnlyOnSelection ? (((array_16 = table.getSelectedRowModel().rows, array_16.length === 0)) ? "disabled" : "enabled") : "enabled",
                Icon: unwrap(control.Icon),
                Label: control.Label,
                OnClick: OnClick,
                TestId: "",
                Variant: "default",
            }));
        }, props.Controls))), ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "gap-5", "py-5"])], (elems_1 = toList(delay(() => append(props.Options.GlobalSearch ? singleton(createElement(IconInput, {
            ComponentState: "enabled",
            LeftIcon: Magnifying_Glass(),
            OnChange: patternInput_2[1],
            TestId: "",
            Value: globalSearch,
        })) : empty(), delay(() => append(props.Options.ConfigurableColumns ? singleton(createElement(ColumnSelect, {
            ButtonLabel: patternInput[0]("table.columns"),
            ComponentState: "enabled",
            Items: ofArray(map((column_2) => {
                let matchValue_12;
                const Id = column_2.columnDef.id;
                return {
                    Checked: column_2.getIsVisible(),
                    Group: (matchValue_12 = FSharpMap__TryFind(columnDefinitionMap, column_2.id), (matchValue_12 == null) ? "" : matchValue_12.Group),
                    Id: Id,
                    Name: column_2.columnDef.header,
                    OnCheck: (_arg_5) => {
                        column_2.toggleVisibility(!column_2.getIsVisible());
                    },
                };
            }, table.getAllLeafColumns())),
            TestId: "infinite-table-columm-select",
        })) : empty(), delay(() => {
            let matchValue_13;
            return append((matchValue_13 = props.FilterDropDownElements, (matchValue_13 != null) ? singleton(matchValue_13) : singleton(defaultOf())), delay(() => append(props.Options.CSVDownload ? singleton(createElement(TextButton, {
                ComponentState: "enabled",
                Icon: Download_File(),
                Label: "",
                OnClick: () => {
                    patternInput_1[1](true);
                },
                TestId: "download-csv-button",
                Variant: "default",
            })) : empty(), delay(() => {
                if (patternInput_1[0]) {
                    const csvData = toArray_1(delay(() => {
                        let array_19;
                        return append(singleton(map((column_4) => FSharpMap__get_Item(columnDefinitionMap, column_4.id).Header, (array_19 = table.getAllLeafColumns(), array_19.filter((column_3) => column_3.getIsVisible())))), delay(() => map((row_1) => map((cell) => {
                            const matchValue_14 = FSharpMap__get_Item(columnDefinitionMap, cell.column.id).AccessorFunction(row_1.original);
                            switch (matchValue_14.tag) {
                                case 3:
                                    if (length(matchValue_14.fields[0]) === 0) {
                                        return "";
                                    }
                                    else {
                                        return reduce((labels_1, label_1) => (`${labels_1}, ${label_1}`), map_3((label) => label.Name, matchValue_14.fields[0]));
                                    }
                                case 1:
                                    return "";
                                case 2:
                                    return "";
                                case 4:
                                    return "";
                                case 5:
                                    if (matchValue_14.fields[0] == null) {
                                        return "";
                                    }
                                    else {
                                        const date = matchValue_14.fields[0];
                                        return toString(toLocalTime(date), "dd.MM.yyyy");
                                    }
                                case 6:
                                    return toString(toLocalTime(matchValue_14.fields[0]), "dd.MM.yyyy HH:mm");
                                default:
                                    return matchValue_14.fields[0];
                            }
                        }, row_1.getVisibleCells()), table.getRowModel().rows)));
                    }));
                    return singleton(reactApi_1.createElement(CSVDownload, {
                        data: csvData,
                        separator: ";",
                    }));
                }
                else {
                    return empty();
                }
            }))));
        })))))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))) : empty(), delay(() => {
            let elems_3;
            return singleton(createElement("div", createObj(ofArray([["ref", parentRef], ["className", join(" ", ["h-[calc(100%-80px)]", "overflow-auto", "bg-white"])], (elems_3 = [createElement(InfiniteBaseTable, {
                ColumnDefinitions: props.ColumnDefinitions,
                ColumnDefinitionsMap: columnDefinitionMap,
                GlobalSearch: globalSearch,
                IsHeaderDraggable: isHeaderDraggable,
                Options: {
                    BorderStyle: props.Options.BorderStyle,
                    ConfigurableColumns: props.Options.ConfigurableColumns,
                    OnRowClick: unwrap(props.Options.OnRowClick),
                    RowSelection: props.Options.RowSelection,
                    StickyHeader: true,
                    WithQueryParameters: props.Options.WithQueryParameters,
                },
                Table: table,
                TableRows: props.TableRows,
                Virtualizer: rowVirtualizer,
            })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

