import { RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getCustomerConfiguration } from "../../Requests/Configuration.js";
import { getReservations } from "../../Requests/Reservation.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";

export function init(userData) {
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), userData), Cmd_batch(ofArray([getCustomerConfiguration((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1]))), getReservations((Item_2) => (new Msg(1, [Item_2])), (Item_3) => (new Msg(3, [Item_3])))]))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 2:
            return [new State(state.Reservations, new RequestedValue$1(1, [msg.fields[0]]), state.UserData), Cmd_none()];
        case 1:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.CustomerConfiguration, state.UserData), Cmd_none()];
        case 3:
            return [state, Cmd_none()];
        default:
            return [state, getReservations((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(3, [Item_1])))];
    }
}

