import { PostResponse$1_$reflection, RequestedValue$1, FormKind, DispolistId } from "../../Shared/Shared.js";
import { filter, append, map, ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { utcNow } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { DispolistResponse_$reflection, Helper_toElement, ToolWithQuantityElement, Element$, ConsumableElement, Helper_elementId, Dispolist_$reflection, DispolistElement_$reflection, Dispolist as Dispolist_6 } from "../../Shared/Dispolist.js";
import { Msg, State } from "./Types.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { unwrapDispolistId } from "../../Shared/Helper.js";
import { equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { isNullOrWhiteSpace } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";

export const emptyDispolist = new Dispolist_6(new DispolistId("00000000-0000-0000-0000-000000000000"), "", "", empty(), utcNow(), utcNow(), false);

export function init(userData) {
    return [new State(new FormKind(0, []), new RequestedValue$1(1, [emptyDispolist]), false, new RequestedValue$1(0, []), userData, false, undefined), Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(DispolistElement_$reflection())));
    })))), "/api/dispolists/tools", (Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(9, [Item_1])))];
}

export function initEdit(dispolistId, userData) {
    return [new State(new FormKind(1, []), new RequestedValue$1(0, []), false, new RequestedValue$1(0, []), userData, false, undefined), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Dispolist_$reflection()));
    })))), `/api/dispolists/${unwrapDispolistId(new DispolistId(dispolistId))}`, (Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(9, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(DispolistElement_$reflection())));
    })))), "/api/dispolists/tools", (Item_2) => (new Msg(7, [Item_2])), (Item_3) => (new Msg(9, [Item_3])))]))];
}

export function update(msg, state) {
    let matchValue_6;
    switch (msg.tag) {
        case 1: {
            const matchValue_1 = state.Dispolist;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const dispolist_2 = matchValue_1.fields[0];
                return [new State(state.FormKind, new RequestedValue$1(1, [new Dispolist_6(dispolist_2.Id, dispolist_2.Name, msg.fields[0], dispolist_2.Elements, dispolist_2.CreatedAt, dispolist_2.UpdatedAt, dispolist_2.IsDeleted)]), state.IsLoading, state.Elements, state.UserData, state.IsValidated, state.Message), Cmd_none()];
            }
        }
        case 2: {
            const quantity = msg.fields[1];
            const matchValue_2 = state.Dispolist;
            if (matchValue_2.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const dispolist_4 = matchValue_2.fields[0];
                if (quantity == null) {
                    return [state, Cmd_none()];
                }
                else {
                    const quantity_1 = quantity;
                    return [new State(state.FormKind, new RequestedValue$1(1, [new Dispolist_6(dispolist_4.Id, dispolist_4.Name, dispolist_4.Description, map((element) => {
                        if (Helper_elementId(element) === msg.fields[0]) {
                            switch (element.tag) {
                                case 0: {
                                    const consumable = element.fields[0];
                                    return new Element$(0, [new ConsumableElement(consumable.ConsumableId, consumable.Id, consumable.DisplayName, consumable.Unit, quantity_1, consumable.Type)]);
                                }
                                case 1:
                                    return element;
                                default: {
                                    const toolWithQuantity = element.fields[0];
                                    return new Element$(2, [new ToolWithQuantityElement(toolWithQuantity.ToolId, toolWithQuantity.Id, toolWithQuantity.DisplayName, ~~quantity_1, toolWithQuantity.Type)]);
                                }
                            }
                        }
                        else {
                            return element;
                        }
                    }, dispolist_4.Elements), dispolist_4.CreatedAt, dispolist_4.UpdatedAt, dispolist_4.IsDeleted)]), state.IsLoading, state.Elements, state.UserData, state.IsValidated, state.Message), Cmd_none()];
                }
            }
        }
        case 3: {
            const matchValue_3 = state.Dispolist;
            if (matchValue_3.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const dispolist_6 = matchValue_3.fields[0];
                return [new State(state.FormKind, new RequestedValue$1(1, [new Dispolist_6(dispolist_6.Id, dispolist_6.Name, dispolist_6.Description, append(dispolist_6.Elements, map(Helper_toElement, msg.fields[0])), dispolist_6.CreatedAt, dispolist_6.UpdatedAt, dispolist_6.IsDeleted)]), state.IsLoading, state.Elements, state.UserData, state.IsValidated, state.Message), Cmd_none()];
            }
        }
        case 4: {
            const matchValue_4 = state.Dispolist;
            if (matchValue_4.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const dispolist_8 = matchValue_4.fields[0];
                return [new State(state.FormKind, new RequestedValue$1(1, [new Dispolist_6(dispolist_8.Id, dispolist_8.Name, dispolist_8.Description, filter((e) => !equals(e, msg.fields[0]), dispolist_8.Elements), dispolist_8.CreatedAt, dispolist_8.UpdatedAt, dispolist_8.IsDeleted)]), state.IsLoading, state.Elements, state.UserData, state.IsValidated, state.Message), Cmd_none()];
            }
        }
        case 5:
            return [new State(state.FormKind, new RequestedValue$1(1, [msg.fields[0]]), state.IsLoading, state.Elements, state.UserData, state.IsValidated, state.Message), Cmd_none()];
        case 6: {
            const matchValue_5 = state.Dispolist;
            if (matchValue_5.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const dispolist_10 = matchValue_5.fields[0];
                if (isNullOrWhiteSpace(dispolist_10.Name)) {
                    return [new State(state.FormKind, state.Dispolist, false, state.Elements, state.UserData, true, undefined), Cmd_none()];
                }
                else {
                    return [new State(state.FormKind, state.Dispolist, true, state.Elements, state.UserData, false, undefined), (matchValue_6 = state.FormKind, (matchValue_6.tag === 1) ? Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg_1[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_2) => {
                        const pr_1 = response_2.arrayBuffer();
                        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), PostResponse$1_$reflection(DispolistResponse_$reflection())));
                    })))), ["/api/dispolists", toString(0, Auto_generateBoxedEncoder_437914C6(Dispolist_$reflection(), undefined, undefined, undefined)(dispolist_10))], (Item_4) => (new Msg(8, [Item_4])), (Item_5) => (new Msg(9, [Item_5]))) : ((matchValue_6.tag === 2) ? Cmd_none() : Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response_1) => {
                        const pr = response_1.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(DispolistResponse_$reflection())));
                    })))), ["/api/dispolists", toString(0, Auto_generateBoxedEncoder_437914C6(Dispolist_$reflection(), undefined, undefined, undefined)(dispolist_10))], (Item_2) => (new Msg(8, [Item_2])), (Item_3) => (new Msg(9, [Item_3])))))];
                }
            }
        }
        case 8: {
            let patternInput;
            const matchValue_7 = msg.fields[0].Result;
            let matchResult, dispolistId;
            switch (matchValue_7.tag) {
                case 0: {
                    matchResult = 1;
                    dispolistId = matchValue_7.fields[0];
                    break;
                }
                case 1: {
                    matchResult = 1;
                    dispolistId = matchValue_7.fields[0];
                    break;
                }
                default:
                    matchResult = 0;
            }
            switch (matchResult) {
                case 0: {
                    patternInput = [new State(state.FormKind, state.Dispolist, false, state.Elements, state.UserData, state.IsValidated, "dispolist.dispolist_exists_already"), Cmd_errorToast("dispolist.dispolist_exists_already")];
                    break;
                }
                default:
                    patternInput = [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                        RouterModule_nav(singleton(`/inventory-lists/${dispolistId.fields[0]}`), 1, 1);
                    }), Cmd_successToast("general.saved_successfully")]))];
            }
            return [patternInput[0], patternInput[1]];
        }
        case 7:
            return [new State(state.FormKind, state.Dispolist, state.IsLoading, new RequestedValue$1(1, [msg.fields[0]]), state.UserData, state.IsValidated, state.Message), Cmd_none()];
        case 9:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.Dispolist;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const dispolist = matchValue.fields[0];
                return [new State(state.FormKind, new RequestedValue$1(1, [new Dispolist_6(dispolist.Id, msg.fields[0], dispolist.Description, dispolist.Elements, dispolist.CreatedAt, dispolist.UpdatedAt, dispolist.IsDeleted)]), state.IsLoading, state.Elements, state.UserData, state.IsValidated, state.Message), Cmd_none()];
            }
        }
    }
}

