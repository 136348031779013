import de_1 from "moment/locale/de";
import it_1 from "moment/locale/it";
import en_gb from "moment/locale/en-gb";
import fr_1 from "moment/locale/fr";
import moment_1 from "moment";
import { momentLocalizer } from "react-big-calendar";
import { Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { lambda_type, int32_type, record_type, bool_type, class_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.2/String.js";

export const de = de_1;

export const it = it_1;

export const en = en_gb;

export const fr = fr_1;

export const moment = moment_1;

export function localization(currentLanguage) {
    const currentLanguage_1 = (currentLanguage === "en") ? "en-gb" : ((currentLanguage === "de") ? currentLanguage : ((currentLanguage === "fr") ? currentLanguage : ((currentLanguage === "it") ? currentLanguage : "de")));
    const currentMoment = moment;
    currentMoment.lang(currentLanguage_1);
    return momentLocalizer(currentMoment);
}

export class Event$ extends Record {
    constructor(id, title, start, end, allDay) {
        super();
        this.id = id;
        this.title = title;
        this.start = start;
        this.end = end;
        this.allDay = allDay;
    }
}

export function Event$_$reflection() {
    return record_type("SharedComponents.ReactBigCalendar.Event", [], Event$, () => [["id", string_type], ["title", string_type], ["start", class_type("System.DateTime")], ["end", class_type("System.DateTime")], ["allDay", bool_type]]);
}

export class Messages extends Record {
    constructor(allDay, previous, next, today, month, week, day, agenda, date, time, event, noEventsInRange, showMore) {
        super();
        this.allDay = allDay;
        this.previous = previous;
        this.next = next;
        this.today = today;
        this.month = month;
        this.week = week;
        this.day = day;
        this.agenda = agenda;
        this.date = date;
        this.time = time;
        this.event = event;
        this.noEventsInRange = noEventsInRange;
        this.showMore = showMore;
    }
}

export function Messages_$reflection() {
    return record_type("SharedComponents.ReactBigCalendar.Messages", [], Messages, () => [["allDay", string_type], ["previous", string_type], ["next", string_type], ["today", string_type], ["month", string_type], ["week", string_type], ["day", string_type], ["agenda", string_type], ["date", string_type], ["time", string_type], ["event", string_type], ["noEventsInRange", string_type], ["showMore", lambda_type(int32_type, string_type)]]);
}

export function getMessages(currentLanguage) {
    switch (currentLanguage) {
        case "en":
            return new Messages("all day", "<", ">", "Today", "Month", "Week", "Day", "Agenda", "Date", "Time", "Event", "There are no dates available.", (x) => toText(printf("show more %i"))(x));
        case "de":
            return new Messages("ganztägig", "<", ">", "Heute", "Monat", "Woche", "Tag", "Agenda", "Datum", "Zeit", "Ereignis", "Es liegen keine Termine vor.", (x_1) => toText(printf("mehr anzeigen %i"))(x_1));
        case "fr":
            return new Messages("toute la journée", "<", ">", "Aujourd\'hui", "Mois", "Semaine", "Étiquette", "Agenda", "Date", "Temps", "Événement", "Il n\'y a pas de dates disponibles.", (x_2) => toText(printf("mehr anzeigen %i"))(x_2));
        case "it":
            return new Messages("tutto il giorno", "<", ">", "oggi", "Mese", "Settimana", "Tag", "Agenda", "Data", "Tempo", "Evento", "Non ci sono date disponibili.", (x_3) => toText(printf("mehr anzeigen %i"))(x_3));
        default:
            return new Messages("ganztägig", "<", ">", "Heute", "Monat", "Woche", "Tag", "Agenda", "Datum", "Zeit", "Ereignis", "Es liegen keine Termine vor.", (x_4) => toText(printf("mehr anzeigen %i"))(x_4));
    }
}

