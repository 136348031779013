import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { DataTransfer_OverviewSet_$reflection } from "../../Shared/Set.js";
import { union_type, class_type, record_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { AccountSettings_$reflection, CustomerConfiguration_$reflection, RequestedValue$1_$reflection } from "../../Shared/Shared.js";
import { PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";

export class State extends Record {
    constructor(Sets, PropertyConfiguration, CustomerConfiguration, AccountSettings) {
        super();
        this.Sets = Sets;
        this.PropertyConfiguration = PropertyConfiguration;
        this.CustomerConfiguration = CustomerConfiguration;
        this.AccountSettings = AccountSettings;
    }
}

export function State_$reflection() {
    return record_type("Sets.Overview.Types.State", [], State, () => [["Sets", RequestedValue$1_$reflection(list_type(DataTransfer_OverviewSet_$reflection()))], ["PropertyConfiguration", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["AccountSettings", RequestedValue$1_$reflection(AccountSettings_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PropertyConfigurationsFetched", "SetsFetched", "CustomerConfigurationFetched", "AccountSettingsFetched", "FetchSets", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Sets.Overview.Types.Msg", [], Msg, () => [[["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", list_type(DataTransfer_OverviewSet_$reflection())]], [["Item", CustomerConfiguration_$reflection()]], [["Item", AccountSettings_$reflection()]], [], [["Item", class_type("System.Exception")]]]);
}

