import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, SetId, RequestedValue$1, FormState_get_empty, RequestedValue$1_$reflection, FormState_$reflection, TokenDataDto_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { Receiver_$reflection } from "../../Shared/User.js";
import { DataTransfer_UnassignSetDto, DataTransfer_UnassignSetDto_$reflection, DataTransfer_DetailSet_$reflection } from "../../Shared/Set.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { unassignCmd } from "../../Requests/Set.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextArea } from "../../Components/TextArea.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Dialog } from "../../Components/Dialog.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UnassignedResponse", "OnUnassign", "SetComment", "ReceiverFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Sets.Dialog.UnassignDialog.Msg", [], Msg, () => [[["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", string_type]], [["Item", Receiver_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Set$, UserData, FormState, SuccessCallback, OnClose, Receiver, Dto) {
        super();
        this.Set = Set$;
        this.UserData = UserData;
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Receiver = Receiver;
        this.Dto = Dto;
    }
}

function State_$reflection() {
    return record_type("Sets.Dialog.UnassignDialog.State", [], State, () => [["Set", DataTransfer_DetailSet_$reflection()], ["UserData", TokenDataDto_$reflection()], ["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Receiver", RequestedValue$1_$reflection(Receiver_$reflection())], ["Dto", DataTransfer_UnassignSetDto_$reflection()]]);
}

function init(props) {
    const initialModel = new State(props.Set, props.UserData, FormState_get_empty(), props.SuccessCallback, props.OnClose, new RequestedValue$1(0, []), new DataTransfer_UnassignSetDto(""));
    const matchValue = props.Set.LocationInformation.AssignedReceiverId;
    if (matchValue == null) {
        return [initialModel, Cmd_none()];
    }
    else {
        return [initialModel, Cmd_batch(singleton(Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Receiver_$reflection()));
        })))), `/api/receivers/receiver/${matchValue}`, (Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(4, [Item_1])))))];
    }
}

function update(msg, state) {
    switch (msg.tag) {
        case 3:
            return [new State(state.Set, state.UserData, state.FormState, state.SuccessCallback, state.OnClose, new RequestedValue$1(1, [msg.fields[0]]), state.Dto), Cmd_none()];
        case 1: {
            const matchValue = state.Set.LocationInformation.AssignedReceiverId;
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                const assignedReceiver = matchValue;
                return [new State(state.Set, state.UserData, FormState_get_empty(), state.SuccessCallback, state.OnClose, state.Receiver, state.Dto), unassignCmd(new SetId(parse(state.Set.Id)), state.Dto, (Item_1) => (new Msg(0, [Item_1])), (Item_2) => (new Msg(4, [Item_2])))];
            }
        }
        case 2:
            return [new State(state.Set, state.UserData, state.FormState, state.SuccessCallback, state.OnClose, state.Receiver, new DataTransfer_UnassignSetDto(msg.fields[0])), Cmd_none()];
        case 4:
            return [new State(state.Set, state.UserData, FormState_get_empty(), state.SuccessCallback, state.OnClose, state.Receiver, state.Dto), Cmd_none()];
        default: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.Set, state.UserData, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0]), state.SuccessCallback, state.OnClose, state.Receiver, state.Dto), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.Set, state.UserData, FormState_get_empty(), state.SuccessCallback, state.OnClose, state.Receiver, state.Dto), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
    }
}

function DialogBody(props) {
    let elems_1;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const formState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-5"])], (elems_1 = toList(delay(() => {
        let arg_1;
        return append(singleton_1(createElement("p", {
            children: (arg_1 = {
                user: props.Receiver.Name,
            }, patternInput[1].t("tool.dialog_unassign_tool_query", arg_1)),
        })), delay(() => {
            let elems;
            return append(singleton_1(createElement("div", createObj(singleton((elems = [createElement(TextArea, {
                ComponentState: formState,
                Label: t("general.comment"),
                OnChange: props.SetComment,
                TestId: "unassign-tool-dialog-comment-test-id",
                Value: props.Comment,
            })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), delay(() => {
                const matchValue = FormState__getValidation(props.FormState, t, "global");
                if (matchValue == null) {
                    return empty();
                }
                else {
                    return singleton_1(createElement(ErrorAlert, {
                        Label: matchValue,
                    }));
                }
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function OpenUnassignDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        Set: props.Set,
        SuccessCallback: props.SuccessCallback,
        UserData: props.UserData,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    if (state_1.Set.LocationInformation.AssignedReceiverId == null) {
        return defaultOf();
    }
    else {
        return createElement(Dialog, (Title = t("tool.back_to_storage"), {
            Body: toList(delay(() => {
                const matchValue_1 = state_1.Receiver;
                return (matchValue_1.tag === 0) ? singleton_1(createElement(Skeleton, {
                    Variant: "normal",
                })) : singleton_1(createElement(DialogBody, {
                    Comment: state_1.Dto.Comment,
                    FormState: state_1.FormState,
                    Receiver: matchValue_1.fields[0],
                    SetComment: (value) => {
                        dispatch(new Msg(2, [value]));
                    },
                }));
            })),
            Controls: ofArray([createElement(TextButton, {
                ComponentState: "enabled",
                Label: t("general.cancel"),
                OnClick: props.OnClose,
                TestId: "close-reservation-dialog-test-id",
                Variant: "blueButton",
            }), createElement(TextButton, {
                ComponentState: state_1.FormState.IsLoading ? "disabled" : "enabled",
                Label: t("general.save"),
                OnClick: () => {
                    dispatch(new Msg(1, []));
                },
                TestId: "save-unassign-dialog-test-id",
                Variant: "blueButton",
            })]),
            IsOpen: props.IsOpen,
            OnClose: props.OnClose,
            Title: Title,
        }));
    }
}

