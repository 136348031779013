import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, list_type, bool_type, class_type, int32_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { AdditionalReminderDefinitionDto, CustomIntervalDefinition, ReminderInterval, SetId_$reflection, ReminderInterval_$reflection } from "../../Shared/Shared.js";
import { AddReminderDto, AddReminderDto_$reflection } from "../../Shared/Tool.js";
import { utcNow } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { newGuid } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { tryFind, ofArray, map, empty, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Cmd_getReminderNameSuggestions } from "../../Requests/Tool.js";
import { map as map_1, unwrap, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createObj, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join, isNullOrWhiteSpace, isNullOrEmpty } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { postReminderCmd } from "../../Requests/Set.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { InformationAlert } from "../../Components/Alert.js";
import { RadioButton } from "../../Components/RadioButton.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { AutoSuggestInput } from "../../Components/Input.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { Select } from "../../Components/Select.js";
import { DayWeekMonthYearInput } from "../../Widgets/DayWeekMonthYearInput.js";
import { AdditionalReminderWidget } from "../../Widgets/AdditionalReminderWidget.js";
import { TextArea } from "../../Components/TextArea.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetAdditionalReminderName", "SetAdditionalReminderInterval", "SetCustomIntervalValue", "SetReminderInterval", "SetDate", "SetTitle", "SetDescription", "SetIsNormalReminder", "ReminderSaved", "SaveReminderDate", "ReminderNameSuggestionsFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Sets.Dialog.CreateReminderDialog.Msg", [], Msg, () => [[["id", string_type], ["name", string_type]], [["id", string_type], ["interval", string_type], ["value", int32_type]], [["Item1", string_type], ["value", int32_type]], [["Item", ReminderInterval_$reflection()]], [["Item", class_type("System.DateTime")]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [], [["Item", list_type(string_type)]], [["Item", class_type("System.Exception")]]]);
}

export class State extends Record {
    constructor(SetId, IsLoading, SuccessCallback, Dto, OnClose, IsNormalReminder, IsTitleInvalid, ReminderNameSuggestions) {
        super();
        this.SetId = SetId;
        this.IsLoading = IsLoading;
        this.SuccessCallback = SuccessCallback;
        this.Dto = Dto;
        this.OnClose = OnClose;
        this.IsNormalReminder = IsNormalReminder;
        this.IsTitleInvalid = IsTitleInvalid;
        this.ReminderNameSuggestions = ReminderNameSuggestions;
    }
}

export function State_$reflection() {
    return record_type("Sets.Dialog.CreateReminderDialog.State", [], State, () => [["SetId", SetId_$reflection()], ["IsLoading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["Dto", AddReminderDto_$reflection()], ["OnClose", lambda_type(unit_type, unit_type)], ["IsNormalReminder", bool_type], ["IsTitleInvalid", bool_type], ["ReminderNameSuggestions", list_type(string_type)]]);
}

function init(props) {
    return [new State(props.SetId, false, props.SuccessCallback, new AddReminderDto(utcNow(), "", "", new ReminderInterval(4, []), undefined, singleton(new AdditionalReminderDefinitionDto(newGuid(), "", new CustomIntervalDefinition("day", 3)))), props.OnClose, true, false, empty()), Cmd_getReminderNameSuggestions((Item) => (new Msg(10, [Item])), (Item_1) => (new Msg(11, [Item_1])))];
}

function update(msg, state) {
    let bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4, bind$0040_5, bind$0040_6, bind$0040_7, bind$0040;
    switch (msg.tag) {
        case 1:
            return [new State(state.SetId, state.IsLoading, state.SuccessCallback, (bind$0040_1 = state.Dto, new AddReminderDto(bind$0040_1.Date, bind$0040_1.Title, bind$0040_1.Description, bind$0040_1.Interval, bind$0040_1.CustomInterval, map((ar_1) => {
                if (ar_1.Id === msg.fields[0]) {
                    return new AdditionalReminderDefinitionDto(ar_1.Id, ar_1.Name, new CustomIntervalDefinition(msg.fields[1], msg.fields[2]));
                }
                else {
                    return ar_1;
                }
            }, defaultArg(state.Dto.AdditionalReminders, empty())))), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        case 2:
            return [new State(state.SetId, state.IsLoading, state.SuccessCallback, (bind$0040_2 = state.Dto, new AddReminderDto(bind$0040_2.Date, bind$0040_2.Title, bind$0040_2.Description, bind$0040_2.Interval, new CustomIntervalDefinition(msg.fields[0], msg.fields[1]), bind$0040_2.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        case 3: {
            const value_4 = msg.fields[0];
            return [new State(state.SetId, state.IsLoading, state.SuccessCallback, (bind$0040_3 = state.Dto, new AddReminderDto(bind$0040_3.Date, bind$0040_3.Title, bind$0040_3.Description, value_4, !equals(value_4, new ReminderInterval(6, [])) ? undefined : (new CustomIntervalDefinition("day", 0)), bind$0040_3.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        }
        case 5:
            return [new State(state.SetId, state.IsLoading, state.SuccessCallback, (bind$0040_4 = state.Dto, new AddReminderDto(bind$0040_4.Date, msg.fields[0], bind$0040_4.Description, bind$0040_4.Interval, bind$0040_4.CustomInterval, bind$0040_4.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        case 4:
            return [new State(state.SetId, state.IsLoading, state.SuccessCallback, (bind$0040_5 = state.Dto, new AddReminderDto(msg.fields[0], bind$0040_5.Title, bind$0040_5.Description, bind$0040_5.Interval, bind$0040_5.CustomInterval, bind$0040_5.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        case 6:
            return [new State(state.SetId, state.IsLoading, state.SuccessCallback, (bind$0040_6 = state.Dto, new AddReminderDto(bind$0040_6.Date, bind$0040_6.Title, msg.fields[0], bind$0040_6.Interval, bind$0040_6.CustomInterval, bind$0040_6.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        case 7:
            return [new State(state.SetId, state.IsLoading, state.SuccessCallback, state.Dto, state.OnClose, msg.fields[0], state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        case 9:
            if (isNullOrEmpty(state.Dto.Title) ? true : isNullOrWhiteSpace(state.Dto.Title)) {
                return [new State(state.SetId, state.IsLoading, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, true, state.ReminderNameSuggestions), Cmd_none()];
            }
            else {
                return [new State(state.SetId, true, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), postReminderCmd(state.SetId, state.IsNormalReminder ? ((bind$0040_7 = state.Dto, new AddReminderDto(bind$0040_7.Date, bind$0040_7.Title, bind$0040_7.Description, undefined, bind$0040_7.CustomInterval, bind$0040_7.AdditionalReminders))) : state.Dto, (Item) => (new Msg(8, [Item])), (Item_1) => (new Msg(11, [Item_1])))];
            }
        case 8:
            if (msg.fields[0] === "notAuthorized") {
                return [new State(state.SetId, false, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_errorToast("validation_error.not_authorized")];
            }
            else {
                return [new State(state.SetId, false, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast("tool.dialog_add_reminder_success")]))];
            }
        case 10:
            return [new State(state.SetId, state.IsLoading, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, msg.fields[0]), Cmd_none()];
        case 11:
            return [new State(state.SetId, false, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        default:
            return [new State(state.SetId, state.IsLoading, state.SuccessCallback, (bind$0040 = state.Dto, new AddReminderDto(bind$0040.Date, bind$0040.Title, bind$0040.Description, bind$0040.Interval, bind$0040.CustomInterval, map((ar) => {
                if (ar.Id === msg.fields[0]) {
                    return new AdditionalReminderDefinitionDto(ar.Id, msg.fields[1], ar.Interval);
                }
                else {
                    return ar;
                }
            }, defaultArg(state.Dto.AdditionalReminders, empty())))), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems_1;
    const t = useTranslation()[0];
    const formState = props.State.IsLoading ? "disabled" : "enabled";
    const intervalOptions = ofArray([{
        label: t("reminder_dialog.weekly"),
        value: new ReminderInterval(0, []),
    }, {
        label: t("reminder_dialog.monthly"),
        value: new ReminderInterval(1, []),
    }, {
        label: t("reminder_dialog.quarterly"),
        value: new ReminderInterval(2, []),
    }, {
        label: t("reminder_dialog.halfyearly"),
        value: new ReminderInterval(3, []),
    }, {
        label: t("reminder_dialog.yearly"),
        value: new ReminderInterval(4, []),
    }, {
        label: t("reminder_dialog.twoyearly"),
        value: new ReminderInterval(5, []),
    }, {
        label: t("reminder_dialog.user_defined"),
        value: new ReminderInterval(6, []),
    }]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4", "w-[30rem]"])], (elems_1 = toList(delay(() => append(props.State.IsNormalReminder ? singleton_1(defaultOf()) : singleton_1(createElement(InformationAlert, {
        Label: "reminder_dialog.interval_reminder_info",
    })), delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-2"])], (elems = [RadioButton({
            ComponentState: formState,
            Label: t("reminder_dialog.normal_reminder"),
            OnSelect: (_arg) => {
                props.Dispatch(new Msg(7, [true]));
            },
            Selected: props.State.IsNormalReminder,
        }), RadioButton({
            ComponentState: formState,
            Label: t("reminder_dialog.interval_reminder"),
            OnSelect: (_arg_1) => {
                props.Dispatch(new Msg(7, [false]));
            },
            Selected: !props.State.IsNormalReminder,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let dependencies_1;
            return append(singleton_1((dependencies_1 = [props.State.Dto.Title, props.ReminderSuggestions, props.State.IsTitleInvalid, formState], reactApi.useMemo(() => createElement(AutoSuggestInput, {
                ComponentState: formState,
                Label: t("general.name"),
                OnChange: (value_4) => {
                    props.Dispatch(new Msg(5, [value_4]));
                },
                Suggestions: props.ReminderSuggestions,
                TestId: "",
                ValidationMessage: unwrap(props.State.IsTitleInvalid ? t("tool.reminder_invalid_msg") : undefined),
                Value: props.State.Dto.Title,
            }), dependencies_1))), delay(() => append(singleton_1(createElement(DatePicker, {
                ComponentState: formState,
                IsClearable: false,
                Label: props.State.IsNormalReminder ? t("general.date") : t("reminder_dialog.first_execution"),
                OnChange: (date) => {
                    if (date == null) {
                    }
                    else {
                        props.Dispatch(new Msg(4, [date]));
                    }
                },
                TestId: "",
                Value: props.State.Dto.Date,
            })), delay(() => {
                let matchValue, interval;
                return append(props.State.IsNormalReminder ? singleton_1(defaultOf()) : singleton_1(createElement(Select, {
                    ComponentState: formState,
                    IsClearable: false,
                    Label: t("reminder_dialog.interval"),
                    NoOptionsMessage: "",
                    OnChange: (option) => {
                        if (option == null) {
                        }
                        else {
                            props.Dispatch(new Msg(3, [option.value]));
                        }
                    },
                    Options: intervalOptions,
                    PlaceholderKey: "",
                    TestId: "",
                    Value: unwrap((matchValue = props.State.Dto.Interval, (matchValue == null) ? {
                        label: t("reminder_dialog.yearly"),
                        value: new ReminderInterval(4, []),
                    } : ((interval = matchValue, tryFind((option_2) => equals(option_2.value, interval), intervalOptions))))),
                })), delay(() => {
                    let matchValue_1, matchValue_2, Value_3;
                    return append((matchValue_1 = props.State.Dto.Interval, (matchValue_1 == null) ? (empty_1()) : ((matchValue_2 = matchValue_1, (matchValue_2.tag === 1) ? (empty_1()) : ((matchValue_2.tag === 2) ? (empty_1()) : ((matchValue_2.tag === 3) ? (empty_1()) : ((matchValue_2.tag === 4) ? (empty_1()) : ((matchValue_2.tag === 5) ? (empty_1()) : ((matchValue_2.tag === 6) ? singleton_1(createElement(DayWeekMonthYearInput, (Value_3 = (defaultArg(map_1((ci) => ci.Value, props.State.Dto.CustomInterval), 0) | 0), {
                        ComponentState: formState,
                        InitialInterval: unwrap(map_1((ci_1) => ci_1.Interval, props.State.Dto.CustomInterval)),
                        IsBefore: false,
                        SetValue: (value_6) => {
                            let tupledArg;
                            props.Dispatch((tupledArg = value_6, new Msg(2, [tupledArg[0], tupledArg[1]])));
                        },
                        Value: Value_3,
                    }))) : (empty_1()))))))))), delay(() => append(singleton_1(createElement(AdditionalReminderWidget, {
                        AdditionlReminders: defaultArg(props.State.Dto.AdditionalReminders, empty()),
                        ComponentState: formState,
                        SetInterval: (tupledArg_2) => {
                            props.Dispatch(new Msg(1, [tupledArg_2[0], tupledArg_2[1], tupledArg_2[2]]));
                        },
                        SetName: (tupledArg_1) => {
                            props.Dispatch(new Msg(0, [tupledArg_1[0], tupledArg_1[1]]));
                        },
                    })), delay(() => singleton_1(createElement(TextArea, {
                        ComponentState: formState,
                        Label: t("general.description"),
                        OnChange: (value_11) => {
                            props.Dispatch(new Msg(6, [value_11]));
                        },
                        TestId: "tool-add-reminder-description-test-id",
                        Value: props.State.Dto.Description,
                    }))))));
                }));
            }))));
        }));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function OpenCreateReminderDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        SetId: props.SetId,
        SuccessCallback: props.SuccessCallback,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("tool.create_reminder"), {
        Body: singleton(createElement(DialogBody, {
            Dispatch: dispatch,
            ReminderSuggestions: state_1.ReminderNameSuggestions,
            State: state_1,
            SuccessCallback: props.SuccessCallback,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-reservation-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: state_1.IsLoading ? "disabled" : "enabled",
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(9, []));
            },
            TestId: "save-reminder-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

