import { Record, Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { array_type, uint8_type, float64_type, int32_type, list_type, bool_type, option_type, record_type, string_type, class_type, union_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { parse, newGuid } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import { map, singleton, append } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { FSharpMap__TryFind, FSharpMap__get_IsEmpty, toList, ofList, FSharpMap__Add, empty } from "../fable_modules/fable-library-js.4.19.2/Map.js";
import { comparePrimitives } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { map as map_1 } from "../fable_modules/fable-library-js.4.19.2/Option.js";

export class RemoteValue$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Available", "Unavailable"];
    }
}

export function RemoteValue$1_$reflection(gen0) {
    return union_type("Shared.RemoteValue`1", [gen0], RemoteValue$1, () => [[["Item", gen0]], []]);
}

export class ToolId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ToolId"];
    }
}

export function ToolId_$reflection() {
    return union_type("Shared.ToolId", [], ToolId, () => [[["Item", class_type("System.Guid")]]]);
}

export function ToolId__get_unwrap(this$) {
    return this$.fields[0];
}

export class ManufacturerId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ManufacturerId"];
    }
}

export function ManufacturerId_$reflection() {
    return union_type("Shared.ManufacturerId", [], ManufacturerId, () => [[["Item", string_type]]]);
}

export class ModelId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ModelId"];
    }
}

export function ModelId_$reflection() {
    return union_type("Shared.ModelId", [], ModelId, () => [[["Item", string_type]]]);
}

export class StorageId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["StorageId"];
    }
}

export function StorageId_$reflection() {
    return union_type("Shared.StorageId", [], StorageId, () => [[["Item", class_type("System.Guid")]]]);
}

export function StorageId__get_unwrap(this$) {
    return this$.fields[0];
}

export class ProblemReportId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ProblemReportId"];
    }
}

export function ProblemReportId_$reflection() {
    return union_type("Shared.ProblemReportId", [], ProblemReportId, () => [[["Item", class_type("System.Guid")]]]);
}

export function ProblemReportId__get_unwrap(this$) {
    return this$.fields[0];
}

export class UserId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["UserId"];
    }
}

export function UserId_$reflection() {
    return union_type("Shared.UserId", [], UserId, () => [[["Item", string_type]]]);
}

export function UserId__get_unwrap(this$) {
    return this$.fields[0];
}

export class StocktakingId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["StocktakingId"];
    }
}

export function StocktakingId_$reflection() {
    return union_type("Shared.StocktakingId", [], StocktakingId, () => [[["Item", class_type("System.Guid")]]]);
}

export function StocktakingId__get_unwrap(this$) {
    return this$.fields[0];
}

export class StocktakingListId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["StocktakingListId"];
    }
}

export function StocktakingListId_$reflection() {
    return union_type("Shared.StocktakingListId", [], StocktakingListId, () => [[["Item", class_type("System.Guid")]]]);
}

export function StocktakingListId__get_unwrap(this$) {
    return this$.fields[0];
}

export class ShoppingCartId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ShoppingCartId"];
    }
}

export function ShoppingCartId_$reflection() {
    return union_type("Shared.ShoppingCartId", [], ShoppingCartId, () => [[["Item", class_type("System.Guid")]]]);
}

export class ShoppingCartPositionId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ShoppingCartPositionId"];
    }
}

export function ShoppingCartPositionId_$reflection() {
    return union_type("Shared.ShoppingCartPositionId", [], ShoppingCartPositionId, () => [[["Item", class_type("System.Guid")]]]);
}

export class DocumentId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["DocumentId"];
    }
}

export function DocumentId_$reflection() {
    return union_type("Shared.DocumentId", [], DocumentId, () => [[["Item", class_type("System.Guid")]]]);
}

export function DocumentId__get_unwrap(this$) {
    return this$.fields[0];
}

export class ReminderId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ReminderId"];
    }
}

export function ReminderId_$reflection() {
    return union_type("Shared.ReminderId", [], ReminderId, () => [[["Item", class_type("System.Guid")]]]);
}

export function ReminderId__get_unwrap(this$) {
    return this$.fields[0];
}

export class LabelId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["LabelId"];
    }
}

export function LabelId_$reflection() {
    return union_type("Shared.LabelId", [], LabelId, () => [[["Item", class_type("System.Guid")]]]);
}

export function LabelId__get_unwrap(this$) {
    return this$.fields[0];
}

export class NotificationToken extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["NotificationToken"];
    }
}

export function NotificationToken_$reflection() {
    return union_type("Shared.NotificationToken", [], NotificationToken, () => [[["Item", string_type]]]);
}

export class DispolistId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["DispolistId"];
    }
}

export function DispolistId_$reflection() {
    return union_type("Shared.DispolistId", [], DispolistId, () => [[["Item", class_type("System.Guid")]]]);
}

export class DispolistSnapshotId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["DispolistSnapshotId"];
    }
}

export function DispolistSnapshotId_$reflection() {
    return union_type("Shared.DispolistSnapshotId", [], DispolistSnapshotId, () => [[["Item", class_type("System.Guid")]]]);
}

export function DispolistSnapshotId__get_unwrap(this$) {
    return this$.fields[0];
}

export class NoticeId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["NoticeId"];
    }
}

export function NoticeId_$reflection() {
    return union_type("Shared.NoticeId", [], NoticeId, () => [[["Item", class_type("System.Guid")]]]);
}

export class ConsumableReservationId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ConsumableReservationId"];
    }
}

export function ConsumableReservationId_$reflection() {
    return union_type("Shared.ConsumableReservationId", [], ConsumableReservationId, () => [[["Item", class_type("System.Guid")]]]);
}

export function ConsumableReservationId__get_unwrap(this$) {
    return this$.fields[0];
}

export class LocationId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["LocationId"];
    }
}

export function LocationId_$reflection() {
    return union_type("Shared.LocationId", [], LocationId, () => [[["Item", class_type("System.Guid")]]]);
}

export function LocationId__get_unwrap(this$) {
    return this$.fields[0];
}

export class ToolReservationId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ToolReservationId"];
    }
}

export function ToolReservationId_$reflection() {
    return union_type("Shared.ToolReservationId", [], ToolReservationId, () => [[["Item", class_type("System.Guid")]]]);
}

export function ToolReservationId__get_unwrap(this$) {
    return this$.fields[0];
}

export class ReservationGroupId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ReservationGroupId"];
    }
}

export function ReservationGroupId_$reflection() {
    return union_type("Shared.ReservationGroupId", [], ReservationGroupId, () => [[["Item", class_type("System.Guid")]]]);
}

export function ReservationGroupId__get_unwrap(this$) {
    return this$.fields[0];
}

export class ConsumableId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ConsumableId"];
    }
}

export function ConsumableId_$reflection() {
    return union_type("Shared.ConsumableId", [], ConsumableId, () => [[["Item", class_type("System.Guid")]]]);
}

export function ConsumableId__get_unwrap(this$) {
    return this$.fields[0];
}

export class PropertyConfigurationId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["PropertyConfigurationId"];
    }
}

export function PropertyConfigurationId_$reflection() {
    return union_type("Shared.PropertyConfigurationId", [], PropertyConfigurationId, () => [[["Item", class_type("System.Guid")]]]);
}

export function PropertyConfigurationId__get_unwrap(this$) {
    return this$.fields[0];
}

export class PropertyConfigurationEnumValueId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["PropertyConfigurationEnumValueId"];
    }
}

export function PropertyConfigurationEnumValueId_$reflection() {
    return union_type("Shared.PropertyConfigurationEnumValueId", [], PropertyConfigurationEnumValueId, () => [[["Item", class_type("System.Guid")]]]);
}

export function PropertyConfigurationEnumValueId_get_Empty() {
    return new PropertyConfigurationEnumValueId("00000000-0000-0000-0000-000000000000");
}

export function PropertyConfigurationEnumValueId_New() {
    return new PropertyConfigurationEnumValueId(newGuid());
}

export function PropertyConfigurationEnumValueId_Parse_Z721C83C5(value) {
    return new PropertyConfigurationEnumValueId(parse(value));
}

export function PropertyConfigurationEnumValueId__get_unwrap(this$) {
    return this$.fields[0];
}

export class SetReservationId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["SetReservationId"];
    }
}

export function SetReservationId_$reflection() {
    return union_type("Shared.SetReservationId", [], SetReservationId, () => [[["Item", class_type("System.Guid")]]]);
}

export function SetReservationId__get_unwrap(this$) {
    return this$.fields[0];
}

export class SetId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["SetId"];
    }
}

export function SetId_$reflection() {
    return union_type("Shared.SetId", [], SetId, () => [[["Item", class_type("System.Guid")]]]);
}

export function SetId__get_unwrap(this$) {
    return this$.fields[0];
}

export class AdditionalReminderId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["AdditionalReminderId"];
    }
}

export function AdditionalReminderId_$reflection() {
    return union_type("Shared.AdditionalReminderId", [], AdditionalReminderId, () => [[["Item", class_type("System.Guid")]]]);
}

export function AdditionalReminderId__get_unwrap(this$) {
    return this$.fields[0];
}

export class NotificationId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["NotificationId"];
    }
}

export function NotificationId_$reflection() {
    return union_type("Shared.NotificationId", [], NotificationId, () => [[["Item", string_type]]]);
}

export function NotificationId__get_unwrap(this$) {
    return this$.fields[0];
}

export class ChangelogId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ChangelogId"];
    }
}

export function ChangelogId_$reflection() {
    return union_type("Shared.ChangelogId", [], ChangelogId, () => [[["Item", class_type("System.Guid")]]]);
}

export function ChangelogId__get_unwrap(this$) {
    return this$.fields[0];
}

export class SwwStagingCustomerSpecificTool extends Record {
    constructor(Test) {
        super();
        this.Test = Test;
    }
}

export function SwwStagingCustomerSpecificTool_$reflection() {
    return record_type("Shared.SwwStagingCustomerSpecificTool", [], SwwStagingCustomerSpecificTool, () => [["Test", string_type]]);
}

export class PrinzingCustomerSpecificTool extends Record {
    constructor(CheckNumber) {
        super();
        this.CheckNumber = CheckNumber;
    }
}

export function PrinzingCustomerSpecificTool_$reflection() {
    return record_type("Shared.PrinzingCustomerSpecificTool", [], PrinzingCustomerSpecificTool, () => [["CheckNumber", string_type]]);
}

export class AppResponse extends Record {
    constructor(Value, Error$) {
        super();
        this.Value = Value;
        this.Error = Error$;
    }
}

export function AppResponse_$reflection() {
    return record_type("Shared.AppResponse", [], AppResponse, () => [["Value", string_type], ["Error", option_type(string_type)]]);
}

export class CustomerSpecificTool extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Default", "Prinzing", "SwwStaging"];
    }
}

export function CustomerSpecificTool_$reflection() {
    return union_type("Shared.CustomerSpecificTool", [], CustomerSpecificTool, () => [[], [["Item", PrinzingCustomerSpecificTool_$reflection()]], [["Item", SwwStagingCustomerSpecificTool_$reflection()]]]);
}

export class CustomerSpecificToolWithQuantity extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Default", "Prinzing", "SwwStaging"];
    }
}

export function CustomerSpecificToolWithQuantity_$reflection() {
    return union_type("Shared.CustomerSpecificToolWithQuantity", [], CustomerSpecificToolWithQuantity, () => [[], [["Item", PrinzingCustomerSpecificTool_$reflection()]], []]);
}

export class Customer extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Default", "Prinzing", "SwwStaging", "Schoeffmann", "SBucher", "WasserNord", "Figlass", "Aerq", "OhrmannI24", "PrinzingBaustrom", "SwissAthletic"];
    }
}

export function Customer_$reflection() {
    return union_type("Shared.Customer", [], Customer, () => [[], [], [], [], [], [], [], [], [], [], []]);
}

export class RequestedValue$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Pending", "Available"];
    }
}

export function RequestedValue$1_$reflection(gen0) {
    return union_type("Shared.RequestedValue`1", [gen0], RequestedValue$1, () => [[], [["Item", gen0]]]);
}

export class ReceiverVisibilityConfiguration extends Record {
    constructor(ConstructionSites, Employees, ExternalPersons, Projects, Rooms, SchoolClasses, Students, Subcontractos, Users, Vehicles) {
        super();
        this.ConstructionSites = ConstructionSites;
        this.Employees = Employees;
        this.ExternalPersons = ExternalPersons;
        this.Projects = Projects;
        this.Rooms = Rooms;
        this.SchoolClasses = SchoolClasses;
        this.Students = Students;
        this.Subcontractos = Subcontractos;
        this.Users = Users;
        this.Vehicles = Vehicles;
    }
}

export function ReceiverVisibilityConfiguration_$reflection() {
    return record_type("Shared.ReceiverVisibilityConfiguration", [], ReceiverVisibilityConfiguration, () => [["ConstructionSites", bool_type], ["Employees", bool_type], ["ExternalPersons", bool_type], ["Projects", bool_type], ["Rooms", bool_type], ["SchoolClasses", bool_type], ["Students", bool_type], ["Subcontractos", bool_type], ["Users", bool_type], ["Vehicles", bool_type]]);
}

export class ReceiverCreateReservationAllowedConfiguration extends Record {
    constructor(ConstructionSites, Employees, ExternalPersons, Projects, Rooms, SchoolClasses, Students, Subcontractos, Users, Vehicles) {
        super();
        this.ConstructionSites = ConstructionSites;
        this.Employees = Employees;
        this.ExternalPersons = ExternalPersons;
        this.Projects = Projects;
        this.Rooms = Rooms;
        this.SchoolClasses = SchoolClasses;
        this.Students = Students;
        this.Subcontractos = Subcontractos;
        this.Users = Users;
        this.Vehicles = Vehicles;
    }
}

export function ReceiverCreateReservationAllowedConfiguration_$reflection() {
    return record_type("Shared.ReceiverCreateReservationAllowedConfiguration", [], ReceiverCreateReservationAllowedConfiguration, () => [["ConstructionSites", bool_type], ["Employees", bool_type], ["ExternalPersons", bool_type], ["Projects", bool_type], ["Rooms", bool_type], ["SchoolClasses", bool_type], ["Students", bool_type], ["Subcontractos", bool_type], ["Users", bool_type], ["Vehicles", bool_type]]);
}

export class TenantConfiguration extends Record {
    constructor(Customer, Label) {
        super();
        this.Customer = Customer;
        this.Label = Label;
    }
}

export function TenantConfiguration_$reflection() {
    return record_type("Shared.TenantConfiguration", [], TenantConfiguration, () => [["Customer", string_type], ["Label", string_type]]);
}

export class CustomerConfiguration extends Record {
    constructor(Staging, IsGlobalAppSearchActiveForUserRole, IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, IsSelfServiceReservationForUserActive, ReceiverVisibilityConfiguration, NotificationEmailsOnlyToAdministrators, DemoDataImported, CanUserSeeAllTools, CanUserWriteNotices, AppVisibilityPermissionReceiver, GlobalSelfServiceStorageActive, CustomI18nextNamespace, Tenants, TenantName, ReceiverCreateReservationAllowedConfiguration, AllowUserToUserAssignmentViaUserSelection) {
        super();
        this.Staging = Staging;
        this.IsGlobalAppSearchActiveForUserRole = IsGlobalAppSearchActiveForUserRole;
        this.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification = IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification;
        this.IsSelfServiceReservationForUserActive = IsSelfServiceReservationForUserActive;
        this.ReceiverVisibilityConfiguration = ReceiverVisibilityConfiguration;
        this.NotificationEmailsOnlyToAdministrators = NotificationEmailsOnlyToAdministrators;
        this.DemoDataImported = DemoDataImported;
        this.CanUserSeeAllTools = CanUserSeeAllTools;
        this.CanUserWriteNotices = CanUserWriteNotices;
        this.AppVisibilityPermissionReceiver = AppVisibilityPermissionReceiver;
        this.GlobalSelfServiceStorageActive = GlobalSelfServiceStorageActive;
        this.CustomI18nextNamespace = CustomI18nextNamespace;
        this.Tenants = Tenants;
        this.TenantName = TenantName;
        this.ReceiverCreateReservationAllowedConfiguration = ReceiverCreateReservationAllowedConfiguration;
        this.AllowUserToUserAssignmentViaUserSelection = AllowUserToUserAssignmentViaUserSelection;
    }
}

export function CustomerConfiguration_$reflection() {
    return record_type("Shared.CustomerConfiguration", [], CustomerConfiguration, () => [["Staging", bool_type], ["IsGlobalAppSearchActiveForUserRole", bool_type], ["IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification", bool_type], ["IsSelfServiceReservationForUserActive", bool_type], ["ReceiverVisibilityConfiguration", ReceiverVisibilityConfiguration_$reflection()], ["NotificationEmailsOnlyToAdministrators", bool_type], ["DemoDataImported", bool_type], ["CanUserSeeAllTools", bool_type], ["CanUserWriteNotices", bool_type], ["AppVisibilityPermissionReceiver", ReceiverVisibilityConfiguration_$reflection()], ["GlobalSelfServiceStorageActive", bool_type], ["CustomI18nextNamespace", option_type(string_type)], ["Tenants", option_type(list_type(TenantConfiguration_$reflection()))], ["TenantName", option_type(string_type)], ["ReceiverCreateReservationAllowedConfiguration", ReceiverCreateReservationAllowedConfiguration_$reflection()], ["AllowUserToUserAssignmentViaUserSelection", bool_type]]);
}

export class CustomerConfigurationResponseResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Updated", "ReceiverExists"];
    }
}

export function CustomerConfigurationResponseResult_$reflection() {
    return union_type("Shared.CustomerConfigurationResponseResult", [], CustomerConfigurationResponseResult, () => [[], []]);
}

export class CommercialData extends Record {
    constructor(PurchaseDate, Supplier, SupplierArticleNumber, PurchasePrice, WarrantyDate, Comment$) {
        super();
        this.PurchaseDate = PurchaseDate;
        this.Supplier = Supplier;
        this.SupplierArticleNumber = SupplierArticleNumber;
        this.PurchasePrice = PurchasePrice;
        this.WarrantyDate = WarrantyDate;
        this.Comment = Comment$;
    }
}

export function CommercialData_$reflection() {
    return record_type("Shared.CommercialData", [], CommercialData, () => [["PurchaseDate", option_type(class_type("System.DateTime"))], ["Supplier", string_type], ["SupplierArticleNumber", string_type], ["PurchasePrice", option_type(int32_type)], ["WarrantyDate", option_type(class_type("System.DateTime"))], ["Comment", string_type]]);
}

export class CommercialDataFormDto extends Record {
    constructor(PurchaseDate, Supplier, SupplierArticleNumber, PurchasePrice, WarrantyDate, Comment$) {
        super();
        this.PurchaseDate = PurchaseDate;
        this.Supplier = Supplier;
        this.SupplierArticleNumber = SupplierArticleNumber;
        this.PurchasePrice = PurchasePrice;
        this.WarrantyDate = WarrantyDate;
        this.Comment = Comment$;
    }
}

export function CommercialDataFormDto_$reflection() {
    return record_type("Shared.CommercialDataFormDto", [], CommercialDataFormDto, () => [["PurchaseDate", option_type(string_type)], ["Supplier", string_type], ["SupplierArticleNumber", string_type], ["PurchasePrice", option_type(int32_type)], ["WarrantyDate", option_type(string_type)], ["Comment", string_type]]);
}

export class Document$ extends Record {
    constructor(UploadDate, Id, Name, IsVisibleForUserRole, Description) {
        super();
        this.UploadDate = UploadDate;
        this.Id = Id;
        this.Name = Name;
        this.IsVisibleForUserRole = IsVisibleForUserRole;
        this.Description = Description;
    }
}

export function Document$_$reflection() {
    return record_type("Shared.Document", [], Document$, () => [["UploadDate", class_type("System.DateTime")], ["Id", DocumentId_$reflection()], ["Name", string_type], ["IsVisibleForUserRole", bool_type], ["Description", string_type]]);
}

export class DocumentDetail extends Record {
    constructor(UploadDate, Id, Name, IsVisibleForUserRole, Description, Url) {
        super();
        this.UploadDate = UploadDate;
        this.Id = Id;
        this.Name = Name;
        this.IsVisibleForUserRole = IsVisibleForUserRole;
        this.Description = Description;
        this.Url = Url;
    }
}

export function DocumentDetail_$reflection() {
    return record_type("Shared.DocumentDetail", [], DocumentDetail, () => [["UploadDate", class_type("System.DateTime")], ["Id", DocumentId_$reflection()], ["Name", string_type], ["IsVisibleForUserRole", bool_type], ["Description", string_type], ["Url", string_type]]);
}

export class Label extends Record {
    constructor(Id, Name, ColorHex, VisibleForUserRole) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.ColorHex = ColorHex;
        this.VisibleForUserRole = VisibleForUserRole;
    }
}

export function Label_$reflection() {
    return record_type("Shared.Label", [], Label, () => [["Id", LabelId_$reflection()], ["Name", string_type], ["ColorHex", string_type], ["VisibleForUserRole", bool_type]]);
}

export class LabelResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LabelNameAlreadyExists", "Successful"];
    }
}

export function LabelResponse_$reflection() {
    return union_type("Shared.LabelResponse", [], LabelResponse, () => [[], [["Item", LabelId_$reflection()]]]);
}

export class LabelDeleteResponse extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Successful"];
    }
}

export function LabelDeleteResponse_$reflection() {
    return union_type("Shared.LabelDeleteResponse", [], LabelDeleteResponse, () => [[]]);
}

export class ToolHistoryElementType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Tool", "ToolWithQuantity", "Dispolist", "Consumable"];
    }
}

export function ToolHistoryElementType_$reflection() {
    return union_type("Shared.ToolHistoryElementType", [], ToolHistoryElementType, () => [[["Item", ToolId_$reflection()]], [["Item", ToolId_$reflection()]], [["Item", DispolistSnapshotId_$reflection()]], [["Item", ConsumableId_$reflection()]]]);
}

export class DispolistHistoryRow extends Record {
    constructor(Id, EventDate, EventType, DispolistName, Receiver, Sender) {
        super();
        this.Id = Id;
        this.EventDate = EventDate;
        this.EventType = EventType;
        this.DispolistName = DispolistName;
        this.Receiver = Receiver;
        this.Sender = Sender;
    }
}

export function DispolistHistoryRow_$reflection() {
    return record_type("Shared.DispolistHistoryRow", [], DispolistHistoryRow, () => [["Id", string_type], ["EventDate", class_type("System.DateTime")], ["EventType", string_type], ["DispolistName", string_type], ["Receiver", string_type], ["Sender", string_type]]);
}

export class InvoiceMethod extends Record {
    constructor(SendByPost) {
        super();
        this.SendByPost = SendByPost;
    }
}

export function InvoiceMethod_$reflection() {
    return record_type("Shared.InvoiceMethod", [], InvoiceMethod, () => [["SendByPost", bool_type]]);
}

export class SEPAMethod extends Record {
    constructor(BIC, IBAN, AccountHolder) {
        super();
        this.BIC = BIC;
        this.IBAN = IBAN;
        this.AccountHolder = AccountHolder;
    }
}

export function SEPAMethod_$reflection() {
    return record_type("Shared.SEPAMethod", [], SEPAMethod, () => [["BIC", string_type], ["IBAN", string_type], ["AccountHolder", string_type]]);
}

export class UpdateUserPackage extends Record {
    constructor(NewUserPackages) {
        super();
        this.NewUserPackages = (NewUserPackages | 0);
    }
}

export function UpdateUserPackage_$reflection() {
    return record_type("Shared.UpdateUserPackage", [], UpdateUserPackage, () => [["NewUserPackages", int32_type]]);
}

export class UpdateUserContigentResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Failed", "Successful"];
    }
}

export function UpdateUserContigentResult_$reflection() {
    return union_type("Shared.UpdateUserContigentResult", [], UpdateUserContigentResult, () => [[], []]);
}

export class UpdateUserPackageResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Updated", "SmallerThanZero", "ToManyUsersInSystem", "Failed"];
    }
}

export function UpdateUserPackageResult_$reflection() {
    return union_type("Shared.UpdateUserPackageResult", [], UpdateUserPackageResult, () => [[], [], [], []]);
}

export class Package extends Record {
    constructor(CustomerId, Name, UserPackages, ReceiverNumber, CheckModule) {
        super();
        this.CustomerId = (CustomerId | 0);
        this.Name = Name;
        this.UserPackages = (UserPackages | 0);
        this.ReceiverNumber = (ReceiverNumber | 0);
        this.CheckModule = CheckModule;
    }
}

export function Package_$reflection() {
    return record_type("Shared.Package", [], Package, () => [["CustomerId", int32_type], ["Name", string_type], ["UserPackages", int32_type], ["ReceiverNumber", int32_type], ["CheckModule", bool_type]]);
}

export class I24Packages extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Standard", "Large", "Individual"];
    }
}

export function I24Packages_$reflection() {
    return union_type("Shared.I24Packages", [], I24Packages, () => [[], [], []]);
}

export class UpdateI24Package extends Record {
    constructor(NewPackage) {
        super();
        this.NewPackage = NewPackage;
    }
}

export function UpdateI24Package_$reflection() {
    return record_type("Shared.UpdateI24Package", [], UpdateI24Package, () => [["NewPackage", I24Packages_$reflection()]]);
}

export class UpdateI24PackageResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Failed", "Successful"];
    }
}

export function UpdateI24PackageResult_$reflection() {
    return union_type("Shared.UpdateI24PackageResult", [], UpdateI24PackageResult, () => [[], []]);
}

export class UpdateI24PackageUpdateResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Updated", "NewAndOldIsEqual", "DowngradeNotPossible", "Failed"];
    }
}

export function UpdateI24PackageUpdateResult_$reflection() {
    return union_type("Shared.UpdateI24PackageUpdateResult", [], UpdateI24PackageUpdateResult, () => [[], [], [], []]);
}

export class Code extends Record {
    constructor(Id, Code, I18NDescriptionKey, IsActive, CreatedAt, UpdatedAt) {
        super();
        this.Id = (Id | 0);
        this.Code = Code;
        this.I18NDescriptionKey = I18NDescriptionKey;
        this.IsActive = IsActive;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function Code_$reflection() {
    return record_type("Shared.Code", [], Code, () => [["Id", int32_type], ["Code", string_type], ["I18NDescriptionKey", string_type], ["IsActive", bool_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export class CustomerCode extends Record {
    constructor(Id, Code, I18NDescriptionKey, IsActive, CreatedAt, UpdatedAt) {
        super();
        this.Id = (Id | 0);
        this.Code = Code;
        this.I18NDescriptionKey = I18NDescriptionKey;
        this.IsActive = IsActive;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function CustomerCode_$reflection() {
    return record_type("Shared.CustomerCode", [], CustomerCode, () => [["Id", int32_type], ["Code", string_type], ["I18NDescriptionKey", string_type], ["IsActive", bool_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export class ReceiverPackage extends Record {
    constructor(Id, PackageName, PackageId, Quantity, MonthlyPrice, YearlyPrice, CreatedAt, UpdatedAt) {
        super();
        this.Id = (Id | 0);
        this.PackageName = PackageName;
        this.PackageId = (PackageId | 0);
        this.Quantity = (Quantity | 0);
        this.MonthlyPrice = MonthlyPrice;
        this.YearlyPrice = YearlyPrice;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function ReceiverPackage_$reflection() {
    return record_type("Shared.ReceiverPackage", [], ReceiverPackage, () => [["Id", int32_type], ["PackageName", string_type], ["PackageId", int32_type], ["Quantity", int32_type], ["MonthlyPrice", float64_type], ["YearlyPrice", float64_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export class BasePackageType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OldFashionedMwk", "OldFashionedI24", "Small", "Medium", "Large"];
    }
}

export function BasePackageType_$reflection() {
    return union_type("Shared.BasePackageType", [], BasePackageType, () => [[], [], [], [], []]);
}

export class BasePackage extends Record {
    constructor(Id, PackageName, PackageId, MonthlyPrice, YearlyPrice, BasePackageType, CreatedAt, UpdatedAt) {
        super();
        this.Id = (Id | 0);
        this.PackageName = PackageName;
        this.PackageId = (PackageId | 0);
        this.MonthlyPrice = MonthlyPrice;
        this.YearlyPrice = YearlyPrice;
        this.BasePackageType = BasePackageType;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function BasePackage_$reflection() {
    return record_type("Shared.BasePackage", [], BasePackage, () => [["Id", int32_type], ["PackageName", string_type], ["PackageId", int32_type], ["MonthlyPrice", float64_type], ["YearlyPrice", float64_type], ["BasePackageType", BasePackageType_$reflection()], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export class BillingMethodSepa extends Record {
    constructor(BIC, IBAN, AccountHolder) {
        super();
        this.BIC = BIC;
        this.IBAN = IBAN;
        this.AccountHolder = AccountHolder;
    }
}

export function BillingMethodSepa_$reflection() {
    return record_type("Shared.BillingMethodSepa", [], BillingMethodSepa, () => [["BIC", string_type], ["IBAN", string_type], ["AccountHolder", string_type]]);
}

export class BillingMethodInvoice extends Record {
    constructor(InvoiceSendPerPost) {
        super();
        this.InvoiceSendPerPost = InvoiceSendPerPost;
    }
}

export function BillingMethodInvoice_$reflection() {
    return record_type("Shared.BillingMethodInvoice", [], BillingMethodInvoice, () => [["InvoiceSendPerPost", bool_type]]);
}

export class BillingMethod extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SEPA", "Invoice"];
    }
}

export function BillingMethod_$reflection() {
    return union_type("Shared.BillingMethod", [], BillingMethod, () => [[["Item", BillingMethodSepa_$reflection()]], [["Item", BillingMethodInvoice_$reflection()]]]);
}

export class PaymentPeriod extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Yearly", "HalfYearly", "Monthly"];
    }
}

export function PaymentPeriod_$reflection() {
    return union_type("Shared.PaymentPeriod", [], PaymentPeriod, () => [[], [], []]);
}

export class BillingData extends Record {
    constructor(Id, BillingMethod, PaymentPeriod, PaymentPeriodEnd, CreatedAt, UpdatedAt) {
        super();
        this.Id = (Id | 0);
        this.BillingMethod = BillingMethod;
        this.PaymentPeriod = PaymentPeriod;
        this.PaymentPeriodEnd = PaymentPeriodEnd;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function BillingData_$reflection() {
    return record_type("Shared.BillingData", [], BillingData, () => [["Id", int32_type], ["BillingMethod", BillingMethod_$reflection()], ["PaymentPeriod", PaymentPeriod_$reflection()], ["PaymentPeriodEnd", class_type("System.DateTime")], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export class InvoiceAddress extends Record {
    constructor(Id, CompanyName, ContactPerson, Email, Street, Zip, City, CreatedAt, UpdatedAt) {
        super();
        this.Id = (Id | 0);
        this.CompanyName = CompanyName;
        this.ContactPerson = ContactPerson;
        this.Email = Email;
        this.Street = Street;
        this.Zip = Zip;
        this.City = City;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function InvoiceAddress_$reflection() {
    return record_type("Shared.InvoiceAddress", [], InvoiceAddress, () => [["Id", int32_type], ["CompanyName", string_type], ["ContactPerson", string_type], ["Email", string_type], ["Street", string_type], ["Zip", string_type], ["City", string_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export class CustomerData extends Record {
    constructor(Id, IsNoRealCustomer, FirebaseName, MainUserEmail, ReceiverPackage, BasePackage, InvoiceAddress, BillingData, UsedCodes, CreatedAt, TestPeriodEnd, StripeCustomerId) {
        super();
        this.Id = (Id | 0);
        this.IsNoRealCustomer = IsNoRealCustomer;
        this.FirebaseName = FirebaseName;
        this.MainUserEmail = MainUserEmail;
        this.ReceiverPackage = ReceiverPackage;
        this.BasePackage = BasePackage;
        this.InvoiceAddress = InvoiceAddress;
        this.BillingData = BillingData;
        this.UsedCodes = UsedCodes;
        this.CreatedAt = CreatedAt;
        this.TestPeriodEnd = TestPeriodEnd;
        this.StripeCustomerId = StripeCustomerId;
    }
}

export function CustomerData_$reflection() {
    return record_type("Shared.CustomerData", [], CustomerData, () => [["Id", int32_type], ["IsNoRealCustomer", bool_type], ["FirebaseName", string_type], ["MainUserEmail", string_type], ["ReceiverPackage", option_type(ReceiverPackage_$reflection())], ["BasePackage", option_type(BasePackage_$reflection())], ["InvoiceAddress", option_type(InvoiceAddress_$reflection())], ["BillingData", option_type(BillingData_$reflection())], ["UsedCodes", list_type(Code_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["TestPeriodEnd", class_type("System.DateTime")], ["StripeCustomerId", option_type(string_type)]]);
}

export class AccountSettings extends Record {
    constructor(AccountState, AccountEndDate, PackageName, IncludedUsers, IncludedInventory, CustomFieldsEnabled, SetsEnabled, EmailAssignmentEnabled, InventoryEnabled, RestEnabled, SsoEnabled, TenantsEnabled) {
        super();
        this.AccountState = AccountState;
        this.AccountEndDate = AccountEndDate;
        this.PackageName = PackageName;
        this.IncludedUsers = (IncludedUsers | 0);
        this.IncludedInventory = (IncludedInventory | 0);
        this.CustomFieldsEnabled = CustomFieldsEnabled;
        this.SetsEnabled = SetsEnabled;
        this.EmailAssignmentEnabled = EmailAssignmentEnabled;
        this.InventoryEnabled = InventoryEnabled;
        this.RestEnabled = RestEnabled;
        this.SsoEnabled = SsoEnabled;
        this.TenantsEnabled = TenantsEnabled;
    }
}

export function AccountSettings_$reflection() {
    return record_type("Shared.AccountSettings", [], AccountSettings, () => [["AccountState", string_type], ["AccountEndDate", option_type(class_type("System.DateTime"))], ["PackageName", option_type(string_type)], ["IncludedUsers", int32_type], ["IncludedInventory", int32_type], ["CustomFieldsEnabled", bool_type], ["SetsEnabled", bool_type], ["EmailAssignmentEnabled", bool_type], ["InventoryEnabled", bool_type], ["RestEnabled", bool_type], ["SsoEnabled", bool_type], ["TenantsEnabled", bool_type]]);
}

export class AccountStatistic extends Record {
    constructor(NumberOfTools, NumberOfToolsWithQuantity, NumberOfConsumables, NumberOfSets, NumberOfUsers, IncludedUsers, IncludedInventory, CustomFieldsEnabled, SetsEnabled, InventoryEnabled, RestEnabled, SsoEnabled, TenantsEnabled) {
        super();
        this.NumberOfTools = (NumberOfTools | 0);
        this.NumberOfToolsWithQuantity = (NumberOfToolsWithQuantity | 0);
        this.NumberOfConsumables = (NumberOfConsumables | 0);
        this.NumberOfSets = (NumberOfSets | 0);
        this.NumberOfUsers = (NumberOfUsers | 0);
        this.IncludedUsers = (IncludedUsers | 0);
        this.IncludedInventory = (IncludedInventory | 0);
        this.CustomFieldsEnabled = CustomFieldsEnabled;
        this.SetsEnabled = SetsEnabled;
        this.InventoryEnabled = InventoryEnabled;
        this.RestEnabled = RestEnabled;
        this.SsoEnabled = SsoEnabled;
        this.TenantsEnabled = TenantsEnabled;
    }
}

export function AccountStatistic_$reflection() {
    return record_type("Shared.AccountStatistic", [], AccountStatistic, () => [["NumberOfTools", int32_type], ["NumberOfToolsWithQuantity", int32_type], ["NumberOfConsumables", int32_type], ["NumberOfSets", int32_type], ["NumberOfUsers", int32_type], ["IncludedUsers", int32_type], ["IncludedInventory", int32_type], ["CustomFieldsEnabled", bool_type], ["SetsEnabled", bool_type], ["InventoryEnabled", bool_type], ["RestEnabled", bool_type], ["SsoEnabled", bool_type], ["TenantsEnabled", bool_type]]);
}

export class UserInformation extends Record {
    constructor(Id, Email, Name) {
        super();
        this.Id = Id;
        this.Email = Email;
        this.Name = Name;
    }
}

export function UserInformation_$reflection() {
    return record_type("Shared.UserInformation", [], UserInformation, () => [["Id", string_type], ["Email", string_type], ["Name", string_type]]);
}

export class NewCustomerDto extends Record {
    constructor(NumberOfUsers, BasePackageType, InvoiceAddress, BillingMethod, PaymentPeriod) {
        super();
        this.NumberOfUsers = (NumberOfUsers | 0);
        this.BasePackageType = BasePackageType;
        this.InvoiceAddress = InvoiceAddress;
        this.BillingMethod = BillingMethod;
        this.PaymentPeriod = PaymentPeriod;
    }
}

export function NewCustomerDto_$reflection() {
    return record_type("Shared.NewCustomerDto", [], NewCustomerDto, () => [["NumberOfUsers", int32_type], ["BasePackageType", BasePackageType_$reflection()], ["InvoiceAddress", InvoiceAddress_$reflection()], ["BillingMethod", BillingMethod_$reflection()], ["PaymentPeriod", PaymentPeriod_$reflection()]]);
}

export class CreateNewCustomer extends Record {
    constructor(UserInformation, CustomerData, CustomerName) {
        super();
        this.UserInformation = UserInformation;
        this.CustomerData = CustomerData;
        this.CustomerName = CustomerName;
    }
}

export function CreateNewCustomer_$reflection() {
    return record_type("Shared.CreateNewCustomer", [], CreateNewCustomer, () => [["UserInformation", UserInformation_$reflection()], ["CustomerData", NewCustomerDto_$reflection()], ["CustomerName", string_type]]);
}

export class CreateNewCustomerResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "Failed"];
    }
}

export function CreateNewCustomerResponse_$reflection() {
    return union_type("Shared.CreateNewCustomerResponse", [], CreateNewCustomerResponse, () => [[], []]);
}

export class UpdateReceiverPackage extends Record {
    constructor(NewReceiverPackage, OldReceiverPackage) {
        super();
        this.NewReceiverPackage = NewReceiverPackage;
        this.OldReceiverPackage = OldReceiverPackage;
    }
}

export function UpdateReceiverPackage_$reflection() {
    return record_type("Shared.UpdateReceiverPackage", [], UpdateReceiverPackage, () => [["NewReceiverPackage", ReceiverPackage_$reflection()], ["OldReceiverPackage", ReceiverPackage_$reflection()]]);
}

export class UpdateBasePackage extends Record {
    constructor(NewBasePackage, OldBasePackage) {
        super();
        this.NewBasePackage = NewBasePackage;
        this.OldBasePackage = OldBasePackage;
    }
}

export function UpdateBasePackage_$reflection() {
    return record_type("Shared.UpdateBasePackage", [], UpdateBasePackage, () => [["NewBasePackage", BasePackage_$reflection()], ["OldBasePackage", BasePackage_$reflection()]]);
}

export class UpdatePackage extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ReceiverPackage", "BasePackage"];
    }
}

export function UpdatePackage_$reflection() {
    return union_type("Shared.UpdatePackage", [], UpdatePackage, () => [[["Item", UpdateReceiverPackage_$reflection()]], [["Item", UpdateBasePackage_$reflection()]]]);
}

export class UpdateCustomerPackage extends Record {
    constructor(UserInformation, CustomerId, Package) {
        super();
        this.UserInformation = UserInformation;
        this.CustomerId = (CustomerId | 0);
        this.Package = Package;
    }
}

export function UpdateCustomerPackage_$reflection() {
    return record_type("Shared.UpdateCustomerPackage", [], UpdateCustomerPackage, () => [["UserInformation", UserInformation_$reflection()], ["CustomerId", int32_type], ["Package", UpdatePackage_$reflection()]]);
}

export class UpdatePackageCustomerResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "FailedTooManyUsersInSystem", "FailedTooManyToolsInSystem", "Failed"];
    }
}

export function UpdatePackageCustomerResponse_$reflection() {
    return union_type("Shared.UpdatePackageCustomerResponse", [], UpdatePackageCustomerResponse, () => [[], [], [], []]);
}

export class CancelContract extends Record {
    constructor(UserInformation, CustomerId, CancelContractReason) {
        super();
        this.UserInformation = UserInformation;
        this.CustomerId = (CustomerId | 0);
        this.CancelContractReason = CancelContractReason;
    }
}

export function CancelContract_$reflection() {
    return record_type("Shared.CancelContract", [], CancelContract, () => [["UserInformation", UserInformation_$reflection()], ["CustomerId", int32_type], ["CancelContractReason", string_type]]);
}

export class CancelContractResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "Failed"];
    }
}

export function CancelContractResponse_$reflection() {
    return union_type("Shared.CancelContractResponse", [], CancelContractResponse, () => [[], []]);
}

export class UpdateCustomerInvoiceAddress extends Record {
    constructor(UserInformation, CustomerId, OldInvoiceAddress, NewInvoiceAddress) {
        super();
        this.UserInformation = UserInformation;
        this.CustomerId = (CustomerId | 0);
        this.OldInvoiceAddress = OldInvoiceAddress;
        this.NewInvoiceAddress = NewInvoiceAddress;
    }
}

export function UpdateCustomerInvoiceAddress_$reflection() {
    return record_type("Shared.UpdateCustomerInvoiceAddress", [], UpdateCustomerInvoiceAddress, () => [["UserInformation", UserInformation_$reflection()], ["CustomerId", int32_type], ["OldInvoiceAddress", InvoiceAddress_$reflection()], ["NewInvoiceAddress", InvoiceAddress_$reflection()]]);
}

export class UpdateInvoiceAddressCustomerResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "Failed"];
    }
}

export function UpdateInvoiceAddressCustomerResponse_$reflection() {
    return union_type("Shared.UpdateInvoiceAddressCustomerResponse", [], UpdateInvoiceAddressCustomerResponse, () => [[], []]);
}

export class UpdateCustomerBillingData extends Record {
    constructor(UserInformation, CustomerId, NewBillingData, OldBillingData) {
        super();
        this.UserInformation = UserInformation;
        this.CustomerId = (CustomerId | 0);
        this.NewBillingData = NewBillingData;
        this.OldBillingData = OldBillingData;
    }
}

export function UpdateCustomerBillingData_$reflection() {
    return record_type("Shared.UpdateCustomerBillingData", [], UpdateCustomerBillingData, () => [["UserInformation", UserInformation_$reflection()], ["CustomerId", int32_type], ["NewBillingData", BillingData_$reflection()], ["OldBillingData", BillingData_$reflection()]]);
}

export class UpdateBillingDataCustomerResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "Failed"];
    }
}

export function UpdateBillingDataCustomerResponse_$reflection() {
    return union_type("Shared.UpdateBillingDataCustomerResponse", [], UpdateBillingDataCustomerResponse, () => [[], []]);
}

export class PhaseType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Test", "Paid"];
    }
}

export function PhaseType_$reflection() {
    return union_type("Shared.PhaseType", [], PhaseType, () => [[], []]);
}

export class Testphase extends Record {
    constructor(Phase, EndDate) {
        super();
        this.Phase = Phase;
        this.EndDate = EndDate;
    }
}

export function Testphase_$reflection() {
    return record_type("Shared.Testphase", [], Testphase, () => [["Phase", PhaseType_$reflection()], ["EndDate", class_type("System.DateTime")]]);
}

export function Role__ToI18nString(this$) {
    switch (this$) {
        case "administrator":
            return "role.administrator";
        case "toolManager":
            return "role.tool_manager";
        case "user":
            return "role.user";
        default:
            return "System";
    }
}

export class Validated$1 extends Record {
    constructor(Raw, Parsed) {
        super();
        this.Raw = Raw;
        this.Parsed = Parsed;
    }
}

export function Validated$1_$reflection(gen0) {
    return record_type("Shared.Validated`1", [gen0], Validated$1, () => [["Raw", string_type], ["Parsed", option_type(gen0)]]);
}

export class ReminderInterval extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Weekly", "Monthly", "Quarterly", "HalfYearly", "Yearly", "TwoYearly", "UserDefined"];
    }
}

export function ReminderInterval_$reflection() {
    return union_type("Shared.ReminderInterval", [], ReminderInterval, () => [[], [], [], [], [], [], []]);
}

export class CustomIntervalDefinition extends Record {
    constructor(Interval, Value) {
        super();
        this.Interval = Interval;
        this.Value = (Value | 0);
    }
}

export function CustomIntervalDefinition_$reflection() {
    return record_type("Shared.CustomIntervalDefinition", [], CustomIntervalDefinition, () => [["Interval", string_type], ["Value", int32_type]]);
}

export class AdditionalReminderDefinition extends Record {
    constructor(Id, Name, Interval) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Interval = Interval;
    }
}

export function AdditionalReminderDefinition_$reflection() {
    return record_type("Shared.AdditionalReminderDefinition", [], AdditionalReminderDefinition, () => [["Id", AdditionalReminderId_$reflection()], ["Name", string_type], ["Interval", CustomIntervalDefinition_$reflection()]]);
}

export class AdditionalReminderDefinitionDto extends Record {
    constructor(Id, Name, Interval) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Interval = Interval;
    }
}

export function AdditionalReminderDefinitionDto_$reflection() {
    return record_type("Shared.AdditionalReminderDefinitionDto", [], AdditionalReminderDefinitionDto, () => [["Id", string_type], ["Name", string_type], ["Interval", CustomIntervalDefinition_$reflection()]]);
}

export class ReminderDate extends Record {
    constructor(Id, Date$, Title, Description, IsConfirmed, ConfirmationComment, Interval, CustomInterval, AdditionalReminders) {
        super();
        this.Id = Id;
        this.Date = Date$;
        this.Title = Title;
        this.Description = Description;
        this.IsConfirmed = IsConfirmed;
        this.ConfirmationComment = ConfirmationComment;
        this.Interval = Interval;
        this.CustomInterval = CustomInterval;
        this.AdditionalReminders = AdditionalReminders;
    }
}

export function ReminderDate_$reflection() {
    return record_type("Shared.ReminderDate", [], ReminderDate, () => [["Id", ReminderId_$reflection()], ["Date", class_type("System.DateTime")], ["Title", string_type], ["Description", string_type], ["IsConfirmed", bool_type], ["ConfirmationComment", string_type], ["Interval", option_type(ReminderInterval_$reflection())], ["CustomInterval", option_type(CustomIntervalDefinition_$reflection())], ["AdditionalReminders", list_type(AdditionalReminderDefinition_$reflection())]]);
}

export class ReminderDateDto extends Record {
    constructor(Id, Date$, Title, Description, IsConfirmed, ConfirmationComment, Interval, CustomInterval, AdditionalReminders) {
        super();
        this.Id = Id;
        this.Date = Date$;
        this.Title = Title;
        this.Description = Description;
        this.IsConfirmed = IsConfirmed;
        this.ConfirmationComment = ConfirmationComment;
        this.Interval = Interval;
        this.CustomInterval = CustomInterval;
        this.AdditionalReminders = AdditionalReminders;
    }
}

export function ReminderDateDto_$reflection() {
    return record_type("Shared.ReminderDateDto", [], ReminderDateDto, () => [["Id", string_type], ["Date", class_type("System.DateTime")], ["Title", string_type], ["Description", string_type], ["IsConfirmed", bool_type], ["ConfirmationComment", string_type], ["Interval", option_type(ReminderInterval_$reflection())], ["CustomInterval", option_type(CustomIntervalDefinition_$reflection())], ["AdditionalReminders", list_type(AdditionalReminderDefinition_$reflection())]]);
}

export class ReservationState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Planned", "Completed"];
    }
}

export function ReservationState_$reflection() {
    return union_type("Shared.ReservationState", [], ReservationState, () => [[], []]);
}

export class MailService_SendMailToWithContent extends Record {
    constructor(Content, MailAddress, Subject) {
        super();
        this.Content = Content;
        this.MailAddress = MailAddress;
        this.Subject = Subject;
    }
}

export function MailService_SendMailToWithContent_$reflection() {
    return record_type("Shared.MailService.SendMailToWithContent", [], MailService_SendMailToWithContent, () => [["Content", string_type], ["MailAddress", list_type(string_type)], ["Subject", string_type]]);
}

export class Version_MinAppVersion extends Record {
    constructor(Version) {
        super();
        this.Version = Version;
    }
}

export function Version_MinAppVersion_$reflection() {
    return record_type("Shared.Version.MinAppVersion", [], Version_MinAppVersion, () => [["Version", string_type]]);
}

export class LoginInformation extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Email", "PhoneNumber"];
    }
}

export function LoginInformation_$reflection() {
    return union_type("Shared.LoginInformation", [], LoginInformation, () => [[["Item", string_type]], [["Item", string_type]]]);
}

export class LoginDto extends Record {
    constructor(Username, Password) {
        super();
        this.Username = Username;
        this.Password = Password;
    }
}

export function LoginDto_$reflection() {
    return record_type("Shared.LoginDto", [], LoginDto, () => [["Username", string_type], ["Password", string_type]]);
}

export class ResetPasswordDto extends Record {
    constructor(Username) {
        super();
        this.Username = Username;
    }
}

export function ResetPasswordDto_$reflection() {
    return record_type("Shared.ResetPasswordDto", [], ResetPasswordDto, () => [["Username", string_type]]);
}

export class CheckIfPhoneNumberExistsDto extends Record {
    constructor(PhoneNumber) {
        super();
        this.PhoneNumber = PhoneNumber;
    }
}

export function CheckIfPhoneNumberExistsDto_$reflection() {
    return record_type("Shared.CheckIfPhoneNumberExistsDto", [], CheckIfPhoneNumberExistsDto, () => [["PhoneNumber", string_type]]);
}

export class UserFormResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Created", "SubcontractorExists", "EmailExists", "PhoneNumberExists", "NoStorageAssignment", "NoMoreUserContigent"];
    }
}

export function UserFormResponse_$reflection() {
    return union_type("Shared.UserFormResponse", [], UserFormResponse, () => [[["Item", string_type]], [], [], [], [], []]);
}

export class AssignToolDto extends Record {
    constructor(ReceiverId, SenderId, StorageId, ToolId, Quantity, Type, Comment$, WithSignature, PlannedReturnDate) {
        super();
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.ToolId = ToolId;
        this.Quantity = Quantity;
        this.Type = Type;
        this.Comment = Comment$;
        this.WithSignature = WithSignature;
        this.PlannedReturnDate = PlannedReturnDate;
    }
}

export function AssignToolDto_$reflection() {
    return record_type("Shared.AssignToolDto", [], AssignToolDto, () => [["ReceiverId", string_type], ["SenderId", string_type], ["StorageId", string_type], ["ToolId", string_type], ["Quantity", option_type(int32_type)], ["Type", string_type], ["Comment", option_type(string_type)], ["WithSignature", bool_type], ["PlannedReturnDate", option_type(string_type)]]);
}

export class AssignToolWithoutNotificationDto extends Record {
    constructor(ReceiverId, Comment$, SignatureUrl, PlannedReturnDate) {
        super();
        this.ReceiverId = ReceiverId;
        this.Comment = Comment$;
        this.SignatureUrl = SignatureUrl;
        this.PlannedReturnDate = PlannedReturnDate;
    }
}

export function AssignToolWithoutNotificationDto_$reflection() {
    return record_type("Shared.AssignToolWithoutNotificationDto", [], AssignToolWithoutNotificationDto, () => [["ReceiverId", string_type], ["Comment", string_type], ["SignatureUrl", option_type(string_type)], ["PlannedReturnDate", option_type(string_type)]]);
}

export class UpdateReturnDateDto extends Record {
    constructor(PlannedReturnDate) {
        super();
        this.PlannedReturnDate = PlannedReturnDate;
    }
}

export function UpdateReturnDateDto_$reflection() {
    return record_type("Shared.UpdateReturnDateDto", [], UpdateReturnDateDto, () => [["PlannedReturnDate", option_type(string_type)]]);
}

export class AssignToolFromUserToUserDto extends Record {
    constructor(ReceiverId, Comment$) {
        super();
        this.ReceiverId = ReceiverId;
        this.Comment = Comment$;
    }
}

export function AssignToolFromUserToUserDto_$reflection() {
    return record_type("Shared.AssignToolFromUserToUserDto", [], AssignToolFromUserToUserDto, () => [["ReceiverId", string_type], ["Comment", string_type]]);
}

export class UnassignToolWithoutNotificationDto extends Record {
    constructor(Comment$) {
        super();
        this.Comment = Comment$;
    }
}

export function UnassignToolWithoutNotificationDto_$reflection() {
    return record_type("Shared.UnassignToolWithoutNotificationDto", [], UnassignToolWithoutNotificationDto, () => [["Comment", string_type]]);
}

export class AssignToolWithQuantityWithoutNotificationDto extends Record {
    constructor(ReceiverId, SenderId, StorageId, Quantity, Comment$, SignatureUrl) {
        super();
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.Quantity = (Quantity | 0);
        this.Comment = Comment$;
        this.SignatureUrl = SignatureUrl;
    }
}

export function AssignToolWithQuantityWithoutNotificationDto_$reflection() {
    return record_type("Shared.AssignToolWithQuantityWithoutNotificationDto", [], AssignToolWithQuantityWithoutNotificationDto, () => [["ReceiverId", string_type], ["SenderId", option_type(string_type)], ["StorageId", string_type], ["Quantity", int32_type], ["Comment", string_type], ["SignatureUrl", option_type(string_type)]]);
}

export class AssignToolWithQuantityWithNotificationDto extends Record {
    constructor(ReceiverId, StorageId, Quantity, Comment$, WithSignature, Type) {
        super();
        this.ReceiverId = ReceiverId;
        this.StorageId = StorageId;
        this.Quantity = (Quantity | 0);
        this.Comment = Comment$;
        this.WithSignature = WithSignature;
        this.Type = Type;
    }
}

export function AssignToolWithQuantityWithNotificationDto_$reflection() {
    return record_type("Shared.AssignToolWithQuantityWithNotificationDto", [], AssignToolWithQuantityWithNotificationDto, () => [["ReceiverId", string_type], ["StorageId", string_type], ["Quantity", int32_type], ["Comment", string_type], ["WithSignature", bool_type], ["Type", string_type]]);
}

export class UnassignToolWithQuantityWithoutNotificationDto extends Record {
    constructor(ReceiverId, StorageId, Quantity, Comment$) {
        super();
        this.ReceiverId = ReceiverId;
        this.StorageId = StorageId;
        this.Quantity = (Quantity | 0);
        this.Comment = Comment$;
    }
}

export function UnassignToolWithQuantityWithoutNotificationDto_$reflection() {
    return record_type("Shared.UnassignToolWithQuantityWithoutNotificationDto", [], UnassignToolWithQuantityWithoutNotificationDto, () => [["ReceiverId", string_type], ["StorageId", string_type], ["Quantity", int32_type], ["Comment", string_type]]);
}

export class UnassignToolWithQuantityWithNotificationDto extends Record {
    constructor(ReceiverId, StorageId, Quantity, Comment$, Type) {
        super();
        this.ReceiverId = ReceiverId;
        this.StorageId = StorageId;
        this.Quantity = (Quantity | 0);
        this.Comment = Comment$;
        this.Type = Type;
    }
}

export function UnassignToolWithQuantityWithNotificationDto_$reflection() {
    return record_type("Shared.UnassignToolWithQuantityWithNotificationDto", [], UnassignToolWithQuantityWithNotificationDto, () => [["ReceiverId", option_type(string_type)], ["StorageId", string_type], ["Quantity", int32_type], ["Comment", string_type], ["Type", string_type]]);
}

export class AssignConsumableDto extends Record {
    constructor(ReceiverId, SenderId, StorageId, ConsumableId, Comment$, Quantity, WithSignature, CommissionNumber, Type) {
        super();
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.ConsumableId = ConsumableId;
        this.Comment = Comment$;
        this.Quantity = Quantity;
        this.WithSignature = WithSignature;
        this.CommissionNumber = CommissionNumber;
        this.Type = Type;
    }
}

export function AssignConsumableDto_$reflection() {
    return record_type("Shared.AssignConsumableDto", [], AssignConsumableDto, () => [["ReceiverId", string_type], ["SenderId", string_type], ["StorageId", string_type], ["ConsumableId", string_type], ["Comment", option_type(string_type)], ["Quantity", float64_type], ["WithSignature", bool_type], ["CommissionNumber", string_type], ["Type", string_type]]);
}

export class AssignConsumableV2Dto extends Record {
    constructor(ReceiverId, StorageId, Comment$, Quantity, SignatureUrl, CommissionNumber) {
        super();
        this.ReceiverId = ReceiverId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.Quantity = Quantity;
        this.SignatureUrl = SignatureUrl;
        this.CommissionNumber = CommissionNumber;
    }
}

export function AssignConsumableV2Dto_$reflection() {
    return record_type("Shared.AssignConsumableV2Dto", [], AssignConsumableV2Dto, () => [["ReceiverId", string_type], ["StorageId", string_type], ["Comment", string_type], ["Quantity", float64_type], ["SignatureUrl", option_type(string_type)], ["CommissionNumber", string_type]]);
}

export class AssignConsumableWithNotificationDto extends Record {
    constructor(ReceiverId, StorageId, Comment$, CommissionNumber, Quantity, WithSignature, Type) {
        super();
        this.ReceiverId = ReceiverId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.CommissionNumber = CommissionNumber;
        this.Quantity = Quantity;
        this.WithSignature = WithSignature;
        this.Type = Type;
    }
}

export function AssignConsumableWithNotificationDto_$reflection() {
    return record_type("Shared.AssignConsumableWithNotificationDto", [], AssignConsumableWithNotificationDto, () => [["ReceiverId", string_type], ["StorageId", string_type], ["Comment", string_type], ["CommissionNumber", string_type], ["Quantity", float64_type], ["WithSignature", bool_type], ["Type", string_type]]);
}

export class UnassignConsumableV2Dto extends Record {
    constructor(ReceiverId, StorageId, Comment$, Quantity, CommissionNumber) {
        super();
        this.ReceiverId = ReceiverId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.Quantity = Quantity;
        this.CommissionNumber = CommissionNumber;
    }
}

export function UnassignConsumableV2Dto_$reflection() {
    return record_type("Shared.UnassignConsumableV2Dto", [], UnassignConsumableV2Dto, () => [["ReceiverId", string_type], ["StorageId", string_type], ["Comment", string_type], ["Quantity", float64_type], ["CommissionNumber", string_type]]);
}

export class UnassignConsumableWithNotificationDto extends Record {
    constructor(ReceiverId, StorageId, Comment$, Quantity, CommissionNumber, Type) {
        super();
        this.ReceiverId = ReceiverId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.Quantity = Quantity;
        this.CommissionNumber = CommissionNumber;
        this.Type = Type;
    }
}

export function UnassignConsumableWithNotificationDto_$reflection() {
    return record_type("Shared.UnassignConsumableWithNotificationDto", [], UnassignConsumableWithNotificationDto, () => [["ReceiverId", option_type(string_type)], ["StorageId", string_type], ["Comment", string_type], ["Quantity", float64_type], ["CommissionNumber", string_type], ["Type", string_type]]);
}

export class AssignConsumableWithoutNotificationDto extends Record {
    constructor(AffectedReceiverId, StorageId, Type, Comment$, WithSignature, SignatureUrl, CommissionNumber, Quantity) {
        super();
        this.AffectedReceiverId = AffectedReceiverId;
        this.StorageId = StorageId;
        this.Type = Type;
        this.Comment = Comment$;
        this.WithSignature = WithSignature;
        this.SignatureUrl = SignatureUrl;
        this.CommissionNumber = CommissionNumber;
        this.Quantity = Quantity;
    }
}

export function AssignConsumableWithoutNotificationDto_$reflection() {
    return record_type("Shared.AssignConsumableWithoutNotificationDto", [], AssignConsumableWithoutNotificationDto, () => [["AffectedReceiverId", string_type], ["StorageId", string_type], ["Type", string_type], ["Comment", string_type], ["WithSignature", bool_type], ["SignatureUrl", option_type(string_type)], ["CommissionNumber", string_type], ["Quantity", float64_type]]);
}

export class AcceptAssignConsumableDto extends Record {
    constructor(CommissionNumber, SignatureUrl, Comment$) {
        super();
        this.CommissionNumber = CommissionNumber;
        this.SignatureUrl = SignatureUrl;
        this.Comment = Comment$;
    }
}

export function AcceptAssignConsumableDto_$reflection() {
    return record_type("Shared.AcceptAssignConsumableDto", [], AcceptAssignConsumableDto, () => [["CommissionNumber", string_type], ["SignatureUrl", option_type(string_type)], ["Comment", option_type(string_type)]]);
}

export class UpdateStockDto extends Record {
    constructor(StorageId, Quantity, Comment$) {
        super();
        this.StorageId = StorageId;
        this.Quantity = (Quantity | 0);
        this.Comment = Comment$;
    }
}

export function UpdateStockDto_$reflection() {
    return record_type("Shared.UpdateStockDto", [], UpdateStockDto, () => [["StorageId", string_type], ["Quantity", int32_type], ["Comment", string_type]]);
}

export class UpdateNotificationStockDto extends Record {
    constructor(StorageId, NotificationQuantity) {
        super();
        this.StorageId = StorageId;
        this.NotificationQuantity = (NotificationQuantity | 0);
    }
}

export function UpdateNotificationStockDto_$reflection() {
    return record_type("Shared.UpdateNotificationStockDto", [], UpdateNotificationStockDto, () => [["StorageId", string_type], ["NotificationQuantity", int32_type]]);
}

export class UpdateToolStorageDto extends Record {
    constructor(OldStorageId, NewStorageId, StorageSpace) {
        super();
        this.OldStorageId = OldStorageId;
        this.NewStorageId = NewStorageId;
        this.StorageSpace = StorageSpace;
    }
}

export function UpdateToolStorageDto_$reflection() {
    return record_type("Shared.UpdateToolStorageDto", [], UpdateToolStorageDto, () => [["OldStorageId", StorageId_$reflection()], ["NewStorageId", StorageId_$reflection()], ["StorageSpace", string_type]]);
}

export class UpdateStorageSpaceDto extends Record {
    constructor(StorageId, StorageSpace) {
        super();
        this.StorageId = StorageId;
        this.StorageSpace = StorageSpace;
    }
}

export function UpdateStorageSpaceDto_$reflection() {
    return record_type("Shared.UpdateStorageSpaceDto", [], UpdateStorageSpaceDto, () => [["StorageId", StorageId_$reflection()], ["StorageSpace", string_type]]);
}

export class UpdateToolWithQuantityStorageDto extends Record {
    constructor(OldStorageId, NewStorageId, Quantity, StorageSpace) {
        super();
        this.OldStorageId = OldStorageId;
        this.NewStorageId = NewStorageId;
        this.Quantity = (Quantity | 0);
        this.StorageSpace = StorageSpace;
    }
}

export function UpdateToolWithQuantityStorageDto_$reflection() {
    return record_type("Shared.UpdateToolWithQuantityStorageDto", [], UpdateToolWithQuantityStorageDto, () => [["OldStorageId", string_type], ["NewStorageId", string_type], ["Quantity", int32_type], ["StorageSpace", string_type]]);
}

export class UpdateConsumableStorageDto extends Record {
    constructor(OldStorageId, NewStorageId, Quantity) {
        super();
        this.OldStorageId = OldStorageId;
        this.NewStorageId = NewStorageId;
        this.Quantity = Quantity;
    }
}

export function UpdateConsumableStorageDto_$reflection() {
    return record_type("Shared.UpdateConsumableStorageDto", [], UpdateConsumableStorageDto, () => [["OldStorageId", string_type], ["NewStorageId", string_type], ["Quantity", float64_type]]);
}

export class UpdateStockResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["StockUpdated", "NegativeStockNotAllowed"];
    }
}

export function UpdateStockResult_$reflection() {
    return union_type("Shared.UpdateStockResult", [], UpdateStockResult, () => [[], []]);
}

export class UpdateNotificationStockResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NotificationStockUpdated", "NegativeNotificationStockNotAllowed"];
    }
}

export function UpdateNotificationStockResult_$reflection() {
    return union_type("Shared.UpdateNotificationStockResult", [], UpdateNotificationStockResult, () => [[], []]);
}

export class UpdateConsumableStockDto extends Record {
    constructor(StorageId, Quantity, Comment$) {
        super();
        this.StorageId = StorageId;
        this.Quantity = Quantity;
        this.Comment = Comment$;
    }
}

export function UpdateConsumableStockDto_$reflection() {
    return record_type("Shared.UpdateConsumableStockDto", [], UpdateConsumableStockDto, () => [["StorageId", string_type], ["Quantity", float64_type], ["Comment", string_type]]);
}

export class UpdateConsumableNotificationStockDto extends Record {
    constructor(StorageId, Quantity) {
        super();
        this.StorageId = StorageId;
        this.Quantity = Quantity;
    }
}

export function UpdateConsumableNotificationStockDto_$reflection() {
    return record_type("Shared.UpdateConsumableNotificationStockDto", [], UpdateConsumableNotificationStockDto, () => [["StorageId", string_type], ["Quantity", float64_type]]);
}

export class ReportToolProblemDto extends Record {
    constructor(SenderId, ToolId, Message, ImageUrl) {
        super();
        this.SenderId = SenderId;
        this.ToolId = ToolId;
        this.Message = Message;
        this.ImageUrl = ImageUrl;
    }
}

export function ReportToolProblemDto_$reflection() {
    return record_type("Shared.ReportToolProblemDto", [], ReportToolProblemDto, () => [["SenderId", string_type], ["ToolId", string_type], ["Message", string_type], ["ImageUrl", option_type(string_type)]]);
}

export class TemporaryFile extends Record {
    constructor(Name, Content) {
        super();
        this.Name = Name;
        this.Content = Content;
    }
}

export function TemporaryFile_$reflection() {
    return record_type("Shared.TemporaryFile", [], TemporaryFile, () => [["Name", string_type], ["Content", array_type(uint8_type)]]);
}

export class TokenContent extends Record {
    constructor(UserId, CustomerId, Role) {
        super();
        this.UserId = UserId;
        this.CustomerId = CustomerId;
        this.Role = Role;
    }
}

export function TokenContent_$reflection() {
    return record_type("Shared.TokenContent", [], TokenContent, () => [["UserId", string_type], ["CustomerId", string_type], ["Role", string_type]]);
}

export class DbUser extends Record {
    constructor(Id, Email, Firstname, Lastname, Roles, PasswordHash, Salt) {
        super();
        this.Id = (Id | 0);
        this.Email = Email;
        this.Firstname = Firstname;
        this.Lastname = Lastname;
        this.Roles = Roles;
        this.PasswordHash = PasswordHash;
        this.Salt = Salt;
    }
}

export function DbUser_$reflection() {
    return record_type("Shared.DbUser", [], DbUser, () => [["Id", int32_type], ["Email", string_type], ["Firstname", string_type], ["Lastname", string_type], ["Roles", list_type(string_type)], ["PasswordHash", string_type], ["Salt", string_type]]);
}

export class UserTentantConfiguration extends Record {
    constructor(Tenant, Role) {
        super();
        this.Tenant = Tenant;
        this.Role = Role;
    }
}

export function UserTentantConfiguration_$reflection() {
    return record_type("Shared.UserTentantConfiguration", [], UserTentantConfiguration, () => [["Tenant", string_type], ["Role", string_type]]);
}

export class TokenDataDto extends Record {
    constructor(UserName, UserId, CustomerId, Customer, MainTenant, Tenants, Role, Token) {
        super();
        this.UserName = UserName;
        this.UserId = UserId;
        this.CustomerId = CustomerId;
        this.Customer = Customer;
        this.MainTenant = MainTenant;
        this.Tenants = Tenants;
        this.Role = Role;
        this.Token = Token;
    }
}

export function TokenDataDto_$reflection() {
    return record_type("Shared.TokenDataDto", [], TokenDataDto, () => [["UserName", string_type], ["UserId", string_type], ["CustomerId", string_type], ["Customer", Customer_$reflection()], ["MainTenant", UserTentantConfiguration_$reflection()], ["Tenants", option_type(list_type(UserTentantConfiguration_$reflection()))], ["Role", string_type], ["Token", string_type]]);
}

export class TokenData extends Record {
    constructor(UserName, DisplayName, UserId, CustomerId, Tenants, Role, Token) {
        super();
        this.UserName = UserName;
        this.DisplayName = DisplayName;
        this.UserId = UserId;
        this.CustomerId = CustomerId;
        this.Tenants = Tenants;
        this.Role = Role;
        this.Token = Token;
    }
}

export function TokenData_$reflection() {
    return record_type("Shared.TokenData", [], TokenData, () => [["UserName", string_type], ["DisplayName", string_type], ["UserId", UserId_$reflection()], ["CustomerId", string_type], ["Tenants", option_type(list_type(UserTentantConfiguration_$reflection()))], ["Role", string_type], ["Token", string_type]]);
}

export class Token extends Record {
    constructor(UserName, UserId, Roles) {
        super();
        this.UserName = UserName;
        this.UserId = UserId;
        this.Roles = Roles;
    }
}

export function Token_$reflection() {
    return record_type("Shared.Token", [], Token, () => [["UserName", string_type], ["UserId", string_type], ["Roles", list_type(string_type)]]);
}

export class AuthResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "AccessDenied"];
    }
}

export function AuthResponse_$reflection() {
    return union_type("Shared.AuthResponse", [], AuthResponse, () => [[["Item", TokenDataDto_$reflection()]], []]);
}

export class ResetPasswordResponse extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Reseted"];
    }
}

export function ResetPasswordResponse_$reflection() {
    return union_type("Shared.ResetPasswordResponse", [], ResetPasswordResponse, () => [[]]);
}

export class EmptyResponse extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Empty"];
    }
}

export function EmptyResponse_$reflection() {
    return union_type("Shared.EmptyResponse", [], EmptyResponse, () => [[]]);
}

export class PostResponse$1 extends Record {
    constructor(Result) {
        super();
        this.Result = Result;
    }
}

export function PostResponse$1_$reflection(gen0) {
    return record_type("Shared.PostResponse`1", [gen0], PostResponse$1, () => [["Result", gen0]]);
}

export class ImageDeletedResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "Failed"];
    }
}

export function ImageDeletedResponse_$reflection() {
    return union_type("Shared.ImageDeletedResponse", [], ImageDeletedResponse, () => [[], []]);
}

export class Validation extends Record {
    constructor(AffectedProperty, ErrorMessage) {
        super();
        this.AffectedProperty = AffectedProperty;
        this.ErrorMessage = ErrorMessage;
    }
}

export function Validation_$reflection() {
    return record_type("Shared.Validation", [], Validation, () => [["AffectedProperty", string_type], ["ErrorMessage", string_type]]);
}

export class ValidationResponse extends Record {
    constructor(GlobalValidationMessage, Validations) {
        super();
        this.GlobalValidationMessage = GlobalValidationMessage;
        this.Validations = Validations;
    }
}

export function ValidationResponse_$reflection() {
    return record_type("Shared.ValidationResponse", [], ValidationResponse, () => [["GlobalValidationMessage", option_type(string_type)], ["Validations", list_type(Validation_$reflection())]]);
}

export class ApiResponse$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Ok", "BadRequest", "Unauthorized", "Forbidden", "NotFound", "Invalid", "InternalServerError"];
    }
}

export function ApiResponse$1_$reflection(gen0) {
    return union_type("Shared.ApiResponse`1", [gen0], ApiResponse$1, () => [[["Item", gen0]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", ValidationResponse_$reflection()]], [["Item", string_type]]]);
}

export class InternalApiResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Ok", "BadRequest", "Forbidden", "Invalid", "InternalServerError"];
    }
}

export function InternalApiResponse_$reflection() {
    return union_type("Shared.InternalApiResponse", [], InternalApiResponse, () => [[], [["Item", string_type]], [["Item", string_type]], [["Item", ValidationResponse_$reflection()]], [["Item", string_type]]]);
}

export class ClientApiResponse$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Ok", "Invalid"];
    }
}

export function ClientApiResponse$1_$reflection(gen0) {
    return union_type("Shared.ClientApiResponse`1", [gen0], ClientApiResponse$1, () => [[["Item", gen0]], [["Item", ValidationResponse_$reflection()]]]);
}

export class DataTransfer_CreateReservationFormDto extends Record {
    constructor(StartDate, EndDate, Name, ReceiverId, SenderId, NotifyUser, Description) {
        super();
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Name = Name;
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.NotifyUser = NotifyUser;
        this.Description = Description;
    }
}

export function DataTransfer_CreateReservationFormDto_$reflection() {
    return record_type("Shared.DataTransfer.CreateReservationFormDto", [], DataTransfer_CreateReservationFormDto, () => [["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Name", string_type], ["ReceiverId", string_type], ["SenderId", string_type], ["NotifyUser", bool_type], ["Description", string_type]]);
}

export class DataTransfer_UpdateReservationFormDto extends Record {
    constructor(ReservationId, StartDate, EndDate, Name, ReceiverId, SenderId, NotifyUser, Description) {
        super();
        this.ReservationId = ReservationId;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Name = Name;
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.NotifyUser = NotifyUser;
        this.Description = Description;
    }
}

export function DataTransfer_UpdateReservationFormDto_$reflection() {
    return record_type("Shared.DataTransfer.UpdateReservationFormDto", [], DataTransfer_UpdateReservationFormDto, () => [["ReservationId", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Name", string_type], ["ReceiverId", string_type], ["SenderId", string_type], ["NotifyUser", bool_type], ["Description", string_type]]);
}

export class ValidationModule_Validation {
    constructor() {
    }
}

export function ValidationModule_Validation_$reflection() {
    return class_type("Shared.ValidationModule.Validation", undefined, ValidationModule_Validation);
}

export function ValidationModule_Validation_$ctor() {
    return new ValidationModule_Validation();
}

export function ValidationModule_Validation__Bind_5A8BA68(this$, x, f) {
    switch (x.tag) {
        case 0:
            return f(x);
        case 3: {
            const oldValidationResponse = x.fields[0];
            const matchValue = f(x);
            if (matchValue.tag === 3) {
                return new InternalApiResponse(3, [new ValidationResponse(oldValidationResponse.GlobalValidationMessage, append(oldValidationResponse.Validations, matchValue.fields[0].Validations))]);
            }
            else {
                return x;
            }
        }
        default:
            return x;
    }
}

export function ValidationModule_Validation__Return_1505(this$, x) {
    return x;
}

export const ValidationModule_validate = ValidationModule_Validation_$ctor();

export class FormState extends Record {
    constructor(Validations, IsLoading) {
        super();
        this.Validations = Validations;
        this.IsLoading = IsLoading;
    }
}

export function FormState_$reflection() {
    return class_type("Shared.FormState", undefined, FormState, class_type("System.ValueType"));
}

export function FormState_$ctor_240AE3F4(validations, isLoading) {
    return new FormState(validations, isLoading);
}

export function FormState_get_empty() {
    return FormState_$ctor_240AE3F4(empty({
        Compare: comparePrimitives,
    }), false);
}

/**
 * Append a single validation
 */
export function FormState__addValidation(this$, affectedProperty, errorMessage) {
    return FormState_$ctor_240AE3F4(FSharpMap__Add(this$.Validations, affectedProperty, errorMessage), this$.IsLoading);
}

/**
 * Append a list of validations
 */
export function FormState__addValidations_Z51B1D053(this$, validations) {
    return FormState_$ctor_240AE3F4(ofList(append(validations, toList(this$.Validations)), {
        Compare: comparePrimitives,
    }), this$.IsLoading);
}

/**
 * Set a single validation
 */
export function FormState__setValidation(this$, affectedProperty, errorMessage) {
    return FormState_$ctor_240AE3F4(ofList(singleton([affectedProperty, errorMessage]), {
        Compare: comparePrimitives,
    }), this$.IsLoading);
}

/**
 * Set a list of validations
 */
export function FormState__setValidations_Z51B1D053(this$, validations) {
    return FormState_$ctor_240AE3F4(ofList(validations, {
        Compare: comparePrimitives,
    }), this$.IsLoading);
}

/**
 * Add the ValidationResponse into the FormState and set IsLoading = false
 */
export function FormState__setValidationResponse_5219762A(this$, validationResponse) {
    let matchValue;
    const validations = ofList(map((validation) => [validation.AffectedProperty, validation.ErrorMessage], validationResponse.Validations), {
        Compare: comparePrimitives,
    });
    return FormState_$ctor_240AE3F4((matchValue = validationResponse.GlobalValidationMessage, (matchValue == null) ? validations : FSharpMap__Add(validations, "global", matchValue)), false);
}

export function FormState__isValid(this$) {
    return FSharpMap__get_IsEmpty(this$.Validations);
}

/**
 * Resets the Validations and set IsLoading = true
 */
export function FormState__startLoading(this$) {
    return FormState_$ctor_240AE3F4(empty({
        Compare: comparePrimitives,
    }), true);
}

export function FormState__getValidation(this$, translate, affectedProperty) {
    return map_1(translate, FSharpMap__TryFind(this$.Validations, affectedProperty));
}

export class FormKind extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["New", "Edit", "Copy"];
    }
}

export function FormKind_$reflection() {
    return union_type("Shared.FormKind", [], FormKind, () => [[], [], []]);
}

