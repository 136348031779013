import { ToolId, SetId, ConsumableId, FormState__startLoading, FormState__setValidationResponse_5219762A, NotificationId, RequestedValue$1, FormState_get_empty } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { acceptToolNotification, acceptToolWithQuantityNotification, acceptSetNotification, acceptConsumableNotification, rejectNotification, getMailNotification } from "../../Requests/Notification.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { tryHead, length, singleton, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { uploadSignature } from "../../Requests/Files.js";
import { AcceptNotificationDto } from "../../Shared/Tool.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";

export function init(notificationId) {
    return [new State(notificationId, FormState_get_empty(), new RequestedValue$1(0, []), undefined, undefined), getMailNotification(new NotificationId(notificationId), (Item_1) => (new Msg(4, [Item_1])), (Item_2) => (new Msg(8, [Item_2])))];
}

export function update(msg, state) {
    let xs, xs_2;
    switch (msg.tag) {
        case 2: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.NotificationId, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0]), state.AcceptEmailNotificationDetail, state.Signature, state.SignatureUrl), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.NotificationId, FormState_get_empty(), state.AcceptEmailNotificationDetail, state.Signature, state.SignatureUrl), Cmd_batch(ofArray([Cmd_successToast("general.saved_successfully"), (xs = ["accept-email-notification", "success"], Cmd_ofEffect((_arg) => {
                    RouterModule_nav(ofArray(xs), 1, 1);
                }))]))];
            }
        }
        case 3: {
            const response_1 = msg.fields[0];
            if (response_1.tag === 1) {
                return [new State(state.NotificationId, FormState__setValidationResponse_5219762A(state.FormState, response_1.fields[0]), state.AcceptEmailNotificationDetail, state.Signature, state.SignatureUrl), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.NotificationId, FormState_get_empty(), state.AcceptEmailNotificationDetail, state.Signature, state.SignatureUrl), Cmd_batch(ofArray([Cmd_successToast("general.saved_successfully"), (xs_2 = ["accept-email-notification", "rejected"], Cmd_ofEffect((_arg_1) => {
                    RouterModule_nav(ofArray(xs_2), 1, 1);
                }))]))];
            }
        }
        case 1:
            return [new State(state.NotificationId, FormState__startLoading(state.FormState), state.AcceptEmailNotificationDetail, state.Signature, state.SignatureUrl), rejectNotification(new NotificationId(state.NotificationId), (Item_12) => (new Msg(3, [Item_12])), (Item_13) => (new Msg(8, [Item_13])))];
        case 4:
            return [new State(state.NotificationId, state.FormState, new RequestedValue$1(1, [msg.fields[0]]), state.Signature, state.SignatureUrl), Cmd_none()];
        case 5:
            return [new State(state.NotificationId, state.FormState, state.AcceptEmailNotificationDetail, msg.fields[0], state.SignatureUrl), Cmd_none()];
        case 6: {
            const matchValue_2 = state.Signature;
            if (matchValue_2 == null) {
                return [state, Cmd_none()];
            }
            else {
                const signature = matchValue_2;
                const formData = new FormData();
                formData.append(signature.name, signature);
                return [new State(state.NotificationId, FormState__startLoading(state.FormState), state.AcceptEmailNotificationDetail, state.Signature, undefined), uploadSignature(formData, (Item_14) => (new Msg(7, [Item_14])), (Item_15) => (new Msg(8, [Item_15])))];
            }
        }
        case 7: {
            const response_2 = msg.fields[0];
            switch (response_2.tag) {
                case 0:
                    return [state, Cmd_errorToast("general.file_too_large")];
                case 2:
                    return [state, Cmd_errorToast("general.a_error_occured")];
                default:
                    return [new State(state.NotificationId, state.FormState, state.AcceptEmailNotificationDetail, state.Signature, response_2.fields[0].ImageUrl), singleton((dispatch) => {
                        dispatch(new Msg(0, []));
                    })];
            }
        }
        case 8:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.AcceptEmailNotificationDetail;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const dto = matchValue.fields[0];
                if ((((length(dto.Consumables) + length(dto.Sets)) + length(dto.Tools)) + length(dto.ToolsWithQuantity)) === 1) {
                    const tool = tryHead(dto.Tools);
                    const toolWithQuantity = tryHead(dto.ToolsWithQuantity);
                    const set$ = tryHead(dto.Sets);
                    const consumable = tryHead(dto.Consumables);
                    const dto_1 = new AcceptNotificationDto(state.SignatureUrl, undefined);
                    if (tool == null) {
                        if (toolWithQuantity == null) {
                            if (set$ == null) {
                                if (consumable == null) {
                                    return [state, Cmd_none()];
                                }
                                else {
                                    const consumable_1 = consumable;
                                    return [new State(state.NotificationId, FormState__startLoading(state.FormState), state.AcceptEmailNotificationDetail, state.Signature, state.SignatureUrl), acceptConsumableNotification(new ConsumableId(parse(consumable_1.Id)), new NotificationId(state.NotificationId), dto_1, (Item_10) => (new Msg(2, [Item_10])), (Item_11) => (new Msg(8, [Item_11])))];
                                }
                            }
                            else {
                                const set$_1 = set$;
                                return [new State(state.NotificationId, FormState__startLoading(state.FormState), state.AcceptEmailNotificationDetail, state.Signature, state.SignatureUrl), acceptSetNotification(new SetId(parse(set$_1.Id)), new NotificationId(state.NotificationId), dto_1, (Item_7) => (new Msg(2, [Item_7])), (Item_8) => (new Msg(8, [Item_8])))];
                            }
                        }
                        else {
                            const tool_2 = toolWithQuantity;
                            return [new State(state.NotificationId, FormState__startLoading(state.FormState), state.AcceptEmailNotificationDetail, state.Signature, state.SignatureUrl), acceptToolWithQuantityNotification(new ToolId(parse(tool_2.Id)), new NotificationId(state.NotificationId), dto_1, (Item_4) => (new Msg(2, [Item_4])), (Item_5) => (new Msg(8, [Item_5])))];
                        }
                    }
                    else {
                        const tool_1 = tool;
                        return [new State(state.NotificationId, FormState__startLoading(state.FormState), state.AcceptEmailNotificationDetail, state.Signature, state.SignatureUrl), acceptToolNotification(new ToolId(parse(tool_1.Id)), new NotificationId(state.NotificationId), dto_1, (Item_1) => (new Msg(2, [Item_1])), (Item_2) => (new Msg(8, [Item_2])))];
                    }
                }
                else {
                    return [state, Cmd_none()];
                }
            }
        }
    }
}

