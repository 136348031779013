import { Record, Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, list_type, string_type, lambda_type, unit_type, union_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { User_$reflection } from "../Shared/User.js";
import { PropertyConfiguration_$reflection } from "../Shared/PropertyConfiguration.js";
import { Cmd_ofEffect, Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { empty, singleton, append, delay, toArray } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TableControl$1, Table, ColumnDefinition$1, CellContent } from "../Widgets/Table/Table.js";
import { roleToString } from "../Shared/Helper.js";
import { Feature, isFeatureEnabled } from "../FeatureFlip.js";
import { Table_customPropertyColumnDefinitions } from "../Widgets/CustomProperties.js";
import { ofArray, contains, filter, empty as empty_1, map, isEmpty, singleton as singleton_1, toArray as toArray_1 } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import User_Add_Plus from "../../public/assets/icons/User_Add_Plus.svg";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ModalSheet } from "../Components/ModalSheet.js";
import { stringHash, createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import Delete_1 from "../../public/assets/icons/Delete_1.svg";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { TableContainer } from "../Widgets/Container.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.2/Util.js";

class Modal_Msg extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["OnSave"];
    }
}

function Modal_Msg_$reflection() {
    return union_type("Receiver.UserSelect.Modal.Msg", [], Modal_Msg, () => [[]]);
}

class Modal_State extends Record {
    constructor(OnClose, SelectedUserIds, Users, PropertyConfigurations, OnSave) {
        super();
        this.OnClose = OnClose;
        this.SelectedUserIds = SelectedUserIds;
        this.Users = Users;
        this.PropertyConfigurations = PropertyConfigurations;
        this.OnSave = OnSave;
    }
}

function Modal_State_$reflection() {
    return record_type("Receiver.UserSelect.Modal.State", [], Modal_State, () => [["OnClose", lambda_type(unit_type, unit_type)], ["SelectedUserIds", list_type(string_type)], ["Users", list_type(User_$reflection())], ["PropertyConfigurations", list_type(PropertyConfiguration_$reflection())], ["OnSave", lambda_type(list_type(string_type), unit_type)]]);
}

function Modal_init(props) {
    return [new Modal_State(props.OnClose, props.SelectedUserIds, props.Users, props.PropertyConfigurations, props.OnSave), Cmd_none()];
}

function Modal_update(msg, state) {
    return [state, Cmd_ofEffect((_arg) => {
        state.OnSave(state.SelectedUserIds);
    })];
}

function Modal_SelectUserContent(props) {
    let TableRows, Options;
    const t = useTranslation()[0];
    const columnDefinitions = toArray(delay(() => append(singleton(new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("personnelNumber", "", t("user.personnel_number"), (dto_1) => (new CellContent(0, [dto_1.PersonnelNumber])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("emailPhoneNumber", "", t("user.email_mobile_number"), (dto_2) => {
        const matchValue = dto_2.LoginInformation;
        return (matchValue.tag === 1) ? (new CellContent(0, [matchValue.fields[0]])) : (new CellContent(0, [matchValue.fields[0]]));
    }, "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("department", "", t("user.department"), (dto_3) => (new CellContent(0, [dto_3.Department])), "select", "text")), delay(() => append(singleton(new ColumnDefinition$1("role", "", t("general.role"), (dto_4) => (new CellContent(0, [t(`role.${roleToString(dto_4.Role)}`)])), "select", "text")), delay(() => append(isFeatureEnabled(new Feature(6, [])) ? singleton(new ColumnDefinition$1("archived", "", t("user.is_archived"), (dto_5) => (new CellContent(0, [dto_5.IsArchived ? t("general.yes") : t("general.no")])), "select", "text")) : empty(), delay(() => Table_customPropertyColumnDefinitions(props.PropertyConfigurations, t("tool.custom_properties"), (dto_6) => dto_6.CustomProperties, t)))))))))))))));
    return createElement(Table, (TableRows = toArray_1(props.Users), (Options = {
        BorderStyle: "full",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultColumnFilter: unwrap(isFeatureEnabled(new Feature(6, [])) ? singleton_1({
            id: "archived",
            value: [t("general.no")],
        }) : undefined),
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: "multiRow",
    }, {
        ColumnDefinitions: columnDefinitions,
        Controls: singleton_1(new TableControl$1(t("general.add"), User_Add_Plus(), (selectedUsers) => {
            if (isEmpty(selectedUsers)) {
            }
            else {
                props.OnSave(map((selectedUser) => selectedUser.Id, selectedUsers));
            }
        }, true, false, "")),
        Options: Options,
        TableRows: TableRows,
    })));
}

export function Modal_SelectUserModal(props) {
    let init;
    const state_1 = ((init = Modal_init({
        OnClose: props.OnClose,
        OnSave: props.OnSave,
        PropertyConfigurations: props.PropertyConfigurations,
        SelectedUserIds: props.SelectedUserIds,
        Users: props.Users,
    }), React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, Modal_update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen])))[0];
    return createElement(ModalSheet, {
        CloseButtonTestId: "",
        Content: createElement(Modal_SelectUserContent, {
            OnSave: props.OnSave,
            PropertyConfigurations: state_1.PropertyConfigurations,
            Users: state_1.Users,
        }),
        Controls: empty_1(),
        IsModalOpen: props.IsOpen,
        OnClose: props.OnClose,
    });
}

function Table_VisibleUserSection(props) {
    let elems, TableRows, Options;
    const t = useTranslation()[0];
    const columnDefinitions = toArray(delay(() => append(singleton(new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("personnelNumber", "", t("user.personnel_number"), (dto_1) => (new CellContent(0, [dto_1.PersonnelNumber])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("department", "", t("user.department"), (dto_2) => (new CellContent(0, [dto_2.Department])), "select", "text")), delay(() => append(singleton(new ColumnDefinition$1("role", "", t("general.role"), (dto_3) => (new CellContent(0, [t(`role.${roleToString(dto_3.Role)}`)])), "select", "text")), delay(() => Table_customPropertyColumnDefinitions(props.PropertyConfigurations, t("tool.custom_properties"), (dto_4) => dto_4.CustomProperties, t)))))))))));
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Modal_SelectUserModal, {
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        OnSave: props.OnSave,
        PropertyConfigurations: props.PropertyConfigurations,
        SelectedUserIds: map((user_1) => user_1.Id, props.Users),
        Users: props.Users,
    }), createElement(Table, (TableRows = toArray_1(filter((user_2) => contains(user_2.Id, props.VisibleForUserIds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), props.Users)), (Options = {
        BorderStyle: "full",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: "multiRow",
    }, {
        ColumnDefinitions: columnDefinitions,
        Controls: ofArray([new TableControl$1(t("general.add"), User_Add_Plus(), (_arg) => {
            props.SetIsOpen();
        }, false, false, ""), new TableControl$1(t("general.remove"), Delete_1(), (users) => {
            props.OnDelete(map((user) => user.Id, users));
        }, true, false, "")]),
        Options: Options,
        TableRows: TableRows,
    })))], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function Table_UserVisibleTable(props) {
    let Title;
    const patternInput = useTranslation();
    if (isFeatureEnabled(new Feature(13, []))) {
        return createElement(TableContainer, (Title = patternInput[0]("receivers.visible_for"), {
            Content: createElement(Table_VisibleUserSection, {
                IsOpen: props.IsOpen,
                OnClose: props.OnClose,
                OnDelete: props.OnDelete,
                OnSave: props.OnSave,
                PropertyConfigurations: props.PropertyConfigurations,
                SetIsOpen: props.SetIsOpen,
                Users: props.Users,
                VisibleForUserIds: props.VisibleForUserIds,
            }),
            Title: unwrap(Title),
        }));
    }
    else {
        return defaultOf();
    }
}

