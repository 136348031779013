import { FSharpException } from "./fable_modules/fable-library-js.4.19.2/Types.js";
import { class_type } from "./fable_modules/fable-library-js.4.19.2/Reflection.js";
import { captureException } from "./Sentry.js";
import { RouterModule_nav } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { ofArray } from "./fable_modules/fable-library-js.4.19.2/List.js";

export class Exception400 extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
}

export function Exception400_$reflection() {
    return class_type("ErrorHandling.Exception400", undefined, Exception400, class_type("System.Exception"));
}

export class Exception401 extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
}

export function Exception401_$reflection() {
    return class_type("ErrorHandling.Exception401", undefined, Exception401, class_type("System.Exception"));
}

export class Exception403 extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
}

export function Exception403_$reflection() {
    return class_type("ErrorHandling.Exception403", undefined, Exception403, class_type("System.Exception"));
}

export class Exception404 extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
}

export function Exception404_$reflection() {
    return class_type("ErrorHandling.Exception404", undefined, Exception404, class_type("System.Exception"));
}

export class Exception500 extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
}

export function Exception500_$reflection() {
    return class_type("ErrorHandling.Exception500", undefined, Exception500, class_type("System.Exception"));
}

export class CouldNotParseValidation extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
}

export function CouldNotParseValidation_$reflection() {
    return class_type("ErrorHandling.CouldNotParseValidation", undefined, CouldNotParseValidation, class_type("System.Exception"));
}

export class UnexpectedResponseCode extends FSharpException {
    constructor(Data0, Data1) {
        super();
        this.Data0 = (Data0 | 0);
        this.Data1 = Data1;
    }
}

export function UnexpectedResponseCode_$reflection() {
    return class_type("ErrorHandling.UnexpectedResponseCode", undefined, UnexpectedResponseCode, class_type("System.Exception"));
}

export class ValidationOnGetRequest extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
}

export function ValidationOnGetRequest_$reflection() {
    return class_type("ErrorHandling.ValidationOnGetRequest", undefined, ValidationOnGetRequest, class_type("System.Exception"));
}

export function handleFetchError(e) {
    if (e instanceof Exception400) {
        captureException(new Error(e.Data0));
        RouterModule_nav(ofArray(["bad-request"]), 1, 1);
    }
    else if (e instanceof Exception401) {
        captureException(new Error(e.Data0));
        RouterModule_nav(ofArray(["unauthorized"]), 1, 1);
    }
    else if (e instanceof Exception403) {
        captureException(new Error(e.Data0));
        RouterModule_nav(ofArray(["forbidden"]), 1, 1);
    }
    else if (e instanceof Exception404) {
        captureException(new Error(`404 - not found: ${e.Data0}`));
        RouterModule_nav(ofArray(["not-found"]), 1, 1);
    }
    else if (e instanceof Exception500) {
        captureException(new Error(e.Data0));
        RouterModule_nav(ofArray(["error"]), 1, 1);
    }
    else {
        captureException(new Error(e.message));
    }
    throw e;
}

