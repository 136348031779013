import { Label_$reflection, RequestedValue$1 } from "../../Shared/Shared.js";
import { InvalidMessage as InvalidMessage_1, Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../../CommunicationV2.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { PropertyConfigurationEnabledResponse_$reflection, PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ValidationOnGetRequest } from "../../ErrorHandling.js";
import { addPrefix as addPrefix_1, fetchWithDecoder as fetchWithDecoder_1 } from "../../Communication.js";

export function init() {
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), undefined), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), "/api/property-configurations", (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(4, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Label_$reflection())));
    })))), "/api/labels", (Item_2) => (new Msg(3, [Item_2])), (Item_3) => (new Msg(4, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix(url_6), (refreshOn_1) => fetchWithDecoder(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_4 = _arg_1;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_4) => (new Msg(2, [Item_4])), (Item_5) => (new Msg(4, [Item_5])))]))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.Labels, state.IsEnabled, state.InvalidMessage), Cmd_none()];
        case 3:
            return [new State(state.CustomProperties, new RequestedValue$1(1, [msg.fields[0]]), state.IsEnabled, state.InvalidMessage), Cmd_none()];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.CustomProperties, state.Labels, new RequestedValue$1(1, [false]), new InvalidMessage_1(2, [])), Cmd_none()];
            }
            else {
                return [new State(state.CustomProperties, state.Labels, new RequestedValue$1(1, [true]), state.InvalidMessage), Cmd_none()];
            }
        case 4:
            return [new State(new RequestedValue$1(0, []), state.Labels, state.IsEnabled, state.InvalidMessage), Cmd_none()];
        default:
            return [state, Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_2;
                return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
                    const pr = response.arrayBuffer();
                    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(PropertyConfiguration_$reflection())));
                }, refreshOn)))(true).then((_arg) => {
                    const result_1 = _arg;
                    return (result_1.tag === 1) ? (Promise.resolve((() => {
                        throw new ValidationOnGetRequest(result_1.fields[0]);
                    })())) : (Promise.resolve(result_1.fields[0]));
                });
            })), "/api/property-configurations", (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(4, [Item_1])))];
    }
}

