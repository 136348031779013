import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { list_type, record_type, option_type, union_type, string_type, class_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { EmptyResponse_$reflection, AccountSettings_$reflection, Label_$reflection, Customer_$reflection, TokenDataDto_$reflection, CustomerConfiguration_$reflection, RequestedValue$1_$reflection, ReminderId_$reflection, ToolReservationId_$reflection } from "../../Shared/Shared.js";
import { ToolDto_$reflection } from "../../Shared/Tool.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { Location_$reflection } from "../../Shared/Location.js";
import { TableConfiguration_$reflection, Receiver_$reflection, UserConfiguration_$reflection } from "../../Shared/User.js";
import { PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";

export class Filter extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ReservationsInDateRange", "FreeInDateRange", "ReservationNameFilter"];
    }
}

export function Filter_$reflection() {
    return union_type("Tools.Overview.Types.Filter", [], Filter, () => [[["startDate", class_type("System.DateTime")], ["endDate", class_type("System.DateTime")]], [["startDate", class_type("System.DateTime")], ["endDate", class_type("System.DateTime")]], [["value", string_type]]]);
}

export class PlanningTypeId extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ReservationId", "ReminderId"];
    }
}

export function PlanningTypeId_$reflection() {
    return union_type("Tools.Overview.Types.PlanningTypeId", [], PlanningTypeId, () => [[["Item", ToolReservationId_$reflection()]], [["Item", ReminderId_$reflection()]]]);
}

export class Planning extends Record {
    constructor(Type, StartDate, EndDate, ReservationName, ReceiverName, Description, PlanningTypeId) {
        super();
        this.Type = Type;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.ReservationName = ReservationName;
        this.ReceiverName = ReceiverName;
        this.Description = Description;
        this.PlanningTypeId = PlanningTypeId;
    }
}

export function Planning_$reflection() {
    return record_type("Tools.Overview.Types.Planning", [], Planning, () => [["Type", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", option_type(class_type("System.DateTime"))], ["ReservationName", string_type], ["ReceiverName", string_type], ["Description", string_type], ["PlanningTypeId", PlanningTypeId_$reflection()]]);
}

export class State extends Record {
    constructor(Tools, Storages, Locations, UserConfiguration, CustomerConfiguration, Receivers, UserData, Customer, Labels, SelectedTools, PropertyConfigurations, SelectedFilter, AccountSettings, ReservationNames) {
        super();
        this.Tools = Tools;
        this.Storages = Storages;
        this.Locations = Locations;
        this.UserConfiguration = UserConfiguration;
        this.CustomerConfiguration = CustomerConfiguration;
        this.Receivers = Receivers;
        this.UserData = UserData;
        this.Customer = Customer;
        this.Labels = Labels;
        this.SelectedTools = SelectedTools;
        this.PropertyConfigurations = PropertyConfigurations;
        this.SelectedFilter = SelectedFilter;
        this.AccountSettings = AccountSettings;
        this.ReservationNames = ReservationNames;
    }
}

export function State_$reflection() {
    return record_type("Tools.Overview.Types.State", [], State, () => [["Tools", RequestedValue$1_$reflection(list_type(ToolDto_$reflection()))], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["Locations", RequestedValue$1_$reflection(list_type(Location_$reflection()))], ["UserConfiguration", RequestedValue$1_$reflection(UserConfiguration_$reflection())], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["UserData", TokenDataDto_$reflection()], ["Customer", Customer_$reflection()], ["Labels", RequestedValue$1_$reflection(list_type(Label_$reflection()))], ["SelectedTools", list_type(ToolDto_$reflection())], ["PropertyConfigurations", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["SelectedFilter", option_type(Filter_$reflection())], ["AccountSettings", RequestedValue$1_$reflection(AccountSettings_$reflection())], ["ReservationNames", RequestedValue$1_$reflection(list_type(string_type))]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetFilter", "ToolsFetched", "StoragesFetched", "LabelsFetched", "LocationsFetched", "PropertyConfigurationsFetched", "UpdateTableConfiguration", "UserConfigurationUpdated", "UserConfigurationFetched", "CustomerConfigurationFetched", "AccountSettingsFetched", "ReservationNamesFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Tools.Overview.Types.Msg", [], Msg, () => [[["Item", option_type(Filter_$reflection())]], [["Item", list_type(ToolDto_$reflection())]], [["Item", list_type(Storage_$reflection())]], [["Item", list_type(Label_$reflection())]], [["Item", list_type(Location_$reflection())]], [["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", TableConfiguration_$reflection()]], [["Item", EmptyResponse_$reflection()]], [["Item", UserConfiguration_$reflection()]], [["Item", CustomerConfiguration_$reflection()]], [["Item", AccountSettings_$reflection()]], [["Item", list_type(string_type)]], [["Item", class_type("System.Exception")]]]);
}

