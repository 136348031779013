import { Cmd_ofEffect, Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../Communication.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { cons, contains, filter, choose, singleton, map, empty, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { DetailedDispolist_$reflection, DetailedDispolist, DispolistDetailedAssignToolWithQuantity, DispolistDetailedAssignConsumable, DetailedDispolistElements, DispolistAssignToolWithQuantity, DispolistAssignConsumable, DispolistAssignTool, Helper_detailedElementId, DispolistOverview_$reflection, AssignDispolistWithoutNotification, AssignDispolistWithoutNotification_$reflection, AssignDispolistResponse_$reflection } from "../Shared/Dispolist.js";
import { PostResponse$1_$reflection } from "../Shared/Shared.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { NotAssignableTools as NotAssignableTools_2, State, Msg } from "./Types.js";
import { AssignDispolistDto, ToAssignConsumable, ToAssignTool, ToAssignToolWithQuantity, AssignDispolistDto_$reflection } from "../Shared/DevicesBulk.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { list_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Receiver_$reflection } from "../Shared/User.js";
import { stringHash } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { unwrapDispolistId } from "../Shared/Helper.js";
import { Cmd_successToast, Cmd_errorToast } from "../Components/Toast.js";
import { value as value_16 } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { parse } from "../fable_modules/fable-library-js.4.19.2/Double.js";
import { replace } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { unwrapDispolistId as unwrapDispolistId_1 } from "../Shared/Helper.js";

function assignDispolistWithoutNotificationCmd(dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(AssignDispolistResponse_$reflection())));
    })))), ["/api/dispolists/assign", toString(0, Auto_generateBoxedEncoder_437914C6(AssignDispolistWithoutNotification_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(12, [Item_1])));
}

function askAssignDispolistCmd(dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(AssignDispolistResponse_$reflection())));
    })))), ["/api/notification/dispolist/bulk", toString(0, Auto_generateBoxedEncoder_437914C6(AssignDispolistDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(12, [Item_1])));
}

function initialState(props) {
    const dto = new AssignDispolistWithoutNotification(props.Receiver.Id, "", "storage_to_user_bulk", "", "", undefined, props.ToAssignToolsWithQuantity, props.ToAssignTools, props.ToAssignConsumables);
    return new State(false, undefined, props.Customer, empty(), undefined, empty(), undefined, props.SuccessCallback, props.OnClose, false, new AssignDispolistDto(props.Receiver.Id, "", "storage_to_user_bulk", "", false, "", map((t) => (new ToAssignToolWithQuantity(t.ToolId, t.Quantity, t.StorageId)), props.ToAssignToolsWithQuantity), map((t_1) => (new ToAssignTool(t_1.ToolId, undefined, t_1.StorageId)), props.ToAssignTools), map((t_2) => (new ToAssignConsumable(t_2.ConsumableId, t_2.Quantity, "", t_2.StorageId)), props.ToAssignConsumables)), dto, false, "");
}

export function init(props) {
    return [initialState(props), Cmd_batch(ofArray([Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(DispolistOverview_$reflection())));
    })))), "/api/dispolists", (Item) => (new Msg(9, [Item])), (Item_1) => (new Msg(12, [Item_1]))), Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), Receiver_$reflection()));
    })))), `/api/receivers/receiver/${props.Receiver.Id}`, (Item_2) => (new Msg(10, [Item_2])), (Item_3) => (new Msg(12, [Item_3])))]))];
}

export function update(msg, state) {
    let toolsToAdd, consumablesToAdd, toolsWithQuantityToAdd, bind$0040, toolsToAdd_1, consumablesToAdd_1, toolsWithQuantityToAdd_1, bind$0040_1;
    switch (msg.tag) {
        case 1:
            return [new State(state.RequestState, state.Receiver, state.Customer, state.Dispolists, state.SelectedDispolist, state.RemovedDispolistElementIds, state.NotAssignableTools, state.SuccessCallback, state.OnClose, state.IsLoading, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, state.WithNotification, msg.fields[0]), Cmd_none()];
        case 2: {
            const matchValue = state.SelectedDispolist;
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                const dispolist = matchValue;
                return [new State(state.RequestState, state.Receiver, state.Customer, state.Dispolists, state.SelectedDispolist, state.RemovedDispolistElementIds, state.NotAssignableTools, state.SuccessCallback, state.OnClose, true, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, state.WithNotification, state.Comment), state.WithNotification ? ((toolsToAdd = choose((element_1) => {
                    switch (element_1.tag) {
                        case 0:
                        case 2:
                            return undefined;
                        default: {
                            const value_3 = element_1.fields[0];
                            return new ToAssignTool(value_3.ToolId, undefined, value_3.StorageId);
                        }
                    }
                }, filter((element) => !contains(Helper_detailedElementId(element), state.RemovedDispolistElementIds, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: stringHash,
                }), dispolist.Elements)), (consumablesToAdd = choose((element_3) => {
                    switch (element_3.tag) {
                        case 1:
                        case 2:
                            return undefined;
                        default: {
                            const value_5 = element_3.fields[0];
                            return new ToAssignConsumable(value_5.ConsumableId, value_5.Quantity, "", value_5.StorageId);
                        }
                    }
                }, filter((element_2) => !contains(Helper_detailedElementId(element_2), state.RemovedDispolistElementIds, {
                    Equals: (x_1, y_1) => (x_1 === y_1),
                    GetHashCode: stringHash,
                }), dispolist.Elements)), (toolsWithQuantityToAdd = choose((element_5) => {
                    switch (element_5.tag) {
                        case 0:
                        case 1:
                            return undefined;
                        default: {
                            const value_7 = element_5.fields[0];
                            return new ToAssignToolWithQuantity(value_7.ToolId, value_7.Quantity, value_7.StorageId);
                        }
                    }
                }, filter((element_4) => !contains(Helper_detailedElementId(element_4), state.RemovedDispolistElementIds, {
                    Equals: (x_2, y_2) => (x_2 === y_2),
                    GetHashCode: stringHash,
                }), dispolist.Elements)), askAssignDispolistCmd((bind$0040 = state.AssignDispolistDto, new AssignDispolistDto(bind$0040.ReceiverId, bind$0040.SenderId, bind$0040.Type, state.Comment, bind$0040.WithSignature, unwrapDispolistId(dispolist.Id), toolsWithQuantityToAdd, toolsToAdd, consumablesToAdd))))))) : ((toolsToAdd_1 = choose((element_7) => {
                    switch (element_7.tag) {
                        case 0:
                        case 2:
                            return undefined;
                        default: {
                            const value_9 = element_7.fields[0];
                            return new DispolistAssignTool(value_9.ToolId, value_9.StorageId);
                        }
                    }
                }, filter((element_6) => !contains(Helper_detailedElementId(element_6), state.RemovedDispolistElementIds, {
                    Equals: (x_3, y_3) => (x_3 === y_3),
                    GetHashCode: stringHash,
                }), dispolist.Elements)), (consumablesToAdd_1 = choose((element_9) => {
                    switch (element_9.tag) {
                        case 1:
                        case 2:
                            return undefined;
                        default: {
                            const value_11 = element_9.fields[0];
                            return new DispolistAssignConsumable(value_11.ConsumableId, value_11.Quantity, value_11.StorageId);
                        }
                    }
                }, filter((element_8) => !contains(Helper_detailedElementId(element_8), state.RemovedDispolistElementIds, {
                    Equals: (x_4, y_4) => (x_4 === y_4),
                    GetHashCode: stringHash,
                }), dispolist.Elements)), (toolsWithQuantityToAdd_1 = choose((element_11) => {
                    switch (element_11.tag) {
                        case 0:
                        case 1:
                            return undefined;
                        default: {
                            const value_13 = element_11.fields[0];
                            return new DispolistAssignToolWithQuantity(value_13.ToolId, value_13.Quantity, value_13.StorageId);
                        }
                    }
                }, filter((element_10) => !contains(Helper_detailedElementId(element_10), state.RemovedDispolistElementIds, {
                    Equals: (x_5, y_5) => (x_5 === y_5),
                    GetHashCode: stringHash,
                }), dispolist.Elements)), assignDispolistWithoutNotificationCmd((bind$0040_1 = state.AssignDispolistWithoutNotification, new AssignDispolistWithoutNotification(bind$0040_1.ReceiverId, bind$0040_1.SenderId, bind$0040_1.Type, unwrapDispolistId(dispolist.Id), state.Comment, bind$0040_1.SignatureUrl, toolsWithQuantityToAdd_1, toolsToAdd_1, consumablesToAdd_1)))))))];
            }
        }
        case 3: {
            const matchValue_1 = msg.fields[0].Result;
            if (matchValue_1.tag === 1) {
                return [new State(state.RequestState, state.Receiver, state.Customer, state.Dispolists, state.SelectedDispolist, state.RemovedDispolistElementIds, new NotAssignableTools_2(matchValue_1.fields[0], matchValue_1.fields[2], matchValue_1.fields[1]), state.SuccessCallback, state.OnClose, false, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, state.WithNotification, state.Comment), Cmd_errorToast("dispolist_assign.dialog_assign_warning")];
            }
            else {
                return [new State(state.RequestState, state.Receiver, state.Customer, state.Dispolists, state.SelectedDispolist, state.RemovedDispolistElementIds, state.NotAssignableTools, state.SuccessCallback, state.OnClose, false, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, state.WithNotification, state.Comment), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("dispolist_assign.dialog_assign_success"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
        case 4: {
            const storageId = msg.fields[1];
            const id = msg.fields[0];
            const matchValue_2 = state.SelectedDispolist;
            if (matchValue_2 == null) {
                return [state, Cmd_none()];
            }
            else {
                const selectedDispolist = matchValue_2;
                return [new State(state.RequestState, state.Receiver, state.Customer, state.Dispolists, new DetailedDispolist(selectedDispolist.Id, selectedDispolist.Name, map((e) => {
                    switch (e.tag) {
                        case 1: {
                            const element_13 = e.fields[0];
                            if (element_13.Id === id) {
                                return new DetailedDispolistElements(1, [element_13]);
                            }
                            else {
                                return e;
                            }
                        }
                        case 0: {
                            const element_14 = e.fields[0];
                            if (element_14.Id === id) {
                                return new DetailedDispolistElements(0, [new DispolistDetailedAssignConsumable(element_14.ConsumableId, element_14.CheckNumber, element_14.Id, element_14.Name, element_14.AdditionalName, element_14.Quantity, storageId, element_14.Storages)]);
                            }
                            else {
                                return e;
                            }
                        }
                        default: {
                            const element_12 = e.fields[0];
                            if (element_12.Id === id) {
                                return new DetailedDispolistElements(2, [new DispolistDetailedAssignToolWithQuantity(element_12.ToolId, element_12.CheckNumber, element_12.Id, element_12.Manufacturer, element_12.Model, element_12.DisplayName, element_12.Quantity, storageId, element_12.Storages)]);
                            }
                            else {
                                return e;
                            }
                        }
                    }
                }, selectedDispolist.Elements), selectedDispolist.InvalidElements), state.RemovedDispolistElementIds, state.NotAssignableTools, state.SuccessCallback, state.OnClose, state.IsLoading, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, state.WithNotification, state.Comment), Cmd_none()];
            }
        }
        case 5: {
            const quantity = msg.fields[1];
            const intValue = msg.fields[2];
            const id_1 = msg.fields[0];
            const matchValue_3 = state.SelectedDispolist;
            if (matchValue_3 == null) {
                return [state, Cmd_none()];
            }
            else {
                const selectedDispolist_1 = matchValue_3;
                return [new State(state.RequestState, state.Receiver, state.Customer, state.Dispolists, new DetailedDispolist(selectedDispolist_1.Id, selectedDispolist_1.Name, map((e_1) => {
                    switch (e_1.tag) {
                        case 1: {
                            const element_16 = e_1.fields[0];
                            if (element_16.Id === id_1) {
                                return new DetailedDispolistElements(1, [element_16]);
                            }
                            else {
                                return e_1;
                            }
                        }
                        case 0: {
                            const element_17 = e_1.fields[0];
                            if (element_17.Id === id_1) {
                                if (quantity == null) {
                                    return e_1;
                                }
                                else {
                                    const quantity_1 = quantity;
                                    return new DetailedDispolistElements(0, [new DispolistDetailedAssignConsumable(element_17.ConsumableId, element_17.CheckNumber, element_17.Id, element_17.Name, element_17.AdditionalName, value_16((() => {
                                        try {
                                            const value_15 = parse(replace(quantity_1, ",", "."));
                                            return (value_15 > 0) ? value_15 : undefined;
                                        }
                                        catch (matchValue_4) {
                                            return undefined;
                                        }
                                    })()), element_17.StorageId, element_17.Storages)]);
                                }
                            }
                            else {
                                return e_1;
                            }
                        }
                        default: {
                            const element_15 = e_1.fields[0];
                            if (element_15.Id === id_1) {
                                if (intValue == null) {
                                    return e_1;
                                }
                                else {
                                    return new DetailedDispolistElements(2, [new DispolistDetailedAssignToolWithQuantity(element_15.ToolId, element_15.CheckNumber, element_15.Id, element_15.Manufacturer, element_15.Model, element_15.DisplayName, intValue, element_15.StorageId, element_15.Storages)]);
                                }
                            }
                            else {
                                return e_1;
                            }
                        }
                    }
                }, selectedDispolist_1.Elements), selectedDispolist_1.InvalidElements), state.RemovedDispolistElementIds, state.NotAssignableTools, state.SuccessCallback, state.OnClose, state.IsLoading, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, state.WithNotification, state.Comment), Cmd_none()];
            }
        }
        case 6:
            return [new State(state.RequestState, state.Receiver, state.Customer, state.Dispolists, state.SelectedDispolist, cons(msg.fields[0], state.RemovedDispolistElementIds), state.NotAssignableTools, state.SuccessCallback, state.OnClose, state.IsLoading, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, state.WithNotification, state.Comment), Cmd_none()];
        case 7:
            return [new State(state.RequestState, state.Receiver, state.Customer, state.Dispolists, state.SelectedDispolist, filter((e_2) => (e_2 !== msg.fields[0]), state.RemovedDispolistElementIds), state.NotAssignableTools, state.SuccessCallback, state.OnClose, state.IsLoading, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, state.WithNotification, state.Comment), Cmd_none()];
        case 8:
            return [new State(true, state.Receiver, state.Customer, state.Dispolists, state.SelectedDispolist, state.RemovedDispolistElementIds, undefined, state.SuccessCallback, state.OnClose, state.IsLoading, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, state.WithNotification, state.Comment), Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
                const pr = response_1.arrayBuffer();
                return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), DetailedDispolist_$reflection()));
            })))), `/api/dispolists/detailed-dispolist/${unwrapDispolistId_1(msg.fields[0])}`, (Item_6) => (new Msg(11, [Item_6])), (Item_7) => (new Msg(12, [Item_7])))];
        case 10:
            return [new State(state.RequestState, msg.fields[0], state.Customer, state.Dispolists, state.SelectedDispolist, state.RemovedDispolistElementIds, state.NotAssignableTools, state.SuccessCallback, state.OnClose, state.IsLoading, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, state.WithNotification, state.Comment), Cmd_none()];
        case 9:
            return [new State(state.RequestState, state.Receiver, state.Customer, msg.fields[0], state.SelectedDispolist, state.RemovedDispolistElementIds, state.NotAssignableTools, state.SuccessCallback, state.OnClose, state.IsLoading, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, state.WithNotification, state.Comment), Cmd_none()];
        case 11:
            return [new State(false, state.Receiver, state.Customer, state.Dispolists, msg.fields[0], empty(), state.NotAssignableTools, state.SuccessCallback, state.OnClose, state.IsLoading, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, state.WithNotification, state.Comment), Cmd_none()];
        case 12:
            return [new State(false, state.Receiver, state.Customer, state.Dispolists, state.SelectedDispolist, state.RemovedDispolistElementIds, state.NotAssignableTools, state.SuccessCallback, state.OnClose, state.IsLoading, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, state.WithNotification, state.Comment), Cmd_none()];
        default:
            return [new State(state.RequestState, state.Receiver, state.Customer, state.Dispolists, state.SelectedDispolist, state.RemovedDispolistElementIds, state.NotAssignableTools, state.SuccessCallback, state.OnClose, state.IsLoading, state.AssignDispolistDto, state.AssignDispolistWithoutNotification, msg.fields[0], state.Comment), Cmd_none()];
    }
}

