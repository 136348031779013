import * as react from "@sentry/react";

export function initializeSentry(userData) {
    const initData = {
        dsn: "https://923a16423eb6d7c5f6515c835d0246e5@o4507814370541568.ingest.de.sentry.io/4507819750326352",
    };
    react.init(initData);
    if (userData == null) {
    }
    else {
        const userData_1 = userData;
        const userData_2 = {
            email: userData_1.UserName,
            id: userData_1.Customer,
        };
        react.setUser(userData_2);
    }
}

export function captureException(error) {
    react.captureException(error);
}

