import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { DocumentId_$reflection, ConsumableId_$reflection, StorageId_$reflection, EmptyResponse_$reflection, PostResponse$1_$reflection, ImageDeletedResponse_$reflection, DocumentDetail_$reflection, Customer_$reflection, TokenDataDto_$reflection, RequestedValue$1_$reflection, ReminderId_$reflection, ConsumableReservationId_$reflection } from "../../Shared/Shared.js";
import { anonRecord_type, bool_type, list_type, record_type, float64_type, option_type, class_type, string_type, union_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ConsumableHistoryDto_$reflection, ConsumableDetailDto_$reflection } from "../../Shared/Consumable.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { UserConfiguration_$reflection } from "../../Shared/User.js";
import { OverviewNotification_$reflection } from "../../Shared/Notification.js";
import { DeleteQRCodeResult_$reflection, AssignQRCodeResult_$reflection } from "../../Shared/Tool.js";

export class PlanningTypeId extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ReservationId", "ReminderId"];
    }
}

export function PlanningTypeId_$reflection() {
    return union_type("Consumables.Detail.Types.PlanningTypeId", [], PlanningTypeId, () => [[["Item", ConsumableReservationId_$reflection()]], [["Item", ReminderId_$reflection()]]]);
}

export class Planning extends Record {
    constructor(Type, StartDate, EndDate, ReservationName, ReceiverName, Description, Quantity, Storage, PlanningTypeId) {
        super();
        this.Type = Type;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.ReservationName = ReservationName;
        this.ReceiverName = ReceiverName;
        this.Description = Description;
        this.Quantity = Quantity;
        this.Storage = Storage;
        this.PlanningTypeId = PlanningTypeId;
    }
}

export function Planning_$reflection() {
    return record_type("Consumables.Detail.Types.Planning", [], Planning, () => [["Type", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", option_type(class_type("System.DateTime"))], ["ReservationName", string_type], ["ReceiverName", string_type], ["Description", string_type], ["Quantity", option_type(float64_type)], ["Storage", string_type], ["PlanningTypeId", PlanningTypeId_$reflection()]]);
}

export class State extends Record {
    constructor(Consumables, UserData, Storages, History, ImageViewerOpen, Customer, QuickOverviewDialog, UserConfiguration, Documents, Notifications) {
        super();
        this.Consumables = Consumables;
        this.UserData = UserData;
        this.Storages = Storages;
        this.History = History;
        this.ImageViewerOpen = ImageViewerOpen;
        this.Customer = Customer;
        this.QuickOverviewDialog = QuickOverviewDialog;
        this.UserConfiguration = UserConfiguration;
        this.Documents = Documents;
        this.Notifications = Notifications;
    }
}

export function State_$reflection() {
    return record_type("Consumables.Detail.Types.State", [], State, () => [["Consumables", RequestedValue$1_$reflection(ConsumableDetailDto_$reflection())], ["UserData", TokenDataDto_$reflection()], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["History", RequestedValue$1_$reflection(list_type(ConsumableHistoryDto_$reflection()))], ["ImageViewerOpen", bool_type], ["Customer", Customer_$reflection()], ["QuickOverviewDialog", anonRecord_type(["IsLoading", bool_type], ["IsOpen", bool_type])], ["UserConfiguration", RequestedValue$1_$reflection(UserConfiguration_$reflection())], ["Documents", RequestedValue$1_$reflection(list_type(DocumentDetail_$reflection()))], ["Notifications", RequestedValue$1_$reflection(list_type(OverviewNotification_$reflection()))]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateQuickOverviewItems", "SetIsQuickOverviewDialogOpen", "OpenImageViewer", "CloseImageViewer", "FetchConsumable", "ConsumableFetched", "ConsumableHistoryFetched", "UploadImage", "DeleteImage", "ImageDeleted", "ImageSaved", "AssignQrCode", "QrCodeAssigned", "DeleteQRCodes", "QRCodeDeleteCompleted", "DownloadDocument", "DocumentFetched", "BlobReceived", "DocumentsFetched", "FetchDocuments", "DownloadFile", "DocumentDownloaded", "UserConfigurationUpdated", "UserConfigurationFetched", "StoragesFetched", "FetchNotifications", "NotificationsFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Consumables.Detail.Types.Msg", [], Msg, () => [[["Item", list_type(string_type)]], [], [], [], [], [["Item", ConsumableDetailDto_$reflection()]], [["Item", list_type(ConsumableHistoryDto_$reflection())]], [["Item", class_type("Browser.Types.File", undefined)]], [], [["Item", PostResponse$1_$reflection(ImageDeletedResponse_$reflection())]], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [["Item1", string_type], ["Item2", StorageId_$reflection()]], [["Item", PostResponse$1_$reflection(AssignQRCodeResult_$reflection())]], [["Item1", string_type], ["Item2", StorageId_$reflection()]], [["Item", PostResponse$1_$reflection(DeleteQRCodeResult_$reflection())]], [["Item1", ConsumableId_$reflection()], ["Item2", DocumentId_$reflection()], ["Item3", string_type]], [["Item1", class_type("Fetch.Types.Response")], ["Item2", string_type]], [["Item1", class_type("Browser.Types.Blob", undefined)], ["Item2", string_type]], [["Item", list_type(DocumentDetail_$reflection())]], [], [["Item1", DocumentId_$reflection()], ["fileName", string_type]], [["Item1", class_type("Fetch.Types.Response")], ["Item2", string_type]], [["Item", EmptyResponse_$reflection()]], [["Item", UserConfiguration_$reflection()]], [["Item", list_type(Storage_$reflection())]], [], [["Item", list_type(OverviewNotification_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

