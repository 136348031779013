import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { int32_type, option_type, class_type, union_type, list_type, record_type, array_type, lambda_type, unit_type, bool_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { parse } from "../fable_modules/fable-library-js.4.19.2/Int32.js";
import { map, ofNullable, defaultArg } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { toText, printf, toConsole, split } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { item } from "../fable_modules/fable-library-js.4.19.2/Array.js";
import { uncurry2, curry2, defaultOf } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { tryPick, pick } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { createElement } from "react";
import React from "react";

export class SelectRow$1 extends Record {
    constructor(mode, clickToSelect, onSelect, selected, onSelectAll) {
        super();
        this.mode = mode;
        this.clickToSelect = clickToSelect;
        this.onSelect = onSelect;
        this.selected = selected;
        this.onSelectAll = onSelectAll;
    }
}

export function SelectRow$1_$reflection(gen0) {
    return record_type("SharedComponents.ReactTable.SelectRow`1", [gen0], SelectRow$1, () => [["mode", string_type], ["clickToSelect", bool_type], ["onSelect", lambda_type(gen0, lambda_type(bool_type, unit_type))], ["selected", array_type(string_type)], ["onSelectAll", lambda_type(bool_type, lambda_type(array_type(gen0), unit_type))]]);
}

export function readIntParam(param) {
    if (param === "") {
        return 1;
    }
    else {
        return parse(param, 511, false, 32) | 0;
    }
}

export function parseParam(p) {
    return defaultArg(ofNullable(p), "");
}

export function getUrlSearchParams(_arg) {
    let arg_1;
    let patternInput;
    if (split(window.location.hash, ["?"], undefined, 0).length === 2) {
        const arg = item(1, split(window.location.hash, ["?"], undefined, 0));
        toConsole(printf("%s"))(arg);
        patternInput = [item(0, split(window.location.hash, ["?"], undefined, 0)), (arg_1 = item(1, split(window.location.hash, ["?"], undefined, 0)), toText(printf("?%s"))(arg_1))];
    }
    else {
        patternInput = [window.location.hash, ""];
    }
    return [patternInput[0], new URLSearchParams(patternInput[1])];
}

export function writeQueryParams(updateParams, readParams, writeParams) {
    const patternInput = getUrlSearchParams(undefined);
    const urlSearchParams = patternInput[1];
    const newUrlSearchParams = writeParams(updateParams(readParams(urlSearchParams)), urlSearchParams);
    let queryParam;
    const arg_1 = newUrlSearchParams.toString();
    queryParam = toText(printf("%s?%s"))(patternInput[0])(arg_1);
    (history).replaceState(defaultOf(), defaultOf(), queryParam);
}

export class ColumnFilter$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoFilter", "TextFilter", "CustomTextFilter", "SelectFilter", "SelectFilterMultiOptions", "MultiSelectFilter"];
    }
}

export function ColumnFilter$1_$reflection(gen0) {
    return union_type("SharedComponents.ReactTable.ColumnFilter`1", [gen0], ColumnFilter$1, () => [[], [], [["Item", lambda_type(gen0, string_type)]], [["Item1", list_type(string_type)], ["Item2", lambda_type(gen0, string_type)]], [["Item1", list_type(string_type)], ["Item2", lambda_type(gen0, list_type(string_type))]], [["Item1", list_type(string_type)], ["Item2", lambda_type(gen0, list_type(string_type))]]]);
}

export class SortFunction$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoSort", "NaturalSort", "CustomNaturalSort", "DateSort"];
    }
}

export function SortFunction$1_$reflection(gen0) {
    return union_type("SharedComponents.ReactTable.SortFunction`1", [gen0], SortFunction$1, () => [[], [], [["Item", lambda_type(gen0, string_type)]], [["Item", lambda_type(gen0, class_type("System.DateTime"))]]]);
}

export class Formatter$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Text", "Translate", "Date", "DateTime", "Custom"];
    }
}

export function Formatter$1_$reflection(gen0) {
    return union_type("SharedComponents.ReactTable.Formatter`1", [gen0], Formatter$1, () => [[], [], [["Item", lambda_type(gen0, class_type("System.DateTime"))]], [["Item", lambda_type(gen0, class_type("System.DateTime"))]], [["Item", lambda_type(string_type, lambda_type(gen0, class_type("Fable.React.ReactElement")))]]]);
}

export class TableColumn$1 extends Record {
    constructor(Selector, Label, SortFunction, Filter, FilterValue, UpdateParams, Formatter, CSVExport, CSVFormatter, Classes, FilterRenderer, Clickable, Hidden) {
        super();
        this.Selector = Selector;
        this.Label = Label;
        this.SortFunction = SortFunction;
        this.Filter = Filter;
        this.FilterValue = FilterValue;
        this.UpdateParams = UpdateParams;
        this.Formatter = Formatter;
        this.CSVExport = CSVExport;
        this.CSVFormatter = CSVFormatter;
        this.Classes = Classes;
        this.FilterRenderer = FilterRenderer;
        this.Clickable = Clickable;
        this.Hidden = Hidden;
    }
}

export function TableColumn$1_$reflection(gen0) {
    return record_type("SharedComponents.ReactTable.TableColumn`1", [gen0], TableColumn$1, () => [["Selector", string_type], ["Label", string_type], ["SortFunction", SortFunction$1_$reflection(gen0)], ["Filter", ColumnFilter$1_$reflection(gen0)], ["FilterValue", string_type], ["UpdateParams", option_type(lambda_type(string_type, unit_type))], ["Formatter", Formatter$1_$reflection(gen0)], ["CSVExport", bool_type], ["CSVFormatter", option_type(lambda_type(string_type, lambda_type(gen0, class_type("Fable.React.ReactElement"))))], ["Classes", string_type], ["FilterRenderer", option_type(lambda_type(lambda_type(string_type, unit_type), lambda_type(gen0, class_type("Fable.React.ReactElement"))))], ["Clickable", bool_type], ["Hidden", bool_type]]);
}

export class ColProp$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Selector", "Label", "SortFunction", "Filter", "FilterValue", "Formatter", "CSVExport", "CSVFormatter", "Classes", "UpdateParams", "FilterRenderer", "Clickable", "Hidden"];
    }
}

export function ColProp$1_$reflection(gen0) {
    return union_type("SharedComponents.ReactTable.ColProp`1", [gen0], ColProp$1, () => [[["Item", string_type]], [["Item", string_type]], [["Item", SortFunction$1_$reflection(gen0)]], [["Item", ColumnFilter$1_$reflection(gen0)]], [["Item", string_type]], [["Item", Formatter$1_$reflection(gen0)]], [["Item", bool_type]], [["Item", lambda_type(string_type, lambda_type(gen0, class_type("Fable.React.ReactElement")))]], [["Item", string_type]], [["Item", lambda_type(string_type, unit_type)]], [["Item", lambda_type(lambda_type(string_type, unit_type), lambda_type(gen0, class_type("Fable.React.ReactElement")))]], [["Item", bool_type]], [["Item", bool_type]]]);
}

export class TableProp$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["KeyField", "Columns", "Rows", "DefaultSortField", "TableKey", "Page", "UpdatePage", "Pagination", "ColumnToggle", "OnRowClick", "Hover", "SelectRow"];
    }
}

export function TableProp$1_$reflection(gen0) {
    return union_type("SharedComponents.ReactTable.TableProp`1", [gen0], TableProp$1, () => [[["Item", string_type]], [["Item", list_type(TableColumn$1_$reflection(gen0))]], [["Item", list_type(gen0)]], [["Item1", string_type], ["Item2", string_type]], [["Item", string_type]], [["Item", int32_type]], [["Item", lambda_type(int32_type, unit_type)]], [["Item", bool_type]], [["Item", bool_type]], [["Item", lambda_type(gen0, unit_type)]], [["Item", bool_type]], [["Item", SelectRow$1_$reflection(gen0)]]]);
}

export class tableProp {
    constructor() {
    }
}

export function tableProp_$reflection() {
    return class_type("SharedComponents.ReactTable.tableProp", undefined, tableProp);
}

export function tableProp_$ctor() {
    return new tableProp();
}

export function tableProp_columns_ZCDDEA8C(cols) {
    return new TableProp$1(1, [cols]);
}

export function tableProp_keyField_Z721C83C5(keyField) {
    return new TableProp$1(0, [keyField]);
}

export function tableProp_rows_5CDFDA7(rows) {
    return new TableProp$1(2, [rows]);
}

export function tableProp_defaultSortField_Z384F8060(field, direction) {
    return new TableProp$1(3, [field, direction]);
}

export function tableProp_tableKey_Z721C83C5(tableKey) {
    return new TableProp$1(4, [tableKey]);
}

export function tableProp_page_Z524259A4(p) {
    return new TableProp$1(5, [p]);
}

export function tableProp_updatePage_Z3B7584A(p) {
    return new TableProp$1(6, [p]);
}

export function tableProp_pagination_Z1FBCCD16(p) {
    return new TableProp$1(7, [p]);
}

export function tableProp_columnToggle_Z1FBCCD16(t) {
    return new TableProp$1(8, [t]);
}

export function tableProp_onRowClick_Z7D5D6F51(f) {
    return new TableProp$1(9, [f]);
}

export function tableProp_hover_Z1FBCCD16(b) {
    return new TableProp$1(10, [b]);
}

export function tableProp_selectRow_72D3E07C(sr) {
    return new TableProp$1(11, [sr]);
}

export class colProp {
    constructor() {
    }
}

export function colProp_$reflection() {
    return class_type("SharedComponents.ReactTable.colProp", undefined, colProp);
}

export function colProp_$ctor() {
    return new colProp();
}

export function colProp_selector_Z721C83C5(s) {
    return new ColProp$1(0, [s]);
}

export function colProp_label_Z721C83C5(l) {
    return new ColProp$1(1, [l]);
}

export function colProp_sortFunction_Z3EEDBD7A(s) {
    return new ColProp$1(2, [s]);
}

export function colProp_filter_770189E8(f) {
    return new ColProp$1(3, [f]);
}

export function colProp_filterValue_Z721C83C5(f) {
    return new ColProp$1(4, [f]);
}

export function colProp_formatter_Z11BEB8A2(form) {
    return new ColProp$1(5, [form]);
}

export function colProp_csvPrint_Z1FBCCD16(print) {
    return new ColProp$1(6, [print]);
}

export function colProp_csvFormatter_Z2DAA2C97(formatter) {
    return new ColProp$1(7, [formatter]);
}

export function colProp_classes_Z721C83C5(c) {
    return new ColProp$1(8, [c]);
}

export function colProp_updateParams_41EFD311(p) {
    return new ColProp$1(9, [p]);
}

export function colProp_filterRenderer_5132E743(r) {
    return new ColProp$1(10, [r]);
}

export function colProp_clickable_Z1FBCCD16(b) {
    return new ColProp$1(11, [b]);
}

export function colProp_hidden_Z1FBCCD16(b) {
    return new ColProp$1(12, [b]);
}

export function tableColumn(props) {
    const selector = pick((_arg) => {
        if (_arg.tag === 0) {
            return _arg.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const label = pick((_arg_1) => {
        if (_arg_1.tag === 1) {
            return _arg_1.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const sortFunction = defaultArg(tryPick((_arg_2) => {
        if (_arg_2.tag === 2) {
            return _arg_2.fields[0];
        }
        else {
            return undefined;
        }
    }, props), new SortFunction$1(0, []));
    const filter = defaultArg(tryPick((_arg_3) => {
        if (_arg_3.tag === 3) {
            return _arg_3.fields[0];
        }
        else {
            return undefined;
        }
    }, props), new ColumnFilter$1(0, []));
    const filterValue = defaultArg(tryPick((_arg_4) => {
        if (_arg_4.tag === 4) {
            return _arg_4.fields[0];
        }
        else {
            return undefined;
        }
    }, props), "");
    const formatter = defaultArg(tryPick((_arg_5) => {
        if (_arg_5.tag === 5) {
            return _arg_5.fields[0];
        }
        else {
            return undefined;
        }
    }, props), new Formatter$1(0, []));
    const csvExport = defaultArg(tryPick((_arg_6) => {
        if (_arg_6.tag === 6) {
            return _arg_6.fields[0];
        }
        else {
            return undefined;
        }
    }, props), true);
    const csvFormatter = tryPick((_arg_7) => {
        if (_arg_7.tag === 7) {
            return curry2(_arg_7.fields[0]);
        }
        else {
            return undefined;
        }
    }, props);
    const classes = defaultArg(tryPick((_arg_8) => {
        if (_arg_8.tag === 8) {
            return _arg_8.fields[0];
        }
        else {
            return undefined;
        }
    }, props), "");
    return new TableColumn$1(selector, label, sortFunction, filter, filterValue, tryPick((_arg_9) => {
        if (_arg_9.tag === 9) {
            return _arg_9.fields[0];
        }
        else {
            return undefined;
        }
    }, props), formatter, csvExport, map(uncurry2, csvFormatter), classes, map(uncurry2, tryPick((_arg_10) => {
        if (_arg_10.tag === 10) {
            return curry2(_arg_10.fields[0]);
        }
        else {
            return undefined;
        }
    }, props)), defaultArg(tryPick((_arg_11) => {
        if (_arg_11.tag === 11) {
            return _arg_11.fields[0];
        }
        else {
            return undefined;
        }
    }, props), true), defaultArg(tryPick((_arg_12) => {
        if (_arg_12.tag === 12) {
            return _arg_12.fields[0];
        }
        else {
            return undefined;
        }
    }, props), false));
}

export function ReactTable(reactTableInputProps) {
    const props = reactTableInputProps.props;
    return createElement("div", {
        children: ["TODO"],
    });
}

