import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, int32_type, bool_type, record_type, list_type, option_type, class_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { DispolistSnapshotId_$reflection, ConsumableId_$reflection, ToolId_$reflection, ToolHistoryElementType_$reflection } from "./Shared.js";
import { CustomPropertyValueDto_$reflection } from "./PropertyConfiguration.js";
import { ReceiverTypeName_$reflection, User_$reflection, Receiver_$reflection } from "./User.js";

export class ReceiverHistoryRow extends Record {
    constructor(ToolId, EventDate, EventType, InventoryName, Quantity, Receiver, Comment$, Sender, Storage, CheckNumber, InventoryNumber, ToolHistoryElementType, SignatureUrl, CommissionNumber, CustomProperties) {
        super();
        this.ToolId = ToolId;
        this.EventDate = EventDate;
        this.EventType = EventType;
        this.InventoryName = InventoryName;
        this.Quantity = Quantity;
        this.Receiver = Receiver;
        this.Comment = Comment$;
        this.Sender = Sender;
        this.Storage = Storage;
        this.CheckNumber = CheckNumber;
        this.InventoryNumber = InventoryNumber;
        this.ToolHistoryElementType = ToolHistoryElementType;
        this.SignatureUrl = SignatureUrl;
        this.CommissionNumber = CommissionNumber;
        this.CustomProperties = CustomProperties;
    }
}

export function ReceiverHistoryRow_$reflection() {
    return record_type("Shared.Receiver.ReceiverHistoryRow", [], ReceiverHistoryRow, () => [["ToolId", string_type], ["EventDate", class_type("System.DateTime")], ["EventType", string_type], ["InventoryName", string_type], ["Quantity", string_type], ["Receiver", string_type], ["Comment", string_type], ["Sender", string_type], ["Storage", string_type], ["CheckNumber", option_type(string_type)], ["InventoryNumber", string_type], ["ToolHistoryElementType", ToolHistoryElementType_$reflection()], ["SignatureUrl", option_type(string_type)], ["CommissionNumber", string_type], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class ToolBoxTool extends Record {
    constructor(ToolId, IsTool, IsSet, Manufacturer, Model, SerialNumber, InventoryNumber, DisplayName, Quantity, QuantityAsString, PriceAsString, Comment$, CheckNumber, QRCodeIds, StorageId, RentDate, ReturnDate) {
        super();
        this.ToolId = ToolId;
        this.IsTool = IsTool;
        this.IsSet = IsSet;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.SerialNumber = SerialNumber;
        this.InventoryNumber = InventoryNumber;
        this.DisplayName = DisplayName;
        this.Quantity = (Quantity | 0);
        this.QuantityAsString = QuantityAsString;
        this.PriceAsString = PriceAsString;
        this.Comment = Comment$;
        this.CheckNumber = CheckNumber;
        this.QRCodeIds = QRCodeIds;
        this.StorageId = StorageId;
        this.RentDate = RentDate;
        this.ReturnDate = ReturnDate;
    }
}

export function ToolBoxTool_$reflection() {
    return record_type("Shared.Receiver.ToolBoxTool", [], ToolBoxTool, () => [["ToolId", string_type], ["IsTool", bool_type], ["IsSet", bool_type], ["Manufacturer", string_type], ["Model", string_type], ["SerialNumber", string_type], ["InventoryNumber", string_type], ["DisplayName", string_type], ["Quantity", int32_type], ["QuantityAsString", string_type], ["PriceAsString", string_type], ["Comment", string_type], ["CheckNumber", option_type(string_type)], ["QRCodeIds", string_type], ["StorageId", string_type], ["RentDate", class_type("System.DateTime")], ["ReturnDate", option_type(class_type("System.DateTime"))]]);
}

export function StoragePermissionType__ToI18nString(this$) {
    if (this$ === "toolManager") {
        return "settings.storages.tool_manager";
    }
    else {
        return "settings.storages.self_service";
    }
}

export class StoragePermissions extends Record {
    constructor(Name, PermissionType) {
        super();
        this.Name = Name;
        this.PermissionType = PermissionType;
    }
}

export function StoragePermissions_$reflection() {
    return record_type("Shared.Receiver.StoragePermissions", [], StoragePermissions, () => [["Name", string_type], ["PermissionType", string_type]]);
}

export class Permissions extends Record {
    constructor(Storages) {
        super();
        this.Storages = Storages;
    }
}

export function Permissions_$reflection() {
    return record_type("Shared.Receiver.Permissions", [], Permissions, () => [["Storages", list_type(StoragePermissions_$reflection())]]);
}

export class ConsumableStock extends Record {
    constructor(Id, Unit, ConsumableName, AdditionalName, InventoryNumber, Quantity, CustomProperties) {
        super();
        this.Id = Id;
        this.Unit = Unit;
        this.ConsumableName = ConsumableName;
        this.AdditionalName = AdditionalName;
        this.InventoryNumber = InventoryNumber;
        this.Quantity = Quantity;
        this.CustomProperties = CustomProperties;
    }
}

export function ConsumableStock_$reflection() {
    return record_type("Shared.Receiver.ConsumableStock", [], ConsumableStock, () => [["Id", string_type], ["Unit", string_type], ["ConsumableName", string_type], ["AdditionalName", string_type], ["InventoryNumber", string_type], ["Quantity", string_type], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class ToolBox extends Record {
    constructor(Receiver, Tools, ToolsWithQuantity, ReceiverHistory, Permissions, ResponsibleUsers, VisibleUsers, ConsumableStock) {
        super();
        this.Receiver = Receiver;
        this.Tools = Tools;
        this.ToolsWithQuantity = ToolsWithQuantity;
        this.ReceiverHistory = ReceiverHistory;
        this.Permissions = Permissions;
        this.ResponsibleUsers = ResponsibleUsers;
        this.VisibleUsers = VisibleUsers;
        this.ConsumableStock = ConsumableStock;
    }
}

export function ToolBox_$reflection() {
    return record_type("Shared.Receiver.ToolBox", [], ToolBox, () => [["Receiver", Receiver_$reflection()], ["Tools", list_type(ToolBoxTool_$reflection())], ["ToolsWithQuantity", list_type(ToolBoxTool_$reflection())], ["ReceiverHistory", list_type(ReceiverHistoryRow_$reflection())], ["Permissions", Permissions_$reflection()], ["ResponsibleUsers", list_type(User_$reflection())], ["VisibleUsers", list_type(User_$reflection())], ["ConsumableStock", list_type(ConsumableStock_$reflection())]]);
}

export class ToolBoxOverviewDto extends Record {
    constructor(DisplayName, PersonnelNumber, ReceiverType, UserId, ToolQuantity, ToolWithQuantityQuantity, Role, LocationName, IsArchived) {
        super();
        this.DisplayName = DisplayName;
        this.PersonnelNumber = PersonnelNumber;
        this.ReceiverType = ReceiverType;
        this.UserId = UserId;
        this.ToolQuantity = ToolQuantity;
        this.ToolWithQuantityQuantity = ToolWithQuantityQuantity;
        this.Role = Role;
        this.LocationName = LocationName;
        this.IsArchived = IsArchived;
    }
}

export function ToolBoxOverviewDto_$reflection() {
    return record_type("Shared.Receiver.ToolBoxOverviewDto", [], ToolBoxOverviewDto, () => [["DisplayName", string_type], ["PersonnelNumber", string_type], ["ReceiverType", ReceiverTypeName_$reflection()], ["UserId", string_type], ["ToolQuantity", string_type], ["ToolWithQuantityQuantity", string_type], ["Role", option_type(string_type)], ["LocationName", option_type(string_type)], ["IsArchived", bool_type]]);
}

export class ToolboxDispolistElementIdType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ToolElementId", "ToolWithQuantityElementId", "ConsumableElementId"];
    }
}

export function ToolboxDispolistElementIdType_$reflection() {
    return union_type("Shared.Receiver.ToolboxDispolistElementIdType", [], ToolboxDispolistElementIdType, () => [[["Item", ToolId_$reflection()]], [["Item", ToolId_$reflection()]], [["Item", ConsumableId_$reflection()]]]);
}

export class ToolboxDispolistElementRow extends Record {
    constructor(Id, ElementType, Name, Quantity, Storage) {
        super();
        this.Id = Id;
        this.ElementType = ElementType;
        this.Name = Name;
        this.Quantity = Quantity;
        this.Storage = Storage;
    }
}

export function ToolboxDispolistElementRow_$reflection() {
    return record_type("Shared.Receiver.ToolboxDispolistElementRow", [], ToolboxDispolistElementRow, () => [["Id", ToolboxDispolistElementIdType_$reflection()], ["ElementType", string_type], ["Name", string_type], ["Quantity", string_type], ["Storage", string_type]]);
}

export class ToolBoxDispolist extends Record {
    constructor(User, SenderId, DispolistSnapshotId, Sender, Timestamp, DispolistName, Rows) {
        super();
        this.User = User;
        this.SenderId = SenderId;
        this.DispolistSnapshotId = DispolistSnapshotId;
        this.Sender = Sender;
        this.Timestamp = Timestamp;
        this.DispolistName = DispolistName;
        this.Rows = Rows;
    }
}

export function ToolBoxDispolist_$reflection() {
    return record_type("Shared.Receiver.ToolBoxDispolist", [], ToolBoxDispolist, () => [["User", Receiver_$reflection()], ["SenderId", string_type], ["DispolistSnapshotId", DispolistSnapshotId_$reflection()], ["Sender", string_type], ["Timestamp", class_type("System.DateTime")], ["DispolistName", string_type], ["Rows", list_type(ToolboxDispolistElementRow_$reflection())]]);
}

export class ToReturnTool extends Record {
    constructor(ToolId) {
        super();
        this.ToolId = ToolId;
    }
}

export function ToReturnTool_$reflection() {
    return record_type("Shared.Receiver.ToReturnTool", [], ToReturnTool, () => [["ToolId", string_type]]);
}

export class ToReturnToolWithQuantity extends Record {
    constructor(ToolId, Quantity, StorageId) {
        super();
        this.ToolId = ToolId;
        this.Quantity = (Quantity | 0);
        this.StorageId = StorageId;
    }
}

export function ToReturnToolWithQuantity_$reflection() {
    return record_type("Shared.Receiver.ToReturnToolWithQuantity", [], ToReturnToolWithQuantity, () => [["ToolId", string_type], ["Quantity", int32_type], ["StorageId", string_type]]);
}

export class ToolboxReturnToStorageDto extends Record {
    constructor(ReceiverId, SenderId, ToReturnTools, ToReturnToolsWithQuantity, Comment$) {
        super();
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.ToReturnTools = ToReturnTools;
        this.ToReturnToolsWithQuantity = ToReturnToolsWithQuantity;
        this.Comment = Comment$;
    }
}

export function ToolboxReturnToStorageDto_$reflection() {
    return record_type("Shared.Receiver.ToolboxReturnToStorageDto", [], ToolboxReturnToStorageDto, () => [["ReceiverId", string_type], ["SenderId", string_type], ["ToReturnTools", list_type(ToReturnTool_$reflection())], ["ToReturnToolsWithQuantity", list_type(ToReturnToolWithQuantity_$reflection())], ["Comment", option_type(string_type)]]);
}

export class ToolboxReturnToStorageResult extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Successful"];
    }
}

export function ToolboxReturnToStorageResult_$reflection() {
    return union_type("Shared.Receiver.ToolboxReturnToStorageResult", [], ToolboxReturnToStorageResult, () => [[]]);
}

