import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { Storage_$reflection } from "../../../Shared/Storage.js";
import { record_type, anonRecord_type, list_type, lambda_type, unit_type, union_type, class_type, string_type, option_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, ToolId, FormState__startLoading, FormState__setValidation, FormState_get_empty, UpdateToolStorageDto, StorageId, FormState_$reflection, TokenDataDto_$reflection, UpdateToolStorageDto_$reflection, ClientApiResponse$1_$reflection } from "../../../Shared/Shared.js";
import { ToolDetailDto_$reflection } from "../../../Shared/Tool.js";
import { singleton, tryFind, ofArray, filter, map } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { unwrapStorageId } from "../../../Shared/Helper.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../../Components/Toast.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_updateToolStorage } from "../../../Requests/Tool.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { equals, createObj } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { Select } from "../../../Components/Select.js";
import { unwrap } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Input } from "../../../Components/Input.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../../Components/Dialog.js";
import { TextButton } from "../../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateStorage", "SetSelectedTargetStorage", "SetStorageSpace", "StorageUpdated", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Tools.SwitchStorageDialog.Msg", [], Msg, () => [[], [["Item", option_type(Storage_$reflection())]], [["Item", string_type]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Tool, Dto, OnClose, SuccessCallback, UserData, Storages, TargetStorageOptions, SelectedTargetStorage, FormState) {
        super();
        this.Tool = Tool;
        this.Dto = Dto;
        this.OnClose = OnClose;
        this.SuccessCallback = SuccessCallback;
        this.UserData = UserData;
        this.Storages = Storages;
        this.TargetStorageOptions = TargetStorageOptions;
        this.SelectedTargetStorage = SelectedTargetStorage;
        this.FormState = FormState;
    }
}

function State_$reflection() {
    return record_type("Tools.SwitchStorageDialog.State", [], State, () => [["Tool", ToolDetailDto_$reflection()], ["Dto", UpdateToolStorageDto_$reflection()], ["OnClose", lambda_type(unit_type, unit_type)], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["UserData", TokenDataDto_$reflection()], ["Storages", list_type(Storage_$reflection())], ["TargetStorageOptions", list_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))], ["SelectedTargetStorage", option_type(Storage_$reflection())], ["FormState", FormState_$reflection()]]);
}

function init(tool, userData, successCallback, isOpen, onClose, storages) {
    return [new State(tool, new UpdateToolStorageDto(new StorageId("00000000-0000-0000-0000-000000000000"), new StorageId("00000000-0000-0000-0000-000000000000"), tool.StorageInformation.StorageSpace), onClose, successCallback, userData, storages, map((storage_1) => ({
        label: storage_1.Name,
        value: storage_1,
    }), filter((storage) => (tool.StorageInformation.StorageId !== unwrapStorageId(storage.Id)), storages)), undefined, FormState_get_empty()), Cmd_none()];
}

function update(msg, state) {
    let bind$0040;
    switch (msg.tag) {
        case 2:
            return [new State(state.Tool, (bind$0040 = state.Dto, new UpdateToolStorageDto(bind$0040.OldStorageId, bind$0040.NewStorageId, msg.fields[0])), state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, state.SelectedTargetStorage, state.FormState), Cmd_none()];
        case 0: {
            const matchValue = state.SelectedTargetStorage;
            if (matchValue == null) {
                return [new State(state.Tool, state.Dto, state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, state.SelectedTargetStorage, FormState__setValidation(state.FormState, "target-storage", "validation.select.storage")), Cmd_errorToast("validation.toast")];
            }
            else {
                const storage_1 = matchValue;
                const dto = new UpdateToolStorageDto(new StorageId(parse(state.Tool.StorageInformation.StorageId)), storage_1.Id, state.Dto.StorageSpace);
                return [new State(state.Tool, state.Dto, state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, state.SelectedTargetStorage, FormState__startLoading(state.FormState)), Cmd_updateToolStorage(new ToolId(parse(state.Tool.Id)), dto, (Item_2) => (new Msg(3, [Item_2])), (Item_3) => (new Msg(4, [Item_3])))];
            }
        }
        case 3: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.Tool, state.Dto, state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, state.SelectedTargetStorage, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.Tool, state.Dto, state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, state.SelectedTargetStorage, FormState_get_empty()), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 4:
            return [state, Cmd_none()];
        default:
            return [new State(state.Tool, state.Dto, state.OnClose, state.SuccessCallback, state.UserData, state.Storages, state.TargetStorageOptions, msg.fields[0], state.FormState), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems, Label, Value, matchValue, targetStorage;
    const t = useTranslation()[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = [createElement(Select, (Label = t("tool.switch_storage_target"), (Value = ((matchValue = props.SelectedTargetStorage, (matchValue == null) ? undefined : ((targetStorage = matchValue, tryFind((option_2) => equals(option_2.value, targetStorage), props.TargetStorageOptions))))), {
        ComponentState: componentState,
        IsClearable: false,
        Label: unwrap(Label),
        NoOptionsMessage: "",
        OnChange: (option) => {
            if (option == null) {
            }
            else {
                props.Dispatch(new Msg(1, [option.value]));
            }
        },
        Options: props.TargetStorageOptions,
        PlaceholderKey: "",
        TestId: "switch-storage-dialog-storage-target-test-id",
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "target-storage")),
        Value: unwrap(Value),
    }))), createElement(Input, {
        ComponentState: componentState,
        Label: t("tool.storage_space"),
        OnChange: (arg) => {
            props.Dispatch(new Msg(2, [arg]));
        },
        TestId: "change-storage-space-test-id",
        Value: props.StorageSpace,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function ChangeStorageDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.Tool, props.UserData, props.SuccessCallback, props.IsOpen, props.OnClose, props.Storages);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.OnClose]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("tool.switch_storage"), {
        Body: singleton(createElement(DialogBody, {
            Dispatch: dispatch,
            FormState: state_1.FormState,
            SelectedTargetStorage: unwrap(state_1.SelectedTargetStorage),
            StorageSpace: state_1.Dto.StorageSpace,
            Storages: state_1.Storages,
            SuccessCallback: props.SuccessCallback,
            TargetStorageOptions: state_1.TargetStorageOptions,
            Tool: props.Tool,
            UserId: state_1.UserData.UserId,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-reservation-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("tool.switch_storage"),
            OnClick: () => {
                dispatch(new Msg(0, []));
            },
            TestId: "save-switch-storage-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

