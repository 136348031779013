import { createElement } from "react";
import React from "react";
import { equals, createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { newGuid } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import { unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";

function BaseInput(props) {
    let elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = toList(delay(() => {
        let elems, value_9;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "relative"])], (elems = [createElement("textarea", createObj(ofArray([["id", `${props.TestId}-textarea-id`], ["data-test-id", props.TestId], ["className", join(" ", toList(delay(() => append(singleton("bg-white"), delay(() => append(singleton("border"), delay(() => append(singleton("focus:border-primary"), delay(() => append(singleton("focus:outline-none"), delay(() => append((props.ValidationMessage == null) ? (empty()) : append(singleton("border-red"), delay(() => singleton("focus:border-red"))), delay(() => append(singleton("rounded-lg"), delay(() => append(singleton("w-full"), delay(() => append(singleton("px-3"), delay(() => append(singleton("py-3"), delay(() => append(singleton("text-base"), delay(() => append(singleton("font-normal"), delay(() => ((props.ComponentState === "disabled") ? append(singleton("text-text-muted"), delay(() => singleton("border-border-muted"))) : singleton("border-[#B6BECB]")))))))))))))))))))))))))))], ["rows", 3], (value_9 = props.Value, ["ref", (e) => {
            if (!(e == null) && !equals(e.value, value_9)) {
                e.value = value_9;
            }
        }]), ["onChange", (ev) => {
            props.OnChange(ev.target.value);
        }], ["disabled", props.ComponentState === "disabled"]])))], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            const matchValue_2 = props.ValidationMessage;
            if (matchValue_2 != null) {
                const invalidMessage = matchValue_2;
                return singleton(createElement("p", {
                    className: join(" ", ["mt-2", "text-red", "text-sm"]),
                    children: invalidMessage,
                }));
            }
            else {
                return empty();
            }
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function TextArea(props) {
    let elems;
    const id = newGuid();
    const matchValue = props.Label;
    if (matchValue == null) {
        return createElement(BaseInput, {
            ComponentState: props.ComponentState,
            Id: id,
            OnChange: props.OnChange,
            TestId: props.TestId,
            ValidationMessage: unwrap(props.ValidationMessage),
            Value: props.Value,
        });
    }
    else {
        const label = matchValue;
        return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-1.5"])], (elems = [createElement("label", {
            htmlFor: id,
            className: join(" ", ["text-sm", "font-bold"]),
            children: label,
        }), createElement(BaseInput, {
            ComponentState: props.ComponentState,
            Id: id,
            OnChange: props.OnChange,
            TestId: props.TestId,
            ValidationMessage: unwrap(props.ValidationMessage),
            Value: props.Value,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
    }
}

