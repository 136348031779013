import { Record, Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { LoginInformation as LoginInformation_1, FormState__getValidation, FormState__startLoading, FormState__setValidationResponse_5219762A, FormState_get_empty, FormState_$reflection, ClientApiResponse$1_$reflection, LoginInformation_$reflection } from "../Shared/Shared.js";
import { record_type, lambda_type, unit_type, union_type, class_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../CommunicationV2.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Requests_UpdateLoginInformationDto, Requests_UpdateLoginInformationDto_$reflection } from "../Shared/User.js";
import { Cmd_successToast } from "../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { ErrorAlert } from "../Components/Alert.js";
import { Input } from "../Components/Input.js";
import { unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../Components/Dialog.js";
import { TextButton } from "../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetLoginInformation", "UpdateEmailOrPhoneNumber", "UpdateEmailOrPhoneNumberResponse", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Receiver.ChangeEmailOrPhoneNumberDialog.Msg", [], Msg, () => [[["Item", LoginInformation_$reflection()]], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

function putUpdateUserEmailOrPhoneNumberCmd(userId, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/receivers/users/${userId}/change-email-or-phone-number`, toString(0, Auto_generateBoxedEncoder_437914C6(Requests_UpdateLoginInformationDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])));
}

class State extends Record {
    constructor(UserId, Dto, SuccessCallback, OnClose, FormState) {
        super();
        this.UserId = UserId;
        this.Dto = Dto;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.FormState = FormState;
    }
}

function State_$reflection() {
    return record_type("Receiver.ChangeEmailOrPhoneNumberDialog.State", [], State, () => [["UserId", string_type], ["Dto", Requests_UpdateLoginInformationDto_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["FormState", FormState_$reflection()]]);
}

function init(userId, currentLoginInformation, successCallback, onClose) {
    return [new State(userId, new Requests_UpdateLoginInformationDto(currentLoginInformation), successCallback, onClose, FormState_get_empty()), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 2: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.UserId, state.Dto, state.SuccessCallback, state.OnClose, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0])), Cmd_none()];
            }
            else {
                const translationKeySuccessMsg = (state.Dto.LoginInformation.tag === 1) ? "user.change_phone_number_success_msg" : "user.change_email_success_msg";
                return [new State(state.UserId, state.Dto, state.SuccessCallback, state.OnClose, FormState__startLoading(state.FormState)), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast(translationKeySuccessMsg)]))];
            }
        }
        case 1:
            return [new State(state.UserId, state.Dto, state.SuccessCallback, state.OnClose, FormState__startLoading(state.FormState)), putUpdateUserEmailOrPhoneNumberCmd(state.UserId, state.Dto)];
        case 3:
            return [state, Cmd_none()];
        default:
            return [new State(state.UserId, new Requests_UpdateLoginInformationDto(msg.fields[0]), state.SuccessCallback, state.OnClose, state.FormState), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = toList(delay(() => {
        let matchValue;
        return append((matchValue = FormState__getValidation(props.FormState, t, "global"), (matchValue == null) ? singleton(defaultOf()) : singleton(createElement(ErrorAlert, {
            Label: matchValue,
        }))), delay(() => {
            let children;
            return singleton((children = toList(delay(() => {
                const matchValue_1 = props.LoginInformation;
                return (matchValue_1.tag === 1) ? singleton(createElement(Input, {
                    ComponentState: props.ComponentState,
                    Label: t("user.phone_number"),
                    OnChange: (value_2) => {
                        props.Dispatch(new Msg(0, [new LoginInformation_1(1, [value_2])]));
                    },
                    TestId: "",
                    ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "phoneNumber")),
                    Value: matchValue_1.fields[0],
                })) : singleton(createElement(Input, {
                    ComponentState: props.ComponentState,
                    Label: "E-Mail",
                    OnChange: (value_1) => {
                        props.Dispatch(new Msg(0, [new LoginInformation_1(0, [value_1])]));
                    },
                    TestId: "",
                    ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "email")),
                    Value: matchValue_1.fields[0],
                }));
            })), createElement("div", {
                children: reactApi.Children.toArray(Array.from(children)),
            })));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function ChangeUserEmailOrPhoneNumberDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.UserId, props.CurrentLoginInformation, props.SuccessCallback, props.OnClose);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const componentState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t((props.CurrentLoginInformation.tag === 1) ? "user.change_phone_number" : "user.change_email"), {
        Body: singleton_1(createElement(DialogBody, {
            ComponentState: componentState,
            Dispatch: dispatch,
            FormState: state_1.FormState,
            LoginInformation: state_1.Dto.LoginInformation,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-update-email-or-phonenumber-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(1, []));
            },
            TestId: "save-update-email-or-phonenumber-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

