import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { ReservationDetailDto_$reflection } from "../../Shared/Planning.js";
import { CustomerConfiguration_$reflection, RequestedValue$1_$reflection } from "../../Shared/Shared.js";
import { union_type, class_type, record_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class State extends Record {
    constructor(Reservation, CustomerConfiguration) {
        super();
        this.Reservation = Reservation;
        this.CustomerConfiguration = CustomerConfiguration;
    }
}

export function State_$reflection() {
    return record_type("Reservation.Detail.Types.State", [], State, () => [["Reservation", RequestedValue$1_$reflection(ReservationDetailDto_$reflection())], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FetchReservation", "ReservationFetched", "CustomerConfigurationFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Reservation.Detail.Types.Msg", [], Msg, () => [[], [["Item", ReservationDetailDto_$reflection()]], [["Item", CustomerConfiguration_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

