import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { stringHash, comparePrimitives, safeHash, equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { AddStocktakingUserModal } from "../Dialog/AddStocktakingUserModal.js";
import { Msg } from "./Types.js";
import { empty as empty_1, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Input } from "../../Components/Input.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { StocktakingId__get_unwrap, FormState__getValidation } from "../../Shared/Shared.js";
import { TextArea } from "../../Components/TextArea.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { StocktakingTypeScope, StocktakingLocationScope } from "../../Shared/Stocktaking.js";
import { singleton as singleton_1, sortBy, toArray, contains, filter, map, tryFind, ofArray, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { GroupedSelectMulti, MultiSelect, Select } from "../../Components/Select.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName } from "../../Shared/User.js";
import { List_groupBy } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { TableContainer } from "../../Widgets/Container.js";
import { TableControl$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import User_Add_Plus from "../../../public/assets/icons/User_Add_Plus.svg";
import User_Delete_Cross from "../../../public/assets/icons/User_Delete_Cross.svg";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { initEditStocktaking, initCopyStocktaking, update as update_1, initNewView } from "./State.js";
import { FormContainer } from "../../Components/FormContainer.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

export function StocktakingForm(props) {
    let elems_2, elems_1;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsAssignHelpingPersonModalOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsAssignResponsiblePersonModalOpen = patternInput_2[1];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    let isStocktakingTypeAndScopeVisible;
    const matchValue = props.EditStocktakingState;
    if (matchValue == null) {
        isStocktakingTypeAndScopeVisible = true;
    }
    else {
        const state = matchValue;
        switch (state.tag) {
            case 0: {
                isStocktakingTypeAndScopeVisible = true;
                break;
            }
            default:
                isStocktakingTypeAndScopeVisible = false;
        }
    }
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "mt-12", "max-w-5xl", "grow"])], (elems_2 = [createElement(AddStocktakingUserModal, {
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsAssignResponsiblePersonModalOpen(false);
        },
        OnSave: (users) => {
            props.Dispatch(new Msg(16, [users]));
            setIsAssignResponsiblePersonModalOpen(false);
        },
        PropertyConfigurations: props.PropertyConfigurations,
        SelectedUserIds: props.Stocktaking.ResponsiblePersonIds,
        Users: props.Users,
    }), createElement(AddStocktakingUserModal, {
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setIsAssignHelpingPersonModalOpen(false);
        },
        OnSave: (users_1) => {
            props.Dispatch(new Msg(13, [users_1]));
            setIsAssignHelpingPersonModalOpen(false);
        },
        PropertyConfigurations: props.PropertyConfigurations,
        SelectedUserIds: props.Stocktaking.HelperIds,
        Users: props.Users,
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-9", "grow"])], (elems_1 = toList(delay(() => append(singleton(createElement(Input, {
        ComponentState: componentState,
        Label: t("general.name"),
        OnChange: (arg) => {
            props.Dispatch(new Msg(5, [arg]));
        },
        TestId: "stocktaking-form-name",
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "name")),
        Value: props.Stocktaking.Name,
    })), delay(() => append(singleton(createElement(TextArea, {
        ComponentState: componentState,
        Label: t("general.description"),
        OnChange: (arg_1) => {
            props.Dispatch(new Msg(8, [arg_1]));
        },
        TestId: "stocktaking-form-description",
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "description")),
        Value: props.Stocktaking.Description,
    })), delay(() => append(singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: props.Stocktaking.ToolsCanBeTaken,
        Label: t("stocktaking.can_be_taken_during_stocktaking"),
        OnCheck: (arg_2) => {
            props.Dispatch(new Msg(11, [arg_2]));
        },
        TestId: "stocktaking-form-can-take-during-stocktaking",
    })), delay(() => append(singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: props.Stocktaking.IsScannerRequired,
        Label: t("stocktaking.scanner_required"),
        OnCheck: (arg_3) => {
            props.Dispatch(new Msg(12, [arg_3]));
        },
        TestId: "stocktaking-form-scanner-required-stocktaking",
    })), delay(() => append(singleton(createElement(DatePicker, {
        ComponentState: componentState,
        IsClearable: false,
        Label: t("general.start_date"),
        OnChange: (arg_4) => {
            props.Dispatch(new Msg(6, [arg_4]));
        },
        TestId: "stocktaking-form-startdate",
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "startDate")),
        Value: unwrap(props.StartDate),
    })), delay(() => append(singleton(createElement(DatePicker, {
        ComponentState: componentState,
        IsClearable: false,
        Label: t("general.end_date"),
        OnChange: (arg_5) => {
            props.Dispatch(new Msg(7, [arg_5]));
        },
        TestId: "stocktaking-form-enddate",
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "endDate")),
        Value: unwrap(props.EndDate),
    })), delay(() => {
        let Label_6, Value_4;
        const locationScopeOption = ofArray([{
            label: t("stocktaking.location_scope.complete"),
            value: new StocktakingLocationScope(0, []),
        }, {
            label: t("stocktaking.location_scope.storage"),
            value: new StocktakingLocationScope(1, [empty()]),
        }, {
            label: t("stocktaking.location_scope.receiver"),
            value: new StocktakingLocationScope(2, [empty()]),
        }]);
        return append(isStocktakingTypeAndScopeVisible ? append(singleton(createElement(Select, (Label_6 = t("stocktaking.location_scope.title"), (Value_4 = tryFind((option) => {
            const matchValue_1 = option.value;
            const matchValue_2 = props.Stocktaking.LocationScope;
            let matchResult;
            switch (matchValue_1.tag) {
                case 2: {
                    if (matchValue_2.tag === 2) {
                        matchResult = 0;
                    }
                    else {
                        matchResult = 1;
                    }
                    break;
                }
                case 0: {
                    if (matchValue_2.tag === 0) {
                        matchResult = 0;
                    }
                    else {
                        matchResult = 1;
                    }
                    break;
                }
                default:
                    if (matchValue_2.tag === 1) {
                        matchResult = 0;
                    }
                    else {
                        matchResult = 1;
                    }
            }
            switch (matchResult) {
                case 0:
                    return true;
                default:
                    return false;
            }
        }, locationScopeOption), {
            ComponentState: componentState,
            IsClearable: false,
            Label: unwrap(Label_6),
            NoOptionsMessage: "",
            OnChange: (value_2) => {
                if (value_2 == null) {
                }
                else {
                    props.Dispatch(new Msg(9, [value_2.value]));
                }
            },
            Options: locationScopeOption,
            PlaceholderKey: "",
            TestId: "",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "locationScope")),
            Value: unwrap(Value_4),
        })))), delay(() => {
            let matchValue_4, Label_7, Options, Value_5, Label_8, Options_1, Value_6;
            return append((matchValue_4 = props.Stocktaking.LocationScope, (matchValue_4.tag === 1) ? singleton(createElement(MultiSelect, (Label_7 = t("general.storage"), (Options = map((storage) => ({
                label: storage.Name,
                value: storage.Id,
            }), props.Storages), (Value_5 = filter((option_2) => contains(option_2.value, matchValue_4.fields[0], {
                Equals: equals,
                GetHashCode: safeHash,
            }), map((storage_1) => ({
                label: storage_1.Name,
                value: storage_1.Id,
            }), props.Storages)), {
                ComponentState: componentState,
                IsClearable: false,
                Label: unwrap(Label_7),
                NoOptionsMessage: "",
                OnChange: (value_4) => {
                    if (value_4 == null) {
                    }
                    else {
                        props.Dispatch(new Msg(9, [new StocktakingLocationScope(1, [map((option_1) => option_1.value, ofArray(value_4))])]));
                    }
                },
                Options: Options,
                PlaceholderKey: "",
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "locationScope")),
                Value: Value_5,
            }))))) : ((matchValue_4.tag === 2) ? singleton(createElement(GroupedSelectMulti, (Label_8 = t("general.receiver"), (Options_1 = map((tupledArg) => {
                const receiverType = tupledArg[0];
                const receivers_1 = toArray(sortBy((receiver_2) => receiver_2.label.toLowerCase(), map((receiver_1) => {
                    const matchValue_5 = receiver_1.LocationName;
                    if (matchValue_5 != null) {
                        const locationName = matchValue_5;
                        if (equals(receiverType, new ReceiverTypeName(4, []))) {
                            return {
                                label: `${locationName} - ${receiver_1.Name}`,
                                value: receiver_1.Id,
                            };
                        }
                        else {
                            return {
                                label: `${receiver_1.Name} - ${locationName}`,
                                value: receiver_1.Id,
                            };
                        }
                    }
                    else {
                        return {
                            label: `${receiver_1.Name}`,
                            value: receiver_1.Id,
                        };
                    }
                }, tupledArg[1]), {
                    Compare: comparePrimitives,
                }));
                return {
                    label: Helper_receiverTypeNameTranslation(t, receiverType),
                    options: receivers_1,
                };
            }, List_groupBy((receiver) => receiver.ReceiverType, props.Receiver, {
                Equals: equals,
                GetHashCode: safeHash,
            })), (Value_6 = filter((option_4) => contains(option_4.value, matchValue_4.fields[0], {
                Equals: (x_3, y_3) => (x_3 === y_3),
                GetHashCode: stringHash,
            }), map((receiver_3) => ({
                label: receiver_3.Name,
                value: receiver_3.Id,
            }), props.Receiver)), {
                ComponentState: componentState,
                IsClearable: false,
                Label: unwrap(Label_8),
                NoOptionsMessage: "",
                OnChange: (value_7) => {
                    if (value_7 == null) {
                    }
                    else {
                        props.Dispatch(new Msg(9, [new StocktakingLocationScope(2, [map((option_3) => option_3.value, ofArray(value_7))])]));
                    }
                },
                Options: Options_1,
                PlaceholderKey: "",
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "locationScope")),
                Value: Value_6,
            }))))) : (empty_1()))), delay(() => {
                let Label_9, Value_7;
                const typeScopeOptions = ofArray([{
                    label: t("stocktaking.type_scope.tools"),
                    value: new StocktakingTypeScope(0, []),
                }, {
                    label: t("stocktaking.type_scope.tools_with_quantity"),
                    value: new StocktakingTypeScope(1, []),
                }, {
                    label: t("stocktaking.type_scope.consumables"),
                    value: new StocktakingTypeScope(2, []),
                }]);
                return singleton(createElement(MultiSelect, (Label_9 = t("stocktaking.type_scope.title"), (Value_7 = filter((option_5) => contains(option_5.value, props.Stocktaking.TypeScope, {
                    Equals: equals,
                    GetHashCode: safeHash,
                }), typeScopeOptions), {
                    ComponentState: componentState,
                    IsClearable: false,
                    Label: unwrap(Label_9),
                    NoOptionsMessage: "",
                    OnChange: (value_10) => {
                        if (value_10 == null) {
                        }
                        else {
                            props.Dispatch(new Msg(10, [map((value_12) => value_12.value, ofArray(value_10))]));
                        }
                    },
                    Options: typeScopeOptions,
                    PlaceholderKey: "",
                    TestId: "",
                    ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "locationScope")),
                    Value: Value_7,
                }))));
            }));
        })) : empty_1(), delay(() => {
            let elems, Title, TableRows, Options_2, Title_1, TableRows_1, Options_3;
            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "grow", "gap-3"])], (elems = [createElement(TableContainer, (Title = t("stocktaking.responsible_person"), {
                Content: createElement(Table, (TableRows = toArray(filter((user) => contains(user.Id, props.Stocktaking.ResponsiblePersonIds, {
                    Equals: (x_5, y_5) => (x_5 === y_5),
                    GetHashCode: stringHash,
                }), props.Users)), (Options_2 = {
                    BorderStyle: "full",
                    CSVDownload: false,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["name", "asc"],
                    GlobalSearch: false,
                    RowSelection: "multiRow",
                }, {
                    ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "text", "text"), new ColumnDefinition$1("department", "", t("user.department"), (dto_1) => (new CellContent(0, [dto_1.Department])), "text", "text")],
                    Controls: ofArray([new TableControl$1(t("stocktaking.add"), User_Add_Plus(), (_arg_2) => {
                        setIsAssignResponsiblePersonModalOpen(true);
                    }, false, false, "stocktaking-form-table-add"), new TableControl$1(t("stocktaking.remove"), User_Delete_Cross(), (users_2) => {
                        props.Dispatch(new Msg(15, [users_2]));
                        setIsAssignResponsiblePersonModalOpen(false);
                    }, true, false, "stocktaking-form-table-remove")]),
                    Options: Options_2,
                    TableRows: TableRows,
                }))),
                Title: unwrap(Title),
            })), createElement(TableContainer, (Title_1 = t("stocktaking.stocktaking_helper"), {
                Content: createElement(Table, (TableRows_1 = toArray(filter((user_1) => contains(user_1.Id, props.Stocktaking.HelperIds, {
                    Equals: (x_6, y_6) => (x_6 === y_6),
                    GetHashCode: stringHash,
                }), props.Users)), (Options_3 = {
                    BorderStyle: "full",
                    CSVDownload: false,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["name", "asc"],
                    GlobalSearch: false,
                    RowSelection: "multiRow",
                }, {
                    ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (dto_2) => (new CellContent(0, [dto_2.Name])), "text", "text"), new ColumnDefinition$1("department", "", t("user.department"), (dto_3) => (new CellContent(0, [dto_3.Department])), "text", "text")],
                    Controls: ofArray([new TableControl$1(t("stocktaking.add"), User_Add_Plus(), (_arg_3) => {
                        setIsAssignHelpingPersonModalOpen(true);
                    }, false, false, "stocktaking-form-add-to-table"), new TableControl$1(t("stocktaking.remove"), User_Delete_Cross(), (users_3) => {
                        props.Dispatch(new Msg(14, [users_3]));
                        setIsAssignResponsiblePersonModalOpen(false);
                    }, true, false, "stocktaking-form-remove-from-table")]),
                    Options: Options_3,
                    TableRows: TableRows_1,
                }))),
                Title: unwrap(Title_1),
            }))], ["children", reactApi.Children.toArray(Array.from(elems))])]))));
        }));
    })))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function NewFormView() {
    let Header;
    const patternInput = useTranslation();
    const patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => initNewView, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const matchValue = state_1.StocktakingForm;
    const matchValue_1 = state_1.Users;
    const matchValue_2 = state_1.Storages;
    const matchValue_3 = state_1.Receiver;
    const matchValue_4 = state_1.PropertyConfigurations;
    let matchResult, propertyConfigurations, receiver, stocktaking, storages, users;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        propertyConfigurations = matchValue_4.fields[0];
        receiver = matchValue_3.fields[0];
        stocktaking = matchValue.fields[0];
        storages = matchValue_2.fields[0];
        users = matchValue_1.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(FormContainer, (Header = patternInput[0]("stocktaking.create_new_stocktaking"), {
                Children: singleton_1(createElement(StocktakingForm, {
                    Dispatch: dispatch,
                    EditStocktakingState: unwrap(state_1.EditStocktakingState),
                    EndDate: unwrap(state_1.EndDate),
                    FormState: state_1.FormState,
                    PropertyConfigurations: propertyConfigurations,
                    Receiver: receiver,
                    StartDate: unwrap(state_1.StartDate),
                    Stocktaking: stocktaking,
                    Storages: storages,
                    Users: users,
                })),
                Header: Header,
                OnBack: () => {
                    RouterModule_nav(ofArray(["stocktaking"]), 1, 1);
                },
                OnSave: () => {
                    dispatch(new Msg(2, []));
                },
            }));
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

export function CopyFormView(props) {
    let Header;
    const patternInput = useTranslation();
    let patternInput_1;
    const init = initCopyStocktaking(props.StocktakingId);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const matchValue = state_1.StocktakingForm;
    const matchValue_1 = state_1.Users;
    const matchValue_2 = state_1.Storages;
    const matchValue_3 = state_1.Receiver;
    const matchValue_4 = state_1.PropertyConfigurations;
    let matchResult, propertyConfigurations, receiver, stocktaking, storages, users;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        propertyConfigurations = matchValue_4.fields[0];
        receiver = matchValue_3.fields[0];
        stocktaking = matchValue.fields[0];
        storages = matchValue_2.fields[0];
        users = matchValue_1.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(FormContainer, (Header = patternInput[0]("stocktaking.create_new_stocktaking"), {
                Children: singleton_1(createElement(StocktakingForm, {
                    Dispatch: dispatch,
                    EditStocktakingState: unwrap(state_1.EditStocktakingState),
                    EndDate: unwrap(state_1.EndDate),
                    FormState: state_1.FormState,
                    PropertyConfigurations: propertyConfigurations,
                    Receiver: receiver,
                    StartDate: unwrap(state_1.StartDate),
                    Stocktaking: stocktaking,
                    Storages: storages,
                    Users: users,
                })),
                Header: Header,
                OnBack: () => {
                    RouterModule_nav(ofArray(["stocktaking", StocktakingId__get_unwrap(props.StocktakingId)]), 1, 1);
                },
                OnSave: () => {
                    dispatch(new Msg(2, []));
                },
            }));
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

export function EditFormView(props) {
    let Header;
    const patternInput = useTranslation();
    let patternInput_1;
    const init = initEditStocktaking(props.StocktakingId);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.StocktakingId]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const matchValue = state_1.StocktakingForm;
    const matchValue_1 = state_1.Users;
    const matchValue_2 = state_1.Storages;
    const matchValue_3 = state_1.Receiver;
    const matchValue_4 = state_1.PropertyConfigurations;
    let matchResult, propertyConfigurations, receiver, stocktaking, storages, users;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        propertyConfigurations = matchValue_4.fields[0];
        receiver = matchValue_3.fields[0];
        stocktaking = matchValue.fields[0];
        storages = matchValue_2.fields[0];
        users = matchValue_1.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(FormContainer, (Header = patternInput[0]("stocktaking.edit_stocktaking"), {
                Children: singleton_1(createElement(StocktakingForm, {
                    Dispatch: dispatch,
                    EditStocktakingState: unwrap(state_1.EditStocktakingState),
                    EndDate: unwrap(state_1.EndDate),
                    FormState: state_1.FormState,
                    PropertyConfigurations: propertyConfigurations,
                    Receiver: receiver,
                    StartDate: unwrap(state_1.StartDate),
                    Stocktaking: stocktaking,
                    Storages: storages,
                    Users: users,
                })),
                Header: Header,
                OnBack: () => {
                    RouterModule_nav(ofArray(["stocktaking", StocktakingId__get_unwrap(props.StocktakingId)]), 1, 1);
                },
                OnSave: () => {
                    dispatch(new Msg(3, []));
                },
            }));
        default:
            return createElement(Skeleton, {
                Variant: "normal",
            });
    }
}

