import { createElement } from "react";
import React from "react";
import { singleton as singleton_1, ofArray, fold } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextButton } from "./Button.js";
import Delete_1 from "../../public/assets/icons/Delete_1.svg";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { reactApi as reactApi_1 } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import react_modal_sheet from "react-modal-sheet";

export function ModalSheet(props) {
    let children_4, props_5, children_3, props_2, children, elems, sheet, props_4, children_2, props_3, sheet_1, sheet_2, sheet_3, sheet_4;
    const props_7 = ofArray([["style", {
        zIndex: 20,
    }], ["isOpen", props.IsModalOpen], ["onClose", props.OnClose], ["detent", "full-height"], ["className", fold((a, b) => (`${a} ${b}`), "", ofArray(["ml-64", "print:ml-0", "print:!absolute"]))], (children_4 = ofArray([(props_5 = singleton_1((children_3 = ofArray([(props_2 = singleton_1((children = singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "m-4", "gap-4", "justify-end"])], (elems = toList(delay(() => append(props.Controls, delay(() => singleton(createElement(TextButton, {
        ComponentState: "enabled",
        Icon: Delete_1(),
        Label: "",
        OnClick: () => {
            props.OnClose();
        },
        TestId: props.CloseButtonTestId,
        Variant: "default",
    })))))), ["children", reactApi.Children.toArray(Array.from(elems))])])))), ["children", reactApi_1.Children.toArray(children)])), (sheet = react_modal_sheet, reactApi_1.createElement(sheet.Header, createObj(props_2)))), (props_4 = singleton_1((children_2 = singleton_1((props_3 = singleton_1(["children", reactApi_1.Children.toArray([props.Content])]), (sheet_1 = react_modal_sheet, reactApi_1.createElement(sheet_1.Scroller, createObj(props_3))))), ["children", reactApi_1.Children.toArray(children_2)])), (sheet_2 = react_modal_sheet, reactApi_1.createElement(sheet_2.Content, createObj(props_4))))]), ["children", reactApi_1.Children.toArray(children_3)])), (sheet_3 = react_modal_sheet, reactApi_1.createElement(sheet_3.Container, createObj(props_5)))), (sheet_4 = react_modal_sheet, reactApi_1.createElement(sheet_4.Backdrop, {
        onTap: props.OnClose,
    }))]), ["children", reactApi_1.Children.toArray(children_4)])]);
    return reactApi_1.createElement(react_modal_sheet, createObj(props_7));
}

