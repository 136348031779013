import { Cmd_none, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { token as token_2, addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { ImportResult_$reflection, ImportDataResponse_$reflection, ImportValidateResponse_$reflection } from "../../Shared/Importer.js";
import { PostResponse$1_$reflection } from "../../Shared/Shared.js";
import { State, Msg } from "./Types.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { ofNullable } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { keyValueList } from "../../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { singleton, empty, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { fetch$ } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { onBlobReceived, getBlob } from "../../Communication.js";

export function validateCmd(formData) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), undefined, (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ImportValidateResponse_$reflection())));
    })))), ["/api/import/validate/school-classes", formData], (Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(7, [Item_1])));
}

export function importCmd(importData) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ImportDataResponse_$reflection())));
    })))), ["/api/import/school-classes", toString(0, Auto_generateBoxedEncoder_437914C6(ImportResult_$reflection(), undefined, undefined, undefined)(importData))], (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(7, [Item_1])));
}

export function init() {
    return [new State(undefined, false, undefined), Cmd_none()];
}

export function update(msg, state) {
    let matchValue;
    switch (msg.tag) {
        case 1: {
            const matchValue_1 = msg.fields[0].Result;
            switch (matchValue_1.tag) {
                case 2:
                    return [new State(undefined, false, "importer.user_limit_reached_msg"), Cmd_errorToast("importer.failed_imported_data")];
                case 3:
                    return [new State(undefined, false, "importer.tool_limit_reached_msg"), Cmd_errorToast("importer.failed_imported_data")];
                case 1:
                    return [new State(undefined, false, "importer.data_not_valid"), Cmd_errorToast("importer.failed_imported_data")];
                case 4:
                    return [new State(undefined, false, state.ValidateErrorMessage), Cmd_errorToast("importer.failed_imported_data")];
                default:
                    return [new State(matchValue_1.fields[0], false, state.ValidateErrorMessage), Cmd_successToast("importer.successfully_imported_data")];
            }
        }
        case 2:
            return [new State(undefined, true, undefined), validateCmd(msg.fields[0])];
        case 3: {
            const matchValue_2 = msg.fields[0].Result;
            switch (matchValue_2.tag) {
                case 1:
                    return [new State(undefined, false, "importer.user_limit_reached_msg"), Cmd_errorToast("importer.failed_validate_data")];
                case 2:
                    return [new State(undefined, false, "importer.tool_limit_reached_msg"), Cmd_errorToast("importer.failed_validate_data")];
                case 3:
                    return [new State(state.Import, false, "importer.validation_error"), Cmd_none()];
                default:
                    return [new State(matchValue_2.fields[0], false, state.ValidateErrorMessage), Cmd_none()];
            }
        }
        case 4:
            return [state, Cmd_OfPromise_either_1((tupledArg_1) => {
                const body_1 = tupledArg_1[2];
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const tenant = ofNullable(localStorage.getItem("tenant"));
                    const props = (body_1 == null) ? ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                        let matchValue_2_1;
                        return append((matchValue_2_1 = token_2(undefined), (matchValue_2_1 == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2_1]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                            const matchValue_3_1 = tenant;
                            if (matchValue_3_1 == null) {
                                return empty();
                            }
                            else {
                                return singleton(["X-ONE-Tenant", matchValue_3_1]);
                            }
                        }))));
                    })), 0)])]) : ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [body_1]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                        let matchValue_3;
                        return append((matchValue_3 = token_2(undefined), (matchValue_3 == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_3]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                            const matchValue_1_1 = tenant;
                            if (matchValue_1_1 == null) {
                                return empty();
                            }
                            else {
                                return singleton(["X-ONE-Tenant", matchValue_1_1]);
                            }
                        }))));
                    })), 0)])]);
                    return fetch$(addPrefix(tupledArg_1[0]), props).then((_arg) => (Promise.resolve([_arg, tupledArg_1[1]])));
                }));
            }, ["/api/import/school-classes/template", "template.csv", undefined], (tupledArg) => (new Msg(5, [tupledArg[0], tupledArg[1]])), (Item_2) => (new Msg(7, [Item_2])))];
        case 5:
            return [state, getBlob(msg.fields[0], msg.fields[1], (tupledArg_2) => (new Msg(6, [tupledArg_2[0], tupledArg_2[1]])), (Item_5) => (new Msg(7, [Item_5])))];
        case 6: {
            onBlobReceived(msg.fields[0], msg.fields[1]);
            return [state, Cmd_none()];
        }
        case 7:
            return [new State(state.Import, false, "importer.csv_validation_failure"), Cmd_none()];
        default:
            return [new State(state.Import, true, state.ValidateErrorMessage), (matchValue = state.Import, (matchValue == null) ? Cmd_none() : importCmd(matchValue))];
    }
}

