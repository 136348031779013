import { DispolistId, RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { DispolistDeleteResponse_$reflection, DispolistElement_$reflection, Dispolist_$reflection } from "../../Shared/Dispolist.js";
import { unwrapDispolistId } from "../../Shared/Helper.js";
import { list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Cmd_successToast } from "../../Components/Toast.js";

export function init(dispolistId, userData) {
    return [new State(new RequestedValue$1(0, []), userData, new RequestedValue$1(0, [])), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Dispolist_$reflection()));
    })))), `/api/dispolists/${unwrapDispolistId(new DispolistId(dispolistId))}`, (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(4, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(DispolistElement_$reflection())));
    })))), "/api/dispolists/tools", (Item_2) => (new Msg(3, [Item_2])), (Item_3) => (new Msg(4, [Item_3])))]))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                RouterModule_nav(singleton("/inventory-lists"), 1, 1);
            }), Cmd_successToast("general.deleted_successfully")]))];
        case 2:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.UserData, state.Elements), Cmd_none()];
        case 3:
            return [new State(state.Dispolist, state.UserData, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 4:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.Dispolist;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
                    const pr = response.arrayBuffer();
                    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), DispolistDeleteResponse_$reflection()));
                })))), `/api/dispolists/${unwrapDispolistId(matchValue.fields[0].Id)}`, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(4, [Item_1])))];
            }
        }
    }
}

