import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, bool_type, union_type, class_type, list_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { NoticeId, RequestedValue$1, RequestedValue$1_$reflection, ProblemReportId_$reflection, ToolId_$reflection, PostResponse$1_$reflection, EmptyResponse_$reflection } from "../../Shared/Shared.js";
import { ProblemReportState, ProblemReportSolvedDto, ProblemReportSolvedState, ProblemReportSolvedDto_$reflection, ToolDetailDto_$reflection, ProblemReport_$reflection } from "../../Shared/Tool.js";
import { Receiver_$reflection } from "../../Shared/User.js";
import { Cmd_ofEffect, Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { empty as empty_1, map as map_1, append as append_1, tryFind, ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { unwrapToolId } from "../../Shared/Helper.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_getToolDetailDto } from "../../Requests/Tool.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { unwrap, map, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { PropertyList } from "../../Widgets/PropertyListWidget.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { toLocalTime, toString as toString_1 } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { SectionHeader } from "../../Components/Text.js";
import { Message as Message_2, ChatWidget } from "../../Widgets/ChatWidget.js";
import { TextButton, Button } from "../../Components/Button.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ConfirmationDialog } from "../../Components/Dialog.js";
import { ModalSheet } from "../../Components/ModalSheet.js";
import Printer from "../../../public/assets/icons/Printer.svg";
import { Skeleton } from "../../Components/Skeleton.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetIsConfirmDialogOpen", "DeleteProblemReport", "NewMessage", "SolveProblemReport", "ProblemReportResponse", "ProblemReportsFetched", "ReceiverFetched", "ToolFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Tools.ProblemReportDialog.Msg", [], Msg, () => [[], [], [["Item", string_type]], [], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [["Item", list_type(ProblemReport_$reflection())]], [["Item", list_type(Receiver_$reflection())]], [["Item", ToolDetailDto_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

function getProblemReportsCmd(toolId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(ProblemReport_$reflection())));
    })))), `/api/tools/${unwrapToolId(toolId)}/problemreports`, (Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(8, [Item_1])));
}

function postProblemSolvedCmd(dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), ["/api/tools/problemreport/solved", toString(0, Auto_generateBoxedEncoder_437914C6(ProblemReportSolvedDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(8, [Item_1])));
}

class State extends Record {
    constructor(ToolId, ProblemReportId, ProblemReports, IsLoading, SuccessCallback, OnClose, IsConfirmDialogOpen, Receivers, Tool) {
        super();
        this.ToolId = ToolId;
        this.ProblemReportId = ProblemReportId;
        this.ProblemReports = ProblemReports;
        this.IsLoading = IsLoading;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.IsConfirmDialogOpen = IsConfirmDialogOpen;
        this.Receivers = Receivers;
        this.Tool = Tool;
    }
}

function State_$reflection() {
    return record_type("Tools.ProblemReportDialog.State", [], State, () => [["ToolId", ToolId_$reflection()], ["ProblemReportId", ProblemReportId_$reflection()], ["ProblemReports", RequestedValue$1_$reflection(list_type(ProblemReport_$reflection()))], ["IsLoading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["IsConfirmDialogOpen", bool_type], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["Tool", RequestedValue$1_$reflection(ToolDetailDto_$reflection())]]);
}

function init(props) {
    return [new State(props.ToolId, props.ProblemReportId, new RequestedValue$1(0, []), false, props.SuccessCallback, props.OnClose, false, new RequestedValue$1(0, []), new RequestedValue$1(0, [])), Cmd_batch(ofArray([getProblemReportsCmd(props.ToolId), Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(8, [Item_1]))), Cmd_getToolDetailDto((Item_2) => (new Msg(7, [Item_2])), (Item_3) => (new Msg(8, [Item_3])), props.ToolId)]))];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.ToolId, state.ProblemReportId, state.ProblemReports, false, state.SuccessCallback, state.OnClose, state.IsConfirmDialogOpen, state.Receivers, state.Tool), Cmd_none()];
        case 3:
            return [state, postProblemSolvedCmd(new ProblemReportSolvedDto(state.ProblemReportId, "", new ProblemReportSolvedState(0, [])))];
        case 2:
            return [state, postProblemSolvedCmd(new ProblemReportSolvedDto(state.ProblemReportId, msg.fields[0], new ProblemReportSolvedState(1, [])))];
        case 4:
            return [new State(state.ToolId, state.ProblemReportId, state.ProblemReports, state.IsLoading, state.SuccessCallback, state.OnClose, false, state.Receivers, state.Tool), Cmd_batch(ofArray([getProblemReportsCmd(state.ToolId), Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            })]))];
        case 5:
            return [new State(state.ToolId, state.ProblemReportId, new RequestedValue$1(1, [msg.fields[0]]), state.IsLoading, state.SuccessCallback, state.OnClose, state.IsConfirmDialogOpen, state.Receivers, state.Tool), Cmd_none()];
        case 6:
            return [new State(state.ToolId, state.ProblemReportId, state.ProblemReports, state.IsLoading, state.SuccessCallback, state.OnClose, state.IsConfirmDialogOpen, new RequestedValue$1(1, [msg.fields[0]]), state.Tool), Cmd_none()];
        case 7:
            return [new State(state.ToolId, state.ProblemReportId, state.ProblemReports, state.IsLoading, state.SuccessCallback, state.OnClose, state.IsConfirmDialogOpen, state.Receivers, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 8:
            return [new State(state.ToolId, state.ProblemReportId, state.ProblemReports, false, state.SuccessCallback, state.OnClose, state.IsConfirmDialogOpen, state.Receivers, state.Tool), Cmd_none()];
        default:
            return [new State(state.ToolId, state.ProblemReportId, state.ProblemReports, state.IsLoading, state.SuccessCallback, state.OnClose, !state.IsConfirmDialogOpen, state.Receivers, state.Tool), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems_4, elems, Title, elems_3;
    const t = useTranslation()[0];
    const receiverName = (receiverId) => defaultArg(map((r_1) => r_1.Name, tryFind((r) => (r.Id === receiverId), props.Receivers)), "");
    return createElement("div", createObj(ofArray([["className", join(" ", ["p-4", "h-full", "flex", "flex-col"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["hidden", "print:block"])], (elems = [createElement(PropertyList, (Title = t("general.master_data"), {
        Properties: toList(delay(() => append(singleton_1({
            Label: t("tool.manufacturer"),
            TestId: "master-data-manufacturer",
            Value: props.Tool.MasterData.Manufacturer,
        }), delay(() => append(singleton_1({
            Label: t("tool.model"),
            TestId: "master-data-model",
            Value: props.Tool.MasterData.Model,
        }), delay(() => append(singleton_1({
            Label: t("tool.serial_number"),
            TestId: "master-data-serial-number",
            Value: props.Tool.MasterData.SerialNumber,
        }), delay(() => append(singleton_1({
            Label: t("tool.displayname"),
            TestId: "master-data-display-name",
            Value: props.Tool.MasterData.DisplayName,
        }), delay(() => append(singleton_1({
            Label: t("tool.inventory_number"),
            TestId: "master-data-inventory-number",
            Value: props.Tool.MasterData.InventoryNumber,
        }), delay(() => append(singleton_1({
            Label: t("general.comment"),
            TestId: "master-data-comment",
            Value: props.Tool.MasterData.Comment,
        }), delay(() => append(singleton_1({
            Label: t("tool.last_check"),
            TestId: "master-data-last-check",
            Value: defaultArg(map((lastInspectionDate) => toString_1(toLocalTime(lastInspectionDate), "dd.MM.yyyy"), props.Tool.MasterData.LastInspectionDate), ""),
        }), delay(() => {
            if (props.UserData.Customer.tag === 1) {
                const matchValue_1 = props.Tool.CustomerSpecific;
                switch (matchValue_1.tag) {
                    case 1:
                        return singleton_1({
                            Label: t("tool.prinzing.check_number"),
                            TestId: "master-data-prinzing-check-number",
                            Value: matchValue_1.fields[0].CheckNumber,
                        });
                    default: {
                        return empty();
                    }
                }
            }
            else {
                return empty();
            }
        })))))))))))))))),
        Title: unwrap(Title),
    }))], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(SectionHeader, {
        Text: t("tool.current_problem_report"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "grow", "h-[calc(100%-4rem)]"])], (elems_3 = toList(delay(() => {
        let elems_1, list_3;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["h-[calc(100%-3.5rem)]"])], (elems_1 = [createElement(ChatWidget, {
            MaxContentHeigth: "",
            Messages: (list_3 = singleton(new Message_2(new NoticeId("00000000-0000-0000-0000-000000000000"), props.ProblemReport.Message, props.ProblemReport.ImageUrl, props.ProblemReport.Timestamp, receiverName(props.ProblemReport.SenderId), props.ProblemReport.SenderId, false)), append_1(map_1((l) => (new Message_2(new NoticeId("00000000-0000-0000-0000-000000000000"), l.Comment, undefined, l.Timestamp, receiverName(l.SenderId), l.SenderId, false)), props.ProblemReport.Logs), list_3)),
            OnSubmit: unwrap((props.ProblemReport.State.tag === 0) ? ((value_8) => {
                props.Dispatch(new Msg(2, [value_8]));
            }) : undefined),
            UserData: props.UserData,
            UserId: props.UserId,
        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
            let elems_2;
            return equals(props.ProblemReport.State, new ProblemReportState(0, [])) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems_2 = [createElement(Button, {
                ComponentState: "enabled",
                Label: t("tool.tool_problem_report_solved"),
                OnClick: () => {
                    props.Dispatch(new Msg(0, []));
                },
                TestId: "solve-problem-report-dialog-test-id",
                Variant: "blueButton",
            })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))) : empty();
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function ProblemReportDialog(props) {
    let elems, Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        ProblemReportId: props.ProblemReportId,
        SuccessCallback: props.SuccessCallback,
        ToolId: props.ToolId,
        UserId: props.UserId,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const matchValue = state_1.ProblemReports;
    const matchValue_1 = state_1.Receivers;
    const matchValue_2 = state_1.Tool;
    let matchResult, problemReports, receivers, tool;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        problemReports = matchValue.fields[0];
        receivers = matchValue_1.fields[0];
        tool = matchValue_2.fields[0];
    }
    switch (matchResult) {
        case 0: {
            const matchValue_4 = tryFind((pr) => equals(pr.Id, state_1.ProblemReportId), problemReports);
            if (matchValue_4 == null) {
                return "No entry found.";
            }
            else {
                const problemReport = matchValue_4;
                return createElement("div", createObj(singleton((elems = [createElement(ConfirmationDialog, (Title = t("tool.tool_problem_report_solved"), {
                    Description: t("tool.dialog_problem_report_confirm_question"),
                    IsOpen: state_1.IsConfirmDialogOpen,
                    OnClose: () => {
                        dispatch(new Msg(0, []));
                    },
                    OnConfirm: () => {
                        dispatch(new Msg(3, []));
                    },
                    Title: Title,
                })), createElement(ModalSheet, {
                    CloseButtonTestId: "problem-report-modal-sheet-test-id",
                    Content: createElement(DialogBody, {
                        Dispatch: dispatch,
                        ProblemReport: problemReport,
                        Receivers: receivers,
                        SuccessCallback: props.SuccessCallback,
                        Tool: tool,
                        UserData: props.UserData,
                        UserId: props.UserId,
                    }),
                    Controls: singleton(createElement(TextButton, {
                        ComponentState: "enabled",
                        Icon: Printer(),
                        Label: "",
                        OnClick: () => {
                            window.print();
                        },
                        TestId: "problem-report-print-button",
                        Variant: "default",
                    })),
                    IsModalOpen: props.IsOpen,
                    OnClose: props.OnClose,
                })], ["children", reactApi.Children.toArray(Array.from(elems))]))));
            }
        }
        default:
            return createElement(ModalSheet, {
                CloseButtonTestId: "tool-problem-report-dialog-modal-sheet-test-id",
                Content: createElement(Skeleton, {
                    Variant: "detail",
                }),
                Controls: empty_1(),
                IsModalOpen: props.IsOpen,
                OnClose: props.OnClose,
            });
    }
}

