import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { Stocktaking_$reflection } from "../../Shared/Stocktaking.js";
import { record_type, union_type, class_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { RequestedValue$1_$reflection } from "../../Shared/Shared.js";

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["StocktakingsFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Stocktaking.Overview.Types.Msg", [], Msg, () => [[["Item", list_type(Stocktaking_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class State extends Record {
    constructor(Stocktakings) {
        super();
        this.Stocktakings = Stocktakings;
    }
}

export function State_$reflection() {
    return record_type("Stocktaking.Overview.Types.State", [], State, () => [["Stocktakings", RequestedValue$1_$reflection(list_type(Stocktaking_$reflection()))]]);
}

