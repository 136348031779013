import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, class_type, option_type, record_type, string_type, int32_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ToolBoxDispolist_$reflection } from "../../Shared/Receiver.js";

export class QueryParams extends Record {
    constructor(Page, Name, NumberTools, NumberToolsWithQuantity) {
        super();
        this.Page = (Page | 0);
        this.Name = Name;
        this.NumberTools = NumberTools;
        this.NumberToolsWithQuantity = NumberToolsWithQuantity;
    }
}

export function QueryParams_$reflection() {
    return record_type("ToolBox.Dispolist.Types.QueryParams", [], QueryParams, () => [["Page", int32_type], ["Name", string_type], ["NumberTools", string_type], ["NumberToolsWithQuantity", string_type]]);
}

export class ToolBoxRow extends Record {
    constructor(UserId, DisplayName, ToolQuantity, ToolWithQuantityQuantity) {
        super();
        this.UserId = UserId;
        this.DisplayName = DisplayName;
        this.ToolQuantity = ToolQuantity;
        this.ToolWithQuantityQuantity = ToolWithQuantityQuantity;
    }
}

export function ToolBoxRow_$reflection() {
    return record_type("ToolBox.Dispolist.Types.ToolBoxRow", [], ToolBoxRow, () => [["UserId", string_type], ["DisplayName", string_type], ["ToolQuantity", string_type], ["ToolWithQuantityQuantity", string_type]]);
}

export class ToolRow extends Record {
    constructor(ToolId, Manufacturer, Model, SerialNumber, DisplayName, Quantity, CheckNumber, RentDate, ReturnDate) {
        super();
        this.ToolId = ToolId;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.SerialNumber = SerialNumber;
        this.DisplayName = DisplayName;
        this.Quantity = Quantity;
        this.CheckNumber = CheckNumber;
        this.RentDate = RentDate;
        this.ReturnDate = ReturnDate;
    }
}

export function ToolRow_$reflection() {
    return record_type("ToolBox.Dispolist.Types.ToolRow", [], ToolRow, () => [["ToolId", string_type], ["Manufacturer", string_type], ["Model", string_type], ["SerialNumber", option_type(string_type)], ["DisplayName", string_type], ["Quantity", option_type(string_type)], ["CheckNumber", option_type(string_type)], ["RentDate", class_type("System.DateTime")], ["ReturnDate", option_type(class_type("System.DateTime"))]]);
}

export class State extends Record {
    constructor(ToolBoxDispolist, UserId) {
        super();
        this.ToolBoxDispolist = ToolBoxDispolist;
        this.UserId = UserId;
    }
}

export function State_$reflection() {
    return record_type("ToolBox.Dispolist.Types.State", [], State, () => [["ToolBoxDispolist", option_type(ToolBoxDispolist_$reflection())], ["UserId", string_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DispolistReturned", "ToolBoxDispolistFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("ToolBox.Dispolist.Types.Msg", [], Msg, () => [[], [["Item", ToolBoxDispolist_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

