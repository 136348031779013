import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { OnTableRowClick$1, Table, ColumnDefinition$1, CellContent } from "../Widgets/Table/Table.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { isNullOrWhiteSpace, join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, toArray, ofArray, empty } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { RouterModule_nav, RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { unwrapLocationId } from "../Shared/Helper.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { Skeleton } from "../Components/Skeleton.js";
import { ConfirmationDialog } from "../Components/Dialog.js";
import { Msg } from "./Types.js";
import { DetailViewHeader } from "../Widgets/DetailViewHeader.js";
import { LabeledValue } from "../Components/Text.js";
import { initEditLocationView, initNewLocationView, init as init_1, update as update_1, initViewLocationView } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { TabsMenu } from "../Widgets/TabsMenu.js";
import { Button } from "../Components/Button.js";
import { FormContainer } from "../Components/FormContainer.js";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { ErrorAlert } from "../Components/Alert.js";
import { Input } from "../Components/Input.js";
import { unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";

export function LocationOverviewTable(props) {
    let elems;
    const t = useTranslation()[0];
    const matchValue = props.State.Locations;
    if (matchValue.tag === 1) {
        const columnDefinitions = [new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "text", "text"), new ColumnDefinition$1("Description", "", t("general.description"), (dto_1) => (new CellContent(0, [dto_1.Description])), "text", "text")];
        return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(Table, {
            ColumnDefinitions: columnDefinitions,
            Controls: empty(),
            Options: {
                BorderStyle: "row",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["name", "asc"],
                GlobalSearch: false,
                OnRowClick: new OnTableRowClick$1(1, [(dto_2) => RouterModule_encodeParts(ofArray(["locations", unwrapLocationId(dto_2.Id)]), 1)]),
                RowSelection: "disabled",
            },
            TableRows: toArray(matchValue.fields[0]),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
    }
    else {
        return createElement(Skeleton, {
            Variant: "table",
        });
    }
}

export function LocationDetails(props) {
    let elems_2, Title, elems_1, elems;
    const patternInput = reactApi.useState(false);
    const setIsDeleteDialogOpen = patternInput[1];
    const isDeleteDialogOpen = patternInput[0];
    const t = useTranslation()[0];
    return createElement("div", createObj(singleton((elems_2 = [createElement(ConfirmationDialog, (Title = t("general.remove"), {
        Description: t("location.delete_confirmation"),
        IsOpen: isDeleteDialogOpen,
        OnClose: () => {
            setIsDeleteDialogOpen(false);
        },
        OnConfirm: () => {
            props.Dispatch(new Msg(4, []));
        },
        Title: Title,
    })), createElement("div", createObj(ofArray([["className", join(" ", ["max-w-[75rem]"])], (elems_1 = [createElement(DetailViewHeader, {
        Badges: empty(),
        OnBack: () => {
            RouterModule_nav(ofArray(["locations"]), 1, 1);
        },
        OnDelete: () => {
            setIsDeleteDialogOpen(!isDeleteDialogOpen);
        },
        OnEdit: () => {
            RouterModule_nav(ofArray(["locations", unwrapLocationId(props.Location.Id), "edit"]), 1, 1);
        },
        Title: t("location.location_details"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-shrink-0", "flex-col", "mb-3", "bg-white", "rounded", "px-12", "py-9"])], (elems = [LabeledValue({
        Label: t("general.name"),
        Value: props.Location.Name,
    }), LabeledValue({
        Label: t("general.description"),
        Value: props.Location.Description,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))));
}

export function LocationDetailsView(props) {
    let patternInput;
    const init = initViewLocationView(props.LocationId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.LocationId]);
    const matchValue = patternInput[0].Form;
    if (matchValue.tag === 0) {
        return createElement(Skeleton, {
            Variant: "detail",
        });
    }
    else {
        return createElement(LocationDetails, {
            Dispatch: patternInput[1],
            Location: matchValue.fields[0],
        });
    }
}

export function LocationOverview() {
    let elems_2, elems_1, elems;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init_1, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const t = useTranslation()[0];
    return createElement("div", createObj(singleton((elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-between", "mb-5", "items-center"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["align-bottom"])], (elems = [createElement(TabsMenu, {
        Tabs: ofArray([{
            IsSelected: false,
            Label: t("navbar.storages"),
            OnClick: () => {
                RouterModule_nav(ofArray(["storages"]), 1, 1);
            },
            TestId: "",
        }, {
            IsSelected: true,
            Label: t("navbar.locations"),
            OnClick: () => {
                RouterModule_nav(ofArray(["locations"]), 1, 1);
            },
            TestId: "",
        }]),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(Button, {
        ComponentState: "enabled",
        Label: t("location.create_new_location"),
        OnClick: () => {
            RouterModule_nav(ofArray(["locations", "new"]), 1, 1);
        },
        TestId: "new-location-button",
        Variant: "primary",
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement(LocationOverviewTable, {
        State: patternInput[0],
    })], ["children", reactApi.Children.toArray(Array.from(elems_2))]))));
}

export function LocationForm(props) {
    const t = useTranslation()[0];
    return createElement(FormContainer, {
        Children: toList(delay(() => {
            let matchValue;
            return append((matchValue = props.Message, (matchValue == null) ? singleton_1(defaultOf()) : singleton_1(createElement(ErrorAlert, {
                Label: matchValue,
            }))), delay(() => append(singleton_1(createElement(Input, {
                ComponentState: "enabled",
                Label: t("general.name"),
                OnChange: (arg) => {
                    props.Dispatch(new Msg(0, [arg]));
                },
                TestId: "",
                ValidationMessage: unwrap(!(props.IsInvalid && isNullOrWhiteSpace(props.Location.Name)) ? undefined : ""),
                Value: props.Location.Name,
            })), delay(() => singleton_1(createElement(Input, {
                ComponentState: "enabled",
                Label: t("general.description"),
                OnChange: (arg_1) => {
                    props.Dispatch(new Msg(1, [arg_1]));
                },
                TestId: "",
                ValidationMessage: unwrap(!(props.IsInvalid && isNullOrWhiteSpace(props.Location.Description)) ? undefined : ""),
                Value: props.Location.Description,
            }))))));
        })),
        Header: props.Header,
        OnBack: props.OnBack,
        OnSave: props.OnSave,
    });
}

export function NewLocationView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(initNewLocationView, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    if (matchValue.tag === 1) {
        return createElement(LocationForm, {
            Dispatch: dispatch,
            Header: patternInput_1[0]("location.create_new_location"),
            IsInvalid: state_1.IsInvalid,
            Location: matchValue.fields[0],
            Message: unwrap(state_1.Message),
            OnBack: () => {
                RouterModule_nav(ofArray(["locations"]), 1, 1);
            },
            OnSave: () => {
                dispatch(new Msg(2, []));
            },
        });
    }
    else {
        return createElement(Skeleton, {
            Variant: "detail",
        });
    }
}

export function EditLocationView(props) {
    let patternInput;
    const init = initEditLocationView(props.LocationId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.LocationId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    if (matchValue.tag === 1) {
        return createElement(LocationForm, {
            Dispatch: dispatch,
            Header: patternInput_1[0]("location.create_new_location"),
            IsInvalid: state_1.IsInvalid,
            Location: matchValue.fields[0],
            Message: unwrap(state_1.Message),
            OnBack: () => {
                RouterModule_nav(ofArray(["locations", unwrapLocationId(props.LocationId)]), 1, 1);
            },
            OnSave: () => {
                dispatch(new Msg(3, []));
            },
        });
    }
    else {
        return createElement(Skeleton, {
            Variant: "detail",
        });
    }
}

