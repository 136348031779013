import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, bool_type, union_type, list_type, option_type, float64_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { DispolistResponse_$reflection, Dispolist_$reflection, Element$_$reflection, DispolistElement_$reflection } from "../../Shared/Dispolist.js";
import { TokenDataDto_$reflection, RequestedValue$1_$reflection, FormKind_$reflection, PostResponse$1_$reflection } from "../../Shared/Shared.js";

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetName", "SetDescription", "SetQuantity", "AddElements", "RemoveElement", "DispolistFetched", "SaveDispolist", "ToolsFetched", "DispolistSaved", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("InventoryLists.Form.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item1", class_type("System.Guid")], ["Item2", option_type(float64_type)]], [["Item", list_type(DispolistElement_$reflection())]], [["Item", Element$_$reflection()]], [["Item", Dispolist_$reflection()]], [], [["Item", list_type(DispolistElement_$reflection())]], [["Item", PostResponse$1_$reflection(DispolistResponse_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class State extends Record {
    constructor(FormKind, Dispolist, IsLoading, Elements, UserData, IsValidated, Message) {
        super();
        this.FormKind = FormKind;
        this.Dispolist = Dispolist;
        this.IsLoading = IsLoading;
        this.Elements = Elements;
        this.UserData = UserData;
        this.IsValidated = IsValidated;
        this.Message = Message;
    }
}

export function State_$reflection() {
    return record_type("InventoryLists.Form.Types.State", [], State, () => [["FormKind", FormKind_$reflection()], ["Dispolist", RequestedValue$1_$reflection(Dispolist_$reflection())], ["IsLoading", bool_type], ["Elements", RequestedValue$1_$reflection(list_type(DispolistElement_$reflection()))], ["UserData", TokenDataDto_$reflection()], ["IsValidated", bool_type], ["Message", option_type(string_type)]]);
}

