import { Record, Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { list_type, int32_type, record_type, string_type, union_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { BillingMethod_$reflection, ShoppingCartId_$reflection, ShoppingCartPositionId_$reflection } from "./Shared.js";
import { int32ToString } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { sumBy } from "../fable_modules/fable-library-js.4.19.2/List.js";

export class PackageUnit extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LargePackage", "SmallPackage"];
    }
}

export function PackageUnit_$reflection() {
    return union_type("Shared.Shop.PackageUnit", [], PackageUnit, () => [[], []]);
}

export class QRCodeSize extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Small", "Medium", "Large"];
    }
}

export function QRCodeSize_$reflection() {
    return union_type("Shared.Shop.QRCodeSize", [], QRCodeSize, () => [[], [], []]);
}

export class ShoppingCartDeliveryAddress extends Record {
    constructor(CompanyName, Street, ZipCode, City) {
        super();
        this.CompanyName = CompanyName;
        this.Street = Street;
        this.ZipCode = ZipCode;
        this.City = City;
    }
}

export function ShoppingCartDeliveryAddress_$reflection() {
    return record_type("Shared.Shop.ShoppingCartDeliveryAddress", [], ShoppingCartDeliveryAddress, () => [["CompanyName", string_type], ["Street", string_type], ["ZipCode", string_type], ["City", string_type]]);
}

export class ShoppingCartPosition extends Record {
    constructor(Id, QRCodeSize, PackageUnit, Amount) {
        super();
        this.Id = Id;
        this.QRCodeSize = QRCodeSize;
        this.PackageUnit = PackageUnit;
        this.Amount = (Amount | 0);
    }
}

export function ShoppingCartPosition_$reflection() {
    return record_type("Shared.Shop.ShoppingCartPosition", [], ShoppingCartPosition, () => [["Id", ShoppingCartPositionId_$reflection()], ["QRCodeSize", QRCodeSize_$reflection()], ["PackageUnit", PackageUnit_$reflection()], ["Amount", int32_type]]);
}

export class ShoppingCart extends Record {
    constructor(ShoppingCartPositions, ShoppingCartId, DeliveryAddress, Comment$, BillingMethod) {
        super();
        this.ShoppingCartPositions = ShoppingCartPositions;
        this.ShoppingCartId = ShoppingCartId;
        this.DeliveryAddress = DeliveryAddress;
        this.Comment = Comment$;
        this.BillingMethod = BillingMethod;
    }
}

export function ShoppingCart_$reflection() {
    return record_type("Shared.Shop.ShoppingCart", [], ShoppingCart, () => [["ShoppingCartPositions", list_type(ShoppingCartPosition_$reflection())], ["ShoppingCartId", ShoppingCartId_$reflection()], ["DeliveryAddress", ShoppingCartDeliveryAddress_$reflection()], ["Comment", string_type], ["BillingMethod", BillingMethod_$reflection()]]);
}

export const Helper_sizeSmallLabel = "Klein - 25 mm x 13 mm";

export const Helper_sizeMediumLabel = "Mittel - 40 mm x 22 mm";

export const Helper_sizeLargeLabel = "Groß - 72 mm x 42 mm";

export function Helper_packageUnitString(packageUnit) {
    if (packageUnit.tag === 1) {
        return "50 Stück (25,00€)";
    }
    else {
        return "100 Stück (40,00€)";
    }
}

export function Helper_qrCodeSizeString(qRCodeSize) {
    switch (qRCodeSize.tag) {
        case 1:
            return Helper_sizeMediumLabel;
        case 2:
            return Helper_sizeLargeLabel;
        default:
            return Helper_sizeSmallLabel;
    }
}

export function Helper_pricePerPosition(pos) {
    if (pos.PackageUnit.tag === 1) {
        return (25 * pos.Amount) | 0;
    }
    else {
        return (40 * pos.Amount) | 0;
    }
}

export function Helper_shoppingCartPriceSumString(shoppingCartPositions) {
    return int32ToString(sumBy(Helper_pricePerPosition, shoppingCartPositions, {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    })) + ",00 €";
}

