import { Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { token as token_2, addPrefix, fetchWithDecoder } from "../Communication.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { reduce, isEmpty, ofArray, singleton } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { ToolInfo_$reflection, AssignToolNotification_$reflection, UnassignQRCode_$reflection, DeleteQRCodeResult_$reflection, ToolWithQuantityDetailDto_$reflection, ToolHistoryDto_$reflection, ToolDetailDto_$reflection, ToolTypes_$reflection, ToolFormDto_$reflection, ToolDto_$reflection } from "../Shared/Tool.js";
import { string_type, list_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../CommunicationV2.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { ProblemReportId__get_unwrap, UpdateReturnDateDto_$reflection, UnassignToolWithoutNotificationDto_$reflection, AssignToolWithoutNotificationDto_$reflection, UpdateToolStorageDto_$reflection, EmptyResponse_$reflection, PostResponse$1_$reflection, ToolId__get_unwrap } from "../Shared/Shared.js";
import { unwrapNoticeId, unwrapToolId } from "../Shared/Helper.js";
import { ValidationOnGetRequest } from "../ErrorHandling.js";
import { ofNullable } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { singleton as singleton_1, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { fetch$ } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import swr from "swr";

export function Cmd_getTools(successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(ToolDto_$reflection())));
    })))), "/api/tools/overview", successMsg, errorMsg);
}

export function Cmd_createTool(toolForm, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), ["/api/tools", toString(0, Auto_generateBoxedEncoder_437914C6(ToolFormDto_$reflection(), undefined, undefined, undefined)(toolForm))], successMsg, errorMsg);
}

export function Cmd_updateTool(toolId, toolForm, successMsg, errorMsg) {
    const body = toString(0, Auto_generateBoxedEncoder_437914C6(ToolFormDto_$reflection(), undefined, undefined, undefined)(toolForm));
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_2, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/tools/${ToolId__get_unwrap(toolId)}`, body], successMsg, errorMsg);
}

export function Cmd_getTool(successMsg, errorMsg, toolId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), ToolTypes_$reflection()));
    })))), `/api/tools/${unwrapToolId(toolId)}`, successMsg, errorMsg);
}

export function Cmd_getToolDetailDto(successMsg, errorMsg, toolId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), ToolDetailDto_$reflection()));
    })))), `/api/tools/${unwrapToolId(toolId)}/details`, successMsg, errorMsg);
}

export function Cmd_getToolHistoryDto(successMsg, errorMsg, toolId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix_1(url), (refreshOn) => fetchWithDecoder_1(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(ToolHistoryDto_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), `/api/tools/${unwrapToolId(toolId)}/history`, successMsg, errorMsg);
}

export function Cmd_getToolWithQuantityDetailDto(successMsg, errorMsg, toolId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), ToolWithQuantityDetailDto_$reflection()));
    })))), `/api/tools/tool-with-quantity/${unwrapToolId(toolId)}/details`, successMsg, errorMsg);
}

export function Cmd_deleteQrCodes(successMsg, errorMsg, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(DeleteQRCodeResult_$reflection())));
    })))), ["/api/tools/unassignqrcode", toString(0, Auto_generateBoxedEncoder_437914C6(UnassignQRCode_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function Cmd_downloadFileCmd(toolId, documentId, fileName, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => {
        const body_1 = tupledArg[2];
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const tenant = ofNullable(localStorage.getItem("tenant"));
            const props = (body_1 == null) ? ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue_2;
                return append((matchValue_2 = token_2(undefined), (matchValue_2 == null) ? (empty()) : singleton_1(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2]))), delay(() => append(singleton_1(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_3 = tenant;
                    if (matchValue_3 == null) {
                        return empty();
                    }
                    else {
                        return singleton_1(["X-ONE-Tenant", matchValue_3]);
                    }
                }))));
            })), 0)])]) : ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [body_1]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue;
                return append((matchValue = token_2(undefined), (matchValue == null) ? (empty()) : singleton_1(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue]))), delay(() => append(singleton_1(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_1 = tenant;
                    if (matchValue_1 == null) {
                        return empty();
                    }
                    else {
                        return singleton_1(["X-ONE-Tenant", matchValue_1]);
                    }
                }))));
            })), 0)])]);
            return fetch$(addPrefix(tupledArg[0]), props).then((_arg) => (Promise.resolve([_arg, tupledArg[1]])));
        }));
    }, [`/api/tools/${toolId.fields[0]}/files/${documentId.fields[0]}`, fileName, undefined], successMsg, errorMsg);
}

export function Cmd_getReminderNameSuggestions(successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(string_type)));
    })))), "/api/tools/reminders/name-suggestions", successMsg, errorMsg);
}

export function Cmd_deleteNoticeCmd(toolId, noticeId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_1), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), `/api/tools/${unwrapToolId(toolId)}/notices/${unwrapNoticeId(noticeId)}`, successMsg, errorMsg);
}

export function Cmd_getManufacturerNameSuggestions(successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(string_type)));
    })))), "/api/tools/manufacturers", successMsg, errorMsg);
}

export function Cmd_updateToolStorage(toolId, dto, successMsg, errorMsg) {
    const body = toString(0, Auto_generateBoxedEncoder_437914C6(UpdateToolStorageDto_$reflection(), undefined, undefined, undefined)(dto));
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_2, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/tools/${ToolId__get_unwrap(toolId)}/storage`, body], successMsg, errorMsg);
}

export function Cmd_getSelectedTools(toolIds, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix_1(url), (refreshOn) => fetchWithDecoder_1(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(ToolTypes_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), `/api/tools/selected?ids=${isEmpty(toolIds) ? "" : reduce((id1, id2) => (`${id1},${id2}`), toolIds)}`, successMsg, errorMsg);
}

export function Cmd_assignCmd(toolId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/tools/${ToolId__get_unwrap(toolId)}/assign`, toString(0, Auto_generateBoxedEncoder_437914C6(AssignToolWithoutNotificationDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function Cmd_unassignCmd(toolId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/tools/${ToolId__get_unwrap(toolId)}/unassign`, toString(0, Auto_generateBoxedEncoder_437914C6(UnassignToolWithoutNotificationDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function Cmd_assignWithNotificationCmd(toolId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_3, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/tools/${ToolId__get_unwrap(toolId)}/assign/notify`, toString(0, Auto_generateBoxedEncoder_437914C6(AssignToolNotification_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function Cmd_updateReturnDateCmd(toolId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/tools/${ToolId__get_unwrap(toolId)}/return-date`, toString(0, Auto_generateBoxedEncoder_437914C6(UpdateReturnDateDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function Cmd_getToolInfo(toolId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix_1(url), (refreshOn) => fetchWithDecoder_1(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), ToolInfo_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), `/api/tools/${ToolId__get_unwrap(toolId)}/info`, successMsg, errorMsg);
}

export function Cmd_deleteProblemReportCmd(toolId, problemReportId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(url_1), (refreshOn) => fetchWithDecoder_1(url_3, singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), `/api/tools/${toolId}/problem-reports/${ProblemReportId__get_unwrap(problemReportId)}`, successMsg, errorMsg);
}

export function SWR_getTools() {
    return swr("/api/tools/overview", (url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(ToolDto_$reflection())));
    })))));
}

