import { RequestedValue$1 } from "../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { getCalendarData } from "../Requests/Calendar.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";

export function init(userData) {
    return [new State(new RequestedValue$1(0, []), userData), getCalendarData((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(1, [Item_1])))];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        return [state, Cmd_none()];
    }
    else {
        return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.UserData), Cmd_none()];
    }
}

