import { utcNow } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getReservation } from "../../Requests/Reservation.js";
import { getCustomerConfiguration } from "../../Requests/Configuration.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";

export function init(userData, reservationId) {
    const now = utcNow();
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, [])), Cmd_batch(ofArray([getReservation(reservationId, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(3, [Item_1]))), getCustomerConfiguration((Item_2) => (new Msg(2, [Item_2])), (Item_3) => (new Msg(3, [Item_3])))]))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 2:
            return [new State(state.Reservation, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 1:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.CustomerConfiguration), Cmd_none()];
        case 3:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.Reservation;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const reservation = matchValue.fields[0];
                return [state, getReservation(parse(defaultArg(reservation.GroupId, reservation.Id)), (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(3, [Item_1])))];
            }
        }
    }
}

