import { Record, Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, bool_type, union_type, class_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { BillingMethod as BillingMethod_1, BillingMethodInvoice, PostResponse$1_$reflection, EmptyResponse_$reflection, BillingMethod_$reflection } from "../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../Communication.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { ShoppingCart as ShoppingCart_5, ShoppingCartDeliveryAddress, ShoppingCart_$reflection } from "../Shared/Shop.js";
import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { Cmd_successToast } from "../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { TextValue, BoxHeader } from "../Components/Text.js";
import { Checkbox } from "../Components/Checkbox.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { WarningAlert } from "../Components/Alert.js";
import { Input } from "../Components/Input.js";
import { unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { TextArea } from "../Components/TextArea.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../Components/Dialog.js";
import { TextButton } from "../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetStreet", "SetCompany", "SetZipCode", "SetCity", "SetComment", "SetBillingMethod", "OrderCart", "CartOrdered", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Shop.ConfirmOrderDialog.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", BillingMethod_$reflection()]], [], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

function postShoppingCartCmd(dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), ["/api/shop/order", toString(0, Auto_generateBoxedEncoder_437914C6(ShoppingCart_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(8, [Item_1])));
}

class State extends Record {
    constructor(IsLoading, BillingMethod, ShoppingCart, SuccessCallback, OnClose, IsDeliveryAddressInvalid) {
        super();
        this.IsLoading = IsLoading;
        this.BillingMethod = BillingMethod;
        this.ShoppingCart = ShoppingCart;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.IsDeliveryAddressInvalid = IsDeliveryAddressInvalid;
    }
}

function State_$reflection() {
    return record_type("Shop.ConfirmOrderDialog.State", [], State, () => [["IsLoading", bool_type], ["BillingMethod", BillingMethod_$reflection()], ["ShoppingCart", ShoppingCart_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["IsDeliveryAddressInvalid", bool_type]]);
}

function init(props) {
    return [new State(false, props.BillingMethod, props.ShoppingCart, props.SuccessCallback, props.OnClose, false), Cmd_none()];
}

function update(msg, state) {
    let bind$0040_2, bind$0040_3, bind$0040_4, bind$0040_5, bind$0040_6, bind$0040_7, bind$0040_8, bind$0040_9, bind$0040, bind$0040_1;
    switch (msg.tag) {
        case 1:
            return [new State(state.IsLoading, state.BillingMethod, (bind$0040_2 = state.ShoppingCart, new ShoppingCart_5(bind$0040_2.ShoppingCartPositions, bind$0040_2.ShoppingCartId, (bind$0040_3 = state.ShoppingCart.DeliveryAddress, new ShoppingCartDeliveryAddress(msg.fields[0], bind$0040_3.Street, bind$0040_3.ZipCode, bind$0040_3.City)), bind$0040_2.Comment, bind$0040_2.BillingMethod)), state.SuccessCallback, state.OnClose, state.IsDeliveryAddressInvalid), Cmd_none()];
        case 2:
            return [new State(state.IsLoading, state.BillingMethod, (bind$0040_4 = state.ShoppingCart, new ShoppingCart_5(bind$0040_4.ShoppingCartPositions, bind$0040_4.ShoppingCartId, (bind$0040_5 = state.ShoppingCart.DeliveryAddress, new ShoppingCartDeliveryAddress(bind$0040_5.CompanyName, bind$0040_5.Street, msg.fields[0], bind$0040_5.City)), bind$0040_4.Comment, bind$0040_4.BillingMethod)), state.SuccessCallback, state.OnClose, state.IsDeliveryAddressInvalid), Cmd_none()];
        case 3:
            return [new State(state.IsLoading, state.BillingMethod, (bind$0040_6 = state.ShoppingCart, new ShoppingCart_5(bind$0040_6.ShoppingCartPositions, bind$0040_6.ShoppingCartId, (bind$0040_7 = state.ShoppingCart.DeliveryAddress, new ShoppingCartDeliveryAddress(bind$0040_7.CompanyName, bind$0040_7.Street, bind$0040_7.ZipCode, msg.fields[0])), bind$0040_6.Comment, bind$0040_6.BillingMethod)), state.SuccessCallback, state.OnClose, state.IsDeliveryAddressInvalid), Cmd_none()];
        case 4:
            return [new State(state.IsLoading, state.BillingMethod, (bind$0040_8 = state.ShoppingCart, new ShoppingCart_5(bind$0040_8.ShoppingCartPositions, bind$0040_8.ShoppingCartId, bind$0040_8.DeliveryAddress, msg.fields[0], bind$0040_8.BillingMethod)), state.SuccessCallback, state.OnClose, state.IsDeliveryAddressInvalid), Cmd_none()];
        case 5:
            return [new State(state.IsLoading, msg.fields[0], state.ShoppingCart, state.SuccessCallback, state.OnClose, state.IsDeliveryAddressInvalid), Cmd_none()];
        case 6:
            if (((isNullOrWhiteSpace(state.ShoppingCart.DeliveryAddress.City) ? true : isNullOrWhiteSpace(state.ShoppingCart.DeliveryAddress.Street)) ? true : isNullOrWhiteSpace(state.ShoppingCart.DeliveryAddress.ZipCode)) ? true : isNullOrWhiteSpace(state.ShoppingCart.DeliveryAddress.CompanyName)) {
                return [new State(state.IsLoading, state.BillingMethod, state.ShoppingCart, state.SuccessCallback, state.OnClose, true), Cmd_none()];
            }
            else {
                return [new State(true, state.BillingMethod, state.ShoppingCart, state.SuccessCallback, state.OnClose, false), postShoppingCartCmd((bind$0040_9 = state.ShoppingCart, new ShoppingCart_5(bind$0040_9.ShoppingCartPositions, bind$0040_9.ShoppingCartId, bind$0040_9.DeliveryAddress, bind$0040_9.Comment, state.BillingMethod)))];
            }
        case 7:
            return [new State(false, state.BillingMethod, state.ShoppingCart, state.SuccessCallback, state.OnClose, state.IsDeliveryAddressInvalid), Cmd_batch(ofArray([Cmd_ofEffect((_arg_1) => {
                state.SuccessCallback();
            }), Cmd_successToast("shop.dialog_cart_success"), Cmd_ofEffect((_arg_2) => {
                state.OnClose();
            })]))];
        case 8:
            return [new State(false, state.BillingMethod, state.ShoppingCart, state.SuccessCallback, state.OnClose, state.IsDeliveryAddressInvalid), Cmd_none()];
        default:
            return [new State(state.IsLoading, state.BillingMethod, (bind$0040 = state.ShoppingCart, new ShoppingCart_5(bind$0040.ShoppingCartPositions, bind$0040.ShoppingCartId, (bind$0040_1 = state.ShoppingCart.DeliveryAddress, new ShoppingCartDeliveryAddress(bind$0040_1.CompanyName, msg.fields[0], bind$0040_1.ZipCode, bind$0040_1.City)), bind$0040.Comment, bind$0040.BillingMethod)), state.SuccessCallback, state.OnClose, state.IsDeliveryAddressInvalid), Cmd_none()];
    }
}

function BillingMethodView(props) {
    let elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = [createElement(BoxHeader, {
        Text: t("customerdata.billing_method"),
    }), Checkbox({
        ComponentState: "enabled",
        IsChecked: props.BillingMethod.tag === 1,
        Label: t("customerdata.invoice"),
        OnCheck: (_arg) => {
            props.SetBillingMethod(new BillingMethod_1(1, [new BillingMethodInvoice(false)]));
        },
        TestId: "",
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

function DeliveryAddressView(props) {
    let elems;
    const t = useTranslation()[0];
    const invalidMessage = props.IsDeliveryAddressInvalid ? "" : undefined;
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = toList(delay(() => append(singleton(createElement(BoxHeader, {
        Text: t("shop.delivery_address"),
    })), delay(() => append(props.IsDeliveryAddressInvalid ? singleton(createElement(WarningAlert, {
        Label: t("shop.delivery_address_not_filled"),
    })) : empty(), delay(() => append(singleton(createElement(Input, {
        ComponentState: "enabled",
        Label: t("shop.company_name"),
        OnChange: props.SetCompany,
        TestId: "",
        ValidationMessage: unwrap(invalidMessage),
        Value: props.Company,
    })), delay(() => append(singleton(createElement(Input, {
        ComponentState: "enabled",
        Label: t("shop.street"),
        OnChange: props.SetStreet,
        TestId: "",
        ValidationMessage: unwrap(invalidMessage),
        Value: props.Street,
    })), delay(() => append(singleton(createElement(Input, {
        ComponentState: "enabled",
        Label: t("shop.zip_code"),
        OnChange: props.SetZipCode,
        TestId: "",
        ValidationMessage: unwrap(invalidMessage),
        Value: props.ZipCode,
    })), delay(() => append(singleton(createElement(Input, {
        ComponentState: "enabled",
        Label: t("shop.city"),
        OnChange: props.SetCity,
        TestId: "",
        ValidationMessage: unwrap(invalidMessage),
        Value: props.City,
    })), delay(() => singleton(createElement(TextArea, {
        ComponentState: "enabled",
        Label: t("general.comment"),
        OnChange: props.SetComment,
        TestId: "",
        Value: props.Comment,
    })))))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

function DialogBody(props) {
    let elems;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = [createElement(DeliveryAddressView, {
        City: props.ShoppingCart.DeliveryAddress.City,
        Comment: props.ShoppingCart.Comment,
        Company: props.ShoppingCart.DeliveryAddress.CompanyName,
        IsDeliveryAddressInvalid: props.IsDeliveryAddressInvalid,
        SetCity: (value_4) => {
            props.Dispatch(new Msg(3, [value_4]));
        },
        SetComment: (value_5) => {
            props.Dispatch(new Msg(4, [value_5]));
        },
        SetCompany: (value_1) => {
            props.Dispatch(new Msg(1, [value_1]));
        },
        SetStreet: (value_2) => {
            props.Dispatch(new Msg(0, [value_2]));
        },
        SetZipCode: (value_3) => {
            props.Dispatch(new Msg(2, [value_3]));
        },
        Street: props.ShoppingCart.DeliveryAddress.Street,
        ZipCode: props.ShoppingCart.DeliveryAddress.ZipCode,
    }), createElement(BillingMethodView, {
        BillingMethod: props.InitialBillingData.BillingMethod,
        ConfiguredBillingMethod: props.SelectedBillingMethod,
        SetBillingMethod: (billingMethod) => {
            props.Dispatch(new Msg(5, [billingMethod]));
        },
    }), createElement(TextValue, {
        FontWeight: "small",
        TestId: "",
        Text: patternInput[0]("shop.dialog_cart_question"),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function ConfirmOrderDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        BillingMethod: props.BillingData.BillingMethod,
        DeliveryAddress: props.ShoppingCart.DeliveryAddress,
        OnClose: props.OnClose,
        ShoppingCart: props.ShoppingCart,
        SuccessCallback: props.SuccessCallback,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("shop.order_shoppingcart"), {
        Body: singleton_1(createElement(DialogBody, {
            Dispatch: dispatch,
            InitialBillingData: props.BillingData,
            IsDeliveryAddressInvalid: state_1.IsDeliveryAddressInvalid,
            SelectedBillingMethod: state_1.BillingMethod,
            ShoppingCart: state_1.ShoppingCart,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-confirm-order-dialog-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("shop.order_shoppingcart"),
            OnClick: () => {
                dispatch(new Msg(6, []));
            },
            TestId: "order-shopping-cart-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

