import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { RequestedValue$1_$reflection, StorageId_$reflection, Customer_$reflection, ToolId_$reflection, ConsumableId_$reflection } from "../Shared/Shared.js";
import { union_type, list_type, bool_type, tuple_type, option_type, int32_type, class_type, record_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ConsumableByCommissionnumber_$reflection, ReportResultDto_$reflection } from "../Shared/Report.js";
import { PropertyConfiguration_$reflection } from "../Shared/PropertyConfiguration.js";
import { Storage_$reflection } from "../Shared/Storage.js";

export class ConsumableByCommissionnumberRow extends Record {
    constructor(Id, Name, Quantity) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Quantity = Quantity;
    }
}

export function ConsumableByCommissionnumberRow_$reflection() {
    return record_type("Report.Types.ConsumableByCommissionnumberRow", [], ConsumableByCommissionnumberRow, () => [["Id", ConsumableId_$reflection()], ["Name", string_type], ["Quantity", string_type]]);
}

export class AppointmentPerToolRow extends Record {
    constructor(ReminderDate, Title, Description, ToolId, IsConfirmed, CheckNumber, DisplayName, SerialNumber) {
        super();
        this.ReminderDate = ReminderDate;
        this.Title = Title;
        this.Description = Description;
        this.ToolId = ToolId;
        this.IsConfirmed = IsConfirmed;
        this.CheckNumber = CheckNumber;
        this.DisplayName = DisplayName;
        this.SerialNumber = SerialNumber;
    }
}

export function AppointmentPerToolRow_$reflection() {
    return record_type("Report.Types.AppointmentPerToolRow", [], AppointmentPerToolRow, () => [["ReminderDate", class_type("System.DateTime")], ["Title", string_type], ["Description", string_type], ["ToolId", ToolId_$reflection()], ["IsConfirmed", string_type], ["CheckNumber", string_type], ["DisplayName", string_type], ["SerialNumber", string_type]]);
}

export class State extends Record {
    constructor(SelectedReportId, SelectedUserId, ReportResult, SelectedConsumableByCommissionnumber, StartDate, Customer, EndDate, SelectedStorageId, RequestState, PropertyConfiguration, Storages) {
        super();
        this.SelectedReportId = SelectedReportId;
        this.SelectedUserId = SelectedUserId;
        this.ReportResult = ReportResult;
        this.SelectedConsumableByCommissionnumber = SelectedConsumableByCommissionnumber;
        this.StartDate = StartDate;
        this.Customer = Customer;
        this.EndDate = EndDate;
        this.SelectedStorageId = SelectedStorageId;
        this.RequestState = RequestState;
        this.PropertyConfiguration = PropertyConfiguration;
        this.Storages = Storages;
    }
}

export function State_$reflection() {
    return record_type("Report.Types.State", [], State, () => [["SelectedReportId", option_type(int32_type)], ["SelectedUserId", option_type(tuple_type(string_type, string_type))], ["ReportResult", option_type(ReportResultDto_$reflection())], ["SelectedConsumableByCommissionnumber", option_type(ConsumableByCommissionnumber_$reflection())], ["StartDate", class_type("System.DateTime")], ["Customer", Customer_$reflection()], ["EndDate", class_type("System.DateTime")], ["SelectedStorageId", option_type(StorageId_$reflection())], ["RequestState", bool_type], ["PropertyConfiguration", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PropertyConfigurationFetched", "SetStartDate", "SetEndDate", "SetConsumableByCommissionnumber", "SetReportId", "SetSelectedUserId", "SetSelectedStorageId", "DownloadReportAsCsv", "FetchReports", "CsvExportFetched", "BlobReceived", "ReportResultFetched", "StoragesFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Report.Types.Msg", [], Msg, () => [[["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [["Item", ConsumableByCommissionnumber_$reflection()]], [["Item", int32_type]], [["Item", option_type(tuple_type(string_type, string_type))]], [["Item", option_type(StorageId_$reflection())]], [], [], [["Item1", class_type("Fetch.Types.Response")], ["Item2", string_type]], [["Item1", class_type("Browser.Types.Blob", undefined)], ["Item2", string_type]], [["Item", ReportResultDto_$reflection()]], [["Item", list_type(Storage_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

