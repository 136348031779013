import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { UnassignDispolistResponse_$reflection } from "../../../Shared/Dispolist.js";
import { TokenDataDto_$reflection, PostResponse$1_$reflection } from "../../../Shared/Shared.js";
import { record_type, bool_type, list_type, tuple_type, lambda_type, unit_type, union_type, class_type, option_type, anonRecord_type, string_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ToolBoxDispolist_$reflection } from "../../../Shared/Receiver.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../../Communication.js";
import { Types_RequestProperties } from "../../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { empty, toArray, map, ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { UnassignDispolist, UnassignDispolist_$reflection } from "../../../Shared/Tool.js";
import { unwrapDispolistSnapshotId } from "../../../Shared/Helper.js";
import { Cmd_successToast } from "../../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { toLocalTime, toString as toString_1 } from "../../../fable_modules/fable-library-js.4.19.2/Date.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { InformationAlert } from "../../../Components/Alert.js";
import { Select } from "../../../Components/Select.js";
import { unwrap } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Button } from "../../../Components/Button.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ColumnDefinition$1, CellContent, Table } from "../../../Widgets/Table/Table.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ModalSheet } from "../../../Components/ModalSheet.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UnassignDispolist", "UnassignDispolistResponse", "SetSelectedDispolist", "ToolBoxDispolistFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("ToolBox.Detail.Dialog.UnassignDispolistDialog.Msg", [], Msg, () => [[], [["Item", PostResponse$1_$reflection(UnassignDispolistResponse_$reflection())]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", string_type]))]], [["Item", ToolBoxDispolist_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

function getToolBoxDispolistCmd(userId, eventId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), ToolBoxDispolist_$reflection()));
    })))), `/api/toolbox/${userId}/dispolist/${eventId}`, (Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(4, [Item_1])));
}

function returnDispolistCmd(dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(UnassignDispolistResponse_$reflection())));
    })))), ["/api/dispolists/unassign-without-notification", toString(0, Auto_generateBoxedEncoder_437914C6(UnassignDispolist_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(4, [Item_1])));
}

class State extends Record {
    constructor(UserData, SuccessCallback, SelectedDispolist, DispolistIdsAndNames, OnClose, IsLoading, ToolBoxDispolist, SenderId) {
        super();
        this.UserData = UserData;
        this.SuccessCallback = SuccessCallback;
        this.SelectedDispolist = SelectedDispolist;
        this.DispolistIdsAndNames = DispolistIdsAndNames;
        this.OnClose = OnClose;
        this.IsLoading = IsLoading;
        this.ToolBoxDispolist = ToolBoxDispolist;
        this.SenderId = SenderId;
    }
}

function State_$reflection() {
    return record_type("ToolBox.Detail.Dialog.UnassignDispolistDialog.State", [], State, () => [["UserData", TokenDataDto_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["SelectedDispolist", option_type(anonRecord_type(["label", string_type], ["value", string_type]))], ["DispolistIdsAndNames", list_type(tuple_type(string_type, string_type, class_type("System.DateTime")))], ["OnClose", lambda_type(unit_type, unit_type)], ["IsLoading", bool_type], ["ToolBoxDispolist", option_type(ToolBoxDispolist_$reflection())], ["SenderId", string_type]]);
}

function init(props) {
    return [new State(props.UserData, props.SuccessCallback, undefined, props.DispolistIdsAndNames, props.OnClose, false, undefined, props.SenderId), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 0: {
            const matchValue = state.ToolBoxDispolist;
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.UserData, state.SuccessCallback, state.SelectedDispolist, state.DispolistIdsAndNames, state.OnClose, true, state.ToolBoxDispolist, state.SenderId), returnDispolistCmd(new UnassignDispolist(unwrapDispolistSnapshotId(matchValue.DispolistSnapshotId), state.SenderId))];
            }
        }
        case 2: {
            const selectedDispolist = msg.fields[0];
            if (selectedDispolist == null) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.UserData, state.SuccessCallback, selectedDispolist, state.DispolistIdsAndNames, state.OnClose, true, state.ToolBoxDispolist, state.SenderId), getToolBoxDispolistCmd(state.SenderId, selectedDispolist.value)];
            }
        }
        case 1:
            return [new State(state.UserData, state.SuccessCallback, state.SelectedDispolist, state.DispolistIdsAndNames, state.OnClose, false, state.ToolBoxDispolist, state.SenderId), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_successToast("toolbox.dispolist_return_successful"), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            })]))];
        case 4:
            return [new State(state.UserData, state.SuccessCallback, state.SelectedDispolist, state.DispolistIdsAndNames, state.OnClose, false, state.ToolBoxDispolist, state.SenderId), Cmd_none()];
        default:
            return [new State(state.UserData, state.SuccessCallback, state.SelectedDispolist, state.DispolistIdsAndNames, state.OnClose, false, msg.fields[0], state.SenderId), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems_3;
    const t = useTranslation()[0];
    const options = map((tupledArg) => ({
        label: `${tupledArg[0]} - ${toString_1(toLocalTime(tupledArg[2]), "dd.MM.yyyy HH:mm")}`,
        value: tupledArg[1],
    }), props.DispolistIdsAndNames);
    return createElement("div", createObj(ofArray([["id", "unassign-dispolist-dialog"], ["className", join(" ", ["grid", "gap-5", "p-4"])], (elems_3 = toList(delay(() => {
        let elems_1, elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "w-[35rem]", "gap-5"])], (elems_1 = [createElement(InformationAlert, {
            Label: t("toolbox.dispolist_return_msg"),
        }), createElement(Select, {
            ComponentState: props.FormState,
            IsClearable: false,
            NoOptionsMessage: "",
            OnChange: props.SetSelectedDispolist,
            Options: options,
            PlaceholderKey: "",
            TestId: "",
            Value: unwrap(props.SelectedDispolist),
        }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems = [createElement(Button, {
            ComponentState: (props.ToolBoxDispolist == null) ? "disabled" : props.FormState,
            Label: t("general.take_back"),
            OnClick: () => {
                props.Dispatch(new Msg(0, []));
            },
            TestId: "save-unassign-dispolist-dialog-test-id",
            Variant: "primary",
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
            let elems_2, TableRows;
            const matchValue = props.ToolBoxDispolist;
            if (matchValue != null) {
                const dispolist = matchValue;
                return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["h-max"])], (elems_2 = [createElement(Table, (TableRows = toArray(dispolist.Rows), {
                    ColumnDefinitions: [new ColumnDefinition$1("elementType", "", t("general.tool_type"), (row) => (new CellContent(0, [t(row.ElementType)])), "none", "text"), new ColumnDefinition$1("name", "", t("general.tool"), (row_1) => (new CellContent(0, [row_1.Name])), "none", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_2) => (new CellContent(0, [row_2.Quantity])), "none", "text"), new ColumnDefinition$1("storage", "", t("general.storage"), (row_3) => (new CellContent(0, [row_3.Storage])), "none", "text")],
                    Controls: empty(),
                    Options: {
                        BorderStyle: "full",
                        CSVDownload: false,
                        ConfigurableColumns: false,
                        DefaultSortColumn: ["name", "asc"],
                        GlobalSearch: false,
                        RowSelection: "disabled",
                    },
                    TableRows: TableRows,
                }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
            }
            else {
                return empty_1();
            }
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function UnassignDispolistDialog(props) {
    const patternInput = useTranslation();
    let patternInput_1;
    const init_1 = init({
        DispolistIdsAndNames: props.DispolistIdsAndNames,
        OnClose: props.OnClose,
        SenderId: props.SenderId,
        SuccessCallback: props.SuccessCallback,
        UserData: props.UserData,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(ModalSheet, {
        CloseButtonTestId: "",
        Content: createElement(DialogBody, {
            Dispatch: dispatch,
            DispolistIdsAndNames: state_1.DispolistIdsAndNames,
            FormState: state_1.IsLoading ? "disabled" : "enabled",
            SelectedDispolist: unwrap(state_1.SelectedDispolist),
            SetSelectedDispolist: (value) => {
                dispatch(new Msg(2, [value]));
            },
            ToolBoxDispolist: unwrap(state_1.ToolBoxDispolist),
        }),
        Controls: empty(),
        IsModalOpen: props.IsOpen,
        OnClose: props.OnClose,
    });
}

