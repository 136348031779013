import { Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { map, empty, ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { RequestedValue$1, Label, LabelId, PostResponse$1_$reflection, LabelResponse_$reflection, Label_$reflection } from "../../Shared/Shared.js";
import { list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { LabelRow, State, LabelForm as LabelForm_4, Msg } from "./Types.js";
import { isNullOrWhiteSpace, printf, toText } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";

export const getLabelsCmd = Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
    const pr = response.arrayBuffer();
    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Label_$reflection())));
})))), "/api/labels", (Item) => (new Msg(9, [Item])), (Item_1) => (new Msg(12, [Item_1])));

export function getLabelCmd(labelId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Label_$reflection()));
    })))), toText(printf("/api/labels/%s"))(labelId.fields[0]), (Item) => (new Msg(8, [Item])), (Item_1) => (new Msg(12, [Item_1])));
}

export function postLabelCmd(label) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(LabelResponse_$reflection())));
    })))), ["/api/labels", toString(0, Auto_generateBoxedEncoder_437914C6(Label_$reflection(), undefined, undefined, undefined)(label))], (Item) => (new Msg(10, [Item])), (Item_1) => (new Msg(12, [Item_1])));
}

export function putLabelCmd(label) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(LabelResponse_$reflection())));
    })))), ["/api/labels", toString(0, Auto_generateBoxedEncoder_437914C6(Label_$reflection(), undefined, undefined, undefined)(label))], (Item) => (new Msg(10, [Item])), (Item_1) => (new Msg(12, [Item_1])));
}

export const emtptyLabel = new Label(new LabelId("00000000-0000-0000-0000-000000000000"), "", "#9A9FFF", true);

export const initialState = new State(new RequestedValue$1(0, []), empty(), new LabelForm_4(emtptyLabel), undefined, false, false);

export function initOverview() {
    return [initialState, Cmd_batch(singleton(getLabelsCmd))];
}

export function initNewLabel() {
    return [initialState, Cmd_none()];
}

export function initViewOrEditLabel(labelId) {
    return [initialState, Cmd_batch(ofArray([getLabelCmd(new LabelId(labelId)), getLabelsCmd]))];
}

export function update(msg, state) {
    let fullPath_2;
    switch (msg.tag) {
        case 7:
            return [state, Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
                const pr = response.arrayBuffer();
                return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Label_$reflection())));
            })))), "/api/labels", (Item) => (new Msg(9, [Item])), (Item_1) => (new Msg(12, [Item_1])))];
        case 1: {
            const label = state.LabelForm.fields[0];
            return [new State(state.Categories, state.LabelTable, new LabelForm_4(new Label(label.Id, msg.fields[0], label.ColorHex, label.VisibleForUserRole)), state.Message, state.IsValidated, state.IsLabelColorSelectorOpen), Cmd_none()];
        }
        case 2: {
            const label_1 = state.LabelForm.fields[0];
            return [new State(state.Categories, state.LabelTable, new LabelForm_4(new Label(label_1.Id, label_1.Name, msg.fields[0], label_1.VisibleForUserRole)), state.Message, state.IsValidated, state.IsLabelColorSelectorOpen), Cmd_none()];
        }
        case 3: {
            const label_2 = state.LabelForm.fields[0];
            return [new State(state.Categories, state.LabelTable, new LabelForm_4(new Label(label_2.Id, label_2.Name, label_2.ColorHex, msg.fields[0])), state.Message, state.IsValidated, state.IsLabelColorSelectorOpen), Cmd_none()];
        }
        case 4: {
            msg.fields[0].preventDefault();
            const label_3 = state.LabelForm.fields[0];
            if (isNullOrWhiteSpace(label_3.Name)) {
                return [new State(state.Categories, state.LabelTable, state.LabelForm, state.Message, true, state.IsLabelColorSelectorOpen), Cmd_none()];
            }
            else {
                return [new State(state.Categories, state.LabelTable, state.LabelForm, undefined, state.IsValidated, state.IsLabelColorSelectorOpen), postLabelCmd(label_3)];
            }
        }
        case 5: {
            msg.fields[0].preventDefault();
            const label_4 = state.LabelForm.fields[0];
            if (isNullOrWhiteSpace(label_4.Name)) {
                return [new State(state.Categories, state.LabelTable, state.LabelForm, state.Message, true, state.IsLabelColorSelectorOpen), Cmd_none()];
            }
            else {
                return [new State(state.Categories, state.LabelTable, state.LabelForm, undefined, state.IsValidated, state.IsLabelColorSelectorOpen), putLabelCmd(label_4)];
            }
        }
        case 6:
            return [new State(state.Categories, state.LabelTable, state.LabelForm, state.Message, state.IsValidated, !state.IsLabelColorSelectorOpen), Cmd_none()];
        case 8:
            return [new State(state.Categories, state.LabelTable, new LabelForm_4(msg.fields[0]), state.Message, state.IsValidated, state.IsLabelColorSelectorOpen), Cmd_none()];
        case 9: {
            const labels = msg.fields[0];
            return [new State(new RequestedValue$1(1, [labels]), map((label_6) => (new LabelRow(label_6.Id.fields[0], label_6.Name, label_6.ColorHex)), labels), state.LabelForm, state.Message, state.IsValidated, state.IsLabelColorSelectorOpen), Cmd_none()];
        }
        case 10: {
            let patternInput_1;
            const matchValue_5 = msg.fields[0].Result;
            patternInput_1 = ((matchValue_5.tag === 0) ? [new State(state.Categories, state.LabelTable, state.LabelForm, "settings.label_exists_msg", state.IsValidated, state.IsLabelColorSelectorOpen), Cmd_errorToast("settings.label_exists_msg")] : [state, Cmd_batch(ofArray([(fullPath_2 = toText(printf("/settings/view/label/%O"))(matchValue_5.fields[0].fields[0]), Cmd_ofEffect((_arg_1) => {
                RouterModule_nav(singleton(fullPath_2), 1, 1);
            })), Cmd_successToast("general.saved_successfully")]))]);
            return [patternInput_1[0], patternInput_1[1]];
        }
        case 11:
            return [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg_2) => {
                RouterModule_nav(singleton("/settings"), 1, 1);
            }), Cmd_successToast("general.deleted_successfully")]))];
        case 12:
            return [state, Cmd_none()];
        default:
            return [state, Cmd_ofEffect((_arg) => {
                RouterModule_nav(singleton("/settings/new/label"), 1, 1);
            })];
    }
}

