import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, record_type, bool_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class OnboardingAvailable extends Record {
    constructor(Value) {
        super();
        this.Value = Value;
    }
}

export function OnboardingAvailable_$reflection() {
    return record_type("Shared.Onboarding.OnboardingAvailable", [], OnboardingAvailable, () => [["Value", bool_type]]);
}

export class ImportDemoDataResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "TestphaseEndOrDemoDataAlreadyImported"];
    }
}

export function ImportDemoDataResponse_$reflection() {
    return union_type("Shared.Onboarding.ImportDemoDataResponse", [], ImportDemoDataResponse, () => [[], []]);
}

