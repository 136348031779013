import { SetId as SetId_1, FormState__setValidationResponse_5219762A, FormState__startLoading, SetId__get_unwrap, Label_$reflection, FormKind as FormKind_1, FormState_get_empty, RequestedValue$1, CommercialDataFormDto } from "../../Shared/Shared.js";
import { contains, filter, map as map_1, append, ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { DataTransfer_CreateOrUpdateSet_$reflection, DataTransfer_CreateOrUpdateSet, DataTransfer_CreateOrUpdateLocationInformation, DataTransfer_CreateOrUpdateMasterData } from "../../Shared/Set.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { string_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { getAssignableToolsWithSetId, getAssignableTools } from "../../Requests/Set.js";
import { Cmd_getManufacturerNameSuggestions } from "../../Requests/Tool.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../CommunicationV2.js";
import { PropertyConfigurationEnabledResponse_$reflection, PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { ValidationOnGetRequest } from "../../ErrorHandling.js";
import { List_distinct } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { equals, stringHash } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { map } from "../../fable_modules/fable-library-js.4.19.2/Array.js";
import { map as map_2, ofNullable, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { unwrapLabelId } from "../../Shared/Helper.js";
import { toISOString } from "../../Common.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { SetId__get_unwrap as SetId__get_unwrap_1 } from "../../Shared/Shared.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { uploadImage } from "../../Requests/Files.js";

const emptySet = new DataTransfer_CreateOrUpdateSet("", new DataTransfer_CreateOrUpdateMasterData("", "", "", "", "", "", new CommercialDataFormDto(undefined, "", "", undefined, undefined, ""), empty()), new DataTransfer_CreateOrUpdateLocationInformation("", ""), empty(), empty(), empty());

export const initState = new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), undefined, false, undefined, undefined, new RequestedValue$1(0, []), empty(), undefined, undefined, false, empty(), new RequestedValue$1(0, []), undefined, new RequestedValue$1(0, []), FormState_get_empty(), new FormKind_1(0, []));

export function initNewSetView(userData) {
    return [new State(initState.Tools, new RequestedValue$1(1, [emptySet]), initState.Storages, initState.Locations, initState.SetId, initState.IsValidated, userData, userData.Customer, initState.Labels, initState.SelectedTools, initState.PurchaseDate, initState.WarrantyDate, initState.ImageViewerOpen, initState.Manufacturers, initState.PropertyConfigurations, initState.SelectedFile, initState.IsCustomPropertiesEnabled, initState.FormState, new FormKind_1(0, [])), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Storage_$reflection())));
    })))), "/api/storages/access", (Item) => (new Msg(32, [Item])), (Item_1) => (new Msg(40, [Item_1]))), getAssignableTools((Item_2) => (new Msg(27, [Item_2])), (Item_3) => (new Msg(40, [Item_3]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Label_$reflection())));
    })))), "/api/labels", (Item_4) => (new Msg(33, [Item_4])), (Item_5) => (new Msg(40, [Item_5]))), Cmd_getManufacturerNameSuggestions((Item_6) => (new Msg(34, [Item_6])), (Item_7) => (new Msg(40, [Item_7]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_3 = _arg;
            return (result_3.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_3.fields[0]);
            })())) : (Promise.resolve(result_3.fields[0]));
        });
    })), "/api/property-configurations", (Item_8) => (new Msg(36, [Item_8])), (Item_9) => (new Msg(40, [Item_9]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_11;
        return ((url_11 = addPrefix_1(url_9), (refreshOn_1) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
            const pr_3 = response_3.arrayBuffer();
            return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_5 = _arg_1;
            return (result_5.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_5.fields[0]);
            })())) : (Promise.resolve(result_5.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_10) => (new Msg(39, [Item_10])), (Item_11) => (new Msg(40, [Item_11])))]))];
}

export function initEditView(userData, setId) {
    return [new State(initState.Tools, initState.SetForm, initState.Storages, initState.Locations, setId, initState.IsValidated, userData, userData.Customer, initState.Labels, initState.SelectedTools, initState.PurchaseDate, initState.WarrantyDate, initState.ImageViewerOpen, initState.Manufacturers, initState.PropertyConfigurations, initState.SelectedFile, initState.IsCustomPropertiesEnabled, initState.FormState, new FormKind_1(1, [])), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), DataTransfer_CreateOrUpdateSet_$reflection()));
    })))), `/api/sets/${SetId__get_unwrap(setId)}/basis`, (Item) => (new Msg(28, [Item])), (Item_1) => (new Msg(40, [Item_1]))), getAssignableToolsWithSetId((Item_2) => (new Msg(27, [Item_2])), (Item_3) => (new Msg(40, [Item_3])), SetId__get_unwrap(setId)), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
    })))), "/api/storages/access", (Item_4) => (new Msg(32, [Item_4])), (Item_5) => (new Msg(40, [Item_5]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(Label_$reflection())));
    })))), "/api/labels", (Item_6) => (new Msg(33, [Item_6])), (Item_7) => (new Msg(40, [Item_7]))), Cmd_getManufacturerNameSuggestions((Item_8) => (new Msg(34, [Item_8])), (Item_9) => (new Msg(40, [Item_9]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_11;
        return ((url_11 = addPrefix_1(url_9), (refreshOn) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
            const pr_3 = response_3.arrayBuffer();
            return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_4 = _arg;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), "/api/property-configurations", (Item_10) => (new Msg(36, [Item_10])), (Item_11) => (new Msg(40, [Item_11]))), Cmd_OfPromise_either((url_12) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_14;
        return ((url_14 = addPrefix_1(url_12), (refreshOn_1) => fetchWithDecoder_1(url_14, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_4) => {
            const pr_4 = response_4.arrayBuffer();
            return pr_4.then((blob_4) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_4)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_6 = _arg_1;
            return (result_6.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_6.fields[0]);
            })())) : (Promise.resolve(result_6.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_12) => (new Msg(39, [Item_12])), (Item_13) => (new Msg(40, [Item_13])))]))];
}

export function initCopySetView(userData, setId) {
    return [new State(initState.Tools, initState.SetForm, initState.Storages, initState.Locations, initState.SetId, initState.IsValidated, userData, userData.Customer, initState.Labels, initState.SelectedTools, initState.PurchaseDate, initState.WarrantyDate, initState.ImageViewerOpen, initState.Manufacturers, initState.PropertyConfigurations, initState.SelectedFile, initState.IsCustomPropertiesEnabled, initState.FormState, new FormKind_1(2, [])), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), DataTransfer_CreateOrUpdateSet_$reflection()));
    })))), `/api/sets/${SetId__get_unwrap(setId)}/basis`, (Item) => (new Msg(28, [Item])), (Item_1) => (new Msg(40, [Item_1]))), getAssignableTools((Item_2) => (new Msg(27, [Item_2])), (Item_3) => (new Msg(40, [Item_3]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
    })))), "/api/storages/access", (Item_4) => (new Msg(32, [Item_4])), (Item_5) => (new Msg(40, [Item_5]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(Label_$reflection())));
    })))), "/api/labels", (Item_6) => (new Msg(33, [Item_6])), (Item_7) => (new Msg(40, [Item_7]))), Cmd_getManufacturerNameSuggestions((Item_8) => (new Msg(34, [Item_8])), (Item_9) => (new Msg(40, [Item_9]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_11;
        return ((url_11 = addPrefix_1(url_9), (refreshOn) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
            const pr_3 = response_3.arrayBuffer();
            return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_4 = _arg;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), "/api/property-configurations", (Item_10) => (new Msg(36, [Item_10])), (Item_11) => (new Msg(40, [Item_11]))), Cmd_OfPromise_either((url_12) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_14;
        return ((url_14 = addPrefix_1(url_12), (refreshOn_1) => fetchWithDecoder_1(url_14, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_4) => {
            const pr_4 = response_4.arrayBuffer();
            return pr_4.then((blob_4) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_4)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_6 = _arg_1;
            return (result_6.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_6.fields[0]);
            })())) : (Promise.resolve(result_6.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_12) => (new Msg(39, [Item_12])), (Item_13) => (new Msg(40, [Item_13])))]))];
}

export function update(msg, state) {
    let matchValue_1, set$_1, bind$0040_1, matchValue_2, set$_2, bind$0040_2, matchValue_3, set$_3, bind$0040_3, matchValue_4, set$_4, bind$0040_4, matchValue_5, set$_5, matchValue_6, set$_6, matchValue_7, set$_7, bind$0040_7, matchValue_8, set$_8, matchValue_9, set$_9, matchValue_10, set$_10, bind$0040_8, matchValue_11, set$_11, bind$0040_9, bind$0040_10, matchValue_12, set$_12, bind$0040_11, bind$0040_12, matchValue_13, set$_13, bind$0040_13, bind$0040_14, matchValue_14, set$_14, bind$0040_15, bind$0040_16, matchValue_15, set$_15, matchValue_16, set$_16, bind$0040_17, bind$0040_18, bind$0040_19, bind$0040_20, body_3, set$_24, body_5, xs, xs_2, xs_4, matchValue, set$, bind$0040;
    switch (msg.tag) {
        case 4:
            return [new State(state.Tools, (matchValue_1 = state.SetForm, (matchValue_1.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_1 = matchValue_1.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_1.ImageUrl, (bind$0040_1 = set$_1.MasterData, new DataTransfer_CreateOrUpdateMasterData(bind$0040_1.Name, bind$0040_1.Manufacturer, bind$0040_1.Model, bind$0040_1.SerialNumber, bind$0040_1.Comment, msg.fields[0], bind$0040_1.CommercialData, bind$0040_1.CustomProperties)), set$_1.LocationInformation, set$_1.AssignedToolIds, set$_1.QRCodeIds, set$_1.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 1:
            return [new State(state.Tools, (matchValue_2 = state.SetForm, (matchValue_2.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_2 = matchValue_2.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_2.ImageUrl, (bind$0040_2 = set$_2.MasterData, new DataTransfer_CreateOrUpdateMasterData(msg.fields[0], bind$0040_2.Manufacturer, bind$0040_2.Model, bind$0040_2.SerialNumber, bind$0040_2.Comment, bind$0040_2.InventoryNumber, bind$0040_2.CommercialData, bind$0040_2.CustomProperties)), set$_2.LocationInformation, set$_2.AssignedToolIds, set$_2.QRCodeIds, set$_2.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 2:
            return [new State(state.Tools, (matchValue_3 = state.SetForm, (matchValue_3.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_3 = matchValue_3.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_3.ImageUrl, (bind$0040_3 = set$_3.MasterData, new DataTransfer_CreateOrUpdateMasterData(bind$0040_3.Name, bind$0040_3.Manufacturer, msg.fields[0], bind$0040_3.SerialNumber, bind$0040_3.Comment, bind$0040_3.InventoryNumber, bind$0040_3.CommercialData, bind$0040_3.CustomProperties)), set$_3.LocationInformation, set$_3.AssignedToolIds, set$_3.QRCodeIds, set$_3.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 3:
            return [new State(state.Tools, (matchValue_4 = state.SetForm, (matchValue_4.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_4 = matchValue_4.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_4.ImageUrl, (bind$0040_4 = set$_4.MasterData, new DataTransfer_CreateOrUpdateMasterData(bind$0040_4.Name, bind$0040_4.Manufacturer, bind$0040_4.Model, msg.fields[0], bind$0040_4.Comment, bind$0040_4.InventoryNumber, bind$0040_4.CommercialData, bind$0040_4.CustomProperties)), set$_4.LocationInformation, set$_4.AssignedToolIds, set$_4.QRCodeIds, set$_4.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 5:
            return [new State(state.Tools, (matchValue_5 = state.SetForm, (matchValue_5.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_5 = matchValue_5.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_5.ImageUrl, set$_5.MasterData, new DataTransfer_CreateOrUpdateLocationInformation(msg.fields[0].value, set$_5.LocationInformation.StorageSpace), set$_5.AssignedToolIds, set$_5.QRCodeIds, set$_5.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 6:
            return [new State(state.Tools, (matchValue_6 = state.SetForm, (matchValue_6.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_6 = matchValue_6.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_6.ImageUrl, set$_6.MasterData, new DataTransfer_CreateOrUpdateLocationInformation(set$_6.LocationInformation.StorageId, msg.fields[0]), set$_6.AssignedToolIds, set$_6.QRCodeIds, set$_6.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 7:
            return [new State(state.Tools, (matchValue_7 = state.SetForm, (matchValue_7.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_7 = matchValue_7.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_7.ImageUrl, (bind$0040_7 = set$_7.MasterData, new DataTransfer_CreateOrUpdateMasterData(bind$0040_7.Name, bind$0040_7.Manufacturer, bind$0040_7.Model, bind$0040_7.SerialNumber, msg.fields[0], bind$0040_7.InventoryNumber, bind$0040_7.CommercialData, bind$0040_7.CustomProperties)), set$_7.LocationInformation, set$_7.AssignedToolIds, set$_7.QRCodeIds, set$_7.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 10:
            return [new State(state.Tools, (matchValue_8 = state.SetForm, (matchValue_8.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_8 = matchValue_8.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_8.ImageUrl, set$_8.MasterData, set$_8.LocationInformation, set$_8.AssignedToolIds, List_distinct(append(singleton(msg.fields[0]), set$_8.QRCodeIds), {
                Equals: (x, y) => (x === y),
                GetHashCode: stringHash,
            }), set$_8.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 8: {
            const assignedLabelIds = ofArray(map((u) => u.value, defaultArg(ofNullable(msg.fields[0]), [])));
            return [new State(state.Tools, (matchValue_9 = state.SetForm, (matchValue_9.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_9 = matchValue_9.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_9.ImageUrl, set$_9.MasterData, set$_9.LocationInformation, set$_9.AssignedToolIds, set$_9.QRCodeIds, List_distinct(map_1(unwrapLabelId, assignedLabelIds), {
                Equals: (x_1, y_1) => (x_1 === y_1),
                GetHashCode: stringHash,
            }))])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        }
        case 9:
            return [new State(state.Tools, (matchValue_10 = state.SetForm, (matchValue_10.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_10 = matchValue_10.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_10.ImageUrl, (bind$0040_8 = set$_10.MasterData, new DataTransfer_CreateOrUpdateMasterData(bind$0040_8.Name, bind$0040_8.Manufacturer, bind$0040_8.Model, bind$0040_8.SerialNumber, bind$0040_8.Comment, bind$0040_8.InventoryNumber, bind$0040_8.CommercialData, msg.fields[0])), set$_10.LocationInformation, set$_10.AssignedToolIds, set$_10.QRCodeIds, set$_10.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 11:
            return [new State(state.Tools, (matchValue_11 = state.SetForm, (matchValue_11.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_11 = matchValue_11.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_11.ImageUrl, (bind$0040_9 = set$_11.MasterData, new DataTransfer_CreateOrUpdateMasterData(bind$0040_9.Name, bind$0040_9.Manufacturer, bind$0040_9.Model, bind$0040_9.SerialNumber, bind$0040_9.Comment, bind$0040_9.InventoryNumber, (bind$0040_10 = set$_11.MasterData.CommercialData, new CommercialDataFormDto(bind$0040_10.PurchaseDate, msg.fields[0], bind$0040_10.SupplierArticleNumber, bind$0040_10.PurchasePrice, bind$0040_10.WarrantyDate, bind$0040_10.Comment)), bind$0040_9.CustomProperties)), set$_11.LocationInformation, set$_11.AssignedToolIds, set$_11.QRCodeIds, set$_11.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 12:
            return [new State(state.Tools, (matchValue_12 = state.SetForm, (matchValue_12.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_12 = matchValue_12.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_12.ImageUrl, (bind$0040_11 = set$_12.MasterData, new DataTransfer_CreateOrUpdateMasterData(bind$0040_11.Name, bind$0040_11.Manufacturer, bind$0040_11.Model, bind$0040_11.SerialNumber, bind$0040_11.Comment, bind$0040_11.InventoryNumber, (bind$0040_12 = set$_12.MasterData.CommercialData, new CommercialDataFormDto(bind$0040_12.PurchaseDate, bind$0040_12.Supplier, msg.fields[0], bind$0040_12.PurchasePrice, bind$0040_12.WarrantyDate, bind$0040_12.Comment)), bind$0040_11.CustomProperties)), set$_12.LocationInformation, set$_12.AssignedToolIds, set$_12.QRCodeIds, set$_12.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 13:
            return [new State(state.Tools, (matchValue_13 = state.SetForm, (matchValue_13.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_13 = matchValue_13.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_13.ImageUrl, (bind$0040_13 = set$_13.MasterData, new DataTransfer_CreateOrUpdateMasterData(bind$0040_13.Name, bind$0040_13.Manufacturer, bind$0040_13.Model, bind$0040_13.SerialNumber, bind$0040_13.Comment, bind$0040_13.InventoryNumber, (bind$0040_14 = set$_13.MasterData.CommercialData, new CommercialDataFormDto(bind$0040_14.PurchaseDate, bind$0040_14.Supplier, bind$0040_14.SupplierArticleNumber, msg.fields[0], bind$0040_14.WarrantyDate, bind$0040_14.Comment)), bind$0040_13.CustomProperties)), set$_13.LocationInformation, set$_13.AssignedToolIds, set$_13.QRCodeIds, set$_13.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 14:
            return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, msg.fields[0], state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 15:
            return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, msg.fields[0], state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 16:
            return [new State(state.Tools, (matchValue_14 = state.SetForm, (matchValue_14.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_14 = matchValue_14.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_14.ImageUrl, (bind$0040_15 = set$_14.MasterData, new DataTransfer_CreateOrUpdateMasterData(bind$0040_15.Name, bind$0040_15.Manufacturer, bind$0040_15.Model, bind$0040_15.SerialNumber, bind$0040_15.Comment, bind$0040_15.InventoryNumber, (bind$0040_16 = set$_14.MasterData.CommercialData, new CommercialDataFormDto(bind$0040_16.PurchaseDate, bind$0040_16.Supplier, bind$0040_16.SupplierArticleNumber, bind$0040_16.PurchasePrice, bind$0040_16.WarrantyDate, msg.fields[0])), bind$0040_15.CustomProperties)), set$_14.LocationInformation, set$_14.AssignedToolIds, set$_14.QRCodeIds, set$_14.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 17:
            return [new State(state.Tools, (matchValue_15 = state.SetForm, (matchValue_15.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_15 = matchValue_15.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_15.ImageUrl, set$_15.MasterData, set$_15.LocationInformation, List_distinct(append(msg.fields[0], set$_15.AssignedToolIds), {
                Equals: (x_2, y_2) => (x_2 === y_2),
                GetHashCode: stringHash,
            }), set$_15.QRCodeIds, set$_15.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 18:
            return [new State(state.Tools, (matchValue_16 = state.SetForm, (matchValue_16.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$_16 = matchValue_16.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$_16.ImageUrl, set$_16.MasterData, set$_16.LocationInformation, filter((toolId) => !contains(toolId, msg.fields[0], {
                Equals: (x_3, y_3) => (x_3 === y_3),
                GetHashCode: stringHash,
            }), set$_16.AssignedToolIds), set$_16.QRCodeIds, set$_16.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 19:
            return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, true, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 20:
            return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, false, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 21: {
            const matchValue_17 = state.SetForm;
            const matchValue_18 = state.PropertyConfigurations;
            const matchValue_19 = state.IsCustomPropertiesEnabled;
            let matchResult, isCustomPropertiesEnabled, propertyConfigurations, set$_17;
            if (matchValue_17.tag === 0) {
                matchResult = 1;
            }
            else if (matchValue_18.tag === 0) {
                matchResult = 1;
            }
            else if (matchValue_19.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                isCustomPropertiesEnabled = matchValue_19.fields[0];
                propertyConfigurations = matchValue_18.fields[0];
                set$_17 = matchValue_17.fields[0];
            }
            switch (matchResult) {
                case 0: {
                    const set$_18 = new DataTransfer_CreateOrUpdateSet(set$_17.ImageUrl, (bind$0040_17 = set$_17.MasterData, new DataTransfer_CreateOrUpdateMasterData(bind$0040_17.Name, bind$0040_17.Manufacturer, bind$0040_17.Model, bind$0040_17.SerialNumber, bind$0040_17.Comment, bind$0040_17.InventoryNumber, (bind$0040_18 = set$_17.MasterData.CommercialData, new CommercialDataFormDto(map_2(toISOString, state.PurchaseDate), bind$0040_18.Supplier, bind$0040_18.SupplierArticleNumber, bind$0040_18.PurchasePrice, map_2(toISOString, state.WarrantyDate), bind$0040_18.Comment)), bind$0040_17.CustomProperties)), set$_17.LocationInformation, set$_17.AssignedToolIds, set$_17.QRCodeIds, set$_17.LabelIds);
                    return [new State(state.Tools, new RequestedValue$1(1, [set$_18]), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, FormState__startLoading(state.FormState), state.FormKind), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_2;
                        return ((url_2 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                            const pr = response.arrayBuffer();
                            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                        }, refreshOn)))(true);
                    })), ["/api/sets", toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_CreateOrUpdateSet_$reflection(), undefined, undefined, undefined)(set$_18))], (Item_18) => (new Msg(31, [Item_18])), (Item_19) => (new Msg(40, [Item_19])))];
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 22: {
            const matchValue_21 = state.SetForm;
            const matchValue_22 = state.PropertyConfigurations;
            const matchValue_23 = state.IsCustomPropertiesEnabled;
            const matchValue_24 = state.SetId;
            let matchResult_1, isCustomPropertiesEnabled_1, propertyConfigurations_1, set$_20, setId;
            if (matchValue_21.tag === 0) {
                matchResult_1 = 1;
            }
            else if (matchValue_22.tag === 0) {
                matchResult_1 = 1;
            }
            else if (matchValue_23.tag === 0) {
                matchResult_1 = 1;
            }
            else if (matchValue_24 == null) {
                matchResult_1 = 1;
            }
            else {
                matchResult_1 = 0;
                isCustomPropertiesEnabled_1 = matchValue_23.fields[0];
                propertyConfigurations_1 = matchValue_22.fields[0];
                set$_20 = matchValue_21.fields[0];
                setId = matchValue_24;
            }
            switch (matchResult_1) {
                case 0: {
                    const set$_21 = new DataTransfer_CreateOrUpdateSet(set$_20.ImageUrl, (bind$0040_19 = set$_20.MasterData, new DataTransfer_CreateOrUpdateMasterData(bind$0040_19.Name, bind$0040_19.Manufacturer, bind$0040_19.Model, bind$0040_19.SerialNumber, bind$0040_19.Comment, bind$0040_19.InventoryNumber, (bind$0040_20 = set$_20.MasterData.CommercialData, new CommercialDataFormDto(map_2(toISOString, state.PurchaseDate), bind$0040_20.Supplier, bind$0040_20.SupplierArticleNumber, bind$0040_20.PurchasePrice, map_2(toISOString, state.WarrantyDate), bind$0040_20.Comment)), bind$0040_19.CustomProperties)), set$_20.LocationInformation, set$_20.AssignedToolIds, set$_20.QRCodeIds, set$_20.LabelIds);
                    return [new State(state.Tools, new RequestedValue$1(1, [set$_21]), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, FormState__startLoading(state.FormState), state.FormKind), (body_3 = toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_CreateOrUpdateSet_$reflection(), undefined, undefined, undefined)(set$_21)), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_5;
                        return ((url_5 = addPrefix_1(tupledArg_1[0]), (refreshOn_1) => fetchWithDecoder_1(url_5, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                            const pr_1 = response_1.arrayBuffer();
                            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                        }, refreshOn_1)))(true);
                    })), [`/api/sets/${SetId__get_unwrap_1(setId)}`, body_3], (Item_21) => (new Msg(29, [Item_21])), (Item_22) => (new Msg(40, [Item_22]))))];
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 38: {
            const response_2 = msg.fields[0];
            switch (response_2.tag) {
                case 0:
                    return [state, Cmd_errorToast("general.a_error_occured")];
                case 1: {
                    const matchValue_26 = state.SetForm;
                    const matchValue_27 = state.SetId;
                    let matchResult_2, set$_23, setId_2;
                    if (matchValue_26.tag === 0) {
                        matchResult_2 = 1;
                    }
                    else if (matchValue_27 == null) {
                        matchResult_2 = 1;
                    }
                    else {
                        matchResult_2 = 0;
                        set$_23 = matchValue_26.fields[0];
                        setId_2 = matchValue_27;
                    }
                    switch (matchResult_2) {
                        case 0:
                            return [state, (set$_24 = (new DataTransfer_CreateOrUpdateSet(response_2.fields[0].ImageUrl, set$_23.MasterData, set$_23.LocationInformation, set$_23.AssignedToolIds, set$_23.QRCodeIds, set$_23.LabelIds)), (body_5 = toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_CreateOrUpdateSet_$reflection(), undefined, undefined, undefined)(set$_24)), Cmd_OfPromise_either((tupledArg_2) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                let url_9;
                                return ((url_9 = addPrefix_1(tupledArg_2[0]), (refreshOn_2) => fetchWithDecoder_1(url_9, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_2[1]])]), "application/json", (response_3) => {
                                    const pr_2 = response_3.arrayBuffer();
                                    return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), string_type));
                                }, refreshOn_2)))(true);
                            })), [`/api/sets/${SetId__get_unwrap_1(setId_2)}`, body_5], (Item_23) => (new Msg(30, [Item_23])), (Item_24) => (new Msg(40, [Item_24])))))];
                        default:
                            return [state, Cmd_none()];
                    }
                }
                default:
                    return [state, Cmd_errorToast("general.file_too_large")];
            }
        }
        case 30: {
            const matchValue_29 = state.SetId;
            if (matchValue_29 == null) {
                return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, FormState_get_empty(), state.FormKind), Cmd_errorToast("general.a_error_occured")];
            }
            else {
                const setId_4 = matchValue_29;
                return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, FormState_get_empty(), state.FormKind), Cmd_batch(ofArray([(xs = ["sets", SetId__get_unwrap(setId_4)], Cmd_ofEffect((_arg) => {
                    RouterModule_nav(ofArray(xs), 1, 1);
                })), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 23:
            return [state, Cmd_none()];
        case 24:
            return [state, Cmd_OfPromise_either((url_10) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_10), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_4) => {
                const pr_3 = response_4.arrayBuffer();
                return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), list_type(Label_$reflection())));
            })))), "/api/labels", (Item_25) => (new Msg(33, [Item_25])), (Item_26) => (new Msg(40, [Item_26])))];
        case 26: {
            const matchValue_30 = state.SetForm;
            if (matchValue_30.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const newForm_17 = matchValue_30.fields[0];
                return [new State(state.Tools, new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(newForm_17.ImageUrl, newForm_17.MasterData, newForm_17.LocationInformation, newForm_17.AssignedToolIds, filter((code) => !contains(code, msg.fields[0], {
                    Equals: (x_8, y_4) => (x_8 === y_4),
                    GetHashCode: stringHash,
                }), newForm_17.QRCodeIds), newForm_17.LabelIds)]), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
            }
        }
        case 31: {
            const response_5 = msg.fields[0];
            if (response_5.tag === 1) {
                return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, FormState__setValidationResponse_5219762A(state.FormState, response_5.fields[0]), state.FormKind), Cmd_errorToast("validation.toast")];
            }
            else {
                const setId_5 = response_5.fields[0];
                const matchValue_31 = state.SelectedFile;
                if (matchValue_31 == null) {
                    return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, FormState_get_empty(), state.FormKind), Cmd_batch(ofArray([(xs_2 = ["sets", setId_5], Cmd_ofEffect((_arg_1) => {
                        RouterModule_nav(ofArray(xs_2), 1, 1);
                    })), Cmd_successToast("general.saved_successfully")]))];
                }
                else {
                    const file = matchValue_31;
                    const formData = new FormData();
                    formData.append(file.name, file);
                    return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, new SetId_1(parse(setId_5)), state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), uploadImage((Item_28) => (new Msg(38, [Item_28])), (Item_29) => (new Msg(40, [Item_29])), formData)];
                }
            }
        }
        case 29: {
            const response_6 = msg.fields[0];
            if (response_6.tag === 1) {
                return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, FormState__setValidationResponse_5219762A(state.FormState, response_6.fields[0]), state.FormKind), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, FormState_get_empty(), state.FormKind), Cmd_batch(ofArray([(xs_4 = ["sets", response_6.fields[0]], Cmd_ofEffect((_arg_2) => {
                    RouterModule_nav(ofArray(xs_4), 1, 1);
                })), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 25:
            return [state, getAssignableTools((Item_30) => (new Msg(27, [Item_30])), (Item_31) => (new Msg(40, [Item_31])))];
        case 28: {
            const response_7 = msg.fields[0];
            return [new State(state.Tools, new RequestedValue$1(1, [equals(state.FormKind, new FormKind_1(2, [])) ? (new DataTransfer_CreateOrUpdateSet(response_7.ImageUrl, response_7.MasterData, response_7.LocationInformation, empty(), empty(), response_7.LabelIds)) : response_7]), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        }
        case 27:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 32:
            return [new State(state.Tools, state.SetForm, new RequestedValue$1(1, [msg.fields[0]]), state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 33:
            return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, new RequestedValue$1(1, [msg.fields[0]]), state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 34:
            return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, msg.fields[0], state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 35:
            return [new State(state.Tools, state.SetForm, state.Storages, new RequestedValue$1(1, [msg.fields[0]]), state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 36:
            return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, new RequestedValue$1(1, [msg.fields[0]]), state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 37:
            return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, msg.fields[0], state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
        case 39:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, new RequestedValue$1(1, [false]), state.FormState, state.FormKind), Cmd_none()];
            }
            else {
                return [new State(state.Tools, state.SetForm, state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, new RequestedValue$1(1, [true]), state.FormState, state.FormKind), Cmd_none()];
            }
        case 40:
            return [state, Cmd_errorToast("general.a_error_occured")];
        default:
            return [new State(state.Tools, (matchValue = state.SetForm, (matchValue.tag === 0) ? (new RequestedValue$1(0, [])) : ((set$ = matchValue.fields[0], new RequestedValue$1(1, [new DataTransfer_CreateOrUpdateSet(set$.ImageUrl, (bind$0040 = set$.MasterData, new DataTransfer_CreateOrUpdateMasterData(bind$0040.Name, msg.fields[0], bind$0040.Model, bind$0040.SerialNumber, bind$0040.Comment, bind$0040.InventoryNumber, bind$0040.CommercialData, bind$0040.CustomProperties)), set$.LocationInformation, set$.AssignedToolIds, set$.QRCodeIds, set$.LabelIds)])))), state.Storages, state.Locations, state.SetId, state.IsValidated, state.UserData, state.Customer, state.Labels, state.SelectedTools, state.PurchaseDate, state.WarrantyDate, state.ImageViewerOpen, state.Manufacturers, state.PropertyConfigurations, state.SelectedFile, state.IsCustomPropertiesEnabled, state.FormState, state.FormKind), Cmd_none()];
    }
}

