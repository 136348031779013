import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, class_type, string_type, record_type, list_type, bool_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ActivatedEmailNotification_$reflection, User_$reflection } from "../Shared/User.js";
import { ClientApiResponse$1_$reflection, PostResponse$1_$reflection, EmptyResponse_$reflection, RemoteValue$1_$reflection } from "../Shared/Shared.js";
import { Storage_$reflection } from "../Shared/Storage.js";

export class State extends Record {
    constructor(Message, User, Storages) {
        super();
        this.Message = Message;
        this.User = User;
        this.Storages = Storages;
    }
}

export function State_$reflection() {
    return record_type("Profile.Types.State", [], State, () => [["Message", bool_type], ["User", RemoteValue$1_$reflection(User_$reflection())], ["Storages", RemoteValue$1_$reflection(list_type(Storage_$reflection()))]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SendResetPassword", "ChangeLanguage", "PasswordResetSent", "UserLanguageUpdated", "ChangeEmailNotification", "UserFetched", "UpdateUser", "UserSaved", "StoragesFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Profile.Types.Msg", [], Msg, () => [[], [["Item", string_type]], [["Item", string_type]], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [["Item", ActivatedEmailNotification_$reflection()]], [["Item", User_$reflection()]], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", list_type(Storage_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

