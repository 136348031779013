import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactPopover } from "../../Components/ReactPopover.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextButton, Button } from "../../Components/Button.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";

export function ComplexFilterPopOver(props) {
    const t = useTranslation()[0];
    return createElement(ReactPopover, {
        Body: (onClose) => {
            let elems_2;
            return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["p-6"])], (elems_2 = toList(delay(() => append(props.Elements, delay(() => {
                let elems_1, elems;
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "w-[330px]", "mx-auto", "mt-5"])], (elems_1 = [createElement(Button, {
                    ComponentState: "enabled",
                    Label: t("tool.show_objects"),
                    OnClick: () => {
                        props.OnFilter();
                        onClose();
                    },
                    TestId: "infinite-table-complex-filter-button",
                    Variant: "primary",
                }), createElement("div", createObj(ofArray([["className", join(" ", ["mx-auto", "mt-2"])], (elems = [createElement(TextButton, {
                    ComponentState: "enabled",
                    Label: t("tool.filter.reset_filter"),
                    OnClick: () => {
                        props.OnReset();
                        onClose();
                    },
                    TestId: "infinite-table-complex-filter-reset",
                    Variant: "blueButton",
                })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
            })))), ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
        },
        ButtonBadge: unwrap(props.Badge),
        ButtonLabel: props.ButtonLabel,
        ComponentState: props.ComponentState,
        TestId: "",
    });
}

