import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { ReservationDto_$reflection } from "../../Shared/Planning.js";
import { union_type, class_type, record_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { TokenDataDto_$reflection, CustomerConfiguration_$reflection, RequestedValue$1_$reflection } from "../../Shared/Shared.js";

export class State extends Record {
    constructor(Reservations, CustomerConfiguration, UserData) {
        super();
        this.Reservations = Reservations;
        this.CustomerConfiguration = CustomerConfiguration;
        this.UserData = UserData;
    }
}

export function State_$reflection() {
    return record_type("Reservation.Overview.Types.State", [], State, () => [["Reservations", RequestedValue$1_$reflection(list_type(ReservationDto_$reflection()))], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["UserData", TokenDataDto_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FetchReservations", "ReservationsFetched", "CustomerConfigurationFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Reservation.Overview.Types.Msg", [], Msg, () => [[], [["Item", list_type(ReservationDto_$reflection())]], [["Item", CustomerConfiguration_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

