import { CreateOrUpdateStocktakingDto_$reflection, CreateOrUpdateStocktakingDto, StocktakingTypeScope, StocktakingLocationScope } from "../../Shared/Stocktaking.js";
import { contains, map as map_1, append, filter, singleton, empty, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { FormState__setValidationResponse_5219762A, FormState__startLoading, FormState_get_empty, RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Receiver_$reflection, User_$reflection } from "../../Shared/User.js";
import { string_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../CommunicationV2.js";
import { PropertyConfigurationVisibility, PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { ValidationOnGetRequest } from "../../ErrorHandling.js";
import { getStocktakingRaw } from "../../Requests/Stocktaking.js";
import { toISOString } from "../../Common.js";
import { stringHash, compare, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { map, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { unwrapStocktakingId } from "../../Shared/Helper.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";

export const emptyStocktaking = new CreateOrUpdateStocktakingDto("", "", "", "", new StocktakingLocationScope(0, []), ofArray([new StocktakingTypeScope(0, []), new StocktakingTypeScope(1, []), new StocktakingTypeScope(2, [])]), true, false, empty(), empty());

export const initialModel = new State(undefined, new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), FormState_get_empty(), undefined, undefined, undefined);

export const initNewView = [new State(initialModel.StocktakingId, new RequestedValue$1(1, [emptyStocktaking]), initialModel.Users, initialModel.Storages, initialModel.Receiver, initialModel.PropertyConfigurations, initialModel.FormState, initialModel.EditStocktakingState, initialModel.StartDate, initialModel.EndDate), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
    const pr = response.arrayBuffer();
    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(User_$reflection())));
})))), "/api/receivers/users", (Item) => (new Msg(17, [Item])), (Item_1) => (new Msg(21, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
    const pr_1 = response_1.arrayBuffer();
    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
})))), "/api/storages", (Item_2) => (new Msg(18, [Item_2])), (Item_3) => (new Msg(21, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
    const pr_2 = response_2.arrayBuffer();
    return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(Receiver_$reflection())));
})))), "/api/receivers", (Item_4) => (new Msg(19, [Item_4])), (Item_5) => (new Msg(21, [Item_5]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
    let url_11;
    return ((url_11 = addPrefix_1(url_9), (refreshOn) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
        const pr_3 = response_3.arrayBuffer();
        return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), list_type(PropertyConfiguration_$reflection())));
    }, refreshOn)))(true).then((_arg) => {
        const result_4 = _arg;
        return (result_4.tag === 1) ? (Promise.resolve((() => {
            throw new ValidationOnGetRequest(result_4.fields[0]);
        })())) : (Promise.resolve(result_4.fields[0]));
    });
})), "/api/property-configurations", (Item_6) => (new Msg(20, [Item_6])), (Item_7) => (new Msg(21, [Item_7])))]))];

export function initEditStocktaking(stocktakingId) {
    return [new State(stocktakingId, initialModel.StocktakingForm, initialModel.Users, initialModel.Storages, initialModel.Receiver, initialModel.PropertyConfigurations, initialModel.FormState, initialModel.EditStocktakingState, initialModel.StartDate, initialModel.EndDate), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(User_$reflection())));
    })))), "/api/receivers/users", (Item) => (new Msg(17, [Item])), (Item_1) => (new Msg(21, [Item_1]))), getStocktakingRaw(stocktakingId, (Item_2) => (new Msg(0, [Item_2])), (Item_3) => (new Msg(21, [Item_3]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_4) => (new Msg(18, [Item_4])), (Item_5) => (new Msg(21, [Item_5]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(Receiver_$reflection())));
    })))), "/api/receivers", (Item_6) => (new Msg(19, [Item_6])), (Item_7) => (new Msg(21, [Item_7]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_11;
        return ((url_11 = addPrefix_1(url_9), (refreshOn) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
            const pr_3 = response_3.arrayBuffer();
            return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_4 = _arg;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), "/api/property-configurations", (Item_8) => (new Msg(20, [Item_8])), (Item_9) => (new Msg(21, [Item_9])))]))];
}

export function initCopyStocktaking(stocktakingId) {
    return [new State(stocktakingId, initialModel.StocktakingForm, initialModel.Users, initialModel.Storages, initialModel.Receiver, initialModel.PropertyConfigurations, initialModel.FormState, initialModel.EditStocktakingState, initialModel.StartDate, initialModel.EndDate), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(User_$reflection())));
    })))), "/api/receivers/users", (Item) => (new Msg(17, [Item])), (Item_1) => (new Msg(21, [Item_1]))), getStocktakingRaw(stocktakingId, (Item_2) => (new Msg(1, [Item_2])), (Item_3) => (new Msg(21, [Item_3]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_4) => (new Msg(18, [Item_4])), (Item_5) => (new Msg(21, [Item_5]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(Receiver_$reflection())));
    })))), "/api/receivers", (Item_6) => (new Msg(19, [Item_6])), (Item_7) => (new Msg(21, [Item_7]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_11;
        return ((url_11 = addPrefix_1(url_9), (refreshOn) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
            const pr_3 = response_3.arrayBuffer();
            return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_4 = _arg;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), "/api/property-configurations", (Item_8) => (new Msg(20, [Item_8])), (Item_9) => (new Msg(21, [Item_9])))]))];
}

export function update(msg, state) {
    let stocktaking_2, stocktaking_3, body_3, xs;
    switch (msg.tag) {
        case 0: {
            const stocktaking_1 = msg.fields[0];
            return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktaking_1.Name, stocktaking_1.Description, toISOString(stocktaking_1.StartDate), toISOString(stocktaking_1.EndDate), stocktaking_1.LocationScope, stocktaking_1.TypeScope, stocktaking_1.ToolsCanBeTaken, stocktaking_1.IsScannerRequired, stocktaking_1.HelperIds, stocktaking_1.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, stocktaking_1.State, stocktaking_1.StartDate, stocktaking_1.EndDate), Cmd_none()];
        }
        case 20:
            return [new State(state.StocktakingId, state.StocktakingForm, state.Users, state.Storages, state.Receiver, new RequestedValue$1(1, [filter((propertyConfiguration) => equals(propertyConfiguration.Visibility, new PropertyConfigurationVisibility(2, [])), msg.fields[0])]), state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
        case 17:
            return [new State(state.StocktakingId, state.StocktakingForm, new RequestedValue$1(1, [msg.fields[0]]), state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
        case 2: {
            const matchValue = state.StocktakingForm;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm = matchValue.fields[0];
                return [new State(state.StocktakingId, state.StocktakingForm, state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, FormState__startLoading(state.FormState), state.EditStocktakingState, state.StartDate, state.EndDate), (stocktaking_2 = (new CreateOrUpdateStocktakingDto(stocktakingForm.Name, stocktakingForm.Description, defaultArg(map(toISOString, state.StartDate), ""), defaultArg(map(toISOString, state.EndDate), ""), stocktakingForm.LocationScope, stocktakingForm.TypeScope, stocktakingForm.ToolsCanBeTaken, stocktakingForm.IsScannerRequired, stocktakingForm.HelperIds, stocktakingForm.ResponsiblePersonIds)), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let url_2;
                    return ((url_2 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response_1) => {
                        const pr = response_1.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                    }, refreshOn)))(true);
                })), ["/api/stocktakings", toString(0, Auto_generateBoxedEncoder_437914C6(CreateOrUpdateStocktakingDto_$reflection(), undefined, undefined, undefined)(stocktaking_2))], (Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(21, [Item_1]))))];
            }
        }
        case 3: {
            const matchValue_1 = state.StocktakingId;
            const matchValue_2 = state.StocktakingForm;
            let matchResult, stocktakingForm_1, stocktakingId;
            if (matchValue_1 == null) {
                matchResult = 1;
            }
            else if (matchValue_2.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                stocktakingForm_1 = matchValue_2.fields[0];
                stocktakingId = matchValue_1;
            }
            switch (matchResult) {
                case 0:
                    return [new State(state.StocktakingId, state.StocktakingForm, state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, FormState__startLoading(state.FormState), state.EditStocktakingState, state.StartDate, state.EndDate), (stocktaking_3 = (new CreateOrUpdateStocktakingDto(stocktakingForm_1.Name, stocktakingForm_1.Description, defaultArg(map(toISOString, state.StartDate), ""), defaultArg(map(toISOString, state.EndDate), ""), stocktakingForm_1.LocationScope, stocktakingForm_1.TypeScope, stocktakingForm_1.ToolsCanBeTaken, stocktakingForm_1.IsScannerRequired, stocktakingForm_1.HelperIds, stocktakingForm_1.ResponsiblePersonIds)), (body_3 = toString(0, Auto_generateBoxedEncoder_437914C6(CreateOrUpdateStocktakingDto_$reflection(), undefined, undefined, undefined)(stocktaking_3)), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_5;
                        return ((url_5 = addPrefix_1(tupledArg_1[0]), (refreshOn_1) => fetchWithDecoder_1(url_5, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_2) => {
                            const pr_1 = response_2.arrayBuffer();
                            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                        }, refreshOn_1)))(true);
                    })), [`/api/stocktakings/${unwrapStocktakingId(stocktakingId)}`, body_3], (Item_2) => (new Msg(4, [Item_2])), (Item_3) => (new Msg(21, [Item_3])))))];
                default:
                    return [state, Cmd_none()];
            }
        }
        case 4: {
            const response_3 = msg.fields[0];
            if (response_3.tag === 1) {
                return [new State(state.StocktakingId, state.StocktakingForm, state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, FormState__setValidationResponse_5219762A(state.FormState, response_3.fields[0]), state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.StocktakingId, state.StocktakingForm, state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, FormState_get_empty(), state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_batch(ofArray([Cmd_successToast("stocktaking.success_created_stocktaking"), (xs = ["stocktaking", response_3.fields[0]], Cmd_ofEffect((_arg) => {
                    RouterModule_nav(ofArray(xs), 1, 1);
                }))]))];
            }
        }
        case 5: {
            const matchValue_4 = state.StocktakingForm;
            if (matchValue_4.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_2 = matchValue_4.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(msg.fields[0], stocktakingForm_2.Description, stocktakingForm_2.StartDate, stocktakingForm_2.EndDate, stocktakingForm_2.LocationScope, stocktakingForm_2.TypeScope, stocktakingForm_2.ToolsCanBeTaken, stocktakingForm_2.IsScannerRequired, stocktakingForm_2.HelperIds, stocktakingForm_2.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
            }
        }
        case 6: {
            const startDate = msg.fields[0];
            return [new State(state.StocktakingId, state.StocktakingForm, state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, startDate, (compare(state.EndDate, startDate) < 0) ? startDate : state.EndDate), Cmd_none()];
        }
        case 7: {
            const endDate = msg.fields[0];
            return [new State(state.StocktakingId, state.StocktakingForm, state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, (compare(state.StartDate, endDate) > 0) ? endDate : state.StartDate, endDate), Cmd_none()];
        }
        case 8: {
            const matchValue_5 = state.StocktakingForm;
            if (matchValue_5.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_3 = matchValue_5.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_3.Name, msg.fields[0], stocktakingForm_3.StartDate, stocktakingForm_3.EndDate, stocktakingForm_3.LocationScope, stocktakingForm_3.TypeScope, stocktakingForm_3.ToolsCanBeTaken, stocktakingForm_3.IsScannerRequired, stocktakingForm_3.HelperIds, stocktakingForm_3.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
            }
        }
        case 11: {
            const matchValue_6 = state.StocktakingForm;
            if (matchValue_6.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_4 = matchValue_6.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_4.Name, stocktakingForm_4.Description, stocktakingForm_4.StartDate, stocktakingForm_4.EndDate, stocktakingForm_4.LocationScope, stocktakingForm_4.TypeScope, msg.fields[0], stocktakingForm_4.IsScannerRequired, stocktakingForm_4.HelperIds, stocktakingForm_4.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
            }
        }
        case 12: {
            const matchValue_7 = state.StocktakingForm;
            if (matchValue_7.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_5 = matchValue_7.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_5.Name, stocktakingForm_5.Description, stocktakingForm_5.StartDate, stocktakingForm_5.EndDate, stocktakingForm_5.LocationScope, stocktakingForm_5.TypeScope, stocktakingForm_5.ToolsCanBeTaken, msg.fields[0], stocktakingForm_5.HelperIds, stocktakingForm_5.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
            }
        }
        case 13: {
            const matchValue_8 = state.StocktakingForm;
            if (matchValue_8.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_6 = matchValue_8.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_6.Name, stocktakingForm_6.Description, stocktakingForm_6.StartDate, stocktakingForm_6.EndDate, stocktakingForm_6.LocationScope, stocktakingForm_6.TypeScope, stocktakingForm_6.ToolsCanBeTaken, stocktakingForm_6.IsScannerRequired, append(map_1((r) => r.Id, msg.fields[0]), stocktakingForm_6.HelperIds), stocktakingForm_6.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
            }
        }
        case 14: {
            const matchValue_9 = state.StocktakingForm;
            if (matchValue_9.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_7 = matchValue_9.fields[0];
                const removedReceiverIds = map_1((receiver) => receiver.Id, msg.fields[0]);
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_7.Name, stocktakingForm_7.Description, stocktakingForm_7.StartDate, stocktakingForm_7.EndDate, stocktakingForm_7.LocationScope, stocktakingForm_7.TypeScope, stocktakingForm_7.ToolsCanBeTaken, stocktakingForm_7.IsScannerRequired, filter((helperId) => !contains(helperId, removedReceiverIds, {
                    Equals: (x_2, y) => (x_2 === y),
                    GetHashCode: stringHash,
                }), stocktakingForm_7.HelperIds), stocktakingForm_7.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
            }
        }
        case 15: {
            const matchValue_10 = state.StocktakingForm;
            if (matchValue_10.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_8 = matchValue_10.fields[0];
                const removedReceiverIds_1 = map_1((receiver_1) => receiver_1.Id, msg.fields[0]);
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_8.Name, stocktakingForm_8.Description, stocktakingForm_8.StartDate, stocktakingForm_8.EndDate, stocktakingForm_8.LocationScope, stocktakingForm_8.TypeScope, stocktakingForm_8.ToolsCanBeTaken, stocktakingForm_8.IsScannerRequired, stocktakingForm_8.HelperIds, filter((responsiblePersonId) => !contains(responsiblePersonId, removedReceiverIds_1, {
                    Equals: (x_3, y_1) => (x_3 === y_1),
                    GetHashCode: stringHash,
                }), stocktakingForm_8.ResponsiblePersonIds))]), state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
            }
        }
        case 16: {
            const matchValue_11 = state.StocktakingForm;
            if (matchValue_11.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_9 = matchValue_11.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_9.Name, stocktakingForm_9.Description, stocktakingForm_9.StartDate, stocktakingForm_9.EndDate, stocktakingForm_9.LocationScope, stocktakingForm_9.TypeScope, stocktakingForm_9.ToolsCanBeTaken, stocktakingForm_9.IsScannerRequired, stocktakingForm_9.HelperIds, append(map_1((r_1) => r_1.Id, msg.fields[0]), stocktakingForm_9.ResponsiblePersonIds))]), state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
            }
        }
        case 9: {
            const matchValue_12 = state.StocktakingForm;
            if (matchValue_12.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_10 = matchValue_12.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_10.Name, stocktakingForm_10.Description, stocktakingForm_10.StartDate, stocktakingForm_10.EndDate, msg.fields[0], stocktakingForm_10.TypeScope, stocktakingForm_10.ToolsCanBeTaken, stocktakingForm_10.IsScannerRequired, stocktakingForm_10.HelperIds, stocktakingForm_10.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
            }
        }
        case 10: {
            const matchValue_13 = state.StocktakingForm;
            if (matchValue_13.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_11 = matchValue_13.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_11.Name, stocktakingForm_11.Description, stocktakingForm_11.StartDate, stocktakingForm_11.EndDate, stocktakingForm_11.LocationScope, msg.fields[0], stocktakingForm_11.ToolsCanBeTaken, stocktakingForm_11.IsScannerRequired, stocktakingForm_11.HelperIds, stocktakingForm_11.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
            }
        }
        case 18:
            return [new State(state.StocktakingId, state.StocktakingForm, state.Users, new RequestedValue$1(1, [msg.fields[0]]), state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
        case 19:
            return [new State(state.StocktakingId, state.StocktakingForm, state.Users, state.Storages, new RequestedValue$1(1, [msg.fields[0]]), state.PropertyConfigurations, state.FormState, state.EditStocktakingState, state.StartDate, state.EndDate), Cmd_none()];
        case 21:
            return [state, Cmd_none()];
        default: {
            const stocktaking = msg.fields[0];
            return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktaking.Name, stocktaking.Description, toISOString(stocktaking.StartDate), toISOString(stocktaking.EndDate), stocktaking.LocationScope, stocktaking.TypeScope, stocktaking.ToolsCanBeTaken, stocktaking.IsScannerRequired, stocktaking.HelperIds, stocktaking.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.PropertyConfigurations, state.FormState, state.EditStocktakingState, stocktaking.StartDate, stocktaking.EndDate), Cmd_none()];
        }
    }
}

