import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { empty, ofArray, singleton as singleton_1, toArray, contains, filter } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createObj, stringHash } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { ModalSheet } from "../../Components/ModalSheet.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { TableControl$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { singleton, append, delay, toArray as toArray_1 } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Role__ToI18nString } from "../../Shared/Shared.js";
import { Table_customPropertyColumnDefinitions } from "../../Widgets/CustomProperties.js";
import User_Add_Plus from "../../../public/assets/icons/User_Add_Plus.svg";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";

export function AddStocktakingUserModal(props) {
    let elems, TableRows, Options;
    const t = useTranslation()[0];
    const users = filter((user) => !contains(user.Id, props.SelectedUserIds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), props.Users);
    return createElement(ModalSheet, {
        CloseButtonTestId: "close-stocktaking-modal-button",
        Content: createElement("div", createObj(ofArray([["className", join(" ", ["p-4"])], (elems = [createElement(Table, (TableRows = toArray(users), (Options = {
            BorderStyle: "full",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            RowSelection: "multiRow",
        }, {
            ColumnDefinitions: toArray_1(delay(() => append(singleton(new ColumnDefinition$1("name", "", t("general.name"), (row) => (new CellContent(0, [row.Name])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("department", "", t("user.department"), (row_1) => (new CellContent(0, [row_1.Department])), "select", "text")), delay(() => append(singleton(new ColumnDefinition$1("personnelNumber", "", t("user.personnel_number"), (row_2) => (new CellContent(0, [row_2.PersonnelNumber])), "select", "text")), delay(() => append(singleton(new ColumnDefinition$1("emailPhoneNumber", "", t("user.email_mobile_number"), (dto) => {
                const matchValue = dto.LoginInformation;
                return (matchValue.tag === 1) ? (new CellContent(0, [matchValue.fields[0]])) : (new CellContent(0, [matchValue.fields[0]]));
            }, "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("role", "", t("general.role"), (row_3) => (new CellContent(0, [t(Role__ToI18nString(row_3.Role))])), "select", "text")), delay(() => Table_customPropertyColumnDefinitions(props.PropertyConfigurations, t("tool.custom_properties"), (dto_1) => dto_1.CustomProperties, t))))))))))))),
            Controls: singleton_1(new TableControl$1(t("stocktaking.add"), User_Add_Plus(), props.OnSave, false, false, "stocktaking-add-button")),
            Options: Options,
            TableRows: TableRows,
        })))], ["children", reactApi.Children.toArray(Array.from(elems))])]))),
        Controls: empty(),
        IsModalOpen: props.IsOpen,
        OnClose: props.OnClose,
    });
}

